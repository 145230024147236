import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const Cookie = `${API_URL}/cookie`

export const service = {
  getCookie: (params: ParamsModel) => {
    return axios.get(Cookie, {params})
  },

  addCookie: (data: any) => {
    return axios.post(Cookie, data)
  },

  updateCookie: (body: any, id: string) => {
    return axios.put(`${Cookie}/${id}`, body)
  },

  deleteCookie: (data: string) => {
    return axios.delete(`${Cookie}/bulk-delete`, {data})
  },
  singleActivateCookie: (id: any) => {
    return axios.patch(`${Cookie}/enable`, id)
  },

  singleDeactivateCookie: (id: any) => {
    return axios.patch(`${Cookie}/disable`, id)
  },

  sortCookie: (body: any) => {
    return axios.patch(`${Cookie}/sort`, body)
  },
}
