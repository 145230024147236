import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getActiveCardCategory: (id: string = '') => ({
    type: actionTypes.GET_ACTIVE_CARD_CATEGORY_START,
    payload: id,
  }),

  getActiveCardCategorySuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ACTIVE_CARD_CATEGORY_SUCCESS,
    payload: data,
  }),
  getActiveCardCategoryFinish: () => ({
    type: actionTypes.GET_ACTIVE_CARD_CATEGORY_FINISH,
  }),
  getActiveCardCategoryError: (error: unknown) => ({
    type: actionTypes.GET_ACTIVE_CARD_CATEGORY_FINISH,
    payload: {error},
  }),

  getCardCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CARD_CATEGORY_START,
    payload: params,
  }),

  getCardCategorySuccess: (data: any) => ({
    type: actionTypes.GET_CARD_CATEGORY_SUCCESS,
    payload: data,
  }),
  getCardCategoryFinish: () => ({
    type: actionTypes.GET_CARD_CATEGORY_FINISH,
  }),
  getCardCategoryError: (error: unknown) => ({
    type: actionTypes.GET_CARD_CATEGORY_FINISH,
    payload: {error},
  }),

  addCardCategory: (data: any) => ({
    type: actionTypes.ADD_CARD_CATEGORY_START,
    payload: data,
  }),
  addCardCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_CARD_CATEGORY_SUCCESS,
    payload: task,
  }),
  addCardCategoryFinish: () => ({
    type: actionTypes.ADD_CARD_CATEGORY_FINISH,
  }),
  addCardCategoryReset: () => ({
    type: actionTypes.ADD_CARD_CATEGORY_RESET,
  }),

  updateCardCategory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_CARD_CATEGORY_START,
    payload: {data, id},
  }),
  updateCardCategorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_CARD_CATEGORY_SUCCESS,
    payload: task,
  }),
  updateCardCategoryFinish: () => ({
    type: actionTypes.UPDATE_CARD_CATEGORY_FINISH,
  }),
  updateCardCategoryReset: () => ({
    type: actionTypes.UPDATE_CARD_CATEGORY_RESET,
  }),

  deleteCardCategory: (data: any) => ({
    type: actionTypes.DELETE_CARD_CATEGORY_START,
    payload: {cardCategoryId: data},
  }),
  deleteCardCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_CARD_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteCardCategoryFinish: () => ({
    type: actionTypes.DELETE_CARD_CATEGORY_FINISH,
  }),
  activateCardCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CARD_CATEGORY_REQUEST,
    payload: {cardCategoryId: id},
  }),
  deactivateCardCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CARD_CATEGORY_REQUEST,
    payload: {cardCategoryId: id},
  }),
  singleActivateCardCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CARD_CATEGORY_REQUEST,
    payload: {cardCategoryId: [id]},
  }),
  singleDeactivateCardCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CARD_CATEGORY_REQUEST,
    payload: {cardCategoryId: [id]},
  }),

  sortCardCategory: (data: any) => ({
    type: actionTypes.SORT_CARD_CATEGORY_START,
    payload: data,
  }),
  sortCardCategorySuccess: (data: any) => ({
    type: actionTypes.SORT_CARD_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortCardCategoryFinish: () => ({
    type: actionTypes.SORT_CARD_CATEGORY_FINISH,
  }),
  sortCardCategoryReset: () => ({
    type: actionTypes.SORT_CARD_CATEGORY_RESET,
  }),
}
