import {columnModel} from 'src/cms/helpers/components/rsuiteTable/Models/columnModel'
import checkMultiLanguage from './checkMultiLanguage'

const checkMultiLanguageColumns = (columns: columnModel[]) => {
  const isSingleLanguage = checkMultiLanguage()
  let filterColumn = isSingleLanguage
    ? columns.filter((item) => item.isNepaliColumn !== true)
    : columns

  return filterColumn
}

export default checkMultiLanguageColumns
