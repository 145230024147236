import {ParamsModel} from 'src/app/modules/common/Model'
import { IExportFileState } from 'src/app/modules/network/components/branchless'
import {actionTypes} from './constants'
import {IReviewState} from './reducer'

export const actions = {
  // get Review
  getReview: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_REVIEW_RATING_START,
      payload: {params},
    }
  },
  getReviewSuccess: (data: IReviewState) => ({
    type: actionTypes.GET_REVIEW_RATING_SUCCESS,
    payload: data,
  }),
  getReviewError: (data: IReviewState) => ({
    type: actionTypes.GET_REVIEW_RATING_FINISH,
    payload: data,
  }),

  //Export file csv
  exportReviewFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_REVIEW_FILE_START,
      payload: {fileName, params},
    }
  },

  exportReviewFileSuccess: (data: IExportFileState) => ({
    type: actionTypes.EXPORT_REVIEW_FILE_SUCCESS,
    payload: data,
  }),
  exportReviewFileError: () => ({
    type: actionTypes.EXPORT_REVIEW_FILE_FINISH,
  }),

 
}
