import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
// rsuite
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import TrashIcon from '@rsuite/icons/Trash'
import axios from 'axios'
import {isEmpty} from 'lodash'
import {FiClipboard, FiEye, FiUserPlus, FiUserX, FiUsers} from 'react-icons/fi'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {careerValidation} from 'src/cms/helpers/constants'
import ChooseInterviewVenu from '../../shortlistedCandidatesForGroupDiscussion/component/ChooseInterviewVenu'
import ChooseExamCenter from './ChooseExamCenter'
import ViewCandidateDetails from './ViewCandidateDetails'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const Bank_Name = window.__RUNTIME_CONFIG__.REACT_BANK_NAME

const CandidatesList = () => {
  const [showViewCandidateDetailsModal, setShowViewCandidatesModal] = useState(false)
  const [showExaCenterModal, setShowExamCenterModal] = useState(false)
  const [showInterviewVenuModal, setShowInterviewVenuModal] = useState(false)
  const [selectedCandidateId, setSelectedCandidateId] = useState('')
  const [selectedCandidate, setSelectedCandidate] = useState<any>()
  const [selectedSatusId, setSelectedStatusId] = useState('')
  const parameters: {vacancyId: any} = useParams()
  const vacancyStateData: any = useHistory()
  const vacancyDetail = vacancyStateData?.location?.state

  const [data, setData] = useState<any>([])
  const [metaData, setMetaData] = useState({total: 0})
  const [loading, setLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [isDownloadingResumes, setIsDownloadingResumes] = useState(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    search: '',
    fromDate: '',
    toDate: '',
  })

  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [municipalityData, setMunicipalityData] = useState([])
  const [zoneData, setZoneData] = useState([])
  const [functionalAreaData, setFunctionalAreaData] = useState([])
  const [branchData, setBranchData] = useState([])
  const [departmentData, setDepartmentData] = useState([])
  const [examCenterData, setExamCentertData] = useState([])
  const [vacancyApplicantStatusData, setVacancyApplicantStatusData] = useState<any>([])

  const showExamCenterPopup = checkBackendSetting('showExamCenterPopup')
  const showInterviewVenuePopup = checkBackendSetting('showInterviewVenuePopup')

  useEffect(() => {
    axios.get(API_URL + '/province').then((res) => {
      setProvinceData(res.data.data.province)
    })
    axios.get(API_URL + '/district').then((res) => {
      setDistrictData(res.data.data.district)
    })
    axios.get(API_URL + '/zone').then((res) => {
      setZoneData(res.data.data.zone)
    })
    axios.get(API_URL + '/municipality').then((res) => {
      setMunicipalityData(res.data.data.municipality)
    })
    axios.get(API_URL + '/functionalarea').then((res) => {
      setFunctionalAreaData(res.data.data.functionalArea)
    })
    axios.get(API_URL + '/branch').then((res) => {
      setBranchData(res.data.data.branch)
    })
    axios.get(API_URL + '/department').then((res) => {
      setDepartmentData(res.data.data.department)
    })
    axios
      .get(API_URL + '/apply-vacancy/exam-center-list', {
        params: {vacancyId: parameters?.vacancyId},
      })
      .then((res) => {
        setExamCentertData(
          res.data.data.examCenter.map((itm: any) => {
            return {
              value: itm.id,
              label: itm.name,
            }
          })
        )
      })

    axios.get(API_URL + '/vacancy-applicant-status').then((res) => {
      setVacancyApplicantStatusData(res.data.data.vacancyApplicantStatus)
    })
  }, [])

  const getData = () => {
    setLoading(true)
    axios
      .get(
        API_URL +
          `/apply-vacancy?vacancyId=${parameters?.vacancyId}&page=${params.page}&limit=${params.limit}&search=${params.search}&fromDate=${params.fromDate}&toDate=${params.toDate}`
      )
      .then((res) => {
        setData(res.data.data.vacancyApplicant)
        setMetaData(res.data.data.meta)
        setLoading(false)
      })
  }
  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showProductAndInterest =
    settings?.data?.setting?.filter((d) => d.name === 'showPopularityAndInterest')[0]?.value === '1'
      ? true
      : false

  useEffect(() => {
    getData()
  }, [parameters?.vacancyId, params])

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  const ftpStorage = checkBackendSetting('ftpStorage')

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  //shortlisting functions
  const shortList = (applicantId: string, actionType: string) => {
    let selectedStatusId = vacancyApplicantStatusData.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id

    axios
      .put(API_URL + `/apply-vacancy/${applicantId}`, {
        vacancyApplicantStatusId: selectedStatusId,
      })
      .then((res) => {
        toast.success('Success.')
        const updatedApplicantIndex = data.findIndex((dta: any) => dta.id === applicantId)
        const updatedData = [...data]
        updatedData[updatedApplicantIndex] = res.data?.data
        setData(updatedData)
        getData()
      })
  }

  //shortlisting for exam
  const shortListExam = (applicantId: string, actionType: string) => {
    let selectedStatus = vacancyApplicantStatusData.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id
    setSelectedStatusId(selectedStatus)
  }

  // handle examcenter choose
  const handleSelectedRow = (rowData: any) => {
    if (vacancyDetail.examCenter && showExamCenterPopup) {
      setShowExamCenterModal(true)
      const a = [rowData].map((itm: any) => {
        return {value: itm.id, label: itm.name}
      })
      setSelectedCandidate(a)
      shortListExam(rowData?.id, 'shortlisted')
    } else {
      if (confirm('Are you sure?')) {
        shortList(rowData?.id, 'shortlisted')
      }
    }
  }
  //shortlisting for interview
  const shortListInterview = (applicantId: string, actionType: string) => {
    let selectedStatus = vacancyApplicantStatusData.filter(
      (d: {systemName: string; id: string}) => d.systemName === actionType
    )[0]?.id

    setSelectedStatusId(selectedStatus)
  }
  const handleSelectedforInterview = (rowData: any) => {
    if (showInterviewVenuePopup) {
      setShowInterviewVenuModal(true)
      const a = [rowData].map((itm: any) => {
        return {value: itm.id, label: itm.name}
      })

      setSelectedCandidate(a)
      shortListInterview(rowData?.id, 'shortlisted_for_interview')
    } else {
      if (confirm('Are you sure?')) {
        shortListInterview(rowData?.id, 'shortlisted_for_interview')
      }
    }
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <>
        {!showProductAndInterest ? (
          <Cell {...props} className='link-group'>
            <CheckPermissions type='Edit'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
                <IconButton
                  onClick={() => {
                    setShowViewCandidatesModal(true)
                    setSelectedCandidateId(rowData?.id)
                  }}
                  data-cy='edit-button'
                  appearance='subtle'
                  icon={<FiEye />}
                />
              </Whisper>
            </CheckPermissions>
            {careerValidation === 'true' ? (
              <>
                {vacancyDetail?.exam && (
                  <CheckPermissions type='Edit'>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>Shortlist for exam</Tooltip>}
                    >
                      <IconButton
                        onClick={() => {
                          handleSelectedRow(rowData)
                        }}
                        data-cy='shortlist-for-exam'
                        appearance='subtle'
                        icon={<FiClipboard />}
                      />
                    </Whisper>
                  </CheckPermissions>
                )}
                {vacancyDetail?.groupDiscussion && !vacancyDetail?.exam && (
                  <CheckPermissions type='Edit'>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>Shortlist for group discussion</Tooltip>}
                    >
                      <IconButton
                        onClick={() => {
                          if (confirm('Are you sure?')) {
                            shortList(rowData?.id, 'shortlisted_for_group_discussion')
                          }
                        }}
                        data-cy='shortlist-for-group-discussion'
                        appearance='subtle'
                        icon={<FiUsers />}
                      />
                    </Whisper>
                  </CheckPermissions>
                )}
                {vacancyDetail?.interview &&
                  !vacancyDetail?.exam &&
                  !vacancyDetail?.groupDiscussion && (
                    <CheckPermissions type='Edit'>
                      <Whisper
                        placement='top'
                        trigger='hover'
                        speaker={<Tooltip>Shortlist for interview</Tooltip>}
                      >
                        <IconButton
                          onClick={() => {
                            handleSelectedforInterview(rowData)
                          }}
                          data-cy='shortlist-for-interview'
                          appearance='subtle'
                          icon={<FiUsers />}
                        />
                      </Whisper>
                    </CheckPermissions>
                  )}
                {!vacancyDetail?.groupDiscussion &&
                  !vacancyDetail?.interview &&
                  !vacancyDetail?.exam && (
                    <CheckPermissions type='Edit'>
                      <Whisper placement='top' trigger='hover' speaker={<Tooltip>Onboard</Tooltip>}>
                        <IconButton
                          onClick={() => {
                            if (confirm('Are you sure?')) {
                              shortList(rowData?.id, 'onboarded')
                            }
                          }}
                          data-cy='onboard'
                          appearance='subtle'
                          icon={<FiUserPlus />}
                        />
                      </Whisper>
                    </CheckPermissions>
                  )}
              </>
            ) : (
              <>
                <CheckPermissions type='Edit'>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>Shortlist for exam</Tooltip>}
                  >
                    <IconButton
                      onClick={() => {
                        if (confirm('Are you sure?')) {
                          shortList(rowData?.id, 'shortlisted')
                        }
                      }}
                      data-cy='shortlist-for-exam-are-you-sure'
                      appearance='subtle'
                      icon={<FiClipboard />}
                    />
                  </Whisper>
                </CheckPermissions>
                <CheckPermissions type='Edit'>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>Shortlist for interview</Tooltip>}
                  >
                    <IconButton
                      onClick={() => {
                        if (confirm('Are you sure?')) {
                          shortList(rowData?.id, 'shortlisted_for_interview')
                        }
                      }}
                      data-cy='shortlist-for-interview'
                      appearance='subtle'
                      icon={<FiUsers />}
                    />
                  </Whisper>
                </CheckPermissions>
                <CheckPermissions type='Edit'>
                  <Whisper placement='top' trigger='hover' speaker={<Tooltip>Onboard</Tooltip>}>
                    <IconButton
                      onClick={() => {
                        if (confirm('Are you sure?')) {
                          shortList(rowData?.id, 'shortlisted_for_onboarding')
                        }
                      }}
                      data-cy='shortlisted-for-onboarding'
                      appearance='subtle'
                      icon={<FiUserPlus />}
                    />
                  </Whisper>
                </CheckPermissions>
              </>
            )}
            <CheckPermissions type='Edit'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>Reject</Tooltip>}>
                <IconButton
                  onClick={() => {
                    if (confirm('Are you sure?')) {
                      shortList(rowData?.id, 'rejected')
                    }
                  }}
                  data-cy='reject-are-you-sure'
                  appearance='subtle'
                  icon={<FiUserX />}
                />
              </Whisper>
            </CheckPermissions>
            <CheckPermissions type='Delete'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
                <IconButton
                  appearance='subtle'
                  onClick={() => {
                    handleAlertOpen()
                    let atmId = [rowData.id]

                    setCheckedValues(atmId)
                  }}
                  data-cy='delete-button'
                  icon={<TrashIcon />}
                />
              </Whisper>
            </CheckPermissions>
          </Cell>
        ) : (
          <Cell {...props} className='link-group'>
            <CheckPermissions type='Edit'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
                <IconButton
                  onClick={() => {
                    setShowViewCandidatesModal(true)
                    setSelectedCandidateId(rowData?.id)
                  }}
                  data-cy='view-details'
                  appearance='subtle'
                  icon={<FiEye />}
                />
              </Whisper>
            </CheckPermissions>
            {careerValidation === 'true' ? (
              <>
                {vacancyDetail?.exam && (
                  <CheckPermissions type='Edit'>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>Shortlist for exam</Tooltip>}
                    >
                      <IconButton
                        onClick={() => {
                          if (confirm('Are you sure?')) {
                            shortList(rowData?.id, 'shortlisted')
                          }
                        }}
                        data-cy='shortlist-for-exam-sure'
                        appearance='subtle'
                        icon={<FiClipboard />}
                      />
                    </Whisper>
                  </CheckPermissions>
                )}
                {vacancyDetail?.groupDiscussion && (
                  <CheckPermissions type='Edit'>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>Shortlist for group discussion</Tooltip>}
                    >
                      <IconButton
                        onClick={() => {
                          if (confirm('Are you sure?')) {
                            shortList(rowData?.id, 'shortlisted_for_group_discussion')
                          }
                        }}
                        data-cy='shortlist-for-group-discussion'
                        appearance='subtle'
                        icon={<FiUsers />}
                      />
                    </Whisper>
                  </CheckPermissions>
                )}
                {vacancyDetail?.interview && (
                  <CheckPermissions type='Edit'>
                    <Whisper
                      placement='top'
                      trigger='hover'
                      speaker={<Tooltip>Shortlist for interview</Tooltip>}
                    >
                      <IconButton
                        onClick={() => {
                          handleSelectedforInterview(rowData)
                        }}
                        data-cy='shortlist-for-interview-main'
                        appearance='subtle'
                        icon={<FiUsers />}
                      />
                    </Whisper>
                  </CheckPermissions>
                )}
                {!vacancyDetail?.groupDiscussion &&
                  !vacancyDetail?.interview &&
                  !vacancyDetail?.exam && (
                    <CheckPermissions type='Edit'>
                      <Whisper placement='top' trigger='hover' speaker={<Tooltip>Onboard</Tooltip>}>
                        <IconButton
                          onClick={() => {
                            if (confirm('Are you sure?')) {
                              shortList(rowData?.id, 'onboarded')
                            }
                          }}
                          data-cy='onboard-are-you-sure'
                          appearance='subtle'
                          icon={<FiUserPlus />}
                        />
                      </Whisper>
                    </CheckPermissions>
                  )}
              </>
            ) : (
              <>
                <CheckPermissions type='Edit'>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>Shortlist for exam</Tooltip>}
                  >
                    <IconButton
                      onClick={() => {
                        if (confirm('Are you sure?')) {
                          shortList(rowData?.id, 'shortlisted')
                        }
                      }}
                      data-cy='shortlist-for-exam-are-you'
                      appearance='subtle'
                      icon={<FiClipboard />}
                    />
                  </Whisper>
                </CheckPermissions>
                <CheckPermissions type='Edit'>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>Shortlist for interview</Tooltip>}
                  >
                    <IconButton
                      onClick={() => {
                        handleSelectedforInterview(rowData)
                      }}
                      data-cy='shortlist-for-interview-are-you-sure'
                      appearance='subtle'
                      icon={<FiUsers />}
                    />
                  </Whisper>
                </CheckPermissions>
                <CheckPermissions type='Edit'>
                  <Whisper placement='top' trigger='hover' speaker={<Tooltip>Onboard</Tooltip>}>
                    <IconButton
                      onClick={() => {
                        if (confirm('Are you sure?')) {
                          shortList(rowData?.id, 'shortlisted_for_onboarding')
                        }
                      }}
                      data-cy='onboard-are-you'
                      appearance='subtle'
                      icon={<FiUserPlus />}
                    />
                  </Whisper>
                </CheckPermissions>
              </>
            )}
            <CheckPermissions type='Edit'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>Reject</Tooltip>}>
                <IconButton
                  onClick={() => {
                    if (confirm('Are you sure?')) {
                      shortList(rowData?.id, 'rejected')
                    }
                  }}
                  data-cy='shortlist-for-exam-are-you-sure'
                  appearance='subtle'
                  icon={<FiUserX />}
                />
              </Whisper>
            </CheckPermissions>
            <CheckPermissions type='Delete'>
              <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
                <IconButton
                  appearance='subtle'
                  onClick={() => {
                    handleAlertOpen()
                    let atmId = [rowData.id]
                    setCheckedValues(atmId)
                  }}
                  data-cy='delete-button-subtle'
                  icon={<TrashIcon />}
                />
              </Whisper>
            </CheckPermissions>
          </Cell>
        )}
      </>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Unique Code',
      dataKey: 'uniqueCode',
      width: 115,
      cell: <Cell dataKey='uniqueCode' />,
      sortable: true,
    },
    {
      label: 'Name',
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
      sortable: true,
      flexGrow: 1,
    },
    {
      label: 'Email',
      dataKey: 'email',
      cell: <Cell dataKey='email' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Applied Date',
      dataKey: 'appliedDate',
      width: 100,
      cell: <Cell dataKey='appliedDate' />,
      sortable: false,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 70,
      cell: <Cell dataKey='status' />,
      sortable: false,
    },
    {
      label: 'Action',
      width: 220,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const vacancyData =
    data?.length > 0
      ? data?.map((item: any, i: number) => ({
          sn: (params?.page - 1) * params?.limit + (i + 1),
          id: item.id,
          jobCode: item.jobCode,
          slug: item.slug,
          name: item.name,
          uniqueCode: item.uniqueCode,
          appliedDate: item.appliedDate,
          email: item.email,
          status: item.vacancyApplicantStatus.name,
        }))
      : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }
  // const handleExport = () => {
  //     axios
  //       .get(
  //         API_URL +
  //           /apply-vacancy/export?vacancyId=${parameters.vacancyId}&fromDate=${params.fromDate}&toDate=${params.toDate}
  //       )
  //       .then((response) => {
  //         const blob = new Blob([response.data])
  //         const url = window.URL.createObjectURL(blob)
  //         const link = document.createElement('a')
  //         link.href = url
  //         link.setAttribute('download', 'vacancy-candidates.csv')
  //         document.body.appendChild(link)
  //         link.click()
  //       })
  //   }

  // const handleExport = async () => {
  //   try {
  //     setIsExporting(true)

  //     const exportResponse = await axios.get(
  //       `${API_URL}/apply-vacancy/background-export?vacancyId=${parameters.vacancyId}&fromDate=${params.fromDate}&toDate=${params.toDate}`
  //     )

  //     const fileName = exportResponse.data.data.fileName
  //     if (!fileName) {
  //       setIsExporting(false)
  //       return
  //     }

  //     let status = 'PENDING'
  //     let fileUrl = null

  //     while (status !== 'COMPLETED') {
  //       const statusResponse = await axios.get(
  //         `${API_URL}/apply-vacancy/check-export-file?fileName=${fileName}`
  //       )

  //       const responseStatus = statusResponse.data.data.status
  //       const responseUrl = statusResponse.data.data.url

  //       if (responseStatus === 'COMPLETED' && responseUrl) {
  //         status = 'COMPLETED'
  //         fileUrl = responseUrl
  //       } else if (responseStatus !== 'PROCESSING') {
  //         break
  //       }

  //       if (status !== 'COMPLETED') {
  //         await new Promise((resolve) => setTimeout(resolve, 10000))
  //       }
  //     }

  //     if (fileUrl) {
  //       const link = document.createElement('a')
  //       link.href = fileUrl
  //       link.download = fileName
  //       link.style.display = 'none'
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //     }
  //   } catch (error) {
  //     console.error('Error during export process:', error)
  //   } finally {
  //     setIsExporting(false)
  //   }
  // }
  const handleExport = async () => {
    if (Bank_Name === 'NIMB') {
      // Export logic for NIMBL
      try {
        setIsExporting(true)

        const exportResponse = await axios.get(
          `${API_URL}/apply-vacancy/background-export?vacancyId=${parameters.vacancyId}&fromDate=${params.fromDate}&toDate=${params.toDate}`
        )

        const fileName = exportResponse.data.data.fileName
        if (!fileName) {
          setIsExporting(false)
          return
        }

        let status = 'PENDING'
        let fileUrl = null

        while (status !== 'COMPLETED') {
          const statusResponse = await axios.get(
            `${API_URL}/apply-vacancy/check-export-file?fileName=${fileName}`
          )

          const responseStatus = statusResponse.data.data.status
          const responseUrl = statusResponse.data.data.url

          if (responseStatus === 'COMPLETED' && responseUrl) {
            status = 'COMPLETED'
            fileUrl = responseUrl
          } else if (responseStatus !== 'PROCESSING') {
            break
          }

          if (status !== 'COMPLETED') {
            await new Promise((resolve) => setTimeout(resolve, 10000))
          }
        }

        if (fileUrl) {
          const link = document.createElement('a')
          link.href = fileUrl
          link.download = fileName
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      } catch (error) {
        console.error('Error during export process:', error)
      } finally {
        setIsExporting(false)
      }
    } else {
      // Export logic for other banks
      axios
        .get(
          `${API_URL}/apply-vacancy/export?vacancyId=${parameters.vacancyId}&fromDate=${params.fromDate}&toDate=${params.toDate}`
        )
        .then((response) => {
          const blob = new Blob([response.data])
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'vacancy-candidates.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch((error) => {
          console.error('Error during export process:', error)
        })
    }
  }

  //Download resume

  const handleDownloadResumes = async () => {
    try {
      setIsDownloadingResumes(true)

      const response = await axios.get(
        `${API_URL}/apply-vacancy/get-all-resume?vacancyId=${parameters?.vacancyId}`
      )

      toast.info(response.data.resDesc)
    } catch (error) {
      console.error('Error requesting resume download:', error)
      toast.error('Failed to request resume download.')
    } finally {
      setIsDownloadingResumes(false)
    }
  }

  const handleDelete = () => {
    let vacancyId: any = checkedValues?.map((value) => ({id: value}))
    axios
      .delete(API_URL + '/apply-vacancy', {
        data: {
          vacancyApplicantId: vacancyId,
        },
      })
      .then((res) => {
        toast.success('Successfully deleted the candidate.')
        getData()
      })
      .catch((err) => {
        toast.error('Something went wrong while deleting the candidate.')
      })
    handleAlertClose()
  }

  const handleRefresh = () => {
    getData()
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleDownloadResume = () => {
    let applicantIds = ''
    if (checkedValues.length > 0) {
      for (let e in checkedValues) {
        //creating comma seperated Ids
        applicantIds = applicantIds ? applicantIds + ',' + checkedValues[e] : checkedValues[e]
      }
    }

    !isEmpty(checkedValues)
      ? axios
          .get(API_URL + `/apply-vacancy/get-resume?vacancyApplicantId=${applicantIds}`)
          .then((res) => {
            const link = document.createElement('a')
            link.href = res.data?.data?.fileName
            link.setAttribute('target', '_blank')
            document.body.appendChild(link)
            link.click()
            setCheckedValues([])
          })
          .catch((err) => toast.error(err.message))
      : toast.error('No data selected')
  }

  let inputTimer: any

  const changeButtonName = () => {
    switch (vacancyDetail.exam) {
      case true:
        return 'Shortlist for Exam'
      case false:
        switch (vacancyDetail.groupDiscussion) {
          case true:
            return 'Shortlist for Group Discussion'
          case false:
            switch (vacancyDetail.interview) {
              case true:
                return 'Shortlist for Interview'
              case false:
                return 'Onboard'
              default:
                return 'Onboard'
            }
        }
    }
  }

  const changeApplicantStatus = () => {
    switch (vacancyDetail.exam) {
      case true:
        return vacancyApplicantStatusData?.filter(
          (item: any) => item.systemName === 'shortlisted'
        )[0].id
      case false:
        switch (vacancyDetail.groupDiscussion) {
          case true:
            return vacancyApplicantStatusData?.filter(
              (item: any) => item.systemName === 'shortlisted_for_group_discussion'
            )[0].id
          case false:
            switch (vacancyDetail.interview) {
              case true:
                return vacancyApplicantStatusData?.filter(
                  (item: any) => item.systemName === 'shortlisted_for_interview'
                )[0].id
              case false:
                return vacancyApplicantStatusData?.filter(
                  (item: any) => item.systemName === 'onboarded'
                )[0].id
              default:
                return vacancyApplicantStatusData?.filter(
                  (item: any) => item.systemName === 'onboarded'
                )[0].id
            }
        }
    }
  }

  const shortListMultipleCandidates = () => {
    if (isEmpty(checkedValues)) {
      toast.error('No data Selected')
      return
    }

    if (vacancyDetail?.exam) {
      setShowExamCenterModal(true)
      return
    }
    if (!vacancyDetail?.exam && !vacancyDetail?.groupDiscussion && vacancyDetail.interview) {
      setShowInterviewVenuModal(true)
      return
    }

    const formData = checkedValues.map((applicantId: string) => ({
      vacancyApplicantId: applicantId,
      vacancyApplicantStatusId: changeApplicantStatus(),
    }))
    axios
      .put(API_URL + `/apply-vacancy/bulk-update-status`, {vacancyApplicantStatus: formData})
      .then((res) => {
        toast.success('Success.')
        setCheckedValues([])
        getData()
      })
  }
  const shortListForDiscussion = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((applicantId: string) => ({
        vacancyApplicantId: applicantId,
        vacancyApplicantStatusId: vacancyApplicantStatusData?.filter(
          (item: any) => item.systemName === 'shortlisted_for_group_discussion'
        )[0].id,
      }))
      axios
        .put(API_URL + `/apply-vacancy/bulk-update-status`, {vacancyApplicantStatus: formData})
        .then((res) => {
          toast.success('Success.')
          setCheckedValues([])
          getData()
        })
    } else {
      toast.error('No data Selected')
    }
  }
  const shortListForInterview = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((applicantId: string) => ({
        vacancyApplicantId: applicantId,
        vacancyApplicantStatusId: vacancyApplicantStatusData?.filter(
          (item: any) => item.systemName === 'shortlisted_for_interview'
        )[0].id,
      }))
      axios
        .put(API_URL + `/apply-vacancy/bulk-update-status`, {vacancyApplicantStatus: formData})
        .then((res) => {
          toast.success('Success.')
          setCheckedValues([])
          getData()
        })
    } else {
      toast.error('No data Selected')
    }
  }
  const rejectMultipleCandidates = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((applicantId: string) => ({
        vacancyApplicantId: applicantId,
        vacancyApplicantStatusId: vacancyApplicantStatusData?.filter(
          (item: any) => item.systemName === 'rejected'
        )[0].id,
      }))
      axios
        .put(API_URL + `/apply-vacancy/bulk-update-status`, {vacancyApplicantStatus: formData})
        .then((res) => {
          toast.success('Candidates Rejected.')
          setCheckedValues([])
          getData()
        })
    } else {
      toast.error('No data Selected')
    }
  }

  /**exam center upadate status */
  const handleStatusUpdate = (message: any) => {
    toast.success('Status update successfully')
    getData()
  }
  //update checkeddvalue and selected candidate on check utton click
  const handlecheck = (e: any) => {
    const row = vacancyData.filter((item: any) => e.includes(item.id))
    const ore = row.map((data: any) => {
      return {value: data.id, label: data.name}
    })
    setCheckedValues(e)
    setSelectedCandidate(ore)
    if (vacancyDetail.exam) {
      shortListExam(ore?.id, 'shortlisted')
    } else if (vacancyDetail.groupDiscussion) {
      shortListExam(ore?.id, 'shortlisted')
    } else {
      shortListInterview(ore?.id, 'shortlisted_for_interview')
    }
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Vacancy Candidates List'
          params={params}
          setParams={(data: any) => {
            const duration = 1000
            clearTimeout(inputTimer)
            inputTimer = setTimeout(() => {
              setParams(data)
            }, duration)
          }}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          handleExport={handleExport}
          isExporting={isExporting}
          handleDownloadAllResumes={handleDownloadResumes}
          isDownloadingAllResumes={isDownloadingResumes}
          Bank_Name={Bank_Name}
          importShow={false}
          toggleMultipleShow={careerValidation === 'true' ? true : false}
          activeButtonName={changeButtonName()}
          inactiveButtonName={'Reject Candidates'}
          enableMultiple={shortListMultipleCandidates}
          enableDiscussion={shortListForDiscussion}
          enableInterview={shortListForInterview}
          groupDiscussion={false}
          interview={false}
          disableMultiple={rejectMultipleCandidates}
          addShow={false}
          deleteShow={careerValidation === 'true' ? true : false}
          filterData={true}
          downloadResumeShow={ftpStorage ? false : true}
          handleDownloadResume={handleDownloadResume}
        />
        <div className='datatable'>
          <RSuiteTable
            onChecked={handlecheck}
            showCheckbox={true}
            columns={columns}
            data={vacancyData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />

          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={metaData?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />

          {showViewCandidateDetailsModal && (
            <ViewCandidateDetails
              handleClose={() => setShowViewCandidatesModal(false)}
              data={data?.filter((d: any) => d.id === selectedCandidateId)[0]}
              provinceList={provinceData}
              districtList={districtData}
              zoneList={zoneData}
              functionalAreaList={functionalAreaData}
              municipalityList={municipalityData}
              departmentList={departmentData}
              branchList={branchData}
            />
          )}
          {showExaCenterModal && (
            <ChooseExamCenter
              selectedSatusId={selectedSatusId}
              selectedCandidate={selectedCandidate}
              examCenterData={examCenterData}
              setShowExamCenterModal={setShowExamCenterModal}
              handleStatusUpdate={handleStatusUpdate}
              setSelectedCandidate={setSelectedCandidate}
            />
          )}
          {/* show Interview venu */}
          {showInterviewVenuModal && (
            <ChooseInterviewVenu
              selectedSatusId={selectedSatusId}
              selectedCandidate={selectedCandidate}
              setShowInterviewVenuModal={setShowInterviewVenuModal}
              handleStatusUpdate={handleStatusUpdate}
              setSelectedCandidate={setSelectedCandidate}
            />
          )}

          {/* Delete Modal */}
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </div>
      </div>
    </>
  )
}

export default CandidatesList
