import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get AppointmentReason saga
function* getAppointmentReasonSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAppointmentReasonData, params)
    const data: any = response?.data
    yield put(actions.getAppointmentReasonSuccess(data))
  } catch (error: any) {
    yield put(actions.getAppointmentReasonError(error))
  }
}

//Get All AppointmentReason saga
function* getAllAppointmentReasonSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAppointmentReasonList)
    const data: any = response?.data
    yield put(actions.getAllAppointmentReasonSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllAppointmentReasonError(error))
  }
}

//Create AppointmentReason
function* createAppointmentReasonSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.addAppointmentReason, body)
    yield put({type: actionTypes.ADD_APPOINTMENT_REASON_SUCCESS, payload: response.data})
    yield put({type: actionTypes.ADD_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_APPOINTMENT_REASON_FINISH})
  }
}

//Update AppointmentReason
function* updateAppointmentReason(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateAppointmentReason,
      body,
      action.payload?.id
    )
    yield put({type: actionTypes.UPDATE_APPOINTMENT_REASON_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_APPOINTMENT_REASON_FINISH})
  }
}

//Activate Deactivate AppointmentReason Saga
function* activateAppointmentReasonSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateAppointmentReason, selectedBanners)
    yield put({type: actionTypes.ACTIVATE_APPOINTMENT_REASON_SUCCESS, payload: response?.data})
    yield put({type: actionTypes.ACTIVATE_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_APPOINTMENT_REASON_FINISH})
  }
}

function* singleActivateAppointmentReasonSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleActivateAppointmentReason,
      selectedBanner
    )

    yield put({
      type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_SUCCESS,
      payload: response?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_FINISH})
  }
}

function* deactivateAppointmentReasonSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateAppointmentReason, selectedBanner)

    yield put({type: actionTypes.DEACTIVATE_APPOINTMENT_REASON_SUCCESS, payload: response?.data})
    yield put({type: actionTypes.DEACTIVATE_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DEACTIVATE_APPOINTMENT_REASON_FINISH})
  }
}

function* singleDeactivateAppointmentReasonSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDeactivateAppointmentReason,
      selectedBanner
    )
    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_SUCCESS,
      payload: response?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_FINISH})
  }
}

function* deleteAppointmentReasonSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBulkAppointmentReason, body)
    yield put({type: actionTypes.DELETE_APPOINTMENT_REASON_SUCCESS})
    yield put({type: actionTypes.DELETE_APPOINTMENT_REASON_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_APPOINTMENT_REASON_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_APPOINTMENT_REASON_START, getAppointmentReasonSaga)
  yield takeLatest(actionTypes.GET_ALL_APPOINTMENT_REASON_START, getAllAppointmentReasonSaga)
  yield takeLatest(actionTypes.ADD_APPOINTMENT_REASON_REQUEST, createAppointmentReasonSaga)
  yield takeLatest(actionTypes.UPDATE_APPOINTMENT_REASON_START, updateAppointmentReason)
  yield takeLatest(actionTypes.DELETE_APPOINTMENT_REASON_START, deleteAppointmentReasonSaga)
  yield takeLatest(actionTypes.ACTIVATE_APPOINTMENT_REASON_REQUEST, activateAppointmentReasonSaga)
  yield takeLatest(
    actionTypes.DEACTIVATE_APPOINTMENT_REASON_REQUEST,
    deactivateAppointmentReasonSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_REQUEST,
    singleActivateAppointmentReasonSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_REQUEST,
    singleDeactivateAppointmentReasonSaga
  )
}
