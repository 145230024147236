export const actionTypes = {
  // get
  GET_ALL_PROGRAM_CATEGORY_START: 'GET_ALL_PROGRAM_CATEGORY_START',
  GET_ALL_PROGRAM_CATEGORY_SUCCESS: 'GET_ALL_PROGRAM_CATEGORY_SUCCESS',
  GET_ALL_PROGRAM_CATEGORY_FINISH: 'GET_ALL_PROGRAM_CATEGORY_FINISH',

  // extended hours
  GET_PROGRAM_CATEGORY_START: 'GET_PROGRAM_CATEGORY_START',
  GET_PROGRAM_CATEGORY_SUCCESS: 'GET_PROGRAM_CATEGORY_SUCCESS',
  GET_PROGRAM_CATEGORY_FINISH: 'GET_PROGRAM_CATEGORY_FINISH',

  // add branch
  ADD_PROGRAM_CATEGORY_START: 'ADD_PROGRAM_CATEGORY_START',
  ADD_PROGRAM_CATEGORY_SUCCESS: 'ADD_PROGRAM_CATEGORY_SUCCESS',
  ADD_PROGRAM_CATEGORY_FINISH: 'ADD_PROGRAM_CATEGORY_FINISH',
  RESET_PROGRAM_TAG: 'RESET_PROGRAM_TAG',

  // update PROGRAM_TAG
  UPDATE_PROGRAM_CATEGORY_START: 'UPDATE_PROGRAM_CATEGORY_START',
  UPDATE_PROGRAM_CATEGORY_SUCCESS: 'UPDATE_PROGRAM_CATEGORY_SUCCESS',
  UPDATE_PROGRAM_CATEGORY_FINISH: 'UPDATE_PROGRAM_CATEGORY_FINISH',

  // delete PROGRAM_TAG
  DELETE_PROGRAM_CATEGORY_START: 'DELETE_PROGRAM_CATEGORY_START',
  DELETE_PROGRAM_CATEGORY_SUCCESS: 'DELETE_PROGRAM_CATEGORY_SUCCESS',
  DELETE_PROGRAM_CATEGORY_FINISH: 'DELETE_PROGRAM_CATEGORY_FINISH',

  // Enable PROGRAM_TAG
  ENABLE_PROGRAM_CATEGORY_REQUEST: 'ENABLE_PROGRAM_CATEGORY_REQUEST',
  ENABLE_PROGRAM_CATEGORY_SUCCESS: 'ENABLE_PROGRAM_CATEGORY_SUCCESS',
  ENABLE_PROGRAM_CATEGORY_FINISH: 'ENABLE_PROGRAM_CATEGORY_FINISH',

  // Disable PROGRAM_TAG
  DISABLE_PROGRAM_CATEGORY_REQUEST: 'DISABLE_PROGRAM_CATEGORY_REQUEST',
  DISABLE_PROGRAM_CATEGORY_SUCCESS: 'DISABLE_PROGRAM_CATEGORY_SUCCESS',
  DISABLE_PROGRAM_CATEGORY_FINISH: 'DISABLE_PROGRAM_CATEGORY_FINISH',

  // Enable PROGRAM_TAG
  SINGLE_ENABLE_PROGRAM_CATEGORY_REQUEST: 'SINGLE_ENABLE_PROGRAM_CATEGORY_REQUEST',
  SINGLE_ENABLE_PROGRAM_CATEGORY_SUCCESS: 'SINGLE_ENABLE_PROGRAM_CATEGORY_SUCCESS',
  SINGLE_ENABLE_PROGRAM_CATEGORY_FINISH: 'SINGLE_ENABLE_PROGRAM_CATEGORY_FINISH',

  // Disable PROGRAM_TAG
  SINGLE_DISABLE_PROGRAM_CATEGORY_REQUEST: 'SINGLE_DISABLE_PROGRAM_CATEGORY_REQUEST',
  SINGLE_DISABLE_PROGRAM_CATEGORY_SUCCESS: 'SINGLE_DISABLE_PROGRAM_CATEGORY_SUCCESS',
  SINGLE_DISABLE_PROGRAM_CATEGORY_FINISH: 'SINGLE_DISABLE_PROGRAM_CATEGORY_FINISH',

  // sort
  SORT_PROGRAM_CATEGORY_START: 'SORT_PROGRAM_CATEGORY_START',
  SORT_PROGRAM_CATEGORY_SUCCESS: 'SORT_PROGRAM_CATEGORY_SUCCESS',
  SORT_PROGRAM_CATEGORY_FINISH: 'SORT_PROGRAM_CATEGORY_FINISH',
  SORT_PROGRAM_CATEGORY_RESET: 'SORT_PROGRAM_CATEGORY_RESET',
}
