import {Action} from 'redux'
import {AlgoliaSearchModel} from '../Model/AlgoliaSearchModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAlgoliaSearchState: IAlgoliaSearchState = {
  data: {
    algoliaSearch: [],
    meta: [],
  },
  sortAlgoliaSearchData: [],
  algoliaSearchList: {algoliaSearch: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IAlgoliaSearchState {
  data?: {
    algoliaSearch?: AlgoliaSearchModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortAlgoliaSearchData?: IAlgoliaSearchState[]
  algoliaSearchList?: {algoliaSearch: AlgoliaSearchModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IAlgoliaSearchState = initialAlgoliaSearchState,
  action: ActionWithPayload<IAlgoliaSearchState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_ALGOLIA_SEARCH_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        algoliaSearchList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_ALGOLIA_SEARCH_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET AlgoliaSearch DATA
    case actionTypes.GET_ALGOLIA_SEARCH_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALGOLIA_SEARCH_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ALGOLIA_SEARCH_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add AlgoliaSearch
    case actionTypes.ADD_ALGOLIA_SEARCH_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_ALGOLIA_SEARCH_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_SEARCH_TAG: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update AlgoliaSearch
    case actionTypes.UPDATE_ALGOLIA_SEARCH_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_ALGOLIA_SEARCH_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_ALGOLIA_SEARCH_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_ALGOLIA_SEARCH_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_ALGOLIA_SEARCH_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_ALGOLIA_SEARCH_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_ALGOLIA_SEARCH_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_ALGOLIA_SEARCH_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_SUCCESS: {
      const changedData: any = action.payload?.data?.algoliaSearch
      let newData = state?.data?.algoliaSearch?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, algoliaSearch: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_SUCCESS: {
      const changedData: any = action.payload?.data?.algoliaSearch
      let newData = state?.data?.algoliaSearch?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, algoliaSearch: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_ALGOLIA_SEARCH_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_ALGOLIA_SEARCH_SUCCESS: {
      return {
        ...state,
        sortAlgoliaSearchData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_ALGOLIA_SEARCH_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_ALGOLIA_SEARCH_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortAlgoliaSearchData: [],
      }
    }

    default:
      return state
  }
}
