export const actionTypes = {
  // get branchless banking
  GET_BRANCHLESS_BANKING_START: 'GET_BRANCHLESS_BANKING_START',
  GET_BRANCHLESS_BANKING_SUCCESS: 'GET_BRANCHLESS_BANKING_SUCCESS',
  GET_BRANCHLESS_BANKING_FINISH: 'GET_BRANCHLESS_BANKING_FINISH',

  // ADD branchless banking
  ADD_BRANCHLESS_BANKING_START: 'ADD_BRANCHLESS_BANKING_START',
  ADD_BRANCHLESS_BANKING_SUCCESS: 'ADD_BRANCHLESS_BANKING_SUCCESS',
  ADD_BRANCHLESS_BANKING_FINISH: 'ADD_BRANCHLESS_BANKING_FINISH',
  ADD_BRANCHLESS_BANKING_RESET: 'ADD_BRANCHLESS_BANKING_RESET',

  // Update branchless banking
  UPDATE_BRANCHLESS_BANKING_START: 'UPDATE_BRANCHLESS_BANKING_START',
  UPDATE_BRANCHLESS_BANKING_SUCCESS: 'UPDATE_BRANCHLESS_BANKING_SUCCESS',
  UPDATE_BRANCHLESS_BANKING_FINISH: 'UPDATE_BRANCHLESS_BANKING_FINISH',
  UPDATE_BRANCHLESS_BANKING_RESET: 'UPDATE_BRANCHLESS_BANKING_RESET',

  // delete branchless banking
  DELETE_BRANCHLESS_BANKING_START: 'DELETE_BRANCHLESS_BANKING_START',
  DELETE_BRANCHLESS_BANKING_SUCCESS: 'DELETE_BRANCHLESS_BANKING_SUCCESS',
  DELETE_BRANCHLESS_BANKING_FINISH: 'DELETE_BRANCHLESS_BANKING_FINISH',

  //Bulk Activate Branchless Banking
  ACTIVATE_BRANCHLESS_BANKING_REQUEST: 'ACTIVATE_BRANCHLESS_BANKING_REQUEST',
  ACTIVATE_BRANCHLESS_BANKING_SUCCESS: 'ACTIVATE_BRANCHLESS_BANKING_SUCCESS',
  ACTIVATE_BRANCHLESS_BANKING_FINISH: 'ACTIVATE_BRANCHLESS_BANKING_FINISH',

  //Bulk Deactivate Branchless Banking
  DEACTIVATE_BRANCHLESS_BANKING_REQUEST: 'DEACTIVATE_BRANCHLESS_BANKING_REQUEST',
  DEACTIVATE_BRANCHLESS_BANKING_SUCCESS: 'DEACTIVATE_BRANCHLESS_BANKING_SUCCESS',
  DEACTIVATE_BRANCHLESS_BANKING_FINISH: 'DEACTIVATE_BRANCHLESS_BANKING_FINISH',

  // single active branchless banking
  SINGLE_ACTIVATE_BRANCHLESS_BANKING_REQUEST: 'SINGLE_ACTIVATE_BRANCHLESS_BANKING_REQUEST',
  SINGLE_ACTIVATE_BRANCHLESS_BANKING_SUCCESS: 'SINGLE_ACTIVATE_BRANCHLESS_BANKING_SUCCESS',
  SINGLE_ACTIVATE_BRANCHLESS_BANKING_FINISH: 'SINGLE_ACTIVATE_BRANCHLESS_BANKING_FINISH',

  // single deactive branchless banking
  SINGLE_DEACTIVATE_BRANCHLESS_BANKING_REQUEST: 'SINGLE_DEACTIVATE_BRANCHLESS_BANKING_REQUEST',
  SINGLE_DEACTIVATE_BRANCHLESS_BANKING_SUCCESS: 'SINGLE_DEACTIVATE_BRANCHLESS_BANKING_SUCCESS',
  SINGLE_DEACTIVATE_BRANCHLESS_BANKING_FINISH: 'SINGLE_DEACTIVATE_BRANCHLESS_BANKING_FINISH',

  // get File csv xlsx
  EXPORT_BRANCHLESS_FILE_START: 'EXPORT_BRANCHLESS_FILE_START',
  EXPORT_BRANCHLESS_FILE_SUCCESS: 'EXPORT_BRANCHLESS_FILE_SUCCESS',
  EXPORT_BRANCHLESS_FILE_FINISH: 'EXPORT_BRANCHLESS_FILE_FINISH',

  //import brancheless banking
  IMPORT_BRANCHLESS_BANKING_START: 'IMPORT_BRANCHLESS_BANKING_START',
  IMPORT_BRANCHLESS_BANKING_SUCCESS: 'IMPORT_BRANCHLESS_BANKING_SUCCESS',
  IMPORT_BRANCHLESS_BANKING_FINISH: 'IMPORT_BRANCHLESS_BANKING_FINISH',

  //EPORT
  EXPORT_BRANCHLESS_TEMPLATE_FILE_START: 'EXPORT_BRANCHLESS_TEMPLATE_FILE_START',
  EXPORT_BRANCHLESS_TEMPLATE_FILE_SUCCESS: 'EXPORT_BRANCHLESS_TEMPLATE_FILE_SUCCESS',
  EXPORT_BRANCHLESS_TEMPLATE_FILE_FINISH: 'EXPORT_BRANCHLESS_TEMPLATE_FILE_FINISH',
}
