import { ErrorMessage, Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

import { isEmpty } from 'lodash'
import { StateParamsModel } from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as branch from '../../branch'
import { BranchModel } from '../../branch/Model/BranchModel'
import * as experienceManager from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  branchId: Yup.string().required('Branch is required'),
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid Email. Eg: example@xyz.com')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please provide valid email')
    .required('Email is required'),
  managerPhone: Yup.string()
    .matches(/^9\d{9}$/, 'Please enter valid phone number')
    .required('Manager phone is required'),
  telephone: Yup.string().required('Telephone is required'),
  status: Yup.string().required('Status is required'),
})

const AddCategory = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const { loading, success } = useSelector((state: any) => state.experienceManager)
  const { data } = useSelector((state: any) => state.branch)

  useEffect(() => {
    dispatch(branch?.actions.getAllBranchList())
  }, [])

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  const hideInWebsiteOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ]

  const branchOptions = data?.branch?.map((items: BranchModel) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(experienceManager?.actions.getExperienceManager(params))
      isEmpty(editSelectedData)
        ? toast.success('Experience Manager added successfully')
        : toast.success('Experience Manager edited successfully')
      dispatch(experienceManager?.actions?.addExperienceManagerReset())
      handleClose()
    }
  }, [success])

  const addRemoveMaster = (e: any, master: any, values: any, setFieldValue: Function) => {
    if (e.target.checked) {
      //adding data to masters array if it doesn't already exists there
      const masterAlreadyExists =
        values.masters.findIndex((d: { id: string }) => d.id === master.id) >= 0
      if (!masterAlreadyExists) {
        const updatedMasters = [
          ...values.masters,
          { id: master.id, status: master.status, value: '' },
        ]
        setFieldValue('masters', updatedMasters)
      }
    } else {
      const updatedMastersData = values.masters.filter((d: { id: string }) => d.id !== master.id)
      setFieldValue('masters', updatedMastersData)
    }
  }

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Experience Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                branchId: '',
                name: '',
                nameNp: '',
                email: '',
                managerPhone: '',
                telephone: '',
                status: 'Active',
                hideInWebsite: 'no',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { setSubmitting }) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  hideInWebsite: values?.hideInWebsite === 'yes' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    experienceManager.actions.updateExperienceManager(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(experienceManager.actions.addExperienceManager(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'nameNp',
                      'email',
                      'managerPhone',
                      'telephone',
                      'status',
                      'hideInWebsite',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('branchId', editSelectedData?.branch, false)
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'hideInWebsite',
                      editSelectedData?.hideInWebsite === true ? 'yes' : 'no',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select'
                              label='Branch'
                              name='branchId'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={branchOptions}
                              required
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Name (E.N)'
                            label='Name (E.N)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Name (N.P)'
                            label='Name (N.P)'
                            name='nameNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Email'
                            label='Email'
                            name='email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Telephone'
                            label='Telephone'
                            name='telephone'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Manager Phone'
                            label='Manager Phone'
                            name='managerPhone'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Hide in Website ?
                          </label>
                          <div className='d-flex ms-5'>
                            {hideInWebsiteOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                containerClassName=''
                                label='Hide in Website ?'
                                name='hideInWebsite'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddCategory
