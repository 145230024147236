import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

import axios from 'axios'
import {FiEye} from 'react-icons/fi'
import {SortType} from 'rsuite-table/lib/@types/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {IBenefitListManagerState} from '../redux'
import {getBenfitListDetails} from '../redux/action'
import ViewDetailsModal from './ViewDetailsModal'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const BenefitCalculatorListManager = () => {
  const dispatch = useDispatch()

  const {
    data: benefitListData,
    loading,
    deleteSuccess,
    success,
  } = useSelector((state: any) => state.benefitCalculatorListManager)

  const BenefitListData: IBenefitListManagerState = useSelector(
    (state: any) => state.benefitCalculatorListManager
  )

  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState<any>([])

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)

  useEffect(() => {
    dispatch(getBenfitListDetails(params))
  }, [params])

  const handleRefresh = () => {
    dispatch(getBenfitListDetails(params))
  }

  const downloadCustomerLoginData = () => {
    const GET_CUSTOMER_LOGIN_DATA = `${API_URL}/benefit-calculator/export?search=${params.search}`
    axios(GET_CUSTOMER_LOGIN_DATA, {
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `benefitListData.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              data-cy='edit-button'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Full Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Mobile No',
      dataKey: 'mobileNumber',
      flexGrow: 1,
      cell: <Cell dataKey='mobileNumber' />,
      sortable: true,
    },
    {
      label: 'Product',
      dataKey: 'product',
      flexGrow: 1,
      cell: <Cell dataKey='product' />,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const data = BenefitListData?.data?.benefitCalculator
    ? BenefitListData?.data?.benefitCalculator?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        name: item.name,
        mobileNumber: item.mobileNumber,
        product: item?.product?.title,
      }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Benefit Calculator Details'
          addShow={false}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={false}
          exportButtonName='Export CSV'
          handleExport={downloadCustomerLoginData}
          toggleMultipleShow={false}
          deleteShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={BenefitListData?.data?.benefitCalculator?.length || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}
      </div>
    </>
  )
}

export default BenefitCalculatorListManager
