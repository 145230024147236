import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialServicePartnerStateRate: IServicePartnerStateRate = {
  data: {
    exchangeRate: [],
    meta: {},
  },

  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  downloadLoading: false,
  downloadSuccess: false,
  importLoading: false,
  importSuccess: false,
  loading: false,
  success: false,
  editSuccess: false,
}

export interface IServicePartnerStateRate {
  data?: {
    exchangeRate?: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }

  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  downloadLoading?: boolean
  downloadSuccess?: boolean
  importLoading?: boolean
  importSuccess?: boolean
  loading?: boolean
  success?: boolean
  editSuccess?: boolean
}

export const reducer = (
  state: IServicePartnerStateRate = initialServicePartnerStateRate,
  action: ActionWithPayload<IServicePartnerStateRate>
) => {
  switch (action.type) {
    //GET SERVICE PARTNER Data
    case actionTypes.GET_EXCHANGE_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_EXCHANGE_RATE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_EXCHANGE_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add SERVICE PARTNER
    case actionTypes.ADD_EXCHANGE_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_EXCHANGE_RATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        editSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ADD_EXCHANGE_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_EXCHANGE_RATE_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
        editSuccess: false,
      }
    }

    //Update SERVICE PARTNER
    case actionTypes.UPDATE_EXCHANGE_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_EXCHANGE_RATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        editSuccess: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_EXCHANGE_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_EXCHANGE_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_EXCHANGE_RATE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_EXCHANGE_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_EXCHANGE_RATE_REQUEST: {
      return {...state, loading: true, enableSuccess: false}
    }

    case actionTypes.ENABLE_EXCHANGE_RATE_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_EXCHANGE_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_EXCHANGE_RATE_REQUEST: {
      return {...state, loading: true, disableSuccess: false}
    }

    case actionTypes.DISABLE_EXCHANGE_RATE_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_EXCHANGE_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_SUCCESS: {
      const changedData: any = action.payload?.data?.exchangeRate
      let newData = state?.data?.exchangeRate?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state?.data?.meta, exchangeRate: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_SUCCESS: {
      const changedData: any = action.payload?.data?.exchangeRate
      let newData = state?.data?.exchangeRate?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state?.data?.meta, exchangeRate: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }
    //Download
    case actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_REQUEST: {
      return {...state, downloadLoading: true}
    }

    case actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_SUCCESS: {
      return {...state, downloadLoading: false}
    }

    case actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_FINISH: {
      return {...state, downloadLoading: false}
    }
    //Import
    case actionTypes.IMPORT_EXCHANGE_RATE_REQUEST: {
      return {...state, data: action.payload, importLoading: true, importSuccess: false}
    }

    case actionTypes.IMPORT_EXCHANGE_RATE_SUCCESS: {
      return {...state, importLoading: false, success: true, importSuccess: true}
    }

    case actionTypes.IMPORT_EXCHANGE_RATE_FINISH: {
      return {...state, importLoading: false, importSuccess: false}
    }

    default:
      return state
  }
}

//For file uplaod
const initialExchangeRateFileState: IExchangeRateFileState = {
  data: {
    file: {},
  },
  loading: false,
  success: false,
}

export interface IExchangeRateFileState {
  data: {
    file: {[key: string]: number}
  }
  loading?: boolean
  success?: boolean
}

export const reducer1 = (
  state: IExchangeRateFileState = initialExchangeRateFileState,
  action: ActionWithPayload<IExchangeRateFileState>
) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
