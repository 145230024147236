import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ApiManagerDetailModel} from '../../apiManager/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_API_MANAGEMENT = `${API_URL}/api-manager`


export const service = {
  getApiManagerDetail: (params: ParamsModel) => {
    return axios.get(GET_API_MANAGEMENT, {params})
  },

  addApiManagerDetail: (data: any) => {
    return axios.post(GET_API_MANAGEMENT, data)
  },

  updateApiManagerDetail: (body: ApiManagerDetailModel, id: string) => {
    return axios.put(`${GET_API_MANAGEMENT}/${id}`, body)
  },

  // deleteCardMenuDetail: (data: DeleteCardMenuDetailModel) => {
  //   return axios.delete(`${GET_CARD_MENU}/bulk-delete`, {data})
  // },

  // enableCardMenuDetail: (data: Array<string>) => {
  //   const selectedCardMenuDetail = {
  //     cardMenuId: data,
  //   }
  //   return axios.patch(`${GET_CARD_MENU}/enable`, selectedCardMenuDetail)
  // },

  // disableCardMenuDetail: (data: Array<string>) => {
  //   const selectedCardMenuDetail = {
  //     cardMenuId: data,
  //   }
  //   return axios.patch(`${GET_CARD_MENU}/disable`, selectedCardMenuDetail)
  // },

  // singleEnableCardMenuDetail: (data: Array<string>) => {
  //   const selectedCardMenuDetail = {
  //     cardMenuId: [data],
  //   }
  //   return axios.patch(`${GET_CARD_MENU}/enable`, selectedCardMenuDetail)
  // },

  // singleDisableCardMenuDetail: (data: Array<string>) => {
  //   const selectedCardMenuDetail = {
  //     cardMenuId: [data],
  //   }
  //   return axios.patch(`${GET_CARD_MENU}/disable`, selectedCardMenuDetail)
  // },

  // getAllCardMenuDetail: () => {
  //   return axios.get(`${GET_CARD_MENU}/list`)
  // },
  // sortCardMenuDetail: (body: SortCardMenuDetailModel) => {
  //   return axios.patch(`${GET_CARD_MENU}/sort`, body)
  // },
}
