import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as place from 'src/app/modules/common'
import MapView from 'src/app/modules/common/components/Map/MapView'
import getBackendSettingValue from 'src/app/modules/common/getBackendSetting'
import {StateParamsModel} from 'src/app/modules/common/Model'
import {branch} from 'src/app/modules/network'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as auctionRedux from '../index'
import {PlaceModel} from '../Model/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {IAuthState} from 'src/app/modules/auth'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddAuction = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [provinceValue, setProvinceValue] = useState('')
  const [locationCategoryValue, setLocationCategoryValue] = useState('')
  const [valleyProvinceOptions, setValleyProvinceOptions] = useState([])
  const [valleyDistrictOptions, setValleyDistrictOptions] = useState([])
  const [attachedFile, setAttachedFile] = useState('')

  const {loading, success, auctionTypeList, propertyTypeList, auctionImageTypeList} = useSelector(
    (state: any) => state.auction
  )
  const {category} = useSelector((state: any) => state.branch)
  const {district, province} = useSelector((state: any) => state.place)
  const auth: IAuthState = useSelector((state: any) => state.auth)

  const isAuctionMaker = auth?.rolePermissions?.name === 'Auction Maker'

  const defaultLongitude = getBackendSettingValue('defaultLongitude')
  const defaultLatitude = getBackendSettingValue('defaultLatitude')

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const YesNoOptions = [
    {label: 'YES', value: 'true'},
    {label: 'NO', value: 'false'},
  ]

  const OptionsDropdown = (data: any) => {
    const responseData = data?.map((item: any) => {
      return {
        label: item?.name || item?.title || item?.displayName,
        value: item?.id,
        systemName: item?.systemName,
      }
    })
    return responseData ?? []
  }

  useMemo(() => {
    dispatch(setting.actions.getSettingType())
    dispatch(auctionRedux.actions.getAuctionType())
    dispatch(auctionRedux.actions.getPropertyTypeList())
    dispatch(auctionRedux.actions.getAuctionImageTypeList())
    dispatch(branch.actions.getBranchCategory())
    dispatch(place.action.getProvince())
  }, [])

  useMemo(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
    }
  }, [editSelectedData])

  useEffect(() => {
    if (provinceValue) {
      dispatch(place.action.getDistrict(provinceValue))
    }
  }, [provinceValue])

  useEffect(() => {
    if (locationCategoryValue === 'e142aa7f-d275-43cf-87a7-503872a9274a') {
      const provinceOptions = province
        ?.filter((d: any) => d.title === 'Bagmati Pradesh')
        .map((items: PlaceModel) => ({
          label: items.title,
          value: items.id,
        }))
      setValleyProvinceOptions(provinceOptions)
    } else {
      const provinceOptions = province?.map((items: PlaceModel) => ({
        label: items.title,
        value: items.id,
      }))
      setValleyProvinceOptions(provinceOptions)
    }
  }, [locationCategoryValue])

  useEffect(() => {
    if (!isEmpty(locationCategoryValue) && !isEmpty(provinceValue)) {
      if (locationCategoryValue === 'e142aa7f-d275-43cf-87a7-503872a9274a') {
        const districtOptions = district
          ?.filter((d: any) => d.categoryId === 'e142aa7f-d275-43cf-87a7-503872a9274a')
          .map((items: PlaceModel) => ({
            label: items.title,
            value: items.id,
          }))
        setValleyDistrictOptions(districtOptions)
      } else {
        const districtOptions = district
          ?.filter((d: any) => d.categoryId !== 'e142aa7f-d275-43cf-87a7-503872a9274a')
          .map((items: PlaceModel) => ({
            label: items.title,
            value: items.id,
          }))
        setValleyDistrictOptions(districtOptions)
      }
    }
  }, [locationCategoryValue, provinceValue, district])

  useEffect(() => {
    if (!isEmpty(editSelectedData)) {
      setProvinceValue(editSelectedData?.province?.id)
      setLocationCategoryValue(editSelectedData?.addressCategory?.id)
    }
  }, [editSelectedData])

  useEffect(() => {
    if (success) {
      dispatch(auctionRedux?.actions.getAuction(params))
      isEmpty(editSelectedData)
        ? toast.success('Auction added successfully')
        : toast.success('Auction edited successfully')
      dispatch(auctionRedux?.actions?.resetAuction())
      handleClose()
    }
  }, [success])

  const disableNBADropdown = checkBackendSetting('disableNBADropdown')
  const showAuctionDescription = checkBackendSetting('showAuctionDescription')
  const showAuctionAttachment = checkBackendSetting('showAuctionAttachment')
  const showAddress1Field = checkBackendSetting('showAddress1Field')
  const showAddress2Field = checkBackendSetting('showAddress2Field')
  const isLongituteNotRequired = checkBackendSetting('isLongituteNotRequired')
  const isLatitudeNotRequired = checkBackendSetting('isLatitudeNotRequired')
  const isStartTimeNotRequired = checkBackendSetting('isStartTimeNotRequired')
  const isEndTimeNotRequired = checkBackendSetting('isEndTimeNotRequired')
  const isStartDateNotRequired = checkBackendSetting('isStartDateNotRequired')
  const isEndDateNotRequired = checkBackendSetting('isEndDateNotRequired')
  const disableStartTimeField = checkBackendSetting('disableStartTimeField')
  const disableEndTimeField = checkBackendSetting('disableEndTimeField')
  const isAssetType = checkBackendSetting('isAssetType')
  const enableMakerChecker = checkBackendSetting('enableMakerChecker')
  const isAuctionSlugFieldEditable = checkBackendSetting('isAuctionSlugFieldEditable')
  const enableConditionalDateFieldRendering = checkBackendSetting(
    'enableConditionalDateFieldRenderingBasedOnAuctionTypes'
  )
  const auctionEnableContactPerson = checkBackendSetting('auctionEnableContactPerson')
  const auctionPrimaryImage = checkBackendSetting('auctionPrimaryImage')

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    status: Yup.string().required('Status is required'),
    property_type_id: Yup.string().required('Required field'),
    auction_type_id: Yup.string().required('Required field'),
    is_nba: Yup.string().required('Required field'),
    address_category_id: Yup.string().required('Required field'),
    district_id: Yup.string().required('Required field'),
    province_id: Yup.string().required('Required field'),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),

    latitude: Yup.string()
      .nullable()
      .when([], {
        is: () => isLatitudeNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Required field'),
      }),
    longitude: Yup.string()
      .nullable()
      .when([], {
        is: () => isLongituteNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Required field'),
      }),
    start_date: Yup.date()
      .required('Required field')
      .nullable()
      .when([], {
        is: () => isStartDateNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Required field'),
      }),
    start_time: Yup.date()
      .nullable()
      .when([], {
        is: () => isStartTimeNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Required field'),
      }),
    end_date: Yup.date()
      .required('Required field')
      .nullable()
      .when('start_date', (start_date, schema) => {
        return schema.test({
          test: (end_date: string) => {
            if (!end_date || !start_date) return true //Skip validation if none of the dates are provided
            return moment(end_date).isSameOrAfter(start_date)
          },
          message: 'End date cannot be before the start date',
        })
      })
      .when([], {
        is: () => isEndDateNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Required field'),
      }),
    end_time: Yup.date()
      .nullable()
      .when([], {
        is: () => isEndTimeNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Required field'),
      }),
    images: Yup.array()
      .of(
        Yup.object().shape({
          path: Yup.string().required('Image is required').nullable(),
          image_type_id: Yup.string().required('Image type required').nullable(),
          show_in_listing: Yup.string()
            .required('Please select an option')
            .oneOf(['true', 'false'], 'Please select a valid option')
            .nullable(),
        })
      )
      .min(1, 'At least one property is required'),
    personalDetails: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Name must not contain number or symbol'),
          number: Yup.string()
            .matches(/^(98|97)([0-9]{8})$/, 'Enter valid mobile number')
            .max(10, 'Mobile number should not exceed 10 digits'),
        })
      )
      .when([], {
        is: () => auctionEnableContactPerson,
        then: (schema) => schema,
        otherwise: (schema) => schema.nullable(),
      }),
    properties: Yup.array()
      .of(
        Yup.object().shape({
          key: Yup.string().required('Key is required').nullable(),
          value: Yup.string().required('Value is required').nullable(),
          show_in_listing: Yup.string()
            .required('Please select an option')
            .oneOf(['true', 'false'], 'Please select a valid option')
            .nullable(),
        })
      )
      .min(1, 'At least one property is required'),
  })

  return (
    <div className='modal-container' data-cy='search-category-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Auction</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                slug: '',
                description: '',
                descriptionNp: '',
                attachments: '',
                property_type_id: '',
                auction_type_id: '',
                is_nba: YesNoOptions?.[0]?.value,
                address_category_id: '',
                district_id: '',
                province_id: '',
                address1: '',
                address2: '',
                start_date: null,
                start_time: null,
                end_date: null,
                end_time: null,
                latitude: '',
                longitude: '',
                images: [
                  {
                    path: '',
                    image_type_id: '',
                    show_in_listing: YesNoOptions?.[0]?.value,
                    primary_image: YesNoOptions?.[1]?.value,
                  },
                ],
                properties: [
                  {
                    key: '',
                    value: '',
                    show_in_listing: YesNoOptions?.[1]?.value,
                  },
                ],
                personalDetails: auctionEnableContactPerson ? [{name: '', number: ''}] : null,
                plus_code: '',
                status: enableMakerChecker ? 'Inactive' : '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: isAuctionSlugFieldEditable
                    ? values?.slug?.trim()?.replace(/\s/g, '-')?.toLowerCase()
                    : !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.name?.replace(/\s/g, '-')?.toLowerCase(),
                  start_date: values?.start_date
                    ? moment(values?.start_date).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  start_time: values?.start_time
                    ? moment(values.start_date ?? Date.now())
                        .set({
                          hour: moment(values.start_time).hour(),
                          minute: moment(values.start_time).minute(),
                          second: moment(values.start_time).second(),
                        })
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  end_date: values?.end_date
                    ? moment(values?.end_date).format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  end_time: values?.end_time
                    ? moment(values.end_date ?? Date.now())
                        .set({
                          hour: moment(values.end_time).hour(),
                          minute: moment(values.end_time).minute(),
                          second: moment(values.end_time).second(),
                        })
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                  is_nba: values?.is_nba === 'true' ? true : false,
                  latitude: values?.latitude?.toString() ?? '',
                  longitude: values?.longitude?.toString() ?? '',
                  images: values?.images?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item?.id : null,
                    show_in_listing: item?.show_in_listing === 'true' ? true : false,
                    primary_image: item?.primary_image === 'true' ? true : false,
                  })),
                  properties: values?.properties?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item?.id : null,
                    show_in_listing: item?.show_in_listing === 'true' ? true : false,
                  })),
                  status: values?.status === 'Active' ? true : false,
                }

                if (enableConditionalDateFieldRendering) {
                  if (
                    formData?.auction_type_id ===
                    auctionTypeList?.auctionType?.find(
                      (auctionType: {name: string; id: string}) => auctionType?.name === 'NBA'
                    )?.id
                  ) {
                    formData.start_date = null
                    formData.end_date = null
                    formData.start_time = null
                    formData.end_time = null
                  }
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(auctionRedux.actions.updateAuction(formData, editSelectedData?.id))
                } else {
                  dispatch(auctionRedux.actions.addAuction(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'slug',
                      'property_type_id',
                      'auction_type_id',
                      'is_nba',
                      'address_category_id',
                      'district_id',
                      'province_id',
                      'address1',
                      'address2',
                      'latitude',
                      'longitude',
                      'images',
                      'personalDetails',
                      'properties',
                      'plus_code',
                      'start_date',
                      'start_time',
                      'end_date',
                      'end_time',
                      'description',
                      'descriptionNp',
                      'attachments',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                useEffect(() => {
                  if (values.province_id && values.province_id !== editSelectedData.province_id) {
                    setFieldValue('province_id', '')
                  }
                }, [values.address_category_id, editSelectedData])

                useEffect(() => {
                  if (values.district_id && values.district_id !== editSelectedData.district_id) {
                    setFieldValue('district_id', '')
                  }
                }, [values.province_id, editSelectedData])
                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='search-category-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={(e: any) => {
                              handleChange(e)
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : setFieldValue(
                                    'slug',
                                    e.target.value.replace(/\s/g, '-').toLowerCase()
                                  )
                            }}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='search-category-name-text-fields'
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={!isAuctionSlugFieldEditable}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Auction Type'
                            name='auction_type_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={OptionsDropdown(auctionTypeList?.auctionType)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label={isAssetType ? 'Asset Type' : 'Property Type'}
                            name='property_type_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={OptionsDropdown(propertyTypeList?.propertyType)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                      </div>
                      {(enableConditionalDateFieldRendering
                        ? values?.auction_type_id !==
                          auctionTypeList?.auctionType?.find(
                            (auctionType: {name: string; id: string}) => auctionType?.name === 'NBA'
                          )?.id
                        : true) && (
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              label='Start date'
                              name='start_date'
                              placeholderText='Please select a Start date'
                              setFieldValue={setFieldValue}
                              value={values?.start_date || false}
                              errors={errors}
                              touched={touched}
                              required={!isStartDateNotRequired}
                              isClearable={isStartDateNotRequired}
                              showIcon={true}
                              data-cy='publish-date-drop-down'
                            />
                          </div>
                          {disableStartTimeField ? null : (
                            <div className='col-md-6 col-xs-12'>
                              <FormDatepicker
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                dateFormat='hh:mm aa'
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                name='start_time'
                                label='Start time'
                                placeholderText='--:-- --'
                                setFieldValue={setFieldValue}
                                value={values?.start_time || false}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                                required={!isStartTimeNotRequired}
                                data-cy='publish-time-drop-down'
                                isClearable={isStartTimeNotRequired}
                              />
                            </div>
                          )}
                          <div className='col-md-6 col-xs-12'>
                            <FormDatepicker
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              label='End date'
                              name='end_date'
                              placeholderText='Please select a End date'
                              setFieldValue={setFieldValue}
                              value={values?.end_date || false}
                              minDate={values?.start_date}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                              disabled={values.start_date ? false : true}
                              required={!isEndDateNotRequired}
                              isClearable={isEndDateNotRequired}
                              data-cy='end-date-drop-down'
                            />
                          </div>
                          {disableEndTimeField ? null : (
                            <div className='col-md-6 col-xs-12'>
                              <FormDatepicker
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                dateFormat='hh:mm aa'
                                showTimeSelect={true}
                                showTimeSelectOnly={true}
                                name='end_time'
                                label='End time'
                                placeholderText='--:-- --'
                                setFieldValue={setFieldValue}
                                value={values?.end_time || false}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                                disabled={values.start_time ? false : true}
                                required={!isEndTimeNotRequired}
                                isClearable={isEndTimeNotRequired}
                                data-cy='end-time-drop-down'
                              />
                            </div>
                          )}
                        </div>
                      )}
                      <div className='row'>
                        {disableNBADropdown ? null : (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='NBA'
                              name='is_nba'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={YesNoOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                        )}
                        {showAuctionAttachment && (
                          <div className='col-md-6 col-xs-12'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Attached File'
                              name='attachments'
                              setFieldValue={setFieldValue}
                              setImageUrl={setAttachedFile}
                              value={values?.attachments}
                              data-cy='add-attached-file-field'
                              buttonName='Add Attachment'
                            />
                            {!isEmpty(values?.attachments) ? (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setAttachedFile('')
                                        setFieldValue('attachments', '')
                                      }}
                                      data-cy='attachment-remove-image-button'
                                    >
                                      X
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={
                                        values?.attachments?.slice(-3).toLowerCase() === 'pdf'
                                          ? '/media/logos/pdfDefault.png'
                                          : `${imageBaseUrl}/${values?.attachments}`
                                      }
                                      alt=''
                                      data-cy='attached-file-preview'
                                    />
                                  </div>
                                </li>
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                      {showAuctionDescription && (
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              name='description'
                              handleChange={handleChange}
                              label='Description (EN)'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                              }
                            />
                          </div>

                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              name='descriptionNp'
                              handleChange={handleChange}
                              label=' Description (NP)'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''
                              }
                            />
                          </div>
                        </div>
                      )}

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Auction Location'
                            name='address_category_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={OptionsDropdown(category)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <>
                          {!isEmpty(values?.address_category_id)
                            ? setLocationCategoryValue(values?.address_category_id)
                            : ''}
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Province'
                            name='province_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              !isEmpty(locationCategoryValue)
                                ? valleyProvinceOptions
                                : OptionsDropdown(province)
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <>
                          {!isEmpty(values?.province_id)
                            ? setProvinceValue(values?.province_id)
                            : ''}
                        </>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='District'
                            name='district_id'
                            disabled={
                              !isEmpty(editSelectedData) || values?.province_id ? false : true
                            }
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={
                              !isEmpty(locationCategoryValue) && !isEmpty(valleyProvinceOptions)
                                ? valleyDistrictOptions
                                : OptionsDropdown(district)
                            }
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                      </div>
                      <div className='row'>
                        {showAddress1Field && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Address 1'
                              name={`address1`}
                              label='Address 1'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}
                        {showAddress2Field && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Address 2'
                              name={`address2`}
                              label='Address 2'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}
                      </div>
                      <div className='row'>
                        <MapView
                          latName='latitude'
                          lngName='longitude'
                          location={{
                            lat: values.latitude || defaultLatitude,
                            lng: values.longitude || defaultLongitude,
                          }}
                          setFieldValue={setFieldValue}
                          data-cy='modal-map-view'
                        />
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Longitude'
                            label='Longitude'
                            name='longitude'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={!isLongituteNotRequired}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Latitude'
                            label='Latitude'
                            name='latitude'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={!isLatitudeNotRequired}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Google Plus Code'
                            label='Google Plus Code'
                            name='plus_code'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <FieldArray
                          name='images'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Auction Images</h5>
                              {values?.images && values?.images.length > 0
                                ? values?.images?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormSelect
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Auction Image Type'
                                          name={`images[${index}].image_type_id`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          options={OptionsDropdown(
                                            auctionImageTypeList?.auctionImageType
                                          )}
                                          values={values?.images[index]?.image_type_id}
                                          arrValue={values?.images[index]?.image_type_id}
                                          required
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>

                                      <div className='col-md-6 col-xs-12'>
                                        <FormInputMediaManager
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Image'
                                          name={`images[${index}].path`}
                                          setFieldValue={setFieldValue}
                                          value={values?.images[index]?.path}
                                          required
                                          showImageOnly={true}
                                        />
                                        {!isEmpty(responseData?.path) ? (
                                          <>
                                            <li className='listing'>
                                              <div className='thumbImageBlock'>
                                                <button
                                                  type='button'
                                                  title='Remove'
                                                  className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                  onClick={() => {
                                                    setFieldValue(`images[${index}].path`, '')
                                                  }}
                                                  data-cy='modal-thumbnail-remove'
                                                >
                                                  X
                                                </button>

                                                <img
                                                  className='thumbImage w-100 h-100'
                                                  src={`${imageBaseUrl}/${responseData?.path}`}
                                                  alt='image'
                                                />
                                              </div>
                                            </li>
                                          </>
                                        ) : null}
                                      </div>

                                      <div className='col-md-6 col-xs-12'>
                                        <FormSelect
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          label='Show in listing'
                                          name={`images[${index}].show_in_listing`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          options={YesNoOptions}
                                          values={values?.images[index]?.show_in_listing}
                                          arrValue={values?.images[index]?.show_in_listing}
                                          required
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>
                                      {auctionPrimaryImage && (
                                        <div className='col-md-6 col-xs-12'>
                                          <label className='form-label fw-bolder text-dark fs-6 required'>
                                            Is Primary Image
                                          </label>

                                          <div className='d-flex ms-5'>
                                            {YesNoOptions?.map(
                                              (status: {label: string; value: string}) => (
                                                <FormRadio
                                                  containerClassName=''
                                                  label='Select Status'
                                                  name={`images[${index}].primary_image`}
                                                  type='radio'
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                  required={true}
                                                  checkBoxText={status?.label}
                                                  value={status?.value}
                                                  disabled={values?.images?.some(
                                                    (item, idx) =>
                                                      item?.primary_image === 'true' &&
                                                      idx !== index
                                                  )}
                                                />
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}
                              {values?.images[values?.images?.length - 1]?.path && (
                                <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                  <button
                                    className='p-2 ps-5 pe-5 btn btn-primary'
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        path: '',
                                        image_type_id: '',
                                        show_in_listing: 'true',
                                        primary_image: 'false',
                                      })
                                    }
                                    data-cy='modal-field-add'
                                  >
                                    + Add More
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </div>

                      <div className='row'>
                        <FieldArray
                          name='properties'
                          render={(arrayHelpers) => (
                            <div>
                              <h5 className='rs-modal-title mb-5'>Auction Property</h5>
                              {values?.properties && values?.properties.length > 0
                                ? values?.properties?.map((responseData: any, index: any) => (
                                    <div className='row mb-4' key={index}>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='key'
                                          name={`properties[${index}].key`}
                                          label='Key'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormTextBox
                                          type='text'
                                          placeholder='Value'
                                          name={`properties[${index}].value`}
                                          label='Value'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          onChange={handleChange}
                                          errors={errors}
                                          required
                                          touched={touched}
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12'>
                                        <FormSelect
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          placeholder='Please select a file type'
                                          label='Show in listing'
                                          name={`properties[${index}].show_in_listing`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          options={YesNoOptions}
                                          values={values?.properties[index]?.show_in_listing}
                                          arrValue={values?.properties[index]?.show_in_listing}
                                          setFieldValue={setFieldValue}
                                          required
                                        />
                                      </div>
                                      <div className='col-md-6 col-xs-12' />
                                      {index > 0 && (
                                        <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                          <button
                                            type='button'
                                            className='p-2 ps-5 pe-5 btn btn-secondary'
                                            onClick={() => arrayHelpers.remove(index)}
                                            data-cy='modal-field-remove'
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                : null}

                              <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                <button
                                  className='p-2 ps-5 pe-5 btn btn-primary'
                                  type='button'
                                  onClick={() =>
                                    arrayHelpers.push({
                                      key: '',
                                      value: '',
                                      show_in_listing: 'false',
                                    })
                                  }
                                  data-cy='modal-field-add'
                                >
                                  + Add More
                                </button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      {auctionEnableContactPerson && (
                        <div className='row'>
                          <FieldArray
                            name='personalDetails'
                            render={(arrayHelpers) => (
                              <div>
                                <h5 className='rs-modal-title mb-5'>Contact People</h5>
                                {values?.personalDetails && values?.personalDetails.length > 0
                                  ? values?.personalDetails?.map(
                                      (responseData: any, index: any) => (
                                        <div className='row mb-4' key={index}>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Contact Person Name'
                                              name={`personalDetails[${index}].name`}
                                              label='Name'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              required
                                              touched={touched}
                                            />
                                          </div>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='number'
                                              placeholder='Contact Number'
                                              name={`personalDetails[${index}].number`}
                                              label='Contact No.'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              required
                                              touched={touched}
                                            />
                                          </div>
                                          {index >= 0 && (
                                            <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                                              <button
                                                type='button'
                                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                                onClick={() => arrayHelpers.remove(index)}
                                                data-cy='modal-field-remove'
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )
                                  : null}

                                <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                                  <button
                                    className='p-2 ps-5 pe-5 btn btn-primary'
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        name: '',
                                        number: '',
                                      })
                                    }
                                    data-cy='modal-field-add'
                                  >
                                    + Add More
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                disabled={
                                  enableMakerChecker ? (isAuctionMaker ? true : false) : false
                                }
                                data-cy='search-category-status-radio'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddAuction
