import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
// includes
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { SortType } from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import { ItemType, StateParamsModel } from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as members from '../index'
import AddMember from './AddMember'

const MemberComponent = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const {
    data,
    loading,
    singleEnableSuccess,
    singleDisableSuccess,
    enableSuccess,
    disableSuccess,
    toggleLoading,
    success,
    deleteSuccess,
    memberList,
    sortMemberData,
  } = useSelector((state: any) => state.member)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    orderBy: 'order',
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [openSortModal, setOpenSortModal] = useState(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const enableMemberData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(members.actions.enableMember(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableMemberData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(members.actions.disableMember(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group' data-cy="link-group-member">
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy="edit-button"
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let branchId = [rowData.id]
                setCheckedValues(branchId)
              }}
              icon={<TrashIcon />}
              data-cy="delete-button"
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={toggleLoading}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const handleToggleAction = (data: { [key: string]: string }) => {
    data?.status
      ? dispatch(members.actions.singleDisableMember({ id: data.id }))
      : dispatch(members.actions.singleEnableMember({ id: data.id }))
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Designation',
      dataKey: 'designation',
      flexGrow: 1,
      cell: <Cell dataKey='designation' />,
      sortable: true,
    },
    {
      label: 'Member',
      dataKey: 'memberSubType',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) => rowData?.memberSubType?.memberType?.name || rowData?.memberType?.name}
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Order',
      dataKey: 'order',
      width: 85,

      cell: <Cell dataKey='order' />,
      sortable: true,
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'
                data-cy='active-button'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'
                data-cy='in-active-button'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(members.actions.getMember(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Member deleted successfully')
    }
    if (enableSuccess) {
      toast.success('Member enabled successfully')
    }
    if (disableSuccess) {
      toast.success('Member disabled successfully')
    }
    handleChecked([])
    dispatch(members.actions.getMember(params))
  }, [deleteSuccess, success, enableSuccess, disableSuccess])

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('Member enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('Member disabled successfully')
    }
    handleChecked([])
  }, [singleDisableSuccess, singleEnableSuccess])

  const memberData = data?.member
    ? data?.member?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item?.id,
      additional_information: item?.additional_information,
      additional_information_np: item?.additional_information_np,
      designation: item?.designation,
      designation_np: item?.designation_np,
      image: item?.image,
      memberFeatured: item?.memberFeatured,
      memberSubType: item?.memberSubType,
      memberType: item?.memberType,
      message: item?.message,
      message_np: item?.message_np,
      name: item?.name,
      name_np: item?.name_np,
      row: item?.row,
      order: item?.order,
      status: item?.status,
    }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let memberId = checkedValues?.map((value) => ({ id: value }))
    dispatch(members?.actions?.deleteMember(memberId))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(members.actions.getMember(params))
  }

  const handleGetAllData = () => {
    setParams({ ...params, limit: data?.meta?.total })
    dispatch(members.actions.getMember(params))
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      memberId: data?.map((item) => ({ id: item?.keyId })),
    }
    dispatch(members.actions.sortMember(body))
  }

  useEffect(() => {
    if (!isEmpty(sortMemberData) && success) {
      toast.success('Member sorted successfully')
      dispatch(members.actions.sortMemberReset())
      dispatch(members.actions.getMember(params))
      setOpenSortModal(false)
    }
  }, [sortMemberData, success])

  return (
    <>
      <div className='shadow p-3 bg-white rounded' data-cy='shadow-department'>
        <DesignComponent
          moduleName='Member'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          enableMultiple={enableMemberData}
          disableMultiple={disableMemberData}
          data={memberData}
          sortShow={true}
          sortButtonName='Sort'
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleGetAllData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
          data-cy='member'
        />

        <div className='datatable' data-cy='datatable-member'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={memberData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
            data-cy='r-suit-table'
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
            data-cy='cms-pagination'
          />
          {open && (
            <AddMember
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>

        {/* Delete Modal */}
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default MemberComponent
