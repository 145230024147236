import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
// includes
import * as mediaManager from '../index'
import {MediaParams} from '../Model'

type Props = {
  open: boolean
  handleClose: () => void
  currentPath: string
  addFileData: {[key: string]: string}
  success: boolean | undefined
  params: MediaParams
  error: string
}

const UploadModal = ({
  open,
  handleClose,
  currentPath,
  addFileData,
  success,
  params,
  error,
}: Props) => {
  const dispatch = useDispatch()
  const [allFiles, setAllFiles] = useState([])
  const [progressPercentage, setProgressPercentage] = useState('')

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  const settingType: any = settingTypeData?.data?.setting
    ?.filter((item: any) => item.name === 'allowed_file_extensions')
    .map((item: any) => item)

  const defaultAcceptedFileExtensions =
    '.csv,.xlsl,.ods,.docs,.docx,.pdf,.jpg,.png,.jpeg,.gif,.ico,.webp,.xls,.xlsx,.mp3'

  const settingAcceptedFileExtensions = settingType?.[0]?.value
    .split(',')
    .map((str: string) => `.${str}`)
    .join(',')

  const acceptedFileFormats = settingAcceptedFileExtensions ?? defaultAcceptedFileExtensions

  useEffect(() => {
    if (!isEmpty(addFileData) && success) {
      dispatch(mediaManager.actions.addFilesReset())
      dispatch(mediaManager.actions.getMediaList(params))
      setTimeout(() => {
        setProgressPercentage('')
        toast.success('Files uploaded successfully')
      }, 1000)
      setAllFiles([])
      handleClose()
    }
  }, [addFileData, success])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setProgressPercentage('')
      }, 1000)
      dispatch(mediaManager.actions.addFilesReset())
    }
  }, [error])

  const selectFiles = (e: any) => {
    const newFiles: any = [...allFiles, ...e.target.files]
    setAllFiles(newFiles)
  }

  const removeFile = (selectedFile: HTMLInputElement) => {
    let newFiles: any = allFiles?.filter(
      (file: HTMLInputElement) => file?.name !== selectedFile?.name
    )
    setAllFiles(newFiles)
  }

  const options = {
    onUploadProgress: (progressEvent: any) => {
      const {loaded, total} = progressEvent
      let percent = Math.floor((loaded * 100) / total)

      if (percent < 100) {
        setProgressPercentage(`${percent}%`)
      }
      if (percent === 100) {
        setTimeout(() => {
          setProgressPercentage('100%')
        }, 1000)
      }
    },
  }

  const uploadAllFiles = () => {
    let body = new FormData()
    body.append('path', currentPath)

    const acceptedFileFormatList = acceptedFileFormats.split(',')

    let isFileUploadValid = true

    allFiles.map((file: any) => {
      const fileExtension = file?.name?.slice(file?.name?.lastIndexOf('.'))
      if (!acceptedFileFormatList.includes(fileExtension)) {
        toast.error('Please upload supported file types only.')
        isFileUploadValid = false
      }
      body.append('files[]', file, file?.name)
    })

    if (isFileUploadValid) {
      dispatch(mediaManager.actions.addFiles(body, options))
    }
  }

  return (
    <Modal
      className='info-modal w-75'
      open={open}
      onClose={() => {
        handleClose()
        setAllFiles([])
      }}
      enforceFocus={false}
      data-cy='modal'
    >
      <Modal.Header>
        <Modal.Title data-cy='modal-title'>Upload Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='form-group'>
          <div className='dropzone dropzone-queue mb-2' id='kt_modal_upload_dropzone'>
            <div className='dropzone-panel mb-4'>
              <a>
                <input
                  type='file'
                  className='inputfile'
                  id='file'
                  // disabled
                  onChange={selectFiles}
                  multiple
                  accept={acceptedFileFormats}
                  data-cy='upload-modal-input-field'
                />
                <label
                  htmlFor='file'
                  className={`dropzone-select btn btn-sm btn-primary me-2 ${
                    settingTypeData?.loading ? 'disabled' : ''
                  }`}
                  data-cy='upload-modal-input-field-attach'
                >
                  Attach files
                </label>
              </a>
              <a
                className='dropzone-upload btn btn-sm btn-light-primary me-2'
                onClick={uploadAllFiles}
                data-cy='upload-modal-upload-all'
              >
                Upload All
              </a>
              <a
                className='dropzone-remove-all btn btn-sm btn-light-primary'
                onClick={() => setAllFiles([])}
                data-cy='upload-modal-remove-all'
              >
                Remove All
              </a>
            </div>

            {/* supported file types warning message */}
            <p className='text-danger'>
              Supported file formats: <b>{acceptedFileFormats.replaceAll('.', ' ')}</b>
            </p>

            <div className='dropzone-items wm-200px'>
              {!isEmpty(allFiles) &&
                allFiles?.map((file: HTMLInputElement, i: number) => (
                  <div className='dropzone-item p-5' key={i}>
                    <div className='dropzone-file'>
                      <div className='dropzone-filename text-dark' title='some_image_file_name.jpg'>
                        <span data-dz-name=''>{file?.name} </span>
                        <strong>
                          ( <span data-dz-size=''>{(file?.size / 1024).toFixed(2)}kb</span> )
                        </strong>
                      </div>
                      <div className='dropzone-error mt-0' data-dz-errormessage=''></div>
                    </div>
                    <div className='dropzone-toolbar'>
                      <span className='dropzone-cancel' data-dz-remove='' style={{display: 'none'}}>
                        <i className='bi bi-x fs-3'></i>
                      </span>
                      <span
                        className='dropzone-delete'
                        onClick={() => removeFile(file)}
                        data-cy='upload-modal-remove-files'
                      >
                        <i className='bi bi-x fs-1'></i>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className='dropzone-progress'>
            <div
              className='progress bg-light-primary'
              style={{opacity: progressPercentage ? 1 : 0}}
            >
              <div
                className='progress-bar bg-primary'
                role='progressbar'
                aria-valuemin={0}
                aria-valuemax={100}
                aria-valuenow={0}
                data-dz-uploadprogress=''
                style={{opacity: progressPercentage ? 1 : 0, width: progressPercentage}}
              ></div>
            </div>
          </div>
          <span className='form-text fs-6 text-muted'>Max file size is 100 MB per file.</span>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UploadModal
