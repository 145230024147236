import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getSpecificBenefitManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSpecificBenefitManager, params)
    yield put(actions.getSpecificBenefitManagerSuccess(response?.data?.data))
    yield put(actions.getSpecificBenefitManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSpecificBenefitManagerFinish())
  }
}

function* getBenefitManagerSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getBenefitManager, params)
    yield put(actions.getBenefitManagerSuccess(response?.data?.data))
    yield put(actions.getBenefitManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBenefitManagerFinish())
  }
}

function* addBenefitManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBenefitManager, body)
    yield put(actions.addBenefitManagerSuccess(response.data?.data))
    yield put(actions.addBenefitManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBenefitManagerFinish())
  }
}

function* updateBenefitManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateBenefitManager,
      body,
      action.payload?.id
    )
    yield put(actions.updateBenefitManagerSuccess(response.data?.data))
    yield put(actions.updateBenefitManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBenefitManagerFinish())
  }
}

function* deleteBenefitManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBenefitManager, body)
    yield put(actions.deleteBenefitManagerSuccess(response.data?.data))
    yield put(actions.deleteBenefitManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBenefitManagerFinish())
  }
}

function* singleActivateBenefitManager(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateBenefitManager,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_FINISH})
  }
}

function* singleDeactivateBenefitManager(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateBenefitManager,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_FINISH})
  }
}

function* sortBenefitManager(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorialBenefitManager, body)

    yield put({
      type: actionTypes.SORT_BENEFIT_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_BENEFIT_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_BENEFIT_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_START, getSpecificBenefitManagerSaga)
  yield takeLatest(actionTypes.GET_BENEFIT_MANAGER_START, getBenefitManagerSaga)
  yield takeLatest(actionTypes.ADD_BENEFIT_MANAGER_START, addBenefitManagerSaga)
  yield takeLatest(actionTypes.UPDATE_BENEFIT_MANAGER_START, updateBenefitManagerSaga)
  yield takeLatest(actionTypes.DELETE_BENEFIT_MANAGER_START, deleteBenefitManagerSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_REQUEST,
    singleActivateBenefitManager
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_REQUEST,
    singleDeactivateBenefitManager
  )
  yield takeLatest(actionTypes.SORT_BENEFIT_MANAGER_START, sortBenefitManager)
}
