import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteInternshipApplicationModel, InternshipApplicationModal} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getInternshipLists: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_INTERNSHIP_LISTS_START,
    payload: params,
  }),

  getInternshipListsSuccess: (data: any) => ({
    type: actionTypes.GET_INTERNSHIP_LISTS_SUCCESS,
    payload: data,
  }),
  getInternshipListsFinish: () => ({
    type: actionTypes.GET_INTERNSHIP_LISTS_FINISH,
  }),
  getInternshipListsError: (error: unknown) => ({
    type: actionTypes.GET_INTERNSHIP_LISTS_FINISH,
    payload: {error},
  }),

  updateInternshipLists: (data: any, id: string) => ({
    type: actionTypes.UPDATE_INTERNSHIP_LISTS_START,
    payload: {data, id},
  }),
  updateInternshipListsSuccess: (task: any) => ({
    type: actionTypes.UPDATE_INTERNSHIP_LISTS_SUCCESS,
    payload: task,
  }),
  updateInternshipListsFinish: () => ({
    type: actionTypes.UPDATE_INTERNSHIP_LISTS_FINISH,
  }),
  updateInternshipListsReset: () => ({
    type: actionTypes.UPDATE_INTERNSHIP_LISTS_RESET,
  }),

  deleteInternshipLists: (data: DeleteInternshipApplicationModel[]) => ({
    type: actionTypes.DELETE_INTERNSHIP_LISTS_START,
    payload: {internshipApplicationId: data},
  }),
  deleteInternshipListsSuccess: (data: any) => ({
    type: actionTypes.DELETE_INTERNSHIP_LISTS_SUCCESS,
    payload: data,
  }),
  deleteInternshipListsFinish: () => ({
    type: actionTypes.DELETE_INTERNSHIP_LISTS_FINISH,
  }),

  //Get csv file
  getInternshipApplicationFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_INTERNSHIP_LISTS_FILE_START,
      payload: fileType,
    }
  },

  getInternshipApplicationFileSuccess: (data:any) => ({
    type: actionTypes.GET_INTERNSHIP_LISTS_FILE_SUCCESS,
    payload: data,
  }),
  getInternshipApplicationFileError: () => ({
    type: actionTypes.GET_INTERNSHIP_LISTS_FILE_FINISH,
  }),
}
