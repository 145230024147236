import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_REVIEW_STATUS = `${API_URL}/review-status`

export const service = {

  getReviewStatus: () => {
    return axios.get(GET_REVIEW_STATUS)
  },

}
