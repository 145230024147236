import axios from 'axios'
import {Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

//Manual import
import {FiEye} from 'react-icons/fi'
import {IconButton} from 'rsuite'
import * as vacancyApplicantRedux from 'src/app/modules/careers/components/vacancyApplicantReport/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {applicantStatusModel} from '../Model'
import {IApplicantStatusState, IVacancyApplicantState} from '../redux'
import ViewCandidateDetails from './../../vacancy/component/ViewCandidateDetails'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const Cell = Table.Cell
const VacancyApplicantReport = () => {
  const [showViewCandidateDetailsModal, setShowViewCandidatesModal] = useState(false)
  const [selectedCandidateId, setSelectedCandidateId] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  let id: any = useParams()

  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    vacancyId: id?.vacancyId,
  })
  const [allDataParams, setAllDataParams] = useState<StateParamsModel>({
    page: 0,
    limit: 0,
  })

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const [open1, setOpen1] = useState(false)
  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const [openSortModal, setOpenSortModal] = useState(false)

  const handleChangeLimit = (dataKey: any) => {
    setParams({
      ...params,
      limit: dataKey,
      vacancyId: id?.vacancyId,
    })
  }

  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [municipalityData, setMunicipalityData] = useState([])
  const [zoneData, setZoneData] = useState([])
  const [functionalAreaData, setFunctionalAreaData] = useState([])
  const [branchData, setBranchData] = useState([])
  const [departmentData, setDepartmentData] = useState([])

  useEffect(() => {
    axios.get(API_URL + '/province').then((res) => {
      setProvinceData(res.data.data.province)
    })
    axios.get(API_URL + '/district').then((res) => {
      setDistrictData(res.data.data.district)
    })
    axios.get(API_URL + '/zone').then((res) => {
      setZoneData(res.data.data.zone)
    })
    axios.get(API_URL + '/municipality').then((res) => {
      setMunicipalityData(res.data.data.municipality)
    })
    axios.get(API_URL + '/functionalarea').then((res) => {
      setFunctionalAreaData(res.data.data.functionalArea)
    })
    axios.get(API_URL + '/branch').then((res) => {
      setBranchData(res.data.data.branch)
    })
    axios.get(API_URL + '/department').then((res) => {
      setDepartmentData(res.data.data.department)
    })
  }, [])

  const vacancyApplicantData: IVacancyApplicantState = useSelector(
    (state: any) => state.vacancyApplicant
  )

  const applicantStatusData: IApplicantStatusState = useSelector(
    (state: any) => state.applicantStatus
  )

  useEffect(() => {
    dispatch(vacancyApplicantRedux.actions.getVacancyApplicant(params))
    dispatch(vacancyApplicantRedux.actions.getApplicantStatus())
  }, [params])

  const {success, loading} = vacancyApplicantData

  const applicantStatusOptions = applicantStatusData?.data?.vacancyApplicantStatus?.map(
    (applicantStatus: applicantStatusModel) => ({
      label:
        applicantStatus.displayName === 'Shortlisted'
          ? 'Shortlisted for exam'
          : applicantStatus.displayName || '',
      value: applicantStatus.id || '',
    })
  )

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Applicants</Tooltip>}>
          <IconButton
            /*  onClick={() =>
               history.push({
                 pathname: `/career/vacancy-applicant-report/${rowData?.id}`,
                 state: rowData,
               })
             } */
            onClick={() => {
              setShowViewCandidatesModal(true)
              setSelectedCandidateId(rowData?.id)
            }}
            data-cy='view-button'
            appearance='subtle'
            icon={<FiEye />}
          />
        </Whisper>
      </Cell>
    )
  }
  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      flexGrow: 1,
      dataKey: 'name',
      cell: <Cell dataKey='name' />,
    },
    {
      label: 'Email',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
    },

    {
      label: 'Unique Code',
      dataKey: 'uniqueCode',
      flexGrow: 1,
      cell: <Cell dataKey='uniqueCode' />,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleRefresh = () => {
    dispatch(vacancyApplicantRedux.actions.getVacancyApplicant(params))
  }

  //Get data from api to map in datatable
  const data = vacancyApplicantData?.data?.vacancyApplicant
    ? vacancyApplicantData?.data?.vacancyApplicant?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        name: item?.name,
        email: item?.email,
        uniqueCode: item?.uniqueCode,
      }))
    : []

  let detail = vacancyApplicantData?.data?.vacancyApplicant
    ? vacancyApplicantData?.data?.vacancyApplicant
    : []

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        {/* Formik form */}
        <Formik
          initialValues={{
            vacancyId: id?.vacancyId,
            statusId: '',
          }}
          onSubmit={(values: any) => {
            const formData = {
              ...values,
            }
            dispatch(vacancyApplicantRedux.actions.getVacancyApplicant(formData))
          }}
        >
          {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
            return (
              <Form>
                <div className='card-body border-top pt-5 pb-5' data-cy='vacancy-report-body'>
                  <div className='row'>
                    <div className='col-md-3 col-sm-6 col-xs-12 offset-md-6'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-md-12'
                        placeholder='Please select Status'
                        label='Applicant Status'
                        name='statusId'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={applicantStatusOptions}
                        values={values}
                        setFieldValue={setFieldValue}
                        data-cy='vacancy-report-status-select'
                      />
                    </div>
                    <div className='col-md-1 col-sm-6 col-xs-12 mt-8'>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='search-button'
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
        {/* End formik */}
        <DesignComponent
          moduleName='Vacancy Applicant Report'
          params={params}
          setParams={setParams}
          addShow={false}
          deleteShow={false}
          toggleMultipleShow={false}
          sortShow={false}
          handleAlertOpen={handleAlertOpen}
          handleRefresh={handleRefresh}
          exportShow={false}
          importShow={false}
          data={data}
          sortButtonName='Sort'
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
          data-cy='vacancy-report-design-comp'
        />

        <div className='datatable' data-cy='vacancy-report-datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={vacancyApplicantData?.loading}
            onChecked={handleCheck}
            data-cy='vacancy-report-r-suite'
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={vacancyApplicantData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='vacancy-report-pagination'
          />
        </div>
        {showViewCandidateDetailsModal && (
          <ViewCandidateDetails
            handleClose={() => setShowViewCandidatesModal(false)}
            data={detail?.filter((d: any) => d.id === selectedCandidateId)[0]}
            provinceList={provinceData}
            districtList={districtData}
            zoneList={zoneData}
            functionalAreaList={functionalAreaData}
            municipalityList={municipalityData}
            departmentList={departmentData}
            branchList={branchData}
          />
        )}
      </div>
    </div>
  )
}
export default VacancyApplicantReport
