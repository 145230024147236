import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

import axios from 'axios'
import {FiEye} from 'react-icons/fi'
import {Tag, Toggle} from 'rsuite'
import {SortType} from 'rsuite-table/lib/@types/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as customerLogin from '../redux'
import ViewDetailsModal from './ViewDetailsModal'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const CustomerLoginManager = () => {
  const dispatch = useDispatch()

  const customerLoginData = useSelector((state: any) => state.customerLoginManager)
  const {
    loading,
    success,
    singleActivateSuccess,
    singleDeactivateSuccess,
    activateSuccess,
    deactivateSuccess,
  } = useSelector((state: any) => state.customerLoginManager)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const downloadCustomerLoginData = () => {
    const GET_CUSTOMER_LOGIN_DATA = `${API_URL}/customer/export?search=${params.search}`
    axios(GET_CUSTOMER_LOGIN_DATA, {
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `customerLoginData.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleToggleAction = (data: {[key: string]: string}) => {
    const formData = {id: data?.id}
    data?.status
      ? dispatch(customerLogin.actions.singleDeactivateCustomer(formData))
      : dispatch(customerLogin.actions.singleActivateCustomer(formData))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              data-cy='edit-button'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle size='sm' checked={rowData.status} onClick={() => handleToggleAction(rowData)} />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Full Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Email Address',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
      sortable: true,
    },
    {
      label: 'Mobile No',
      dataKey: 'mobile_number',
      flexGrow: 1,
      cell: <Cell dataKey='mobile_number' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success('Customer activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success('Customer deactivated successfully')
    }
    handleChecked([])
  }, [singleActivateSuccess, singleDeactivateSuccess])

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Customer activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Customer deactivated successfully')
    }

    handleChecked([])
    dispatch(customerLogin?.actions.getCustomerLoginDetails(params))
  }, [activateSuccess, deactivateSuccess, params])

  const data = customerLoginData?.data?.customer
    ? customerLoginData?.data?.customer?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        name: item.name,
        email: item.email,
        mobile_number: item.mobile_number,
        dob: item.dob,
        address: item.address,
        municipality: item.municipality?.title,
        district: item.district?.title,
        province: item.province?.title,
        city: item.city,
        registration_date: item.registration_date,
        registration_platform: item.registration_platform,
        last_login: item.last_login,
        status: item.status,
      }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleDelete = () => {
    let disputeIds = checkedValues?.map((value) => ({id: value}))
    dispatch(customerLogin?.actions.deleteCustomerLoginDetails(disputeIds))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(customerLogin?.actions.getCustomerLoginDetails(params))
  }

  const activateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(customerLogin.actions.activateCustomer(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(customerLogin.actions.deactivateCustomer(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Customer Login Details'
          addShow={false}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={false}
          deleteShow={false}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          exportButtonName='Export CSV'
          handleExport={downloadCustomerLoginData}
          addNoteShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={customerLoginData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}

        {/* Delete Modal */}
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default CustomerLoginManager
