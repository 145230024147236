import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel, SortCsrCategoryModel} from '../Model'
import {actionTypes} from './constants'
import {ICsrCategoryState} from './reducer'

export const actions = {
  // GET BRANCHLESS BANKING
  getCsrCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CSR_CATEGORY_START,
    payload: params,
  }),

  getCsrCategorySuccess: (data: any) => ({
    type: actionTypes.GET_CSR_CATEGORY_SUCCESS,
    payload: data,
  }),
  getCsrCategoryFinish: () => ({
    type: actionTypes.GET_CSR_CATEGORY_FINISH,
  }),
  getCsrCategoryError: (error: unknown) => ({
    type: actionTypes.GET_CSR_CATEGORY_FINISH,
    payload: {error},
  }),

  // GET CSR Category Dropdown
  getCsrCategoryDropdown: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CSR_CATEGORY_DROPDOWN_START,
    payload: params,
  }),

  getCsrCategoryDropdownSuccess: (data: any) => ({
    type: actionTypes.GET_CSR_CATEGORY_DROPDOWN_SUCCESS,
    payload: data,
  }),
  getCsrCategoryDropdownFinish: () => ({
    type: actionTypes.GET_CSR_CATEGORY_DROPDOWN_FINISH,
  }),
  getCsrCategoryDropdownError: (error: unknown) => ({
    type: actionTypes.GET_CSR_CATEGORY_DROPDOWN_FINISH,
    payload: {error},
  }),

  // create branchless banking
  addCsrCategory: (data: any) => ({
    type: actionTypes.ADD_CSR_CATEGORY_START,
    payload: data,
  }),
  addCsrCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_CSR_CATEGORY_SUCCESS,
    payload: task,
  }),
  addCsrCategoryFinish: () => ({
    type: actionTypes.ADD_CSR_CATEGORY_FINISH,
  }),
  addCsrCategoryReset: () => ({
    type: actionTypes.ADD_CSR_CATEGORY_RESET,
  }),

  // update operation incharge

  updateCsrCategory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_CSR_CATEGORY_START,
    payload: {data, id},
  }),
  updateCsrCategorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_CSR_CATEGORY_SUCCESS,
    payload: task,
  }),
  updateCsrCategoryFinish: () => ({
    type: actionTypes.UPDATE_CSR_CATEGORY_FINISH,
  }),
  updateCsrCategoryReset: () => ({
    type: actionTypes.UPDATE_CSR_CATEGORY_RESET,
  }),

  // DELETE operation incharge
  deleteCsrCategory: (data: DeleteModel[]) => ({
    type: actionTypes.DELETE_CSR_CATEGORY_START,
    payload: {csrCategoryId: data},
  }),
  deleteCsrCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_CSR_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteCsrCategoryFinish: () => ({
    type: actionTypes.DELETE_CSR_CATEGORY_FINISH,
  }),
  // Active cms categories
  activateCsrCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_REQUEST,
    payload: {csrCategoryId: id},
  }),
  //  deactive cms categories
  deactivateCsrCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_REQUEST,
    payload: {csrCategoryId: id},
  }),
  // single active cms categories
  singleActivateCsrCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_REQUEST,
    payload: {csrCategoryId: [id]},
  }),
  // single deactive cms categories
  singleDeactivateCsrCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_REQUEST,
    payload: {csrCategoryId: [id]},
  }),

  // sort
  sortCsrCategory: (data: SortCsrCategoryModel) => ({
    type: actionTypes.SORT_CSR_CATEGORY_START,
    payload: data,
  }),
  sortCsrCategorySuccess: (data: Array<ICsrCategoryState>) => ({
    type: actionTypes.SORT_CSR_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortCsrCategoryFinish: () => ({
    type: actionTypes.SORT_CSR_CATEGORY_FINISH,
  }),
  sortCsrCategoryReset: () => ({
    type: actionTypes.SORT_CSR_CATEGORY_RESET,
  }),
}
