import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCertificateCategoryModel, SortCertificateCategoryModel} from '../Model'
import {CertificateCategoryModel} from '../Model/CertificateCategory'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CERTIFICATE_CATEGORY_API = `${API_URL}/certificate-category`

export const service = {
  getCertificateCategory: (params: ParamsModel) => {
    return axios.get(CERTIFICATE_CATEGORY_API, {params})
  },
  getAllCertificateCategory: () => {
    return axios.get(`${CERTIFICATE_CATEGORY_API}/list`)
  },

  addCertificateCategory: (data: any) => {
    return axios.post(CERTIFICATE_CATEGORY_API, data)
  },

  updateCertificateCategory: (body: CertificateCategoryModel, id: string) => {
    return axios.put(`${CERTIFICATE_CATEGORY_API}/${id}`, body)
  },

  deleteCertificateCategory: (data: DeleteCertificateCategoryModel) => {
    return axios.delete(`${CERTIFICATE_CATEGORY_API}/bulk-delete`, {data})
  },

  enableCertificateCategory: (data: Array<string>) => {
    const selectedCertificateCategory = {
      certificateCategoryId: data,
    }
    return axios.patch(`${CERTIFICATE_CATEGORY_API}/enable`, selectedCertificateCategory)
  },

  disableCertificateCategory: (data: Array<string>) => {
    const selectedCertificateCategory = {
      certificateCategoryId: data,
    }
    return axios.patch(`${CERTIFICATE_CATEGORY_API}/disable`, selectedCertificateCategory)
  },

  singleEnableCertificateCategory: (data: Array<string>) => {
    const selectedCertificateCategory = {
      certificateCategoryId: [data],
    }
    return axios.patch(`${CERTIFICATE_CATEGORY_API}/enable`, selectedCertificateCategory)
  },

  singleDisableCertificateCategory: (data: Array<string>) => {
    const selectedCertificateCategory = {
      certificateCategoryId: [data],
    }
    return axios.patch(`${CERTIFICATE_CATEGORY_API}/disable`, selectedCertificateCategory)
  },

  sortCertificateCategory: (body: SortCertificateCategoryModel) => {
    return axios.patch(`${CERTIFICATE_CATEGORY_API}/sort`, body)
  },
}
