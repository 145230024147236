import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'

//Get Vacancy Applicant saga
function* getVacancyApplicantSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getVacancyApplicant, params)
    const data: any = response?.data
    yield put(actions.getVacancyApplicantSuccess(data))
  } catch (error: any) {
    yield put(actions.getVacancyApplicantError(error))
  }
}

//Get Vacancy Applicant Status saga
function* getVacancyApplicantStatusSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getApplicantStatus)
    const data: any = response?.data
    yield put(actions.getApplicantStatusSuccess(data))
  } catch (error: any) {
    yield put(actions.getApplicantStatusError(error))
  }
}

export function* saga() {
    yield takeLatest(actionTypes.GET_VACANCY_APPLICANT_START, getVacancyApplicantSaga)
    yield takeLatest(actionTypes.GET_APPLICANT_STATUS_START, getVacancyApplicantStatusSaga)
    
  }
  