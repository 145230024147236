import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ProgramCategoryModel, SortProgramCategoryModel} from '../Model/ProgramCategoryModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PRODUCT_CATEGORY_API = `${API_URL}/program-category`

export const service = {
  getProgramCategory: (params: ParamsModel) => {
    return axios.get(PRODUCT_CATEGORY_API, {params})
  },
  getAllProgramCategory: () => {
    return axios.get(`${PRODUCT_CATEGORY_API}/list`)
  },

  addProgramCategory: (data: any) => {
    return axios.post(PRODUCT_CATEGORY_API, data)
  },

  updateProgramCategory: (body: ProgramCategoryModel, id: string) => {
    return axios.put(`${PRODUCT_CATEGORY_API}/${id}`, body)
  },

  deleteProgramCategory: (data: {id: string}) => {
    return axios.delete(PRODUCT_CATEGORY_API, {data})
  },

  enableProgramCategory: (data: Array<string>) => {
    const selectedProgramCategory = {
      programCategoryId: data,
    }
    return axios.patch(`${PRODUCT_CATEGORY_API}/enable`, selectedProgramCategory)
  },

  disableProgramCategory: (data: Array<string>) => {
    const selectedProgramCategory = {
      programCategoryId: data,
    }
    return axios.patch(`${PRODUCT_CATEGORY_API}/disable`, selectedProgramCategory)
  },

  singleEnableProgramCategory: (data: Array<string>) => {
    const selectedProgramCategory = {
      programCategoryId: [data],
    }
    return axios.patch(`${PRODUCT_CATEGORY_API}/enable`, selectedProgramCategory)
  },

  singleDisableProgramCategory: (data: Array<string>) => {
    const selectedProgramCategory = {
      programCategoryId: [data],
    }
    return axios.patch(`${PRODUCT_CATEGORY_API}/disable`, selectedProgramCategory)
  },
  sortProgramCategory: (body: SortProgramCategoryModel) => {
    return axios.patch(`${PRODUCT_CATEGORY_API}/sort`, body)
  },
}
