export const actionTypes = {
  // get
  GET_AGENCY_CATEGORY_START: 'GET_AGENCY_CATEGORY_START',
  GET_AGENCY_CATEGORY_SUCCESS: 'GET_AGENCY_CATEGORY_SUCCESS',
  GET_AGENCY_CATEGORY_FINISH: 'GET_AGENCY_CATEGORY_FINISH',

  // extended hours
  GET_EXTENDED_HOURS_START: 'GET_EXTENDED_HOURS_START',
  GET_EXTENDED_HOURS_SUCCESS: 'GET_EXTENDED_HOURS_SUCCESS',
  GET_EXTENDED_HOURS_FINISH: 'GET_EXTENDED_HOURS_FINISH',

  // Get Agency Data
  GET_AGENCY_DATA_START: 'GET_AGENCY_DATA_START',
  GET_AGENCY_DATA_SUCCESS: 'GET_AGENCY_DATA_SUCCESS',
  GET_AGENCY_DATA_FINISH: 'GET_AGENCY_DATA_FINISH',

  // Get Agency Data
  GET_AGENCY_LIST_DATA_START: 'GET_AGENCY_LIST_DATA_START',
  GET_AGENCY_LIST_DATA_SUCCESS: 'GET_AGENCY_LIST_DATA_SUCCESS',
  GET_AGENCY_LIST_DATA_FINISH: 'GET_AGENCY_LIST_DATA_FINISH',

  // Get Agency Data
  GET_ALL_AGENCY_DATA_START: 'GET_ALL_AGENCY_DATA_START',
  GET_ALL_AGENCY_DATA_SUCCESS: 'GET_ALL_AGENCY_DATA_SUCCESS',
  GET_ALL_AGENCY_DATA_FINISH: 'GET_ALL_AGENCY_DATA_FINISH',

  // add Agency
  ADD_AGENCY_START: 'ADD_AGENCY_START',
  ADD_AGENCY_SUCCESS: 'ADD_AGENCY_SUCCESS',
  ADD_AGENCY_FINISH: 'ADD_AGENCY_FINISH',
  RESET_AGENCY: 'RESET_AGENCY',

  // update Agency
  UPDATE_AGENCY_START: 'UPDATE_AGENCY_START',
  UPDATE_AGENCY_SUCCESS: 'UPDATE_AGENCY_SUCCESS',
  UPDATE_AGENCY_FINISH: 'UPDATE_AGENCY_FINISH',

  // delete Agency
  DELETE_AGENCY_START: 'DELETE_AGENCY_START',
  DELETE_AGENCY_SUCCESS: 'DELETE_AGENCY_SUCCESS',
  DELETE_AGENCY_FINISH: 'DELETE_AGENCY_FINISH',

  // Import Agency
  IMPORT_AGENCY_START: 'IMPORT_AGENCY_START',
  IMPORT_AGENCY_SUCCESS: 'IMPORT_AGENCY_SUCCESS',
  IMPORT_AGENCY_FINISH: 'IMPORT_AGENCY_FINISH',

  // Enable Agency
  ENABLE_AGENCY_REQUEST: 'ENABLE_AGENCY_REQUEST',
  ENABLE_AGENCY_SUCCESS: 'ENABLE_AGENCY_SUCCESS',
  ENABLE_AGENCY_FINISH: 'ENABLE_AGENCY_FINISH',

  // Disable Agency
  DISABLE_AGENCY_REQUEST: 'DISABLE_AGENCY_REQUEST',
  DISABLE_AGENCY_SUCCESS: 'DISABLE_AGENCY_SUCCESS',
  DISABLE_AGENCY_FINISH: 'DISABLE_AGENCY_FINISH',

  // Enable Agency
  SINGLE_ENABLE_AGENCY_REQUEST: 'SINGLE_ENABLE_AGENCY_REQUEST',
  SINGLE_ENABLE_AGENCY_SUCCESS: 'SINGLE_ENABLE_AGENCY_SUCCESS',
  SINGLE_ENABLE_AGENCY_FINISH: 'SINGLE_ENABLE_AGENCY_FINISH',

  // Disable Agency
  SINGLE_DISABLE_AGENCY_REQUEST: 'SINGLE_DISABLE_AGENCY_REQUEST',
  SINGLE_DISABLE_AGENCY_SUCCESS: 'SINGLE_DISABLE_AGENCY_SUCCESS',
  SINGLE_DISABLE_AGENCY_FINISH: 'SINGLE_DISABLE_AGENCY_FINISH',

  // get File csv xlsx
  EXPORT_AGENCY_FILE_START: 'EXPORT_AGENCY_FILE_START',
  EXPORT_AGENCY_FILE_SUCCESS: 'EXPORT_AGENCY_FILE_SUCCESS',
  EXPORT_AGENCY_FILE_FINISH: 'EXPORT_AGENCY_FILE_FINISH',

  EXPORT_AGENCY_TEMPLATE_FILE_START: 'EXPORT_AGENCY_TEMPLATE_FILE_START',
  EXPORT_AGENCY_TEMPLATE_FILE_SUCCESS: 'EXPORT_AGENCY_TEMPLATE_FILE_SUCCESS',
  EXPORT_AGENCY_TEMPLATE_FILE_FINISH: 'EXPORT_AGENCY_TEMPLATE_FILE_FINISH',
}
