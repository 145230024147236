import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getApplicationSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getOnlineApplication, params)
    yield put(actions.getApplicationSuccess(response?.data?.data))
    yield put(actions.getApplicationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getApplicationFinish())
  }
}

function* addApplicationSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addOnlineApplication, body)
    yield put(actions.addApplicationSuccess(response.data?.data))
    yield put(actions.addApplicationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addApplicationFinish())
  }
}

function* updateApplicationSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateOnlineApplication,
      body,
      action.payload?.id
    )
    yield put(actions.updateApplicationSuccess(response.data?.data))
    yield put(actions.updateApplicationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateApplicationFinish())
  }
}

function* deleteApplicationSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteOnlineApplication, body)
    yield put(actions.deleteApplicationSuccess(response.data?.data))
    yield put(actions.deleteApplicationFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteApplicationFinish())
  }
}

function* singleActivateApplication(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateOnlineApplication,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_FINISH})
  }
}

function* singleDeactivateApplication(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateOnlineApplication,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_FINISH})
  }
}

function* sortApplication(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortOnlineApplication, body)

    yield put({
      type: actionTypes.SORT_ONLINE_APPLICATION_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_ONLINE_APPLICATION_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_ONLINE_APPLICATION_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ONLINE_APPLICATION_START, getApplicationSaga)
  yield takeLatest(actionTypes.ADD_ONLINE_APPLICATION_START, addApplicationSaga)
  yield takeLatest(actionTypes.UPDATE_ONLINE_APPLICATION_START, updateApplicationSaga)
  yield takeLatest(actionTypes.DELETE_ONLINE_ONLINE_APPLICATION_START, deleteApplicationSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_REQUEST,
    singleActivateApplication
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_REQUEST,
    singleDeactivateApplication
  )
  yield takeLatest(actionTypes.SORT_ONLINE_APPLICATION_START, sortApplication)
}
