export const actionTypes = {
  //Add user role
  ADD_USER_ROLE_REQUEST: 'ADD_USER_ROLE_REQUEST',
  ADD_USER_ROLE_SUCCESS: 'ADD_USER_ROLE_SUCCESS',
  ADD_USER_ROLE_FINISH: 'ADD_USER_ROLE_FINISH',
  ADD_USER_ROLE_RESET: 'ADD_USER_ROLE_RESET',

  //Get user role
  GET_USER_ROLE_REQUEST: 'GET_USER_ROLE_REQUEST',
  GET_USER__ROLE_SUCCESS: 'GET_USER__ROLE_SUCCESS',
  GET_USER_ROLE_FINISH: 'GET_USER_ROLE_FINISH',

  //Get user role permission
  GET_USER_ROLE_PERMISSION_REQUEST: 'GET_USER_ROLE_PERMISSION_REQUEST',
  GET_USER__ROLE_PERMISSION_SUCCESS: 'GET_USER__ROLE_PERMISSION_SUCCESS',
  GET_USER_ROLE_PERMISSION_FINISH: 'GET_USER_ROLE_PERMISSION_FINISH',

  // update
  UPDATE_USER_ROLE_START: 'UPDATE_USER_ROLE_START',
  UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
  UPDATE_USER_ROLE_FINISH: 'UPDATE_USER_ROLE_FINISH',
  UPDATE_USER_ROLE_RESET: 'UPDATE_USER_ROLE_RESET',
}
