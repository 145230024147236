import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const EVENT = `${API_URL}/events`
export const GET_EVENT_BANNER_OPTION = `${API_URL}/events-option/banner-option`
export const GET_ALBUM_OPTION = `${API_URL}/events-option/album-option`

export const service = {
  getEvent: (params: ParamsModel) => {
    return axios.get(EVENT, {params})
  },

  addEvent: (data: any) => {
    return axios.post(EVENT, data)
  },

  updateEvent: (body: any, id: string) => {
    return axios.patch(`${EVENT}/${id}`, body)
  },

  deleteEvent: (data: DeleteModel) => {
    return axios.delete(EVENT, {data})
  },
  singleActivateEvent: (id: any) => {
    return axios.patch(`${EVENT}/enable`, id)
  },

  singleDeactivateEvent: (id: any) => {
    return axios.patch(`${EVENT}/disable`, id)
  },
  getAlbumOption: () => {
    return axios.get(GET_ALBUM_OPTION)
  },

  getBannerOption: () => {
    return axios.get(GET_EVENT_BANNER_OPTION)
  },
}
