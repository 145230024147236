import {ParamsModel} from 'src/app/modules/common/Model'
import {BannerModelOffer, DeleteBannerModelOffer, SortBannerModelOffer} from '../Model'
import {actionTypes} from './constants'
import {IBannerOfferState} from './reducer'

export const actions = {
  // get Banner
  getBannerOffer: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_BANNER_OFFER_START,
      payload: {params},
    }
  },

  getBannerOfferSuccess: (data: IBannerOfferState) => ({
    type: actionTypes.GET_BANNER_OFFER_SUCCESS,
    payload: data,
  }),
  getBannerOfferError: (data: IBannerOfferState) => ({
    type: actionTypes.GET_BANNER_OFFER_FINISH,
    payload: data,
  }),

  // get Banner
  getAllBannerOffer: () => {
    return {
      type: actionTypes.GET_ALL_BANNER_OFFER_START,
    }
  },

  getAllBannerOfferSuccess: (data: IBannerOfferState) => ({
    type: actionTypes.GET_ALL_BANNER_OFFER_SUCCESS,
    payload: data,
  }),
  getAllBannerOfferError: () => ({
    type: actionTypes.GET_ALL_BANNER_OFFER_FINISH,
  }),

  // get Color Option
  getBannerOfferColorOption: () => {
    return {
      type: actionTypes.GET_BANNER_COLOR_OPTION_START,
    }
  },
  getBannerOfferColorOptionSuccess: (data: IBannerOfferState) => ({
    type: actionTypes.GET_BANNER_COLOR_OPTION_SUCCESS,
    payload: data,
  }),
  getBannerOfferColorOptionError: () => ({
    type: actionTypes.GET_BANNER_COLOR_OPTION_FINISH,
  }),

  //Add Banner Action
  bannerOfferCreateBanner: (data: any) => ({
    type: actionTypes.ADD_BANNER_OFFER_REQUEST,
    payload: data,
  }),

  bannerOfferCreateBannerSuccess: (task: any) => ({
    type: actionTypes.ADD_BANNER_OFFER_SUCCESS,
    payload: task,
  }),
  bannerOfferCreateBannerFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_BANNER_OFFER_FINISH,
    payload: errorMsg,
  }),
  bannerOfferCreateBannerReset: () => ({
    type: actionTypes.ADD_BANNER_OFFER_RESET,
  }),

  //Activate and Deactivate Bulk Banner
  activateBannerOffer: (data: any) => ({
    type: actionTypes.ACTIVATE_BANNER_OFFER_REQUEST,
    payload: {data},
  }),
  deactivateBannerOffer: (data: any) => ({
    type: actionTypes.DEACTIVATE_BANNER_OFFER_REQUEST,
    payload: {data},
  }),

  //Single Activate and Deactivate Banner
  singleActivateBannerOffer: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_BANNER_OFFER_REQUEST,
    payload: {data},
  }),

  singleDeactivateBannerOffer: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BANNER_OFFER_REQUEST,
    payload: {data},
  }),

  // update Banner
  updateBannerOffer: (data: BannerModelOffer, id: string) => ({
    type: actionTypes.UPDATE_BANNER_OFFER_START,
    payload: {data, id},
  }),

  //delete and reset Banner
  deleteBannerOffer: (data: DeleteBannerModelOffer[]) => ({
    type: actionTypes.DELETE_BANNER_OFFER_START,
    payload: {bannerId: data},
  }),

  // sort
  sortBannerOffer: (data: SortBannerModelOffer) => ({
    type: actionTypes.SORT_BANNER_OFFER_START,
    payload: data,
  }),
  sortBannerOfferSuccess: (data: Array<BannerModelOffer>) => ({
    type: actionTypes.SORT_BANNER_OFFER_SUCCESS,
    payload: data,
  }),
  sortBannerOfferFinish: () => ({
    type: actionTypes.SORT_BANNER_OFFER_FINISH,
  }),
  sortBannerOfferReset: () => ({
    type: actionTypes.SORT_BANNER_OFFER_RESET,
  }),
}
