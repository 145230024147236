import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const intialAnalyticsData: IAnalyticDataState = {
  data: {
   
  },
 
  loading: false,
  success: false,
}

export interface IAnalyticDataState {
  data?: {
   
  }
  
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IAnalyticDataState = intialAnalyticsData,
  action: ActionWithPayload<IAnalyticDataState>
) => {
  switch (action.type) {
    //GET BankDetail DATA
    case actionTypes.GET_ANALYTIC_USER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ANALYTIC_USER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ANALYTIC_USER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

   
  
  

    default:
      return state
  }
}
export const reducer1 = (
  state: IAnalyticDataState = intialAnalyticsData,
  action: ActionWithPayload<IAnalyticDataState>
) => {
  switch (action.type) {
  case actionTypes.GET_COUNTRY_USER_START: {
    return {...state, loading: true}
  }

  case actionTypes.GET_COUNTRY_USER_SUCCESS: {
    return {...state, data: action.payload, loading: false}
  }

  case actionTypes.GET_COUNTRY_USER_FINISH: {
    const error = action.payload
    return {...state, error, loading: false}
  }



  default:
    return state
}}
export const reducer2 = (
  state: IAnalyticDataState = intialAnalyticsData,
  action: ActionWithPayload<IAnalyticDataState>
) => { switch (action.type) {
  case actionTypes.GET_UNIQUE_USER_START: {
    return {...state, loading: true}
  }

  case actionTypes.GET_UNIQUE_USER_SUCCESS: {
    return {...state, data: action.payload, loading: false}
  }

  case actionTypes.GET_UNIQUE_USER_FINISH: {
    const error = action.payload
    return {...state, error, loading: false}
  }

  default:
    return state
}
}