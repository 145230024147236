import {Action} from 'redux'
import {ApiManagerDetailModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialApiManagerDetailState: IApiManagerState = {
  data: {
    apiManager: [],
    meta: {},
  },
  sortApiManagerDetailData: [],
  apiManagerDetailFileOption: [],
  apiManagerDetailList: {album: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IApiManagerState {
  data: {
    apiManager: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortApiManagerDetailData?: IApiManagerState[]
  apiManagerDetailFileOption?: []
  apiManagerDetailList?: {album: ApiManagerDetailModel[] | any}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IApiManagerState = initialApiManagerDetailState,
  action: ActionWithPayload<IApiManagerState>
) => {
  switch (action.type) {
    //GET ApiManagerDetail Data
    case actionTypes.GET_API_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_API_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_API_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET ApiManagerDetail File type Data
    case actionTypes.GET_API_MANAGER_FILE_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_API_MANAGER_FILE_TYPE_SUCCESS: {
      return {
        ...state,
        ApiManagerDetailFileOption: action.payload?.apiManagerDetailFileOption,
        loading: false,
      }
    }

    case actionTypes.GET_API_MANAGER_FILE_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add apiManagerDetail
    case actionTypes.ADD_API_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_API_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_API_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_API_MANAGER_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update apiManagerDetail
    case actionTypes.UPDATE_API_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_API_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_API_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_API_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_API_MANAGER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_API_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_API_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_API_MANAGER_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_API_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_API_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_API_MANAGER_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_API_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_API_MANAGER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_API_MANAGER_SUCCESS: {
      const changedData: any = action.payload?.data?.apiManager
      let newData = state?.data?.apiManager?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state?.data?.meta, album: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_API_MANAGER_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_API_MANAGER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_API_MANAGER_SUCCESS: {
      const changedData: any = action.payload?.data?.apiManager
      let newData = state?.data?.apiManager?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state?.data?.meta, album: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_API_MANAGER_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Get apiManagerDetail Reducer
    case actionTypes.GET_ALL_API_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_API_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        apiManagerDetailList: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_API_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // sort
    case actionTypes.SORT_API_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_API_MANAGER_SUCCESS: {
      return {
        ...state,
        sortapiManagerDetailData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_API_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_API_MANAGER_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortapiManagerDetailData: [],
      }
    }
    default:
      return state
  }
}
