import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel, OptionModel} from '../Model'
import {actionTypes} from './constants'
// import {IEventState} from './reducer'
// import {EventModel} from '../Model/EventModel'
export const actions = {
  // GET EVENTS
  getEvent: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_EVENT_START,
    payload: params,
  }),

  getEventSuccess: (data: any) => ({
    type: actionTypes.GET_EVENT_SUCCESS,
    payload: data,
  }),
  getEventFinish: () => ({
    type: actionTypes.GET_EVENT_FINISH,
  }),
  getEventError: (error: unknown) => ({
    type: actionTypes.GET_EVENT_FINISH,
    payload: {error},
  }),

  // create EVENTS
  addEvent: (data: any) => ({
    type: actionTypes.ADD_EVENT_START,
    payload: data,
  }),
  addEventSuccess: (task: any) => ({
    type: actionTypes.ADD_EVENT_SUCCESS,
    payload: task,
  }),
  addEventFinish: () => ({
    type: actionTypes.ADD_EVENT_FINISH,
  }),
  addEventReset: () => ({
    type: actionTypes.ADD_EVENT_RESET,
  }),

  // update EVENTS

  updateEvent: (data: any, id: string) => ({
    type: actionTypes.UPDATE_EVENT_START,
    payload: {data, id},
  }),
  updateEventSuccess: (task: any) => ({
    type: actionTypes.UPDATE_EVENT_SUCCESS,
    payload: task,
  }),
  updateEventFinish: () => ({
    type: actionTypes.UPDATE_EVENT_FINISH,
  }),
  updateEventReset: () => ({
    type: actionTypes.UPDATE_EVENT_RESET,
  }),

  // DELETE EVENTS
  deleteEvent: (data: DeleteModel[]) => ({
    type: actionTypes.DELETE_EVENT_START,
    payload: {eventsId: data},
  }),
  deleteEventSuccess: (data: any) => ({
    type: actionTypes.DELETE_EVENT_SUCCESS,
    payload: data,
  }),
  deleteEventFinish: () => ({
    type: actionTypes.DELETE_EVENT_FINISH,
  }),
  // Active EVENTS
  activateEvent: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_EVENT_REQUEST,
    payload: {eventsId: id},
  }),
  //  deactive EVENTS
  deactivateEvent: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_EVENT_REQUEST,
    payload: {eventsId: id},
  }),
  // single active EVENTS
  singleActivateEvent: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_EVENT_REQUEST,
    payload: {eventsId: [id]},
  }),
  // single deactive EVENTS
  singleDeactivateEvent: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_EVENT_REQUEST,
    payload: {eventsId: [id]},
  }),

  //GET BANNER OPTION ACTIONS
  getBannerOption: () => ({type: actionTypes.GET_EVENT_BANNER_OPTION_START}),

  getBannerOptionSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_EVENT_BANNER_OPTION_SUCCESS,
    payload: data,
  }),
  getBannerOptionError: (error: string) => ({
    type: actionTypes.GET_EVENT_BANNER_OPTION_FINISH,
    payload: {error},
  }),

  //GET ALBUM OPTION ACTIONS
  getAlbumOption: () => ({type: actionTypes.GET_ALBUM_OPTION_START}),

  getAlbumOptionSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_ALBUM_OPTION_SUCCESS,
    payload: data,
  }),
  getAlbumOptionError: (error: string) => ({
    type: actionTypes.GET_ALBUM_OPTION_FINISH,
    payload: {error},
  }),
}
