import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { SortType } from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'

import { isEmpty } from 'lodash'
import { StateParamsModel } from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import * as introductory from '../index'
import AddIntroductory from './AddIntroductory'
import checkMultiLanguageColumns from '../../common/checkMultiLanguageColumns'

const IntroductoryManager = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [selectedData, setSelectedData] = useState<Array<string>>([])
  const [openSortModal, setOpenSortModal] = useState(false)

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const introductoryData: any = useSelector((state: any) => state.introductory)

  const {
    activateSuccess,
    deactivateSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    sortIntroductoryData,
    success,
    loading,
  } = introductoryData

  useEffect(() => {
    dispatch(introductory.actions.getIntroductory(params))
  }, [params])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const handleToggleAction = (data: { [key: string]: string }) => {
    const formData = { id: data?.id }
    data?.status
      ? dispatch(introductory.actions.singleDeactivateIntroductory(formData))
      : dispatch(introductory.actions.singleActivateIntroductory(formData))
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle size='sm' checked={rowData.status} onClick={() => handleToggleAction(rowData)} data-cy='toggle-button' />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'SN',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Title(E.N)',
      dataKey: 'title',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },
    {
      label: 'Title(N.P)',
      dataKey: 'title_np',
      flexGrow: 1,
      cell: <Cell dataKey='title_np' />,
      isNepaliColumn: true,
      sortable: true,
    },
    {
      label: 'link',
      dataKey: 'link',
      flexGrow: 1,
      cell: <Cell dataKey='link' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    if (introductoryData?.deleteSuccess) {
      toast.success('Introductory deleted successfully')
      dispatch(introductory?.actions.getIntroductory(params))
    }
  }, [introductoryData])

  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success('Introductory activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success('Introductory deactivated successfully')
    }
    handleChecked([])
  }, [singleActivateSuccess, singleDeactivateSuccess])

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Introductory activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Introductory deactivated successfully')
    }

    handleChecked([])
    dispatch(introductory?.actions.getIntroductory(params))
  }, [activateSuccess, deactivateSuccess])

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  const activateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(introductory.actions.activateIntroductory(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(introductory.actions.deactivateIntroductory(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleChecked = (values: any) => {
    setCheckedValues(values)
  }

  const data = introductoryData?.data?.introductory
    ? introductoryData?.data?.introductory?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item?.id,
      menus: item?.menus?.map((menuId: any) => menuId?.id),
      title: item?.title,
      title_np: item?.titleNp,
      subTitle: item?.subTitle,
      subTitle_np: item?.subTitleNp,
      link: item?.link,
      logo: item?.logo,
      status: item?.status,
    }))
    : []

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value) => ({ id: value }))
    dispatch(introductory?.actions?.deleteIntroductory(id))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(introductory?.actions.getIntroductory(params))
  }

  const handleSubmitSort = (data: Array<any>) => {
    let body = {
      introductoryId: data?.map((item) => ({ id: item?.keyId })),
    }
    dispatch(introductory?.actions.sortIntroductory(body))
  }

  useEffect(() => {
    if (!isEmpty(sortIntroductoryData) && success) {
      toast.success('Introductory sorted successfully')
      dispatch(introductory?.actions.sortIntroductoryReset())
      setParams({ ...params, limit: 10 })
      dispatch(introductory?.actions.getIntroductory(params))
      setOpenSortModal(false)
    }
  }, [sortIntroductoryData, success])

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Introductory Manager'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          sortShow={true}
          data={data}
          sortButtonName='Sort'
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleRefresh}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={checkMultiLanguageColumns(columns)}
            data={data}
            showLoading={loading}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={introductoryData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
          />
          {open && (
            <AddIntroductory
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => modalSubmit()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default IntroductoryManager
