import {Action} from 'redux'
import { ReviewModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialReviewState: IReviewState = {
  data: {
    review: [],
    meta: {},
  },
  
  reviewList: {review: []},
  loading: false,
  success: false,
 
}

export interface IReviewState {
  data: {
    review: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  reviewList?: {review: ReviewModel[] | any}
  loading: false
  success: false
 
}

export const reducer = (
  state: IReviewState = initialReviewState,
  action: ActionWithPayload<IReviewState>
) => {
  switch (action.type) {
    //Get Review Reducer
    case actionTypes.GET_REVIEW_RATING_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_REVIEW_RATING_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_REVIEW_RATING_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

     //Get Review Status
     case actionTypes.GET_REVIEW_STATUS_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_REVIEW_STATUS_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_REVIEW_STATUS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.EXPORT_REVIEW_FILE_START: {
        return {...state, loading: true}
      }
  
      case actionTypes.EXPORT_REVIEW_FILE_SUCCESS: {
        return {...state, success: true, loading: false}
      }
  
      case actionTypes.EXPORT_REVIEW_FILE_FINISH: {
        const error = action.payload
        return {...state, error, success: false, loading: false}
      }
   
    default:
      return state
  }
}
