import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ServicePartnerModel} from '../Model'

function* getServicePartnerSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getServicePartner, params)
    yield put(actions.getServicePartnerSuccess(response?.data?.data))
    yield put(actions.getServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getServicePartnerFinish())
  }
}

function* addServicePartnerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addServicePartner, body)
    yield put(actions.addServicePartnerSuccess(response.data?.data))
    yield put(actions.addServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addServicePartnerFinish())
  }
}

function* enableServicePartnerSaga(action: ActionModel) {
  try {
    const selectedServicePartner: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableServicePartner, selectedServicePartner)
    yield put(actions.enableServicePartnerSuccess(response?.data))
    yield put(actions.enableServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableServicePartnerFinish())
  }
}

function* disableServicePartnerSaga(action: ActionModel) {
  try {
    const selectedServicePartner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.disableServicePartner,
      selectedServicePartner
    )
    yield put(actions.disableServicePartnerSuccess(response?.data))
    yield put(actions.disableServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableServicePartnerFinish())
  }
}

function* singleEnableServicePartnerSaga(action: ActionModel) {
  try {
    const selectedServicePartner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleEnableServicePartner,
      selectedServicePartner
    )
    yield put(actions.singleEnableServicePartnerSuccess(response?.data))
    yield put(actions.singleEnableServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableServicePartnerFinish())
  }
}

function* singleDisableServicePartnerSaga(action: ActionModel) {
  try {
    const selectedServicePartner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDisableServicePartner,
      selectedServicePartner
    )
    yield put(actions.singleDisableServicePartnerSuccess(response?.data))
    yield put(actions.singleDisableServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableServicePartnerFinish())
  }
}

function* updateServicePartnerSaga(action: ActionModel) {
  try {
    const body: ServicePartnerModel = action.payload
    const params: any = action
    const response: ResponseModel = yield call(service.updateServicePartner, body, params.id)

    yield put(actions.updateServicePartnerSuccess(response.data?.data))
    yield put(actions.updateServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateServicePartnerFinish())
  }
}

function* deleteServicePartnerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteServicePartner, body)

    yield put(actions.deleteServicePartnerSuccess(response.data?.data))
    yield put(actions.deleteServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteServicePartnerFinish())
  }
}

function* downloadServicePartnerSampleSaga(action:ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.downloadSample,fileName)
    yield put(actions.downloadServicePartnerSampleSuccess(response?.data))
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.downloadServicePartnerSampleError(err))
  }
}
function* importServicePartnerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importServicePartner, body)
    yield put(actions.importServicePartnerSuccess(response?.data))
    yield put(actions.importServicePartnerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importServicePartnerFinish())
  }
}
function* servicePartnerCountrySaga() {
  try {
    const response: ResponseModel = yield call(service.getServicePartnerCountryList)
    yield put(actions.servicePartnerCountryListSuccess(response?.data?.data))
    yield put(actions.servicePartnerCountryListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.servicePartnerCountryListFinish())
  }
}
function* servicePartnerCurrencySaga() {
  try {
    const response: ResponseModel = yield call(service.getServicePartnerCurrencyList)
    yield put(actions.servicePartnerCurrencyListSuccess(response?.data?.data))
    yield put(actions.servicePartnerCurrencyListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.servicePartnerCurrencyListFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_SERVICE_PARTNER_START, getServicePartnerSaga)
  yield takeLatest(actionTypes.ADD_SERVICE_PARTNER_START, addServicePartnerSaga)
  yield takeLatest(actionTypes.UPDATE_SERVICE_PARTNER_START, updateServicePartnerSaga)
  yield takeLatest(actionTypes.DELETE_SERVICE_PARTNER_START, deleteServicePartnerSaga)
  yield takeLatest(actionTypes.ENABLE_SERVICE_PARTNER_REQUEST, enableServicePartnerSaga)
  yield takeLatest(actionTypes.DISABLE_SERVICE_PARTNER_REQUEST, disableServicePartnerSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_REQUEST,
    singleEnableServicePartnerSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_REQUEST,
    singleDisableServicePartnerSaga
  )
  yield takeLatest(
    actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_REQUEST,
    downloadServicePartnerSampleSaga
  )
  yield takeLatest(actionTypes.IMPORT_SERVICE_PARTNER_REQUEST, importServicePartnerSaga)
  yield takeLatest(actionTypes.GET_SERVICE_PARTNER_COUNTRY_REQUEST, servicePartnerCountrySaga)
  yield takeLatest(actionTypes.GET_SERVICE_PARTNER_CURRENCY_REQUEST, servicePartnerCurrencySaga)
}
