import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {PartnersModel} from '../Model/PartnersModel'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SortPartnersActionModel, SortPartnersModel} from '../Model'

function* getPartnersSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getPartners, params)
    yield put(actions.getPartnersSuccess(response?.data?.data))
    yield put(actions.getPartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getPartnersFinish())
  }
}

function* getAllPartnersSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllPartners)
    yield put(actions.getAllPartnersSuccess(response?.data?.data))
    yield put(actions.getAllPartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllPartnersFinish())
  }
}

function* addPartnersSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addPartners, body)
    yield put(actions.addPartnersSuccess(response.data?.data))
    yield put(actions.addPartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addPartnersFinish())
  }
}

function* enablePartnersSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enablePartners, selectedUsers)
    yield put(actions.enablePartnersSuccess(response?.data))
    yield put(actions.enablePartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enablePartnersFinish())
  }
}

function* disablePartnersSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disablePartners, selectedUsers)
    yield put(actions.disablePartnersSuccess(response?.data))
    yield put(actions.disablePartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enablePartnersFinish())
  }
}

function* singleEnablePartnersSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnablePartners, selectedUsers)
    yield put(actions.singleEnablePartnersSuccess(response?.data))
    yield put(actions.singleEnablePartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnablePartnersFinish())
  }
}

function* singleDisablePartnersSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisablePartners, selectedUsers)
    yield put(actions.singleDisablePartnersSuccess(response?.data))
    yield put(actions.singleDisablePartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisablePartnersFinish())
  }
}

function* updatePartnersSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updatePartners, body, action.payload?.id)
    yield put(actions.updatePartnersSuccess(response.data?.data))
    yield put(actions.updatePartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updatePartnersFinish())
  }
}

function* deletePartnersSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deletePartners, body)
    yield put(actions.deletePartnersSuccess(response.data?.data))
    yield put(actions.deletePartnersFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deletePartnersFinish())
  }
}

function* sortPartners(action: SortPartnersActionModel) {
  try {
    const body: SortPartnersModel = action.payload
    const response: ResponseModel = yield call(service.sortPartners, body)

    yield put({
      type: actionTypes.SORT_PARTNERS_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_PARTNERS_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_PARTNERS_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_PARTNERS_START, getPartnersSaga)
  yield takeLatest(actionTypes.GET_ALL_PARTNERS_START, getAllPartnersSaga)
  yield takeLatest(actionTypes.ADD_PARTNERS_START, addPartnersSaga)
  yield takeLatest(actionTypes.UPDATE_PARTNERS_START, updatePartnersSaga)
  yield takeLatest(actionTypes.DELETE_PARTNERS_START, deletePartnersSaga)
  yield takeLatest(actionTypes.ENABLE_PARTNERS_REQUEST, enablePartnersSaga)
  yield takeLatest(actionTypes.DISABLE_PARTNERS_REQUEST, disablePartnersSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_PARTNERS_REQUEST, singleEnablePartnersSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_PARTNERS_REQUEST, singleDisablePartnersSaga)
  yield takeLatest(actionTypes.SORT_PARTNERS_START, sortPartners)
}
