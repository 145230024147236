import {Action} from 'redux'
import {NotificationModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialNotificationState: INotificationState = {
  data: {
    notification: [],
    meta: [],
  },
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface INotificationState {
  data?: {
    notification?: NotificationModel[]
    meta?: {[key: string]: string | number}[]
  }
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: INotificationState = initialNotificationState,
  action: ActionWithPayload<INotificationState>
) => {
  switch (action.type) {
    //GET Notification DATA
    case actionTypes.GET_NOTIFICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_NOTIFICATION_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_NOTIFICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add Notification
    case actionTypes.ADD_NOTIFICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_NOTIFICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_NOTIFICATION: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Notification
    case actionTypes.UPDATE_NOTIFICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_NOTIFICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_NOTIFICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_NOTIFICATION_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    default:
      return state
  }
}
