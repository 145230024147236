import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CARD_MASTER = `${API_URL}/card-master`

export const service = {
  getAllCardMaster: (id: string) => {
    return axios.get(`${CARD_MASTER}/list`)
  },
  getSpecificCardMaster: (id: string) => {
    return axios.get(`${CARD_MASTER}/list/${id}`)
  },
  getCardMaster: (params: ParamsModel) => {
    return axios.get(CARD_MASTER, {params})
  },

  addCardMaster: (data: any) => {
    return axios.post(CARD_MASTER, data)
  },

  updateCardMaster: (body: any, id: string) => {
    return axios.put(`${CARD_MASTER}/${id}`, body)
  },

  deleteCardMaster: (data: any) => {
    return axios.delete(`${CARD_MASTER}/bulk-delete`, {data})
  },
  singleActivateCardMaster: (id: any) => {
    return axios.patch(`${CARD_MASTER}/enable`, id)
  },

  singleDeactivateCardMaster: (id: any) => {
    return axios.patch(`${CARD_MASTER}/disable`, id)
  },

  sortTutorialCardMaster: (body: any) => {
    return axios.patch(`${CARD_MASTER}/sort`, body)
  },
}
