export const actionTypes = {
  // get
  GET_ALL_CERTIFICATE_START: 'GET_ALL_CERTIFICATE_START',
  GET_ALL_CERTIFICATE_SUCCESS: 'GET_ALL_CERTIFICATE_SUCCESS',
  GET_ALL_CERTIFICATE_FINISH: 'GET_ALL_CERTIFICATE_FINISH',

  // extended hours
  GET_CERTIFICATE_START: 'GET_CERTIFICATE_START',
  GET_CERTIFICATE_SUCCESS: 'GET_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_FINISH: 'GET_CERTIFICATE_FINISH',

  // add branch
  ADD_CERTIFICATE_START: 'ADD_CERTIFICATE_START',
  ADD_CERTIFICATE_SUCCESS: 'ADD_CERTIFICATE_SUCCESS',
  ADD_CERTIFICATE_FINISH: 'ADD_CERTIFICATE_FINISH',
  RESET_CERTIFICATE: 'RESET_CERTIFICATE',

  // update CERTIFICATE
  UPDATE_CERTIFICATE_START: 'UPDATE_CERTIFICATE_START',
  UPDATE_CERTIFICATE_SUCCESS: 'UPDATE_CERTIFICATE_SUCCESS',
  UPDATE_CERTIFICATE_FINISH: 'UPDATE_CERTIFICATE_FINISH',

  // delete CERTIFICATE
  DELETE_CERTIFICATE_START: 'DELETE_CERTIFICATE_START',
  DELETE_CERTIFICATE_SUCCESS: 'DELETE_CERTIFICATE_SUCCESS',
  DELETE_CERTIFICATE_FINISH: 'DELETE_CERTIFICATE_FINISH',

  // Enable CERTIFICATE
  ENABLE_CERTIFICATE_REQUEST: 'ENABLE_CERTIFICATE_REQUEST',
  ENABLE_CERTIFICATE_SUCCESS: 'ENABLE_CERTIFICATE_SUCCESS',
  ENABLE_CERTIFICATE_FINISH: 'ENABLE_CERTIFICATE_FINISH',

  // Disable CERTIFICATE
  DISABLE_CERTIFICATE_REQUEST: 'DISABLE_CERTIFICATE_REQUEST',
  DISABLE_CERTIFICATE_SUCCESS: 'DISABLE_CERTIFICATE_SUCCESS',
  DISABLE_CERTIFICATE_FINISH: 'DISABLE_CERTIFICATE_FINISH',

  // Enable CERTIFICATE
  SINGLE_ENABLE_CERTIFICATE_REQUEST: 'SINGLE_ENABLE_CERTIFICATE_REQUEST',
  SINGLE_ENABLE_CERTIFICATE_SUCCESS: 'SINGLE_ENABLE_CERTIFICATE_SUCCESS',
  SINGLE_ENABLE_CERTIFICATE_FINISH: 'SINGLE_ENABLE_CERTIFICATE_FINISH',

  // Disable CERTIFICATE
  SINGLE_DISABLE_CERTIFICATE_REQUEST: 'SINGLE_DISABLE_CERTIFICATE_REQUEST',
  SINGLE_DISABLE_CERTIFICATE_SUCCESS: 'SINGLE_DISABLE_CERTIFICATE_SUCCESS',
  SINGLE_DISABLE_CERTIFICATE_FINISH: 'SINGLE_DISABLE_CERTIFICATE_FINISH',

  // sort
  SORT_CERTIFICATE_START: 'SORT_CERTIFICATE_START',
  SORT_CERTIFICATE_SUCCESS: 'SORT_CERTIFICATE_SUCCESS',
  SORT_CERTIFICATE_FINISH: 'SORT_CERTIFICATE_FINISH',
  SORT_CERTIFICATE_RESET: 'SORT_CERTIFICATE_RESET',
  
  GET_CERTIFICATE_LIST_START: 'GET_CERTIFICATE_LIST_START',
  GET_CERTIFICATE_LIST_SUCCESS: 'GET_CERTIFICATE_LIST_SUCCESS',
  GET_CERTIFICATE_LIST_FINISH: 'GET_CERTIFICATE_LIST_FINISH',
  
}
