export const actionTypes: any = {
  // GET
  GET_AUCTION_START: 'GET_AUCTION_START',
  GET_AUCTION_SUCCESS: 'GET_AUCTION_SUCCESS',
  GET_AUCTION_FINISH: 'GET_AUCTION_FINISH',

  // ADD
  ADD_AUCTION_START: 'ADD_AUCTION_START',
  ADD_AUCTION_SUCCESS: 'ADD_AUCTION_SUCCESS',
  ADD_AUCTION_FINISH: 'ADD_AUCTION_FINISH',
  RESET_AUCTION: 'RESET_AUCTION',

  // UPDATE
  UPDATE_AUCTION_START: 'UPDATE_AUCTION_START',
  UPDATE_AUCTION_SUCCESS: 'UPDATE_AUCTION_SUCCESS',
  UPDATE_AUCTION_FINISH: 'UPDATE_AUCTION_FINISH',

  // Delete
  DELETE_AUCTION_START: 'DELETE_AUCTION_START',
  DELETE_AUCTION_SUCCESS: 'DELETE_AUCTION_SUCCESS',
  DELETE_AUCTION_FINISH: 'DELETE_AUCTION_FINISH',

  // Enable
  ENABLE_AUCTION_REQUEST: 'ENABLE_AUCTION_REQUEST',
  ENABLE_AUCTION_SUCCESS: 'ENABLE_AUCTION_SUCCESS',
  ENABLE_AUCTION_FINISH: 'ENABLE_AUCTION_FINISH',

  // Disable
  DISABLE_AUCTION_REQUEST: 'DISABLE_AUCTION_REQUEST',
  DISABLE_AUCTION_SUCCESS: 'DISABLE_AUCTION_SUCCESS',
  DISABLE_AUCTION_FINISH: 'DISABLE_AUCTION_FINISH',

  // Enable
  SINGLE_ENABLE_AUCTION_REQUEST: 'SINGLE_ENABLE_AUCTION_REQUEST',
  SINGLE_ENABLE_AUCTION_SUCCESS: 'SINGLE_ENABLE_AUCTION_SUCCESS',
  SINGLE_ENABLE_AUCTION_FINISH: 'SINGLE_ENABLE_AUCTION_FINISH',

  // Disable
  SINGLE_DISABLE_AUCTION_REQUEST: 'SINGLE_DISABLE_AUCTION_REQUEST',
  SINGLE_DISABLE_AUCTION_SUCCESS: 'SINGLE_DISABLE_AUCTION_SUCCESS',
  SINGLE_DISABLE_AUCTION_FINISH: 'SINGLE_DISABLE_AUCTION_FINISH',

  // sort
  SORT_AUCTION_START: 'SORT_AUCTION_START',
  SORT_AUCTION_SUCCESS: 'SORT_AUCTION_SUCCESS',
  SORT_AUCTION_FINISH: 'SORT_AUCTION_FINISH',
  SORT_AUCTION_RESET: 'SORT_AUCTION_RESET',

  // GET AUCTION_TYPE
  GET_AUCTION_TYPE_LIST_START: 'GET_AUCTION_TYPE_LIST_START',
  GET_AUCTION_TYPE_LIST_SUCCESS: 'GET_AUCTION_TYPE_LIST_SUCCESS',
  GET_AUCTION_TYPE_LIST_FINISH: 'GET_AUCTION_TYPE_LIST_FINISH',

  // GET PROPERTY_TYPE
  GET_PROPERTY_TYPE_LIST_START: 'GET_PROPERTY_TYPE_LIST_START',
  GET_PROPERTY_TYPE_LIST_SUCCESS: 'GET_PROPERTY_TYPE_LIST_SUCCESS',
  GET_PROPERTY_TYPE_LIST_FINISH: 'GET_PROPERTY_TYPE_LIST_FINISH',

  // GET AUCTION_IMAGE_TYPE
  GET_AUCTION_IMAGE_TYPE_LIST_START: 'GET_AUCTION_IMAGE_TYPE_LIST_START',
  GET_AUCTION_IMAGE_TYPE_LIST_SUCCESS: 'GET_AUCTION_IMAGE_TYPE_LIST_SUCCESS',
  GET_AUCTION_IMAGE_TYPE_LIST_FINISH: 'GET_AUCTION_IMAGE_TYPE_LIST_FINISH',
}
