import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const LEAD_FORM_API = `${API_URL}/leadform`

export const service = {
  getLeadFormDetail: (params: ParamsModel) => {
    return axios.get(LEAD_FORM_API, {params})
  },

  //export file
  exportLeadFormDetailFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${LEAD_FORM_API}/export`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
