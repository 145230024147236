import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCollegeDetailModel, SortCollegeDetailModel} from '../Model'
import {CollegeDetailModel} from '../Model/CollegeDetailModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const COLLEGE_DETAIL_DATA = `${API_URL}/about-college`

export const service = {
  getCollegeDetail: (params: ParamsModel) => {
    return axios.get(COLLEGE_DETAIL_DATA, {params})
  },
  getAllCollegeDetail: () => {
    return axios.get(`${COLLEGE_DETAIL_DATA}/list`)
  },

  addCollegeDetail: (data: any) => {
    return axios.post(COLLEGE_DETAIL_DATA, data)
  },

  updateCollegeDetail: (body: CollegeDetailModel, id: string) => {
    return axios.patch(`${COLLEGE_DETAIL_DATA}/${id}`, body)
  },

  deleteCollegeDetail: (data: DeleteCollegeDetailModel) => {
    return axios.delete(`${COLLEGE_DETAIL_DATA}/bulk-delete`, {data})
  },

  enableCollegeDetail: (data: Array<string>) => {
    const selectedCollegeDetail = {
      aboutCollegeId: data,
    }
    return axios.patch(`${COLLEGE_DETAIL_DATA}/enable`, selectedCollegeDetail)
  },

  disableCollegeDetail: (data: Array<string>) => {
    const selectedCollegeDetail = {
      aboutCollegeId: data,
    }
    return axios.patch(`${COLLEGE_DETAIL_DATA}/disable`, selectedCollegeDetail)
  },

  singleEnableCollegeDetail: (data: Array<string>) => {
    const selectedCollegeDetail = {
      aboutCollegeId: [data],
    }
    return axios.patch(`${COLLEGE_DETAIL_DATA}/enable`, selectedCollegeDetail)
  },

  singleDisableCollegeDetail: (data: Array<string>) => {
    const selectedCollegeDetail = {
      aboutCollegeId: [data],
    }
    return axios.patch(`${COLLEGE_DETAIL_DATA}/disable`, selectedCollegeDetail)
  },

  sortCollegeDetail: (body: SortCollegeDetailModel) => {
    return axios.patch(`${COLLEGE_DETAIL_DATA}/sort`, body)
  },
  getAboutTypeOption: () => {
    return axios.get(`${COLLEGE_DETAIL_DATA}/type-option`)
  },
}
