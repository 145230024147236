import {Action} from 'redux'
import {FeaturedModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialFeaturedState: IFeaturedState = {
  data: {
    featuredImage: [],
    meta: {},
  },
  featuredImageType: [],
  sortFeaturedData: [],
  featuredFileOption: [],
  featuredList: {featuredImage: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
  sortSuccess: false,
}

export interface IFeaturedState {
  data: {
    featuredImage: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  featuredImageType: [],
  sortFeaturedData?: IFeaturedState[]
  featuredFileOption?: []
  featuredList?: {featuredImage: FeaturedModel[] | any}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
  sortSuccess?: boolean
}

export const reducer = (
  state: IFeaturedState = initialFeaturedState,
  action: ActionWithPayload<IFeaturedState>
) => {
  switch (action.type) {
    //GET Featured Data
    case actionTypes.GET_FEATURED_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_FEATURED_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_FEATURED_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET Featured File type Data
    case actionTypes.GET_FEATURED_FILE_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_FEATURED_FILE_TYPE_SUCCESS: {
      return {...state, featuredFileOption: action.payload?.featuredFileOption, loading: false}
    }

    case actionTypes.GET_FEATURED_FILE_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }


    //GET Featured Image type Data
    case actionTypes.GET_FEATURED_IMAGE_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_FEATURED_IMAGE_TYPE_SUCCESS: {
      return {...state, featuredImageType: action.payload?.featuredImageType, loading: false}
    }

    case actionTypes.GET_FEATURED_IMAGE_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add Featured
    case actionTypes.ADD_FEATURED_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_FEATURED_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_FEATURED_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_FEATURED_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Featured
    case actionTypes.UPDATE_FEATURED_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_FEATURED_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_FEATURED_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_FEATURED_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_FEATURED_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_FEATURED_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_FEATURED_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_FEATURED_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_FEATURED_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_FEATURED_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_FEATURED_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_FEATURED_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_FEATURED_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_FEATURED_SUCCESS: {
      const changedData: any = action.payload?.data?.featuredImage
      let newData = state?.data?.featuredImage?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state?.data?.meta, featuredImage: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_FEATURED_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_FEATURED_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_FEATURED_SUCCESS: {
      const changedData: any = action.payload?.data?.featuredImage
      let newData = state?.data?.featuredImage?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state?.data?.meta, featuredImage: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_FEATURED_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Get Featured Reducer
    case actionTypes.GET_ALL_FEATURED_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_FEATURED_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        featuredList: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_FEATURED_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // sort
    case actionTypes.SORT_FEATURED_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_FEATURED_SUCCESS: {
      return {
        ...state,
        sortFeaturedData: action?.payload,
        sortSuccess: true,
        loading: false,
      }
    }

    case actionTypes.SORT_FEATURED_FINISH: {
      return {
        ...state,
        loading: false,
        sortSuccess: false,
      }
    }

    case actionTypes.SORT_FEATURED_RESET: {
      return {
        ...state,
        loading: false,
        sortSuccess: false,
        sortFeaturedData: [],
      }
    }
    default:
      return state
  }
}
