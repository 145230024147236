export const actionTypes = {
  // get
  GET_ALL_SUBSCRIBE_TYPE_START: 'GET_ALL_SUBSCRIBE_TYPE_START',
  GET_ALL_SUBSCRIBE_TYPE_SUCCESS: 'GET_ALL_SUBSCRIBE_TYPE_SUCCESS',
  GET_ALL_SUBSCRIBE_TYPE_FINISH: 'GET_ALL_SUBSCRIBE_TYPE_FINISH',

  // extended hours
  GET_SUBSCRIBE_TYPE_START: 'GET_SUBSCRIBE_TYPE_START',
  GET_SUBSCRIBE_TYPE_SUCCESS: 'GET_SUBSCRIBE_TYPE_SUCCESS',
  GET_SUBSCRIBE_TYPE_FINISH: 'GET_SUBSCRIBE_TYPE_FINISH',

  // add branch
  ADD_SUBSCRIBE_TYPE_START: 'ADD_SUBSCRIBE_TYPE_START',
  ADD_SUBSCRIBE_TYPE_SUCCESS: 'ADD_SUBSCRIBE_TYPE_SUCCESS',
  ADD_SUBSCRIBE_TYPE_FINISH: 'ADD_SUBSCRIBE_TYPE_FINISH',
  RESET_SUBSCRIBE_TYPE: 'RESET_SUBSCRIBE_TYPE',

  // update SUBSCRIBE_TYPE
  UPDATE_SUBSCRIBE_TYPE_START: 'UPDATE_SUBSCRIBE_TYPE_START',
  UPDATE_SUBSCRIBE_TYPE_SUCCESS: 'UPDATE_SUBSCRIBE_TYPE_SUCCESS',
  UPDATE_SUBSCRIBE_TYPE_FINISH: 'UPDATE_SUBSCRIBE_TYPE_FINISH',

  // delete SUBSCRIBE_TYPE
  DELETE_SUBSCRIBE_TYPE_START: 'DELETE_SUBSCRIBE_TYPE_START',
  DELETE_SUBSCRIBE_TYPE_SUCCESS: 'DELETE_SUBSCRIBE_TYPE_SUCCESS',
  DELETE_SUBSCRIBE_TYPE_FINISH: 'DELETE_SUBSCRIBE_TYPE_FINISH',

  // Enable SUBSCRIBE_TYPE
  ENABLE_SUBSCRIBE_TYPE_REQUEST: 'ENABLE_SUBSCRIBE_TYPE_REQUEST',
  ENABLE_SUBSCRIBE_TYPE_SUCCESS: 'ENABLE_SUBSCRIBE_TYPE_SUCCESS',
  ENABLE_SUBSCRIBE_TYPE_FINISH: 'ENABLE_SUBSCRIBE_TYPE_FINISH',

  // Disable SUBSCRIBE_TYPE
  DISABLE_SUBSCRIBE_TYPE_REQUEST: 'DISABLE_SUBSCRIBE_TYPE_REQUEST',
  DISABLE_SUBSCRIBE_TYPE_SUCCESS: 'DISABLE_SUBSCRIBE_TYPE_SUCCESS',
  DISABLE_SUBSCRIBE_TYPE_FINISH: 'DISABLE_SUBSCRIBE_TYPE_FINISH',

  // Enable SUBSCRIBE_TYPE
  SINGLE_ENABLE_SUBSCRIBE_TYPE_REQUEST: 'SINGLE_ENABLE_SUBSCRIBE_TYPE_REQUEST',
  SINGLE_ENABLE_SUBSCRIBE_TYPE_SUCCESS: 'SINGLE_ENABLE_SUBSCRIBE_TYPE_SUCCESS',
  SINGLE_ENABLE_SUBSCRIBE_TYPE_FINISH: 'SINGLE_ENABLE_SUBSCRIBE_TYPE_FINISH',

  // Disable SUBSCRIBE_TYPE
  SINGLE_DISABLE_SUBSCRIBE_TYPE_REQUEST: 'SINGLE_DISABLE_SUBSCRIBE_TYPE_REQUEST',
  SINGLE_DISABLE_SUBSCRIBE_TYPE_SUCCESS: 'SINGLE_DISABLE_SUBSCRIBE_TYPE_SUCCESS',
  SINGLE_DISABLE_SUBSCRIBE_TYPE_FINISH: 'SINGLE_DISABLE_SUBSCRIBE_TYPE_FINISH',
}
