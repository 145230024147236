import {ParamsModel} from 'src/app/modules/common/Model'
import { IExportFileState } from 'src/app/modules/network/components/branchless'
import { ReviewStatusModel } from '../Model'
import {actionTypes} from './constants'

export const actions = {
  // get Review status

   getReviewStatus: () => ({
    type: actionTypes.GET_REVIEW_STATUS_START,
  }),

  getReviewStatusSuccess: (data: ReviewStatusModel[]) => ({
    type: actionTypes.GET_REVIEW_STATUS_SUCCESS,
    payload: data,
  }),
  getReviewStatusFinish: () => ({
    type: actionTypes.GET_REVIEW_STATUS_FINISH,
  }),
  getReviewStatusError: (error: unknown) => ({
    type: actionTypes.GET_REVIEW_STATUS_FINISH,
    payload: {error},
  }),


 
}
