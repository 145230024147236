import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialApplicationState: IApplicationState = {
  data: {
    application: [],
    meta: {},
  },
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface IApplicationState {
  data?: {
    application?: []
    meta?: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IApplicationState = initialApplicationState,
  action: ActionWithPayload<IApplicationState>
) => {
  switch (action.type) {
    case actionTypes.GET_ONLINE_APPLICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ONLINE_APPLICATION_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ONLINE_APPLICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_ONLINE_APPLICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_ONLINE_APPLICATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_ONLINE_APPLICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_ONLINE_APPLICATION_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_ONLINE_APPLICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_ONLINE_APPLICATION_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_ONLINE_APPLICATION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_ONLINE_ONLINE_APPLICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_ONLINE_APPLICATION_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_ONLINE_APPLICATION_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_ONLINE_APPLICATION_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_ONLINE_APPLICATION_SUCCESS: {
      return {
        ...state,
        sortApplicationData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_ONLINE_APPLICATION_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_ONLINE_APPLICATION_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortApplicationData: [],
      }
    }

    default:
      return state
  }
}
