import { Action } from 'redux'
import {
  ActivityLogModel,
} from '../Model/Model'
import { actionTypes } from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialActivityLogsState: IActivityLogsState = {
  data: {
    userActivityLog: [],
    meta: [],
  },
  loading: false,
  success: false,
}

export interface IActivityLogsState {
  data?: {
    userActivityLog?: ActivityLogModel[]
    meta?: {[key: string]: string | number}[]
  }
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IActivityLogsState = initialActivityLogsState,
  action: ActionWithPayload<IActivityLogsState>
) => {
  switch (action.type) {
    //GET Activity Logs DATA
    case actionTypes.GET_ACTIVITY_LOG_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ACTIVITY_LOG_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ACTIVITY_LOG_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }



    default:
      return state
  }
}
