import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getLoanSubCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_LOAN_SUB_CATEGORY_START,
    payload: params,
  }),

  getLoanSubCategorySuccess: (data: any) => ({
    type: actionTypes.GET_LOAN_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  getLoanSubCategoryFinish: () => ({
    type: actionTypes.GET_LOAN_SUB_CATEGORY_FINISH,
  }),
  getLoanSubCategoryError: (error: unknown) => ({
    type: actionTypes.GET_LOAN_SUB_CATEGORY_FINISH,
    payload: {error},
  }),

  addLoanSubCategory: (data: any) => ({
    type: actionTypes.ADD_LOAN_SUB_CATEGORY_START,
    payload: data,
  }),
  addLoanSubCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_LOAN_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  addLoanSubCategoryFinish: () => ({
    type: actionTypes.ADD_LOAN_SUB_CATEGORY_FINISH,
  }),
  addLoanSubCategoryReset: () => ({
    type: actionTypes.ADD_LOAN_SUB_CATEGORY_RESET,
  }),

  updateLoanSubCategory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_LOAN_SUB_CATEGORY_START,
    payload: {data, id},
  }),
  updateLoanSubCategorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_LOAN_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  updateLoanSubCategoryFinish: () => ({
    type: actionTypes.UPDATE_LOAN_SUB_CATEGORY_FINISH,
  }),
  updateLoanSubCategoryReset: () => ({
    type: actionTypes.UPDATE_LOAN_SUB_CATEGORY_RESET,
  }),

  deleteLoanSubCategory: (data: any) => ({
    type: actionTypes.DELETE_LOAN_SUB_CATEGORY_START,
    payload: {loanSubCategoryId: data},
  }),
  deleteLoanSubCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_LOAN_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteLoanSubCategoryFinish: () => ({
    type: actionTypes.DELETE_LOAN_SUB_CATEGORY_FINISH,
  }),
  activateLoanSubCategory: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_REQUEST,
    payload: {loanSubCategoryId: id},
  }),
  deactivateLoanSubCategory: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_REQUEST,
    payload: {loanSubCategoryId: id},
  }),
  singleActivateLoanSubCategory: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_LOAN_SUB_CATEGORY_REQUEST,
    payload: {loanSubCategoryId: [id]},
  }),
  singleDeactivateLoanSubCategory: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_LOAN_SUB_CATEGORY_REQUEST,
    payload: {loanSubCategoryId: [id]},
  }),

  sortLoanSubCategory: (data: any) => ({
    type: actionTypes.SORT_LOAN_SUB_CATEGORY_START,
    payload: data,
  }),
  sortLoanSubCategorySuccess: (data: any) => ({
    type: actionTypes.SORT_LOAN_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortLoanSubCategoryFinish: () => ({
    type: actionTypes.SORT_LOAN_SUB_CATEGORY_FINISH,
  }),
  sortLoanSubCategoryReset: () => ({
    type: actionTypes.SORT_LOAN_SUB_CATEGORY_RESET,
  }),
}
