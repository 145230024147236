export const actionTypes = {
  // get Gallery
  GET_CARD_MENU_START: 'GET_CARD_MENU_START',
  GET_CARD_MENU_SUCCESS: 'GET_CARD_MENU_SUCCESS',
  GET_CARD_MENU_FINISH: 'GET_CARD_MENU_FINISH',

  // get Media type
  GET_CARD_MENU_FILE_TYPE_START: 'GET_CARD_MENU_FILE_TYPE_START',
  GET_CARD_MENU_FILE_TYPE_SUCCESS: 'GET_CARD_MENU_FILE_TYPE_SUCCESS',
  GET_CARD_MENU_FILE_TYPE_FINISH: 'GET_CARD_MENU_FILE_TYPE_FINISH',

  // add Gallery
  ADD_CARD_MENU_START: 'ADD_CARD_MENU_START',
  ADD_CARD_MENU_SUCCESS: 'ADD_CARD_MENU_SUCCESS',
  ADD_CARD_MENU_FINISH: 'ADD_CARD_MENU_FINISH',
  RESET_CARD_MENU_TYPE: 'RESET_CARD_MENU_TYPE',

  // update Gallery
  UPDATE_CARD_MENU_START: 'UPDATE_CARD_MENU_START',
  UPDATE_CARD_MENU_SUCCESS: 'UPDATE_CARD_MENU_SUCCESS',
  UPDATE_CARD_MENU_FINISH: 'UPDATE_CARD_MENU_FINISH',

  // delete Gallery
  DELETE_CARD_MENU_START: 'DELETE_CARD_MENU_START',
  DELETE_CARD_MENU_SUCCESS: 'DELETE_CARD_MENU_SUCCESS',
  DELETE_CARD_MENU_FINISH: 'DELETE_CARD_MENU_FINISH',

  // Enable Gallery
  ENABLE_CARD_MENU_REQUEST: 'ENABLE_CARD_MENU_REQUEST',
  ENABLE_CARD_MENU_SUCCESS: 'ENABLE_CARD_MENU_SUCCESS',
  ENABLE_CARD_MENU_FINISH: 'ENABLE_CARD_MENU_FINISH',

  // Disable Gallery
  DISABLE_CARD_MENU_REQUEST: 'DISABLE_CARD_MENU_REQUEST',
  DISABLE_CARD_MENU_SUCCESS: 'DISABLE_CARD_MENU_SUCCESS',
  DISABLE_CARD_MENU_FINISH: 'DISABLE_CARD_MENU_FINISH',

  // Enable Gallery
  SINGLE_ENABLE_CARD_MENU_REQUEST: 'SINGLE_ENABLE_CARD_MENU_REQUEST',
  SINGLE_ENABLE_CARD_MENU_SUCCESS: 'SINGLE_ENABLE_CARD_MENU_SUCCESS',
  SINGLE_ENABLE_CARD_MENU_FINISH: 'SINGLE_ENABLE_CARD_MENU_FINISH',

  // Disable Gallery
  SINGLE_DISABLE_CARD_MENU_REQUEST: 'SINGLE_DISABLE_CARD_MENU_REQUEST',
  SINGLE_DISABLE_CARD_MENU_SUCCESS: 'SINGLE_DISABLE_CARD_MENU_SUCCESS',
  SINGLE_DISABLE_CARD_MENU_FINISH: 'SINGLE_DISABLE_CARD_MENU_FINISH',

  // get CARD_MENUs
  GET_ALL_CARD_MENU_START: 'GET_ALL_CARD_MENU_START',
  GET_ALL_CARD_MENU_SUCCESS: 'GET_ALL_CARD_MENU_SUCCESS',
  GET_ALL_CARD_MENU_FINISH: 'GET_ALL_CARD_MENU_FINISH',

  // sort
  SORT_CARD_MENU_START: 'SORT_CARD_MENU_START',
  SORT_CARD_MENU_SUCCESS: 'SORT_CARD_MENU_SUCCESS',
  SORT_CARD_MENU_FINISH: 'SORT_CARD_MENU_FINISH',
  SORT_CARD_MENU_RESET: 'SORT_CARD_MENU_RESET',
}
