import {ErrorMessage, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

import {isEmpty} from 'lodash'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as productManager from '../../../../products/components/productManager/index'
import * as cardMaster from '../../cardComparisonMaster/index'
import * as cardCategory from '../../categorySettings/index'
import * as cardManager from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  productId: Yup.string().required('Product name is required'),
  status: Yup.string().required('Status is required'),
})

const AddCardManager = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [masterDataError, setMasterDataError] = useState(false)

  const {loading, success} = useSelector((state: any) => state.cardManager)

  const cardMasterData = useSelector((state: any) => state.cardMaster?.data?.cardMaster)

  const [subCategories, setSubCategories]: any = useState([])

  //for dropdowns
  const categoryData: any = useSelector((state: any) => state?.cardCategory?.data?.cardCategory)

  const categoryOptions = categoryData?.map((items: any) => ({
    label: items?.title,
    value: items.id,
  }))

  const productData: any = useSelector(
    (state: any) => state?.productManager?.allProductManager?.productManager
  )
  const productOptions = productData
    ?.filter((item: any) => item?.productCategory?.slug === 'cards')
    .map((items: any) => ({
      label: items.title,
      value: items.id,
    }))

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const hideInWebsiteOptions = [
    {label: 'Yes', value: 'yes'},
    {label: 'No', value: 'no'},
  ]

  useEffect(() => {
    dispatch(cardMaster.actions.getAllCardMaster())
    dispatch(productManager.actions.getAllProductManager())
    dispatch(cardCategory.actions.getActiveCardCategory())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(cardManager?.actions.getCardManager(params))
      isEmpty(editSelectedData)
        ? toast.success('Card Manager added successfully')
        : toast.success('Card Manager edited successfully')
      dispatch(cardManager?.actions?.addCardManagerReset())
      handleClose()
    }
  }, [success])

  useEffect(() => {
    const subCats: Array<string> = []
    cardMasterData.map((card: any) => {
      if (!subCats.includes(card?.title)) {
        subCats.push(card?.title)
      }
    })
    setSubCategories(subCats)
  }, [cardMasterData.length])

  const addRemoveMaster = (e: any, master: any, values: any, setFieldValue: Function) => {
    if (e.target.checked) {
      //adding data to masters array if it doesn't already exists there
      const masterAlreadyExists =
        values.masters.findIndex((d: {id: string}) => d.id === master.id) >= 0
      if (!masterAlreadyExists) {
        const updatedMasters = [
          ...values.masters,
          {id: master.id, status: master.status, value: ''},
        ]
        setFieldValue('masters', updatedMasters)
      }
    } else {
      const updatedMastersData = values.masters.filter((d: {id: string}) => d.id !== master.id)
      setFieldValue('masters', updatedMastersData)
    }
  }

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Card Comparison Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                categoryId: '',
                productId: '',
                masters: [],
                status: 'Active',
                hide_in_website: 'no',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any, {setSubmitting}) => {
                const formData = {
                  ...values,
                  total: String(
                    values.masters?.reduce(
                      (a: number, master: {value: string}) => a + Number(master.value),
                      0
                    )
                  ),
                  status: values?.status === 'Active' ? true : false,
                  hide_in_website: values?.hide_in_website === 'yes' ? true : false,
                }

                //masters min length 1
                if (formData.masters.length < 1) {
                  setMasterDataError(true)
                  return
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(cardManager.actions.updateCardManager(formData, editSelectedData?.id))
                } else {
                  dispatch(cardManager.actions.addCardManager(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'productId',
                      'categoryId',
                      'masters',
                      'status',
                      'hide_in_website',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'hide_in_website',
                      editSelectedData?.hide_in_website === true ? 'yes' : 'no',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select category'
                            label='Category'
                            name='categoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select product'
                            label='Product'
                            name='productId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={productOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className='col-md-12 col-xs-12 mb-5 '>
                          {subCategories.map((subCategory: any) => {
                            const filtered = cardMasterData.filter(
                              (d: any) => d.status && d.title === subCategory
                            )
                            const masterIds: any = []
                            filtered.map((d: any) => masterIds.push(d.id))
                            return (
                              <div key={subCategory} className='row'>
                                <h5 className='my-5'>{subCategory}</h5>

                                {filtered.map((master: {id: string; cardName: string}) => {
                                  return (
                                    <div key={master.id} className='col-md-6'>
                                      <input
                                        id={master.id}
                                        type='checkbox'
                                        className='mt-3'
                                        onChange={(e) =>
                                          addRemoveMaster(e, master, values, setFieldValue)
                                        }
                                        checked={
                                          values.masters.findIndex(
                                            (d: {id: string}) => d.id === master.id
                                          ) >= 0
                                        }
                                      />
                                      <label htmlFor={master.id} className='text-break mx-1 mb-2'>
                                        {subCategory}
                                      </label>

                                      <br />
                                      <input
                                        type='text'
                                        className='form-control my-2 col-md-12'
                                        placeholder='Enter Title'
                                        value={
                                          values.masters.filter(
                                            (d: {id: string; value: string}) => d.id === master.id
                                          )[0]?.value || ''
                                        }
                                        disabled={
                                          values.masters.findIndex(
                                            (d: {id: string}) => d.id === master.id
                                          ) < 0
                                        }
                                        onChange={(e) => {
                                          const updatedMasters: any = [...values.masters]
                                          const indexOfMaster = updatedMasters.findIndex(
                                            (d: {id: string}) => d.id === master.id
                                          )
                                          updatedMasters[indexOfMaster].value = e.target.value
                                          setFieldValue('masters', updatedMasters)
                                        }}
                                        min='0'
                                        required
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          })}
                        </div>

                        {masterDataError && (
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-left mt-1 mb-2'>
                            Please check at least one master data
                          </div>
                        )}

                        <div className='col-md-12 mb-5 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Hide in Website ?
                          </label>
                          <div className='d-flex ms-5'>
                            {hideInWebsiteOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Hide in Website ?'
                                name='hide_in_website'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddCardManager
