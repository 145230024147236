import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getGeneralApplicationsListSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getGeneralApplicationsList, params)

    yield put(actions.getGeneralApplicationsListSuccess(response?.data?.data))
    yield put(actions.getGeneralApplicationsListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getGeneralApplicationsListFinish())
  }
}

//Get file
function* getGeneralApplicationFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getGeneralApplicationFile, fileName)
    const data: any = response?.data
    yield put(actions.getGeneralApplicationFileSuccess(data))
    yield put(actions.getGeneralApplicationFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getGeneralApplicationFileError())
  }
}

function* updateGeneralApplicationsListSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateGeneralApplicationsList,
      body,
      action.payload?.id
    )
    yield put(actions.updateGeneralApplicationsListSuccess(response.data?.data))
    yield put(actions.updateGeneralApplicationsListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateGeneralApplicationsListFinish())
  }
}

function* deleteGeneralApplicationsListSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteGeneralApplicationsList, body)
    yield put(actions.deleteGeneralApplicationsListSuccess(response.data?.data))
    yield put(actions.deleteGeneralApplicationsListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteGeneralApplicationsListFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_GENERAL_APPLICATIONS_LIST_START, getGeneralApplicationsListSaga)
  yield takeLatest(actionTypes.UPDATE_GENERAL_APPLICATIONS_LIST_START, updateGeneralApplicationsListSaga)
  yield takeLatest(actionTypes.GET_GENERAL_APPLICATIONS_LIST_FILE_START, getGeneralApplicationFileSaga)
  yield takeLatest(actionTypes.DELETE_GENERAL_APPLICATIONS_LIST_START,deleteGeneralApplicationsListSaga )
}
