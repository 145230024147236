import {LoadingOutlined} from '@ant-design/icons'
import {Spin} from 'antd'
import axios from 'axios'
import {useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {Modal} from 'rsuite'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  data: {
    id: String
    name: String
    uniqueCode: String
    dobAd: String
    dobBs: String
    age: String
    gender: String
    grandfatherName: String
    fatherName: String
    motherName: String
    placeOfBirth: String
    bloodGroup: String
    citizenshipNumber: String
    districtId: String
    citizenshipIssueDateAd: String
    citizenshipIssueDateBs: String
    maritalStatus: String
    email: String
    mobileNumber: String
    telephone: String
    sameAsPermanent: true
    workedHere: true
    generalApplicantStatusId: String
    status: true
    generalApplicantPermanentAddress: {
      id: String
      generalApplicantId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
      tole: String
    }
    generalApplicantTemporaryAddress: {
      id: String
      generalApplicantId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
      tole: String
    }
    generalApplicantEducation: {
      generalApplicantEducation: [
        {
          id: String
          generalApplicantId: String
          nameAddressInstituition: String
          university: String
          qualificationId: String
          areaSpecialization: String
          passingYear: String
          percentage: String
          cgpa: String
          marksheet: String
        }
      ]
    }
    generalApplicantTraining: {
      generalApplicantTraining: [
        {
          id: String
          generalApplicantId: String
          name: String
          instituteName: String
          duration: String
          completionYear: String
        }
      ]
    }
    generalApplicantProfessionalReference: {
      generalApplicantProfessionalReference: [
        {
          id: String
          generalApplicantId: String
          name: String
          address: String
          email: String
          phoneNumber: String
          organizationName: String
        }
      ]
    }
    generalApplicantDocument: {
      id: String
      generalApplicantId: String
      latestPhoto: String
      resume: String
      citizenship: String
      latestDegree: String
      application: String
      frontCitizenship: String
      backCitizenship: String
    }
    generalApplicantCurrentExperience: {
      generalApplicantCurrentExperience: [
        {
          designation: String
          generalApplicantId: String
          id: String
          organizationName: String
          salaryPerMonth: String
          totalExeprienceMonths: String
        }
      ]
    }
    generalApplicantRecentExperience: {
      generalApplicantRecentExperience: [
        {
          id: String
          generalApplicantId: String
          organizationName: String
          designation: any
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          totalMonth: String
          reasonForLeaving: String
        }
      ]
    }
    generalApplicantWorkedHereDetail: {
      id: String
      generalApplicantId: String
      branchId: String
      departmentId: String
      position: String
      fromDate: String
      toDate: String
      status: String
    }
    generalApplicantJobPreferanceDetail: {
      branchId: String
      provinceId: String
      departmentId: String
      expectedSalary: String
      generalApplicantId: String
      id: String
      position: String
      status: String
    }
  }
  handleClose: () => void
  provinceList: Array<any>
  districtList: Array<any>
  zoneList: Array<any>
  municipalityList: Array<any>
  functionalAreaList: Array<any>
  branchList: Array<any>
  departmentList: Array<any>
  educationList: Array<any>
}

const ViewCandidateDetails = ({
  data,
  handleClose,
  branchList,
  departmentList,
  provinceList,
  districtList,
  zoneList,
  municipalityList,
  functionalAreaList,
  educationList,
}: Props) => {
  const [loading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const ftpStorage = checkBackendSetting('ftpStorage')
  const FTP_API = '/api/apply-vacancy/ftp-file-download?'
  const handleOpenFile = async (url: any, fileName: string) => {
    setIsLoading(true)
    setFileName(fileName)
    const fileExtension = url.split('.').pop()

    await axios
      .get(`${imageBaseUrl}${FTP_API}ftpFilePath=${url}`, {
        method: 'GET',
        responseType: 'blob',
      })
      .then((response: any) => {
        setIsLoading(false)
        setFileName('')
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.${fileExtension}`)
        document.body.appendChild(link)
        link.click()
        toast.success('Download successfully')
      })
      .catch((exception) => {
        setIsLoading(false)
        setFileName('')
        toast.error('Internal error occurred')
        console.log('exception', exception)
      })
  }

  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Candidate Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Personal Details</h5>
            <Row>
              <Col md={3}>Gender : {data?.gender}</Col>
              <Col md={3}>Name : {data?.name}</Col>
              <Col md={3}>Unique Code : {data?.uniqueCode}</Col>
              <Col md={3}>Date Of Birth (A.D): {data?.dobAd}</Col>
            </Row>

            <Row className='mt-5'>
              <Col md={3}>Email : {data?.email}</Col>
              <Col md={3}>Mobile Number: {data?.mobileNumber}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.generalApplicantPermanentAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Zone:{' '}
                {zoneList.filter(
                  (d: any) => d.id === data?.generalApplicantPermanentAddress?.zoneId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.generalApplicantPermanentAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.generalApplicantPermanentAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Ward Number: {data?.generalApplicantPermanentAddress?.wardNumber}
              </Col>

              <Col md={3}>
                Present Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.generalApplicantTemporaryAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Zone:{' '}
                {zoneList.filter(
                  (d: any) => d.id === data?.generalApplicantTemporaryAddress?.zoneId
                )[0]?.title || 'N/A'}{' '}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Present District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.generalApplicantTemporaryAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.generalApplicantTemporaryAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>

            <h5 className='mt-5'>Professional Details</h5>

            {data?.generalApplicantEducation?.generalApplicantEducation?.map(
              (education: any, index: number) => {
                return (
                  <div key={index}>
                    <Row className='mt-5'>
                      <Col md={6}>
                        Higher Educational Qualification:{' '}
                        {educationList.filter((d: any) => d.id === education?.qualificationId)[0]
                          ?.name || 'N/A'}
                      </Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col md={3}>CGPA: {education?.cgpa}</Col>
                      <Col md={3}>Percentage : {education?.percentage}</Col>
                    </Row>
                  </div>
                )
              }
            )}

            <h5 className='mt-5'>Current Experience</h5>
            <Col md={3}>
              Designation :{' '}
              {data?.generalApplicantCurrentExperience?.generalApplicantCurrentExperience[0]
                .designation || 'N/A'}
            </Col>

            <Col md={3}>
              Organization Name :{' '}
              {data?.generalApplicantCurrentExperience?.generalApplicantCurrentExperience[0]
                .organizationName || 'N/A'}
            </Col>

            <Col md={3}>
              Salary Per Month :{' '}
              {data?.generalApplicantCurrentExperience?.generalApplicantCurrentExperience[0]
                .salaryPerMonth || 'N/A'}
            </Col>

            <Col md={3}>
              Total Exeprience Months :{' '}
              {data?.generalApplicantCurrentExperience?.generalApplicantCurrentExperience[0]
                .totalExeprienceMonths || 'N/A'}
            </Col>

            <h5 className='mt-5'>Job Reference </h5>
            <Row className='mt-5'>
              <Col md={3}>
                Department:{' '}
                {departmentList.filter(
                  (d) => d.id === data?.generalApplicantJobPreferanceDetail?.departmentId
                )[0]?.name || 'N/A'}
              </Col>

              <Col md={3}>
                Province:{' '}
                {provinceList.filter(
                  (d) => d.id === data?.generalApplicantJobPreferanceDetail?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Branch :{' '}
                {branchList.filter(
                  (d) => d.id === data?.generalApplicantJobPreferanceDetail?.branchId
                )[0]?.title || 'N/A'}
              </Col>

              <Col md={3}>
                Expected Salary :{' '}
                {data?.generalApplicantJobPreferanceDetail?.expectedSalary || 'N/A'}
              </Col>

              <Col md={3}>
                Position : {data?.generalApplicantJobPreferanceDetail?.position || 'N/A'}
              </Col>
            </Row>

            <h5 className='mt-5'>Document Details</h5>
            <Row className='mt-5'>
              <Col md={3}>
                Citizenship:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(
                        `${data?.generalApplicantDocument?.frontCitizenship}`,
                        'citizenship'
                      )
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'citizenship' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.generalApplicantDocument?.frontCitizenship}
                  >
                    View
                  </a>
                )}
              </Col>
              <Col md={3}>
                Resume:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(`${data?.generalApplicantDocument?.resume}`, 'resume')
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'resume' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.generalApplicantDocument?.resume}
                  >
                    View
                  </a>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewCandidateDetails
