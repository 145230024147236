import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeletePayoutLocationModel, SortPayoutLocationModel} from '../Model'
import {PayoutLocationModel} from '../Model/PayoutLocationModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PAYOUT_LOCATION_DATA = `${API_URL}/payoutlocations`
export const IMPORT_PAYOUT_LOCATION = `${API_URL}/payoutlocations/import`
export const GET_DATA = `${API_URL}/payoutlocations/get-sample`
export const EXPORT_CSV_DATA = `${API_URL}/payoutlocations/export`

export const service = {
  getPayoutLocation: (params: ParamsModel) => {
    return axios.get(PAYOUT_LOCATION_DATA, {params})
  },
  getAllPayoutLocation: () => {
    return axios.get(`${PAYOUT_LOCATION_DATA}/list`)
  },

  addPayoutLocation: (data: any) => {
    return axios.post(PAYOUT_LOCATION_DATA, data)
  },

  updatePayoutLocation: (body: PayoutLocationModel, id: string) => {
    return axios.put(`${PAYOUT_LOCATION_DATA}/${id}`, body)
  },

  deletePayoutLocation: (data: DeletePayoutLocationModel) => {
    return axios.delete(`${PAYOUT_LOCATION_DATA}/bulk-delete`, {data})
  },

  enablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: data,
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/enable`, selectedPayoutLocation)
  },

  disablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: data,
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/disable`, selectedPayoutLocation)
  },

  singleEnablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: [data],
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/enable`, selectedPayoutLocation)
  },

  singleDisablePayoutLocation: (data: Array<string>) => {
    const selectedPayoutLocation = {
      payoutLocationsId: [data],
    }
    return axios.patch(`${PAYOUT_LOCATION_DATA}/disable`, selectedPayoutLocation)
  },

  sortPayoutLocation: (body: SortPayoutLocationModel) => {
    return axios.patch(`${PAYOUT_LOCATION_DATA}/sort`, body)
  },

  // import file
  importPayoutLocation: (data: any) => {
    return axios.post(`${IMPORT_PAYOUT_LOCATION}`, data)
  },

  //export template
  exportTemplateFile: () => {
    return axios.get(`${GET_DATA}`).then((response) => {
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', '') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  exportFile: (fileName: string) => {
    return axios.get(`${EXPORT_CSV_DATA}`).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
