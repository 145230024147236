import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {
  ProductSubCategoryModel,
  SortProductSubActionModel,
  SortProductSubCategoryModel,
} from '../Model/ProductSubCategoryModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getProductSubCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getProductSubCategory, params)
    yield put(actions.getProductSubCategorySuccess(response?.data?.data))
    yield put(actions.getProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getProductSubCategoryFinish())
  }
}

function* getAllProductSubCategorySaga() {
  try {
    const response: ResponseModel = yield call(service.getAllProductSubCategory)
    yield put(actions.getAllProductSubCategorySuccess(response?.data?.data))
    yield put(actions.getAllProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllProductSubCategoryFinish())
  }
}

function* addProductSubCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addProductSubCategory, body)
    yield put(actions.addProductSubCategorySuccess(response.data?.data))
    yield put(actions.addProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addProductSubCategoryFinish())
  }
}

function* enableProductSubCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableProductSubCategory, selectedUsers)
    yield put(actions.enableProductSubCategorySuccess(response?.data))
    yield put(actions.enableProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableProductSubCategoryFinish())
  }
}

function* disableProductSubCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableProductSubCategory, selectedUsers)
    yield put(actions.disableProductSubCategorySuccess(response?.data))
    yield put(actions.disableProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableProductSubCategoryFinish())
  }
}

function* singleEnableProductSubCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleEnableProductSubCategory,
      selectedUsers
    )
    yield put(actions.singleEnableProductSubCategorySuccess(response?.data))
    yield put(actions.singleEnableProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableProductSubCategoryFinish())
  }
}

function* singleDisableProductSubCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDisableProductSubCategory,
      selectedUsers
    )
    yield put(actions.singleDisableProductSubCategorySuccess(response?.data))
    yield put(actions.singleDisableProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableProductSubCategoryFinish())
  }
}

function* updateProductSubCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateProductSubCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateProductSubCategorySuccess(response.data?.data))
    yield put(actions.updateProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateProductSubCategoryFinish())
  }
}

function* deleteProductSubCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteProductSubCategory, body)
    yield put(actions.deleteProductSubCategorySuccess(response.data?.data))
    yield put(actions.deleteProductSubCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteProductSubCategoryFinish())
  }
}

function* sortProductSubCategory(action: SortProductSubActionModel) {
  try {
    const body: SortProductSubCategoryModel = action.payload
    const response: ResponseModel = yield call(service.sortProductSubCategory, body)

    yield put({
      type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_PRODUCT_SUB_CATEGORY_START, getProductSubCategorySaga)
  yield takeLatest(actionTypes.GET_ALL_PRODUCT_SUB_CATEGORY_START, getAllProductSubCategorySaga)
  yield takeLatest(actionTypes.ADD_PRODUCT_SUB_CATEGORY_START, addProductSubCategorySaga)
  yield takeLatest(actionTypes.UPDATE_PRODUCT_SUB_CATEGORY_START, updateProductSubCategorySaga)
  yield takeLatest(actionTypes.DELETE_PRODUCT_SUB_CATEGORY_START, deleteProductSubCategorySaga)
  yield takeLatest(actionTypes.ENABLE_PRODUCT_SUB_CATEGORY_REQUEST, enableProductSubCategorySaga)
  yield takeLatest(actionTypes.DISABLE_PRODUCT_SUB_CATEGORY_REQUEST, disableProductSubCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_REQUEST,
    singleEnableProductSubCategorySaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_REQUEST,
    singleDisableProductSubCategorySaga
  )
  yield takeLatest(actionTypes.SORT_PRODUCT_SUB_CATEGORY_START, sortProductSubCategory)
}
