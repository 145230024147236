import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ApplicationModel, DeleteApplicationModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const Applications_URL = `${API_URL}/bank-guarantee`

export const service = {
  getApplications: (params: ParamsModel) => {
    return axios.get(Applications_URL, {params})
  },

  deleteApplications: (data: DeleteApplicationModel) => {
    return axios.delete(Applications_URL + '/bulk-delete', {data})
  },
}
