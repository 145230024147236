import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteExchangeRateModel, ExchangeRateModel} from '../Model'
import {actionTypes} from './constants'
import { IExchangeRateFileState } from './reducer'
export const actions = {
  // get Service partneer
  getExchangeRate: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_EXCHANGE_RATE_START,
    payload: params,
  }),
  getExchangeRateSuccess: (data: ExchangeRateModel) => ({
    type: actionTypes.GET_EXCHANGE_RATE_SUCCESS,
    payload: data,
  }),
  getExchangeRateFinish: () => ({
    type: actionTypes.GET_EXCHANGE_RATE_FINISH,
  }),

  // create service partner
  addExchangeRate: (data: ExchangeRateModel | any) => ({
    type: actionTypes.ADD_EXCHANGE_RATE_START,
    payload: data,
  }),
  addExchangeRateSuccess: (task: any) => ({
    type: actionTypes.ADD_EXCHANGE_RATE_SUCCESS,
    payload: task,
  }),
  addExchangeRateFinish: () => ({
    type: actionTypes.ADD_EXCHANGE_RATE_FINISH,
  }),
  resetExchangeRate: () => ({
    type: actionTypes.RESET_EXCHANGE_RATE_TYPE,
  }),

  //Update service partner
  updateExchangeRate: (data: ExchangeRateModel | any, id: string) => ({
    type: actionTypes.UPDATE_EXCHANGE_RATE_START,
    payload: data,
    id,
  }),

  updateExchangeRateSuccess: (data: ExchangeRateModel) => ({
    type: actionTypes.UPDATE_EXCHANGE_RATE_SUCCESS,
    payload: data,
  }),

  updateExchangeRateFinish: () => ({
    type: actionTypes.UPDATE_EXCHANGE_RATE_FINISH,
  }),

  // delete service partner
  deleteExchangeRate: (data: DeleteExchangeRateModel[]) => ({
    type: actionTypes.DELETE_EXCHANGE_RATE_START,
    payload: {servicePartnerRateId: data},
  }),
  deleteExchangeRateSuccess: (data: any) => ({
    type: actionTypes.DELETE_EXCHANGE_RATE_SUCCESS,
    payload: data,
  }),
  deleteExchangeRateFinish: () => ({
    type: actionTypes.DELETE_EXCHANGE_RATE_FINISH,
  }),

  //Enable Service Partner
  enableExchangeRate: (data: any) => ({
    type: actionTypes.ENABLE_EXCHANGE_RATE_REQUEST,
    payload: {data},
  }),

  enableExchangeRateSuccess: (task: any) => ({
    type: actionTypes.ENABLE_EXCHANGE_RATE_SUCCESS,
    payload: task,
  }),
  enableExchangeRateFinish: () => ({
    type: actionTypes.ENABLE_EXCHANGE_RATE_FINISH,
  }),

  //Disable ExchangeRate
  disableExchangeRate: (data: any) => ({
    type: actionTypes.DISABLE_EXCHANGE_RATE_REQUEST,
    payload: {data},
  }),

  disableExchangeRateSuccess: (task: any) => ({
    type: actionTypes.DISABLE_EXCHANGE_RATE_SUCCESS,
    payload: task,
  }),
  disableExchangeRateFinish: () => ({
    type: actionTypes.DISABLE_EXCHANGE_RATE_FINISH,
  }),

  //Single Enable ExchangeRate
  singleEnableExchangeRate: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_REQUEST,
    payload: {data},
  }),

  singleEnableExchangeRateSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_SUCCESS,
    payload: task,
  }),
  singleEnableExchangeRateFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_EXCHANGE_RATE_FINISH,
  }),

  //Single Disable ExchangeRate
  singleDisableExchangeRate: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_REQUEST,
    payload: {data},
  }),

  singleDisableExchangeRateSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_SUCCESS,
    payload: task,
  }),
  singleDisableExchangeRateFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_EXCHANGE_RATE_FINISH,
  }),

  //Donload ExchangeRate sample
  downloadExchangeRateSample: (fileType: string = 'csv') => ({
    type: actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_REQUEST,
    payload: fileType,
  }),

  downloadExchangeRateSampleSuccess: (data: IExchangeRateFileState) => ({
    type: actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_SUCCESS,
    payload: data, 
  }),
  downloadExchangeRateSampleError: (data: IExchangeRateFileState) => ({
    type: actionTypes.DOWNLOAD_EXCHANGE_RATE_SAMPLE_FINISH,
    payload: data,
  }),
  

  //Import ExchangeRate
  importExchangeRate: (data: any) => ({
    type: actionTypes.IMPORT_EXCHANGE_RATE_REQUEST,
    payload: data,
  }),

  importExchangeRateSuccess: (data: any) => ({
    type: actionTypes.IMPORT_EXCHANGE_RATE_SUCCESS,
  }),
  importExchangeRateFinish: () => ({
    type: actionTypes.IMPORT_EXCHANGE_RATE_FINISH,
  }),
}
