import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteAppointmentTypeModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_APPOINTMENT = `${API_URL}/appointment-type`
export const GET_MEETING_TYPE = `${API_URL}/meeting-type`

export const service = {
  getMeetingTypeData: () => {
    return axios.get(GET_MEETING_TYPE)
  },
  getAppointmentTypeData: (params: ParamsModel) => {
    return axios.get(GET_APPOINTMENT, {params})
  },

  getAppointmentTypeList: () => {
    return axios.get(`${GET_APPOINTMENT}/list`)
  },
  addAppointmentType: (data: any) => {
    return axios.post(GET_APPOINTMENT, data)
  },

  updateAppointmentType: (body: any, id: string) => {
    return axios.patch(`${GET_APPOINTMENT}/${id}`, body)
  },

  deleteBulkAppointmentType: (data: DeleteAppointmentTypeModel) => {
    return axios.delete(`${GET_APPOINTMENT}`, {data})
  },

  activateAppointmentType: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentTypeId: data,
    }
    return axios.patch(`${GET_APPOINTMENT}/enable`, selectedAppointmentType)
  },

  deactivateAppointmentType: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentTypeId: data,
    }
    return axios.patch(`${GET_APPOINTMENT}/disable`, selectedAppointmentType)
  },

  singleActivateAppointmentType: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentTypeId: [data],
    }
    return axios.patch(`${GET_APPOINTMENT}/enable`, selectedAppointmentType)
  },

  singleDeactivateAppointmentType: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentTypeId: [data],
    }
    return axios.patch(`${GET_APPOINTMENT}/disable`, selectedAppointmentType)
  },
}
