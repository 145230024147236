export const actionTypes = {
  GET_CSR_FILE_TYPE_START: 'GET_CSR_FILE_TYPE_START',
  GET_CSR_FILE_TYPE_SUCCESS: 'GET_CSR_FILE_TYPE_SUCCESS',
  GET_CSR_FILE_TYPE_FINISH: 'GET_CSR_FILE_TYPE_FINISH',

  GET_CSR_LIST_START: 'GET_CSR_LIST_START',
  GET_CSR_LIST_SUCCESS: 'GET_CSR_LIST_SUCCESS',
  GET_CSR_LIST_FINISH: 'GET_CSR_LIST_FINISH',

  GET_CSR_DATA_START: 'GET_CSR_DATA_START',
  GET_CSR_DATA_SUCCESS: 'GET_CSR_DATA_SUCCESS',
  GET_CSR_DATA_FINISH: 'GET_CSR_DATA_FINISH',

  ADD_CSR_ITEM_START: 'ADD_CSR_ITEM_START',
  ADD_CSR_ITEM_SUCCESS: 'ADD_CSR_ITEM_SUCCESS',
  ADD_CSR_ITEM_FINISH: 'ADD_CSR_ITEM_FINISH',
  ADD_CSR_ITEM_RESET: 'ADD_CSR_ITEM_RESET',

  UPDATE_CSR_ITEM_START: 'UPDATE_CSR_ITEM_START',
  UPDATE_CSR_ITEM_SUCCESS: 'UPDATE_CSR_ITEM_SUCCESS',
  UPDATE_CSR_ITEM_FINISH: 'UPDATE_CSR_ITEM_FINISH',
  UPDATE_CSR_ITEM_RESET: 'UPDATE_CSR_ITEM_RESET',

  DELETE_BULK_CSR_START: 'DELETE_BULK_CSR_START',
  DELETE_BULK_CSR_SUCCESS: 'DELETE_BULK_CSR_SUCCESS',
  DELETE_BULK_CSR_FINISH: 'DELETE_BULK_CSR_FINISH',

  ACTIVATE_CSR_START: 'ACTIVATE_CSR_START',
  ACTIVATE_CSR_SUCCESS: 'ACTIVATE_CSR_SUCCESS',
  ACTIVATE_CSR_FINISH: 'ACTIVATE_CSR_FINISH',

  DEACTIVATE_CSR_START: 'DEACTIVATE_CSR_START',
  DEACTIVATE_CSR_SUCCESS: 'DEACTIVATE_CSR_SUCCESS',
  DEACTIVATE_CSR_FINISH: 'DEACTIVATE_CSR_FINISH',

  // ACTIVATE CSR
  SINGLE_ACTIVATE_CSR_START: 'SINGLE_ACTIVATE_CSR_START',
  SINGLE_ACTIVATE_CSR_SUCCESS: 'SINGLE_ACTIVATE_CSR_SUCCESS',
  SINGLE_ACTIVATE_CSR_FINISH: 'SINGLE_ACTIVATE_CSR_FINISH',

  // DEACTIVATE CSR
  SINGLE_DEACTIVATE_CSR_START: 'SINGLE_DEACTIVATE_CSR_START',
  SINGLE_DEACTIVATE_CSR_SUCCESS: 'SINGLE_DEACTIVATE_CSR_SUCCESS',
  SINGLE_DEACTIVATE_CSR_FINISH: 'SINGLE_DEACTIVATE_CSR_FINISH',

  // sort csr
  SORT_CSR_START: 'SORT_CSR_START',
  SORT_CSR_SUCCESS: 'SORT_CSR_SUCCESS',
  SORT_CSR_FINISH: 'SORT_CSR_FINISH',
  SORT_CSR_RESET: 'SORT_CSR_RESET',
}
