import { ErrorMessage, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import { StateParamsModel } from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import * as Yup from 'yup'
import * as baseRateRedux from '../../baseRate'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddInterestRate = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()

  const { loading, success } = useSelector((state: any) => state.baseRate)
  useEffect(() => {
    dispatch(baseRateRedux.actions.getBaseRate(params))
  }, [params])

  useEffect(() => {
    if (success) {
      dispatch(baseRateRedux?.actions.getBaseRate(params))
      isEmpty(editSelectedData)
        ? toast.success('Base Rate added successfully')
        : toast.success('Base Rate edited successfully')
      dispatch(baseRateRedux?.actions.resetBaseRate())
      handleClose()
    }
  }, [success])

  const baseRateInterestSpreadFileRequired = checkBackendSetting(
    'baseRateInterestSpreadFileRequired'
  )
  const baseRateYearRequired = checkBackendSetting('baseRateYearRequired')
  const baseRateFileRequired = checkBackendSetting('baseRateFileRequired')

  let FORM_VALIDATION = Yup.object().shape({})

  if (baseRateYearRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      year: Yup.date().required('Year is required'),
    })
  }

  if (baseRateFileRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      baseRateFile: Yup.string().required('Base rate file is required'),
    })
  }

  if (baseRateInterestSpreadFileRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      interestSpreadFile: Yup.string().required('Interest spread file is required'),
    })
  }

  return (
    <div className='modal-container'>
      <Modal open={open} onClose={handleClose} className='w-75' enforceFocus={false} data-cy='modal'>
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Base Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                year: '',
                baseRateFile: '',
                interestSpreadFile: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { setSubmitting }) => {
                const formData: any = new FormData()
                formData.append('year', moment(values?.year).format('YYYY'))
                formData.append('baseRateFile', values.baseRateFile)

                baseRateInterestSpreadFileRequired &&
                  formData.append('interestSpreadFile', values.interestSpreadFile)

                actionType === 'Edit' ? formData.append('_method', 'PUT') : null

                if (!isEmpty(editSelectedData)) {
                  dispatch(baseRateRedux.actions.updateBaseRate(formData, editSelectedData?.id))
                } else {
                  dispatch(baseRateRedux.actions.addBaseRate(formData))
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                handleBlur,
                errors,
                values,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'year',
                      'firstSectionInfoText',
                      'baseRateFile',
                      'interestSpreadFile',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'year',
                      editSelectedData?.date
                        ? moment(editSelectedData?.date, 'YYYY').toDate()
                        : null,
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <FormDatepicker
                          labelClassName='col-12'
                          containerClassName='col-6 '
                          label=' Year'
                          name='year'
                          placeholderText='Please select a year'
                          setFieldValue={setFieldValue}
                          value={values.year || false}
                          touched={touched}
                          required={baseRateYearRequired}
                          showIcon={true}
                          errors={errors}
                          showYearPicker={true}
                          dateFormat='yyyy'
                        />
                        <div style={{ display: 'flex' }}>
                          <div
                            className='col-md-6 col-sm-6 col-xs-12 mb-2'
                            style={{ paddingBottom: '10px' }}
                          >
                            <label className='mb-1 fw-bolder fs-6 required w-100'>
                              Base Rate File
                            </label>
                            <input
                              type='file'
                              onBlur={handleBlur}
                              name='baseRateFile'
                              onChange={(event) => {
                                setFieldValue('baseRateFile', event.target.files![0])
                              }}
                              data-cy='modal-baserate-file-add'
                            ></input>
                            <div className='text-danger fw-bolder small text-end'>
                              <ErrorMessage name='baseRateFile' component='div' />
                            </div>
                          </div>

                          {baseRateInterestSpreadFileRequired && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2 '
                              style={{ paddingBottom: '10px' }}
                            >
                              <label className='mb-1 fw-bolder fs-6 required w-100'>
                                Interest Spread File
                              </label>
                              <input
                                type='file'
                                onBlur={handleBlur}
                                name='interestSpreadFile'
                                onChange={(event) => {
                                  setFieldValue('interestSpreadFile', event.target.files![0])
                                }}
                                data-cy='modal-interestrate-file-add'
                              ></input>
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='interestSpreadFile' component='div' />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-5 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddInterestRate
