import Modal from 'rsuite/Modal'

interface Props {
  isOpen?: boolean
  handleClick: () => void
  handleClose: () => void
  remarks: string
  setRemarks: Function
  department: string
  statusList: Array<any>
  setDepartment: Function
  disableSubmitButton: boolean
  status: string
  setStatus: Function
  departmentList: Array<any>
}
const GrievanceActionsModal = ({
  isOpen,
  disableSubmitButton,
  handleClick,
  handleClose,
  remarks,
  setRemarks,
  department,
  setDepartment,
  status,
  setStatus,
  statusList,
  departmentList,
}: Props) => {
  return (
    <>
      <Modal
        backdrop='static'
        role='alertdialog'
        open={isOpen}
        size='xs'
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Body>
          <div className='d-flex'>
            <h4 data-cy='modal-title'>
              <strong className='ms-3'>Edit Grievance</strong>
            </h4>
          </div>
          <div className='mx-3'>
            <label className='mt-5'>Select Status</label>
            <select
              placeholder='Select Status'
              className='unstyled-input'
              onChange={(e) => {
                setStatus(e.target.value)
                setRemarks('')
                setDepartment('')
              }}
              data-cy='status-select-field'
            >
              <option value='Select Status' selected={status === 'Select Status'}>
                Select Status
              </option>
              {statusList.map((dta) => {
                return (
                  <option
                    key={dta.id}
                    value={dta.displayName}
                    selected={status === dta.displayName}
                  >
                    {dta.displayName}
                  </option>
                )
              })}
            </select>
          </div>

          {typeof status === 'string' && status.toLowerCase() === 'assigned to department' && (
            <div className='mx-3 mt-5'>
              <label>Select Department</label>
              <select
                placeholder='Select Department'
                className='unstyled-input'
                onChange={(e) => {
                  setDepartment(e.target.value)
                  setRemarks('')
                }}
                data-cy='department-select-field'
              >
                <option value='Select Department' selected={department === 'Select Department'}>
                  Select Department
                </option>
                {departmentList.map((dta) => {
                  return (
                    <option key={dta.id} value={dta.name} selected={department === dta.name}>
                      {dta.name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          {status && status !== 'Select Status' && status !== 'Assigned To Department' && (
            <div className='mx-3 mt-5'>
              <label className='mt-5'>Type Remarks *</label>

              <input
                placeholder='remarks'
                className='unstyled-input'
                type='text'
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                data-cy='remarks-text-field'
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {((remarks.trim().length > 0 && status !== 'Select Status') ||
            (department && department !== 'Select Department')) && (
            <button
              onClick={handleClick}
              disabled={disableSubmitButton}
              className='btn btn-primary btn-sm ms-3'
              data-cy='modal-submit'
            >
              Submit
            </button>
          )}
          <button
            onClick={handleClose}
            className='btn btn-secondary btn-sm ms-3'
            data-cy='modal-cancel'
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GrievanceActionsModal
