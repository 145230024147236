import {ParamsModel} from 'src/app/modules/common/Model'
import {TisaForexCategory} from '../Model'
import {actionTypes} from './constants'
import {ITisaForexRateFileState, ITisaForexRateState} from './reducer'

export const actions = {
  // get TisaForex Rate
  getTisaForexRate: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_TISA_FOREX_RATE_START,
      payload: {params},
    }
  },

  getTisaForexRateSuccess: (data: ITisaForexRateState) => ({
    type: actionTypes.GET_TISA_FOREX_RATE_SUCCESS,
    payload: data,
  }),
  getTisaForexRateError: (data: ITisaForexRateState) => ({
    type: actionTypes.GET_TISA_FOREX_RATE_FINISH,
    payload: data,
  }),

  //Get forex rate file csv slsx
  getTisaForexRateFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_TISA_FOREX_RATE_FILE_START,
      payload: fileType,
    }
  },

  getTisaForexRateFileSuccess: (data: ITisaForexRateFileState) => ({
    type: actionTypes.GET_TISA_FOREX_RATE_FILE_SUCCESS,
    payload: data,
  }),
  getTisaForexRateFileError: (data: ITisaForexRateFileState) => ({
    type: actionTypes.GET_TISA_FOREX_RATE_FILE_FINISH,
    payload: data,
  }),

  // create TisaForex Rate
  CreateTisaForexRate: (data: any) => ({
    type: actionTypes.CREATE_TISA_FOREX_RATE_START,
    payload: data,
  }),

  createTisaForexRateSuccess: (task: any) => ({
    type: actionTypes.CREATE_TISA_FOREX_RATE_SUCCESS,
    payload: task,
  }),
  createTisaForexRateFinish: (errorMsg: any) => ({
    type: actionTypes.CREATE_TISA_FOREX_RATE_FINISH,
    payload: errorMsg,
  }),
  createTisaForexRateReset: () => ({
    type: actionTypes.CREATE_TISA_FOREX_RATE_RESET,
  }),

  //Activate deactivate
  activateTisaForexRates: (data: any) => ({
    type: actionTypes.ACTIVATE_TISA_FOREX_RATE_REQUEST,
    payload: {data},
  }),

  singleActivateTisaForexRate: (data: string) => ({
    type: actionTypes.SINGLE_ACTIVATE_TISA_FOREX_RATE_REQUEST,
    payload: data,
  }),

  singleDeactivateTisaForexRate: (id: string) => ({
    type: actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_REQUEST,
    payload: id,
  }),

  deactivateTisaForexRate: (data: any) => ({
    type: actionTypes.DEACTIVATE_TISA_FOREX_RATE_REQUEST,
    payload: {data},
  }),

  // update UserRole
  updateTisaForexRate: (data: any, id: string) => ({
    type: actionTypes.UPDATE_TISA_FOREX_RATE_START,
    payload: {data, id},
  }),
  updateTisaForexRateSuccess: (task: any) => ({
    type: actionTypes.UPDATE_TISA_FOREX_RATE_SUCCESS,
    payload: task,
  }),
  updateTisaForexRateFinish: (errorMsg: any) => ({
    type: actionTypes.UPDATE_TISA_FOREX_RATE_FINISH,
    payload: errorMsg,
  }),
  updateTisaForexRateReset: () => ({
    type: actionTypes.UPDATE_TISA_FOREX_RATE_RESET,
  }),

  // IMPORT TISA_FOREX RATE
  importTisaForexRate: (data: any) => ({
    type: actionTypes.IMPORT_TISA_FOREX_RATE_START,
    payload: data,
  }),
  importTisaForexRateSuccess: (task: any) => ({
    type: actionTypes.IMPORT_TISA_FOREX_RATE_SUCCESS,
    payload: task,
  }),
  importTisaForexRateFinish: () => ({
    type: actionTypes.IMPORT_TISA_FOREX_RATE_FINISH,
  }),

  //delete and reset TisaForex Rate
  deleteTisaForexRate: (data: TisaForexCategory[]) => ({
    type: actionTypes.DELETE_TISA_FOREX_RATE_START,
    payload: {forexRateId: data},
  }),
}
