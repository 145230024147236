import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialCategoryState: ICategoryState = {
  data: {
    benefitCategory: [],
    meta: {},
  },
  deleteSuccess: false,
  sortBenefitCategoryData: [],

  loading: false,
  success: false,
}

export interface ICategoryState {
  data: {
    benefitCategory: any
    meta: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  sortBenefitCategoryData: ICategoryState[]
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ICategoryState = initialCategoryState,
  action: ActionWithPayload<ICategoryState>
) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_BENEFIT_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_BENEFIT_CATEGORY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_BENEFIT_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_BENEFIT_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_BENEFIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_BENEFIT_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_BENEFIT_CATEGORY_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_BENEFIT_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_BENEFIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_BENEFIT_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_BENEFIT_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_BENEFIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_BENEFIT_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_BENEFIT_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_BENEFIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        sortBenefitCategoryData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_BENEFIT_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_BENEFIT_CATEGORY_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortBenefitCategoryData: [],
      }
    }

    default:
      return state
  }
}
