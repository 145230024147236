import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as Yup from 'yup'
import * as subscribeType from '../index'

import Modal from 'rsuite/Modal'

import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'

type Props = {
  open: boolean
  params?: StateParamsModel
  setParams?: any
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  // .matches(/^[A-Za-z\s]+$/, 'Must not contain number or symbol')
  status: Yup.string().required('Status is required'),
  receiverEmails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Invalid Email. Eg: example@xyz.com').nullable(),
    })
  ),
})

const AddServiceType = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.subscribeType)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      dispatch(subscribeType?.actions.getSubscribeType(params))
      isEmpty(editSelectedData)
        ? toast.success('Subscribe Type added successfully')
        : toast.success('Subscribe Type edited successfully')
      dispatch(subscribeType?.actions?.resetSubscribeType())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Subscribe Type </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                status: '',
                subscribeCode: '',
                receiverEmails: [{email: ''}],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData: any = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  receiverEmails: !isEmpty(values?.receiverEmails[0].email)
                    ? values.receiverEmails
                    : null,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    subscribeType.actions.updateSubscribeType(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(subscribeType.actions.addSubscribeType(formData))
                }
              }}
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'nameNp',
                      'note',
                      'noteNp',
                      'receiverEmails',
                      'subscribeCode',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Submission Code '
                            label='Submission Code '
                            name='subscribeCode'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Name (NP)'
                            label='Name (NP)'
                            name='nameNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Note (EN)'
                            name='note'
                            initialValue={!isEmpty(editSelectedData) ? editSelectedData?.note : ''}
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Note (NP)'
                            name='noteNp'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.noteNp : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FieldArray
                            name='receiverEmails'
                            render={(arrayHelpers) => (
                              <div>
                                {values.receiverEmails && values.receiverEmails.length > 0
                                  ? values.receiverEmails.map((friend: any, index: any) => (
                                      <div key={index}>
                                        {/** both these conventions do the same */}
                                        <FormTextBox
                                          type='text'
                                          labelClassName='col-md-12'
                                          containerClassName='col-md-12'
                                          placeholder='Enter E-mail'
                                          label='Receiver E-mail'
                                          name={`receiverEmails.${index}.email`}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                        />

                                        {index > 0 && (
                                          <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                            <button
                                              type='button'
                                              className='p-2 ps-5 pe-5 btn btn-secondary'
                                              onClick={() => arrayHelpers.remove(index)}
                                              data-cy='modal-field-remove'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  : null}
                                <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                  <button
                                    className='p-2 ps-5 pe-5 btn btn-primary'
                                    type='button'
                                    data-cy='modal-field-add'
                                    onClick={() => arrayHelpers.push({email: ''})}
                                  >
                                    + Add More
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddServiceType
