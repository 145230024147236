import {Col, Row} from 'react-bootstrap-v5'
import {Modal} from 'rsuite'

type Props = {
  data: {
    id: string
    name: string
    email: string
    mobile_number: string
    dob: string
    address: string
    province: string
    district: string
    municipality: string
    city: string
    registration_date: string
    registration_platform: string
    last_login: string
  }
  handleClose: () => void
}
const ViewDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Customer Login Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Name</b>
              </Col>
              <Col md={6}>
                <p>{data.name}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email</b>
              </Col>
              <Col md={6}>
                <p>{data.email}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Mobile Number</b>
              </Col>
              <Col md={6}>
                <p>{data.mobile_number}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Date Of Birth</b>
              </Col>
              <Col md={6}>
                <p>{data.dob}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Address</b>
              </Col>
              <Col md={6}>
                <p>{data.address}</p>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <b>Province</b>
              </Col>
              <Col md={6}>
                <p>{data.province}</p>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <b>District</b>
              </Col>
              <Col md={6}>
                <p>{data.district}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Municipality</b>
              </Col>
              <Col md={6}>
                <p>{data.municipality}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>City</b>
              </Col>
              <Col md={6}>
                <p>{data.city}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Last Login</b>
              </Col>
              <Col md={6}>
                <p>{data.last_login}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Registered Platform</b>
              </Col>
              <Col md={6}>
                <p>{data.registration_platform || '-'}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Registered Date</b>
              </Col>
              <Col md={6}>
                <p>{data.registration_date || '-'}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
