import Modal from 'rsuite/Modal'

interface Props {
  isOpen?: boolean
  handleClick: () => void
  handleClose: () => void
  score: number
  setScore: Function
  modalFor: string
  disableSubmitButton: boolean
}
const GiveScoreModal = ({
  isOpen,
  disableSubmitButton,
  handleClick,
  handleClose,
  score,
  setScore,
  modalFor,
}: Props) => {
  return (
    <>
      <Modal backdrop='static' role='alertdialog' open={isOpen} size='xs' enforceFocus={false}>
        <Modal.Body data-cy='shortlist-score-body'>
          <div className='d-flex'>
            <h4>
              <strong className='ms-3'>Give {modalFor} Score</strong>
              <input
                placeholder={`Type ${modalFor} Score`}
                className='form-control border mt-3'
                type='number'
                value={score}
                onChange={(e) => setScore(e.target.value)}
                data-cy='type-score-field'
              />
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer data-cy='shortlist-score-footer'>
          {score > 0 && (
            <button
              onClick={handleClick}
              disabled={disableSubmitButton}
              data-cy='modal-submit'
              className='btn btn-primary btn-sm ms-3'
            >
              Submit {modalFor} Score
            </button>
          )}
          <button
            onClick={handleClose}
            data-cy='modal-cancel'
            className='btn btn-secondary btn-sm ms-3'
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GiveScoreModal
