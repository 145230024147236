import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const Introductory = `${API_URL}/introductory`

export const service = {
  getIntroductory: (params: ParamsModel) => {
    return axios.get(Introductory, {params})
  },

  addIntroductory: (data: any) => {
    return axios.post(Introductory, data)
  },

  updateIntroductory: (body: any, id: string) => {
    return axios.patch(`${Introductory}/${id}`, body)
  },

  deleteIntroductory: (data: string) => {
    return axios.delete(Introductory, {data})
  },
  singleActivateIntroductory: (id: any) => {
    return axios.patch(`${Introductory}/enable`, id)
  },

  singleDeactivateIntroductory: (id: any) => {
    return axios.patch(`${Introductory}/disable`, id)
  },

  sortIntroductory: (body: any) => {
    return axios.patch(`${Introductory}/sort`, body)
  },
}
