export const actionTypes = {
  // get
  GET_KEY_SETTING_START: 'GET_KEY_SETTING_START',
  GET_KEY_SETTING_SUCCESS: 'GET_KEY_SETTING_SUCCESS',
  GET_KEY_SETTING_FINISH: 'GET_KEY_SETTING_FINISH',
  // create
  CREATE_KEY_START: 'CREATE_KEY_START',
  CREATE_KEY_SUCCESS: 'CREATE_KEY_SUCCESS',
  CREATE_KEY_FINISH: 'CREATE_KEY_FINISH',
  CREATE_KEY_FAILURE: 'CREATE_KEY_FAILURE',
  CREATE_KEY_RESET: 'CREATE_KEY_RESET',
  // update
  UPDATE_KEY_START: 'UPDATE_KEY_START',
  UPDATE_KEY_SUCCESS: 'UPDATE_KEY_SUCCESS',
  UPDATE_KEY_FINISH: 'UPDATE_KEY_FINISH',
  UPDATE_KEY_RESET: 'UPDATE_KEY_RESET',

  // delete
  DELETE_KEY_START: 'DELETE_KEY_START',
  DELETE_KEY_SUCCESS: 'DELETE_KEY_SUCCESS',
  DELETE_KEY_FINISH: 'DELETE_KEY_FINISH',
}
