export const actionTypes = {
  // get
  GET_ALL_FORMS_DOWNLOAD_START: 'GET_ALL_FORMS_DOWNLOAD_START',
  GET_ALL_FORMS_DOWNLOAD_SUCCESS: 'GET_ALL_FORMS_DOWNLOAD_SUCCESS',
  GET_ALL_FORMS_DOWNLOAD_FINISH: 'GET_ALL_FORMS_DOWNLOAD_FINISH',

  // extended hours
  GET_FORMS_DOWNLOAD_START: 'GET_FORMS_DOWNLOAD_START',
  GET_FORMS_DOWNLOAD_SUCCESS: 'GET_FORMS_DOWNLOAD_SUCCESS',
  GET_FORMS_DOWNLOAD_FINISH: 'GET_FORMS_DOWNLOAD_FINISH',

   // category type
   GET_FORMS_DOWNLOAD_CATEGORY_START: 'GET_FORMS_DOWNLOAD_CATEGORY_START',
   GET_FORMS_DOWNLOAD_CATEGORY_SUCCESS: 'GET_FORMS_DOWNLOAD_CATEGORY_SUCCESS',
   GET_FORMS_DOWNLOAD_CATEGORY_FINISH: 'GET_FORMS_DOWNLOAD_CATEGORY_FINISH',

  // add branch
  ADD_FORMS_DOWNLOAD_START: 'ADD_FORMS_DOWNLOAD_START',
  ADD_FORMS_DOWNLOAD_SUCCESS: 'ADD_FORMS_DOWNLOAD_SUCCESS',
  ADD_FORMS_DOWNLOAD_FINISH: 'ADD_FORMS_DOWNLOAD_FINISH',
  RESET_FORMS_DOWNLOAD: 'RESET_FORMS_DOWNLOAD',

  // update FORMS_DOWNLOAD
  UPDATE_FORMS_DOWNLOAD_START: 'UPDATE_FORMS_DOWNLOAD_START',
  UPDATE_FORMS_DOWNLOAD_SUCCESS: 'UPDATE_FORMS_DOWNLOAD_SUCCESS',
  UPDATE_FORMS_DOWNLOAD_FINISH: 'UPDATE_FORMS_DOWNLOAD_FINISH',

  // delete FORMS_DOWNLOAD
  DELETE_FORMS_DOWNLOAD_START: 'DELETE_FORMS_DOWNLOAD_START',
  DELETE_FORMS_DOWNLOAD_SUCCESS: 'DELETE_FORMS_DOWNLOAD_SUCCESS',
  DELETE_FORMS_DOWNLOAD_FINISH: 'DELETE_FORMS_DOWNLOAD_FINISH',

  // Enable FORMS_DOWNLOAD
  ENABLE_FORMS_DOWNLOAD_REQUEST: 'ENABLE_FORMS_DOWNLOAD_REQUEST',
  ENABLE_FORMS_DOWNLOAD_SUCCESS: 'ENABLE_FORMS_DOWNLOAD_SUCCESS',
  ENABLE_FORMS_DOWNLOAD_FINISH: 'ENABLE_FORMS_DOWNLOAD_FINISH',

  // Disable FORMS_DOWNLOAD
  DISABLE_FORMS_DOWNLOAD_REQUEST: 'DISABLE_FORMS_DOWNLOAD_REQUEST',
  DISABLE_FORMS_DOWNLOAD_SUCCESS: 'DISABLE_FORMS_DOWNLOAD_SUCCESS',
  DISABLE_FORMS_DOWNLOAD_FINISH: 'DISABLE_FORMS_DOWNLOAD_FINISH',

  // Enable FORMS_DOWNLOAD
  SINGLE_ENABLE_FORMS_DOWNLOAD_REQUEST: 'SINGLE_ENABLE_FORMS_DOWNLOAD_REQUEST',
  SINGLE_ENABLE_FORMS_DOWNLOAD_SUCCESS: 'SINGLE_ENABLE_FORMS_DOWNLOAD_SUCCESS',
  SINGLE_ENABLE_FORMS_DOWNLOAD_FINISH: 'SINGLE_ENABLE_FORMS_DOWNLOAD_FINISH',

  // Disable FORMS_DOWNLOAD
  SINGLE_DISABLE_FORMS_DOWNLOAD_REQUEST: 'SINGLE_DISABLE_FORMS_DOWNLOAD_REQUEST',
  SINGLE_DISABLE_FORMS_DOWNLOAD_SUCCESS: 'SINGLE_DISABLE_FORMS_DOWNLOAD_SUCCESS',
  SINGLE_DISABLE_FORMS_DOWNLOAD_FINISH: 'SINGLE_DISABLE_FORMS_DOWNLOAD_FINISH',

  // sort
  SORT_FORMS_DOWNLOAD_START: 'SORT_FORMS_DOWNLOAD_START',
  SORT_FORMS_DOWNLOAD_SUCCESS: 'SORT_FORMS_DOWNLOAD_SUCCESS',
  SORT_FORMS_DOWNLOAD_FINISH: 'SORT_FORMS_DOWNLOAD_FINISH',
  SORT_FORMS_DOWNLOAD_RESET: 'SORT_FORMS_DOWNLOAD_RESET',
}
