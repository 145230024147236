import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get AppointmentRequest saga
function* getAppointmentRequestSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAppointmentRequest, params)
    const data: any = response?.data
    yield put(actions.getAppointmentRequestSuccess(data))
  } catch (error: any) {
    yield put(actions.getAppointmentRequestError(error))
  }
}

//Create AppointmentRequest
/* function* createAppointmentRequestSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.addAppointmentRequest, body)
    yield put({type: actionTypes.ADD_APPOINTMENT_REQUEST_SUCCESS, payload: response.data})
    yield put({type: actionTypes.ADD_APPOINTMENT_REQUEST_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_APPOINTMENT_REQUEST_FINISH})
  }
} */

//Update AppointmentRequest
function* updateAppointmentRequestSaga(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateAppointmentRequest,
      body,
      action.payload.id
    )
    yield put({type: actionTypes.UPDATE_APPOINTMENT_REQUEST_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_APPOINTMENT_REQUEST_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_APPOINTMENT_REQUEST_FINISH})
  }
}

//Get appointment Request file
function* getAppointmentRequestFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAppointmentRequestFile, fileName)
    const data: any = response?.data
    yield put(actions.getAppointmentRequestFileSuccess(data))
  } catch (error: any) {
    yield put(actions.getAppointmentRequestFileError(error))
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_APPOINTMENT_REQUEST_START, getAppointmentRequestSaga)
  //yield takeLatest(actionTypes.ADD_APPOINTMENT_REQUEST_REQUEST, createAppointmentRequestSaga)
  yield takeLatest(actionTypes.UPDATE_APPOINTMENT_REQUEST_START, updateAppointmentRequestSaga)
  yield takeLatest(actionTypes.GET_REQUEST_FILE_START, getAppointmentRequestFileSaga)
}
