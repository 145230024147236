export const COMMON_TYPES = {
  REQUEST: '_REQUEST',
  SUCCESS: '_SUCCESS',
  FAILURE: '_FAILURE',
  CALL_OVER: '_FINISHED',
}

export const PORTFOLIO_TYPES = {
  GET_PORTFOLIO: 'GET_PORTFOLIO',
  GET_PORTFOLIO_LIST: 'GET_PORTFOLIO_LIST',
  ADD_PORTFOLIO: 'ADD_PORTFOLIO',
  DELETE_PORTFOLIO: 'DELETE_PORTFOLIO',
  RESET_PORTFOLIO: 'RESET_PORTFOLIO',
}
