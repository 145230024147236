export const actionTypes = {
  // get
  GET_ALL_SERVICE_SUB_TYPE_START: 'GET_ALL_SERVICE_SUB_TYPE_START',
  GET_ALL_SERVICE_SUB_TYPE_SUCCESS: 'GET_ALL_SERVICE_SUB_TYPE_SUCCESS',
  GET_ALL_SERVICE_SUB_TYPE_FINISH: 'GET_ALL_SERVICE_SUB_TYPE_FINISH',

  // extended hours
  GET_SERVICE_SUB_TYPE_START: 'GET_SERVICE_SUB_TYPE_START',
  GET_SERVICE_SUB_TYPE_SUCCESS: 'GET_SERVICE_SUB_TYPE_SUCCESS',
  GET_SERVICE_SUB_TYPE_FINISH: 'GET_SERVICE_SUB_TYPE_FINISH',

  // add branch
  ADD_SERVICE_SUB_TYPE_START: 'ADD_SERVICE_SUB_TYPE_START',
  ADD_SERVICE_SUB_TYPE_SUCCESS: 'ADD_SERVICE_SUB_TYPE_SUCCESS',
  ADD_SERVICE_SUB_TYPE_FINISH: 'ADD_SERVICE_SUB_TYPE_FINISH',
  RESET_SERVICE_TYPE: 'RESET_SERVICE_TYPE',

  // update SERVICE_TYPE
  UPDATE_SERVICE_SUB_TYPE_START: 'UPDATE_SERVICE_SUB_TYPE_START',
  UPDATE_SERVICE_SUB_TYPE_SUCCESS: 'UPDATE_SERVICE_SUB_TYPE_SUCCESS',
  UPDATE_SERVICE_SUB_TYPE_FINISH: 'UPDATE_SERVICE_SUB_TYPE_FINISH',

  // delete SERVICE_TYPE
  DELETE_SERVICE_SUB_TYPE_START: 'DELETE_SERVICE_SUB_TYPE_START',
  DELETE_SERVICE_SUB_TYPE_SUCCESS: 'DELETE_SERVICE_SUB_TYPE_SUCCESS',
  DELETE_SERVICE_SUB_TYPE_FINISH: 'DELETE_SERVICE_SUB_TYPE_FINISH',

  // Enable SERVICE_TYPE
  ENABLE_SERVICE_SUB_TYPE_REQUEST: 'ENABLE_SERVICE_SUB_TYPE_REQUEST',
  ENABLE_SERVICE_SUB_TYPE_SUCCESS: 'ENABLE_SERVICE_SUB_TYPE_SUCCESS',
  ENABLE_SERVICE_SUB_TYPE_FINISH: 'ENABLE_SERVICE_SUB_TYPE_FINISH',

  // Disable SERVICE_TYPE
  DISABLE_SERVICE_SUB_TYPE_REQUEST: 'DISABLE_SERVICE_SUB_TYPE_REQUEST',
  DISABLE_SERVICE_SUB_TYPE_SUCCESS: 'DISABLE_SERVICE_SUB_TYPE_SUCCESS',
  DISABLE_SERVICE_SUB_TYPE_FINISH: 'DISABLE_SERVICE_SUB_TYPE_FINISH',

  // Enable SERVICE_TYPE
  SINGLE_ENABLE_SERVICE_SUB_TYPE_REQUEST: 'SINGLE_ENABLE_SERVICE_SUB_TYPE_REQUEST',
  SINGLE_ENABLE_SERVICE_SUB_TYPE_SUCCESS: 'SINGLE_ENABLE_SERVICE_SUB_TYPE_SUCCESS',
  SINGLE_ENABLE_SERVICE_SUB_TYPE_FINISH: 'SINGLE_ENABLE_SERVICE_SUB_TYPE_FINISH',

  // Disable SERVICE_TYPE
  SINGLE_DISABLE_SERVICE_SUB_TYPE_REQUEST: 'SINGLE_DISABLE_SERVICE_SUB_TYPE_REQUEST',
  SINGLE_DISABLE_SERVICE_SUB_TYPE_SUCCESS: 'SINGLE_DISABLE_SERVICE_SUB_TYPE_SUCCESS',
  SINGLE_DISABLE_SERVICE_SUB_TYPE_FINISH: 'SINGLE_DISABLE_SERVICE_SUB_TYPE_FINISH',
}
