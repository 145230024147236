import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialVacancyApplicantState: IVacancyApplicantState = {
  data: {
    vacancyApplicant: [],
    meta: {},
  },
  loading: false,
  success: false,
}

export interface IVacancyApplicantState {
  data: {
    vacancyApplicant: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  vacancyApplicant?: IVacancyApplicantState[]
  loading: false
  success: false
 
}

export const reducer = (
  state: IVacancyApplicantState = initialVacancyApplicantState,
  action: ActionWithPayload<IVacancyApplicantState>
) => {
  switch (action.type) {
    //Get vacancy applicant Reducer
    case actionTypes.GET_VACANCY_APPLICANT_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_VACANCY_APPLICANT_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_VACANCY_APPLICANT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
        return state
    }
  }

  /* Applicant status */
  const initialApplicantStatusState: IApplicantStatusState = {
    data: {
      vacancyApplicantStatus: [],
    },
    loading: false,
    success: false,
  }
  
  export interface IApplicantStatusState {
    data: {
      vacancyApplicantStatus: {[key: string]: number | string}[]
    }
    vacancyApplicantStatus?: IApplicantStatusState[]
    loading: false
    success: false
   
  }
  
  export const reducer1 = (
    state: IApplicantStatusState = initialApplicantStatusState,
    action: ActionWithPayload<IApplicantStatusState>
  ) => {
    switch (action.type) {
      //Get vacancy applicant Status Reducer
      case actionTypes.GET_APPLICANT_STATUS_START: {
        return {...state, loading: true}
      }
  
      case actionTypes.GET_APPLICANT_STATUS_SUCCESS: {
        return {...state, data: action.payload?.data, loading: false}
      }
  
      case actionTypes.GET_APPLICANT_STATUS_FINISH: {
        const error = action.payload
        return {...state, error, loading: false}
      }
  
      default:
          return state
      }
    }
  