import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_APPOINTMENT_REQUEST = `${API_URL}/appointment-request`

export const service = {
  getAppointmentRequest: (params: ParamsModel) => {
    return axios.get(GET_APPOINTMENT_REQUEST, {params})
  },

  updateAppointmentRequest: (body: any, id: string) => {
    return axios.patch(`${GET_APPOINTMENT_REQUEST}/${id}`, body)
  },

  getAppointmentRequestFile: (params: ParamsModel) => {
    return axios.get(`${GET_APPOINTMENT_REQUEST}/export`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `appointment-request.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
