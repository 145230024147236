import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getFeedbackFormDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getFeedbackFormDetail, params)
    yield put(actions.getFeedbackFormDetailSuccess(response?.data?.data))
    yield put(actions.getFeedbackFormDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getFeedbackFormDetailFinish())
  }
}

//Get branchless file
function* exportFeedbackFormDetailFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    const params: any = action.payload.params
    const response: ResponseModel = yield call(
      service.exportFeedbackFormDetailFile,
      fileName,
      params
    )
    const responseData: any = response?.data
    yield put(actions.exportFileSuccess(responseData))
    yield put(actions.exportFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportFileError())
  }
}

function* deleteFeedbackSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteFeedback, body)
    yield put(actions.deleteFeedbackSuccess(response.data?.data))
    yield put(actions.deleteFeedbackFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteFeedbackFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_FEEDBACK_FORM_DETAIL_START, getFeedbackFormDetailSaga)
  yield takeLatest(
    actionTypes.EXPORT_FEEDBACK_FORM_DETAIL_FILE_START,
    exportFeedbackFormDetailFileSaga
  )
  yield takeLatest(actionTypes.DELETE_FEEDBACK_START, deleteFeedbackSaga)
}
