import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {BannerModelOffer, SortBannerModelOffer} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_BANNER_OFFER = `${API_URL}/banneroffer`
export const ADD_BANNER_OFFER = `${API_URL}/banneroffer`
export const ACTIVATE_BANNER_OFFERS = `${API_URL}/banneroffer/enable`
export const DEACTIVATE_BANNER_OFFERS = `${API_URL}/banneroffer/disable`
export const DELETE_BANNER_OFFER = `${API_URL}/banneroffer`
export const GET_COLOR_OPTION = `${API_URL}/banner-offer-background-color-option`

export const service = {
  getBannerOffer: (params: ParamsModel) => {
    return axios.get(GET_BANNER_OFFER, {params})
  },
  getAllBanner: () => {
    return axios.get(`${GET_BANNER_OFFER}/list`)
  },
  getColorOption: () => {
    return axios.get(GET_COLOR_OPTION)
  },

  addBanner: (data: any) => {
    return axios.post(ADD_BANNER_OFFER, data)
  },

  activateBanner: (data: Array<string>) => {
    const formData = {
      bannerId: data,
    }
    return axios.patch(ACTIVATE_BANNER_OFFERS, formData)
  },

  singleActivateBanner: (data: Array<string>) => {
    const formData = {
      bannerId: [data],
    }
    return axios.patch(ACTIVATE_BANNER_OFFERS, formData)
  },
  singleDeactivateBanner: (data: Array<string>) => {
    const formData = {
      bannerId: [data],
    }
    return axios.patch(DEACTIVATE_BANNER_OFFERS, formData)
  },

  deactivateBanner: (data: Array<string>) => {
    const formData = {
      bannerId: data,
    }
    return axios.patch(DEACTIVATE_BANNER_OFFERS, formData)
  },

  updateBanner: (body: BannerModelOffer, id: string) => {
    return axios.patch(`${ADD_BANNER_OFFER}/${id}`, body)
  },

  deleteBanner: (data: BannerModelOffer) => {
    return axios.delete(DELETE_BANNER_OFFER, {data})
  },
  sortBanner: (body: SortBannerModelOffer) => {
    return axios.patch(`${GET_BANNER_OFFER}/sort`, body)
  },
}
