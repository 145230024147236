import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// includes
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import axios from 'axios'
import {isEmpty} from 'lodash'
import {FiEye} from 'react-icons/fi'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {serviceCategory} from '../../..'
import * as departments from '../../../../userMgmt/components/departments/index'
import * as grievanceRedux from '../index'
import ViewGrievanceDetails from './ViewDetails'
import GrievanceActionsModal from './grievanceActionsModal'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const GrievanceHandlingComponent = () => {
  const dispatch = useDispatch()
  const {
    data: {grievance, meta},
    deleteSuccess,
    success,
    loading,
  } = useSelector((state: any) => state.grievance)
  const departmentData = useSelector((state: any) => state.departments)

  const {data: grievanceServiceCategory} = useSelector((state: any) => state.serviceCategory)

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  useEffect(() => {
    dispatch(grievanceRedux.actions.getGrievance(params))
  }, [params])

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const [showGrievanceActionModal, setShowGrievanceActionModal] = useState(false)
  const [selectedGrievanceId, setSelectedGrievanceId] = useState(false)
  const [grievanceStatusData, setGrievanceStatusData]: any = useState([])
  const [status, setStatus] = useState('')
  const [department, setDepartment] = useState('')
  const [remarks, setRemarks] = useState('')
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const [showGrievanceDetailsModal, setShowGrievanceDetailsModal] = useState(false)

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
            <IconButton
              onClick={() => {
                setShowGrievanceDetailsModal(true)
                setSelectedGrievanceId(rowData?.id)
              }}
              data-cy='edit-button'
              appearance='subtle'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let branchId = [rowData.id]
                setCheckedValues(branchId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              onClick={() => {
                setRemarks('')
                setDepartment(rowData?.department || 'Select Department')
                setStatus(rowData?.status || 'Select Status')
                setDisableSubmitButton(false)
                setShowGrievanceActionModal(true)
                setSelectedGrievanceId(rowData?.id)
              }}
              data-cy='department-edit-button'
              appearance='subtle'
              icon={<Edit2 />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Grievance Code',
      dataKey: 'grievanceCode',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) => (!isEmpty(rowData?.grievanceCode) ? rowData?.grievanceCode : '-')}
        </Cell>
      ),
      sortable: true,
    },

    {
      label: 'Full Name',
      dataKey: 'fullName',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.fullName) ? rowData?.fullName : '-')}</Cell>,

      sortable: false,
    },
    {
      label: 'Email Address',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.email) ? rowData?.email : '-')}</Cell>,

      sortable: false,
    },
    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      flexGrow: 1,
      cell: (
        <Cell>{(rowData) => (!isEmpty(rowData?.mobileNumber) ? rowData?.mobileNumber : '-')}</Cell>
      ),

      sortable: false,
    },
    {
      label: 'Preferred Branch',
      dataKey: 'preferredBranch',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) =>
            !isEmpty(rowData?.preferredBranch?.name) ? rowData?.preferredBranch?.name : '-'
          }
        </Cell>
      ),

      sortable: false,
    },
    {
      label: 'Service Category',
      dataKey: 'serviceCategory',
      flexGrow: 1,
      cell: (
        <Cell>{(rowData) => (rowData?.serviceCategory ? rowData?.serviceCategory.name : '-')}</Cell>
      ),

      sortable: false,
    },
    {
      label: 'Assigned To',
      dataKey: 'department',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (rowData?.department ? rowData?.department : '-')}</Cell>,
      sortable: false,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: <Cell>{(rowData) => rowData?.status}</Cell>,
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]
  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Grievance deleted successfully')
    }

    handleChecked([])
    dispatch(grievanceRedux?.actions.getGrievance())
  }, [deleteSuccess, success])

  const grievanceData = !isEmpty(grievance)
    ? grievance?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        fullName: item.fullName,
        email: item.email,
        grievanceCode: item.grievanceCode,
        message: item.message,
        mobileNumber: item.mobileNumber,
        preferredBranch: item.preferredBranch,
        preferredBranchName: item?.preferredBranch?.name,
        serviceSubType: item.serviceSubType,
        serviceType: item.serviceType,
        accountNumber: item.accountNumber,
        serviceCategory: item.serviceCategory,
        status: item.status?.name,
        department: item.department?.name,
      }))
    : []

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleDelete = () => {
    let grievanceId = checkedValues?.map((value) => ({id: value}))
    dispatch(grievanceRedux?.actions?.deleteGrievance(grievanceId))
    handleAlertClose()
  }

  useEffect(() => {
    handleChecked([])
    dispatch(grievanceRedux?.actions.getGrievance())
    dispatch(departments.actions.getDepartment())
  }, [success])

  const handleExport = () => {
    dispatch(grievanceRedux.actions.exportFile('Grievance Data', params))
  }
  const handleRefresh = () => {
    dispatch(grievanceRedux.actions.getGrievance())
  }

  useEffect(() => {
    axios.get(API_URL + '/grievance-status-option').then((res) => {
      setGrievanceStatusData(res.data?.data?.grievanceStatusOption)
    })
  }, [])

  useEffect(() => {
    dispatch(serviceCategory.actions.getAllServiceCategory())
  }, [])

  const updateGrievanceStatus = () => {
    setDisableSubmitButton(true)

    const statusId = grievanceStatusData.filter(
      (g: {displayName: string; id: string}) => g.displayName === status
    )[0]?.id
    const departmentId = departmentData?.data?.department.filter(
      (d: {name: string}) => d.name === department
    )[0]?.id

    axios
      .put(API_URL + `/grievance/${selectedGrievanceId}`, {
        status: statusId,
        department: departmentId,
        remarks: remarks,
      })
      .then((res) => {
        toast.success('Successfully edited the grievance.')
        handleRefresh()
        setShowGrievanceActionModal(false)
      })
      .catch((err) => {
        toast.error('Something went wrong.')
        setDisableSubmitButton(false)
      })
  }

  const serviceCategoryOptions = grievanceServiceCategory?.category?.map(
    (item: {[key: string]: string}) => ({
      label: item.name,
      value: item.id,
    })
  )

  const enableCategoryFilter = checkBackendSetting('enableCategoryFilter')

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Grievance Manager'
          params={params}
          setParams={setParams}
          filterData={true}
          handleExport={handleExport}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          addShow={false}
          deleteShow={true}
          importShow={false}
          toggleMultipleShow={false}
          enableDropdownFilter={enableCategoryFilter}
          dropdownOption={serviceCategoryOptions}
          handleDropdownChange={(option: any) => {
            setParams({...params, serviceCategory: option?.value})
          }}
          dropdownValue={serviceCategoryOptions?.find((obj: any) =>
            params?.serviceCategory?.includes(obj?.value)
          )}
          dropdownPlaceholder='Filter by Category'
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            showLoading={loading}
            data={grievanceData}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />

          {showGrievanceActionModal && (
            <GrievanceActionsModal
              remarks={remarks}
              handleClick={updateGrievanceStatus}
              handleClose={() => {
                setShowGrievanceActionModal(false)
              }}
              setRemarks={setRemarks}
              department={department}
              setDepartment={setDepartment}
              statusList={grievanceStatusData}
              isOpen={showGrievanceActionModal}
              disableSubmitButton={disableSubmitButton}
              status={status}
              setStatus={setStatus}
              departmentList={departmentData?.data?.department}
            />
          )}

          {showGrievanceDetailsModal && (
            <ViewGrievanceDetails
              data={grievance?.filter((d: any) => d.id === selectedGrievanceId)[0]}
              handleClose={() => setShowGrievanceDetailsModal(false)}
            />
          )}

          {/* Delete Modal */}
          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => handleDelete()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </div>
      </div>
    </>
  )
}

export default GrievanceHandlingComponent
