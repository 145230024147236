export const actionTypes = {
  GET_ACTIVE_TUTORIAL_CATEGORY_START: 'GET_ACTIVE_TUTORIAL_CATEGORY_START',
  GET_ACTIVE_TUTORIAL_CATEGORY_SUCCESS: 'GET_ACTIVE_TUTORIAL_CATEGORY_SUCCESS',
  GET_ACTIVE_TUTORIAL_CATEGORY_FINISH: 'GET_ACTIVE_TUTORIAL_CATEGORY_FINISH',

  GET_TUTORIAL_CATEGORY_START: 'GET_TUTORIAL_CATEGORY_START',
  GET_TUTORIAL_CATEGORY_SUCCESS: 'GET_TUTORIAL_CATEGORY_SUCCESS',
  GET_TUTORIAL_CATEGORY_FINISH: 'GET_TUTORIAL_CATEGORY_FINISH',

  ADD_TUTORIAL_CATEGORY_START: 'ADD_TUTORIAL_CATEGORY_START',
  ADD_TUTORIAL_CATEGORY_SUCCESS: 'ADD_TUTORIAL_CATEGORY_SUCCESS',
  ADD_TUTORIAL_CATEGORY_FINISH: 'ADD_TUTORIAL_CATEGORY_FINISH',
  ADD_TUTORIAL_CATEGORY_RESET: 'ADD_TUTORIAL_CATEGORY_RESET',

  UPDATE_TUTORIAL_CATEGORY_START: 'UPDATE_TUTORIAL_CATEGORY_START',
  UPDATE_TUTORIAL_CATEGORY_SUCCESS: 'UPDATE_TUTORIAL_CATEGORY_SUCCESS',
  UPDATE_TUTORIAL_CATEGORY_FINISH: 'UPDATE_TUTORIAL_CATEGORY_FINISH',
  UPDATE_TUTORIAL_CATEGORY_RESET: 'UPDATE_TUTORIAL_CATEGORY_RESET',

  DELETE_TUTORIAL_CATEGORY_START: 'DELETE_TUTORIAL_CATEGORY_START',
  DELETE_TUTORIAL_CATEGORY_SUCCESS: 'DELETE_TUTORIAL_CATEGORY_SUCCESS',
  DELETE_TUTORIAL_CATEGORY_FINISH: 'DELETE_TUTORIAL_CATEGORY_FINISH',

  SINGLE_ACTIVATE_TUTORIAL_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_TUTORIAL_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_TUTORIAL_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_TUTORIAL_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_TUTORIAL_CATEGORY_FINISH: 'SINGLE_ACTIVATE_TUTORIAL_CATEGORY_FINISH',

  SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_FINISH',

  SORT_TUTORIAL_CATEGORY_START: 'SORT_TUTORIAL_CATEGORY_START',
  SORT_TUTORIAL_CATEGORY_SUCCESS: 'SORT_TUTORIAL_CATEGORY_SUCCESS',
  SORT_TUTORIAL_CATEGORY_FINISH: 'SORT_TUTORIAL_CATEGORY_FINISH',
  SORT_TUTORIAL_CATEGORY_RESET: 'SORT_TUTORIAL_CATEGORY_RESET',
}
