import {ParamsModel} from 'src/app/modules/common/Model'
import {AppointmentTypeModel, DeleteAppointmentTypeModel} from '../Model'
import {actionTypes} from './constants'
import {IAppointmentTypeState} from './reducer'

export const actions = {
  // get MeetingType
  getMeetingType: () => {
    return {
      type: actionTypes.GET_MEETING_TYPE_START,
    }
  },
  getMeetingTypeSuccess: (data: any) => ({
    type: actionTypes.GET_MEETING_TYPE_SUCCESS,
    payload: data,
  }),
  getMeetingTypeError: (data: any) => ({
    type: actionTypes.GET_MEETING_TYPE_FINISH,
    payload: data,
  }),

  // get AppointmentType
  getAppointmentType: (params: ParamsModel = {page: 1, limit: 10}, id: string = '') => {
    return {
      type: actionTypes.GET_APPOINTMENT_TYPE_START,
      payload: {params, id},
    }
  },
  getAppointmentTypeSuccess: (data: IAppointmentTypeState) => ({
    type: actionTypes.GET_APPOINTMENT_TYPE_SUCCESS,
    payload: data,
  }),
  getAppointmentTypeError: (data: IAppointmentTypeState) => ({
    type: actionTypes.GET_APPOINTMENT_TYPE_FINISH,
    payload: data,
  }),

  // get AppointmentType
  getAllAppointmentType: () => {
    return {
      type: actionTypes.GET_ALL_APPOINTMENT_TYPE_START,
    }
  },
  getAllAppointmentTypeSuccess: (data: IAppointmentTypeState) => ({
    type: actionTypes.GET_ALL_APPOINTMENT_TYPE_SUCCESS,
    payload: data,
  }),
  getAllAppointmentTypeError: () => ({
    type: actionTypes.GET_ALL_APPOINTMENT_TYPE_FINISH,
  }),

  //Add AppointmentType Action
  CreateAppointmentType: (data: any) => ({
    type: actionTypes.ADD_APPOINTMENT_TYPE_REQUEST,
    payload: data,
  }),

  createAppointmentTypeSuccess: (task: any) => ({
    type: actionTypes.ADD_APPOINTMENT_TYPE_SUCCESS,
    payload: task,
  }),
  createAppointmentTypeFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_APPOINTMENT_TYPE_FINISH,
    payload: errorMsg,
  }),
  createAppointmentTypeReset: () => ({
    type: actionTypes.ADD_APPOINTMENT_TYPE_RESET,
  }),

  //Activate and Deactivate Bulk AppointmentType
  activateAppointmentType: (data: any) => ({
    type: actionTypes.ACTIVATE_APPOINTMENT_TYPE_REQUEST,
    payload: {data},
  }),
  deactivateAppointmentType: (data: any) => ({
    type: actionTypes.DEACTIVATE_APPOINTMENT_TYPE_REQUEST,
    payload: {data},
  }),

  //Single Activate and Deactivate AppointmentType
  singleActivateAppointmentType: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_TYPE_REQUEST,
    payload: {data},
  }),

  singleDeactivateAppointmentType: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_TYPE_REQUEST,
    payload: {data},
  }),

  // update AppointmentType
  updateAppointmentType: (data: AppointmentTypeModel, id: string) => ({
    type: actionTypes.UPDATE_APPOINTMENT_TYPE_START,
    payload: {data, id},
  }),

  //delete and reset AppointmentType
  deleteAppointmentType: (data: DeleteAppointmentTypeModel[]) => ({
    type: actionTypes.DELETE_APPOINTMENT_TYPE_START,
    payload: {appointmentTypeId: data},
  }),
}
