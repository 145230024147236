import { ErrorMessage, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'

import { isEmpty } from 'lodash'
import { StateParamsModel } from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import { imageBaseUrl } from 'src/cms/helpers/constants'
import * as category from '../../applicationCategory'
import * as onlineApplication from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Application Name is required'),
  status: Yup.string().required('Status required'),
})

const AddOnlineApplication = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const { loading, success } = useSelector((state: any) => state.onlineApplicationManager)
  const [imageError, setImageError] = useState(false)

  const categoryData: any = useSelector(
    (state: any) => state.onlineApplicationCategory?.data?.onlineApplicationCategory
  )

  useEffect(() => {
    dispatch(category.getAllCategory(params))
  }, [])

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  const categoryOptions = categoryData?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(onlineApplication?.actions?.getApplication(params))
      isEmpty(editSelectedData)
        ? toast.success('Online Application added successfully')
        : toast.success('Online Application edited successfully')
      dispatch(onlineApplication?.actions?.sortApplicationReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Application Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                titleNp: '',
                firstButtonText: '',
                firstButtonTextNp: '',
                firstButtonUrl: '',
                secondButtonText: '',
                secondButtonTextNp: '',
                secondButtonUrl: '',
                status: 'Active',
                categoryId: '',
                images: [
                  {
                    image: '',
                  },
                ],
                created_at: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { }) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  created_at: values?.created_at ? values.created_at : new Date().toISOString(),
                }

                //validating images list
                for (let data of values.images) {
                  if (!data.image) {
                    setImageError(true)
                    return
                  }
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    onlineApplication?.actions?.updateApplication(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(onlineApplication?.actions?.addApplication(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'title',
                      'titleNp',
                      'firstButtonText',
                      'firstButtonTextNp',
                      'firstButtonUrl',
                      'secondButtonText',
                      'secondButtonTextNp',
                      'secondButtonUrl',
                      'status',
                      'categoryId',
                      'images',
                      'created_at',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Application Name (EN)'
                            label='Application Name (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Application Name (NP)'
                            label='Application Name (NP)'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Primary Button Text (EN)'
                            label='Primary Button Text (EN)'
                            name='firstButtonText'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Primary Button Text (NP)'
                            label='Primary Button Text (NP)'
                            name='firstButtonTextNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Primary Button URL'
                            label='Primary Button URL'
                            name='firstButtonUrl'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Secondary Button Text (EN)'
                            label='Secondary Button Text (EN)'
                            name='secondButtonText'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Secondary Button Text (NP)'
                            label='Secondary Button Text (NP)'
                            name='secondButtonTextNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Secondary Button URL'
                            label='Secondary Button URL'
                            name='secondButtonUrl'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select category'
                            label='Category'
                            name='categoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        <div className='d-flex flex-column'>
                          {values.images?.map((val, i) => {
                            return (
                              <div className='col-md-6 col-xs-12 d-flex flex-column' key={i}>
                                <div className='col-12 pt-5 border-top'>
                                  {i > 0 && (
                                    <div className=' d-flex justify-content-md-end mb-2'>
                                      <button
                                        type='button'
                                        className='p-2 ps-5 pe-5 btn btn-secondary'
                                        onClick={() => {
                                          const updatedImages = values.images.filter(
                                            (_: any, index: number) => index !== i
                                          )
                                          setFieldValue('images', updatedImages)
                                          setImageError(false)
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label={`Image ${i + 1}`}
                                  name='images'
                                  setFieldValue={(_: string, downloadLink: string) => {
                                    const updatedImages = [...values.images]
                                    updatedImages[i].image = downloadLink
                                    setFieldValue('images', updatedImages)
                                    setImageError(false)
                                  }}
                                  value={val.image}
                                  readOnly={true}
                                  showImageOnly
                                  required={true}
                                />
                                {!isEmpty(val.image) && (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            const updatedImages = values.images.filter(
                                              (_: any, index: number) => index !== i
                                            )
                                            setFieldValue('images', updatedImages)
                                            setImageError(false)
                                          }}
                                          data-cy='modal-thumbnail-remove'

                                        >
                                          Delete
                                        </button>
                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${val?.image}`}
                                          alt='image'
                                          data-cy='onlineApplication-image'
                                        />
                                      </div>
                                    </li>
                                  </>
                                )}
                              </div>
                            )
                          })}
                        </div>

                        {imageError && <p className='text-danger'>Please select image</p>}

                        <button
                          type='button'
                          className='btn btn-primary btn-sm ms-3 col-md-2 p-2 my-2'
                          disabled={values.images.some((img) => img.image === '')}
                          onClick={() => setFieldValue('images', [...values.images, { image: '' }])}
                          data-cy='add-image-button'
                        >
                          Add New Image
                        </button>

                        <br />

                        <div className='col-md-12 mt-5 col-xs-12 '>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddOnlineApplication
