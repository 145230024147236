export const actionTypes = {
  // get CONTACT_PERSON
  GET_CONTACT_PERSON_START: 'GET_CONTACT_PERSON_START',
  GET_CONTACT_PERSON_SUCCESS: 'GET_CONTACT_PERSON_SUCCESS',
  GET_CONTACT_PERSON_FINISH: 'GET_CONTACT_PERSON_FINISH',

  // add CONTACT_PERSON
  ADD_CONTACT_PERSON_START: 'ADD_CONTACT_PERSON_START',
  ADD_CONTACT_PERSON_SUCCESS: 'ADD_CONTACT_PERSON_SUCCESS',
  ADD_CONTACT_PERSON_FINISH: 'ADD_CONTACT_PERSON_FINISH',
  RESET_CONTACT_PERSON: 'RESET_CONTACT_PERSON',

  // update CONTACT_PERSON
  UPDATE_CONTACT_PERSON_START: 'UPDATE_CONTACT_PERSON_START',
  UPDATE_CONTACT_PERSON_SUCCESS: 'UPDATE_CONTACT_PERSON_SUCCESS',
  UPDATE_CONTACT_PERSON_FINISH: 'UPDATE_CONTACT_PERSON_FINISH',

  // delete CONTACT_PERSON
  DELETE_CONTACT_PERSON_START: 'DELETE_CONTACT_PERSON_START',
  DELETE_CONTACT_PERSON_SUCCESS: 'DELETE_CONTACT_PERSON_SUCCESS',
  DELETE_CONTACT_PERSON_FINISH: 'DELETE_CONTACT_PERSON_FINISH',

  // sort
  SORT_CONTACT_PERSON_START: 'SORT_CONTACT_PERSON_START',
  SORT_CONTACT_PERSON_SUCCESS: 'SORT_CONTACT_PERSON_SUCCESS',
  SORT_CONTACT_PERSON_FINISH: 'SORT_CONTACT_PERSON_FINISH',
  SORT_CONTACT_PERSON_RESET: 'SORT_CONTACT_PERSON_RESET',
}
