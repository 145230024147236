import {ParamsModel} from 'src/app/modules/common/Model'
import {AppointmentReasonModel, DeleteAppointmentReasonModel} from '../Model'
import {actionTypes} from './constants'
import {IAppointmentReasonState} from './reducer'

export const actions = {
  // get AppointmentReason
  getAppointmentReason: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_APPOINTMENT_REASON_START,
      payload: {params},
    }
  },
  getAppointmentReasonSuccess: (data: IAppointmentReasonState) => ({
    type: actionTypes.GET_APPOINTMENT_REASON_SUCCESS,
    payload: data,
  }),
  getAppointmentReasonError: (data: IAppointmentReasonState) => ({
    type: actionTypes.GET_APPOINTMENT_REASON_FINISH,
    payload: data,
  }),

  // get AppointmentReason
  getAllAppointmentReason: () => {
    return {
      type: actionTypes.GET_ALL_APPOINTMENT_REASON_START,
    }
  },
  getAllAppointmentReasonSuccess: (data: IAppointmentReasonState) => ({
    type: actionTypes.GET_ALL_APPOINTMENT_REASON_SUCCESS,
    payload: data,
  }),
  getAllAppointmentReasonError: (data: IAppointmentReasonState) => ({
    type: actionTypes.GET_ALL_APPOINTMENT_REASON_FINISH,
    payload: data,
  }),

  //Add AppointmentReason Action
  CreateAppointmentReason: (data: any) => ({
    type: actionTypes.ADD_APPOINTMENT_REASON_REQUEST,
    payload: data,
  }),

  createAppointmentReasonSuccess: (task: any) => ({
    type: actionTypes.ADD_APPOINTMENT_REASON_SUCCESS,
    payload: task,
  }),
  createAppointmentReasonFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_APPOINTMENT_REASON_FINISH,
    payload: errorMsg,
  }),
  createAppointmentReasonReset: () => ({
    type: actionTypes.ADD_APPOINTMENT_REASON_RESET,
  }),

  //Activate and Deactivate Bulk AppointmentReason
  activateAppointmentReason: (data: any) => ({
    type: actionTypes.ACTIVATE_APPOINTMENT_REASON_REQUEST,
    payload: {data},
  }),
  deactivateAppointmentReason: (data: any) => ({
    type: actionTypes.DEACTIVATE_APPOINTMENT_REASON_REQUEST,
    payload: {data},
  }),

  //Single Activate and Deactivate AppointmentReason
  singleActivateAppointmentReason: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_REQUEST,
    payload: {data},
  }),

  singleDeactivateAppointmentReason: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_REQUEST,
    payload: {data},
  }),

  // update AppointmentReason
  updateAppointmentReason: (data: AppointmentReasonModel, id: string) => ({
    type: actionTypes.UPDATE_APPOINTMENT_REASON_START,
    payload: {data, id},
  }),

  //delete  AppointmentReason
  deleteAppointmentReason: (data: DeleteAppointmentReasonModel[]) => ({
    type: actionTypes.DELETE_APPOINTMENT_REASON_START,
    payload: {appointmentReasonId: data},
  }),
}
