import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const Award = `${API_URL}/award`

export const service = {
  getAward: (params: ParamsModel) => {
    return axios.get(Award, {params})
  },

  addAward: (data: any) => {
    return axios.post(Award, data)
  },

  updateAward: (body: any, id: string) => {
    return axios.put(`${Award}/${id}`, body)
  },

  deleteAward: (data: string) => {
    return axios.delete(`${Award}/bulk-delete`, {data})
  },
  singleActivateAward: (id: any) => {
    return axios.patch(`${Award}/enable`, id)
  },

  singleDeactivateAward: (id: any) => {
    return axios.patch(`${Award}/disable`, id)
  },

  sortAward: (body: any) => {
    return axios.patch(`${Award}/sort`, body)
  },
}
