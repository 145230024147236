export const actionTypes = {
  GET_DISPUTE_OPTION_START: 'GET_DISPUTE_OPTION_START',
  GET_DISPUTE_OPTION_SUCCESS: 'GET_DISPUTE_OPTION_SUCCESS',
  GET_DISPUTE_OPTION_FINISH: 'GET_DISPUTE_OPTION_FINISH',

  ADD_DISPUTE_OPTION_START: 'ADD_DISPUTE_OPTION_START',
  ADD_DISPUTE_OPTION_SUCCESS: 'ADD_DISPUTE_OPTION_SUCCESS',
  ADD_DISPUTE_OPTION_FINISH: 'ADD_DISPUTE_OPTION_FINISH',
  ADD_DISPUTE_OPTION_RESET: 'ADD_DISPUTE_OPTION_RESET',

  UPDATE_DISPUTE_OPTION_START: 'UPDATE_DISPUTE_OPTION_START',
  UPDATE_DISPUTE_OPTION_SUCCESS: 'UPDATE_DISPUTE_OPTION_SUCCESS',
  UPDATE_DISPUTE_OPTION_FINISH: 'UPDATE_DISPUTE_OPTION_FINISH',
  UPDATE_DISPUTE_OPTION_RESET: 'UPDATE_DISPUTE_OPTION_RESET',

  DELETE_DISPUTE_OPTION_START: 'DELETE_DISPUTE_OPTION_START',
  DELETE_DISPUTE_OPTION_SUCCESS: 'DELETE_DISPUTE_OPTION_SUCCESS',
  DELETE_DISPUTE_OPTION_FINISH: 'DELETE_DISPUTE_OPTION_FINISH',

  SINGLE_ACTIVATE_DISPUTE_OPTION_REQUEST: 'SINGLE_ACTIVATE_DISPUTE_OPTION_REQUEST',
  SINGLE_ACTIVATE_DISPUTE_OPTION_SUCCESS: 'SINGLE_ACTIVATE_DISPUTE_OPTION_SUCCESS',
  SINGLE_ACTIVATE_DISPUTE_OPTION_FINISH: 'SINGLE_ACTIVATE_DISPUTE_OPTION_FINISH',

  SINGLE_DEACTIVATE_DISPUTE_OPTION_REQUEST: 'SINGLE_DEACTIVATE_DISPUTE_OPTION_REQUEST',
  SINGLE_DEACTIVATE_DISPUTE_OPTION_SUCCESS: 'SINGLE_DEACTIVATE_DISPUTE_OPTION_SUCCESS',
  SINGLE_DEACTIVATE_DISPUTE_OPTION_FINISH: 'SINGLE_DEACTIVATE_DISPUTE_OPTION_FINISH',

  SORT_DISPUTE_OPTION_START: 'SORT_DISPUTE_OPTION_START',
  SORT_DISPUTE_OPTION_SUCCESS: 'SORT_DISPUTE_OPTION_SUCCESS',
  SORT_DISPUTE_OPTION_FINISH: 'SORT_DISPUTE_OPTION_FINISH',
  SORT_DISPUTE_OPTION_RESET: 'SORT_DISPUTE_OPTION_RESET',
}
