import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
export const actions = {
  // get Certificate
  getAllCertificate: () => ({
    type: actionTypes.GET_ALL_CERTIFICATE_START,
  }),
  getAllCertificateSuccess: (data: any) => ({
    type: actionTypes.GET_ALL_CERTIFICATE_SUCCESS,
    payload: data,
  }),
  getAllCertificateFinish: () => ({
    type: actionTypes.GET_ALL_CERTIFICATE_FINISH,
  }),

  // get Certificate DATA
  getCertificate: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CERTIFICATE_START,
    payload: params,
  }),
  getCertificateSuccess: (data: any) => ({
    type: actionTypes.GET_CERTIFICATE_SUCCESS,
    payload: data,
  }),
  getCertificateFinish: () => ({
    type: actionTypes.GET_CERTIFICATE_FINISH,
  }),

  //list

  getCertificateList: () => ({type: actionTypes.GET_CERTIFICATE_LIST_START}),

  getCertificateListSuccess: (data: any) => ({
    type: actionTypes.GET_CERTIFICATE_LIST_SUCCESS,
    payload: data,
  }),
  getCertificateListFinish: () => ({
    type: actionTypes.GET_CERTIFICATE_LIST_FINISH,
  }),

  // create key
  addCertificate: (data: any) => ({
    type: actionTypes.ADD_CERTIFICATE_START,
    payload: data,
  }),
  addCertificateSuccess: (task: any) => ({
    type: actionTypes.ADD_CERTIFICATE_SUCCESS,
    payload: task,
  }),
  addCertificateFinish: () => ({
    type: actionTypes.ADD_CERTIFICATE_FINISH,
  }),
  resetCertificate: () => ({
    type: actionTypes.RESET_CERTIFICATE,
  }),

  //Update Certificate
  updateCertificate: (data: any, id: string) => ({
    type: actionTypes.UPDATE_CERTIFICATE_START,
    payload: data,
    id,
  }),

  updateCertificateSuccess: (data: any) => ({
    type: actionTypes.UPDATE_CERTIFICATE_SUCCESS,
    payload: data,
  }),

  updateCertificateFinish: () => ({
    type: actionTypes.UPDATE_CERTIFICATE_FINISH,
  }),

  // delete key
  deleteCertificate: (data: any) => ({
    type: actionTypes.DELETE_CERTIFICATE_START,
    payload: {certificateId: data},
  }),
  deleteCertificateSuccess: (data: any) => ({
    type: actionTypes.DELETE_CERTIFICATE_SUCCESS,
    payload: data,
  }),
  deleteCertificateFinish: () => ({
    type: actionTypes.DELETE_CERTIFICATE_FINISH,
  }),

  //Enable Certificate
  enableCertificate: (data: any) => ({
    type: actionTypes.ENABLE_CERTIFICATE_REQUEST,
    payload: {data},
  }),

  enableCertificateSuccess: (task: any) => ({
    type: actionTypes.ENABLE_CERTIFICATE_SUCCESS,
    payload: task,
  }),
  enableCertificateFinish: () => ({
    type: actionTypes.ENABLE_CERTIFICATE_FINISH,
  }),

  //Disable Certificate
  disableCertificate: (data: any) => ({
    type: actionTypes.DISABLE_CERTIFICATE_REQUEST,
    payload: {data},
  }),

  disableCertificateSuccess: (task: any) => ({
    type: actionTypes.DISABLE_CERTIFICATE_SUCCESS,
    payload: task,
  }),
  disableCertificateFinish: () => ({
    type: actionTypes.DISABLE_CERTIFICATE_FINISH,
  }),

  //Enable Certificate
  singleEnableCertificate: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_CERTIFICATE_REQUEST,
    payload: {data},
  }),

  singleEnableCertificateSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_CERTIFICATE_SUCCESS,
    payload: task,
  }),
  singleEnableCertificateFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_CERTIFICATE_FINISH,
  }),

  //Disable Certificate
  singleDisableCertificate: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_CERTIFICATE_REQUEST,
    payload: {data},
  }),

  singleDisableCertificateSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_CERTIFICATE_SUCCESS,
    payload: task,
  }),
  singleDisableCertificateFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_CERTIFICATE_FINISH,
  }),
  // sort
  sortCertificate: (data: any) => ({
    type: actionTypes.SORT_CERTIFICATE_START,
    payload: data,
  }),
  sortCertificateSuccess: (data: Array<any>) => ({
    type: actionTypes.SORT_CERTIFICATE_SUCCESS,
    payload: data,
  }),
  sortCertificateFinish: () => ({
    type: actionTypes.SORT_CERTIFICATE_FINISH,
  }),
  sortCertificateReset: () => ({
    type: actionTypes.SORT_CERTIFICATE_RESET,
  }),
}
