export const actionTypes = {
  // get APPLY_MEMBERSHIPs
  GET_APPLY_MEMBERSHIP_START: 'GET_APPLY_MEMBERSHIP_START',
  GET_APPLY_MEMBERSHIP_SUCCESS: 'GET_APPLY_MEMBERSHIP_SUCCESS',
  GET_APPLY_MEMBERSHIP_FINISH: 'GET_APPLY_MEMBERSHIP_FINISH',

  //Add APPLY_MEMBERSHIP
  ADD_APPLY_MEMBERSHIP_REQUEST: 'ADD_APPLY_MEMBERSHIP_REQUEST',
  ADD_APPLY_MEMBERSHIP_SUCCESS: 'ADD_APPLY_MEMBERSHIP_SUCCESS',
  ADD_APPLY_MEMBERSHIP_FINISH: 'ADD_APPLY_MEMBERSHIP_FINISH',
  ADD_APPLY_MEMBERSHIP_RESET: 'ADD_APPLY_MEMBERSHIP_RESET',

  // update APPLY_MEMBERSHIP
  UPDATE_APPLY_MEMBERSHIP_START: 'UPDATE_APPLY_MEMBERSHIP_START',
  UPDATE_APPLY_MEMBERSHIP_SUCCESS: 'UPDATE_APPLY_MEMBERSHIP_SUCCESS',
  UPDATE_APPLY_MEMBERSHIP_FINISH: 'UPDATE_APPLY_MEMBERSHIP_FINISH',
  UPDATE_APPLY_MEMBERSHIP_RESET: 'UPDATE_APPLY_MEMBERSHIP_RESET',

  //Bulk Activate APPLY_MEMBERSHIP
  ACTIVATE_APPLY_MEMBERSHIP_REQUEST: 'ACTIVATE_APPLY_MEMBERSHIP_REQUEST',
  ACTIVATE_APPLY_MEMBERSHIP_SUCCESS: 'ACTIVATE_APPLY_MEMBERSHIP_SUCCESS',
  ACTIVATE_APPLY_MEMBERSHIP_FINISH: 'ACTIVATE_APPLY_MEMBERSHIP_FINISH',

  //Bulk Deactivate APPLY_MEMBERSHIP
  DEACTIVATE_APPLY_MEMBERSHIP_REQUEST: 'DEACTIVATE_APPLY_MEMBERSHIP_REQUEST',
  DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS: 'DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS',
  DEACTIVATE_APPLY_MEMBERSHIP_FINISH: 'DEACTIVATE_APPLY_MEMBERSHIP_FINISH',

  //Single Activate APPLY_MEMBERSHIP
  SINGLE_ACTIVATE_APPLY_MEMBERSHIP_REQUEST: 'SINGLE_ACTIVATE_APPLY_MEMBERSHIP_REQUEST',
  SINGLE_ACTIVATE_APPLY_MEMBERSHIP_SUCCESS: 'SINGLE_ACTIVATE_APPLY_MEMBERSHIP_SUCCESS',
  SINGLE_ACTIVATE_APPLY_MEMBERSHIP_FINISH: 'SINGLE_ACTIVATE_APPLY_MEMBERSHIP_FINISH',

  //Single Deactivate APPLY_MEMBERSHIP
  SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_REQUEST: 'SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_REQUEST',
  SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS: 'SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS',
  SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_FINISH: 'SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_FINISH',

  // delete APPLY_MEMBERSHIP
  DELETE_APPLY_MEMBERSHIP_START: 'DELETE_APPLY_MEMBERSHIP_START',
  DELETE_APPLY_MEMBERSHIP_SUCCESS: 'DELETE_APPLY_MEMBERSHIP_SUCCESS',
  DELETE_APPLY_MEMBERSHIP_FINISH: 'DELETE_APPLY_MEMBERSHIP_FINISH',
}
