import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'

//Manual import
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as exchangeRateRedux from '../index'

import moment from 'moment'
import {useEffect} from 'react'
import * as Yup from 'yup'

const FORM_VALIDATION = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  rateType: Yup.string().required('Rate Type is required'),
})

const ImportExchangeRate = ({handleImportModalClose}: {handleImportModalClose: any}) => {
  const dispatch = useDispatch()
  const {loading, importSuccess} = useSelector((state: any) => state.exchangeRate)

  useEffect(() => {
    if (importSuccess) {
      handleImportModalClose()
    }
  }, [importSuccess])

  return (
    <Formik
      initialValues={{
        date: '',
        rateType: '',
        file: '',
      }}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values: any) => {
        if (!isEmpty(values)) {
          let formData = new FormData()
          formData.append('file', values.file)
          formData.append('date', moment(values.date).format('YYYY-MM-DD'))
          formData.append('rateType', values.rateType)
          dispatch(exchangeRateRedux.actions.importExchangeRate(formData))
        }
      }}
    >
      {({
        isSubmitting,
        touched,
        handleChange,
        handleSubmit,
        errors,
        handleBlur,
        setFieldValue,
        values,
      }) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                <FormDatepicker
                  labelClassName='col-12'
                  containerClassName='col-12 '
                  dateFormat={'yyyy-MM-dd'}
                  label='Published date'
                  name='date'
                  placeholderText='Please select a Published date'
                  setFieldValue={setFieldValue}
                  value={values.date || false}
                  errors={errors}
                  touched={touched}
                  required={true}
                  showIcon={true}
                />
              </div>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                <FormSelect
                  labelClassName='col-md-12'
                  containerClassName='col-md-12'
                  placeholder='Rate Type'
                  label='Rate Type'
                  name='rateType'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={[
                    {label: '1st Rate', value: 'Early'},
                    {label: '2nd Rate', value: 'After On'},
                  ]}
                  required
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className=''>
                <input
                  className=''
                  name='file'
                  id='my-file'
                  type='file'
                  onChange={(event: any) => {
                    if (event) {
                      setFieldValue('file', event.currentTarget.files[0])
                    }
                  }}
                />
              </div>
            </div>

            <div className='d-flex justify-content-end px-5 '>
              <button
                type='submit'
                disabled={loading}
                className='btn btn-primary btn-sm ms-3'
                data-cy='modal-submit'
              >
                Save
              </button>
              <button
                type='button'
                onClick={handleImportModalClose}
                className='btn btn-secondary btn-sm ms-3'
                data-cy='modal-cancel'
              >
                Cancel
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ImportExchangeRate
