import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

// icons

// includes
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import IconButton from 'rsuite/esm/IconButton'
import Pagination from 'rsuite/Pagination'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'

import * as categoryType from 'src/app/modules/common'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as apiManager from '../index'

import {isEmpty} from 'lodash'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import AddApiList from './AddAPIList'
import {IApiManagerState} from '../redux'

const ApiManager = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [selectedData, setSelectedData] = useState<Array<string>>([])
  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const apiManagerData: IApiManagerState = useSelector((state: any) => state.apiManager)

  const {
    // activateSuccess,
    // deactivateSuccess,
    // singleActivateSuccess,
    // singleDeactivateSuccess,
    loading,
  } = apiManagerData

  // const { data: categoryTypeData } = useSelector((state: any) => state.categoryType)

  useEffect(() => {
    dispatch(apiManager.actions.getApiManagerDetail(params))
  }, [params])

  // useEffect(() => {
  //   dispatch(categoryType.action.getCategoryType())
  // }, [])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const handleToggleAction = (data: {[key: string]: string}) => {
    // const formData = { id: data?.id }
    // data?.status
    //   ? dispatch(news.actions.singleDeactivateNews(formData))
    //   : dispatch(news.actions.singleActivateNews(formData))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group' data-cy='apiManager-link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy='apiManager-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              icon={<TrashIcon />}
              data-cy='apiManagerte-button'
            />
          </Whisper>
        </CheckPermissions>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='apiManagerle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'API Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'API',
      dataKey: 'api',
      flexGrow: 1,
      cell: <Cell dataKey='api' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const columnNext = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'API Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'API',
      dataKey: 'api',
      flexGrow: 1,
      cell: <Cell dataKey='api' />,
      sortable: true,
    },
    {
      label: 'Encryption',
      dataKey: 'encryption',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.encryption ? (
              <Tag color='green' size='sm'>
                True
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                False
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  // useEffect(() => {
  //   if (newsData?.deleteSuccess) {
  //     toast.success('News deleted successfully')
  //     dispatch(news?.actions.getNews(params))
  //   }
  // }, [newsData])

  // active/deactive section

  // useEffect(() => {
  //   if (singleActivateSuccess) {
  //     toast.success('News activated successfully')
  //   }
  //   if (singleDeactivateSuccess) {
  //     toast.success('News deactivated successfully')
  //   }
  //   handleChecked([])
  // }, [singleActivateSuccess, singleDeactivateSuccess])

  // useEffect(() => {
  //   if (activateSuccess) {
  //     toast.success('News activated successfully')
  //   }
  //   if (deactivateSuccess) {
  //     toast.success('News deactivated successfully')
  //   }

  //   handleChecked([])
  //   dispatch(news?.actions.getNews(params))
  // }, [activateSuccess, deactivateSuccess])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const activateSelectedData = () => {
    // if (!isEmpty(checkedValues)) {
    //   const formData: any = checkedValues.map((d: string) => ({
    //     id: d,
    //   }))
    //   dispatch(news.actions.activateNews(formData))
    // } else {
    //   toast.error('No data Selected')
    // }
  }

  const deactivateSelectedData = () => {
    // if (!isEmpty(checkedValues)) {
    //   const formData: any = checkedValues.map((d: string) => ({
    //     id: d,
    //   }))
    //   dispatch(news.actions.deactivateNews(formData))
    // } else {
    //   toast.error('No data Selected')
    // }
  }

  const handleChecked = (values: any) => {
    setCheckedValues(values)
  }

  const data = apiManagerData?.data?.apiManager
    ? apiManagerData?.data?.apiManager?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        name: item?.name,
        api: item?.api,
        status: item?.status,
        encryption: item?.encryption,
      }))
    : []

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value) => ({id: value}))
    // dispatch(news?.actions?.deleteNews(id))
    handleAlertClose()
  }

  const handleRefresh = () => {
    // dispatch(news?.actions.getNews(params))
  }
  const multiLanguage = checkMultiLanguage()
  return (
    <>
      <div className='shadow p-3 bg-white rounded' data-cy='apiManager-shadow-comp'>
        <DesignComponent
          moduleName='API Manager'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          data-cy='apiManager-design-comp'
        />

        <div className='datatable' data-cy='apiManager-data-table'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={multiLanguage ? columnNext : columns}
            data={data}
            showLoading={loading}
            checkedValues={checkedValues}
            handleSort={handleSort}
            data-cy='apiManager-r-suite'
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={apiManagerData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='apiManager-pagination'
          />
          {open && (
            <AddApiList
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => modalSubmit()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default ApiManager
