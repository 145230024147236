import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import {StateParamsModel} from 'src/app/modules/common/Model'
import * as memberTypeRedux from '../../memberType/index'
import * as memberSubType from '../index'

import Modal from 'rsuite/Modal'

import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  status: Yup.string().required('Status is required'),
  description: Yup.string().required('Description is required'),
  memberTypeId: Yup.string().required('Merchant Type is required'),
})

const AddMemberSubType = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.memberSubType)
  const {
    data: {memberType},
  } = useSelector((state: any) => state.memberType)

  useEffect(() => {
    dispatch(memberTypeRedux.actions.getAllMemberType())
  }, [])

  const memberTypeOptions = memberType?.map((item: {[key: string]: string}) => ({
    label: item.name,
    value: item.id,
  }))

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    if (success) {
      dispatch(memberSubType?.actions.getMemberSubType(params))
      isEmpty(editSelectedData)
        ? toast.success('Member sub type added successfully')
        : toast.success('Member sub type edited successfully')
      dispatch(memberSubType?.actions?.resetMemberSubType())
      handleClose()
    }
  }, [success])
  const isSingleLanguage = checkMultiLanguage()
  return (
    <div className='modal-container' data-cy='modal-sub-member-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Member Sub Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                slug: '',
                description: '',
                memberTypeId: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.name.replace(/\s/g, '-').toLowerCase(),
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    memberSubType.actions.updateMemberSubType(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(memberSubType.actions.addMemberSubType(formData))
                }
              }}
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'description',
                      'description_np',
                      'slug',
                      'id',
                      'name',
                      'name_np',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('memberTypeId', editSelectedData?.memberType?.id, false)
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='card-sub-member-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Member Type'
                            name='memberTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={memberTypeOptions}
                            required
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='add-sub-member-type-id-drop-down-field'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label={isSingleLanguage ? 'Name ' : 'Name (EN)'}
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='add-sub-member-name-text-field'
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Name (NP)'
                              label='Name (NP)'
                              name='name_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              data-cy='add-sub-member-name-np-text-field'
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12' data-cy='sub-member-row-text-editor'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label={isSingleLanguage ? 'Description ' : 'Description (EN)'}
                            name='description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                            required
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              label='Description (NP)'
                              name='description_np'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.description_np : ''
                              }
                              handleChange={handleChange}
                              data-cy='sub-member-editor-message'
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : values?.name.replace(/\s/g, '-').toLowerCase()
                            }
                            data-cy='sub-member-slug-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='sub-member-status-radio-button'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddMemberSubType
