import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getSubscribeTypeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSubscribeType, params)
    yield put(actions.getSubscribeTypeSuccess(response?.data?.data))
    yield put(actions.getSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSubscribeTypeFinish())
  }
}

function* getAllSubscribeTypeSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllSubscribeType, params)
    yield put(actions.getAllSubscribeTypeSuccess(response?.data?.data))
    yield put(actions.getAllSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllSubscribeTypeFinish())
  }
}

function* addSubscribeTypeSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addSubscribeType, body)
    yield put(actions.addSubscribeTypeSuccess(response.data?.data))
    yield put(actions.addSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addSubscribeTypeFinish())
  }
}

function* enableSubscribeTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableSubscribeType, selectedUsers)
    yield put(actions.enableSubscribeTypeSuccess(response?.data))
    yield put(actions.enableSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableSubscribeTypeFinish())
  }
}

function* disableSubscribeTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableSubscribeType, selectedUsers)
    yield put(actions.disableSubscribeTypeSuccess(response?.data))
    yield put(actions.disableSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableSubscribeTypeFinish())
  }
}

function* singleEnableSubscribeTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableSubscribeType, selectedUsers)
    yield put(actions.singleEnableSubscribeTypeSuccess(response?.data))
    yield put(actions.singleEnableSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableSubscribeTypeFinish())
  }
}

function* singleDisableSubscribeTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableSubscribeType, selectedUsers)
    yield put(actions.singleDisableSubscribeTypeSuccess(response?.data))
    yield put(actions.singleDisableSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableSubscribeTypeFinish())
  }
}

function* updateSubscribeTypeSaga(action: ActionModel) {
  try {
    const body: ServiceTypeModel = action.payload
    const response: ResponseModel = yield call(service.updateSubscribeType, body, action.payload?.id)
    yield put(actions.updateSubscribeTypeSuccess(response.data?.data))
    yield put(actions.updateSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateSubscribeTypeFinish())
  }
}

function* deleteSubscribeTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteSubscribeType, body)
    yield put(actions.deleteSubscribeTypeSuccess(response.data?.data))
    yield put(actions.deleteSubscribeTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteSubscribeTypeFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_SUBSCRIBE_TYPE_START, getSubscribeTypeSaga)
  yield takeLatest(actionTypes.GET_ALL_SUBSCRIBE_TYPE_START, getAllSubscribeTypeSaga)
  yield takeLatest(actionTypes.ADD_SUBSCRIBE_TYPE_START, addSubscribeTypeSaga)
  yield takeLatest(actionTypes.UPDATE_SUBSCRIBE_TYPE_START, updateSubscribeTypeSaga)
  yield takeLatest(actionTypes.DELETE_SUBSCRIBE_TYPE_START, deleteSubscribeTypeSaga)
  yield takeLatest(actionTypes.ENABLE_SUBSCRIBE_TYPE_REQUEST, enableSubscribeTypeSaga)
  yield takeLatest(actionTypes.DISABLE_SUBSCRIBE_TYPE_REQUEST, disableSubscribeTypeSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_SUBSCRIBE_TYPE_REQUEST, singleEnableSubscribeTypeSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_SUBSCRIBE_TYPE_REQUEST, singleDisableSubscribeTypeSaga)
}
