import {LoadingOutlined} from '@ant-design/icons'
import {Spin} from 'antd'
import axios from 'axios'
import {useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {Modal} from 'rsuite'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkProvince from 'src/app/modules/common/checkProvince'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  data: {
    id: String | number
    name: String
    uniqueCode: String
    dobAd: String
    dobBs: String
    age: String
    gender: String
    vacancyName: String
    grandfatherName: String
    fatherName: String
    motherName: String
    placeOfBirth: String
    bloodGroup: String
    citizenshipNumber: String
    districtId: String
    citizenshipIssueDateAd: String
    citizenshipIssueDateBs: String
    isLicenseHolder: Boolean
    selectedBranch: String
    maritalStatus: String
    email: String
    mobileNumber: String
    telephone: String
    sameAsPermanent: true
    workedHere: true
    vacancyApplicantStatusId: String
    status: true
    bankingExperienceInMonth: String
    bankingExperienceInYear: String
    vacancyApplicantPermanentAddress: {
      id: String
      vacancyApplicantId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
      tole: String
    }
    vacancyApplicantTemporaryAddress: {
      id: String
      vacancyApplicantId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
      tole: String
    }
    branch: {
      title: String
    }
    vacancyApplicantEducation: {
      vacancyApplicantEducation: [
        {
          id: String
          vacancyApplicantId: String
          nameAddressInstituition: String
          university: String
          qualificationId: String
          areaSpecialization: String
          passingYear: String
          percentage: String
          cgpa: String
          marksheet: String
        }
      ]
    }
    vacancyApplicantTraining: {
      vacancyApplicantTraining: [
        {
          id: String
          vacancyApplicantId: String
          name: String
          instituteName: String
          duration: String
          completionYear: String
        }
      ]
    }
    vacancyApplicantProfessionalReference: {
      vacancyApplicantProfessionalReference: [
        {
          id: String
          vacancyApplicantId: String
          name: String
          address: String
          email: String
          phoneNumber: String
          organizationName: String
        }
      ]
    }
    vacancyApplicantDocument: {
      id: String
      vacancyApplicantId: String
      latestPhoto: String
      resume: String
      latestDegree: String
      application: String
      frontCitizenship: String
      backCitizenship: String
    }
    vacancyApplicantCurrentExperience: {
      vacancyApplicantCurrentExperience: [
        {
          id: String
          vacancyApplicantId: String
          organizationName: String
          designation: String
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          lastPromotionDate: String
          document: String
          expectedSalary: String
        }
      ]
    }
    vacancyApplicantRecentExperience: {
      vacancyApplicantRecentExperience: [
        {
          id: String
          vacancyApplicantId: String
          organizationName: String
          designation: String
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          totalMonth: String
          reasonForLeaving: String
        }
      ]
    }
    vacancyApplicantWorkedHereDetail: {
      id: String
      vacancyApplicantId: String
      branchId: String
      departmentId: String
      position: String
      fromDate: String
      toDate: String
      status: String
    }
  }
  handleClose: () => void
  provinceList: Array<any>
  districtList: Array<any>
  zoneList: Array<any>
  municipalityList: Array<any>
  functionalAreaList: Array<any>
  branchList: Array<any>
  departmentList: Array<any>
}

const ViewCandidateDetails = ({
  data,
  handleClose,
  branchList,
  departmentList,
  provinceList,
  districtList,
  zoneList,
  municipalityList,
  functionalAreaList,
}: any) => {
  const [loading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const ftpStorage = checkBackendSetting('ftpStorage')
  const hasProvince = checkProvince()
  const FTP_API = '/api/apply-vacancy/ftp-file-download?'
  const handleOpenFile = async (url: any, fileName: string) => {
    setIsLoading(true)
    setFileName(fileName)
    const fileExtension = url.split('.').pop()
    await axios
      .get(`${imageBaseUrl}${FTP_API}ftpFilePath=${url}`, {
        method: 'GET',
        responseType: 'blob',
      })
      .then((response: any) => {
        setIsLoading(false)
        setFileName('')
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.${fileExtension}`)
        document.body.appendChild(link)
        link.click()
        toast.success('Download successfully')
      })
      .catch((exception) => {
        setIsLoading(false)
        setFileName('')
        toast.error('Internal error occurred')
        console.log('exception', exception)
      })
  }
  return (
    <div className='modal-container' data-cy='view-vacancy-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Candidate Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className='d-flex'>
              <h5> Vacancy Name: </h5>
              <span className='mb-2 mx-2'> {data?.vacancyName} </span>
            </div>
            {hasProvince && (
              <div className='d-flex'>
                <h5> Provinces: </h5>
                <span className='mb-2 mx-2'> {data?.provinceId?.name} </span>
              </div>
            )}
            {data?.position && (
              <div className='d-flex'>
                <h5> Position: </h5>
                <span className='mb-2 mx-2'> {data?.position?.name} </span>
              </div>
            )}
            {data?.department && (
              <div className='d-flex'>
                <h5> Department: </h5>
                <span className='mb-2 mx-2'> {data?.department?.name} </span>
              </div>
            )}
            <div className='d-flex'>
              <h5> Applied Branch: </h5>
              <span className='mb-2 mx-2'>
                {hasProvince
                  ? data?.vacancyApplicantPreferBranch?.vacancyApplicantPreferBranch
                      .map((item: any) => item.branchName)
                      .toString()
                      .replaceAll(',', ', ')
                  : data?.branch?.title}{' '}
              </span>
            </div>
            <h5>Personal Details</h5>
            <Row>
              <Col md={3}>Name : {data?.name}</Col>
              <Col md={3}>Unique Code : {data?.uniqueCode}</Col>
              <Col md={3}>Date Of Birth (B.S): {data?.dobBs}</Col>
              <Col md={3}>Date Of Birth (A.D): {data?.dobAd}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Age : {data?.age}</Col>
              <Col md={3}>Gender : {data?.gender}</Col>
              <Col md={3}>Father's Name: {data?.fatherName}</Col>
              <Col md={3}>Grandfather's Name: {data?.grandfatherName}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Mother's Name : {data?.motherName}</Col>
              <Col md={3}>Marital Status : {data?.maritalStatus}</Col>
              {data?.spouseName && <Col md={3}>Spouse Name : {data?.spouseName}</Col>}
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Place Of Birth : {data?.placeOfBirth}</Col>
              <Col md={3}>Blood Group : {data?.bloodGroup}</Col>
              <Col md={3}>Citizenship Number: {data?.citizenshipNumber}</Col>
              <Col md={3}>Citizenship Issued Date (B.S): {data?.citizenshipIssueDateBs}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Citizenship Issued Date (A.D): {data?.citizenshipIssueDateAd}</Col>
              <Col md={3}>Email : {data?.email}</Col>
              <Col md={3}>Mobile Number: {data?.mobileNumber}</Col>
              <Col md={3}>Home Telephone: {data?.telephone || 'N/A'}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.vacancyApplicantPermanentAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Zone:{' '}
                {zoneList.filter(
                  (d: any) => d.id === data?.vacancyApplicantPermanentAddress?.zoneId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.vacancyApplicantPermanentAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.vacancyApplicantPermanentAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Ward Number: {data?.vacancyApplicantPermanentAddress?.wardNumber}
              </Col>
              <Col md={3}>Permanent Tole: {data?.vacancyApplicantPermanentAddress?.tole}</Col>
              <Col md={3}>
                Present Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.vacancyApplicantTemporaryAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Zone:{' '}
                {zoneList.filter(
                  (d: any) => d.id === data?.vacancyApplicantTemporaryAddress?.zoneId
                )[0]?.title || 'N/A'}{' '}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Present District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.vacancyApplicantTemporaryAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.vacancyApplicantTemporaryAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>Present Tole: {data?.vacancyApplicantTemporaryAddress?.tole}</Col>
            </Row>

            <Row>
              {data.bankingExperienceInMonth && (
                <Col md={3}>
                  Total Banking Experience In Months : {data.bankingExperienceInMonth}
                </Col>
              )}
              {data.bankingExperienceInYear && (
                <Col md={3}>Total Banking Experience In Years : {data.bankingExperienceInYear}</Col>
              )}
              {data?.isLicenseHolder && (
                <Col md={3}>Has License: {data?.isLicenseHolder ? 'Yes' : 'No'}</Col>
              )}
              {data?.isLicenseHolder && <Col md={3}>License No: {data?.licenseNo}</Col>}
              {data.selectedBranch && (
                <Col md={3}>Preferred Exam Center {data?.selectedBranch}</Col>
              )}
            </Row>

            <h5 className='mt-5'>Education Details</h5>
            {data?.vacancyApplicantEducation?.vacancyApplicantEducation?.map(
              (education: any, index: number) => {
                return (
                  <div key={index}>
                    <h5 className='mt-2'>{education?.qualificationName}</h5>
                    <Row className='mt-5'>
                      <Col md={3}>University/College: {education?.university}</Col>
                      <Col md={3}>Institution Address : {education?.nameAddressInstituition}</Col>
                      <Col md={3}>Area Of Specialization: {education?.areaSpecialization}</Col>
                      <Col md={3}>Passing Year: {education?.passingYear}</Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col md={3}>CGPA: {education?.cgpa}</Col>
                      <Col md={3}>Percentage : {education?.percentage}</Col>
                      {/* <Col md={3}>
                        Marksheet:{' '}
                        <a target='_blank' href={imageBaseUrl + '/' + education.marksheet}>
                          View
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                )
              }
            )}
            {data?.vacancyApplicantTraining?.vacancyApplicantTraining[0]?.name && (
              <>
                <h5 className='mt-5'>Training Details</h5>
                {data?.vacancyApplicantTraining?.vacancyApplicantTraining?.map(
                  (training: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Training {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Training Name: {training?.name}</Col>
                          <Col md={3}>Institute Name : {training?.instituteName || 'N/A'}</Col>
                          <Col md={3}>Start Date: {training?.startDate || 'N/A'}</Col>
                          <Col md={3}>End Date: {training?.endDate || 'N/A'}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </>
            )}

            {data?.vacancyApplicantCurrentExperience?.vacancyApplicantCurrentExperience[0]
              ?.organizationName && (
              <>
                <h5 className='mt-5'>Current Experience Details</h5>
                {data?.vacancyApplicantCurrentExperience?.vacancyApplicantCurrentExperience?.map(
                  (currentExperience: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Current Experience {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Organization Name: {currentExperience?.organizationName}</Col>
                          <Col md={3}>Designation: {currentExperience?.designation}</Col>
                          <Col md={3}>
                            Functional Area:{' '}
                            {functionalAreaList.filter(
                              (d: any) => d.id === currentExperience.functionalAreaId
                            )[0]?.name || 'N/A'}
                          </Col>
                          <Col md={3}>Salary Per Month: {currentExperience?.salaryPerMonth}</Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>From Date: {currentExperience?.fromDate}</Col>
                          <Col md={3}>To Date: {currentExperience?.toDate}</Col>
                          <Col md={3}>
                            Job Responsibilities: {currentExperience?.jobResponsibilities}
                          </Col>
                          <Col md={3}>
                            Last Promotion Date: {currentExperience?.lastPromotionDate}
                          </Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>
                            Document:{' '}
                            <a
                              target='_blank'
                              href={imageBaseUrl + '/' + currentExperience?.document}
                            >
                              View
                            </a>
                          </Col>
                          <Col md={3}>Expected Salary: {currentExperience?.expectedSalary}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </>
            )}

            <h5 className='mt-5'>Recent Experience Details</h5>
            {data?.vacancyApplicantRecentExperience?.vacancyApplicantRecentExperience?.map(
              (recentExperience: any, index: number) => {
                return (
                  <div key={index}>
                    <h5 className='mt-2'>Recent Experience {index + 1}</h5>
                    <Row className='mt-5'>
                      <Col md={3}>Organization Name: {recentExperience?.organizationName}</Col>
                      <Col md={3}>Designation: {recentExperience?.designation}</Col>
                      <Col md={3}>
                        Functional Area:{' '}
                        {functionalAreaList.filter(
                          (d: any) => d.id === recentExperience.functionalAreaId
                        )[0]?.name || 'N/A'}
                      </Col>
                      <Col md={3}>Salary Per Month: {recentExperience?.salaryPerMonth}</Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col md={3}>From Date: {recentExperience?.fromDate}</Col>
                      <Col md={3}>To Date: {recentExperience?.toDate}</Col>
                      <Col md={3}>
                        Job Responsibilities: {recentExperience?.jobResponsibilities}
                      </Col>
                      <Col md={3}>Total Month: {recentExperience?.totalMonth}</Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col md={3}>Reason for leaving: {recentExperience?.reasonForLeaving}</Col>
                    </Row>
                  </div>
                )
              }
            )}

            {data?.vacancyApplicantWorkedHereDetail?.position && (
              <>
                <h5 className='mt-5'>Worked Here Details</h5>
                <Row className='mt-5'>
                  <Col md={3}>
                    Branch Name:{' '}
                    {branchList.filter(
                      (d: any) => d.id === data?.vacancyApplicantWorkedHereDetail?.branchId
                    )[0]?.title || 'N/A'}
                  </Col>
                  <Col md={3}>
                    Department:{' '}
                    {departmentList.filter(
                      (d: any) => d.id === data.vacancyApplicantWorkedHereDetail?.departmentId
                    )[0]?.name || 'N/A'}
                  </Col>
                  <Col md={3}>Position: {data?.vacancyApplicantWorkedHereDetail?.position}</Col>
                  <Col md={3}>From Date: {data?.vacancyApplicantWorkedHereDetail?.fromDate}</Col>
                </Row>
                <Row className='mt-5'>
                  <Col md={3}>To Date: {data?.vacancyApplicantWorkedHereDetail?.toDate}</Col>
                  <Col md={3}>Status: {data?.vacancyApplicantWorkedHereDetail?.status}</Col>
                </Row>
              </>
            )}
            {data?.vacancyApplicantProfessionalReference?.vacancyApplicantProfessionalReference[0]
              ?.name && (
              <>
                <h5 className='mt-5'>Reference Details</h5>
                {data?.vacancyApplicantProfessionalReference?.vacancyApplicantProfessionalReference?.map(
                  (reference: any, index: number) => {
                    return (
                      <div key={index}>
                        <h5 className='mt-2'>Reference {index + 1}</h5>
                        <Row className='mt-5'>
                          <Col md={3}>Name: {reference?.name}</Col>
                          <Col md={3}>Address: {reference?.address}</Col>
                          <Col md={3}>Email: {reference?.email}</Col>
                          <Col md={3}>Phone Number: {reference?.phoneNumber}</Col>
                        </Row>
                        <Row className='mt-5'>
                          <Col md={3}>Organization Name: {reference?.organizationName}</Col>
                        </Row>
                      </div>
                    )
                  }
                )}
              </>
            )}

            <h5 className='mt-5'>Document Details</h5>
            <Row className='mt-5'>
              <Col md={3}>
                Latest Photo:
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(`${data?.vacancyApplicantDocument?.latestPhoto}`, 'photo')
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'photo' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.vacancyApplicantDocument?.latestPhoto}
                  >
                    View
                  </a>
                )}
              </Col>
              <Col md={3}>
                Resume:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(`${data?.vacancyApplicantDocument?.resume}`, 'resume')
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'resume' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.vacancyApplicantDocument?.resume}
                  >
                    View
                  </a>
                )}
              </Col>
              <Col md={3}>
                Citizenship:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(
                        `${data?.vacancyApplicantDocument?.frontCitizenship}`,
                        'citizenship'
                      )
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'citizenship' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.vacancyApplicantDocument?.frontCitizenship}
                  >
                    View
                  </a>
                )}
              </Col>

              <Col md={3} className=''>
                Application:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(
                        `${data?.vacancyApplicantDocument?.application}`,
                        'application'
                      )
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'application' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.vacancyApplicantDocument?.application}
                  >
                    View
                  </a>
                )}
              </Col>
              <Col md={3} className='mt-4'>
                Latest Degree:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(
                        `${data?.vacancyApplicantDocument?.latestDegree}`,
                        'latestDegree'
                      )
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'latestDegree' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.vacancyApplicantDocument?.latestDegree}
                  >
                    View
                  </a>
                )}
              </Col>
              <Col md={3} className='mt-4'>
                License:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() => handleOpenFile(`${data?.licenseImage}`, 'licenseImage')}
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'licenseImage' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  data?.licenseImage?.includes('.') && (
                    <a target='_blank' href={imageBaseUrl + '/' + data?.licenseImage}>
                      View
                    </a>
                  )
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewCandidateDetails
