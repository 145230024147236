import {ParamsModel} from 'src/app/modules/common/Model'
import {ProgramCategoryModel, SortProgramCategoryModel} from '../Model/ProgramCategoryModel'
import {actionTypes} from './constants'
export const actions = {
  // get ProgramCategory
  getAllProgramCategory: () => ({
    type: actionTypes.GET_ALL_PROGRAM_CATEGORY_START,
  }),
  getAllProgramCategorySuccess: (data: ProgramCategoryModel | any) => ({
    type: actionTypes.GET_ALL_PROGRAM_CATEGORY_SUCCESS,
    payload: data,
  }),
  getAllProgramCategoryFinish: () => ({
    type: actionTypes.GET_ALL_PROGRAM_CATEGORY_FINISH,
  }),

  // get ProgramCategory DATA
  getProgramCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_PROGRAM_CATEGORY_START,
    payload: params,
  }),
  getProgramCategorySuccess: (data: ProgramCategoryModel | any) => ({
    type: actionTypes.GET_PROGRAM_CATEGORY_SUCCESS,
    payload: data,
  }),
  getProgramCategoryFinish: () => ({
    type: actionTypes.GET_PROGRAM_CATEGORY_FINISH,
  }),

  // create key
  addProgramCategory: (data: ProgramCategoryModel | any) => ({
    type: actionTypes.ADD_PROGRAM_CATEGORY_START,
    payload: data,
  }),
  addProgramCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_PROGRAM_CATEGORY_SUCCESS,
    payload: task,
  }),
  addProgramCategoryFinish: () => ({
    type: actionTypes.ADD_PROGRAM_CATEGORY_FINISH,
  }),
  resetProgramCategory: () => ({
    type: actionTypes.RESET_PROGRAM_TAG,
  }),

  //Update ProgramCategory
  updateProgramCategory: (data: ProgramCategoryModel | any, id: string) => ({
    type: actionTypes.UPDATE_PROGRAM_CATEGORY_START,
    payload: data,
    id,
  }),

  updateProgramCategorySuccess: (data: ProgramCategoryModel | any) => ({
    type: actionTypes.UPDATE_PROGRAM_CATEGORY_SUCCESS,
    payload: data,
  }),

  updateProgramCategoryFinish: () => ({
    type: actionTypes.UPDATE_PROGRAM_CATEGORY_FINISH,
  }),

  // delete key
  deleteProgramCategory: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_PROGRAM_CATEGORY_START,
    payload: {programCategoryId: data},
  }),
  deleteProgramCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_PROGRAM_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteProgramCategoryFinish: () => ({
    type: actionTypes.DELETE_PROGRAM_CATEGORY_FINISH,
  }),

  //Enable ProgramCategory
  enableProgramCategory: (data: any) => ({
    type: actionTypes.ENABLE_PROGRAM_CATEGORY_REQUEST,
    payload: {data},
  }),

  enableProgramCategorySuccess: (task: any) => ({
    type: actionTypes.ENABLE_PROGRAM_CATEGORY_SUCCESS,
    payload: task,
  }),
  enableProgramCategoryFinish: () => ({
    type: actionTypes.ENABLE_PROGRAM_CATEGORY_FINISH,
  }),

  //Disable ProgramCategory
  disableProgramCategory: (data: any) => ({
    type: actionTypes.DISABLE_PROGRAM_CATEGORY_REQUEST,
    payload: {data},
  }),

  disableProgramCategorySuccess: (task: any) => ({
    type: actionTypes.DISABLE_PROGRAM_CATEGORY_SUCCESS,
    payload: task,
  }),
  disableProgramCategoryFinish: () => ({
    type: actionTypes.DISABLE_PROGRAM_CATEGORY_FINISH,
  }),

  //Enable ProgramCategory
  singleEnableProgramCategory: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_PROGRAM_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleEnableProgramCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_PROGRAM_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleEnableProgramCategoryFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_PROGRAM_CATEGORY_FINISH,
  }),

  //Disable ProgramCategory
  singleDisableProgramCategory: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_PROGRAM_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleDisableProgramCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_PROGRAM_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleDisableProgramCategoryFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_PROGRAM_CATEGORY_FINISH,
  }),

  // sort
  sortProgramCategory: (data: SortProgramCategoryModel) => ({
    type: actionTypes.SORT_PROGRAM_CATEGORY_START,
    payload: data,
  }),
  sortProgramCategorySuccess: (data: Array<ProgramCategoryModel>) => ({
    type: actionTypes.SORT_PROGRAM_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortProgramCategoryFinish: () => ({
    type: actionTypes.SORT_PROGRAM_CATEGORY_FINISH,
  }),
  sortProgramCategoryReset: () => ({
    type: actionTypes.SORT_PROGRAM_CATEGORY_RESET,
  }),
}
