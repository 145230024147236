import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteBankDetailModel, BankDetailModel, SortBankDetailModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_BANK_DETAIL = `${API_URL}/about-bank-detail`
// export const ADD_BANK_DETAIL = `${API_URL}/about-bank-detail`
// export const ACTIVATE_BANK_DETAILS = `${API_URL}/about-bank-detail/enable`
// export const DEACTIVATE_BANK_DETAILS = `${API_URL}/about-bank-detail/disable`
// export const DELETE_BANK_DETAIL = `${API_URL}/about-bank-detail/bulk-delete`

export const service = {
  getAboutBankDetail: (params: ParamsModel) => {
    return axios.get(GET_BANK_DETAIL, {params})
  },

  addBankDetail: (data: any) => {
    return axios.post(GET_BANK_DETAIL, data)
  },

  updateBankDetail: (body: BankDetailModel, id: string) => {
    return axios.put(`${GET_BANK_DETAIL}/${id}`, body)
  },

  deleteBankDetail: (data: DeleteBankDetailModel) => {
    return axios.delete(`${GET_BANK_DETAIL}/bulk-delete`, {data})
  },

  enableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankDetailId: data,
    }
    return axios.patch(`${GET_BANK_DETAIL}/enable`, selectedBankDetail)
  },

  disableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankDetailId: data,
    }
    return axios.patch(`${GET_BANK_DETAIL}/disable`, selectedBankDetail)
  },

  singleEnableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankDetailId: [data],
    }
    return axios.patch(`${GET_BANK_DETAIL}/enable`, selectedBankDetail)
  },

  singleDisableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankDetailId: [data],
    }
    return axios.patch(`${GET_BANK_DETAIL}/disable`, selectedBankDetail)
  },

  getAllBankDetail: () => {
    return axios.get(`${GET_BANK_DETAIL}/list`)
  },
  sortBankDetail: (body: SortBankDetailModel) => {
    return axios.patch(`${GET_BANK_DETAIL}/sort`, body)
  },
}
