import {BankDetailParamsModel} from 'src/app/modules/common/Model'
import {ApiManagerDetailModel} from '../Model'
import {actionTypes} from './constants'
// import {ICardMenuState} from './reducer'
export const actions = {
  // get BankDetail DATA
  getApiManagerDetail: (params: BankDetailParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_API_MANAGER_START,
    payload: params,
  }),
  getApiManagerDetailSuccess: (data: ApiManagerDetailModel) => ({
    type: actionTypes.GET_API_MANAGER_SUCCESS,
    payload: data,
  }),
  getApiManagerDetailFinish: () => ({
    type: actionTypes.GET_API_MANAGER_FINISH,
  }),

  //   // get BankDetail File Type Data
  //   getCardMenuDetailFileType: () => ({
  //     type: actionTypes.GET_API_MANAGER_START,
  //   }),
  //   getCardMenuDetailFileTypeSuccess: (data: CardMenuDetailModel) => ({
  //     type: actionTypes.GET_API_MANAGER_SUCCESS,
  //     payload: data,
  //   }),
  //   getCardMenuDetailFileTypeFinish: () => ({
  //     type: actionTypes.GET_API_MANAGER_FINISH,
  //   }),

  // create key
  addApiManagerDetail: (data: ApiManagerDetailModel) => ({
    type: actionTypes.ADD_API_MANAGER_START,
    payload: data,
  }),
  addApiManagerDetailSuccess: (task: any) => ({
    type: actionTypes.ADD_API_MANAGER_SUCCESS,
    payload: task,
  }),
  addApiManagerDetailFinish: () => ({
    type: actionTypes.ADD_API_MANAGER_FINISH,
  }),
  resetApiManagerDetail: () => ({
    type: actionTypes.RESET_API_MANAGER_TYPE,
  }),

  //Update APIManager
  updateApiManagerDetail: (data: ApiManagerDetailModel, id: string) => ({
    type: actionTypes.UPDATE_API_MANAGER_START,
    payload: data,
    id,
  }),

  updateApiManagerDetailSuccess: (data: ApiManagerDetailModel) => ({
    type: actionTypes.UPDATE_API_MANAGER_SUCCESS,
    payload: data,
  }),

  updateApiManagerDetailFinish: () => ({
    type: actionTypes.UPDATE_API_MANAGER_FINISH,
  }),

  //   // delete key
  //   deleteCardMenuDetail: (data: DeleteCardMenuDetailModel[]) => ({
  //     type: actionTypes.DELETE_API_MANAGER_START,
  //     payload: {cardMenuId: data},
  //   }),
  //   deleteCardMenuDetailSuccess: (data: any) => ({
  //     type: actionTypes.DELETE_API_MANAGER_SUCCESS,
  //     payload: data,
  //   }),
  //   deleteCardMenuDetailFinish: () => ({
  //     type: actionTypes.DELETE_API_MANAGER_FINISH,
  //   }),

  //   //Enable BankDetail
  //   enableCardMenuDetail: (data: any) => ({
  //     type: actionTypes.ENABLE_API_MANAGER_REQUEST,
  //     payload: {data},
  //   }),

  //   enableCardMenuDetailSuccess: (task: any) => ({
  //     type: actionTypes.ENABLE_API_MANAGER_SUCCESS,
  //     payload: task,
  //   }),
  //   enableCardMenuDetailFinish: () => ({
  //     type: actionTypes.ENABLE_API_MANAGER_FINISH,
  //   }),

  //   //Disable BankDetail
  //   disableCardMenuDetail: (data: any) => ({
  //     type: actionTypes.DISABLE_API_MANAGER_REQUEST,
  //     payload: {data},
  //   }),

  //   disableCardMenuDetailSuccess: (task: any) => ({
  //     type: actionTypes.DISABLE_API_MANAGER_SUCCESS,
  //     payload: task,
  //   }),
  //   disableCardMenuDetailFinish: () => ({
  //     type: actionTypes.DISABLE_API_MANAGER_FINISH,
  //   }),

  //   //Enable BankDetail
  //   singleEnableCardMenuDetail: (data: any) => ({
  //     type: actionTypes.SINGLE_ENABLE_API_MANAGER_REQUEST,
  //     payload: {data},
  //   }),

  //   singleEnableCardMenuDetailSuccess: (task: any) => ({
  //     type: actionTypes.SINGLE_ENABLE_API_MANAGER_SUCCESS,
  //     payload: task,
  //   }),
  //   singleEnableCardMenuDetailFinish: () => ({
  //     type: actionTypes.SINGLE_ENABLE_API_MANAGER_FINISH,
  //   }),

  //   //Disable BankDetail
  //   singleDisableCardMenuDetail: (data: any) => ({
  //     type: actionTypes.SINGLE_DISABLE_API_MANAGER_REQUEST,
  //     payload: {data},
  //   }),

  //   singleDisableCardMenuDetailSuccess: (task: any) => ({
  //     type: actionTypes.SINGLE_DISABLE_API_MANAGER_SUCCESS,
  //     payload: task,
  //   }),
  //   singleDisableCardMenuDetailFinish: () => ({
  //     type: actionTypes.SINGLE_DISABLE_API_MANAGER_FINISH,
  //   }),

  //   // get BankDetail
  //   getAllCardMenuDetail: () => {
  //     return {
  //       type: actionTypes.GET_ALL_API_MANAGER_START,
  //     }
  //   },
  //   getAllCardMenuDetailSuccess: (data: ICardMenuState) => ({
  //     type: actionTypes.GET_ALL_API_MANAGER_SUCCESS,
  //     payload: data,
  //   }),
  //   getAllCardMenuDetailError: () => ({
  //     type: actionTypes.GET_ALL_API_MANAGER_FINISH,
  //   }),

  //   // sort
  //   sortCardMenuDetail: (data: SortBankDetailModel) => ({
  //     type: actionTypes.SORT_API_MANAGER_START,
  //     payload: data,
  //   }),
  //   sortCardMenuDetailSuccess: (data: Array<CardMenuDetailModel>) => ({
  //     type: actionTypes.SORT_API_MANAGER_SUCCESS,
  //     payload: data,
  //   }),
  //   sortCardMenuDetailFinish: () => ({
  //     type: actionTypes.SORT_API_MANAGER_FINISH,
  //   }),
  //   sortCardMenuDetailReset: () => ({
  //     type: actionTypes.SORT_API_MANAGER_RESET,
  //   }),
}
