import { ParamsModel } from 'src/app/modules/common/Model'
import { IExportFileState } from '../../branchless'
import { DeleteAgencyModel, OptionModel } from '../Model'
import { AgencyModel } from '../Model/AgencyModel'
import { actionTypes } from './constants'
import { IAgencyState } from './reducer'
export const actions = {
  // get agency category
  getAgencyCategory: () => ({
    type: actionTypes.GET_AGENCY_CATEGORY_START,
  }),
  getAgencyCategorySuccess: (data: OptionModel) => ({
    type: actionTypes.GET_AGENCY_CATEGORY_SUCCESS,
    payload: data,
  }),
  getAgencyCategoryFinish: () => ({
    type: actionTypes.GET_AGENCY_CATEGORY_FINISH,
  }),

  // get EXTENDED HOUR category
  getExtendedHour: () => ({
    type: actionTypes.GET_EXTENDED_HOURS_START,
  }),
  getExtendedHourSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_EXTENDED_HOURS_SUCCESS,
    payload: data,
  }),
  getExtendedHourFinish: () => ({
    type: actionTypes.GET_EXTENDED_HOURS_FINISH,
  }),

  // get AGENCY DATA category
  getAgencyData: (params: ParamsModel = { page: 1, limit: 10 }) => ({
    type: actionTypes.GET_AGENCY_DATA_START,
    payload: params,
  }),
  getAgencyDataSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_AGENCY_DATA_SUCCESS,
    payload: data,
  }),
  getAgencyDataFinish: () => ({
    type: actionTypes.GET_AGENCY_DATA_FINISH,
  }),

  // get AGENCY DATA category
  getAgencyListData: (params: { provinceId: string }) => ({
    type: actionTypes.GET_AGENCY_LIST_DATA_START,
    payload: params,
  }),
  getAgencyListDataSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_AGENCY_LIST_DATA_SUCCESS,
    payload: data,
  }),
  getAgencyListDataFinish: () => ({
    type: actionTypes.GET_AGENCY_LIST_DATA_FINISH,
  }),

  // get EXTENDED HOUR category
  getAllAgencyList: () => ({
    type: actionTypes.GET_ALL_AGENCY_DATA_START,
  }),
  getAllAgencyListSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_ALL_AGENCY_DATA_SUCCESS,
    payload: data,
  }),
  getAllAgencyListFinish: () => ({
    type: actionTypes.GET_ALL_AGENCY_DATA_FINISH,
  }),

  // create key
  addAgency: (data: AgencyModel) => ({
    type: actionTypes.ADD_AGENCY_START,
    payload: data,
  }),
  addAgencySuccess: (task: any) => ({
    type: actionTypes.ADD_AGENCY_SUCCESS,
    payload: task,
  }),
  addAgencyFinish: () => ({
    type: actionTypes.ADD_AGENCY_FINISH,
  }),
  resetAgency: () => ({
    type: actionTypes.RESET_AGENCY,
  }),

  //Update AGENCY
  updateAgency: (data: AgencyModel, id: string) => ({
    type: actionTypes.UPDATE_AGENCY_START,
    payload: data,
    id,
  }),

  updateAgencySuccess: (data: AgencyModel) => ({
    type: actionTypes.UPDATE_AGENCY_SUCCESS,
    payload: data,
  }),

  updateAgencyFinish: () => ({
    type: actionTypes.UPDATE_AGENCY_FINISH,
  }),

  // delete key
  deleteAgency: (data: DeleteAgencyModel[]) => ({
    type: actionTypes.DELETE_AGENCY_START,
    payload: { agencyId: data },
  }),
  deleteAgencySuccess: (data: any) => ({
    type: actionTypes.DELETE_AGENCY_SUCCESS,
    payload: data,
  }),
  deleteAgencyFinish: () => ({
    type: actionTypes.DELETE_AGENCY_FINISH,
  }),

  // IMPORT AGENCY
  importAgency: (data: any) => ({
    type: actionTypes.IMPORT_AGENCY_START,
    payload: data,
  }),
  importAgencySuccess: (task: any) => ({
    type: actionTypes.IMPORT_AGENCY_SUCCESS,
    payload: task,
  }),
  importAgencyFinish: () => ({
    type: actionTypes.IMPORT_AGENCY_FINISH,
  }),

  //Enable Agency
  enableAgency: (data: any) => ({ type: actionTypes.ENABLE_AGENCY_REQUEST, payload: { data } }),

  enableAgencySuccess: (task: any) => ({
    type: actionTypes.ENABLE_AGENCY_SUCCESS,
    payload: task,
  }),
  enableAgencyFinish: () => ({
    type: actionTypes.ENABLE_AGENCY_FINISH,
  }),

  //Disable Agency
  disableAgency: (data: any) => ({ type: actionTypes.DISABLE_AGENCY_REQUEST, payload: { data } }),

  disableAgencySuccess: (task: any) => ({
    type: actionTypes.DISABLE_AGENCY_SUCCESS,
    payload: task,
  }),
  disableAgencyFinish: () => ({
    type: actionTypes.DISABLE_AGENCY_FINISH,
  }),

  //Enable Agency
  singleEnableAgency: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_AGENCY_REQUEST,
    payload: { data },
  }),

  singleEnableAgencySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_AGENCY_SUCCESS,
    payload: task,
  }),
  singleEnableAgencyFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_AGENCY_FINISH,
  }),

  //Disable Agency
  singleDisableAgency: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_AGENCY_REQUEST,
    payload: { data },
  }),

  singleDisableAgencySuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_AGENCY_SUCCESS,
    payload: task,
  }),
  singleDisableAgencyFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_AGENCY_FINISH,
  }),

  //Export file csv
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_AGENCY_FILE_START,
      payload: { fileName, params },
    }
  },

  exportFileSuccess: (data: IExportFileState) => ({
    type: actionTypes.EXPORT_AGENCY_FILE_SUCCESS,
    payload: data,
  }),
  exportFileError: () => ({
    type: actionTypes.EXPORT_AGENCY_FILE_FINISH,
  }),

  //Export Template
  exportTemplateFile: () => {
    return {
      type: actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_START,
    }
  },

  exportTemplateFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_SUCCESS,
    payload: data,
  }),
  exportTemplateFileFinish: () => ({
    type: actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_FINISH,
  }),
}
