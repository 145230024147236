import {Action} from 'redux'
import {COMMON_TYPES, BENEFIT_LIST_TYPES} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialDisputeState: IBenefitListManagerState = {
  data: {
    benefitCalculator: [],
    meta: {},
  },
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IBenefitListManagerState {
  data?: {
    benefitCalculator?: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IBenefitListManagerState = initialDisputeState,
  action: ActionWithPayload<IBenefitListManagerState>
) => {
  switch (action.type) {
    case BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.REQUEST: {
      return {...state, loading: true}
    }

    case BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.SUCCESS: {
      return {...state, data: action?.payload, loading: false}
    }

    case BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.FAILURE: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case BENEFIT_LIST_TYPES.RESET_BENEFIT_LIST: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    default:
      return state
  }
}
