import {Action} from 'redux'
import {SuccessStoryModel, SuccessStoryOptionModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialSuccessStoryState: ISuccessStoryState = {
  data: {
    successstory: [],
    meta: {},
  },
  successStoryFileType: [],
  successStoryList: {successStory: []},
  sortSuccessStoryData: [],
  deleteSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
  loading: false,
  success: false,
}

export interface ISuccessStoryState {
  data: {
    successstory: SuccessStoryModel[]
    meta: {[key: string]: number},
    //successStoryFileOption:[]
  }
  successStoryFileType: SuccessStoryOptionModel[]
  successStoryList: {successStory: SuccessStoryModel[] | any}
  sortSuccessStoryData: ISuccessStoryState[]
  deleteSuccess?: boolean
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  toggleLoading?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ISuccessStoryState = initialSuccessStoryState,
  action: ActionWithPayload<ISuccessStoryState>
) => {
  switch (action.type) {
    //* GET SUCCESS_STORY_FILE_TYPE DATA
    case actionTypes.GET_SUCCESS_STORY_FILE_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SUCCESS_STORY_FILE_TYPE_SUCCESS: {
      return {...state, successStoryFileType: action.payload, loading: false}
    }

    case actionTypes.GET_SUCCESS_STORY_FILE_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //* GET SUCCESS_STORY_ITEM DATA
    case actionTypes.GET_SUCCESS_STORY_DATA_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SUCCESS_STORY_DATA_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SUCCESS_STORY_DATA_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }
    //* GET SUCCESS_STORY_LIST DATA
    case actionTypes.GET_SUCCESS_STORY_LIST_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SUCCESS_STORY_LIST_SUCCESS: {
      return {...state, successStoryList: action.payload, loading: false}
    }

    case actionTypes.GET_SUCCESS_STORY_LIST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //* ADD CMS ITEM

    case actionTypes.ADD_SUCCESS_STORY_ITEM_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_SUCCESS_STORY_ITEM_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_SUCCESS_STORY_ITEM_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_SUCCESS_STORY_ITEM_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //* UPDATE SUCCESS_STORY ITEM
    case actionTypes.UPDATE_SUCCESS_STORY_ITEM_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_SUCCESS_STORY_ITEM_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_SUCCESS_STORY_ITEM_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //* DELETE SUCCESS_STORY ITEM
    case actionTypes.DELETE_BULK_SUCCESS_STORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_BULK_SUCCESS_STORY_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_BULK_SUCCESS_STORY_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ACTIVATE_SUCCESS_STORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.ACTIVATE_SUCCESS_STORY_SUCCESS: {
      return {
        ...state,
        activateSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ACTIVATE_SUCCESS_STORY_FINISH: {
      return {
        ...state,
        loading: false,
        activateSuccess: false,
      }
    }

    //disable
    case actionTypes.DEACTIVATE_SUCCESS_STORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.DEACTIVATE_SUCCESS_STORY_SUCCESS: {
      return {
        ...state,
        deactivateSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DEACTIVATE_SUCCESS_STORY_FINISH: {
      return {
        ...state,
        loading: false,
        deactivateSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_START: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_SUCCESS: {
      const changedData: any = action.payload?.data?.successstory
      let newData = state?.data?.successstory?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, successstory: newData},
        singleActivateSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_SUCCESS_STORY_FINISH: {
      return {
        ...state,
        singleActivateSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_START: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_SUCCESS: {
      const changedData: any = action.payload?.data?.successstory
      let newData = state?.data?.successstory?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, successstory: newData},
        singleDeactivateSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DEACTIVATE_SUCCESS_STORY_FINISH: {
      return {
        ...state,
        singleDeactivateSuccess: false,
        toggleLoading: false,
      }
    }
    // sort
    case actionTypes.SORT_SUCCESS_STORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_SUCCESS_STORY_SUCCESS: {
      return {
        ...state,
        sortSuccessStoryData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_SUCCESS_STORY_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_SUCCESS_STORY_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortSuccessStoryData: [],
      }
    }

    default:
      return state
  }
}
