import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {GoogleParamsModel, ParamsModel} from 'src/app/modules/common/Model'

function* getUserDetailSaga(action: ActionModel) {
  try {
    const params: GoogleParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getUsersDetail, params)
    yield put(actions.getUserSuccess(response?.data?.data))
    yield put(actions.getUserFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getUserFinish())
  }
}

function* getCountryDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCountryDetail, params)
    yield put(actions.getCountryDetailSuccess(response?.data?.data))
    yield put(actions.getCountryDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCountryDetailFinish())
  }
}

function* getUniqueDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getUniqueUsersDetail, params)
    yield put(actions.getUniqueDetailSuccess(response?.data?.data))
    yield put(actions.getUniqueDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getUniqueDetailFinish())
  }
}



export function* saga() {
  yield takeLatest(actionTypes.GET_ANALYTIC_USER_START, getUserDetailSaga)
  yield takeLatest(actionTypes.GET_COUNTRY_USER_START, getCountryDetailSaga)
  yield takeLatest(actionTypes.GET_UNIQUE_USER_START, getUniqueDetailSaga)
}
