import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

//Formik/Rsuite Imports
import {Form, Formik} from 'formik'
import Modal from 'rsuite/Modal'
import Panel from 'rsuite/Panel'
import PanelGroup from 'rsuite/PanelGroup'
//Misc
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {BasicMenuInfo} from './BasicMenuInfo'
//Model and Component Import
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import {MenuOptionModal} from '../Model'
import {IMenuState} from '../redux/reducer'
import PageHeaderComponent from './PageHeaderComponent'

//Redux Import
import * as cms from 'src/app/modules/cms'
import * as categories from 'src/app/modules/cms/components/categories'
import * as contentRedux from 'src/app/modules/cms/components/content'
import * as categoryTypeRedux from 'src/app/modules/common'
import * as ProgramsRedux from 'src/app/modules/programs/programManager'
import {ContentOptionModal} from '../../content/Model'
import {ContentModel} from '../../content/Model/ContentModal'

type Props = {
  open: boolean
  params: StateParamsModel
  setParams: any
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddMenuModals = ({
  open,
  params,
  setParams,
  handleClose,
  actionType,
  editSelectedData,
}: Props) => {
  const dispatch = useDispatch()
  const [contentCategoryId, setContentCategoryId] = useState('')
  const [selectedContentCat, setSelectedContentCat] = useState('')
  const {
    menuIconType,
    menuLinkType,
    menuStatus,
    menuType,
    topMenu,
    visibilityOption,
    loading,
    success,
    data: {meta},
    menuList,
    menuBreadcrumbOption,
  } = useSelector((state: IMenuState | any) => state.menuManager)

  const multiLanguage = checkMultiLanguage()

  useEffect(() => {
    dispatch(cms.menu.actions.getAllMenu())
  }, [])

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('Menu title is required'),
    status: Yup.string().required('Status is required'),
    hideInWebsite: Yup.string().required('Hide in website field is required'),
    slug: Yup.string().required('Slug is required'),
    menuLinkType: Yup.string().required('Menu link type field is required'),
    menuType: Yup.string().required('Menu type field is required'),
    topMenuId: Yup.string().required('Top menu field is required'),
    shortDescription: Yup.string().max(250, 'Short Description exceeds 250 words').nullable(),
    shortDescriptionNp: Yup.string()
      .max(250, 'Short Description Nepali exceeds 250 words')
      .nullable(),
  })

  const {
    data: {content},
  } = useSelector((state: IMenuState | any) => state.content)

  const {programList} = useSelector((state: any) => state.program)

  const {
    data: {categoryType},
  } = useSelector((state: any) => state.categoryType)
  const {
    data: {category},
  } = useSelector((state: any) => state.categories)

  useEffect(() => {
    const contentCategoryType = categoryType?.filter(
      (item: {[key: string]: string}) => item.system_name === 'content'
    )
    setContentCategoryId(contentCategoryType[0]?.id)
  }, [categoryType])

  useEffect(() => {
    if (!isEmpty(contentCategoryId)) {
      dispatch(categories.actions.getSpecificCmsCategories(contentCategoryId))
    }
  }, [contentCategoryId])

  useEffect(() => {
    dispatch(categoryTypeRedux.action.getCategoryType())
    dispatch(cms.menu.actions.getMenuVisibilityStatus())
    dispatch(cms.menu.actions.getMenuIconType())
    dispatch(cms.menu.actions.getMenuLinkType())
    dispatch(cms.menu.actions.getMenuStatus())
    dispatch(cms.menu.actions.getMenuType())
    dispatch(cms.menu.actions.getTopMenu())
    dispatch(ProgramsRedux.actions.getAllProgram())
    dispatch(cms.menu.actions.getMenuBreadCrumbOptions())
  }, [])

  useEffect(() => {
    if (!isEmpty(selectedContentCat)) {
      dispatch(contentRedux.actions.getContentDataByCategoryId(selectedContentCat))
    }
  }, [selectedContentCat])

  //Create options from API for FormSelect

  //filtering out current selected menu
  const menuOptions = menuList?.menu
    ?.filter((menu: {name: string}) => menu.name !== editSelectedData?.name)
    ?.map((item: any) => ({
      label: item.tableViewName,
      value: item.id,
      children: item?.children?.map((child: any) => ({
        label: child.tableViewName,
        value: child.id,
        children: child?.children?.map((grchild: any) => ({
          label: grchild.tableViewName,
          value: grchild.id,
          children: grchild?.children?.map((grchild1: any) => ({
            label: grchild1.tableViewName,
            value: grchild1.id,
          })),
        })),
      })),
    }))

  const breadcrumbOptions = menuBreadcrumbOption?.map((items: ContentOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  const programDataOption = programList?.program?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  const contentCategoryOptions = category?.map((items: {[key: string]: string}) => ({
    label: items.name,
    value: items.id,
  }))

  const contentDataOptions = content?.map((items: ContentModel) => ({
    label: items.title,
    value: items.id,
  }))

  const iconTypeOptions = menuIconType?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const linkTypeOptions = menuLinkType?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  const menuStatusOptions = menuStatus?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const menuTypeOptions = menuType?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const collegeMenuTypeOptions = menuType
    ?.filter(
      (item: MenuOptionModal) =>
        item?.displayName === 'Top Left Menu' ||
        item?.displayName === 'Side Menu' ||
        item?.displayName === 'Footer Menu'
    )
    .map((items: MenuOptionModal) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    }))
  const topMenuOptions = topMenu?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))
  const visibilityStatusOptions = visibilityOption?.map((items: MenuOptionModal) => ({
    label: items.displayName,
    value: items.id,
    systemName: items.systemName,
  }))

  useEffect(() => {
    if (success) {
      dispatch(cms.menu.actions.getMenu())
      isEmpty(editSelectedData)
        ? toast.success('Menu added successfully')
        : toast.success('Menu edited successfully')
      dispatch(cms.menu.actions.resetMenu())
      handleClose()
    }
  }, [success])
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body className='flexibleContainer'>
          <div>
            <Formik
              initialValues={{
                parentId: '',
                name: '',
                nameNp: '',
                slug: '',
                iconType: '',
                menuIcon: '',
                menuImage: '',
                menuType: '',
                featuredImage: '',
                featuredImageText: '',
                featuredImageTextNp: '',
                featuredImageUrl: '',
                status: '',
                menuLinkType: '',
                productId: '',
                contentId: '',
                topMenuId: '',
                url: '',
                file: '',
                serviceId: '',
                metaTitle: '',
                metaKeyword: '',
                metaDescription: '',
                headerImage: '',
                headerShortDescription: '',
                headerShortDescriptionNp: '',
                firstButtonText: '',
                firstButtonTextNp: '',
                firstButtonLink: '',
                secondButtonText: '',
                secondButtonTextNp: '',
                secondButtonLink: '',
                showNotificationBell: false,
                showToLoggedInUserOnly: false,
                hideInWebsite: '',
                programId: '',
                allowInRoute: true,
                show_breadcrumb_id: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(cms.menu.actions.updateMenu(formData, editSelectedData?.id))
                } else {
                  dispatch(cms.menu.actions.addMenu(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'contentData',
                      'contentId',
                      'featuredImage',
                      'featuredImageText',
                      'featuredImageTextNp',
                      'featuredImageUrl',
                      'file',
                      'firstButtonLink',
                      'firstButtonText',
                      'firstButtonTextNp',
                      'headerImage',
                      'headerShortDescription',
                      'headerShortDescriptionNp',
                      'hideInWebsite',
                      'iconType',
                      'topMenuId',
                      'id',
                      'menuIcon',
                      'menuImage',
                      'menuLinkType',
                      'menuType',
                      'metaKeyword',
                      'metaTitle',
                      'name',
                      'allowInRoute',
                      'nameNp',
                      'orderId',
                      'parentId',
                      'productId',
                      'programId',
                      'secondButtonLink',
                      'secondButtonText',
                      'secondButtonTextNp',
                      'serviceId',
                      'showNotificationBell',
                      'showToLoggedInUserOnly',
                      'slug',
                      'status',
                      'tableViewName',
                      'url',
                      'show_breadcrumb_id',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                  //Default Value while adding
                  if (actionType === 'Add') {
                    setFieldValue(
                      'show_breadcrumb_id',
                      breadcrumbOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'hideInWebsite',
                      visibilityStatusOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'status',
                      menuStatusOptions
                        ?.filter((items: any) => items.systemName === 'active')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top'>
                      <div className=''>
                        <div className='row'>
                          <PanelGroup accordion>
                            <Panel
                              header='Basic Menu Info'
                              style={{
                                overflow: 'visible',
                              }}
                              defaultExpanded
                            >
                              <BasicMenuInfo
                                iconTypeOptions={iconTypeOptions}
                                linkTypeOptions={linkTypeOptions}
                                menuStatusOptions={menuStatusOptions}
                                menuTypeOptions={
                                  multiLanguage ? collegeMenuTypeOptions : menuTypeOptions
                                }
                                topMenuOptions={topMenuOptions}
                                menuOptions={menuOptions}
                                breadcrumbOptions={breadcrumbOptions}
                                contentDataOptions={contentDataOptions}
                                programDataOptions={programDataOption}
                                contentCategoryOptions={contentCategoryOptions}
                                setSelectedContentCat={setSelectedContentCat}
                                editSelectedData={editSelectedData}
                                visibilityStatusOptions={visibilityStatusOptions}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                errors={errors}
                              />
                            </Panel>
                            <Panel header='Page Header Settings'>
                              <PageHeaderComponent
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                values={values}
                                editSelectedData={editSelectedData}
                              />
                            </Panel>
                          </PanelGroup>
                        </div>

                        <div className='d-flex justify-content-end px-9 '>
                          <button
                            type='submit'
                            disabled={loading}
                            className='btn btn-primary btn-sm ms-3'
                            data-cy='modal-submit'
                          >
                            Save
                          </button>
                          <button
                            type='button'
                            onClick={handleClose}
                            className='btn btn-secondary btn-sm ms-3'
                            data-cy='modal-cancel'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddMenuModals
