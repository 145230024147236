import {EmptyObject} from 'chart.js/types/basic'
import {useEffect, useState} from 'react'
import {AiOutlineEdit} from 'react-icons/ai'
import {FiEye} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import IconButton from 'rsuite/IconButton'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

//Manual import
import axios from 'axios'
import {isEmpty} from 'lodash'
import * as appointmentRequestRedux from 'src/app/modules/appointments/components/appointmentRequest/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {AppointmentRequestModel} from '../Model'
import AddStatus from './AddStatus'
import UpdateStatus from './UpdateStatus'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const Cell = Table.Cell
const AppointmentRequest = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  const statusParams: {id: string} = useParams()

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    fromDate: '',
    toDate: '',
  })
  const [allDataParams, setAllDataParams] = useState<StateParamsModel>({
    page: 0,
    limit: 0,
  })

  const [actionType, setActionType] = useState('Add')
  const [actionTypeStatus, setActionTypeStatus] = useState('AddStatus')
  const [alertOpen, setAlertOpen] = useState(false)
  const [param, setParam] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [open1, setOpen1] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [editSelectedData, setEditCheckedData] = useState<AppointmentRequestModel | EmptyObject>({})
  const [updateSelectedData, setUpdateCheckedData] = useState<
    AppointmentRequestModel | EmptyObject
  >({})
  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const handleClose1 = () => setOpen1(false)
  const handleCloseStatus = () => setOpenStatus(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const appointmentRequestData = useSelector((state: any) => state.appointmentRequest)

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  const [appRequestId, setAppRequestId] = useState(appointmentRequestData?.id)

  const settingType: any = settingTypeData?.data?.setting
    ?.filter((item: any) => item.name === 'appointmentStatus')
    .map((item: any) => item)

  useEffect(() => {
    if (!isEmpty(statusParams?.id)) {
      setAppRequestId(statusParams?.id)
    }
    dispatch(appointmentRequestRedux.actions.getAppointmentRequest(params))
    dispatch(setting.actions.getSettingType())
  }, [params])
  const {success, loading} = appointmentRequestData

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper
            placement='top'
            trigger='hover'
            speaker={<Tooltip>View Appointment List</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen1(true)
              }}
              icon={<FiEye />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>

        <Whisper
          placement='top'
          trigger='hover'
          speaker={<Tooltip>Update Appointment Status</Tooltip>}
        >
          <IconButton
            appearance='subtle'
            onClick={() => {
              setEditCheckedData(rowData)
              setActionTypeStatus('Update')
              setOpenStatus(true)
            }}
            icon={<AiOutlineEdit />}
            disabled={
              settingType[0]?.value === '1' && rowData?.appointmentStatus === 'Completed'
                ? true
                : false
            }
            data-cy='action-update'
          />
        </Whisper>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },

    {
      label: 'Appointment Reason',
      dataKey: 'appointmentReason',
      flexGrow: 1,
      cell: (
        <Cell>
          {(rowData) =>
            !isEmpty(rowData?.appointmentReason) ? rowData?.appointmentReason?.title : '-'
          }
        </Cell>
      ),
      sortable: true,
    },

    {
      label: settingType[0]?.value === '1' ? 'Status' : 'Meeting Type',
      dataKey: settingType[0]?.value === '1' ? 'appointmentStatus' : 'meetingType',
      flexGrow: 1,
      cell: (
        <Cell>
          {settingType[0]?.value === '1'
            ? (rowData) =>
                !isEmpty(rowData?.appointmentStatus) ? (
                  <div
                    className={`text-uppercase text-white text-center fw-normal ${
                      rowData?.appointmentStatus === 'Pending' && 'badge bg-warning'
                    } ${rowData?.appointmentStatus === 'Completed' && 'badge bg-success'} 
                `}
                  >
                    {rowData?.appointmentStatus}
                  </div>
                ) : (
                  '-'
                )
            : (rowData) =>
                !isEmpty(rowData?.meetingType) ? rowData?.meetingType?.displayName : '-'}
        </Cell>
      ),
      sortable: settingType[0]?.value === '1' ? false : true,
    },
    {
      label: 'Branch',
      dataKey: 'branch',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.branch) ? rowData?.branch?.title : '-')}</Cell>,
      sortable: true,
    },

    {
      label: 'Name',
      dataKey: 'firstName',
      flexGrow: 1,
      cell: <Cell dataKey='firstName' />,
      sortable: true,
    },

    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      flexGrow: 1,
      cell: <Cell dataKey='mobileNumber' />,
      sortable: true,
    },

    {
      label: 'Appointment Date',
      dataKey: 'appointmentDate',
      flexGrow: 1,
      cell: <Cell dataKey='appointmentDate' />,
      sortable: true,
    },
    {
      label: 'Appointment Time',
      dataKey: 'appointmentTime',
      flexGrow: 1,
      cell: <Cell dataKey='appointmentTime' />,
      sortable: true,
    },
    {
      label: 'Action',
      flexGrow: 1,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleRefresh = () => {
    dispatch(appointmentRequestRedux.actions.getAppointmentRequest(params))
  }
  const handleExportFile = () => {
    dispatch(appointmentRequestRedux.actions.getAppointmentRequestFile('csv'))
  }

  const handleExport = () => {
    axios
      .get(
        API_URL + `/appointment-request/export?fromDate=${params.fromDate}&toDate=${params.toDate}`
      )
      .then((response) => {
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'appointment-list.csv')
        document.body.appendChild(link)
        link.click()
      })
  }

  //Get data from api to map in datatable
  const data = appointmentRequestData?.data?.appointmentRequest
    ? appointmentRequestData?.data?.appointmentRequest?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        appointmentTypeId: item?.appointmentReason?.appoinmentType?.appointmentType?.[0]?.id,
        appoinmentType: item?.appointmentReason?.appoinmentType?.appointmentType?.[0]?.title,
        appointmentReasonId: item?.appointmentReason?.id,
        appointmentReason: item.appointmentReason,
        statusId: item?.appointmentStatus?.id,
        appointmentStatus: item?.appointmentStatus?.displayName,
        meetingTypeId: item?.meetingType?.id,
        meetingType: item.meetingType,
        appointmentDate: item?.appointmentDate,
        appointmentTime: item?.appointmentTime,
        firstName: item?.firstName,
        middleName: item?.middleName,
        lastName: item?.lastName,
        email: item?.email,
        mobileNumber: item?.mobileNumber,
        additionalAttendees: item?.additionalAttendees,
        branchId: item?.branch?.id,
        branch: item?.branch,
        remarks: item?.remarks,
      }))
    : []
  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen1(true)
    setActionType('Add')
  }

  const handleAddStatusModal = () => {
    setOpenStatus(true)
    setActionType('AddStatus')
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Appointment List'
          params={params}
          setParams={setParams}
          handleAddModal={handleAddModal}
          handleAddStatus={handleAddStatusModal}
          handleAlertOpen={handleAlertOpen}
          handleRefresh={handleRefresh}
          exportShow={true}
          importShow={false}
          handleExport={handleExport}
          exportButtonName='Export Appointment List'
          addShow={false}
          updateShow={false}
          deleteShow={false}
          filterData={true}
          toggleMultipleShow={false}
          data={data}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={appointmentRequestData?.loading}
            onChecked={handleCheck}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={appointmentRequestData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {openStatus && (
          <UpdateStatus
            open={openStatus}
            handleClose={handleCloseStatus}
            actionType={actionTypeStatus}
            editSelectedData={editSelectedData}
            params={params}
          />
        )}
        {open1 && (
          <AddStatus
            open={open1}
            handleClose={handleClose1}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}
      </div>
    </div>
  )
}
export default AppointmentRequest
