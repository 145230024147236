import { ParamsModel } from "src/app/modules/common/Model";
import { PropertyTypeModel, SortPropertyTypeModel } from "../Model/Model";
import { actionTypes } from "./constants";
export const actions = {
  // get PropertyType DATA
  getPropertyType: (params: ParamsModel = { page: 1, limit: 10 }) => ({
    type: actionTypes.GET_PROPERTY_TYPE_START,
    payload: params,
  }),
  getPropertyTypeSuccess: (data: PropertyTypeModel | any) => ({
    type: actionTypes.GET_PROPERTY_TYPE_SUCCESS,
    payload: data,
  }),
  getPropertyTypeFinish: () => ({
    type: actionTypes.GET_PROPERTY_TYPE_FINISH,
  }),

  // create key
  addPropertyType: (data: PropertyTypeModel | any) => ({
    type: actionTypes.ADD_PROPERTY_TYPE_START,
    payload: data,
  }),
  addPropertyTypeSuccess: (task: any) => ({
    type: actionTypes.ADD_PROPERTY_TYPE_SUCCESS,
    payload: task,
  }),
  addPropertyTypeFinish: () => ({
    type: actionTypes.ADD_PROPERTY_TYPE_FINISH,
  }),
  resetPropertyType: () => ({
    type: actionTypes.RESET_PROPERTY_TYPE,
  }),

  //Update PropertyType
  updatePropertyType: (data: PropertyTypeModel | any, id: string) => ({
    type: actionTypes.UPDATE_PROPERTY_TYPE_START,
    payload: data,
    id,
  }),

  updatePropertyTypeSuccess: (data: PropertyTypeModel | any) => ({
    type: actionTypes.UPDATE_PROPERTY_TYPE_SUCCESS,
    payload: data,
  }),

  updatePropertyTypeFinish: () => ({
    type: actionTypes.UPDATE_PROPERTY_TYPE_FINISH,
  }),

  // delete key
  deletePropertyType: (data: { id: string }[]) => ({
    type: actionTypes.DELETE_PROPERTY_TYPE_START,
    payload: { propertyTypeId: data },
  }),
  deletePropertyTypeSuccess: (data: any) => ({
    type: actionTypes.DELETE_PROPERTY_TYPE_SUCCESS,
    payload: data,
  }),
  deletePropertyTypeFinish: () => ({
    type: actionTypes.DELETE_PROPERTY_TYPE_FINISH,
  }),

  //Enable PropertyType
  enablePropertyType: (data: any) => ({
    type: actionTypes.ENABLE_PROPERTY_TYPE_REQUEST,
    payload: { data },
  }),

  enablePropertyTypeSuccess: (task: any) => ({
    type: actionTypes.ENABLE_PROPERTY_TYPE_SUCCESS,
    payload: task,
  }),
  enablePropertyTypeFinish: () => ({
    type: actionTypes.ENABLE_PROPERTY_TYPE_FINISH,
  }),

  //Disable PropertyType
  disablePropertyType: (data: any) => ({
    type: actionTypes.DISABLE_PROPERTY_TYPE_REQUEST,
    payload: { data },
  }),

  disablePropertyTypeSuccess: (task: any) => ({
    type: actionTypes.DISABLE_PROPERTY_TYPE_SUCCESS,
    payload: task,
  }),
  disablePropertyTypeFinish: () => ({
    type: actionTypes.DISABLE_PROPERTY_TYPE_FINISH,
  }),

  //Enable PropertyType
  singleEnablePropertyType: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_REQUEST,
    payload: { data },
  }),

  singleEnablePropertyTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_SUCCESS,
    payload: task,
  }),
  singleEnablePropertyTypeFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_FINISH,
  }),

  //Disable PropertyType
  singleDisablePropertyType: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_REQUEST,
    payload: { data },
  }),

  singleDisablePropertyTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_SUCCESS,
    payload: task,
  }),
  singleDisablePropertyTypeFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_FINISH,
  }),

  // sort
  sortPropertyType: (data: SortPropertyTypeModel) => ({
    type: actionTypes.SORT_PROPERTY_TYPE_START,
    payload: data,
  }),
  sortPropertyTypeSuccess: (data: Array<PropertyTypeModel>) => ({
    type: actionTypes.SORT_PROPERTY_TYPE_SUCCESS,
    payload: data,
  }),
  sortPropertyTypeFinish: () => ({
    type: actionTypes.SORT_PROPERTY_TYPE_FINISH,
  }),
  sortPropertyTypeReset: () => ({
    type: actionTypes.SORT_PROPERTY_TYPE_RESET,
  }),
};
