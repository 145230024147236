import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCertificateCategoryModel, SortCertificateCategoryModel} from '../Model'
import { CertificateCategoryModel } from '../Model/CertificateCategory'
import {actionTypes} from './constants'
export const actions = {
  // get CertificateCategory
  getAllCertificateCategory: () => ({
    type: actionTypes.GET_ALL_CERTIFICATE_CATEGORY_START,
  }),
  getAllCertificateCategorySuccess: (data: CertificateCategoryModel) => ({
    type: actionTypes.GET_ALL_CERTIFICATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  getAllCertificateCategoryFinish: () => ({
    type: actionTypes.GET_ALL_CERTIFICATE_CATEGORY_FINISH,
  }),

  // get CertificateCategory DATA
  getCertificateCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CERTIFICATE_CATEGORY_START,
    payload: params,
  }),
  getCertificateCategorySuccess: (data: CertificateCategoryModel) => ({
    type: actionTypes.GET_CERTIFICATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  getCertificateCategoryFinish: () => ({
    type: actionTypes.GET_CERTIFICATE_CATEGORY_FINISH,
  }),

  // create key
  addCertificateCategory: (data: CertificateCategoryModel) => ({
    type: actionTypes.ADD_CERTIFICATE_CATEGORY_START,
    payload: data,
  }),
  addCertificateCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_CERTIFICATE_CATEGORY_SUCCESS,
    payload: task,
  }),
  addCertificateCategoryFinish: () => ({
    type: actionTypes.ADD_CERTIFICATE_CATEGORY_FINISH,
  }),
  resetCertificateCategory: () => ({
    type: actionTypes.RESET_CERTIFICATE_CATEGORY,
  }),

  //Update CertificateCategory
  updateCertificateCategory: (data: CertificateCategoryModel, id: string) => ({
    type: actionTypes.UPDATE_CERTIFICATE_CATEGORY_START,
    payload: data,
    id,
  }),

  updateCertificateCategorySuccess: (data: CertificateCategoryModel) => ({
    type: actionTypes.UPDATE_CERTIFICATE_CATEGORY_SUCCESS,
    payload: data,
  }),

  updateCertificateCategoryFinish: () => ({
    type: actionTypes.UPDATE_CERTIFICATE_CATEGORY_FINISH,
  }),

  // delete key
  deleteCertificateCategory: (data: DeleteCertificateCategoryModel[]) => ({
    type: actionTypes.DELETE_CERTIFICATE_CATEGORY_START,
    payload: {certificateCategoryId: data},
  }),
  deleteCertificateCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_CERTIFICATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteCertificateCategoryFinish: () => ({
    type: actionTypes.DELETE_CERTIFICATE_CATEGORY_FINISH,
  }),

  //Enable CertificateCategory
  enableCertificateCategory: (data: any) => ({
    type: actionTypes.ENABLE_CERTIFICATE_CATEGORY_REQUEST,
    payload: {data},
  }),

  enableCertificateCategorySuccess: (task: any) => ({
    type: actionTypes.ENABLE_CERTIFICATE_CATEGORY_SUCCESS,
    payload: task,
  }),
  enableCertificateCategoryFinish: () => ({
    type: actionTypes.ENABLE_CERTIFICATE_CATEGORY_FINISH,
  }),

  //Disable CertificateCategory
  disableCertificateCategory: (data: any) => ({
    type: actionTypes.DISABLE_CERTIFICATE_CATEGORY_REQUEST,
    payload: {data},
  }),

  disableCertificateCategorySuccess: (task: any) => ({
    type: actionTypes.DISABLE_CERTIFICATE_CATEGORY_SUCCESS,
    payload: task,
  }),
  disableCertificateCategoryFinish: () => ({
    type: actionTypes.DISABLE_CERTIFICATE_CATEGORY_FINISH,
  }),

  //Enable CertificateCategory
  singleEnableCertificateCategory: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_CERTIFICATE_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleEnableCertificateCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_CERTIFICATE_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleEnableCertificateCategoryFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_CERTIFICATE_CATEGORY_FINISH,
  }),

  //Disable CertificateCategory
  singleDisableCertificateCategory: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_CERTIFICATE_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleDisableCertificateCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_CERTIFICATE_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleDisableCertificateCategoryFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_CERTIFICATE_CATEGORY_FINISH,
  }),

  
  // sort
  sortCertificateCategory: (data: SortCertificateCategoryModel) => ({
    type: actionTypes.SORT_CERTIFICATE_CATEGORY_START,
    payload: data,
  }),
  sortCertificateCategorySuccess: (data: Array<CertificateCategoryModel>) => ({
    type: actionTypes.SORT_CERTIFICATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortCertificateCategoryFinish: () => ({
    type: actionTypes.SORT_CERTIFICATE_CATEGORY_FINISH,
  }),
  sortCertificateCategoryReset: () => ({
    type: actionTypes.SORT_CERTIFICATE_CATEGORY_RESET,
  }),
}
