import { Action } from "redux";
import { PropertyTypeModel } from "../Model/Model";
import { actionTypes } from "./constants";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

const initialPropertyTypeState: IPropertyTypeState = {
  data: {
    propertyType: [],
    meta: [],
  },
  sortPropertyTypeData: [],
  propertyTypeList: { propertyType: [] },
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
};

export interface IPropertyTypeState {
  data?: {
    propertyType?: PropertyTypeModel[];
    meta?: { [key: string]: string | number }[];
  };
  sortPropertyTypeData?: IPropertyTypeState[];
  propertyTypeList?: { propertyType: PropertyTypeModel[] };
  deleteSuccess?: boolean;
  enableSuccess?: boolean;
  disableSuccess?: boolean;
  singleEnableSuccess?: boolean;
  singleDisableSuccess?: boolean;
  loading?: boolean;
  success?: boolean;
}

export const reducer = (
  state: IPropertyTypeState = initialPropertyTypeState,
  action: ActionWithPayload<IPropertyTypeState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROPERTY_TYPE_START: {
      return { ...state, loading: true };
    }

    case actionTypes.GET_ALL_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        propertyTypeList: action.payload,
        loading: false,
      };
    }

    case actionTypes.GET_ALL_PROPERTY_TYPE_FINISH: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    //GET PropertyType DATA
    case actionTypes.GET_PROPERTY_TYPE_START: {
      return { ...state, loading: true };
    }

    case actionTypes.GET_PROPERTY_TYPE_SUCCESS: {
      return { ...state, data: action.payload, loading: false };
    }

    case actionTypes.GET_PROPERTY_TYPE_FINISH: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    //Add PropertyType
    case actionTypes.ADD_PROPERTY_TYPE_START: {
      return { ...state, loading: true };
    }

    case actionTypes.ADD_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      };
    }

    case actionTypes.ADD_PROPERTY_TYPE_FINISH: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    case actionTypes.RESET_PROPERTY_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
      };
    }

    //Update PropertyType
    case actionTypes.UPDATE_PROPERTY_TYPE_START: {
      return { ...state, loading: true };
    }

    case actionTypes.UPDATE_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      };
    }

    case actionTypes.UPDATE_PROPERTY_TYPE_FINISH: {
      const error = action.payload;
      return { ...state, error, loading: false };
    }

    //Delete
    case actionTypes.DELETE_PROPERTY_TYPE_START: {
      return { ...state, loading: true };
    }

    case actionTypes.DELETE_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      };
    }

    case actionTypes.DELETE_PROPERTY_TYPE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      };
    }

    //Enable
    case actionTypes.ENABLE_PROPERTY_TYPE_REQUEST: {
      return { ...state, loading: true };
    }

    case actionTypes.ENABLE_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      };
    }

    case actionTypes.ENABLE_PROPERTY_TYPE_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      };
    }

    //disable
    case actionTypes.DISABLE_PROPERTY_TYPE_REQUEST: {
      return { ...state, loading: true };
    }

    case actionTypes.DISABLE_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      };
    }

    case actionTypes.DISABLE_PROPERTY_TYPE_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      };
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_REQUEST: {
      return { ...state, toggleLoading: true };
    }

    case actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_SUCCESS: {
      const changedData: any = action.payload?.data?.propertyType;
      let newData = state?.data?.propertyType?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          };
        } else {
          return data;
        }
      });
      return {
        ...state,
        data: { ...state, meta: state?.data?.meta, propertyType: newData },
        singleEnableSuccess: true,
        toggleLoading: false,
      };
    }

    case actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      };
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_REQUEST: {
      return { ...state, toggleLoading: true };
    }

    case actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_SUCCESS: {
      const changedData: any = action.payload?.data?.propertyType;
      let newData = state?.data?.propertyType?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          };
        } else {
          return data;
        }
      });

      return {
        ...state,
        data: { ...state, meta: state?.data?.meta, propertyType: newData },
        singleDisableSuccess: true,
        toggleLoading: false,
      };
    }

    case actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      };
    }

    // sort
    case actionTypes.SORT_PROPERTY_TYPE_START: {
      return { ...state, loading: true };
    }

    case actionTypes.SORT_PROPERTY_TYPE_SUCCESS: {
      return {
        ...state,
        sortPropertyTypeData: action?.payload,
        success: true,
        loading: false,
      };
    }

    case actionTypes.SORT_PROPERTY_TYPE_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    case actionTypes.SORT_PROPERTY_TYPE_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortPropertyTypeData: [],
      };
    }

    default:
      return state;
  }
};
