export const COMMON_TYPES = {
  REQUEST: '_REQUEST',
  SUCCESS: '_SUCCESS',
  FAILURE: '_FAILURE',
  CALL_OVER: '_FINISHED',
}

export const BENEFIT_LIST_TYPES = {
  GET_BENEFIT_LIST: 'GET_BENEFIT_LIST',
  RESET_BENEFIT_LIST: 'RESET_BENEFIT_LIST',
}
