import {FC} from 'react'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {useLayout} from '../core'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {isEmpty} from 'lodash'

const Footer: FC = () => {
  const {classes} = useLayout()
  const dispatch = useDispatch()

  const [title, setTitle] = useState('')
  const [applicationUrl, setApplicationUrl] = useState('')
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setTitle(item?.value)
          }
          if (item?.name === 'applicationUrl') {
            setApplicationUrl(item?.value)
          }
        })
    }
  }, [settingTypeData])

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-end`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href={applicationUrl} className=''>
            {title}
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        {/* <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
        </ul> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
