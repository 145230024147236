import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialCategoryState: IBenefitManagerData = {
  data: {
    benefitManager: [],
    meta: {},
  },
  deleteSuccess: false,
  sortBenefitManagerData: [],

  loading: false,
  success: false,
}

export interface IBenefitManagerData {
  data: {
    benefitManager: any
    meta: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  sortBenefitManagerData: IBenefitManagerData[]
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IBenefitManagerData = initialCategoryState,
  action: ActionWithPayload<IBenefitManagerData>
) => {
  switch (action.type) {
    case actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_BENEFIT_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_BENEFIT_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_BENEFIT_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_BENEFIT_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_BENEFIT_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_BENEFIT_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_BENEFIT_MANAGER_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_BENEFIT_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_BENEFIT_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_BENEFIT_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_BENEFIT_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_BENEFIT_MANAGER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_BENEFIT_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_BENEFIT_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_BENEFIT_MANAGER_SUCCESS: {
      return {
        ...state,
        sortBenefitManagerData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_BENEFIT_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_BENEFIT_MANAGER_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortBenefitManagerData: [],
      }
    }

    default:
      return state
  }
}
