import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Pagination, Table, Tag, Toggle, Tooltip, Whisper } from 'rsuite'

import { isEmpty } from 'lodash'
import { FiEye } from 'react-icons/fi'
import { toast } from 'react-toastify'
import { SortType } from 'rsuite-table/lib/@types/common'
import { ItemType, StateParamsModel } from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {
  ICategoryState,
  activateCategory,
  deactivateCategory,
  deleteCategory,
  getAllCategory,
  singleActivateCategory,
  singleDeactivateCategory,
  sortCategory,
  sortCategoryReset,
} from '../redux'
import AddOnlineApplicationCategory from './AddOnlineApplicationCategory'

const ApplicationCategoryComponent = () => {
  const dispatch = useDispatch()
  const {
    data: onlineApplicationCategory,
    loading,
    success,
    deleteSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    activateSuccess,
    deactivateSuccess,
    sortApplicationCategoryData,
  } = useSelector((state: any) => state.onlineApplicationCategory)
  const ApplicationCategoryData: ICategoryState = useSelector(
    (state: any) => state.onlineApplicationCategory
  )

  const [openSortModal, setOpenSortModal] = useState(false)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditSelectedData] = useState<any>()
  const [addModal, setAddModal] = useState(false)
  const [actionType, setActionType] = useState<any>([])
  const [alertOpen, setAlertOpen] = useState(false)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    orderBy: 'order',
  })

  useEffect(() => {
    dispatch(getAllCategory(params))
  }, [params])

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(getAllCategory(params))
      toast.success('Category deleted successfully')
      handleChecked([])
    }
  }, [deleteSuccess, success])

  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success('Category activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success('Category deactivated successfully')
    }
    if (activateSuccess) {
      toast.success('Category activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Category deactivated successfully')
    }
    handleChecked([])
    dispatch(getAllCategory(params))
  }, [singleActivateSuccess, singleDeactivateSuccess, activateSuccess, deactivateSuccess])

  const handleToggleAction = (data: { [key: string]: string }) => {
    const formData = { id: data?.id }
    data?.status
      ? dispatch(singleDeactivateCategory(formData))
      : dispatch(singleActivateCategory(formData))
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View Details</Tooltip>}>
            <IconButton onClick={() => { }} appearance='subtle' icon={<FiEye />}
              data-cy='view-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditSelectedData(rowData)
                setActionType('Edit')
                setAddModal(true)
              }}
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            checked={rowData.status}
            onClick={() => {
              handleToggleAction(rowData)
            }}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Category Title(E.N)',
      dataKey: 'title',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },
    {
      label: 'Category Title(N.P)',
      dataKey: 'title_np',
      flexGrow: 1,
      cell: <Cell dataKey='title_np' />,
      sortable: false,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleAddModal = () => {
    setAddModal(true)
    setActionType('Add')
  }

  const handleClose = () => {
    setAddModal(false)
  }

  const handleRefresh = () => {
    dispatch(getAllCategory(params))
  }
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleChecked = (value: any) => {
    setCheckedValues(value)
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value: any) => ({ id: value }))
    dispatch(deleteCategory(id))
    handleAlertClose()
  }

  const activateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(activateCategory(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(deactivateCategory(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const data = ApplicationCategoryData?.data?.onlineApplicationCategory
    ? ApplicationCategoryData?.data?.onlineApplicationCategory.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item?.id,
      title: item?.title,
      title_np: item?.titleNp,
      status: item?.status,
    }))
    : []

  const handleGetAllData = () => {
    setParams({ ...params, limit: onlineApplicationCategory?.meta?.total })
    dispatch(getAllCategory(params))
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      onlineApplicationCategoryId: data?.map((item) => ({ id: item?.keyId })),
    }
    dispatch(sortCategory(body))
  }

  useEffect(() => {
    if (!isEmpty(sortApplicationCategoryData) && success) {
      toast.success('Category sorted successfully')
      dispatch(sortCategoryReset())
      setParams({ ...params, limit: 10 })
      dispatch(getAllCategory(params))
      setOpenSortModal(false)
    }
  }, [sortApplicationCategoryData, success])

  return (
    <div className='shadow p-3 bg-white rounded'>
      <DesignComponent
        moduleName='Online Application Category'
        params={params}
        setParams={setParams}
        handleRefresh={handleRefresh}
        handleAddModal={handleAddModal}
        handleAlertOpen={handleAlertOpen}
        exportShow={false}
        importShow={false}
        enableMultiple={activateSelectedData}
        disableMultiple={deactivateSelectedData}
        sortShow={false}
        sortButtonName='Sort'
        openSortModal={openSortModal}
        setOpenSortModal={setOpenSortModal}
        loading={loading}
      />

      <div className='datatable'>
        <RSuiteTable
          onChecked={handleChecked}
          showCheckbox={true}
          columns={columns}
          data={data}
          showLoading={loading}
          checkedValues={checkedValues}
          handleSort={handleSort}
        />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          className='mt-5'
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={onlineApplicationCategory?.meta?.total || 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({ ...params, page: value })}
          onChangeLimit={handleChangeLimit}
        />

        {addModal && (
          <AddOnlineApplicationCategory
            open={addModal}
            params={params}
            handleClose={handleClose}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}

        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => modalSubmit()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </div>
  )
}

export default ApplicationCategoryComponent
