import {ParamsModel} from 'src/app/modules/common/Model'
import {ApplyMembershipModel, DeleteApplyMembershipModel} from '../Model'
import {actionTypes} from './constants'
import {IApplyMembershipState} from './reducer'

export const actions = {
  // get ApplyMembership
  getApplyMembership: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_APPLY_MEMBERSHIP_START,
      payload: {params},
    }
  },
  getApplyMembershipSuccess: (data: IApplyMembershipState) => ({
    type: actionTypes.GET_APPLY_MEMBERSHIP_SUCCESS,
    payload: data,
  }),
  getApplyMembershipError: (data: IApplyMembershipState) => ({
    type: actionTypes.GET_APPLY_MEMBERSHIP_FINISH,
    payload: data,
  }),

  //Add ApplyMembership Action
  CreateApplyMembership: (data: any) => ({
    type: actionTypes.ADD_APPLY_MEMBERSHIP_REQUEST,
    payload: data,
  }),

  createApplyMembershipSuccess: (task: any) => ({
    type: actionTypes.ADD_APPLY_MEMBERSHIP_SUCCESS,
    payload: task,
  }),
  createApplyMembershipFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_APPLY_MEMBERSHIP_FINISH,
    payload: errorMsg,
  }),
  createApplyMembershipReset: () => ({
    type: actionTypes.ADD_APPLY_MEMBERSHIP_RESET,
  }),

  //Activate and Deactivate Bulk ApplyMembership
  activateApplyMembership: (data: any) => ({
    type: actionTypes.ACTIVATE_APPLY_MEMBERSHIP_REQUEST,
    payload: {data},
  }),
  deactivateApplyMembership: (data: any) => ({
    type: actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_REQUEST,
    payload: {data},
  }),

  //Single Activate and Deactivate ApplyMembership
  singleActivateApplyMembership: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_REQUEST,
    payload: {data},
  }),

  singleDeactivateApplyMembership: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_REQUEST,
    payload: {data},
  }),

  // update ApplyMembership
  updateApplyMembership: (data: any, id: string) => ({
    type: actionTypes.UPDATE_APPLY_MEMBERSHIP_START,
    payload: {data, id},
  }),

  //delete and reset ApplyMembership
  deleteApplyMembership: (data: DeleteApplyMembershipModel[]) => ({
    type: actionTypes.DELETE_APPLY_MEMBERSHIP_START,
    payload: {applyMembershipId: data},
  }),
}
