import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getGeneralApplicationsSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getGeneralApplications, params)
    yield put(actions.getGeneralApplicationsSuccess(response?.data?.data))
    yield put(actions.getGeneralApplicationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getGeneralApplicationsFinish())
  }
}

//Get file
function* getGeneralApplicationFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getGeneralApplicationFile, fileName)
    const data: any = response?.data
    yield put(actions.getGeneralApplicationFileSuccess(data))
    yield put(actions.getGeneralApplicationFileError())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getGeneralApplicationFileError())
  }
}

function* updateGeneralApplicationsSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateGeneralApplications,
      body,
      action.payload?.id
    )
    yield put(actions.updateGeneralApplicationsSuccess(response.data?.data))
    yield put(actions.updateGeneralApplicationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateGeneralApplicationsFinish())
  }
}

function* deleteGeneralApplicationsSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteGeneralApplications, body)
    yield put(actions.deleteGeneralApplicationsSuccess(response.data?.data))
    yield put(actions.deleteGeneralApplicationsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteGeneralApplicationsFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_GENERAL_APPLICATIONS_START, getGeneralApplicationsSaga)
  yield takeLatest(actionTypes.UPDATE_GENERAL_APPLICATIONS_START, updateGeneralApplicationsSaga)
  yield takeLatest(actionTypes.GET_GENERAL_APPLICATIONS_FILE_START, getGeneralApplicationFileSaga)
  yield takeLatest(actionTypes.DELETE_GENERAL_APPLICATIONS_START,deleteGeneralApplicationsSaga )
}
