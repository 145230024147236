import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getAllBenefitMaster: (id: string = '') => ({
    type: actionTypes.GET_ALL_BENEFIT_MASTER_START,
    payload: id,
  }),

  getAllBenefitMasterSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ALL_BENEFIT_MASTER_SUCCESS,
    payload: data,
  }),
  getAllBenefitMasterFinish: () => ({
    type: actionTypes.GET_ALL_BENEFIT_MASTER_FINISH,
  }),
  getAllBenefitMasterError: (error: unknown) => ({
    type: actionTypes.GET_ALL_BENEFIT_MASTER_FINISH,
    payload: {error},
  }),

  getSpecificBenefitMaster: (id: string = '') => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MASTER_START,
    payload: id,
  }),

  getSpecificBenefitMasterSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MASTER_SUCCESS,
    payload: data,
  }),
  getSpecificBenefitMasterFinish: () => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MASTER_FINISH,
  }),
  getSpecificBenefitMasterError: (error: unknown) => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MASTER_FINISH,
    payload: {error},
  }),

  getBenefitMaster: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BENEFIT_MASTER_START,
    payload: params,
  }),

  getBenefitMasterSuccess: (data: any) => ({
    type: actionTypes.GET_BENEFIT_MASTER_SUCCESS,
    payload: data,
  }),
  getBenefitMasterFinish: () => ({
    type: actionTypes.GET_BENEFIT_MASTER_FINISH,
  }),
  getBenefitMasterError: (error: unknown) => ({
    type: actionTypes.GET_BENEFIT_MASTER_FINISH,
    payload: {error},
  }),

  addBenefitMaster: (data: any) => ({
    type: actionTypes.ADD_BENEFIT_MASTER_START,
    payload: data,
  }),
  addBenefitMasterSuccess: (task: any) => ({
    type: actionTypes.ADD_BENEFIT_MASTER_SUCCESS,
    payload: task,
  }),
  addBenefitMasterFinish: () => ({
    type: actionTypes.ADD_BENEFIT_MASTER_FINISH,
  }),
  addBenefitMasterReset: () => ({
    type: actionTypes.ADD_BENEFIT_MASTER_RESET,
  }),

  updateBenefitMaster: (data: any, id: string) => ({
    type: actionTypes.UPDATE_BENEFIT_MASTER_START,
    payload: {data, id},
  }),
  updateBenefitMasterSuccess: (task: any) => ({
    type: actionTypes.UPDATE_BENEFIT_MASTER_SUCCESS,
    payload: task,
  }),
  updateBenefitMasterFinish: () => ({
    type: actionTypes.UPDATE_BENEFIT_MASTER_FINISH,
  }),
  updateBenefitMasterReset: () => ({
    type: actionTypes.UPDATE_BENEFIT_MASTER_RESET,
  }),

  deleteBenefitMaster: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_MASTER_START,
    payload: {benefitMasterId: data},
  }),
  deleteBenefitMasterSuccess: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_MASTER_SUCCESS,
    payload: data,
  }),
  deleteBenefitMasterFinish: () => ({
    type: actionTypes.DELETE_BENEFIT_MASTER_FINISH,
  }),
  activateBenefitMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MASTER_REQUEST,
    payload: {benefitMasterId: id},
  }),
  deactivateBenefitMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MASTER_REQUEST,
    payload: {benefitMasterId: id},
  }),
  singleActivateBenefitMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MASTER_REQUEST,
    payload: {benefitMasterId: [id]},
  }),
  singleDeactivateBenefitMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MASTER_REQUEST,
    payload: {benefitMasterId: [id]},
  }),

  sortBenefitMaster: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_MASTER_START,
    payload: data,
  }),
  sortBenefitMasterSuccess: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_MASTER_SUCCESS,
    payload: data,
  }),
  sortBenefitMasterFinish: () => ({
    type: actionTypes.SORT_BENEFIT_MASTER_FINISH,
  }),
  sortBenefitMasterReset: () => ({
    type: actionTypes.SORT_BENEFIT_MASTER_RESET,
  }),
}
