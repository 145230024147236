import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteInternshipApplicationModel, InternshipApplicationModal} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getInternshipApplications: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_INTERNSHIP_APPLICATIONS_START,
    payload: params,
  }),

  getInternshipApplicationsSuccess: (data: any) => ({
    type: actionTypes.GET_INTERNSHIP_APPLICATIONS_SUCCESS,
    payload: data,
  }),
  getInternshipApplicationsFinish: () => ({
    type: actionTypes.GET_INTERNSHIP_APPLICATIONS_FINISH,
  }),
  getInternshipApplicationsError: (error: unknown) => ({
    type: actionTypes.GET_INTERNSHIP_APPLICATIONS_FINISH,
    payload: {error},
  }),

  updateInternshipApplications: (data: any, id: string) => ({
    type: actionTypes.UPDATE_INTERNSHIP_APPLICATIONS_START,
    payload: {data, id},
  }),
  updateInternshipApplicationsSuccess: (task: any) => ({
    type: actionTypes.UPDATE_INTERNSHIP_APPLICATIONS_SUCCESS,
    payload: task,
  }),
  updateInternshipApplicationsFinish: () => ({
    type: actionTypes.UPDATE_INTERNSHIP_APPLICATIONS_FINISH,
  }),
  updateInternshipApplicationsReset: () => ({
    type: actionTypes.UPDATE_INTERNSHIP_APPLICATIONS_RESET,
  }),

  deleteInternshipApplications: (data: DeleteInternshipApplicationModel[]) => ({
    type: actionTypes.DELETE_INTERNSHIP_APPLICATIONS_START,
    payload: {internshipApplicationId: data},
  }),
  deleteInternshipApplicationsSuccess: (data: any) => ({
    type: actionTypes.DELETE_INTERNSHIP_APPLICATIONS_SUCCESS,
    payload: data,
  }),
  deleteInternshipApplicationsFinish: () => ({
    type: actionTypes.DELETE_INTERNSHIP_APPLICATIONS_FINISH,
  }),

  //Get csv file
  getInternshipApplicationFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_INTERNSHIP_APPLICATIONS_FILE_START,
      payload: fileType,
    }
  },

  getInternshipApplicationFileSuccess: (data:any) => ({
    type: actionTypes.GET_INTERNSHIP_APPLICATIONS_FILE_SUCCESS,
    payload: data,
  }),
  getInternshipApplicationFileError: () => ({
    type: actionTypes.GET_INTERNSHIP_APPLICATIONS_FILE_FINISH,
  }),
}
