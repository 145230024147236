import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCollegeDetailModel, OptionModel, SortCollegeDetailModel} from '../Model'
import {CollegeDetailModel} from '../Model/CollegeDetailModel'
import {actionTypes} from './constants'
export const actions = {
  // get CollegeDetail
  getAllCollegeDetail: () => ({
    type: actionTypes.GET_ALL_COLLEGE_DETAIL_START,
  }),
  getAllCollegeDetailSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_ALL_COLLEGE_DETAIL_SUCCESS,
    payload: data,
  }),
  getAllCollegeDetailFinish: () => ({
    type: actionTypes.GET_ALL_COLLEGE_DETAIL_FINISH,
  }),

  // get CollegeDetail DATA
  getCollegeDetail: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_COLLEGE_DETAIL_START,
    payload: params,
  }),
  getCollegeDetailSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_COLLEGE_DETAIL_SUCCESS,
    payload: data,
  }),
  getCollegeDetailFinish: () => ({
    type: actionTypes.GET_COLLEGE_DETAIL_FINISH,
  }),

  // create key
  addCollegeDetail: (data: any) => ({
    type: actionTypes.ADD_COLLEGE_DETAIL_START,
    payload: data,
  }),
  addCollegeDetailSuccess: (task: any) => ({
    type: actionTypes.ADD_COLLEGE_DETAIL_SUCCESS,
    payload: task,
  }),
  addCollegeDetailFinish: () => ({
    type: actionTypes.ADD_COLLEGE_DETAIL_FINISH,
  }),
  resetCollegeDetail: () => ({
    type: actionTypes.RESET_COLLEGE_DETAIL,
  }),

  //Update CollegeDetail
  updateCollegeDetail: (data: any, id: string) => ({
    type: actionTypes.UPDATE_COLLEGE_DETAIL_START,
    payload: data,
    id,
  }),

  updateCollegeDetailSuccess: (data: CollegeDetailModel) => ({
    type: actionTypes.UPDATE_COLLEGE_DETAIL_SUCCESS,
    payload: data,
  }),

  updateCollegeDetailFinish: () => ({
    type: actionTypes.UPDATE_COLLEGE_DETAIL_FINISH,
  }),

  // delete key
  deleteCollegeDetail: (data: DeleteCollegeDetailModel[]) => ({
    type: actionTypes.DELETE_COLLEGE_DETAIL_START,
    payload: {aboutcollege: data},
  }),
  deleteCollegeDetailSuccess: (data: any) => ({
    type: actionTypes.DELETE_COLLEGE_DETAIL_SUCCESS,
    payload: data,
  }),
  deleteCollegeDetailFinish: () => ({
    type: actionTypes.DELETE_COLLEGE_DETAIL_FINISH,
  }),

  //Enable CollegeDetail
  enableCollegeDetail: (data: any) => ({
    type: actionTypes.ENABLE_COLLEGE_DETAIL_REQUEST,
    payload: {data},
  }),

  enableCollegeDetailSuccess: (task: any) => ({
    type: actionTypes.ENABLE_COLLEGE_DETAIL_SUCCESS,
    payload: task,
  }),
  enableCollegeDetailFinish: () => ({
    type: actionTypes.ENABLE_COLLEGE_DETAIL_FINISH,
  }),

  //Disable CollegeDetail
  disableCollegeDetail: (data: any) => ({
    type: actionTypes.DISABLE_COLLEGE_DETAIL_REQUEST,
    payload: {data},
  }),

  disableCollegeDetailSuccess: (task: any) => ({
    type: actionTypes.DISABLE_COLLEGE_DETAIL_SUCCESS,
    payload: task,
  }),
  disableCollegeDetailFinish: () => ({
    type: actionTypes.DISABLE_COLLEGE_DETAIL_FINISH,
  }),

  //Enable CollegeDetail
  singleEnableCollegeDetail: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_COLLEGE_DETAIL_REQUEST,
    payload: {data},
  }),

  singleEnableCollegeDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_COLLEGE_DETAIL_SUCCESS,
    payload: task,
  }),
  singleEnableCollegeDetailFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_COLLEGE_DETAIL_FINISH,
  }),

  //Disable CollegeDetail
  singleDisableCollegeDetail: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_COLLEGE_DETAIL_REQUEST,
    payload: {data},
  }),

  singleDisableCollegeDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_COLLEGE_DETAIL_SUCCESS,
    payload: task,
  }),
  singleDisableCollegeDetailFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_COLLEGE_DETAIL_FINISH,
  }),
  // sort
  sortCollegeDetail: (data: SortCollegeDetailModel) => ({
    type: actionTypes.SORT_COLLEGE_DETAIL_START,
    payload: data,
  }),
  sortCollegeDetailSuccess: (data: Array<CollegeDetailModel>) => ({
    type: actionTypes.SORT_COLLEGE_DETAIL_SUCCESS,
    payload: data,
  }),
  sortCollegeDetailFinish: () => ({
    type: actionTypes.SORT_COLLEGE_DETAIL_FINISH,
  }),
  sortCollegeDetailReset: () => ({
    type: actionTypes.SORT_COLLEGE_DETAIL_RESET,
  }),

  // get Type Option
  getAboutTypeOption: () => {
    return {
      type: actionTypes.GET_ABOUT_TYPE_OPTION_START,
    }
  },
  getAboutTypeOptionSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_ABOUT_TYPE_OPTION_SUCCESS,
    payload: data,
  }),
  getAboutTypeOptionError: () => ({
    type: actionTypes.GET_ABOUT_TYPE_OPTION_FINISH,
  }),
}
