export const actionTypes = {
  GET_INTRODUCTORY_START: 'GET_INTRODUCTORY_START',
  GET_INTRODUCTORY_SUCCESS: 'GET_INTRODUCTORY_SUCCESS',
  GET_INTRODUCTORY_FINISH: 'GET_INTRODUCTORY_FINISH',

  ADD_INTRODUCTORY_START: 'ADD_INTRODUCTORY_START',
  ADD_INTRODUCTORY_SUCCESS: 'ADD_INTRODUCTORY_SUCCESS',
  ADD_INTRODUCTORY_FINISH: 'ADD_INTRODUCTORY_FINISH',
  ADD_INTRODUCTORY_RESET: 'ADD_INTRODUCTORY_RESET',

  UPDATE_INTRODUCTORY_START: 'UPDATE_INTRODUCTORY_START',
  UPDATE_INTRODUCTORY_SUCCESS: 'UPDATE_INTRODUCTORY_SUCCESS',
  UPDATE_INTRODUCTORY_FINISH: 'UPDATE_INTRODUCTORY_FINISH',
  UPDATE_INTRODUCTORY_RESET: 'UPDATE_INTRODUCTORY_RESET',

  DELETE_INTRODUCTORY_START: 'DELETE_INTRODUCTORY_START',
  DELETE_INTRODUCTORY_SUCCESS: 'DELETE_INTRODUCTORY_SUCCESS',
  DELETE_INTRODUCTORY_FINISH: 'DELETE_INTRODUCTORY_FINISH',

  SINGLE_ACTIVATE_INTRODUCTORY_REQUEST: 'SINGLE_ACTIVATE_INTRODUCTORY_REQUEST',
  SINGLE_ACTIVATE_INTRODUCTORY_SUCCESS: 'SINGLE_ACTIVATE_INTRODUCTORY_SUCCESS',
  SINGLE_ACTIVATE_INTRODUCTORY_FINISH: 'SINGLE_ACTIVATE_INTRODUCTORY_FINISH',

  SINGLE_DEACTIVATE_INTRODUCTORY_REQUEST: 'SINGLE_DEACTIVATE_INTRODUCTORY_REQUEST',
  SINGLE_DEACTIVATE_INTRODUCTORY_SUCCESS: 'SINGLE_DEACTIVATE_INTRODUCTORY_SUCCESS',
  SINGLE_DEACTIVATE_INTRODUCTORY_FINISH: 'SINGLE_DEACTIVATE_INTRODUCTORY_FINISH',

  SORT_INTRODUCTORY_START: 'SORT_INTRODUCTORY_START',
  SORT_INTRODUCTORY_SUCCESS: 'SORT_INTRODUCTORY_SUCCESS',
  SORT_INTRODUCTORY_FINISH: 'SORT_INTRODUCTORY_FINISH',
  SORT_INTRODUCTORY_RESET: 'SORT_INTRODUCTORY_RESET',
}
