import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getHistory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_HISTORY_START,
    payload: params,
  }),

  getHistorySuccess: (data: any) => ({
    type: actionTypes.GET_HISTORY_SUCCESS,
    payload: data,
  }),
  getHistoryFinish: () => ({
    type: actionTypes.GET_HISTORY_FINISH,
  }),
  getHistoryError: (error: unknown) => ({
    type: actionTypes.GET_HISTORY_FINISH,
    payload: {error},
  }),

  addHistory: (data: any) => ({
    type: actionTypes.ADD_HISTORY_START,
    payload: data,
  }),
  addHistorySuccess: (task: any) => ({
    type: actionTypes.ADD_HISTORY_SUCCESS,
    payload: task,
  }),
  addHistoryFinish: () => ({
    type: actionTypes.ADD_HISTORY_FINISH,
  }),
  addHistoryReset: () => ({
    type: actionTypes.ADD_HISTORY_RESET,
  }),

  updateHistory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_HISTORY_START,
    payload: {data, id},
  }),
  updateHistorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_HISTORY_SUCCESS,
    payload: task,
  }),
  updateHistoryFinish: () => ({
    type: actionTypes.UPDATE_HISTORY_FINISH,
  }),
  updateHistoryReset: () => ({
    type: actionTypes.UPDATE_HISTORY_RESET,
  }),

  deleteHistory: (data: any) => ({
    type: actionTypes.DELETE_HISTORY_START,
    payload: {historyId: data},
  }),
  deleteHistorySuccess: (data: any) => ({
    type: actionTypes.DELETE_HISTORY_SUCCESS,
    payload: data,
  }),
  deleteHistoryFinish: () => ({
    type: actionTypes.DELETE_HISTORY_FINISH,
  }),
  activateHistory: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_HISTORY_REQUEST,
    payload: {historyId: id},
  }),
  deactivateHistory: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_HISTORY_REQUEST,
    payload: {historyId: id},
  }),
  singleActivateHistory: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_HISTORY_REQUEST,
    payload: {historyId: [id]},
  }),
  singleDeactivateHistory: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_HISTORY_REQUEST,
    payload: {historyId: [id]},
  }),

  sortHistory: (data: any) => ({
    type: actionTypes.SORT_HISTORY_START,
    payload: data,
  }),
  sortHistorySuccess: (data: any) => ({
    type: actionTypes.SORT_HISTORY_SUCCESS,
    payload: data,
  }),
  sortHistoryFinish: () => ({
    type: actionTypes.SORT_HISTORY_FINISH,
  }),
  sortHistoryReset: () => ({
    type: actionTypes.SORT_HISTORY_RESET,
  }),

  // get FiscalYear AD DATA
  getFiscalYearAD: () => ({
    type: actionTypes.GET_FISCAL_YEAR_AD_START,
  }),
  getFiscalYearADSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_FISCAL_YEAR_AD_SUCCESS,
    payload: data,
  }),
  getFiscalYearADFinish: () => ({
    type: actionTypes.GET_FISCAL_YEAR_AD_FINISH,
  }),
}
