import { FC, useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import { LayoutSplashScreen } from '../../../../cms/layout/core'
import * as auth from './actions'
import { RootState } from '../../../../setup'
import { service } from './service'
import { isEmpty } from 'lodash'
import { ISettingTypeState } from '../../siteSettings/components/settings'
import { imageBaseUrl } from 'src/cms/helpers/constants'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { accessToken, guestToken }: any = useSelector<RootState>(({ auth }) => auth, shallowEqual)
  const [splashLogo, setSplashLogo] = useState('')
  const [splashLogoAlt, setSplashLogoAlt] = useState('')
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  const splashScreen = document.getElementById('splash-screen')

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Theme settings']?.map((item: any) => {
          if (item?.name === 'loadingImage') {
            setSplashLogo(item?.value)
            setSplashLogoAlt(item?.name)
          }
        })
    }
  }, [settingTypeData])
  useEffect(() => {
    if (!isEmpty(splashLogo)) {
      let img = document.createElement('img')
      img.setAttribute('src', `${imageBaseUrl}/${splashLogo}`)
      img.setAttribute('alt', `${splashLogoAlt}`)
      img.setAttribute('class', 'h-30px')

      splashScreen?.appendChild(img)
    }
  }, [splashLogo])

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await service.getUserByToken()
          dispatch(props.fulfillUser(user))
        }
      } catch (error: any) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    if (accessToken) {
      requestUser()
    } else {
      // if (!guestToken) {
      //   dispatch(props?.getGuestToken())
      // }
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
