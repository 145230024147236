import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialDisputeState: ISocialMediaManagerState = {
  data: {
    customer: [],
    meta: {},
  },
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface ISocialMediaManagerState {
  data?: {
    customer?: {[key: string]: number | string}[]
    meta?: {[key: string]: number}
  }
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ISocialMediaManagerState = initialDisputeState,
  action: ActionWithPayload<ISocialMediaManagerState>
) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_LOGIN_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_CUSTOMER_LOGIN_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_CUSTOMER_LOGIN_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_CUSTOMER_LOGIN: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.DELETE_CUSTOMER_LOGIN_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_CUSTOMER_LOGIN_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_CUSTOMER_LOGIN_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.ACTIVATE_CUSTOMER_LOGIN_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ACTIVATE_CUSTOMER_LOGIN_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.ACTIVATE_CUSTOMER_LOGIN_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.DEACTIVATE_CUSTOMER_LOGIN_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DEACTIVATE_CUSTOMER_LOGIN_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.DEACTIVATE_CUSTOMER_LOGIN_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    default:
      return state
  }
}
