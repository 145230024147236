import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getIntroductory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_INTRODUCTORY_START,
    payload: params,
  }),

  getIntroductorySuccess: (data: any) => ({
    type: actionTypes.GET_INTRODUCTORY_SUCCESS,
    payload: data,
  }),
  getIntroductoryFinish: () => ({
    type: actionTypes.GET_INTRODUCTORY_FINISH,
  }),
  getIntroductoryError: (error: unknown) => ({
    type: actionTypes.GET_INTRODUCTORY_FINISH,
    payload: {error},
  }),

  addIntroductory: (data: any) => ({
    type: actionTypes.ADD_INTRODUCTORY_START,
    payload: data,
  }),
  addIntroductorySuccess: (task: any) => ({
    type: actionTypes.ADD_INTRODUCTORY_SUCCESS,
    payload: task,
  }),
  addIntroductoryFinish: () => ({
    type: actionTypes.ADD_INTRODUCTORY_FINISH,
  }),
  addIntroductoryReset: () => ({
    type: actionTypes.ADD_INTRODUCTORY_RESET,
  }),

  updateIntroductory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_INTRODUCTORY_START,
    payload: {data, id},
  }),
  updateIntroductorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_INTRODUCTORY_SUCCESS,
    payload: task,
  }),
  updateIntroductoryFinish: () => ({
    type: actionTypes.UPDATE_INTRODUCTORY_FINISH,
  }),
  updateIntroductoryReset: () => ({
    type: actionTypes.UPDATE_INTRODUCTORY_RESET,
  }),

  deleteIntroductory: (data: any) => ({
    type: actionTypes.DELETE_INTRODUCTORY_START,
    payload: {introductoryId: data},
  }),
  deleteIntroductorySuccess: (data: any) => ({
    type: actionTypes.DELETE_INTRODUCTORY_SUCCESS,
    payload: data,
  }),
  deleteIntroductoryFinish: () => ({
    type: actionTypes.DELETE_INTRODUCTORY_FINISH,
  }),
  activateIntroductory: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_INTRODUCTORY_REQUEST,
    payload: {introductoryId: id},
  }),
  deactivateIntroductory: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_INTRODUCTORY_REQUEST,
    payload: {introductoryId: id},
  }),
  singleActivateIntroductory: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_INTRODUCTORY_REQUEST,
    payload: {introductoryId: [id]},
  }),
  singleDeactivateIntroductory: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_INTRODUCTORY_REQUEST,
    payload: {introductoryId: [id]},
  }),

  sortIntroductory: (data: any) => ({
    type: actionTypes.SORT_INTRODUCTORY_START,
    payload: data,
  }),
  sortIntroductorySuccess: (data: any) => ({
    type: actionTypes.SORT_INTRODUCTORY_SUCCESS,
    payload: data,
  }),
  sortIntroductoryFinish: () => ({
    type: actionTypes.SORT_INTRODUCTORY_FINISH,
  }),
  sortIntroductoryReset: () => ({
    type: actionTypes.SORT_INTRODUCTORY_RESET,
  }),
}
