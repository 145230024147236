export const actionTypes = {
  GET_LOAN_TENURE_START: 'GET_LOAN_TENURE_START',
  GET_LOAN_TENURE_SUCCESS: 'GET_LOAN_TENURE_SUCCESS',
  GET_LOAN_TENURE_FINISH: 'GET_LOAN_TENURE_FINISH',

  ADD_LOAN_TENURE_START: 'ADD_LOAN_TENURE_START',
  ADD_LOAN_TENURE_SUCCESS: 'ADD_LOAN_TENURE_SUCCESS',
  ADD_LOAN_TENURE_FINISH: 'ADD_LOAN_TENURE_FINISH',
  ADD_LOAN_TENURE_RESET: 'ADD_LOAN_TENURE_RESET',

  UPDATE_LOAN_TENURE_START: 'UPDATE_LOAN_TENURE_START',
  UPDATE_LOAN_TENURE_SUCCESS: 'UPDATE_LOAN_TENURE_SUCCESS',
  UPDATE_LOAN_TENURE_FINISH: 'UPDATE_LOAN_TENURE_FINISH',
  UPDATE_LOAN_TENURE_RESET: 'UPDATE_LOAN_TENURE_RESET',

  DELETE_LOAN_TENURE_START: 'DELETE_LOAN_TENURE_START',
  DELETE_LOAN_TENURE_SUCCESS: 'DELETE_LOAN_TENURE_SUCCESS',
  DELETE_LOAN_TENURE_FINISH: 'DELETE_LOAN_TENURE_FINISH',

  SINGLE_ACTIVATE_LOAN_TENURE_REQUEST: 'SINGLE_ACTIVATE_LOAN_TENURE_REQUEST',
  SINGLE_ACTIVATE_LOAN_TENURE_SUCCESS: 'SINGLE_ACTIVATE_LOAN_TENURE_SUCCESS',
  SINGLE_ACTIVATE_LOAN_TENURE_FINISH: 'SINGLE_ACTIVATE_LOAN_TENURE_FINISH',

  SINGLE_DEACTIVATE_LOAN_TENURE_REQUEST: 'SINGLE_DEACTIVATE_LOAN_TENURE_REQUEST',
  SINGLE_DEACTIVATE_LOAN_TENURE_SUCCESS: 'SINGLE_DEACTIVATE_LOAN_TENURE_SUCCESS',
  SINGLE_DEACTIVATE_LOAN_TENURE_FINISH: 'SINGLE_DEACTIVATE_LOAN_TENURE_FINISH',

  SORT_LOAN_TENURE_START: 'SORT_LOAN_TENURE_START',
  SORT_LOAN_TENURE_SUCCESS: 'SORT_LOAN_TENURE_SUCCESS',
  SORT_LOAN_TENURE_FINISH: 'SORT_LOAN_TENURE_FINISH',
  SORT_LOAN_TENURE_RESET: 'SORT_LOAN_TENURE_RESET',
}
