import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getAuctionEnquirySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAuctionEnquiry, params)
    yield put(actions.getAuctionEnquirySuccess(response?.data?.data))
    yield put(actions.getAuctionEnquiryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getAuctionEnquiryFinish())
  }
}

function* putViewAuctionEnquirySaga(action: ActionModel) {
  try {
    const {auctionEnquiryId} = action.payload
    console.log(action.payload)
    yield delay(500)
    const response: ResponseModel = yield call(service.putViewAuctionEnquiry, auctionEnquiryId)
    yield put(actions.putViewAuctionEnquirySuccess(response?.data?.data))
    console.log(response?.data?.data)
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
  } finally {
    yield put(actions.putViewAuctionEnquiryFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_AUCTION_ENQUIRY_START, getAuctionEnquirySaga)
  yield takeLatest(actionTypes.PUT_VIEW_AUCTION_ENQUIRY_START, putViewAuctionEnquirySaga)
}
