import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteServiceTypeModel} from '../Model'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'
import {actionTypes} from './constants'
export const actions = {
  // get ServiceType
  getAllSubscribeType: (params: {} = {}) => ({
    type: actionTypes.GET_ALL_SUBSCRIBE_TYPE_START,
    payload: params,
  }),
  getAllSubscribeTypeSuccess: (data: ServiceTypeModel) => ({
    type: actionTypes.GET_ALL_SUBSCRIBE_TYPE_SUCCESS,
    payload: data,
  }),
  getAllSubscribeTypeFinish: () => ({
    type: actionTypes.GET_ALL_SUBSCRIBE_TYPE_FINISH,
  }),

  // get ServiceType DATA
  getSubscribeType: (params: ParamsModel | {status: number} = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_SUBSCRIBE_TYPE_START,
    payload: params,
  }),
  getSubscribeTypeSuccess: (data: ServiceTypeModel) => ({
    type: actionTypes.GET_SUBSCRIBE_TYPE_SUCCESS,
    payload: data,
  }),
  getSubscribeTypeFinish: () => ({
    type: actionTypes.GET_SUBSCRIBE_TYPE_FINISH,
  }),

  // create key
  addSubscribeType: (data: ServiceTypeModel) => ({
    type: actionTypes.ADD_SUBSCRIBE_TYPE_START,
    payload: data,
  }),
  addSubscribeTypeSuccess: (task: any) => ({
    type: actionTypes.ADD_SUBSCRIBE_TYPE_SUCCESS,
    payload: task,
  }),
  addSubscribeTypeFinish: () => ({
    type: actionTypes.ADD_SUBSCRIBE_TYPE_FINISH,
  }),
  resetSubscribeType: () => ({
    type: actionTypes.RESET_SUBSCRIBE_TYPE,
  }),

  //Update ServiceType
  updateSubscribeType: (data: ServiceTypeModel, id: string) => ({
    type: actionTypes.UPDATE_SUBSCRIBE_TYPE_START,
    payload: data,
    id,
  }),

  updateSubscribeTypeSuccess: (data: ServiceTypeModel) => ({
    type: actionTypes.UPDATE_SUBSCRIBE_TYPE_SUCCESS,
    payload: data,
  }),

  updateSubscribeTypeFinish: () => ({
    type: actionTypes.UPDATE_SUBSCRIBE_TYPE_FINISH,
  }),

  // delete key
  deleteSubscribeType: (data: DeleteServiceTypeModel[]) => ({
    type: actionTypes.DELETE_SUBSCRIBE_TYPE_START,
    payload: {subscribeTypeId: data},
  }),
  deleteSubscribeTypeSuccess: (data: any) => ({
    type: actionTypes.DELETE_SUBSCRIBE_TYPE_SUCCESS,
    payload: data,
  }),
  deleteSubscribeTypeFinish: () => ({
    type: actionTypes.DELETE_SUBSCRIBE_TYPE_FINISH,
  }),

  //Enable ServiceType
  enableSubscribeType: (data: any) => ({
    type: actionTypes.ENABLE_SUBSCRIBE_TYPE_REQUEST,
    payload: {data},
  }),

  enableSubscribeTypeSuccess: (task: any) => ({
    type: actionTypes.ENABLE_SUBSCRIBE_TYPE_SUCCESS,
    payload: task,
  }),
  enableSubscribeTypeFinish: () => ({
    type: actionTypes.ENABLE_SUBSCRIBE_TYPE_FINISH,
  }),

  //Disable ServiceType
  disableSubscribeType: (data: any) => ({
    type: actionTypes.DISABLE_SUBSCRIBE_TYPE_REQUEST,
    payload: {data},
  }),

  disableSubscribeTypeSuccess: (task: any) => ({
    type: actionTypes.DISABLE_SUBSCRIBE_TYPE_SUCCESS,
    payload: task,
  }),
  disableSubscribeTypeFinish: () => ({
    type: actionTypes.DISABLE_SUBSCRIBE_TYPE_FINISH,
  }),

  //Enable ServiceType
  singleEnableSubscribeType: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_SUBSCRIBE_TYPE_REQUEST,
    payload: {data},
  }),

  singleEnableSubscribeTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_SUBSCRIBE_TYPE_SUCCESS,
    payload: task,
  }),
  singleEnableSubscribeTypeFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_SUBSCRIBE_TYPE_FINISH,
  }),

  //Disable ServiceType
  singleDisableSubscribeType: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_SUBSCRIBE_TYPE_REQUEST,
    payload: {data},
  }),

  singleDisableSubscribeTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_SUBSCRIBE_TYPE_SUCCESS,
    payload: task,
  }),
  singleDisableSubscribeTypeFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_SUBSCRIBE_TYPE_FINISH,
  }),
}
