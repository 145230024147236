import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {ITisaForexRateCategoryState} from './reducer'

export const actions = {
  getTisaForexRateCategory: (params: ParamsModel = {page: 1, limit: 100}) => {
    return {
      type: actionTypes.GET_TISA_FOREX_RATE_CATEGORY_START,
      payload: {params},
    }
  },

  getTisaForexRateCategorySuccess: (data: ITisaForexRateCategoryState) => ({
    type: actionTypes.GET_TISA_FOREX_RATE_CATEGORY_SUCCESS,
    payload: data,
  }),
  getTisaForexRateCategoryError: (data: ITisaForexRateCategoryState) => ({
    type: actionTypes.GET_TISA_FOREX_RATE_CATEGORY_FINISH,
    payload: data,
  }),
}
