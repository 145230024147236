import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteFeaturedModel, FeaturedModel, SortFeaturedModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_FEATURED = `${API_URL}/featured-image`
export const DELETE_FEATURED = `${API_URL}/featured-image/bulk-delete`
export const GET_FEATURED_FILE_TYPE = `${API_URL}/featured-file-type-option`
export const GET_FEATURED_IMAGE_TYPE = `${API_URL}/featured-image/type-option`

export const service = {
  getFeatured: (params: ParamsModel) => {
    return axios.get(GET_FEATURED, {params})
  },
  getFeaturedFileType: () => {
    return axios.get(GET_FEATURED_FILE_TYPE)
  },
  
  getFeaturedImageType: () => {
    return axios.get(GET_FEATURED_IMAGE_TYPE)
  },

  addFeatured: (data: any) => {
    return axios.post(GET_FEATURED, data)
  },

  updateFeatured: (body: FeaturedModel, id: string) => {
    return axios.put(`${GET_FEATURED}/${id}`, body)
  },

  deleteFeatured: (data: DeleteFeaturedModel) => {
    return axios.delete(DELETE_FEATURED, {data})
  },

  enableFeatured: (data: Array<string>) => {
    const selectedFeatured = {
      featuredImageId: data,
    }
    return axios.patch(`${GET_FEATURED}/enable`, selectedFeatured)
  },

  disableFeatured: (data: Array<string>) => {
    const selectedFeatured = {
      featuredImageId: data,
    }
    return axios.patch(`${GET_FEATURED}/disable`, selectedFeatured)
  },

  singleEnableFeatured: (data: Array<string>) => {
    const selectedFeatured = {
      featuredImageId: [data],
    }
    return axios.patch(`${GET_FEATURED}/enable`, selectedFeatured)
  },

  singleDisableFeatured: (data: Array<string>) => {
    const selectedFeatured = {
      featuredImageId: [data],
    }
    return axios.patch(`${GET_FEATURED}/disable`, selectedFeatured)
  },

  getAllFeatured: () => {
    return axios.get(`${GET_FEATURED}/list`)
  },
  sortFeatured: (body: SortFeaturedModel) => {
    return axios.patch(`${GET_FEATURED}/sort`, body)
  },
}
