import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// rsuite
import Table from 'rsuite/Table'

// includes
import { isEmpty } from 'lodash'
import { SortType } from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import { StateParamsModel } from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as departments from '../../../../userMgmt/components/departments/index'
import * as leadFormRedux from '../index'

const ProgramLeadFormComponent = () => {
  const dispatch = useDispatch()
  const {
    data: { leadForm, meta },
    success,
    loading,
  } = useSelector((state: any) => state.leadFormState)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    modelType: `Amnil\\SharedModels\\Program`,
  })

  useEffect(() => {
    dispatch(leadFormRedux.actions.getLeadFormDetail(params))
  }, [params])

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.name) ? rowData?.name : '-')}</Cell>,
      sortable: true,
    },

    {
      label: 'Email Address',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (!isEmpty(rowData?.email) ? rowData?.email : '-')}</Cell>,

      sortable: false,
    },
    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      flexGrow: 1,
      cell: (
        <Cell>{(rowData) => (!isEmpty(rowData?.mobileNumber) ? rowData?.mobileNumber : '-')}</Cell>
      ),

      sortable: false,
    },
    {
      label: 'Program Title',
      dataKey: 'programTitle',
      flexGrow: 1,
      cell: (
        <Cell>{(rowData) => (!isEmpty(rowData?.programTitle) ? rowData?.programTitle : '-')}</Cell>
      ),

      sortable: false,
    },
  ]

  const programLeadData = !isEmpty(leadForm)
    ? leadForm?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item.id,
      name: item.name,
      email: item.email,
      mobileNumber: item.mobileNumber,
      programTitle: item?.leadData?.title,
    }))
    : []

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  useEffect(() => {
    handleChecked([])
    dispatch(leadFormRedux?.actions.getLeadFormDetail(params))
    dispatch(departments.actions.getDepartment())
  }, [success])

  const handleExport = () => {
    dispatch(leadFormRedux.actions.exportFile('Program Lead Data', params))
  }
  const handleRefresh = () => {
    dispatch(leadFormRedux.actions.getLeadFormDetail(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Program Lead Form'
          params={params}
          setParams={setParams}
          filterData={true}
          handleExport={handleExport}
          handleRefresh={handleRefresh}
          addShow={false}
          deleteShow={false}
          importShow={false}
          toggleMultipleShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            showLoading={loading}
            data={programLeadData}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </>
  )
}

export default ProgramLeadFormComponent
