export const actionTypes = {
  IMPORT_EXPERIENCE_MANAGER_START: 'IMPORT_EXPERIENCE_MANAGER_START',
  IMPORT_EXPERIENCE_MANAGER_SUCCESS: 'IMPORT_EXPERIENCE_MANAGER_SUCCESS',
  IMPORT_EXPERIENCE_MANAGER_FINISH: 'IMPORT_EXPERIENCE_MANAGER_FINISH',

  GET_EXPERIENCE_MANAGER_START: 'GET_EXPERIENCE_MANAGER_START',
  GET_EXPERIENCE_MANAGER_SUCCESS: 'GET_EXPERIENCE_MANAGER_SUCCESS',
  GET_EXPERIENCE_MANAGER_FINISH: 'GET_EXPERIENCE_MANAGER_FINISH',

  ADD_EXPERIENCE_MANAGER_START: 'ADD_EXPERIENCE_MANAGER_START',
  ADD_EXPERIENCE_MANAGER_SUCCESS: 'ADD_EXPERIENCE_MANAGER_SUCCESS',
  ADD_EXPERIENCE_MANAGER_FINISH: 'ADD_EXPERIENCE_MANAGER_FINISH',
  ADD_EXPERIENCE_MANAGER_RESET: 'ADD_EXPERIENCE_MANAGER_RESET',

  UPDATE_EXPERIENCE_MANAGER_START: 'UPDATE_EXPERIENCE_MANAGER_START',
  UPDATE_EXPERIENCE_MANAGER_SUCCESS: 'UPDATE_EXPERIENCE_MANAGER_SUCCESS',
  UPDATE_EXPERIENCE_MANAGER_FINISH: 'UPDATE_EXPERIENCE_MANAGER_FINISH',
  UPDATE_EXPERIENCE_MANAGER_RESET: 'UPDATE_EXPERIENCE_MANAGER_RESET',

  DELETE_EXPERIENCE_MANAGER_START: 'DELETE_EXPERIENCE_MANAGER_START',
  DELETE_EXPERIENCE_MANAGER_SUCCESS: 'DELETE_EXPERIENCE_MANAGER_SUCCESS',
  DELETE_EXPERIENCE_MANAGER_FINISH: 'DELETE_EXPERIENCE_MANAGER_FINISH',

  SINGLE_ACTIVATE_EXPERIENCE_MANAGER_REQUEST: 'SINGLE_ACTIVATE_EXPERIENCE_MANAGER_REQUEST',
  SINGLE_ACTIVATE_EXPERIENCE_MANAGER_SUCCESS: 'SINGLE_ACTIVATE_EXPERIENCE_MANAGER_SUCCESS',
  SINGLE_ACTIVATE_EXPERIENCE_MANAGER_FINISH: 'SINGLE_ACTIVATE_EXPERIENCE_MANAGER_FINISH',

  SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_REQUEST: 'SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_REQUEST',
  SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_SUCCESS: 'SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_SUCCESS',
  SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_FINISH: 'SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_FINISH',

  SORT_EXPERIENCE_MANAGER_START: 'SORT_EXPERIENCE_MANAGER_START',
  SORT_EXPERIENCE_MANAGER_SUCCESS: 'SORT_EXPERIENCE_MANAGER_SUCCESS',
  SORT_EXPERIENCE_MANAGER_FINISH: 'SORT_EXPERIENCE_MANAGER_FINISH',
  SORT_EXPERIENCE_MANAGER_RESET: 'SORT_EXPERIENCE_MANAGER_RESET',
}
