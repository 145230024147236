export const actionTypes = {
  // get Text Popup
  GET_TEXT_POPUP_START: 'GET_TEXT_POPUP_START',
  GET_TEXT_POPUP_SUCCESS: 'GET_TEXT_POPUP_SUCCESS',
  GET_TEXT_POPUP_FINISH: 'GET_TEXT_POPUP_FINISH',

  //Add TextPopup
  ADD_TEXT_POPUP_REQUEST: 'ADD_TEXT_POPUP_REQUEST',
  ADD_TEXT_POPUP_SUCCESS: 'ADD_TEXT_POPUP_SUCCESS',
  ADD_TEXT_POPUP_FINISH: 'ADD_TEXT_POPUP_FINISH',
  ADD_TEXT_POPUP_RESET: 'ADD_TEXT_POPUP_RESET',

  // update TextPopup
  UPDATE_TEXT_POPUP_START: 'UPDATE_TEXT_POPUP_START',
  UPDATE_TEXT_POPUP_SUCCESS: 'UPDATE_TEXT_POPUP_SUCCESS',
  UPDATE_TEXT_POPUP_FINISH: 'UPDATE_TEXT_POPUP_FINISH',
  UPDATE_TEXT_POPUP_RESET: 'UPDATE_TEXT_POPUP_RESET',

  //Bulk Activate TextPopup
  ACTIVATE_TEXT_POPUP_REQUEST: 'ACTIVATE_TEXT_POPUP_REQUEST',
  ACTIVATE_TEXT_POPUP_SUCCESS: 'ACTIVATE_TEXT_POPUP_SUCCESS',
  ACTIVATE_TEXT_POPUP_FINISH: 'ACTIVATE_TEXT_POPUP_FINISH',

  //Bulk Deactivate TextPopup
  DEACTIVATE_TEXT_POPUP_REQUEST: 'DEACTIVATE_TEXT_POPUP_REQUEST',
  DEACTIVATE_TEXT_POPUP_SUCCESS: 'DEACTIVATE_TEXT_POPUP_SUCCESS',
  DEACTIVATE_TEXT_POPUP_FINISH: 'DEACTIVATE_TEXT_POPUP_FINISH',

  //Single Activate TextPopup
  SINGLE_ACTIVATE_TEXT_POPUP_REQUEST: 'SINGLE_ACTIVATE_TEXT_POPUP_REQUEST',
  SINGLE_ACTIVATE_TEXT_POPUP_SUCCESS: 'SINGLE_ACTIVATE_TEXT_POPUP_SUCCESS',
  SINGLE_ACTIVATE_TEXT_POPUP_FINISH: 'SINGLE_ACTIVATE_TEXT_POPUP_FINISH',

  //Single Deactivate TextPopup
  SINGLE_DEACTIVATE_TEXT_POPUP_REQUEST: 'SINGLE_DEACTIVATE_TEXT_POPUP_REQUEST',
  SINGLE_DEACTIVATE_TEXT_POPUP_SUCCESS: 'SINGLE_DEACTIVATE_TEXT_POPUP_SUCCESS',
  SINGLE_DEACTIVATE_TEXT_POPUP_FINISH: 'SINGLE_DEACTIVATE_TEXT_POPUP_FINISH',

  // delete TextPopup
  DELETE_TEXT_POPUP_START: 'DELETE_TEXT_POPUP_START',
  DELETE_TEXT_POPUP_SUCCESS: 'DELETE_TEXT_POPUP_SUCCESS',
  DELETE_TEXT_POPUP_FINISH: 'DELETE_TEXT_POPUP_FINISH',

  // sort
  SORT_TEXT_POPUP_START: 'SORT_TEXT_POPUP_START',
  SORT_TEXT_POPUP_SUCCESS: 'SORT_TEXT_POPUP_SUCCESS',
  SORT_TEXT_POPUP_FINISH: 'SORT_TEXT_POPUP_FINISH',
  SORT_TEXT_POPUP_RESET: 'SORT_TEXT_POPUP_RESET',
}
