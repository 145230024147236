export const COMMON_TYPES = {
  REQUEST: '_REQUEST',
  SUCCESS: '_SUCCESS',
  FAILURE: '_FAILURE',
  CALL_OVER: '_FINISHED',
}
export const CATEGORY_TYPES = {
  GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
  GET_ALL_CATEGORY: 'GET_ALL_CATEGORY',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  SINGLE_ACTIVATE_APPLICATION_CATEGORY: 'SINGLE_ACTIVATE_APPLICATION_CATEGORY',
  SINGLE_DEACTIVATE_APPLICATION_CATEGORY: 'SINGLE_DEACTIVATE_APPLICATION_CATEGORY',
  ACTIVATE_CATEGORY: 'ACTIVATE_CATEGORY',
  DEACTIVATE_CATEGORY: 'DEACTIVATE_CATEGORY',
  SORT_CATEGORY: 'SORT_CATEGORY',
  SORT_CATEGORY_RESET: 'SORT_CATEGORY_RESET',
}
