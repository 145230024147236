import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { toast } from 'react-toastify'
import { SortType } from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'

import { ItemType, StateParamsModel } from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import * as midMenu from '../index'

import { isEmpty } from 'lodash'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import AddMidMenuManager from './AddMidMenuManager'

const MidMenuManager = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    orderBy: '',
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const [openSortModal, setOpenSortModal] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const MidMenuData: any = useSelector((state: any) => state.productMidMenu)
  const {
    activateSuccess,
    deactivateSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    success,
    loading,
    sortMidMenuData,
  } = MidMenuData

  useEffect(() => {
    dispatch(midMenu.actions.getMidMenu(params))
  }, [params])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }
  const handleToggleAction = (data: { [key: string]: string }) => {
    const formData = { id: data?.id }
    data?.status
      ? dispatch(midMenu.actions.singleDeactivateMidMenu(formData))
      : dispatch(midMenu.actions.singleActivateMidMenu(formData))
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle size='sm' checked={rowData.status} onClick={() => handleToggleAction(rowData)} data-cy='toggle-button' />
        </Whisper>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Product Category (EN)',
      dataKey: 'productCategoryName',
      flexGrow: 1,
      cell: <Cell dataKey='productCategoryName' />,
      sortable: true,
    },
    {
      label: 'Product Category (NP)',
      dataKey: 'productCategoryNameNp',
      flexGrow: 1,
      cell: <Cell dataKey='productCategoryNameNp' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    if (MidMenuData?.deleteSuccess) {
      toast.success('Mid Menu Manager deleted successfully')
      dispatch(midMenu?.actions.getMidMenu(params))
    }
  }, [MidMenuData])

  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success('Mid Menu Manager activated successfully')
    }
    if (singleDeactivateSuccess) {
      toast.success('Mid Menu Manager deactivated successfully')
    }
    handleChecked([])
  }, [singleActivateSuccess, singleDeactivateSuccess])

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Mid Menu Manager activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Mid Menu Manager deactivated successfully')
    }
    handleChecked([])
    dispatch(midMenu?.actions.getMidMenu(params))
  }, [activateSuccess, deactivateSuccess])

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  const activateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(midMenu.actions.activateMidMenu(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(checkedValues)) {
      const formData: any = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(midMenu.actions.deactivateMidMenu(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleChecked = (values: any) => {
    setCheckedValues(values)
  }

  const data = MidMenuData?.data?.midMenu
    ? MidMenuData?.data?.midMenu?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item?.id,
      is_featured: item?.is_featured,
      productCategoryId: item?.productCategory?.id,
      productCategoryName: item?.productCategory?.name,
      productCategoryNameNp: item?.productCategory?.name_np,
      child: item?.child?.midMenuChild?.map((item: { [key: string]: string }) => ({
        id: item.id,
        title: item.title,
        title_np: item.title_np,
        image: item.image,
        url: item.url,
        order: item.order,
      })),
      status: item.status,
    }))
    : []

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value) => ({ id: value }))
    dispatch(midMenu?.actions?.deleteMidMenu(id))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(midMenu?.actions.getMidMenu(params))
  }
  const handleGetAllData = () => {
    setParams({ ...params, limit: MidMenuData?.data?.meta?.total })
    dispatch(midMenu?.actions.getMidMenu(params))
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      MidMenuId: data?.map((item) => ({ id: item?.keyId })),
    }
    dispatch(midMenu?.actions.sortMidMenu(body))
  }

  useEffect(() => {
    if (!isEmpty(sortMidMenuData) && success) {
      toast.success('Mid Menu Manager sorted successfully')
      dispatch(midMenu.actions.sortMidMenuReset())
      setParams({ ...params, limit: 10 })
      dispatch(midMenu.actions.getMidMenu(params))
      setOpenSortModal(false)
    }
  }, [sortMidMenuData, success])

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Mid Menu Manager'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          data={data}
          handleGetAllData={handleGetAllData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={MidMenuData?.loading}
            checkedValues={checkedValues}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={MidMenuData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
          />
          {open && (
            <AddMidMenuManager
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => modalSubmit()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default MidMenuManager
