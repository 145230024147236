import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const FEEDBACK_FORM_API = `${API_URL}/feedback`

export const service = {
  getFeedbackFormDetail: (params: ParamsModel) => {
    return axios.get(FEEDBACK_FORM_API, {params})
  },

  //export file
  exportFeedbackFormDetailFile: (fileName: string, params: ParamsModel) => {
    return axios.get(`${FEEDBACK_FORM_API}/export`, {params}).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
  deleteFeedback: (data: any) => {
    return axios.delete(`${FEEDBACK_FORM_API}/bulk-delete`, {data})
  },
}
