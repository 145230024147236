export const actionTypes = {
  // get
  GET_STOCK_RATE_START: 'GET_STOCK_RATE_START',
  GET_STOCK_RATE_SUCCESS: 'GET_GOLD_RATE_SUCCESS',
  GET_STOCK_RATE_FINISH: 'GET_GOLD_RATE_FINISH',

  // create
  CREATE_STOCK_RATE_START: 'CREATE_STOCK_RATE_START',
  CREATE_STOCK_RATE_SUCCESS: 'CREATE_STOCK_RATE_SUCCESS',
  CREATE_STOCK_RATE_FINISH: 'CREATE_STOCK_RATE_FINISH',
  CREATE_STOCK_RATE_RESET: 'CREATE_STOCK_RATE_RESET',

  // update
  UPDATE_STOCK_RATE_START: 'UPDATE_STOCK_RATE_START',
  UPDATE_STOCK_RATE_SUCCESS: 'UPDATE_STOCK_RATE_SUCCESS',
  UPDATE_STOCK_RATE_FINISH: 'UPDATE_STOCK_RATE_FINISH',
  UPDATE_STOCK_RATE_RESET: 'UPDATE_STOCK_RATE_RESET',

  //Activate deactivate
  ACTIVATE_STOCK_RATE_REQUEST: 'ACTIVATE_STOCK_RATE_REQUEST',
  ACTIVATE_STOCK_RATE_SUCCESS: 'ACTIVATE_STOCK_RATE_SUCCESS',
  ACTIVATE_STOCK_RATE_FINISH: 'ACTIVATE_STOCK_RATE_FINISH',

  SINGLE_ACTIVATE_STOCK_RATE_REQUEST: 'SINGLE_ACTIVATE_STOCK_RATE_REQUEST',
  SINGLE_ACTIVATE_STOCK_RATE_SUCCESS: 'SINGLE_ACTIVATE_STOCK_RATE_SUCCESS',
  SINGLE_ACTIVATE_STOCK_RATE_FINISH: 'SINGLE_ACTIVATE_STOCK_RATE_FINISH',

  SINGLE_DEACTIVATE_STOCK_RATE_REQUEST: 'SINGLE_DEACTIVATE_STOCK_RATE_REQUEST',
  SINGLE_DEACTIVATE_STOCK_RATE_SUCCESS: 'SINGLE_DEACTIVATE_STOCK_RATE_SUCCESS',
  SINGLE_DEACTIVATE_STOCK_RATE_FINISH: 'SINGLE_DEACTIVATE_STOCK_RATE_FINISH',

  DEACTIVATE_STOCK_RATE_REQUEST: 'DEACTIVATE_STOCK_RATE_REQUEST',
  DEACTIVATE_STOCK_RATE_SUCCESS: 'DEACTIVATE_STOCK_RATE_SUCCESS',
  DEACTIVATE_STOCK_RATE_FINISH: 'DEACTIVATE_STOCK_RATE_FINISH',

  // delete
  DELETE_STOCK_RATE_START: 'DELETE_STOCK_RATE_START',
  DELETE_STOCK_RATE_SUCCESS: 'DELETE_STOCK_RATE_SUCCESS',
  DELETE_STOCK_RATE_FINISH: 'DELETE_STOCK_RATE_FINISH',

  // get File csv xlsx
  GET_STOCK_RATE_FILE_START: 'GET_STOCK_RATE_FILE_START',
  GET_STOCK_RATE_FILE_SUCCESS: 'GET_STOCK_RATE_FILE_SUCCESS',
  GET_STOCK_RATE_FILE_FINISH: 'GET_STOCK_RATE_FILE_FINISH',

  // Import Stock rate
  IMPORT_STOCK_RATE_START: 'IMPORT_STOCK_RATE_START',
  IMPORT_STOCK_RATE_SUCCESS: 'IMPORT_STOCK_RATE_SUCCESS',
  IMPORT_STOCK_RATE_FINISH: 'IMPORT_STOCK_RATE_FINISH',

  EXPORT_SHARES_TEMPLATE_FILE_START: 'EXPORT_SHARES_TEMPLATE_FILE_START',
  EXPORT_SHARES_TEMPLATE_FILE_SUCCESS: 'EXPORT_SHARES_TEMPLATE_FILE_SUCCESS',
  EXPORT_SHARES_TEMPLATE_FILE_FINISH: 'EXPORT_SHARES_TEMPLATE_FILE_FINISH',
}
