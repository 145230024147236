import {Action} from 'redux'
import {ApplyMembershipModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialApplyMembershipState: IApplyMembershipState = {
  data: {
    membershipApply: [],
    applyMembership: [],
    meta: {},
  },
  loading: false,
  success: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
}

export interface IApplyMembershipState {
  data: {
    membershipApply: {[key: string]: number | string}[]
    applyMembership: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  loading: false
  success: false
  editSuccess: false
  activateSuccess: false
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false
}

export const reducer = (
  state: IApplyMembershipState = initialApplyMembershipState,
  action: ActionWithPayload<IApplyMembershipState>
) => {
  switch (action.type) {
    //Get ApplyMembership Reducer
    case actionTypes.GET_APPLY_MEMBERSHIP_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_APPLY_MEMBERSHIP_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_APPLY_MEMBERSHIP_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add ApplyMembership Reducer
    case actionTypes.ADD_APPLY_MEMBERSHIP_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_APPLY_MEMBERSHIP_SUCCESS: {
      const ApplyMembershipData = action.payload
      return {...state, success: true, loading: false}
    }

    case actionTypes.ADD_APPLY_MEMBERSHIP_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    case actionTypes.ADD_APPLY_MEMBERSHIP_RESET: {
      return {...state, success: false, data: [], loading: false}
    }

    //update ApplyMembership
    case actionTypes.UPDATE_APPLY_MEMBERSHIP_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_APPLY_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_APPLY_MEMBERSHIP_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.UPDATE_APPLY_MEMBERSHIP_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }
    //Delete ApplyMembership
    case actionTypes.DELETE_APPLY_MEMBERSHIP_START: {
      return {...state, loading: true, deleteSuccess: false}
    }

    case actionTypes.DELETE_APPLY_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_APPLY_MEMBERSHIP_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Activate ApplyMemberships
    case actionTypes.ACTIVATE_APPLY_MEMBERSHIP_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ACTIVATE_APPLY_MEMBERSHIP_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.ACTIVATE_APPLY_MEMBERSHIP_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    //Single Activate ApplyMembership
    case actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_SUCCESS: {
      const changedData: any = action.payload?.data?.applyMembership
      let newData = state?.data?.membershipApply?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, membershipApply: newData},
        singleActivateSuccess: true,
        toggleLoading: false,
      }
    }
    case actionTypes.SINGLE_ACTIVATE_APPLY_MEMBERSHIP_FINISH: {
      return {...state, singleActivateSuccess: false, toggleLoading: false}
    }

    //Deactivate ApplyMembership
    case actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.DEACTIVATE_APPLY_MEMBERSHIP_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_SUCCESS: {
      const changedData: any = action.payload?.data?.applyMembership
      let newData = state?.data?.membershipApply?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, membershipApply: newData},
        singleDeactivateSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DEACTIVATE_APPLY_MEMBERSHIP_FINISH: {
      return {...state, singleDeactivateSuccess: false, toggleLoading: false}
    }

    default:
      return state
  }
}
