import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getActiveBenefitCategorySaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getActiveBenefitCategory)
    yield put(actions.getActiveBenefitCategorySuccess(response?.data?.data))
    yield put(actions.getActiveBenefitCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBenefitCategoryFinish())
  }
}

function* getBenefitCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getBenefitCategory, params)
    yield put(actions.getBenefitCategorySuccess(response?.data?.data))
    yield put(actions.getBenefitCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getBenefitCategoryFinish())
  }
}

function* addBenefitCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBenefitCategory, body)
    yield put(actions.addBenefitCategorySuccess(response.data?.data))
    yield put(actions.addBenefitCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBenefitCategoryFinish())
  }
}

function* updateBenefitCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateBenefitCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateBenefitCategorySuccess(response.data?.data))
    yield put(actions.updateBenefitCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBenefitCategoryFinish())
  }
}

function* deleteBenefitCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBenefitCategory, body)
    yield put(actions.deleteBenefitCategorySuccess(response.data?.data))
    yield put(actions.deleteBenefitCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBenefitCategoryFinish())
  }
}

function* singleActivateBenefitCategorySaga(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateBenefitCategory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_FINISH})
  }
}

function* singleDeactivateBenefitCategorySaga(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateBenefitCategory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_FINISH})
  }
}

function* sortBenefitCategorySaga(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortBenefitCategory, body)

    yield put({
      type: actionTypes.SORT_BENEFIT_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_BENEFIT_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_BENEFIT_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_START, getActiveBenefitCategorySaga)
  yield takeLatest(actionTypes.GET_BENEFIT_CATEGORY_START, getBenefitCategorySaga)
  yield takeLatest(actionTypes.ADD_BENEFIT_CATEGORY_START, addBenefitCategorySaga)
  yield takeLatest(actionTypes.UPDATE_BENEFIT_CATEGORY_START, updateBenefitCategorySaga)
  yield takeLatest(actionTypes.DELETE_BENEFIT_CATEGORY_START, deleteBenefitCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_REQUEST,
    singleActivateBenefitCategorySaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_REQUEST,
    singleDeactivateBenefitCategorySaga
  )
  yield takeLatest(actionTypes.SORT_BENEFIT_CATEGORY_START, sortBenefitCategorySaga)
}
