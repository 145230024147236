import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeletePortfolioModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const getAllPortfolioAPI = (params: ParamsModel) => {
  return axios.get(`${API_URL}/portfolio`, {params})
}

export const addPortfolioAPI = (data: any) => {
  return axios.post(`${API_URL}/portfolio`, data)
}

export const deletePortfolioAPI = (data: DeletePortfolioModel) => {
  return axios.delete(`${API_URL}/portfolio/bulk-delete`, {data})
}
