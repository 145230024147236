import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteServiceTypeModel} from '../Model'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const SUBSCRIBE_TYPE = `${API_URL}/subscribe-type`

export const service = {
  getSubscribeType: (params: ParamsModel) => {
    return axios.get(SUBSCRIBE_TYPE, {params})
  },
  getAllSubscribeType: (params: any = {}) => {
    return axios.get(`${SUBSCRIBE_TYPE}/list`, {params})
  },

  addSubscribeType: (data: any) => {
    return axios.post(SUBSCRIBE_TYPE, data)
  },

  updateSubscribeType: (body: ServiceTypeModel, id: string) => {
    return axios.put(`${SUBSCRIBE_TYPE}/${id}`, body)
  },

  deleteSubscribeType: (data: DeleteServiceTypeModel) => {
    return axios.delete(`${SUBSCRIBE_TYPE}/bulk-delete`, {data})
  },

  enableSubscribeType: (data: Array<string>) => {
    const selectedServiceType = {
      subscribeTypeId: data,
    }
    return axios.patch(`${SUBSCRIBE_TYPE}/enable`, selectedServiceType)
  },

  disableSubscribeType: (data: Array<string>) => {
    const selectedServiceType = {
      subscribeTypeId: data,
    }
    return axios.patch(`${SUBSCRIBE_TYPE}/disable`, selectedServiceType)
  },

  singleEnableSubscribeType: (data: Array<string>) => {
    const selectedServiceType = {
      subscribeTypeId: [data],
    }
    return axios.patch(`${SUBSCRIBE_TYPE}/enable`, selectedServiceType)
  },

  singleDisableSubscribeType: (data: Array<string>) => {
    const selectedServiceType = {
      subscribeTypeId: [data],
    }
    return axios.patch(`${SUBSCRIBE_TYPE}/disable`, selectedServiceType)
  },
}
