import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const LoanPurpose = `${API_URL}/loan-purpose`

export const service = {
  getLoanPurpose: (params: ParamsModel) => {
    return axios.get(LoanPurpose, {params})
  },

  addLoanPurpose: (data: any) => {
    return axios.post(LoanPurpose, data)
  },

  updateLoanPurpose: (body: any, id: string) => {
    return axios.patch(`${LoanPurpose}/${id}`, body)
  },

  deleteLoanPurpose: (data: DeleteModel) => {
    return axios.delete(LoanPurpose, {data})
  },
  singleActivateLoanPurpose: (id: any) => {
    return axios.patch(`${LoanPurpose}/enable`, id)
  },

  singleDeactivateLoanPurpose: (id: any) => {
    return axios.patch(`${LoanPurpose}/disable`, id)
  },

  sortLoanPurpose: (body: any) => {
    return axios.patch(`${LoanPurpose}/sort`, body)
  },
}
