import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CARD_CATEGORY = `${API_URL}/card-category`

export const service = {
  getActiveCardCategory: () => {
    return axios.get(`${CARD_CATEGORY}/list`)
  },
  getCardCategory: (params: ParamsModel) => {
    return axios.get(CARD_CATEGORY, {params})
  },

  addCardCategory: (data: any) => {
    return axios.post(CARD_CATEGORY, data)
  },

  updateCardCategory: (body: any, id: string) => {
    return axios.put(`${CARD_CATEGORY}/${id}`, body)
  },

  deleteCardCategory: (data: any) => {
    return axios.delete(`${CARD_CATEGORY}/bulk-delete`, {data})
  },
  singleActivateCardCategory: (id: any) => {
    return axios.patch(`${CARD_CATEGORY}/enable`, id)
  },

  singleDeactivateCardCategory: (id: any) => {
    return axios.patch(`${CARD_CATEGORY}/disable`, id)
  },

  sortCardCategory: (body: any) => {
    return axios.patch(`${CARD_CATEGORY}/sort`, body)
  },
}
