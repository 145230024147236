import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAppointmentRequestState: IAppointmentRequestState = {
  data: {
    appointmentRequest: [],
    meta: {},
  },

  loading: false,
  success: false,
  editSuccess: false,
}

export interface IAppointmentRequestState {
  data: {
    appointmentRequest: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  loading: false
  success: false
  editSuccess: false
}

export const reducer = (
  state: IAppointmentRequestState = initialAppointmentRequestState,
  action: ActionWithPayload<IAppointmentRequestState>
) => {
  switch (action.type) {
    //Get appointmentRequest Reducer
    case actionTypes.GET_APPOINTMENT_REQUEST_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_APPOINTMENT_REQUEST_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_APPOINTMENT_REQUEST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //export appointment request file
    case actionTypes.GET_REQUEST_FILE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_REQUEST_FILE_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_REQUEST_FILE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_APPOINTMENT_REQUEST_RESET: {
      return {...state, success: false, data: [], loading: false}
    }
    //Add appointmentRequest Reducer
    /*  case actionTypes.ADD_APPOINTMENT_REQUEST_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_APPOINTMENT_REQUEST_SUCCESS: {
      const appointmentRequestData = action.payload
      return {...state, success: true, loading: false}
    }

    case actionTypes.ADD_APPOINTMENT_REQUEST_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    case actionTypes.ADD_APPOINTMENT_REQUEST_RESET: {
      return {...state, success: false, data: [], loading: false}
    } */

    //update appointmentRequest
    case actionTypes.UPDATE_APPOINTMENT_REQUEST_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_APPOINTMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_APPOINTMENT_REQUEST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.UPDATE_APPOINTMENT_REQUEST_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    default:
      return state
  }
}
