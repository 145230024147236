export const actionTypes = {
  // get
  GET_SMTP_SETTING_START: 'GET_SMTP_SETTING_START',
  GET_SMTP_SETTING_SUCCESS: 'GET_SMTP_SETTING_SUCCESS',
  GET_SMTP_SETTING_FINISH: 'GET_SMTP_SETTING_FINISH',

  // get SMTP encription
  GET_SMTP_ENCRYPTION_START: 'GET_SMTP_ENCRYPTION_START',
  GET_SMTP_ENCRYPTION_SUCCESS: 'GET_SMTP_ENCRYPTION_SUCCESS',
  GET_SMTP_ENCRYPTION_FINISH: 'GET_SMTP_ENCRYPTION_FINISH',

  // create
  CREATE_SMTP_START: 'CREATE_SMTP_START',
  CREATE_SMTP_SUCCESS: 'CREATE_SMTP_SUCCESS',
  CREATE_SMTP_FINISH: 'CREATE_SMTP_FINISH',
  CREATE_SMTP_RESET: 'CREATE_SMTP_RESET',

  // update
  UPDATE_SMTP_START: 'UPDATE_SMTP_START',
  UPDATE_SMTP_SUCCESS: 'UPDATE_SMTP_SUCCESS',
  UPDATE_SMTP_FINISH: 'UPDATE_SMTP_FINISH',
  UPDATE_SMTP_RESET: 'UPDATE_SMTP_RESET',

  // delete
  DELETE_SMTP_START: 'DELETE_SMTP_START',
  DELETE_SMTP_SUCCESS: 'DELETE_SMTP_SUCCESS',
  DELETE_SMTP_FINISH: 'DELETE_SMTP_FINISH',
}
