import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteNotificationModel} from '../Model'
import {NotificationModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const NOTIFICATION_API_DATA = `${API_URL}/notifications`

export const service = {
  getNotification: (params: ParamsModel) => {
    return axios.get(NOTIFICATION_API_DATA, {params})
  },

  addNotification: (data: any) => {
    return axios.post(NOTIFICATION_API_DATA, data)
  },

  updateNotification: (body: NotificationModel, id: string) => {
    return axios.put(`${NOTIFICATION_API_DATA}/${id}`, body)
  },

  deleteNotification: (data: DeleteNotificationModel) => {
    return axios.delete(NOTIFICATION_API_DATA, {data})
  },
}
