import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeletePartnersModel, SortPartnersModel} from '../Model'
import {PartnersModel} from '../Model/PartnersModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PARTNERS_DATA = `${API_URL}/partners`

export const service = {
  getPartners: (params: ParamsModel) => {
    return axios.get(PARTNERS_DATA, {params})
  },
  getAllPartners: () => {
    return axios.get(`${PARTNERS_DATA}/list`)
  },

  addPartners: (data: any) => {
    return axios.post(PARTNERS_DATA, data)
  },

  updatePartners: (body: PartnersModel, id: string) => {
    return axios.put(`${PARTNERS_DATA}/${id}`, body)
  },

  deletePartners: (data: DeletePartnersModel) => {
    return axios.delete(PARTNERS_DATA, {data})
  },

  enablePartners: (data: Array<string>) => {
    const selectedPartners = {
      partnersId: data,
    }
    return axios.patch(`${PARTNERS_DATA}/enable`, selectedPartners)
  },

  disablePartners: (data: Array<string>) => {
    const selectedPartners = {
      partnersId: data,
    }
    return axios.patch(`${PARTNERS_DATA}/disable`, selectedPartners)
  },

  singleEnablePartners: (data: Array<string>) => {
    const selectedPartners = {
      partnersId: [data],
    }
    return axios.patch(`${PARTNERS_DATA}/enable`, selectedPartners)
  },

  singleDisablePartners: (data: Array<string>) => {
    const selectedPartners = {
      partnersId: [data],
    }
    return axios.patch(`${PARTNERS_DATA}/disable`, selectedPartners)
  },

  sortPartners: (body: SortPartnersModel) => {
    return axios.patch(`${PARTNERS_DATA}/sort`, body)
  },
}
