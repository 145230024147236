export const actionTypes: any = {
  // GET
  GET_PROPERTY_TYPE_START: 'GET_PROPERTY_TYPE_START',
  GET_PROPERTY_TYPE_SUCCESS: 'GET_PROPERTY_TYPE_SUCCESS',
  GET_PROPERTY_TYPE_FINISH: 'GET_PROPERTY_TYPE_FINISH',

  // ADD
  ADD_PROPERTY_TYPE_START: 'ADD_PROPERTY_TYPE_START',
  ADD_PROPERTY_TYPE_SUCCESS: 'ADD_PROPERTY_TYPE_SUCCESS',
  ADD_PROPERTY_TYPE_FINISH: 'ADD_PROPERTY_TYPE_FINISH',
  RESET_PROPERTY_TYPE: 'RESET_PROPERTY_TYPE',

  // UPDATE
  UPDATE_PROPERTY_TYPE_START: 'UPDATE_PROPERTY_TYPE_START',
  UPDATE_PROPERTY_TYPE_SUCCESS: 'UPDATE_PROPERTY_TYPE_SUCCESS',
  UPDATE_PROPERTY_TYPE_FINISH: 'UPDATE_PROPERTY_TYPE_FINISH',

  // Delete
  DELETE_PROPERTY_TYPE_START: 'DELETE_PROPERTY_TYPE_START',
  DELETE_PROPERTY_TYPE_SUCCESS: 'DELETE_PROPERTY_TYPE_SUCCESS',
  DELETE_PROPERTY_TYPE_FINISH: 'DELETE_PROPERTY_TYPE_FINISH',

  // Enable
  ENABLE_PROPERTY_TYPE_REQUEST: 'ENABLE_PROPERTY_TYPE_REQUEST',
  ENABLE_PROPERTY_TYPE_SUCCESS: 'ENABLE_PROPERTY_TYPE_SUCCESS',
  ENABLE_PROPERTY_TYPE_FINISH: 'ENABLE_PROPERTY_TYPE_FINISH',

  // Disable
  DISABLE_PROPERTY_TYPE_REQUEST: 'DISABLE_PROPERTY_TYPE_REQUEST',
  DISABLE_PROPERTY_TYPE_SUCCESS: 'DISABLE_PROPERTY_TYPE_SUCCESS',
  DISABLE_PROPERTY_TYPE_FINISH: 'DISABLE_PROPERTY_TYPE_FINISH',

  // Enable
  SINGLE_ENABLE_PROPERTY_TYPE_REQUEST: 'SINGLE_ENABLE_PROPERTY_TYPE_REQUEST',
  SINGLE_ENABLE_PROPERTY_TYPE_SUCCESS: 'SINGLE_ENABLE_PROPERTY_TYPE_SUCCESS',
  SINGLE_ENABLE_PROPERTY_TYPE_FINISH: 'SINGLE_ENABLE_PROPERTY_TYPE_FINISH',

  // Disable
  SINGLE_DISABLE_PROPERTY_TYPE_REQUEST: 'SINGLE_DISABLE_PROPERTY_TYPE_REQUEST',
  SINGLE_DISABLE_PROPERTY_TYPE_SUCCESS: 'SINGLE_DISABLE_PROPERTY_TYPE_SUCCESS',
  SINGLE_DISABLE_PROPERTY_TYPE_FINISH: 'SINGLE_DISABLE_PROPERTY_TYPE_FINISH',

  // sort
  SORT_PROPERTY_TYPE_START: 'SORT_PROPERTY_TYPE_START',
  SORT_PROPERTY_TYPE_SUCCESS: 'SORT_PROPERTY_TYPE_SUCCESS',
  SORT_PROPERTY_TYPE_FINISH: 'SORT_PROPERTY_TYPE_FINISH',
  SORT_PROPERTY_TYPE_RESET: 'SORT_PROPERTY_TYPE_RESET',
}
