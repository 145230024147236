import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_VACANCY_APPLICANT = `${API_URL}/apply-vacancy/list`
export const GET_VACANCY_APPLICANT_STATUS = `${API_URL}/vacancy-applicant-status`



export const service = {
  getVacancyApplicant: (params: ParamsModel) => {
    return axios.get(GET_VACANCY_APPLICANT, {params})
  },
  getApplicantStatus: () => {
    return axios.get(`${GET_VACANCY_APPLICANT_STATUS}`)
 
}
}