import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialCategoryState: IMenuButtonData = {
  data: {
    menuButton: [],
    meta: {},
  },
  deleteSuccess: false,
  sortMenuButtonData: [],
  loading: false,
  success: false,
}

export interface IMenuButtonData {
  data: {
    menuButton: any
    meta: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  sortMenuButtonData: IMenuButtonData[]
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IMenuButtonData = initialCategoryState,
  action: ActionWithPayload<IMenuButtonData>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_MENU_BUTTON_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ALL_MENU_BUTTON_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_MENU_BUTTON_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_MENU_BUTTON_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_MENU_BUTTON_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_MENU_BUTTON_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_MENU_BUTTON_MANAGER_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_MENU_BUTTON_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_MENU_BUTTON_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_MENU_BUTTON_MANAGER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_MENU_BUTTON_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_MENU_BUTTON_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_MENU_BUTTON_MANAGER_SUCCESS: {
      return {
        ...state,
        sortMenuButtonData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_MENU_BUTTON_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_MENU_BUTTON_MANAGER_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortMenuButtonData: [],
      }
    }

    default:
      return state
  }
}
