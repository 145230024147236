import {BankDetailParamsModel} from 'src/app/modules/common/Model'
import {DeleteBankDetailModel, BankDetailModel, SortBankDetailModel} from '../Model'
import {actionTypes} from './constants'
import {IAboutBankDetailState} from './reducer'
export const actions = {
  // get BankDetail DATA
  getAboutBankDetail: (params: BankDetailParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ABOUT_BANK_DETAIL_START,
    payload: params,
  }),
  getAboutBankDetailSuccess: (data: BankDetailModel) => ({
    type: actionTypes.GET_ABOUT_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  getAboutBankDetailFinish: () => ({
    type: actionTypes.GET_ABOUT_BANK_DETAIL_FINISH,
  }),

  // get BankDetail File Type Data
  getAboutBankDetailFileType: () => ({
    type: actionTypes.GET_ABOUT_BANK_DETAIL_FILE_TYPE_START,
  }),
  getAboutBankDetailFileTypeSuccess: (data: BankDetailModel) => ({
    type: actionTypes.GET_ABOUT_BANK_DETAIL_FILE_TYPE_SUCCESS,
    payload: data,
  }),
  getAboutBankDetailFileTypeFinish: () => ({
    type: actionTypes.GET_ABOUT_BANK_DETAIL_FILE_TYPE_FINISH,
  }),

  // create key
  addAboutBankDetail: (data: BankDetailModel) => ({
    type: actionTypes.ADD_ABOUT_BANK_DETAIL_START,
    payload: data,
  }),
  addAboutBankDetailSuccess: (task: any) => ({
    type: actionTypes.ADD_ABOUT_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  addAboutBankDetailFinish: () => ({
    type: actionTypes.ADD_ABOUT_BANK_DETAIL_FINISH,
  }),
  resetAboutBankDetail: () => ({
    type: actionTypes.RESET_ABOUT_BANK_DETAIL_TYPE,
  }),

  //Update BankDetail
  updateAboutBankDetail: (data: BankDetailModel, id: string) => ({
    type: actionTypes.UPDATE_ABOUT_BANK_DETAIL_START,
    payload: data,
    id,
  }),

  updateAboutBankDetailSuccess: (data: BankDetailModel) => ({
    type: actionTypes.UPDATE_ABOUT_BANK_DETAIL_SUCCESS,
    payload: data,
  }),

  updateAboutBankDetailFinish: () => ({
    type: actionTypes.UPDATE_ABOUT_BANK_DETAIL_FINISH,
  }),

  // delete key
  deleteAboutBankDetail: (data: DeleteBankDetailModel[]) => ({
    type: actionTypes.DELETE_ABOUT_BANK_DETAIL_START,
    payload: {aboutBankDetailId: data},
  }),
  deleteAboutBankDetailSuccess: (data: any) => ({
    type: actionTypes.DELETE_ABOUT_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  deleteAboutBankDetailFinish: () => ({
    type: actionTypes.DELETE_ABOUT_BANK_DETAIL_FINISH,
  }),

  //Enable BankDetail
  enableAboutBankDetail: (data: any) => ({
    type: actionTypes.ENABLE_ABOUT_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  enableAboutBankDetailSuccess: (task: any) => ({
    type: actionTypes.ENABLE_ABOUT_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  enableAboutBankDetailFinish: () => ({
    type: actionTypes.ENABLE_ABOUT_BANK_DETAIL_FINISH,
  }),

  //Disable BankDetail
  disableAboutBankDetail: (data: any) => ({
    type: actionTypes.DISABLE_ABOUT_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  disableAboutBankDetailSuccess: (task: any) => ({
    type: actionTypes.DISABLE_ABOUT_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  disableAboutBankDetailFinish: () => ({
    type: actionTypes.DISABLE_ABOUT_BANK_DETAIL_FINISH,
  }),

  //Enable BankDetail
  singleEnableAboutBankDetail: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  singleEnableAboutBankDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  singleEnableAboutBankDetailFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_FINISH,
  }),

  //Disable BankDetail
  singleDisableAboutBankDetail: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  singleDisableAboutBankDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  singleDisableAboutBankDetailFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_FINISH,
  }),

  // get BankDetail
  getAllAboutBankDetail: () => {
    return {
      type: actionTypes.GET_ALL_ABOUT_BANK_DETAIL_START,
    }
  },
  getAllAboutBankDetailSuccess: (data: IAboutBankDetailState) => ({
    type: actionTypes.GET_ALL_ABOUT_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  getAllAboutBankDetailError: () => ({
    type: actionTypes.GET_ALL_ABOUT_BANK_DETAIL_FINISH,
  }),

  // sort
  sortAboutBankDetail: (data: SortBankDetailModel) => ({
    type: actionTypes.SORT_ABOUT_BANK_DETAIL_START,
    payload: data,
  }),
  sortAboutBankDetailSuccess: (data: Array<BankDetailModel>) => ({
    type: actionTypes.SORT_ABOUT_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  sortAboutBankDetailFinish: () => ({
    type: actionTypes.SORT_ABOUT_BANK_DETAIL_FINISH,
  }),
  sortAboutBankDetailReset: () => ({
    type: actionTypes.SORT_ABOUT_BANK_DETAIL_RESET,
  }),
}
