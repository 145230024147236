import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialCategoryState: ICardManagerData = {
  data: {
    cardManager: [],
    meta: {},
  },
  deleteSuccess: false,
  sortCardManagerData: [],

  loading: false,
  success: false,
}

export interface ICardManagerData {
  data: {
    cardManager: any
    meta: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  sortCardManagerData: ICardManagerData[]
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ICardManagerData = initialCategoryState,
  action: ActionWithPayload<ICardManagerData>
) => {
  switch (action.type) {
    case actionTypes.GET_SPECIFIC_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SPECIFIC_CARD_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SPECIFIC_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_CARD_MANAGER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_CARD_MANAGER_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_CARD_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        sortBenefitManagerData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_CARD_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_CARD_MANAGER_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortBenefitManagerData: [],
      }
    }

    default:
      return state
  }
}
