import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteApplyLoanModel} from '../../applyLoan/Model'
import {COMMON_TYPES, PORTFOLIO_TYPES} from './constant'

export const getAllPortfolio = (params: ParamsModel = {page: 1, limit: 10}) => ({
  type: PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.REQUEST,
  payload: params,
})

export const getPortfolio = (payload: any) => ({
  type: PORTFOLIO_TYPES.GET_PORTFOLIO + COMMON_TYPES.REQUEST,
  payload,
})

export const addPortfolio = (payload: any) => ({
  type: PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.REQUEST,
  payload,
})

export const resetPortfolio = () => ({
  type: PORTFOLIO_TYPES.RESET_PORTFOLIO,
})

export const deletePortfolio = (data: DeleteApplyLoanModel[]) => ({
  type: PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.REQUEST,
  payload: {portfolio: data},
})
