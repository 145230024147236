export const actionTypes = {
    // get Rivew Rating
    GET_REVIEW_RATING_START: 'GET_REVIEW_RATING_START',
    GET_REVIEW_RATING_SUCCESS: 'GET_REVIEW_RATING_SUCCESS',
    GET_REVIEW_RATING_FINISH: 'GET_REVIEW_RATING_FINISH', 

    // get Rivew Status
    GET_REVIEW_STATUS_START: 'GET_REVIEW_STATUS_START',
    GET_REVIEW_STATUS_SUCCESS: 'GET_REVIEW_STATUS_SUCCESS',
    GET_REVIEW_STATUS_FINISH: 'GET_REVIEW_STATUS_FINISH', 

      // get File csv xlsx
  EXPORT_REVIEW_FILE_START: 'EXPORT_REVIEW_FILE_START',
  EXPORT_REVIEW_FILE_SUCCESS: 'EXPORT_REVIEW_FILE_SUCCESS',
  EXPORT_REVIEW_FILE_FINISH: 'EXPORT_REVIEW_FILE_FINISH',

}