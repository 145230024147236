import { FieldArray, FormikErrors } from 'formik'
import { isEmpty } from 'lodash'
import { ChangeEvent } from 'react'
import { FormOptionModal } from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{ [key: string]: string }> | any
  touched: any
  values: { [key: string]: string } | any
  editSelectedData: { [key: string]: string } | any
  benefitOption: FormOptionModal[]
  validationState?: any
  setValidationState?: any
  setFieldValue: any
}

export function ProductBenefitComponent({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  benefitOption,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {

  const benefitSettingId = benefitOption.find(
    (item: FormOptionModal) => item.value === values.benefitSettingId
  )

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Show Product Benefits Section'
              name='benefitSettingId'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={benefitOption}
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        {benefitSettingId?.systemName === 'yes' ? (
          <>
            <div className='row'>
              <FieldArray
                name='benefitId'
                render={(arrayHelpers) => (
                  <div>
                    {values?.benefitId && values?.benefitId?.length > 0
                      ? values?.benefitId.map((friend: any, index: any) => (
                        <div className='row mb-4' key={index}>
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Text (EN)'
                              name={`benefitId[${index}].title`}
                              label='Title (EN)'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Text (NP)'
                              name={`benefitId[${index}].titleNp`}
                              label='Title (NP)'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              labelClassName='col-md-12'
                              label='Description (EN)'
                              name={`benefitId.${index}.description`}
                              initialValue={
                                !isEmpty(editSelectedData)
                                  ? editSelectedData?.benefitId[index]?.description
                                  : ''
                              }
                              handleChange={handleChange}
                            />
                          </div>
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              labelClassName='col-md-12'
                              label='Description (NP)'
                              name={`benefitId.${index}.descriptionNp`}
                              initialValue={
                                !isEmpty(editSelectedData)
                                  ? editSelectedData?.benefitId[index]?.descriptionNp
                                  : ''
                              }
                              handleChange={handleChange}
                            />
                          </div>
                          <div className='col-md-6 col-sm-6 col-xs-12'></div>

                          {index > 0 && (
                            <div className='col-md-6 col-sm-6 col-xs-12 justify-content-md-end text-end mb-2'>
                              <button
                                type='button'
                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                onClick={() => arrayHelpers.remove(index)}
                                data-cy='modal-field-remove'
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                      : null}
                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                      <button
                        className='p-2 ps-5 pe-5 btn btn-primary'
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            title: '',
                            titleNp: '',
                            description: '',
                            descriptionNp: '',
                          })
                        }
                        data-cy='modal-field-add'
                      >
                        + Add More
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}
