export const actionTypes = {
  GET_HISTORY_START: 'GET_HISTORY_START',
  GET_HISTORY_SUCCESS: 'GET_HISTORY_SUCCESS',
  GET_HISTORY_FINISH: 'GET_HISTORY_FINISH',

  ADD_HISTORY_START: 'ADD_HISTORY_START',
  ADD_HISTORY_SUCCESS: 'ADD_HISTORY_SUCCESS',
  ADD_HISTORY_FINISH: 'ADD_HISTORY_FINISH',
  ADD_HISTORY_RESET: 'ADD_HISTORY_RESET',

  UPDATE_HISTORY_START: 'UPDATE_HISTORY_START',
  UPDATE_HISTORY_SUCCESS: 'UPDATE_HISTORY_SUCCESS',
  UPDATE_HISTORY_FINISH: 'UPDATE_HISTORY_FINISH',
  UPDATE_HISTORY_RESET: 'UPDATE_HISTORY_RESET',

  DELETE_HISTORY_START: 'DELETE_HISTORY_START',
  DELETE_HISTORY_SUCCESS: 'DELETE_HISTORY_SUCCESS',
  DELETE_HISTORY_FINISH: 'DELETE_HISTORY_FINISH',

  SINGLE_ACTIVATE_HISTORY_REQUEST: 'SINGLE_ACTIVATE_HISTORY_REQUEST',
  SINGLE_ACTIVATE_HISTORY_SUCCESS: 'SINGLE_ACTIVATE_HISTORY_SUCCESS',
  SINGLE_ACTIVATE_HISTORY_FINISH: 'SINGLE_ACTIVATE_HISTORY_FINISH',

  SINGLE_DEACTIVATE_HISTORY_REQUEST: 'SINGLE_DEACTIVATE_HISTORY_REQUEST',
  SINGLE_DEACTIVATE_HISTORY_SUCCESS: 'SINGLE_DEACTIVATE_HISTORY_SUCCESS',
  SINGLE_DEACTIVATE_HISTORY_FINISH: 'SINGLE_DEACTIVATE_HISTORY_FINISH',

  SORT_HISTORY_START: 'SORT_HISTORY_START',
  SORT_HISTORY_SUCCESS: 'SORT_HISTORY_SUCCESS',
  SORT_HISTORY_FINISH: 'SORT_HISTORY_FINISH',
  SORT_HISTORY_RESET: 'SORT_HISTORY_RESET',

  // get FISCAL YEARs AD
  GET_FISCAL_YEAR_AD_START: 'GET_FISCAL_YEAR_AD_START',
  GET_FISCAL_YEAR_AD_SUCCESS: 'GET_FISCAL_YEAR_AD_SUCCESS',
  GET_FISCAL_YEAR_AD_FINISH: 'GET_FISCAL_YEAR_AD_FINISH',
}
