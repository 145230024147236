import { FieldArray, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { ChangeEvent, useEffect, useState } from 'react'
import { FormOptionModal } from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import { isEmpty } from 'lodash'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import { imageBaseUrl } from 'src/cms/helpers/constants'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{ [key: string]: string }> | any
  touched: any
  bannerOptionOptions: FormOptionModal[]
  bannerDataOption?: FormOptionModal[]
  validationState: { [key: string]: Yup.StringSchema }
  setValidationState: any
  values: any
  setFieldValue: any

  editSelectedData: { [key: string]: string } | any
}

export function BannerComponent({
  handleChange,
  errors,
  touched,
  values,
  bannerDataOption,
  bannerOptionOptions,
  validationState,
  setValidationState,
  setFieldValue,
  editSelectedData,
}: Props) {
  const showBannerId = bannerOptionOptions?.find(
    (item: FormOptionModal) => item.value === values.banner_option_id
  )
  const [imageUrl, setImageUrl] = useState('')
  // useEffect(() => {
  //   showBannerId?.systemName === 'yes'
  //     ? setValidationState({
  //         ...validationState,
  //         bannerSection: Yup.string().required('Banner  Data is required'),
  //       })
  //     : showBannerId?.systemName === 'no'
  //     ? setValidationState({
  //         ...validationState,
  //         bannerSection: Yup.string().nullable(),
  //       })
  //     : null
  // }, [values.bannerSection])

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Show banner'
              name='banner_option_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={bannerOptionOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          {showBannerId?.systemName === 'yes' ? (
            <>
              <div className='row'>
                <FieldArray
                  name='bannerDetails'
                  render={(arrayHelpers) => (
                    <div>
                      {values.bannerDetails && values?.bannerDetails?.length > 0
                        ? values.bannerDetails.map((friend: any, index: any) => (
                          <div className='row mb-4' key={index}>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Title'
                                name={`bannerDetails[${index}].title`}
                                label='Title'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Sub Title'
                                name={`bannerDetails[${index}].subtitle`}
                                label='Sub Title'
                                as='textarea'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTinyMce
                                containerClassName='col-md-12'
                                labelClassName='col-md-12'
                                label='Description'
                                name={`bannerDetails.${index}.description`}
                                initialValue={
                                  !isEmpty(editSelectedData)
                                    ? editSelectedData?.bannerDetails?.[index]?.description
                                    : ''
                                }
                                handleChange={handleChange}
                              />
                            </div>

                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormInputMediaManager
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Banner Image'
                                name={`bannerDetails[${index}].image`}
                                setFieldValue={setFieldValue}
                                setImageUrl={setImageUrl}
                                value={values?.bannerDetails[index].image}
                                showImageOnly={true}
                              />

                              {!isEmpty(values?.bannerDetails[index].image) ? (
                                <>
                                  <li className='listing'>
                                    <div className='thumbImageBlock'>
                                      <button
                                        type='button'
                                        title='Remove'
                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                        onClick={() => {
                                          setImageUrl('')
                                          setFieldValue(`bannerDetails[${index}].image`, '')
                                        }}
                                        data-cy='modal-thumbnail-remove'
                                      >
                                        Delete
                                      </button>

                                      <img
                                        className='thumbImage w-100 h-100'
                                        src={`${imageBaseUrl}/${values?.bannerDetails[index].image}`}
                                        alt=''
                                        data-cy='modal-thumbnail-image'
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : null}
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='First Button Text '
                                name={`bannerDetails[${index}].first_button_text`}
                                label='First Button Text '
                                containerClassName=''
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='First Button URL'
                                name={`bannerDetails[${index}].first_button_url`}
                                label='First Button URL'
                                containerClassName=''
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Second Button Text '
                                name={`bannerDetails[${index}].second_button_text`}
                                label='Second Button Text '
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Second Button URL '
                                name={`bannerDetails[${index}].second_button_url`}
                                label='Second Button URL'
                                containerClassName=''
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                        ))
                        : null}
                      {values?.bannerDetails?.length == 1 ? null : (
                        <div className='col-6 d-flex justify-content-md-start mb-2 border-top border-light pt-5'>
                          <button
                            className='p-2 ps-5 pe-5 btn btn-primary w-100'
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                title: '',
                                subtitle: '',
                                description: '',
                                image: '',
                                first_button_text: '',
                                first_button_url: '',
                                second_button_text: '',
                                second_button_url: '',
                              })
                            }
                            data-cy='modal-field-add'
                          >
                            + Add Banner Section
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
