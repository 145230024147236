import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getSpecificCardManager: (id: string = '') => ({
    type: actionTypes.GET_SPECIFIC_CARD_MANAGER_START,
    payload: id,
  }),

  getSpecificCardManagerSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SPECIFIC_CARD_MANAGER_SUCCESS,
    payload: data,
  }),
  getSpecificCardManagerFinish: () => ({
    type: actionTypes.GET_SPECIFIC_CARD_MANAGER_FINISH,
  }),
  getSpecificCardManagerError: (error: unknown) => ({
    type: actionTypes.GET_SPECIFIC_CARD_MANAGER_FINISH,
    payload: {error},
  }),

  getCardManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CARD_MANAGER_START,
    payload: params,
  }),

  getCardManagerSuccess: (data: any) => ({
    type: actionTypes.GET_CARD_MANAGER_SUCCESS,
    payload: data,
  }),
  getCardManagerFinish: () => ({
    type: actionTypes.GET_CARD_MANAGER_FINISH,
  }),
  getCardManagerError: (error: unknown) => ({
    type: actionTypes.GET_CARD_MANAGER_FINISH,
    payload: {error},
  }),

  addCardManager: (data: any) => ({
    type: actionTypes.ADD_CARD_MANAGER_START,
    payload: data,
  }),
  addCardManagerSuccess: (task: any) => ({
    type: actionTypes.ADD_CARD_MANAGER_SUCCESS,
    payload: task,
  }),
  addCardManagerFinish: () => ({
    type: actionTypes.ADD_CARD_MANAGER_FINISH,
  }),
  addCardManagerReset: () => ({
    type: actionTypes.ADD_CARD_MANAGER_RESET,
  }),

  updateCardManager: (data: any, id: string) => ({
    type: actionTypes.UPDATE_CARD_MANAGER_START,
    payload: {data, id},
  }),
  updateCardManagerSuccess: (task: any) => ({
    type: actionTypes.UPDATE_CARD_MANAGER_SUCCESS,
    payload: task,
  }),
  updateCardManagerFinish: () => ({
    type: actionTypes.UPDATE_CARD_MANAGER_FINISH,
  }),
  updateCardManagerReset: () => ({
    type: actionTypes.UPDATE_CARD_MANAGER_RESET,
  }),

  deleteCardManager: (data: any) => ({
    type: actionTypes.DELETE_CARD_MANAGER_START,
    payload: {cardManagerId: data},
  }),
  deleteCardManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_CARD_MANAGER_SUCCESS,
    payload: data,
  }),
  deleteCardManagerFinish: () => ({
    type: actionTypes.DELETE_CARD_MANAGER_FINISH,
  }),
  activateCardManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_REQUEST,
    payload: {cardManagerId: id},
  }),
  deactivateCardManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_REQUEST,
    payload: {cardManagerId: id},
  }),
  singleActivateCardManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CARD_MANAGER_REQUEST,
    payload: {cardManagerId: [id]},
  }),
  singleDeactivateCardManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CARD_MANAGER_REQUEST,
    payload: {cardManagerId: [id]},
  }),

  sortCardManager: (data: any) => ({
    type: actionTypes.SORT_CARD_MANAGER_START,
    payload: data,
  }),
  sortCardManagerSuccess: (data: any) => ({
    type: actionTypes.SORT_CARD_MANAGER_SUCCESS,
    payload: data,
  }),
  sortCardManagerFinish: () => ({
    type: actionTypes.SORT_CARD_MANAGER_FINISH,
  }),
  sortCardManagerReset: () => ({
    type: actionTypes.SORT_CARD_MANAGER_RESET,
  }),
}
