export const actionTypes = {
  GET_ALL_MENU_BUTTON_MANAGER_START: 'GET_ALL_MENU_BUTTON_MANAGER_START',
  GET_ALL_MENU_BUTTON_MANAGER_SUCCESS: 'GET_ALL_MENU_BUTTON_MANAGER_SUCCESS',
  GET_ALL_MENU_BUTTON_MANAGER_FINISH: 'GET_ALL_MENU_BUTTON_MANAGER_FINISH',

  GET_SPECIFIC_MENU_BUTTON_MANAGER_START: 'GET_SPECIFIC_MENU_BUTTON_MANAGER_START',
  GET_SPECIFIC_MENU_BUTTON_MANAGER_SUCCESS: 'GET_SPECIFIC_MENU_BUTTON_MANAGER_SUCCESS',
  GET_SPECIFIC_MENU_BUTTON_MANAGER_FINISH: 'GET_SPECIFIC_MENU_BUTTON_MANAGER_FINISH',

  GET_MENU_BUTTON_MANAGER_START: 'GET_MENU_BUTTON_MANAGER_START',
  GET_MENU_BUTTON_MANAGER_SUCCESS: 'GET_MENU_BUTTON_MANAGER_SUCCESS',
  GET_MENU_BUTTON_MANAGER_FINISH: 'GET_MENU_BUTTON_MANAGER_FINISH',

  ADD_MENU_BUTTON_MANAGER_START: 'ADD_MENU_BUTTON_MANAGER_START',
  ADD_MENU_BUTTON_MANAGER_SUCCESS: 'ADD_MENU_BUTTON_MANAGER_SUCCESS',
  ADD_MENU_BUTTON_MANAGER_FINISH: 'ADD_MENU_BUTTON_MANAGER_FINISH',
  ADD_MENU_BUTTON_MANAGER_RESET: 'ADD_MENU_BUTTON_MANAGER_RESET',

  UPDATE_MENU_BUTTON_MANAGER_START: 'UPDATE_MENU_BUTTON_MANAGER_START',
  UPDATE_MENU_BUTTON_MANAGER_SUCCESS: 'UPDATE_MENU_BUTTON_MANAGER_SUCCESS',
  UPDATE_MENU_BUTTON_MANAGER_FINISH: 'UPDATE_MENU_BUTTON_MANAGER_FINISH',
  UPDATE_MENU_BUTTON_MANAGER_RESET: 'UPDATE_MENU_BUTTON_MANAGER_RESET',

  DELETE_MENU_BUTTON_MANAGER_START: 'DELETE_MENU_BUTTON_MANAGER_START',
  DELETE_MENU_BUTTON_MANAGER_SUCCESS: 'DELETE_MENU_BUTTON_MANAGER_SUCCESS',
  DELETE_MENU_BUTTON_MANAGER_FINISH: 'DELETE_MENU_BUTTON_MANAGER_FINISH',

  SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_REQUEST: 'SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_REQUEST',
  SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_SUCCESS: 'SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_SUCCESS',
  SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_FINISH: 'SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_FINISH',

  SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_REQUEST: 'SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_REQUEST',
  SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_SUCCESS: 'SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_SUCCESS',
  SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_FINISH: 'SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_FINISH',

  SORT_MENU_BUTTON_MANAGER_START: 'SORT_MENU_BUTTON_MANAGER_START',
  SORT_MENU_BUTTON_MANAGER_SUCCESS: 'SORT_MENU_BUTTON_MANAGER_SUCCESS',
  SORT_MENU_BUTTON_MANAGER_FINISH: 'SORT_MENU_BUTTON_MANAGER_FINISH',
  SORT_MENU_BUTTON_MANAGER_RESET: 'SORT_MENU_BUTTON_MANAGER_RESET',
}
