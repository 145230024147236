import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ProgramModel, SortProgramModel} from '../Model/ProgramModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PRODUCT_API = `${API_URL}/program`
export const API_PRODUCT_OPTION = `${API_URL}/program-option`

export const service = {
  getProgram: (params: ParamsModel) => {
    return axios.get(PRODUCT_API, {params})
  },
  getAllProgram: () => {
    return axios.get(`${PRODUCT_API}/list`)
  },

  addProgram: (data: any) => {
    return axios.post(PRODUCT_API, data)
  },

  updateProgram: (body: ProgramModel, id: string) => {
    return axios.put(`${PRODUCT_API}/${id}`, body)
  },

  deleteProgram: (data: {id: string}) => {
    return axios.delete(PRODUCT_API, {data})
  },

  enableProgram: (data: Array<string>) => {
    const selectedProgram = {
      programId: data,
    }
    return axios.patch(`${PRODUCT_API}/enable`, selectedProgram)
  },

  disableProgram: (data: Array<string>) => {
    const selectedProgram = {
      programId: data,
    }
    return axios.patch(`${PRODUCT_API}/disable`, selectedProgram)
  },

  singleEnableProgram: (data: Array<string>) => {
    const selectedProgram = {
      programId: [data],
    }
    return axios.patch(`${PRODUCT_API}/enable`, selectedProgram)
  },

  singleDisableProgram: (data: Array<string>) => {
    const selectedProgram = {
      programId: [data],
    }
    return axios.patch(`${PRODUCT_API}/disable`, selectedProgram)
  },
  sortProgram: (body: SortProgramModel) => {
    return axios.patch(`${PRODUCT_API}/sort`, body)
  },

  getAlbumOption: () => {
    return axios.get(`${API_PRODUCT_OPTION}/album-option`)
  },
  getBannerOption: () => {
    return axios.get(`${API_PRODUCT_OPTION}/banner-option`)
  },
  getDownloadOption: () => {
    return axios.get(`${API_PRODUCT_OPTION}/download-option`)
  },
  getLeadForm: () => {
    return axios.get(`${API_PRODUCT_OPTION}/lead-option`)
  },
  getPageHeader: () => {
    return axios.get(`${API_PRODUCT_OPTION}/page-header-option`)
  },
  getProgramOption: () => {
    return axios.get(`${API_PRODUCT_OPTION}/related-option`)
  },
}
