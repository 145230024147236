import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCardMenuDetailModel, CardMenuDetailModel, SortCardMenuDetailModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_CARD_MENU = `${API_URL}/card-menu`
// export const ADD_BANK_DETAIL = `${API_URL}/about-bank-detail`
// export const ACTIVATE_BANK_DETAILS = `${API_URL}/about-bank-detail/enable`
// export const DEACTIVATE_BANK_DETAILS = `${API_URL}/about-bank-detail/disable`
// export const DELETE_BANK_DETAIL = `${API_URL}/about-bank-detail/bulk-delete`

export const service = {
  getCardMenuDetail: (params: ParamsModel) => {
    return axios.get(GET_CARD_MENU, {params})
  },

  addCardMenuDetail: (data: any) => {
    return axios.post(GET_CARD_MENU, data)
  },

  updateCardMenuDetail: (body: CardMenuDetailModel, id: string) => {
    return axios.put(`${GET_CARD_MENU}/${id}`, body)
  },

  deleteCardMenuDetail: (data: DeleteCardMenuDetailModel) => {
    return axios.delete(`${GET_CARD_MENU}/bulk-delete`, {data})
  },

  enableCardMenuDetail: (data: Array<string>) => {
    const selectedCardMenuDetail = {
      cardMenuId: data,
    }
    return axios.patch(`${GET_CARD_MENU}/enable`, selectedCardMenuDetail)
  },

  disableCardMenuDetail: (data: Array<string>) => {
    const selectedCardMenuDetail = {
      cardMenuId: data,
    }
    return axios.patch(`${GET_CARD_MENU}/disable`, selectedCardMenuDetail)
  },

  singleEnableCardMenuDetail: (data: Array<string>) => {
    const selectedCardMenuDetail = {
      cardMenuId: [data],
    }
    return axios.patch(`${GET_CARD_MENU}/enable`, selectedCardMenuDetail)
  },

  singleDisableCardMenuDetail: (data: Array<string>) => {
    const selectedCardMenuDetail = {
      cardMenuId: [data],
    }
    return axios.patch(`${GET_CARD_MENU}/disable`, selectedCardMenuDetail)
  },

  getAllCardMenuDetail: () => {
    return axios.get(`${GET_CARD_MENU}/list`)
  },
  sortCardMenuDetail: (body: SortCardMenuDetailModel) => {
    return axios.patch(`${GET_CARD_MENU}/sort`, body)
  },
}
