import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteAgentModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CUSTOMER_LOGIN_API = `${API_URL}/agent`

export const DELETE_AGENT_API = `${API_URL}/agent/destroy`

export const service = {
  getList: (params: ParamsModel) => {
    return axios.get(CUSTOMER_LOGIN_API, {params})
  },
  deleteAgent: (data: DeleteAgentModel) => {
    return axios.delete(DELETE_AGENT_API, {data})
  },
}
