import axios from 'axios'
import { ParamsModel } from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const ACTIVITY_LOG_API = `${API_URL}/user-activity-log`

export const service = {
  getActivityLogs: (params: ParamsModel) => {
    return axios.get(ACTIVITY_LOG_API, {params})
  },
 
}
