export const actionTypes = {
  // get sub category
  GET_VACANCY_START: 'GET_VACANCY_START',
  GET_VACANCY_SUCCESS: 'GET_VACANCY_SUCCESS',
  GET_VACANCY_FINISH: 'GET_VACANCY_FINISH',

  // ADD sub category
  ADD_VACANCY_START: 'ADD_VACANCY_START',
  ADD_VACANCY_SUCCESS: 'ADD_VACANCY_SUCCESS',
  ADD_VACANCY_FINISH: 'ADD_VACANCY_FINISH',
  ADD_VACANCY_RESET: 'ADD_VACANCY_RESET',

  // Update sub category
  UPDATE_VACANCY_START: 'UPDATE_VACANCY_START',
  UPDATE_VACANCY_SUCCESS: 'UPDATE_VACANCY_SUCCESS',
  UPDATE_VACANCY_FINISH: 'UPDATE_VACANCY_FINISH',
  UPDATE_VACANCY_RESET: 'UPDATE_VACANCY_RESET',

  // delete sub category
  DELETE_VACANCY_START: 'DELETE_VACANCY_START',
  DELETE_VACANCY_SUCCESS: 'DELETE_VACANCY_SUCCESS',
  DELETE_VACANCY_FINISH: 'DELETE_VACANCY_FINISH',

  // Enable VACANCY
  ENABLE_VACANCY_REQUEST: 'ENABLE_VACANCY_REQUEST',
  ENABLE_VACANCY_SUCCESS: 'ENABLE_VACANCY_SUCCESS',
  ENABLE_VACANCY_FINISH: 'ENABLE_VACANCY_FINISH',

  // Disable VACANCY
  DISABLE_VACANCY_REQUEST: 'DISABLE_VACANCY_REQUEST',
  DISABLE_VACANCY_SUCCESS: 'DISABLE_VACANCY_SUCCESS',
  DISABLE_VACANCY_FINISH: 'DISABLE_VACANCY_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_VACANCY_REQUEST: 'SINGLE_ACTIVATE_VACANCY_REQUEST',
  SINGLE_ACTIVATE_VACANCY_SUCCESS: 'SINGLE_ACTIVATE_VACANCY_SUCCESS',
  SINGLE_ACTIVATE_VACANCY_FINISH: 'SINGLE_ACTIVATE_VACANCY_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_VACANCY_REQUEST: 'SINGLE_DEACTIVATE_VACANCY_REQUEST',
  SINGLE_DEACTIVATE_VACANCY_SUCCESS: 'SINGLE_DEACTIVATE_VACANCY_SUCCESS',
  SINGLE_DEACTIVATE_VACANCY_FINISH: 'SINGLE_DEACTIVATE_VACANCY_FINISH',
}
