import {Action} from 'redux'
import {AppointmentReasonModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAppointmentReasonState: IAppointmentReasonState = {
  data: {
    appointmentReason: [],
    meta: {},
  },

  loading: false,
  success: false,
  editSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
}

export interface IAppointmentReasonState {
  data: {
    appointmentReason: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  appointmentReasonList?: {appointmentReason: IAppointmentReasonState[] | any}
  loading: false
  success: false
  editSuccess: false
  activateSuccess: false
  deactivateSuccess: false
  deleteSuccess: false
  toggleLoading: false
  singleActivateSuccess: false
  singleDeactivateSuccess: false
}

export const reducer = (
  state: IAppointmentReasonState = initialAppointmentReasonState,
  action: ActionWithPayload<IAppointmentReasonState>
) => {
  switch (action.type) {
    //Get appointmentReason Reducer
    case actionTypes.GET_APPOINTMENT_REASON_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_APPOINTMENT_REASON_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_APPOINTMENT_REASON_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Get appointmentReason Reducer
    case actionTypes.GET_ALL_APPOINTMENT_REASON_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_APPOINTMENT_REASON_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_APPOINTMENT_REASON_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }
    //Add appointmentReason Reducer
    case actionTypes.ADD_APPOINTMENT_REASON_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_APPOINTMENT_REASON_SUCCESS: {
      const appointmentTypeData = action.payload
      return {...state, success: true, loading: false}
    }

    case actionTypes.ADD_APPOINTMENT_REASON_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    case actionTypes.ADD_APPOINTMENT_REASON_RESET: {
      return {...state, success: false, data: [], loading: false}
    }

    //update appointmentReason
    case actionTypes.UPDATE_APPOINTMENT_REASON_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_APPOINTMENT_REASON_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_APPOINTMENT_REASON_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.UPDATE_APPOINTMENT_REASON_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }
    //Delete appointmentReason
    case actionTypes.DELETE_APPOINTMENT_REASON_START: {
      return {...state, loading: true, deleteSuccess: false}
    }

    case actionTypes.DELETE_APPOINTMENT_REASON_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_APPOINTMENT_REASON_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Activate appointmentReason
    case actionTypes.ACTIVATE_APPOINTMENT_REASON_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ACTIVATE_APPOINTMENT_REASON_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.ACTIVATE_APPOINTMENT_REASON_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    //Single Activate appointmentReason
    case actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_SUCCESS: {
      const changedData: any = action.payload?.data?.appointmentReason
      let newData = state?.data?.appointmentReason?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, banner: newData},
        singleActivateSuccess: true,
        toggleLoading: false,
      }
    }
    case actionTypes.SINGLE_ACTIVATE_APPOINTMENT_REASON_FINISH: {
      return {...state, singleActivateSuccess: false, toggleLoading: false}
    }

    //Deactivate appointmentReason
    case actionTypes.DEACTIVATE_APPOINTMENT_REASON_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DEACTIVATE_APPOINTMENT_REASON_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.DEACTIVATE_APPOINTMENT_REASON_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_SUCCESS: {
      const changedData: any = action.payload?.data?.appointmentReason
      let newData = state?.data?.appointmentReason?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, appointmentReason: newData},
        singleDeactivateSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DEACTIVATE_APPOINTMENT_REASON_FINISH: {
      return {...state, singleDeactivateSuccess: false, toggleLoading: false}
    }

    default:
      return state
  }
}
