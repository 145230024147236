import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {SortCsrCategoryActionModel, SortCsrCategoryModel} from '../Model'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getCsrCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCsrCategory, params)
    yield put(actions.getCsrCategorySuccess(response?.data?.data))
    yield put(actions.getCsrCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCsrCategoryFinish())
  }
}

function* getCsrCategoryDropdownSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCsrCategoryDropdown)
    yield put(actions.getCsrCategoryDropdownSuccess(response?.data?.data))
    yield put(actions.getCsrCategoryDropdownFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCsrCategoryDropdownFinish())
  }
}

function* addCsrCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCsrCategory, body)
    yield put(actions.addCsrCategorySuccess(response.data?.data))
    yield put(actions.addCsrCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCsrCategoryFinish())
  }
}

function* updateCsrCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateCsrCategory, body, action.payload?.id)
    yield put(actions.updateCsrCategorySuccess(response.data?.data))
    yield put(actions.updateCsrCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCsrCategoryFinish())
  }
}

function* deleteCsrCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCsrCategory, body)
    yield put(actions.deleteCsrCategorySuccess(response.data?.data))
    yield put(actions.deleteCsrCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCsrCategoryFinish())
  }
}

function* singleActivateCsrCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateCsrCategory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_FINISH})
  }
}

function* singleDeactivateCsrCategory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateCsrCategory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_FINISH})
  }
}

function* sortCsrCategory(action: SortCsrCategoryActionModel) {
  try {
    const body: SortCsrCategoryModel = action.payload
    const response: ResponseModel = yield call(service.sortCsrCategory, body)

    yield put({
      type: actionTypes.SORT_CSR_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_CSR_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CSR_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_CSR_CATEGORY_START, getCsrCategorySaga)
  yield takeLatest(actionTypes.GET_CSR_CATEGORY_DROPDOWN_START, getCsrCategoryDropdownSaga)
  yield takeLatest(actionTypes.ADD_CSR_CATEGORY_START, addCsrCategorySaga)
  yield takeLatest(actionTypes.UPDATE_CSR_CATEGORY_START, updateCsrCategorySaga)
  yield takeLatest(actionTypes.DELETE_CSR_CATEGORY_START, deleteCsrCategorySaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_REQUEST, singleActivateCsrCategory)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_REQUEST, singleDeactivateCsrCategory)
  yield takeLatest(actionTypes.SORT_CSR_CATEGORY_START, sortCsrCategory)
}
