import {Action} from 'redux'
import {CATEGORY_TYPES, COMMON_TYPES} from './constant'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface ICategoryState {
  data?: {
    onlineApplicationCategory?: {[key: string]: number | string}[]
    meta?: {[key: string]: number}
  }
  success: false
  deleteSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  loading?: boolean
  sortApplicationCategoryData?: ICategoryState[]
}

const INIT_STATE: ICategoryState = {
  data: {
    onlineApplicationCategory: [],
    meta: {},
  },
  success: false,
  deleteSuccess: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
  loading: false,
  sortApplicationCategoryData: [],
}
export const reducer = (
  state: ICategoryState = INIT_STATE,
  action: ActionWithPayload<ICategoryState>
) => {
  switch (action.type) {
    case CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, data: action?.payload?.data, loading: false}
    case CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, success: true, loading: false}
    case CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, success: false, loading: false}
    case CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, success: true, loading: false}
    case CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, success: false, loading: false}
    case CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, deleteSuccess: true, loading: false}
    case CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, deleteSuccess: false, loading: false}
    case CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, singleActivateSuccess: true, loading: false}
    case CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, singleActivateSuccess: false, loading: false}
    case CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, singleDeactivateSuccess: true, loading: false}
    case CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, singleDeactivateSuccess: false, loading: false}
    case CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, activateSuccess: true, loading: false}
    case CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, activateSuccess: false, loading: false}
    case CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.SUCCESS:
      return {...state, deactivateSuccess: true, loading: false}
    case CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.CALL_OVER:
      return {...state, deactivateSuccess: false, loading: false}
    case CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.REQUEST: {
      return {...state, loading: true}
    }
    case CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.SUCCESS: {
      return {
        ...state,
        sortApplicationCategoryData: action?.payload,
        success: true,
        loading: false,
      }
    }
    case CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.CALL_OVER: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }
    case CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }
    case CATEGORY_TYPES.SORT_CATEGORY_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortApplicationCategoryData: [],
      }
    }
    default:
      return state
  }
}
