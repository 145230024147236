import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getLoanTenureSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getLoanTenure, params)
    yield put(actions.getLoanTenureSuccess(response?.data?.data))
    yield put(actions.getLoanTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getLoanTenureFinish())
  }
}

function* addLoanTenureSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addLoanTenure, body)
    yield put(actions.addLoanTenureSuccess(response.data?.data))
    yield put(actions.addLoanTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addLoanTenureFinish())
  }
}

function* updateLoanTenureSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateLoanTenure, body, action.payload?.id)
    yield put(actions.updateLoanTenureSuccess(response.data?.data))
    yield put(actions.updateLoanTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateLoanTenureFinish())
  }
}

function* deleteLoanTenureSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteLoanTenure, body)
    yield put(actions.deleteLoanTenureSuccess(response.data?.data))
    yield put(actions.deleteLoanTenureFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteLoanTenureFinish())
  }
}

function* singleActivateLoanTenure(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateLoanTenure,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_FINISH})
  }
}

function* singleDeactivateLoanTenure(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateLoanTenure,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_FINISH})
  }
}

function* sortLoanTenure(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortLoanTenure, body)

    yield put({
      type: actionTypes.SORT_LOAN_TENURE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_LOAN_TENURE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_LOAN_TENURE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_LOAN_TENURE_START, getLoanTenureSaga)
  yield takeLatest(actionTypes.ADD_LOAN_TENURE_START, addLoanTenureSaga)
  yield takeLatest(actionTypes.UPDATE_LOAN_TENURE_START, updateLoanTenureSaga)
  yield takeLatest(actionTypes.DELETE_LOAN_TENURE_START, deleteLoanTenureSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_REQUEST, singleActivateLoanTenure)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_REQUEST, singleDeactivateLoanTenure)
  yield takeLatest(actionTypes.SORT_LOAN_TENURE_START, sortLoanTenure)
}
