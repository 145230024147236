import axios from 'axios'
import {Form, Formik} from 'formik'
import moment from 'moment'
import {useState} from 'react'
import Select from 'react-select'
import {toast} from 'react-toastify'
import {Modal} from 'rsuite'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as Yup from 'yup'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

type Props = {
  selectedCandidate: any
  selectedSatusId: any
  setShowExamCenterModal: any
  examCenterData: any
  handleStatusUpdate: any
  setSelectedCandidate: any
}

const FORM_VALIDATION = {
  examCenterId: Yup.string().required('Exam center is required'),
}

export default function ChooseExamCenter({
  selectedCandidate,
  setShowExamCenterModal,
  selectedSatusId,
  examCenterData,
  handleStatusUpdate,
  setSelectedCandidate,
}: Props) {
  const [loading, setLoading] = useState(false)
  const handleFormSubmit = (payload: any) => {
    setLoading(true)

    axios
      .post(`${API_URL}/apply-vacancy/update-exam-status`, payload)
      .then((res) => {
        const message = res.data.data
        setLoading(false)
        handleStatusUpdate(message)
        setShowExamCenterModal(false)
      })
      .catch((err) => {
        setLoading(false)
        toast.error(
          err?.response?.data?.message ? err.response.data.message : 'Something went wrong'
        )
      })
  }

  return (
    <Modal
      open={true}
      className='w-25'
      backdrop='static'
      keyboard={false}
      enforceFocus={false}
      onClose={() => {
        setShowExamCenterModal(false)
      }}
      data-cy='modal'
    >
      <Modal.Header>
        <Modal.Title data-cy='modal-title'>Assign exam center </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pb-0'>
        <Formik
          initialValues={{
            vacancyApplicantId: selectedCandidate || [],
            examCenterId: '',
            date: '',
            time: '',
          }}
          // validationSchema={FORM_VALIDATION}
          onSubmit={(values: any, {setSubmitting}) => {
            const payload = {
              vacancyApplicantStatus: values.vacancyApplicantId.map((item: any) => {
                return {vacancyApplicantId: item.value}
              }),
              examCenterId: values.examCenterId,
              vacancyApplicantStatusId: selectedSatusId,
              date: moment(values.date).format('YYYY-MM-DD'),
              time: moment(values?.time).format('HH:mm a'),
            }

            handleFormSubmit(payload)
          }}
        >
          {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
            return (
              <Form>
                <div className='card-body border-top pt-5'>
                  <div className='row'>
                    <div className='col-12  mb-2'>
                      <label className={`mb-2 fw-bolder fs-6 ${'col-md-12' || 'col-lg-4'}`}>
                        <span>{'Selected Candidate'}</span>
                      </label>
                      <Select
                        placeholder='Please select'
                        name='vacancyApplicantId'
                        defaultValue={selectedCandidate}
                        isMulti={true}
                        options={selectedCandidate}
                        isDisabled={true}
                      />
                    </div>
                    <div className='col-12'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-md-12'
                        placeholder='Please select'
                        label='Select Exam Center'
                        name='examCenterId'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={examCenterData}
                        values={values}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                    <div className='col-12'>
                      <FormDatepicker
                        labelClassName='col-12'
                        containerClassName='col-12 '
                        label=' Date'
                        name='date'
                        placeholderText='Please select a date'
                        setFieldValue={setFieldValue}
                        value={values.date || false}
                        touched={touched}
                        required={true}
                        showIcon={true}
                        errors={errors}
                        dateFormat='yyyy/MM/dd'
                        minDate={new Date()}
                      />
                    </div>
                    <div className='col-12'>
                      <FormDatepicker
                        labelClassName='col-12'
                        containerClassName='col-12 '
                        label='Time'
                        name='time'
                        placeholderText='Please select a time'
                        setFieldValue={setFieldValue}
                        value={values.time || false}
                        touched={touched}
                        required={true}
                        showIcon={true}
                        showTimeSelect={true}
                        showTimeSelectOnly={true}
                        errors={errors}
                        dateFormat='h:mm aa'
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex justify-content-end  '>
                  <button
                    type='submit'
                    disabled={loading}
                    className='btn btn-primary btn-sm ms-3'
                    data-cy='modal-submit'
                  >
                    Save
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}
