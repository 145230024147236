import {Action} from 'redux'
import {BankDetailModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialBankDetailState: IAboutBankDetailState = {
  data: {
    aboutBankDetail: [],
    meta: {},
  },
  sortBankDetailData: [],
  bankDetailFileOption: [],
  bankDetailList: {album: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IAboutBankDetailState {
  data: {
    aboutBankDetail: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortBankDetailData?: IAboutBankDetailState[]
  bankDetailFileOption?: []
  bankDetailList?: {album: BankDetailModel[] | any}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IAboutBankDetailState = initialBankDetailState,
  action: ActionWithPayload<IAboutBankDetailState>
) => {
  switch (action.type) {
    //GET BankDetail Data
    case actionTypes.GET_ABOUT_BANK_DETAIL_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ABOUT_BANK_DETAIL_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_ABOUT_BANK_DETAIL_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET BankDetail File type Data
    case actionTypes.GET_ABOUT_BANK_DETAIL_FILE_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ABOUT_BANK_DETAIL_FILE_TYPE_SUCCESS: {
      return {...state, bankDetailFileOption: action.payload?.bankDetailFileOption, loading: false}
    }

    case actionTypes.GET_ABOUT_BANK_DETAIL_FILE_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add BankDetail
    case actionTypes.ADD_ABOUT_BANK_DETAIL_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_ABOUT_BANK_DETAIL_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_ABOUT_BANK_DETAIL_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update BankDetail
    case actionTypes.UPDATE_ABOUT_BANK_DETAIL_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_ABOUT_BANK_DETAIL_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_ABOUT_BANK_DETAIL_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_ABOUT_BANK_DETAIL_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_ABOUT_BANK_DETAIL_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_ABOUT_BANK_DETAIL_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_ABOUT_BANK_DETAIL_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_ABOUT_BANK_DETAIL_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_SUCCESS: {
      const changedData: any = action.payload?.data?.aboutBankDetail
      let newData = state?.data?.aboutBankDetail?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state?.data?.meta, album: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_SUCCESS: {
      const changedData: any = action.payload?.data?.aboutBankDetail
      let newData = state?.data?.aboutBankDetail?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state?.data?.meta, album: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Get BankDetail Reducer
    case actionTypes.GET_ALL_ABOUT_BANK_DETAIL_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        bankDetailList: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_ABOUT_BANK_DETAIL_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // sort
    case actionTypes.SORT_ABOUT_BANK_DETAIL_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_ABOUT_BANK_DETAIL_SUCCESS: {
      return {
        ...state,
        sortBankDetailData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_ABOUT_BANK_DETAIL_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_ABOUT_BANK_DETAIL_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortBankDetailData: [],
      }
    }
    default:
      return state
  }
}
