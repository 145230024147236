import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteBaseRateModel, BaseRateModel} from '../Model'
import {actionTypes} from './constants'
import {IInterestRateLoanFileState} from './reducer'
export const actions = {
  // get BaseRate DATA
  getBaseRate: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BASE_RATE_START,
    payload: params,
  }),
  getBaseRateSuccess: (data: BaseRateModel) => ({
    type: actionTypes.GET_BASE_RATE_SUCCESS,
    payload: data,
  }),
  getBaseRateFinish: () => ({
    type: actionTypes.GET_BASE_RATE_FINISH,
  }),

  //Get interest rate loan file csv slsx
  getBaseRateFile: () => {
    return {
      type: actionTypes.GET_BASE_RATE_FILE_START,
    }
  },

  getBaseRateFileSuccess: (data: IInterestRateLoanFileState) => ({
    type: actionTypes.GET_BASE_RATE_FILE_SUCCESS,
    payload: data,
  }),
  getBaseRateFileError: (data: IInterestRateLoanFileState) => ({
    type: actionTypes.GET_BASE_RATE_FILE_FINISH,
    payload: data,
  }),

  //Get interest rate Deposit file csv slsx
  getInterestSpreadFile: () => {
    return {
      type: actionTypes.GET_INTEREST_SPREAD_FILE_START,
    }
  },

  getInterestSpreadFileSuccess: (data: IInterestRateLoanFileState) => ({
    type: actionTypes.GET_INTEREST_SPREAD_FILE_SUCCESS,
    payload: data,
  }),
  getInterestSpreadFileError: (data: IInterestRateLoanFileState) => ({
    type: actionTypes.GET_INTEREST_SPREAD_FILE_FINISH,
    payload: data,
  }),
  // create InterestRate
  addBaseRate: (data: BaseRateModel | any) => ({
    type: actionTypes.ADD_BASE_RATE_START,
    payload: data,
  }),
  addBaseRateSuccess: (task: any) => ({
    type: actionTypes.ADD_BASE_RATE_SUCCESS,
    payload: task,
  }),
  addBaseRateFinish: () => ({
    type: actionTypes.ADD_BASE_RATE_FINISH,
  }),
  resetBaseRate: () => ({
    type: actionTypes.RESET_BASE_RATE_TYPE,
  }),

  //Update InterestRate
  updateBaseRate: (data: BaseRateModel | any, id: string) => ({
    type: actionTypes.UPDATE_BASE_RATE_START,
    payload: data,
    id,
  }),

  updateBaseRateSuccess: (data: BaseRateModel) => ({
    type: actionTypes.UPDATE_BASE_RATE_SUCCESS,
    payload: data,
  }),

  updateBaseRateFinish: () => ({
    type: actionTypes.UPDATE_BASE_RATE_FINISH,
  }),

  // delete InterestRate
  deleteBaseRate: (data: DeleteBaseRateModel[]) => ({
    type: actionTypes.DELETE_BASE_RATE_START,
    payload: {baseRateInterestSpreadId: data},
  }),
  deleteBaseRateSuccess: (data: any) => ({
    type: actionTypes.DELETE_BASE_RATE_SUCCESS,
    payload: data,
  }),
  deleteBaseRateFinish: () => ({
    type: actionTypes.DELETE_BASE_RATE_FINISH,
  }),

  //Enable InterestRate
  enableBaseRate: (data: any) => ({
    type: actionTypes.ENABLE_BASE_RATE_REQUEST,
    payload: {data},
  }),

  enableBaseRateSuccess: (task: any) => ({
    type: actionTypes.ENABLE_BASE_RATE_SUCCESS,
    payload: task,
  }),
  enableBaseRateFinish: () => ({
    type: actionTypes.ENABLE_BASE_RATE_FINISH,
  }),

  //Disable InterestRate
  disableBaseRate: (data: any) => ({
    type: actionTypes.DISABLE_BASE_RATE_REQUEST,
    payload: {data},
  }),

  disableBaseRateSuccess: (task: any) => ({
    type: actionTypes.DISABLE_BASE_RATE_SUCCESS,
    payload: task,
  }),
  disableBaseRateFinish: () => ({
    type: actionTypes.DISABLE_BASE_RATE_FINISH,
  }),

  //Single Enable InterestRate
  singleEnableBaseRate: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_BASE_RATE_REQUEST,
    payload: {data},
  }),

  singleEnableBaseRateSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_BASE_RATE_SUCCESS,
    payload: task,
  }),
  singleEnableBaseRateFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_BASE_RATE_FINISH,
  }),

  //Single Disable InterestRate
  singleDisableBaseRate: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_BASE_RATE_REQUEST,
    payload: {data},
  }),

  singleDisableBaseRateSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_BASE_RATE_SUCCESS,
    payload: task,
  }),
  singleDisableBaseRateFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_BASE_RATE_FINISH,
  }),
}
