import { ErrorMessage, FormikErrors } from 'formik'
import { isEmpty } from 'lodash'
import { ChangeEvent, useState } from 'react'
import { FormOptionModal } from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import { imageBaseUrl } from 'src/cms/helpers/constants'
import * as Yup from 'yup'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors?: FormikErrors<{ [key: string]: string }> | any
  touched?: any
  values?: any
  bannerOptions?: FormOptionModal[]
  menuOptions?: any
  categoryOptions?: FormOptionModal[]
  landingPageOption?: FormOptionModal[]
  leadFormOptions?: FormOptionModal[]
  pageHeaderOptions?: FormOptionModal[]
  reviewAndRatingOptions?: FormOptionModal[]
  bannerData?: FormOptionModal[]
  editSelectedData?: { [key: string]: string }
  validationState?: { [key: string]: Yup.StringSchema }
  setValidationState?: any
  setFieldValue?: any
}

export function GeneralComponent({
  handleChange,
  errors,
  touched,
  values,
  categoryOptions,
  leadFormOptions,
  pageHeaderOptions,
  editSelectedData,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {
  const [image, setImage] = useState('')
  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]
  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please Select Category'
              label='Program Category'
              name='category_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={categoryOptions}
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              placeholder='Enter Program Name'
              name='title'
              label='Program Title'
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required={true}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Enter Slug'
              label='Slug'
              name='slug'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              disabled={true}
              value={
                !isEmpty(editSelectedData)
                  ? editSelectedData?.slug
                  : values?.title.replace(/\s/g, '-').toLowerCase()
              }
            />
          </div>

          <div className='col-md-6 col-xs-12'>
            <FormTinyMce
              containerClassName='col-md-12'
              label='Short Description'
              name='short_description'
              initialValue={!isEmpty(editSelectedData) ? editSelectedData?.short_description : ''}
              handleChange={handleChange}
            />
          </div>

          <div className='col-md-6 col-xs-12'>
            <FormTinyMce
              containerClassName='col-md-12'
              label='Long Description'
              name='long_description'
              initialValue={!isEmpty(editSelectedData) ? editSelectedData?.long_description : ''}
              handleChange={handleChange}
            />
          </div>

          <div className='col-md-6 col-xs-12'>
            <FormInputMediaManager
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              label='Image '
              name='image'
              setFieldValue={setFieldValue}
              setImageUrl={setImage}
              value={values?.image}
              showImageOnly={true}
            />

            {!isEmpty(values?.image) ? (
              <>
                <li className='listing'>
                  <div className='thumbImageBlock'>
                    <button
                      type='button'
                      title='Remove'
                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                      onClick={() => {
                        setImage('')
                        setFieldValue('image', '')
                      }}
                      data-cy='modal-thumbnail-remove'
                    >
                      Delete
                    </button>

                    <img
                      className='thumbImage w-100 h-100'
                      src={`${imageBaseUrl}/${values?.image}`}
                      alt=''
                      data-cy='modal-thumbnail-image'
                    />
                  </div>
                </li>
              </>
            ) : null}
          </div>

          {/* <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select page header'
              label='Enable Page Header'
              name='page_header_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={pageHeaderOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div> */}

          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select Lead Form'
              label='Show Lead Form'
              name='lead_option_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={leadFormOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>

            <div className='d-flex ms-5'>
              {statusOptions?.map((status: { label: string; value: string }) => (
                <FormRadio
                  key={status?.value}
                  containerClassName=''
                  label='Select Status'
                  name='status'
                  type='radio'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  checkBoxText={status?.label}
                  value={status?.value}
                />
              ))}
            </div>
            <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
              <ErrorMessage name='status' component='div' className='field-error-message' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
