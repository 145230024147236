export const actionTypes = {
  // get
  GET_BRANCH_CATEGORY_START: 'GET_BRANCH_CATEGORY_START',
  GET_BRANCH_CATEGORY_SUCCESS: 'GET_BRANCH_CATEGORY_SUCCESS',
  GET_BRANCH_CATEGORY_FINISH: 'GET_BRANCH_CATEGORY_FINISH',

  // extended hours
  GET_EXTENDED_HOURS_START: 'GET_EXTENDED_HOURS_START',
  GET_EXTENDED_HOURS_SUCCESS: 'GET_EXTENDED_HOURS_SUCCESS',
  GET_EXTENDED_HOURS_FINISH: 'GET_EXTENDED_HOURS_FINISH',

  // Get Branch Data
  GET_BRANCH_DATA_START: 'GET_BRANCH_DATA_START',
  GET_BRANCH_DATA_SUCCESS: 'GET_BRANCH_DATA_SUCCESS',
  GET_BRANCH_DATA_FINISH: 'GET_BRANCH_DATA_FINISH',

  // Get Branch Data
  GET_BRANCH_LIST_DATA_START: 'GET_BRANCH_LIST_DATA_START',
  GET_BRANCH_LIST_DATA_SUCCESS: 'GET_BRANCH_LIST_DATA_SUCCESS',
  GET_BRANCH_LIST_DATA_FINISH: 'GET_BRANCH_LIST_DATA_FINISH',

  // Get Branch Data
  GET_ALL_BRANCH_DATA_START: 'GET_ALL_BRANCH_DATA_START',
  GET_ALL_BRANCH_DATA_SUCCESS: 'GET_ALL_BRANCH_DATA_SUCCESS',
  GET_ALL_BRANCH_DATA_FINISH: 'GET_ALL_BRANCH_DATA_FINISH',

  // add branch
  ADD_BRANCH_START: 'ADD_BRANCH_START',
  ADD_BRANCH_SUCCESS: 'ADD_BRANCH_SUCCESS',
  ADD_BRANCH_FINISH: 'ADD_BRANCH_FINISH',
  RESET_BRANCH: 'RESET_BRANCH',

  // update branch
  UPDATE_BRANCH_START: 'UPDATE_BRANCH_START',
  UPDATE_BRANCH_SUCCESS: 'UPDATE_BRANCH_SUCCESS',
  UPDATE_BRANCH_FINISH: 'UPDATE_BRANCH_FINISH',

  // delete branch
  DELETE_BRANCH_START: 'DELETE_BRANCH_START',
  DELETE_BRANCH_SUCCESS: 'DELETE_BRANCH_SUCCESS',
  DELETE_BRANCH_FINISH: 'DELETE_BRANCH_FINISH',

  // Import branch
  IMPORT_BRANCH_START: 'IMPORT_BRANCH_START',
  IMPORT_BRANCH_SUCCESS: 'IMPORT_BRANCH_SUCCESS',
  IMPORT_BRANCH_FINISH: 'IMPORT_BRANCH_FINISH',

  // Enable Branch
  ENABLE_BRANCH_REQUEST: 'ENABLE_BRANCH_REQUEST',
  ENABLE_BRANCH_SUCCESS: 'ENABLE_BRANCH_SUCCESS',
  ENABLE_BRANCH_FINISH: 'ENABLE_BRANCH_FINISH',

  // Disable Branch
  DISABLE_BRANCH_REQUEST: 'DISABLE_BRANCH_REQUEST',
  DISABLE_BRANCH_SUCCESS: 'DISABLE_BRANCH_SUCCESS',
  DISABLE_BRANCH_FINISH: 'DISABLE_BRANCH_FINISH',

  // Enable Branch
  SINGLE_ENABLE_BRANCH_REQUEST: 'SINGLE_ENABLE_BRANCH_REQUEST',
  SINGLE_ENABLE_BRANCH_SUCCESS: 'SINGLE_ENABLE_BRANCH_SUCCESS',
  SINGLE_ENABLE_BRANCH_FINISH: 'SINGLE_ENABLE_BRANCH_FINISH',

  // Disable Branch
  SINGLE_DISABLE_BRANCH_REQUEST: 'SINGLE_DISABLE_BRANCH_REQUEST',
  SINGLE_DISABLE_BRANCH_SUCCESS: 'SINGLE_DISABLE_BRANCH_SUCCESS',
  SINGLE_DISABLE_BRANCH_FINISH: 'SINGLE_DISABLE_BRANCH_FINISH',

  // get File csv xlsx
  EXPORT_BRANCH_FILE_START: 'EXPORT_BRANCH_FILE_START',
  EXPORT_BRANCH_FILE_SUCCESS: 'EXPORT_BRANCH_FILE_SUCCESS',
  EXPORT_BRANCH_FILE_FINISH: 'EXPORT_BRANCH_FILE_FINISH',

  EXPORT_BRANCH_TEMPLATE_FILE_START: 'EXPORT_BRANCH_TEMPLATE_FILE_START',
  EXPORT_BRANCH_TEMPLATE_FILE_SUCCESS: 'EXPORT_BRANCH_TEMPLATE_FILE_SUCCESS',
  EXPORT_BRANCH_TEMPLATE_FILE_FINISH: 'EXPORT_BRANCH_TEMPLATE_FILE_FINISH',
}
