import {FieldArray, Form, Formik, useFormikContext} from 'formik'
import {cloneDeep, groupBy, isEmpty, mapValues} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as tisaForexRateCategoryRedux from '../../../tisaForexRateCategory/redux/index'
import * as tisaForexRateAdd from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddTisaForexRates = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, editSuccess} = useSelector((state: any) => state.tisaForexRate)

  const tisaForexRateCategory = useSelector((state: any) => state.tisaForexRateCategory)

  useEffect(() => {
    dispatch(tisaForexRateCategoryRedux?.actions.getTisaForexRateCategory(params))
  }, [params])

  useEffect(() => {
    if (editSuccess) {
      dispatch(tisaForexRateAdd?.actions.getTisaForexRate(params))
      isEmpty(editSelectedData)
        ? toast.success('Forex Exchange Rates added successfully')
        : toast.success('Forex Exchange Rates edited successfully')
      handleClose()
      dispatch(tisaForexRateAdd?.actions.updateTisaForexRateReset())
    }
  }, [editSuccess])

  const AddEditForm = () => {
    // Grab values and submitForm from context
    const {resetForm, setFieldValue} = useFormikContext()

    useEffect(() => {
      if (!isEmpty(editSelectedData)) {
        const fields = ['forexRateCategories']
        fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
      } else {
        resetForm({
          values: {
            forexRateCategories: [
              {
                ...categoryData,
              },
            ],
          },
        })
      }
    }, [editSelectedData])
    return null
  }

  //To ignore the dot in name field
  const formatCategoryData = tisaForexRateCategory?.data?.forexRateCategory?.map((obj: any) => ({
    ...obj,
    name: obj?.name?.replace(/[.,]/g, ''),
  }))

  const categoryData = mapValues(groupBy(formatCategoryData, 'name'), (obj2: any) =>
    obj2.map((item: any) => ({
      ...item,
      unit: 0,
      notes_buy: 0,
      tt_buy: 0,
      am_buy: 0,
      tt_sell: 0,
      notes_sell: 0,
      feature_order: 0,
    }))
  )

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Forex Exchange Rates </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                forexRateCategories: [
                  {
                    ...categoryData,
                  },
                ],
              }}
              onSubmit={(values: any) => {
                let formData: any = []

                let tisaForexRateData = cloneDeep(values?.forexRateCategories[0] || 0)
                Object.keys(tisaForexRateData).map((key, index) => {
                  formData.push(tisaForexRateData[key][0] || 0)
                })

                const newFormData = formData?.map((item: any) => {
                  return {
                    id: item?.id,
                    unit: Number(item?.unit),
                    notes_buy: Number(item?.notes_buy),
                    tt_buy: Number(item?.tt_buy),
                    am_buy: Number(item?.am_buy),
                    tt_sell: Number(item?.tt_sell),
                    notes_sell: Number(item?.notes_sell),
                    feature_order: Number(item?.features_order),
                  }
                })

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    tisaForexRateAdd?.actions.updateTisaForexRate(
                      {forexRateCategories: newFormData},
                      formData[0].tisaForexRateRateId
                    )
                  )
                } else {
                  dispatch(
                    tisaForexRateAdd?.actions.CreateTisaForexRate({
                      forexRateCategories: formData,
                    })
                  )
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                handleSubmit,
                errors,
                handleBlur,
                setFieldValue,
                values,
              }) => {
                return (
                  <Form>
                    <div>
                      <table className='table'>
                        <thead className='thead-dark'>
                          <tr>
                            <th scope='col'>Currency</th>
                            <th scope='col'>Unit</th>
                            <th scope='col'>Notes Buy</th>
                            <th scope='col'>TT Buy</th>
                            <th scope='col'>A/M Buy</th>
                            <th scope='col'>TT Sell</th>
                            <th scope='col'>Note Sell</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='forexRateCategories'
                            render={(arrayHelpers) =>
                              values?.forexRateCategories?.map((item: any) =>
                                Object.keys(item).map((key, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{key}</td>
                                      <td>
                                        <div className='form-group'>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].unit`}
                                            defaultValue={0}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.unit || 0
                                            }
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].notes_buy`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.notes_buy || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].tt_buy`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.tt_buy || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].am_buy`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.am_buy || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].tt_sell`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.tt_sell || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`forexRateCategories[0][${key}][0].notes_sell`}
                                            value={
                                              values?.forexRateCategories[0][key][0]?.notes_sell ||
                                              0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              )
                            }
                          />
                        </tbody>
                      </table>
                    </div>
                    <AddEditForm />
                    <div className='d-flex justify-content-end px-5 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddTisaForexRates
