import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialServicePartnerState: IServicePartnerState = {
  data: {
    servicePartner: [],
    meta: {},
  },

  countryList: [],
  currencyList: [],
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  importLoading: false,
  importSuccess: false,
  countryLoading: false,
  countrySuccess: false,
  currencyLoading: false,
  currencySuccess: false,
  loading: false,
  success: false,
}

export interface IServicePartnerState {
  data?: {
    servicePartner?: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  countryList?: any[]
  currencyList?: any[]
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  importLoading?: boolean
  importSuccess?: boolean
  countryLoading?: boolean
  countrySuccess?: boolean
  currencyLoading?: boolean
  currencySuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IServicePartnerState = initialServicePartnerState,
  action: ActionWithPayload<IServicePartnerState>
) => {
  switch (action.type) {
    //GET SERVICE PARTNER Data
    case actionTypes.GET_SERVICE_PARTNER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SERVICE_PARTNER_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SERVICE_PARTNER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add SERVICE PARTNER
    case actionTypes.ADD_SERVICE_PARTNER_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_SERVICE_PARTNER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_SERVICE_PARTNER_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update SERVICE PARTNER
    case actionTypes.UPDATE_SERVICE_PARTNER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_SERVICE_PARTNER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_SERVICE_PARTNER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_SERVICE_PARTNER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_SERVICE_PARTNER_REQUEST: {
      return {...state, loading: true, enableSuccess: false}
    }

    case actionTypes.ENABLE_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_SERVICE_PARTNER_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_SERVICE_PARTNER_REQUEST: {
      return {...state, loading: true, disableSuccess: false}
    }

    case actionTypes.DISABLE_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_SERVICE_PARTNER_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_SUCCESS: {
      return {
        ...state,
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Import
    case actionTypes.IMPORT_SERVICE_PARTNER_REQUEST: {
      return {...state, importLoading: true, importSuccess: false}
    }

    case actionTypes.IMPORT_SERVICE_PARTNER_SUCCESS: {
      return {...state, data: action.payload, importLoading: true, importSuccess: true}
    }

    case actionTypes.IMPORT_SERVICE_PARTNER_FINISH: {
      return {...state, importLoading: false, importSuccess: false}
    }
    //Country
    case actionTypes.GET_SERVICE_PARTNER_COUNTRY_REQUEST: {
      return {...state, countryLoading: true}
    }

    case actionTypes.GET_SERVICE_PARTNER_COUNTRY_SUCCESS: {
      return {...state, countryList: action.payload, countryLoading: false}
    }

    case actionTypes.GET_SERVICE_PARTNER_COUNTRY_FINISH: {
      return {...state, countryLoading: false}
    }
    //Currency
    case actionTypes.GET_SERVICE_PARTNER_CURRENCY_REQUEST: {
      return {...state, currencyLoading: true}
    }

    case actionTypes.GET_SERVICE_PARTNER_CURRENCY_SUCCESS: {
      return {...state, currencyList: action.payload, currencyLoading: false}
    }

    case actionTypes.GET_SERVICE_PARTNER_CURRENCY_FINISH: {
      return {...state, currencyLoading: false}
    }

    default:
      return state
  }
}

//For file uplaod
const initialServicePartnerFileState: IServicePartnerRateFileState = {
  data: {
    file: {},
  },
  loading: false,
  success: false,
}

export interface IServicePartnerRateFileState {
  data: {
    file: {[key: string]: number}
  }
  loading?: boolean
  success?: boolean
}

export const reducer1 = (
  state: IServicePartnerRateFileState = initialServicePartnerFileState,
  action: ActionWithPayload<IServicePartnerRateFileState>
) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_ERROR: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
