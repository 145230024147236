import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SortFormDownloadActionModel, SortFormDownloadModel} from '../Model'
import { ServiceManagerResponseType } from 'src/app/modules/services/components/serviceManager/Model'

function* getCertificateSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCertificate, params)
    yield put(actions.getCertificateSuccess(response?.data?.data))
    yield put(actions.getCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCertificateFinish())
  }
}

function* getCertificateListSaga() {
  try {
    const response: ServiceManagerResponseType = yield call(service.getCertificateListSaga)
    yield put(actions.getCertificateListSuccess(response?.data?.data?.serviceManager))
    yield put(actions.getCertificateListFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCertificateListFinish())
  }
}

function* getAllCertificateSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllCertificate)
    yield put(actions.getAllCertificateSuccess(response?.data?.data))
    yield put(actions.getAllCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllCertificateFinish())
  }
}

function* addCertificateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCertificate, body)
    yield put(actions.addCertificateSuccess(response.data?.data))
    yield put(actions.addCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCertificateFinish())
  }
}

function* enableCertificateSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableCertificate, selectedUsers)
    yield put(actions.enableCertificateSuccess(response?.data))
    yield put(actions.enableCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCertificateFinish())
  }
}

function* disableCertificateSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableCertificate, selectedUsers)
    yield put(actions.disableCertificateSuccess(response?.data))
    yield put(actions.disableCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCertificateFinish())
  }
}

function* singleEnableCertificateSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableCertificate, selectedUsers)
    yield put(actions.singleEnableCertificateSuccess(response?.data))
    yield put(actions.singleEnableCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableCertificateFinish())
  }
}

function* singleDisableCertificateSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableCertificate, selectedUsers)
    yield put(actions.singleDisableCertificateSuccess(response?.data))
    yield put(actions.singleDisableCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableCertificateFinish())
  }
}

function* updateCertificateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateCertificate,
      body,
      action.payload?.id
    )
    yield put(actions.updateCertificateSuccess(response.data?.data))
    yield put(actions.updateCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCertificateFinish())
  }
}

function* deleteCertificateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCertificate, body)
    yield put(actions.deleteCertificateSuccess(response.data?.data))
    yield put(actions.deleteCertificateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCertificateFinish())
  }
}

function* sortCertificate(action: SortFormDownloadActionModel) {
  try {
    const body: SortFormDownloadModel = action.payload
    const response: ResponseModel = yield call(service.sortCertificate, body)

    yield put({
      type: actionTypes.SORT_CERTIFICATE_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_CERTIFICATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CERTIFICATE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_CERTIFICATE_START, getCertificateSaga)
  yield takeLatest(actionTypes.GET_ALL_CERTIFICATE_START, getAllCertificateSaga)
  yield takeLatest(actionTypes.ADD_CERTIFICATE_START, addCertificateSaga)
  yield takeLatest(actionTypes.UPDATE_CERTIFICATE_START, updateCertificateSaga)
  yield takeLatest(actionTypes.DELETE_CERTIFICATE_START, deleteCertificateSaga)
  yield takeLatest(actionTypes.ENABLE_CERTIFICATE_REQUEST, enableCertificateSaga)
  yield takeLatest(actionTypes.DISABLE_CERTIFICATE_REQUEST, disableCertificateSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_CERTIFICATE_REQUEST, singleEnableCertificateSaga)
  yield takeLatest(actionTypes.GET_CERTIFICATE_LIST_START, getCertificateListSaga)
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_CERTIFICATE_REQUEST,
    singleDisableCertificateSaga
  )
  yield takeLatest(actionTypes.SORT_CERTIFICATE_START, sortCertificate)
}
