import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ISettingTypeState } from 'src/app/modules/siteSettings/components/settings'

const checkCollegeSetting = () => {
  const [isCollegeSettings, setCollegeSettings] = useState<boolean>(false)
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['College Settings']?.map((item: any) => {
          if (item?.name === 'isCollege') {
            let data = item?.value === '1' ? true : false
            setCollegeSettings(data)
          }
        })
    }
  }, [settingTypeData])
  return isCollegeSettings
}

export default checkCollegeSetting
