import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteApplicationCategoryModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const getAllCategoryAPI = (params: ParamsModel) => {
  return axios.get(`${API_URL}/online-application-category`, {params})
}
export const createCategoryAPI = (data: any) => {
  return axios.post(`${API_URL}/online-application-category`, data)
}
export const updateCategoryAPI = (data: any, id: any) => {
  return axios.patch(`${API_URL}/online-application-category/${id}`, data)
}
export const deleteCategoryAPI = (data: DeleteApplicationCategoryModel) => {
  return axios.delete(`${API_URL}/online-application-category`, {data})
}
export const singleActivateCategoryAPI = (data: any) => {
  return axios.patch(`${API_URL}/online-application-category/enable`, data)
}
export const singleDeactivateCategoryAPI = (data: any) => {
  return axios.patch(`${API_URL}/online-application-category/disable`, data)
}
export const sortCategoryAPI = (data: any) => {
  return axios.patch(`${API_URL}/online-application-category/sort`, data)
}
