import {FieldArray, Form, Formik, useFormikContext} from 'formik'
import {cloneDeep, groupBy, isEmpty, mapValues} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'

//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as exchangeRateRedux from '../index'

import moment from 'moment'
import Select from 'react-select'
import * as Yup from 'yup'

const FORM_VALIDATION = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  rateType: Yup.string().required('Rate Type is required'),
})

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const rateType = [
  {label: '1st Rate', value: 'Early'},
  {label: '2nd Rate', value: 'After On'},
]

const AddExchangeRate = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, editSuccess} = useSelector((state: any) => state.exchangeRate)

  const {servicePartnerRateCategory} = useSelector(
    (state: any) => state.servicePartner?.currencyList
  )
  useEffect(() => {
    if (editSuccess) {
      dispatch(exchangeRateRedux?.actions.getExchangeRate(params))
      isEmpty(editSelectedData)
        ? toast.success('Exchange Rate added successfully')
        : toast.success('Exchange Rate edited successfully')
      handleClose()
      dispatch(exchangeRateRedux.actions.resetExchangeRate())
    }
  }, [editSuccess])

  const AddEditForm = () => {
    // Grab values and submitForm from context
    const {resetForm, setFieldValue} = useFormikContext()
    useEffect(() => {
      if (!isEmpty(editSelectedData)) {
        const fields = ['servicePartnerRateCategories']
        fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
      } else {
        resetForm({
          values: {
            servicePartnerRateCategories: [
              {
                ...categoryData,
              },
            ],
          },
        })
      }
    }, [editSelectedData])
    return null
  }
  //To ignore the dot in name field
  const formatCategoryData = servicePartnerRateCategory?.map((obj: any) => ({
    ...obj,
    name: obj?.name?.replace(/[.,]/g, ''),
  }))

  const categoryData = mapValues(groupBy(formatCategoryData, 'name'), (obj2: any) =>
    obj2.map((item: any) => ({
      ...item,
      unit: 0,
      cash_buy: 0,
      non_cash_buy: 0,
      sell: 0,
      feature_order: 0,
    }))
  )
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Exchange Rates </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                servicePartnerRateCategories: [
                  {
                    ...categoryData,
                  },
                ],
                date: moment(editSelectedData?.date).toDate() ?? '',
                rateType: editSelectedData?.rateType ?? '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                let formData: any = []

                let forexRateData = cloneDeep(values?.servicePartnerRateCategories[0] || 0)
                Object.keys(forexRateData).map((key, index) => {
                  formData.push(forexRateData[key][0] || 0)
                })

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    exchangeRateRedux?.actions.updateExchangeRate(
                      {
                        servicePartnerRateCategories: formData,
                        date: values.date,
                        rateType: values.rateType,
                      },
                      formData[0].exchangeRateId
                    )
                  )
                } else {
                  dispatch(
                    exchangeRateRedux?.actions.addExchangeRate({
                      servicePartnerRateCategories: formData,
                      date: values.date,
                      rateType: values.rateType,
                    })
                  )
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                handleSubmit,
                errors,
                handleBlur,
                setFieldValue,
                values,
              }) => {
                return (
                  <Form>
                    <div className='row'>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <FormDatepicker
                          labelClassName='col-12'
                          containerClassName='col-12 '
                          dateFormat={'yyyy-MM-dd'}
                          label='Published date'
                          name='date'
                          placeholderText='Please select a Published date'
                          setFieldValue={setFieldValue}
                          value={values.date || false}
                          errors={errors}
                          touched={touched}
                          required={true}
                          showIcon={true}
                        />
                        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                          {errors?.date ? <span>{errors?.date}</span> : ''}
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-4 col-xs-12'>
                        <label className='mb-1 fw-bolder fs-6  col-lg-4'>
                          <span className='required'>Rate Type</span>
                        </label>
                        <Select
                          placeholder='Rate Type'
                          name='rateType'
                          onChange={(e: any) => {
                            setFieldValue('rateType', e.value)
                          }}
                          options={rateType ?? []}
                          value={
                            values?.rateType
                              ? rateType?.find((obj: any) => obj?.value === values?.rateType)
                              : null
                          }
                        />
                        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                          {errors?.rateType ? <span>{errors?.rateType}</span> : ''}
                        </div>
                      </div>
                    </div>
                    <div>
                      <table className='table'>
                        <thead className='thead-dark'>
                          <tr>
                            <th scope='col'>Currency</th>
                            <th scope='col'>Unit</th>
                            <th scope='col'>Cash Buy</th>
                            <th scope='col'>Feature Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='servicePartnerRateCategories'
                            render={(arrayHelpers) =>
                              values.servicePartnerRateCategories?.map((item: any) =>
                                Object.keys(item).map((key, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{key}</td>
                                      <td>
                                        <div className='form-group'>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`servicePartnerRateCategories[0][${key}][0].unit`}
                                            defaultValue={0}
                                            value={
                                              values?.servicePartnerRateCategories[0][key][0]
                                                ?.unit || 0
                                            }
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`servicePartnerRateCategories[0][${key}][0].cash_buy`}
                                            value={
                                              values?.servicePartnerRateCategories[0][key][0]
                                                ?.cash_buy || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className='form-group '>
                                          <FormTextBox
                                            type='text'
                                            placeholder=''
                                            name={`servicePartnerRateCategories[0][${key}][0].feature_order`}
                                            value={
                                              values?.servicePartnerRateCategories[0][key][0]
                                                ?.feature_order || 0
                                            }
                                            defaultValue={0}
                                            containerClassName=''
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              )
                            }
                          />
                        </tbody>
                      </table>
                    </div>
                    <AddEditForm />
                    <div className='d-flex justify-content-end px-5 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddExchangeRate
