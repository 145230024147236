import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getActiveBenefitSubCategory: (id: string = '') => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_SUB_CATEGORY_START,
    payload: id,
  }),

  getActiveBenefitSubCategorySuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  getActiveBenefitSubCategoryFinish: () => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_SUB_CATEGORY_FINISH,
  }),
  getActiveBenefitSubCategoryError: (error: unknown) => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_SUB_CATEGORY_FINISH,
    payload: {error},
  }),

  getBenefitSubCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BENEFIT_SUB_CATEGORY_START,
    payload: params,
  }),

  getBenefitSubCategorySuccess: (data: any) => ({
    type: actionTypes.GET_BENEFIT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  getBenefitSubCategoryFinish: () => ({
    type: actionTypes.GET_BENEFIT_SUB_CATEGORY_FINISH,
  }),
  getBenefitSubCategoryError: (error: unknown) => ({
    type: actionTypes.GET_BENEFIT_SUB_CATEGORY_FINISH,
    payload: {error},
  }),

  addBenefitSubCategory: (data: any) => ({
    type: actionTypes.ADD_BENEFIT_SUB_CATEGORY_START,
    payload: data,
  }),
  addBenefitSubCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_BENEFIT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  addBenefitSubCategoryFinish: () => ({
    type: actionTypes.ADD_BENEFIT_SUB_CATEGORY_FINISH,
  }),
  addBenefitSubCategoryReset: () => ({
    type: actionTypes.ADD_BENEFIT_SUB_CATEGORY_RESET,
  }),

  updateBenefitSubCategory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_BENEFIT_SUB_CATEGORY_START,
    payload: {data, id},
  }),
  updateBenefitSubCategorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_BENEFIT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  updateBenefitSubCategoryFinish: () => ({
    type: actionTypes.UPDATE_BENEFIT_SUB_CATEGORY_FINISH,
  }),
  updateBenefitSubCategoryReset: () => ({
    type: actionTypes.UPDATE_BENEFIT_SUB_CATEGORY_RESET,
  }),

  deleteBenefitSubCategory: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_SUB_CATEGORY_START,
    payload: {benefitSubCategoryId: data},
  }),
  deleteBenefitSubCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteBenefitSubCategoryFinish: () => ({
    type: actionTypes.DELETE_BENEFIT_SUB_CATEGORY_FINISH,
  }),
  activateBenefitSubCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST,
    payload: {benefitSubCategoryId: id},
  }),
  deactivateBenefitSubCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST,
    payload: {benefitSubCategoryId: id},
  }),
  singleActivateBenefitSubCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST,
    payload: {benefitSubCategoryId: [id]},
  }),
  singleDeactivateBenefitSubCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_SUB_CATEGORY_REQUEST,
    payload: {benefitSubCategoryId: [id]},
  }),

  sortBenefitSubCategory: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_START,
    payload: data,
  }),
  sortBenefitSubCategorySuccess: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortBenefitSubCategoryFinish: () => ({
    type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_FINISH,
  }),
  sortBenefitSubCategoryReset: () => ({
    type: actionTypes.SORT_BENEFIT_SUB_CATEGORY_RESET,
  }),
}
