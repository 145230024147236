import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getDisputeOption: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_DISPUTE_OPTION_START,
    payload: params,
  }),

  getDisputeOptionSuccess: (data: any) => ({
    type: actionTypes.GET_DISPUTE_OPTION_SUCCESS,
    payload: data,
  }),
  getDisputeOptionFinish: () => ({
    type: actionTypes.GET_DISPUTE_OPTION_FINISH,
  }),
  getDisputeOptionError: (error: unknown) => ({
    type: actionTypes.GET_DISPUTE_OPTION_FINISH,
    payload: {error},
  }),

  addDisputeOption: (data: any) => ({
    type: actionTypes.ADD_DISPUTE_OPTION_START,
    payload: data,
  }),
  addDisputeOptionSuccess: (task: any) => ({
    type: actionTypes.ADD_DISPUTE_OPTION_SUCCESS,
    payload: task,
  }),
  addDisputeOptionFinish: () => ({
    type: actionTypes.ADD_DISPUTE_OPTION_FINISH,
  }),
  addDisputeOptionReset: () => ({
    type: actionTypes.ADD_DISPUTE_OPTION_RESET,
  }),

  updateDisputeOption: (data: any, id: string) => ({
    type: actionTypes.UPDATE_DISPUTE_OPTION_START,
    payload: {data, id},
  }),
  updateDisputeOptionSuccess: (task: any) => ({
    type: actionTypes.UPDATE_DISPUTE_OPTION_SUCCESS,
    payload: task,
  }),
  updateDisputeOptionFinish: () => ({
    type: actionTypes.UPDATE_DISPUTE_OPTION_FINISH,
  }),
  updateDisputeOptionReset: () => ({
    type: actionTypes.UPDATE_DISPUTE_OPTION_RESET,
  }),

  deleteDisputeOption: (data: any) => ({
    type: actionTypes.DELETE_DISPUTE_OPTION_START,
    payload: {disputeOptionId: data},
  }),
  deleteDisputeOptionSuccess: (data: any) => ({
    type: actionTypes.DELETE_DISPUTE_OPTION_SUCCESS,
    payload: data,
  }),
  deleteDisputeOptionFinish: () => ({
    type: actionTypes.DELETE_DISPUTE_OPTION_FINISH,
  }),
  activateDisputeOption: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_REQUEST,
    payload: {disputeOptionId: id},
  }),
  deactivateDisputeOption: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_REQUEST,
    payload: {disputeOptionId: id},
  }),
  singleActivateDisputeOption: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_REQUEST,
    payload: {disputeOptionId: [id]},
  }),
  singleDeactivateDisputeOption: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_REQUEST,
    payload: {disputeOptionId: [id]},
  }),

  sortDisputeOption: (data: any) => ({
    type: actionTypes.SORT_DISPUTE_OPTION_START,
    payload: data,
  }),
  sortDisputeOptionSuccess: (data: any) => ({
    type: actionTypes.SORT_DISPUTE_OPTION_SUCCESS,
    payload: data,
  }),
  sortDisputeOptionFinish: () => ({
    type: actionTypes.SORT_DISPUTE_OPTION_FINISH,
  }),
  sortDisputeOptionReset: () => ({
    type: actionTypes.SORT_DISPUTE_OPTION_RESET,
  }),
}
