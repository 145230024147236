import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BENEFIT_SUB_CATEGORY = `${API_URL}/benefit-sub-category`

export const service = {
  getActiveBenefitSubCategory: () => {
    return axios.get(`${BENEFIT_SUB_CATEGORY}/list`)
  },
  getBenefitSubCategory: (params: ParamsModel) => {
    return axios.get(BENEFIT_SUB_CATEGORY, {params})
  },

  addBenefitSubCategory: (data: any) => {
    return axios.post(BENEFIT_SUB_CATEGORY, data)
  },

  updateBenefitSubCategory: (body: any, id: string) => {
    return axios.patch(`${BENEFIT_SUB_CATEGORY}/${id}`, body)
  },

  deleteBenefitSubCategory: (data: any) => {
    return axios.delete(BENEFIT_SUB_CATEGORY, {data})
  },
  singleActivateBenefitSubCategory: (id: any) => {
    return axios.patch(`${BENEFIT_SUB_CATEGORY}/enable`, id)
  },

  singleDeactivateBenefitSubCategory: (id: any) => {
    return axios.patch(`${BENEFIT_SUB_CATEGORY}/disable`, id)
  },

  sortTutorialBenefitSubCategory: (body: any) => {
    return axios.patch(`${BENEFIT_SUB_CATEGORY}/sort`, body)
  },
}
