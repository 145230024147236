import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialGalleryCategoryTypeState: IGalleryCategoryTypeState = {
  data: {
    galleryCategory: [],
  
  },

  loading: false,
  success: false,
}

export interface IGalleryCategoryTypeState {
  data: {
    galleryCategory: {[key: string]: number | string}[]
  
  }
  
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IGalleryCategoryTypeState = initialGalleryCategoryTypeState,
  action: ActionWithPayload<IGalleryCategoryTypeState>
) => {
  switch (action.type) {
    

    //GET Gallery Category type Data
    case actionTypes.GET_GALLERY_CATEGORY_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_GALLERY_CATEGORY_TYPE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_GALLERY_CATEGORY_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    
    default:
      return state
  }
}
