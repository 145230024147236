import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteGeneralApplicationModel, GeneralApplicationModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getGeneralApplications: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_START,
    payload: params,
  }),

  getGeneralApplicationsSuccess: (data: any) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_SUCCESS,
    payload: data,
  }),
  getGeneralApplicationsFinish: () => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_FINISH,
  }),
  getGeneralApplicationsError: (error: unknown) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_FINISH,
    payload: {error},
  }),

  updateGeneralApplications: (data: any, id: string) => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_START,
    payload: {data, id},
  }),
  updateGeneralApplicationsSuccess: (task: any) => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_SUCCESS,
    payload: task,
  }),
  updateGeneralApplicationsFinish: () => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_FINISH,
  }),
  updateGeneralApplicationsReset: () => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_RESET,
  }),

  deleteGeneralApplications: (data: DeleteGeneralApplicationModel[]) => ({
    type: actionTypes.DELETE_GENERAL_APPLICATIONS_START,
    payload: {generalApplicantId: data},
  }),
  deleteGeneralApplicationsSuccess: (data: any) => ({
    type: actionTypes.DELETE_GENERAL_APPLICATIONS_SUCCESS,
    payload: data,
  }),
  deleteGeneralApplicationsFinish: () => ({
    type: actionTypes.DELETE_GENERAL_APPLICATIONS_FINISH,
  }),

  //Get csv file
  getGeneralApplicationFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_GENERAL_APPLICATIONS_FILE_START,
      payload: fileType,
    }
  },

  getGeneralApplicationFileSuccess: (data:any) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_FILE_SUCCESS,
    payload: data,
  }),
  getGeneralApplicationFileError: () => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_FILE_FINISH,
  }),
}
