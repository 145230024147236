import axios from 'axios'
import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {FiMinus, FiPlus} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {PositionModel, VendorModel} from 'src/app/modules/careerSettings/components/Position/Model'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkProvince from 'src/app/modules/common/checkProvince'
import {PlaceModel} from 'src/app/modules/membership/components/memberType/Model'
import * as branch from 'src/app/modules/network/components/branch'
import {BranchModel} from 'src/app/modules/network/components/branch/Model/BranchModel'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {topNavStatus} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as vacancy from '../redux'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = {
  jobCode: Yup.string().required('Job Code is required'),
  title: Yup.string().required('Title is Required'),
  postedDate: Yup.date().required('Posted Date is Required'),
  expiryDate: Yup.date()
    .required('Expiry Date is Required')
    .min(Yup.ref('postedDate'), 'Expiry date should be greater than posted date.'),
  details: Yup.string().required('Details is Required'),
  positions: Yup.array().min(1).required('Positions are required'),
  branches: Yup.array().min(1).required('Branches are required'),
  // departments: Yup.array().min(1).required('Departments are required'),
  status: Yup.string().required('Status is required'),
  vendor: Yup.string().required('Vendor is required'),
  provinces: Yup.array()
    .min(7)
    .required('Provinces are required')
    .when('hasAllBranches', {
      is: true,
      otherwise: (d: any) => Yup.array().min(1).required('Provinces are required'),
    })
    .when('hasProvince', {
      is: true,
      otherwise: (d: any) => Yup.array().required('Provinces are required'),
    }),
}

const AddVacancy = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.vacancy)
  const [branchData, setBranchData] = useState([])
  const [vendorLists, setVendorLists] = useState([])
  const [departmentData, setDepartmentData] = useState([])
  const [provinceIds, setProvinceIds] = useState([])
  const [provinceData, setProvinceData] = useState([])
  const [positionData, setPositionData] = useState([])
  const [qualificationData, setQualificationData] = useState([])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const yesNoStatusOptions = [
    {label: 'Yes', value: 'Yes'},
    {label: 'No', value: 'No'},
  ]

  const staticAllBranches = {
    label: 'ALL BRANCHES',
    value: 'all_branches',
  }

  const [examCenters, setExamCenters] = useState([{centerName: ''}])
  const {data} = useSelector((state: any) => state.branch)

  const branchOptions = data?.branch.map((items: BranchModel) => ({
    label: items.title || '',
    value: items.id || '',
  }))

  function compare(a: any, b: any) {
    return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  }

  useEffect(() => {
    topNavStatus === 'true' ? branchOptions.push(staticAllBranches) : branchOptions
    branchOptions.sort(compare)
  }, [branchOptions])

  const positionOptions = positionData?.map((position: PositionModel) => ({
    label: position.name || '',
    value: position.id || '',
  }))

  const departmentOptions = departmentData?.map((position: PositionModel) => ({
    label: position.name || '',
    value: position.id || '',
  }))
  const provinceOptions = provinceData?.map((position: PlaceModel) => ({
    label: position.title || '',
    value: position.id || '',
  }))
  const vendorOptions = vendorLists?.map((position: VendorModel) => ({
    label: position.name || '',
    value: position.vendorId || '',
  }))

  useEffect(() => {
    dispatch(branch.actions.getBranchListData({provinceId: provinceIds.toString()}))
  }, [provinceIds])

  const hasProvince = checkProvince()

  useEffect(() => {
    if (success) {
      dispatch(vacancy?.actions.getVacancy(params))
      isEmpty(editSelectedData)
        ? toast.success('Vacancy added successfully')
        : toast.success('Vacancy edited successfully')
      dispatch(vacancy?.actions?.addVacancyReset())
      handleClose()
    }
  }, [success])

  const [qualifications, setQualifications] = useState<any>([])

  useEffect(() => {
    if (qualificationData?.length > 0) {
      const qfs: any = []
      qualificationData?.map((dta: any) => {
        qfs.push({
          qualificationId: dta.id,
          minPercentage: '0',
          minCgpa: '0',
          isEnabled: false,
          isRequired: false,
        })
      })

      if (editSelectedData?.qualifications) {
        editSelectedData?.qualifications?.map((dta: any) => {
          const qualificationIndex = qfs.findIndex(
            (d: any) => d.qualificationId === dta.qualificationId
          )
          if (qualificationIndex >= 0) {
            qfs[qualificationIndex] = {...dta}
          }
        })
      }
      setQualifications(qfs)
    }
  }, [qualificationData])

  useEffect(() => {
    axios.get(API_URL + '/department/list').then((res) => {
      setDepartmentData(res.data?.data?.department)
    })
    axios.get(API_URL + '/province').then((res) => {
      setProvinceData(res.data?.data?.province)
    })
    axios.get(API_URL + '/position/list').then((res) => {
      setPositionData(res.data?.data?.position)
    })
    axios.get(API_URL + '/branch/list').then((res) => {
      setBranchData(res.data?.data?.branch)
    })
    axios.get(API_URL + '/vacancy/vendor-list').then((res) => {
      setVendorLists(res.data?.data?.vendor)
    })
    axios.get(API_URL + '/qualification/list').then((res) => {
      setQualificationData(res.data?.data?.qualification)
    })
  }, [params])

  function convertToSlug(text: any) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  const careerQualificationRequired = checkBackendSetting('careerQualificationRequired')
  const showExperienceInQuickReq = checkBackendSetting('showExperienceInQuickReq')
  const showQualificationInQuickReq = checkBackendSetting('showQualificationInQuickReq')
  const showDeadlineInQuickReq = checkBackendSetting('showDeadlineInQuickReq')

  //For NIMB New Request

  const QualificationRequired = checkBackendSetting('Qualification')
  const showTotalBankingExperience = checkBackendSetting('Total Banking Experience')
  const showProfessionalReference = checkBackendSetting('Professional Reference')

  return (
    <div className='modal-container' data-cy='Add-vacancy-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Vacancy </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                jobCode: '',
                slug: '',
                title: '',
                titleNp: '',
                seats: '',
                startingSalary: '',
                fromAge: '',
                toAge: '',
                postedDate: '',
                expiryDate: '',
                expiryTime: '',
                experienceMonth: '',
                branches: [],
                vendorList: [],
                positions: [],
                departments: [],
                provinces: [],
                details: '',
                detailsNp: '',
                experienceInQuickReq: 'No',
                qualificationInQuickReq: 'No',
                deadlineInQuickReq: 'No',
                foreignDegree: 'No',
                experience: 'No',
                isReferenceRequired: 'No',
                isBankingExperienceRequired: 'No',
                isQualificationRequired: 'No',
                position: 'No',
                department: 'No',
                expectedSalary: 'No',
                currentExperience: 'No',
                licenseHolder: 'No',
                applyButton: 'No',
                examCenter: 'No',
                interview: 'No',
                groupDiscussion: 'No',
                status: 'Active',
                exam: 'No',
                vendor: 'No',
                hasProvince: hasProvince ?? false,
                hasAllBranches: false,
              }}
              validationSchema={
                topNavStatus === 'false'
                  ? Yup.object().shape({
                      ...FORM_VALIDATION,
                      seats: Yup.string().required('Number of seats is Required'),
                    })
                  : Yup.object().shape({
                      ...FORM_VALIDATION,
                    })
              }
              onSubmit={(values, {setSubmitting}) => {
                const updatedBranches: any = []
                values.branches.map((branch: any) => {
                  if (branch?.branchId) {
                    updatedBranches.push({branchId: branch.branchId})
                  } else {
                    updatedBranches.push({branchId: branch})
                  }
                })
                const updatedVendors: any = []
                values.vendorList.map((vendor: any) => {
                  if (vendor?.vendorId) {
                    updatedVendors.push({vendorId: vendor.id})
                  } else {
                    updatedVendors.push({vendorId: vendor})
                  }
                })
                const updatedPositions: any = []
                values.positions.map((pos: any) => {
                  if (pos?.positionId) {
                    updatedPositions.push({positionId: pos.positionId})
                  } else {
                    updatedPositions.push({positionId: pos})
                  }
                })
                const updatedDepartments: any = []
                values.departments.map((dep: any) => {
                  if (dep?.departmentId) {
                    updatedDepartments.push({departmentId: dep.departmentId})
                  } else {
                    updatedDepartments.push({departmentId: dep})
                  }
                })
                const updatedProvinces: any = []
                values.provinces.map((dep: any) => {
                  if (dep?.departmentId) {
                    updatedProvinces.push({provinceId: dep.provinceId})
                  } else {
                    updatedProvinces.push({provinceId: dep})
                  }
                })
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  experienceInQuickReq: values?.experienceInQuickReq === 'Yes' ? true : false,
                  qualificationInQuickReq: values?.qualificationInQuickReq === 'Yes' ? true : false,
                  deadlineInQuickReq: values?.deadlineInQuickReq === 'Yes' ? true : false,
                  foreignDegree: values?.foreignDegree === 'Yes' ? true : false,
                  experience: values?.experience === 'Yes' ? true : false,
                  isReferenceRequired: values?.isReferenceRequired === 'Yes' ? true : false,
                  isBankingExperienceRequired:
                    values?.isBankingExperienceRequired === 'Yes' ? true : false,

                  isQualificationRequired: values?.isQualificationRequired === 'Yes' ? true : false,
                  position: values?.position === 'Yes' ? true : false,
                  department: values?.department === 'Yes' ? true : false,
                  expectedSalary: values?.expectedSalary === 'Yes' ? true : false,
                  currentExperience: values?.currentExperience === 'Yes' ? true : false,
                  licenseHolder: values?.licenseHolder === 'Yes' ? true : false,
                  applyButton: values?.applyButton === 'Yes' ? true : false,
                  examCenter: values?.examCenter === 'Yes' ? true : false,
                  interview: values?.interview === 'Yes' ? true : false,
                  groupDiscussion: values?.groupDiscussion === 'Yes' ? true : false,
                  exam: values?.exam === 'Yes' ? true : false,
                  vendor: values?.vendor === 'Yes' ? true : false,
                  qualifications: qualifications,
                  branches: updatedBranches,
                  vendorList: updatedVendors,
                  positions: updatedPositions,
                  departments: updatedDepartments ? updatedDepartments : false,
                  provinces: updatedProvinces ? updatedProvinces : false,
                  examCenters: examCenters[0]?.centerName ? examCenters : null,
                  expiryTime: values?.expiryTime
                    ? moment(values?.expiryTime).format('hh:mm A')
                    : null,
                  slug: convertToSlug(values?.slug),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(vacancy.actions.updateVacancy(formData, editSelectedData?.id))
                } else {
                  dispatch(vacancy.actions.addVacancy(formData))
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                errors,
                values,
                setErrors,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'jobCode',
                      'slug',
                      'title',
                      'titleNp',
                      'seats',
                      'startingSalary',
                      'fromAge',
                      'toAge',
                      'postedDate',
                      'expiryDate',
                      'expiryTime',
                      'branches',
                      'positions',
                      'departments',
                      'provinces',
                      'details',
                      'detailsNp',
                      'foreignDegree',
                      'experience',
                      'isReferenceRequired',
                      'isBankingExperienceRequired',
                      'isQualificationRequired',
                      'position',
                      'department',
                      'experienceInQuickReq',
                      'qualificationInQuickReq',
                      'deadlineInQuickReq',
                      'expectedSalary',
                      'currentExperience',
                      'licenseHolder',
                      'applyButton',
                      'examCenter',
                      'groupDiscussion',
                      'status',
                      'vendor',
                      'vendorList',
                      'experienceMonth',
                      'interview',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue('foreignDegree', editSelectedData?.foreignDegree ? 'Yes' : 'No')
                    setFieldValue('experience', editSelectedData?.experience ? 'Yes' : 'No')
                    setFieldValue(
                      'isQualificationRequired',
                      editSelectedData?.isQualificationRequired ? 'Yes' : 'No'
                    )
                    setFieldValue(
                      'isReferenceRequired',
                      editSelectedData?.isReferenceRequired ? 'Yes' : 'No'
                    )
                    setFieldValue(
                      'isBankingExperienceRequired',
                      editSelectedData?.isBankingExperienceRequired ? 'Yes' : 'No'
                    )

                    setFieldValue('position', editSelectedData?.position ? 'Yes' : 'No')
                    setFieldValue('department', editSelectedData?.department ? 'Yes' : 'No')
                    setFieldValue(
                      'experienceInQuickReq',
                      editSelectedData?.experienceInQuickReq ? 'Yes' : 'No'
                    )
                    setFieldValue(
                      'qualificationInQuickReq',
                      editSelectedData?.qualificationInQuickReq ? 'Yes' : 'No'
                    )
                    setFieldValue(
                      'deadlineInQuickReq',
                      editSelectedData?.deadlineInQuickReq ? 'Yes' : 'No'
                    )
                    setFieldValue('expectedSalary', editSelectedData?.expectedSalary ? 'Yes' : 'No')
                    setFieldValue('applyButton', editSelectedData?.applyButton ? 'Yes' : 'No')
                    setFieldValue('licenseHolder', editSelectedData?.licenseHolder ? 'Yes' : 'No')
                    setFieldValue('exam', editSelectedData?.exam ? 'Yes' : 'No')
                    setFieldValue('vendor', editSelectedData?.vendor ? 'Yes' : 'No')
                    setFieldValue('slug', editSelectedData?.slug)
                    setFieldValue(
                      'groupDiscussion',
                      editSelectedData?.groupDiscussion ? 'Yes' : 'No'
                    )
                    setFieldValue('interview', editSelectedData?.interview ? 'Yes' : 'No')
                    setFieldValue(
                      'currentExperience',
                      editSelectedData?.currentExperience ? 'Yes' : 'No'
                    )
                    setFieldValue('examCenter', editSelectedData?.examCenter ? 'Yes' : 'No')
                    setFieldValue('status', editSelectedData?.status ? 'Active' : 'Inactive')
                    if (editSelectedData?.examCenters) {
                      const updatedExamCenters: any = []
                      editSelectedData?.examCenters?.map((dta: any) => {
                        updatedExamCenters.push({
                          ...dta,
                          centerName: dta.name,
                        })
                      })
                      setExamCenters(updatedExamCenters)
                    }
                    const selectedPositionValues: any = []
                    editSelectedData?.positions?.map((position: {positionId: string}) => {
                      selectedPositionValues.push(position.positionId)
                    })
                    setFieldValue('positions', selectedPositionValues)
                    const selectedDepartmentValues: any = []
                    editSelectedData?.departments?.map((position: {departmentId: string}) => {
                      selectedDepartmentValues.push(position.departmentId)
                    })
                    setFieldValue('departments', selectedDepartmentValues)
                    const selectedProvincesValues: any = []
                    editSelectedData?.provinces?.map((position: {provinceId: string}) => {
                      selectedProvincesValues.push(position.provinceId)
                    })
                    setFieldValue('provinces', selectedProvincesValues)
                    const selectedBranchesValues: any = []
                    editSelectedData?.branches?.map((position: {branchId: string}) => {
                      selectedBranchesValues.push(position.branchId)
                    })
                    setFieldValue('branches', selectedBranchesValues)
                    if (editSelectedData?.vendor) {
                      const selectedVendorsValues: any = []
                      editSelectedData?.vendorList?.map((position: {id: string}) => {
                        selectedVendorsValues.push(position.id)
                      })
                      setFieldValue('vendorList', selectedVendorsValues)
                    }
                  }
                }, [])

                useEffect(() => {
                  if (values.provinces) {
                    setProvinceIds(values.provinces)
                  }
                }, [values.provinces])

                useEffect(() => {
                  if (values.branches) {
                    const hasAllBranches = values.branches.some(
                      (item: string) => item === 'all_branches'
                    )

                    setFieldValue('hasAllBranches', hasAllBranches)
                  }
                }, [values.branches])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='Add-vacancy-doby'>
                      <div className='row'></div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Job Code'
                            label='Job Code'
                            name='jobCode'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='Add-vacancy-job-code-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={convertToSlug(values?.slug)}
                            required={true}
                            data-cy='Add-vacancy-slug-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title (E.N)'
                            label='Title (E.N)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='Add-vacancy-title-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title (N.P)'
                            label='Title (N.P)'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='Add-vacancy-title-np-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Number Of Seats'
                            label='Number Of Seats'
                            name='seats'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={topNavStatus === 'true' ? false : true}
                            data-cy='Add-vacancy-seats-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Starting Salary'
                            label='Starting Salary'
                            name='startingSalary'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='Add-vacancy-salary-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='From Age'
                            label='From Age'
                            name='fromAge'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='Add-vacancy-from-age-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='To Age'
                            label='To Age'
                            name='toAge'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='Add-vacancy-to-age-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='date'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Posted Date'
                            label='Posted Date'
                            name='postedDate'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='Add-vacancy-posted-date-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          {topNavStatus === 'true' ? (
                            <div className='row'>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='date'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Expiry Date'
                                  label='Expiry Date'
                                  name='expiryDate'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  data-cy='Add-vacancy-expiry-text-field'
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  dateFormat='hh:mm aa'
                                  showTimeSelect={true}
                                  showTimeSelectOnly={true}
                                  name='expiryTime'
                                  label='Expiry time'
                                  placeholderText='--:-- --'
                                  setFieldValue={setFieldValue}
                                  minTime={new Date().setHours(24, 59, 59)}
                                  maxTime={new Date().setHours(0, 0, 0)}
                                  value={values?.expiryTime || false}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  disabled={values.expiryDate ? false : true}
                                  data-cy='Add-vacancy-expiry-time-picker'
                                />
                              </div>
                            </div>
                          ) : (
                            <FormTextBox
                              type='date'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Expiry Date'
                              label='Expiry Date'
                              name='expiryDate'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                              data-cy='Add-vacancy-expiry-text-field'
                            />
                          )}
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Positions'
                            name='positions'
                            onChange={handleChange}
                            multiple={true}
                            errors={errors}
                            touched={touched}
                            options={positionOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                            data-cy='Add-vacancy-position-select'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Departments'
                            name='departments'
                            onChange={handleChange}
                            multiple={true}
                            errors={errors}
                            touched={touched}
                            options={departmentOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='Add-vacancy-department-select'
                          />
                        </div>

                        {hasProvince && (
                          <div className='col-md-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Please select'
                              label='Provinces'
                              name='provinces'
                              onChange={handleChange}
                              multiple={true}
                              errors={errors}
                              touched={touched}
                              options={provinceOptions}
                              values={values}
                              setFieldValue={setFieldValue}
                              required={hasProvince ? true : false}
                              data-cy='Add-vacancy-provinces-select'
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Applicable branches'
                            name='branches'
                            onChange={handleChange}
                            multiple={true}
                            errors={errors}
                            touched={touched}
                            options={branchOptions.sort(compare)}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                            data-cy='Add-vacancy-branches-select'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Experience In Months'
                            label='Experience In Months'
                            name='experienceMonth'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='Add-vacancy-experience-text-field'
                          />
                        </div>
                        {hasProvince && <div className='col-md-6 col-xs-12'></div>}

                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description (EN)'
                            name='details'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.details : ''
                            }
                            handleChange={handleChange}
                            required
                            data-cy='Add-vacancy-description-textarea'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description (NP)'
                            name='detailsNp'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.detailsNp : ''
                            }
                            handleChange={handleChange}
                            data-cy='Add-vacancy-description-np-textarea'
                          />
                        </div>

                        <div style={{width: '100%', margin: '25px 0 40px 0'}}>
                          <table style={{width: '100%'}}>
                            <thead>
                              <tr style={{padding: '5px'}}>
                                <th>Qualification</th>
                                <th>Min.Percentage</th>
                                <th>Min.CGPA</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {qualificationData?.map((d: any, index: number) => {
                                return (
                                  <tr
                                    key={d?.name}
                                    className='mt-5 mb-2'
                                    style={{marginTop: '10px'}}
                                  >
                                    <th>{d?.name}</th>
                                    <th>
                                      <input
                                        type='text'
                                        style={{border: 'solid 1px rgba(0,0,0,0.2)'}}
                                        value={qualifications[index]?.minPercentage}
                                        onChange={(e) => {
                                          const updatedQualifications = [...qualifications]
                                          updatedQualifications[index].minPercentage =
                                            e.target.value
                                          setQualifications(updatedQualifications)
                                        }}
                                        data-cy={`${d?.name}-input-field`}
                                      />
                                    </th>
                                    <th>
                                      <input
                                        type='text'
                                        style={{border: 'solid 1px rgba(0,0,0,0.2)'}}
                                        value={qualifications[index]?.minCgpa}
                                        onChange={(e) => {
                                          const updatedQualifications = [...qualifications]
                                          updatedQualifications[index].minCgpa = e.target.value
                                          setQualifications(updatedQualifications)
                                        }}
                                        data-cy={`minCgpa-input-field`}
                                      />
                                    </th>
                                    <th>
                                      <input
                                        type='checkbox'
                                        value='active'
                                        className='mt-2'
                                        checked={qualifications[index]?.isEnabled}
                                        onChange={(e) => {
                                          const updatedQualifications = [...qualifications]
                                          updatedQualifications[index].isEnabled = true
                                          setQualifications(updatedQualifications)
                                        }}
                                        data-cy={`Active-input-field`}
                                      />
                                      Active
                                      <input
                                        type='checkbox'
                                        value='inactive'
                                        className='mt-2 mx-2'
                                        checked={!qualifications[index]?.isEnabled}
                                        onChange={(e) => {
                                          const updatedQualifications = [...qualifications]
                                          updatedQualifications[index].isEnabled = false
                                          setQualifications(updatedQualifications)
                                        }}
                                        data-cy={`Inactive-input-field`}
                                      />
                                      Inactive
                                      {careerQualificationRequired && (
                                        <>
                                          <input
                                            type='checkbox'
                                            value='inactive'
                                            className='mt-2 mx-2'
                                            checked={qualifications[index]?.isRequired}
                                            onChange={(e) => {
                                              const updatedQualifications = [...qualifications]
                                              updatedQualifications[index].isRequired =
                                                !qualifications[index]?.isRequired
                                              setQualifications(updatedQualifications)
                                            }}
                                            data-cy={`Required-input-field`}
                                          />
                                          Required
                                        </>
                                      )}
                                    </th>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        {topNavStatus === 'true' ? null : (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Allow Foreign Degree
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='foreignDegree'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                  data-cy='Add-vacancy-foreign-degree-radio'
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {/* experience */}
                        {showExperienceInQuickReq && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Experience in quick requirement
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='experienceInQuickReq'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                  data-cy='Add-vacancy-experience-radio'
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {/* qualification  */}
                        {showQualificationInQuickReq && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Qualification in quick requirement
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='qualificationInQuickReq'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {/* deadline  */}
                        {showDeadlineInQuickReq && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Deadline in quick requirement
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='deadlineInQuickReq'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {/* m */}

                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Experience Required
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='experience'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Show Applied Position
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='position'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Show Applied Department
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='department'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                        {showProfessionalReference && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Professional Reference
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='isReferenceRequired'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}

                        {showTotalBankingExperience && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Total Banking Experience
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='isBankingExperienceRequired'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {QualificationRequired && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Qualification Required
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='isQualificationRequired'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Show Expected Salary
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='expectedSalary'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                        {topNavStatus === 'true' ? null : (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Current Experience
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='currentExperience'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Show License Holder 2 Wheeler
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='licenseHolder'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Show Apply Button
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='applyButton'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Exam
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='exam'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                        {values.exam === 'Yes' && (
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Preferred Exam Center
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='examCenter'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Interview
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='interview'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12 mb-2'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Group Discussion
                          </label>

                          <div className='d-flex ms-5'>
                            {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='groupDiscussion'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6 col-xs-12'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Status
                            </label>

                            <div className='d-flex ms-5'>
                              {statusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='status'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                          <div className='col-md-6 col-xs-12 mb-2'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>
                              Show Vendors
                            </label>

                            <div className='d-flex ms-5'>
                              {yesNoStatusOptions?.map((status: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Select Status'
                                  name='vendor'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  checkBoxText={status?.label}
                                  value={status?.value}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          {values.examCenter === 'Yes' && values.exam === 'Yes' && (
                            <div className='exam-centers mt-4 col-lg-6'>
                              <h5>Exam Centers</h5>
                              {examCenters.map((center, index) => {
                                return (
                                  <div>
                                    <input
                                      placeholder='Exam Center Name'
                                      type='text'
                                      value={center.centerName}
                                      onChange={(e) => {
                                        const updatedExamCenters = [...examCenters]
                                        updatedExamCenters[index].centerName = e.target.value
                                        setExamCenters(updatedExamCenters)
                                      }}
                                    />
                                    <button
                                      type='button'
                                      onClick={() => {
                                        const updatedExamCenters = examCenters.filter(
                                          (_, i) => i !== index
                                        )
                                        setExamCenters(updatedExamCenters)
                                      }}
                                      data-cy='remove-exam-button'
                                    >
                                      <FiMinus />
                                    </button>
                                  </div>
                                )
                              })}
                              <button
                                type='button'
                                onClick={() =>
                                  setExamCenters((prev) => [...prev, {centerName: ''}])
                                }
                                data-cy='add-exam-button'
                              >
                                <FiPlus />
                              </button>
                            </div>
                          )}
                          {values.vendor === 'Yes' && (
                            <div className='exam-centers mt-4 col-lg-6'>
                              <div className='col-md-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Select Vendors '
                                  label='Select Vendor'
                                  name='vendorList'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={vendorOptions}
                                  values={values}
                                  multiple={true}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddVacancy
