export const actionTypes = {
  // Get Apply Loan
  GET_APPLY_LOAN_START: 'GET_APPLY_LOAN_START',
  GET_APPLY_LOAN_SUCCESS: 'GET_APPLY_LOAN_SUCCESS',
  GET_APPLY_LOAN_FINISH: 'GET_APPLY_LOAN_FINISH',

  // Get Municipality
  GET_MUNICIPALITY_START: 'GET_MUNICIPALITY_START',
  GET_MUNICIPALITY_SUCCESS: 'GET_MUNICIPALITY_SUCCESS',
  GET_MUNICIPALITY_FINISH: 'GET_MUNICIPALITY_FINISH',

  // add Apply Loan
  ADD_APPLY_LOAN_START: 'ADD__APPLY_LOAN_START',
  ADD_APPLY_LOAN_SUCCESS: 'ADD_APPLY_LOAN_SUCCESS',
  ADD_APPLY_LOAN_FINISH: 'ADD_APPLY_LOAN_FINISH',
  RESET_APPLY_LOAN: 'RESET_APPLY_LOAN',

  // update Apply Loan
  UPDATE_APPLY_LOAN_START: 'UPDATE_APPLY_LOAN_START',
  UPDATE_APPLY_LOAN_SUCCESS: 'UPDATE_APPLY_LOAN_SUCCESS',
  UPDATE_APPLY_LOAN_FINISH: 'UPDATE_APPLY_LOAN_FINISH',

  // delete Apply Loan
  DELETE_APPLY_LOAN_START: 'DELETE_APPLY_LOAN_START',
  DELETE_APPLY_LOAN_SUCCESS: 'DELETE_APPLY_LOAN_SUCCESS',
  DELETE_APPLY_LOAN_FINISH: 'DELETE_APPLY_LOAN_FINISH',

  // Enable MEMBER_TYPE
  ENABLE_APPLY_LOAN_REQUEST: 'ENABLE_APPLY_LOAN_REQUEST',
  ENABLE_APPLY_LOAN_SUCCESS: 'ENABLE_APPLY_LOAN_SUCCESS',
  ENABLE_APPLY_LOAN_FINISH: 'ENABLE_APPLY_LOAN_FINISH',

  // Disable Apply Loan
  DISABLE_APPLY_LOAN_REQUEST: 'DISABLE_APPLY_LOAN_REQUEST',
  DISABLE_APPLY_LOAN_SUCCESS: 'DISABLE_APPLY_LOAN_SUCCESS',
  DISABLE_APPLY_LOAN_FINISH: 'DISABLE_APPLY_LOAN_FINISH',

  // Enable Apply Loan
  SINGLE_ENABLE_APPLY_LOAN_REQUEST: 'SINGLE_ENABLE_APPLY_LOAN_REQUEST',
  SINGLE_ENABLE_APPLY_LOAN_SUCCESS: 'SINGLE_ENABLE_APPLY_LOAN_SUCCESS',
  SINGLE_ENABLE_APPLY_LOAN_FINISH: 'SINGLE_ENABLE_APPLY_LOAN_FINISH',

  // Disable Apply Loan
  SINGLE_DISABLE_APPLY_LOAN_REQUEST: 'SINGLE_DISABLE_APPLY_LOAN_REQUEST',
  SINGLE_DISABLE_APPLY_LOAN_SUCCESS: 'SINGLE_DISABLE_APPLY_LOAN_SUCCESS',
  SINGLE_DISABLE_APPLY_LOAN_FINISH: 'SINGLE_DISABLE_APPLY_LOAN_FINISH',
}
