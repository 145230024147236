import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getActiveBenefitCategory: (id: string = '') => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_START,
    payload: id,
  }),

  getActiveBenefitCategorySuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_SUCCESS,
    payload: data,
  }),
  getActiveBenefitCategoryFinish: () => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_FINISH,
  }),
  getActiveBenefitCategoryError: (error: unknown) => ({
    type: actionTypes.GET_ACTIVE_BENEFIT_CATEGORY_FINISH,
    payload: {error},
  }),

  getBenefitCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BENEFIT_CATEGORY_START,
    payload: params,
  }),

  getBenefitCategorySuccess: (data: any) => ({
    type: actionTypes.GET_BENEFIT_CATEGORY_SUCCESS,
    payload: data,
  }),
  getBenefitCategoryFinish: () => ({
    type: actionTypes.GET_BENEFIT_CATEGORY_FINISH,
  }),
  getBenefitCategoryError: (error: unknown) => ({
    type: actionTypes.GET_BENEFIT_CATEGORY_FINISH,
    payload: {error},
  }),

  addBenefitCategory: (data: any) => ({
    type: actionTypes.ADD_BENEFIT_CATEGORY_START,
    payload: data,
  }),
  addBenefitCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_BENEFIT_CATEGORY_SUCCESS,
    payload: task,
  }),
  addBenefitCategoryFinish: () => ({
    type: actionTypes.ADD_BENEFIT_CATEGORY_FINISH,
  }),
  addBenefitCategoryReset: () => ({
    type: actionTypes.ADD_BENEFIT_CATEGORY_RESET,
  }),

  updateBenefitCategory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_BENEFIT_CATEGORY_START,
    payload: {data, id},
  }),
  updateBenefitCategorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_BENEFIT_CATEGORY_SUCCESS,
    payload: task,
  }),
  updateBenefitCategoryFinish: () => ({
    type: actionTypes.UPDATE_BENEFIT_CATEGORY_FINISH,
  }),
  updateBenefitCategoryReset: () => ({
    type: actionTypes.UPDATE_BENEFIT_CATEGORY_RESET,
  }),

  deleteBenefitCategory: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_CATEGORY_START,
    payload: {benefitCategoryId: data},
  }),
  deleteBenefitCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteBenefitCategoryFinish: () => ({
    type: actionTypes.DELETE_BENEFIT_CATEGORY_FINISH,
  }),
  activateBenefitCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_REQUEST,
    payload: {benefitCategoryId: id},
  }),
  deactivateBenefitCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_REQUEST,
    payload: {benefitCategoryId: id},
  }),
  singleActivateBenefitCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_CATEGORY_REQUEST,
    payload: {benefitCategoryId: [id]},
  }),
  singleDeactivateBenefitCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_CATEGORY_REQUEST,
    payload: {benefitCategoryId: [id]},
  }),

  sortBenefitCategory: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_CATEGORY_START,
    payload: data,
  }),
  sortBenefitCategorySuccess: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortBenefitCategoryFinish: () => ({
    type: actionTypes.SORT_BENEFIT_CATEGORY_FINISH,
  }),
  sortBenefitCategoryReset: () => ({
    type: actionTypes.SORT_BENEFIT_CATEGORY_RESET,
  }),
}
