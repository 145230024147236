import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getExperienceManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_EXPERIENCE_MANAGER_START,
    payload: params,
  }),

  getExperienceManagerSuccess: (data: any) => ({
    type: actionTypes.GET_EXPERIENCE_MANAGER_SUCCESS,
    payload: data,
  }),
  getExperienceManagerFinish: () => ({
    type: actionTypes.GET_EXPERIENCE_MANAGER_FINISH,
  }),
  getExperienceManagerError: (error: unknown) => ({
    type: actionTypes.GET_EXPERIENCE_MANAGER_FINISH,
    payload: {error},
  }),

  addExperienceManager: (data: any) => ({
    type: actionTypes.ADD_EXPERIENCE_MANAGER_START,
    payload: data,
  }),
  addExperienceManagerSuccess: (task: any) => ({
    type: actionTypes.ADD_EXPERIENCE_MANAGER_SUCCESS,
    payload: task,
  }),
  addExperienceManagerFinish: () => ({
    type: actionTypes.ADD_EXPERIENCE_MANAGER_FINISH,
  }),
  addExperienceManagerReset: () => ({
    type: actionTypes.ADD_EXPERIENCE_MANAGER_RESET,
  }),

  updateExperienceManager: (data: any, id: string) => ({
    type: actionTypes.UPDATE_EXPERIENCE_MANAGER_START,
    payload: {data, id},
  }),
  updateExperienceManagerSuccess: (task: any) => ({
    type: actionTypes.UPDATE_EXPERIENCE_MANAGER_SUCCESS,
    payload: task,
  }),
  updateExperienceManagerFinish: () => ({
    type: actionTypes.UPDATE_EXPERIENCE_MANAGER_FINISH,
  }),
  updateExperienceManagerReset: () => ({
    type: actionTypes.UPDATE_EXPERIENCE_MANAGER_RESET,
  }),

  deleteExperienceManager: (data: any) => ({
    type: actionTypes.DELETE_EXPERIENCE_MANAGER_START,
    payload: {experienceManagerId: data},
  }),
  deleteExperienceManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_EXPERIENCE_MANAGER_SUCCESS,
    payload: data,
  }),
  deleteExperienceManagerFinish: () => ({
    type: actionTypes.DELETE_EXPERIENCE_MANAGER_FINISH,
  }),
  activateExperienceManager: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_EXPERIENCE_MANAGER_REQUEST,
    payload: {experienceManagerId: id},
  }),
  deactivateExperienceManager: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_REQUEST,
    payload: {experienceManagerId: id},
  }),
  singleActivateExperienceManager: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_EXPERIENCE_MANAGER_REQUEST,
    payload: {experienceManagerId: [id]},
  }),
  singleDeactivateExperienceManager: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_REQUEST,
    payload: {experienceManagerId: [id]},
  }),

  sortExperienceManager: (data: any) => ({
    type: actionTypes.SORT_EXPERIENCE_MANAGER_START,
    payload: data,
  }),
  sortExperienceManagerSuccess: (data: any) => ({
    type: actionTypes.SORT_EXPERIENCE_MANAGER_SUCCESS,
    payload: data,
  }),
  sortExperienceManagerFinish: () => ({
    type: actionTypes.SORT_EXPERIENCE_MANAGER_FINISH,
  }),
  sortExperienceManagerReset: () => ({
    type: actionTypes.SORT_EXPERIENCE_MANAGER_RESET,
  }),
  importExperienceManager: (data: any) => ({
    type: actionTypes.IMPORT_EXPERIENCE_MANAGER_START,
    payload: data,
  }),
  importExperienceManagerSuccess: (task: any) => ({
    type: actionTypes.IMPORT_EXPERIENCE_MANAGER_SUCCESS,
    payload: task,
  }),
  importExperienceManagerFinish: () => ({
    type: actionTypes.IMPORT_EXPERIENCE_MANAGER_FINISH,
  }),
}
