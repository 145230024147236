import axios from 'axios'
import { ParamsModel } from 'src/app/modules/common/Model'
import { DeleteModel } from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const OnlineApplication = `${API_URL}/online-application`

export const service = {
  getOnlineApplication: (params: ParamsModel) => {
    return axios.get(OnlineApplication, { params })
  },

  addOnlineApplication: (data: any) => {
    return axios.post(OnlineApplication, data)
  },

  updateOnlineApplication: (body: any, id: string) => {
    return axios.patch(`${OnlineApplication}/${id}`, body)
  },

  deleteOnlineApplication: (data: DeleteModel) => {
    return axios.delete(OnlineApplication, { data })
  },
  singleActivateOnlineApplication: (id: any) => {
    return axios.patch(`${OnlineApplication}/enable`, id)
  },

  singleDeactivateOnlineApplication: (id: any) => {
    return axios.patch(`${OnlineApplication}/disable`, id)
  },

  sortOnlineApplication: (body: any) => {
    return axios.patch(`${OnlineApplication}/sort`, body)
  },
}
