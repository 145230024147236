import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BENEFIT_CATEGORY = `${API_URL}/benefit-category`

export const service = {
  getActiveBenefitCategory: () => {
    return axios.get(`${BENEFIT_CATEGORY}/list`)
  },
  getBenefitCategory: (params: ParamsModel) => {
    return axios.get(BENEFIT_CATEGORY, {params})
  },

  addBenefitCategory: (data: any) => {
    return axios.post(BENEFIT_CATEGORY, data)
  },

  updateBenefitCategory: (body: any, id: string) => {
    return axios.patch(`${BENEFIT_CATEGORY}/${id}`, body)
  },

  deleteBenefitCategory: (data: any) => {
    return axios.delete(BENEFIT_CATEGORY, {data})
  },
  singleActivateBenefitCategory: (id: any) => {
    return axios.patch(`${BENEFIT_CATEGORY}/enable`, id)
  },

  singleDeactivateBenefitCategory: (id: any) => {
    return axios.patch(`${BENEFIT_CATEGORY}/disable`, id)
  },

  sortBenefitCategory: (body: any) => {
    return axios.patch(`${BENEFIT_CATEGORY}/sort`, body)
  },
}
