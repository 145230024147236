import { Col, Row } from 'react-bootstrap-v5'
import { Modal } from 'rsuite'

type Props = {
  data: {
    name: string
    nameNp: string
    branchName: string
    email: string
    managerPhone: string
    telephone: string
    hideInWebsite: boolean
    status: boolean
  }
  handleClose: () => void
}
const ViewDetailsModal = ({ handleClose, data }: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Experience Manager Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Name(E.N)</b>
              </Col>
              <Col md={6}>
                <p>{data.name}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Name(N.P)</b>
              </Col>
              <Col md={6}>
                <p>{data.nameNp}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Branch Name</b>
              </Col>
              <Col md={6}>
                <p>{data.branchName}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email Address</b>
              </Col>
              <Col md={6}>
                <p>{data.email}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Manager Phone</b>
              </Col>
              <Col md={6}>
                <p>{data.managerPhone}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Telephone</b>
              </Col>
              <Col md={6}>
                <p>{data.telephone}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Hide In Website</b>
              </Col>
              <Col md={6}>
                <p>{data.hideInWebsite ? 'Yes' : 'No'}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Active</b>
              </Col>
              <Col md={6}>
                <p>{data.status ? 'Yes' : 'No'}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
