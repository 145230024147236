import { FormEvent, useState } from 'react'
import { frontendBaseUrl } from 'src/cms/helpers/constants'

export default function ISGRevalidate() {
  const [routeString, setRouteString] = useState('')

  const revalidateGivenRoute = (e: FormEvent) => {
    e.preventDefault()
    window.open(`${frontendBaseUrl}/api/revalidate?route=${routeString}`, "_blank")
  }

  const revalidateAll = () => {
    window.open(`${frontendBaseUrl}/api/revalidateAll`, "_blank")
  }

  return (
    <div className='shadow p-3 bg-white rounded h-100'>
      <h5 className='border-bottom py-3 mb-5'>ISG Revalidate</h5>
      <form className='d-flex align-items-center gap-3 mx-5 mb-5' onSubmit={revalidateGivenRoute}>
        <div className='d-flex flex-column items-center'>
          <label>Route Name</label>
          <input
            value={routeString}
            onChange={(e) => setRouteString(e.target.value)}
            className='form-control'
            type='text'
            placeholder='Route path'
            required
            data-cy='revalidate-input'
          />
          <i className='text-danger my-3'>
            {' '}
            "/" for homepage, similar to "/loans/" , "/loans/personal-loan/" for other pages.{' '}
          </i>
        </div>

        <button className='btn btn-primary mb-4' data-cy='revalidate-button'>Revalidate</button>
        <p>Or</p>
        <button onClick={revalidateAll} className='btn btn-primary mb-4' type='button' data-cy='revalidate-all-input'>
          Revalidate All Pages
        </button>
      </form>

    </div>
  )
}
