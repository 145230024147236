import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCustomerLoginModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getCustomerLoginDetails: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CUSTOMER_LOGIN_START,
    payload: params,
  }),
  getCustomerLoginDetailsSuccess: (data: any) => ({
    type: actionTypes.GET_CUSTOMER_LOGIN_SUCCESS,
    payload: data,
  }),
  getCustomerLoginDetailsFinish: () => ({
    type: actionTypes.GET_CUSTOMER_LOGIN_FINISH,
  }),

  deleteCustomerLoginDetails: (data: DeleteCustomerLoginModel[]) => ({
    type: actionTypes.DELETE_CUSTOMER_LOGIN_START,
    payload: {customer: data},
  }),
  deleteCustomerLoginDetailsSuccess: (data: any) => ({
    type: actionTypes.DELETE_CUSTOMER_LOGIN_SUCCESS,
    payload: data,
  }),
  deleteCustomerLoginDetailsFinish: () => ({
    type: actionTypes.DELETE_CUSTOMER_LOGIN_FINISH,
  }),
  activateCustomer: (id: any) => ({
    type: actionTypes.ACTIVATE_CUSTOMER_LOGIN_REQUEST,
    payload: {customers: id},
  }),
  deactivateCustomer: (id: any) => ({
    type: actionTypes.DEACTIVATE_CUSTOMER_LOGIN_REQUEST,
    payload: {customers: id},
  }),
  singleActivateCustomer: (id: any) => ({
    type: actionTypes.ACTIVATE_CUSTOMER_LOGIN_REQUEST,
    payload: {customers: [id]},
  }),
  singleDeactivateCustomer: (id: any) => ({
    type: actionTypes.DEACTIVATE_CUSTOMER_LOGIN_REQUEST,
    payload: {customers: [id]},
  }),
}
