import { ErrorMessage, Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'

import { isEmpty } from 'lodash'
import { StateParamsModel } from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as productManager from '../../../../products/components/productManager/index'
import * as loanSubCategory from '../index'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  status: Yup.string().required('Status is required'),
  loanTypeId: Yup.string().required('Loan Type is required'),
})

const AddLoanSubCategory = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const { loading, success } = useSelector((state: any) => state.loanSubCategory)

  const categoryData: any = useSelector(
    (state: any) => state?.productManager?.allProductManager?.productManager
  )

  useEffect(() => {
    dispatch(productManager.actions.getAllProductManager())
  }, [])

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  const categoryOptions = categoryData?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(loanSubCategory?.actions.getLoanSubCategory(params))
      isEmpty(editSelectedData)
        ? toast.success('Loan Sub Category added successfully')
        : toast.success('Loan Sub Category edited successfully')
      dispatch(loanSubCategory?.actions?.addLoanSubCategoryReset())
      handleClose()
    }
  }, [success])
  const isSingleLanguage = checkMultiLanguage()
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Loan Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                titleNp: '',
                loanTypeId: '',
                status: 'Active',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { }) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    loanSubCategory.actions.updateLoanSubCategory(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(loanSubCategory.actions.addLoanSubCategory(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['title', 'titleNp', 'loanTypeId', 'status']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title (EN)'
                            label='Title (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Title (NP)'
                              label='Title (NP)'
                              name='titleNp'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select loan type'
                            label='Loan Type'
                            name='loanTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        <div className='col-md-12 mt-5 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddLoanSubCategory
