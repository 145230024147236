import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getDisputeType: () => ({
    type: actionTypes.GET_DISPUTE_TYPE_START,
  }),

  getDisputeTypeSuccess: (data: any) => ({
    type: actionTypes.GET_DISPUTE_TYPE_SUCCESS,
    payload: data,
  }),
  getDisputeTypeFinish: () => ({
    type: actionTypes.GET_DISPUTE_TYPE_FINISH,
  }),
  getDisputeTypeError: (error: unknown) => ({
    type: actionTypes.GET_DISPUTE_TYPE_FINISH,
    payload: {error},
  }),

  getDisputeCard: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_DISPUTE_CARD_START,
    payload: params,
  }),

  getDisputeCardSuccess: (data: any) => ({
    type: actionTypes.GET_DISPUTE_CARD_SUCCESS,
    payload: data,
  }),
  getDisputeCardFinish: () => ({
    type: actionTypes.GET_DISPUTE_CARD_FINISH,
  }),
  getDisputeCardError: (error: unknown) => ({
    type: actionTypes.GET_DISPUTE_CARD_FINISH,
    payload: {error},
  }),

  addDisputeCard: (data: any) => ({
    type: actionTypes.ADD_DISPUTE_CARD_START,
    payload: data,
  }),
  addDisputeCardSuccess: (task: any) => ({
    type: actionTypes.ADD_DISPUTE_CARD_SUCCESS,
    payload: task,
  }),
  addDisputeCardFinish: () => ({
    type: actionTypes.ADD_DISPUTE_CARD_FINISH,
  }),
  addDisputeCardReset: () => ({
    type: actionTypes.ADD_DISPUTE_CARD_RESET,
  }),

  updateDisputeCard: (data: any, id: string) => ({
    type: actionTypes.UPDATE_DISPUTE_CARD_START,
    payload: {data, id},
  }),
  updateDisputeCardSuccess: (task: any) => ({
    type: actionTypes.UPDATE_DISPUTE_CARD_SUCCESS,
    payload: task,
  }),
  updateDisputeCardFinish: () => ({
    type: actionTypes.UPDATE_DISPUTE_CARD_FINISH,
  }),
  updateDisputeCardReset: () => ({
    type: actionTypes.UPDATE_DISPUTE_CARD_RESET,
  }),

  deleteDisputeCard: (data: any) => ({
    type: actionTypes.DELETE_DISPUTE_CARD_START,
    payload: {cardTypeId: data},
  }),
  deleteDisputeCardSuccess: (data: any) => ({
    type: actionTypes.DELETE_DISPUTE_CARD_SUCCESS,
    payload: data,
  }),
  deleteDisputeCardFinish: () => ({
    type: actionTypes.DELETE_DISPUTE_CARD_FINISH,
  }),
  activateDisputeCard: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_DISPUTE_CARD_REQUEST,
    payload: {cardTypeId: id},
  }),
  deactivateDisputeCard: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_CARD_REQUEST,
    payload: {cardTypeId: id},
  }),
  singleActivateDisputeCard: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_DISPUTE_CARD_REQUEST,
    payload: {cardTypeId: [id]},
  }),
  singleDeactivateDisputeCard: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_DISPUTE_CARD_REQUEST,
    payload: {cardTypeId: [id]},
  }),

  sortDisputeCard: (data: any) => ({
    type: actionTypes.SORT_DISPUTE_CARD_START,
    payload: data,
  }),
  sortDisputeCardSuccess: (data: any) => ({
    type: actionTypes.SORT_DISPUTE_CARD_SUCCESS,
    payload: data,
  }),
  sortDisputeCardFinish: () => ({
    type: actionTypes.SORT_DISPUTE_CARD_FINISH,
  }),
  sortDisputeCardReset: () => ({
    type: actionTypes.SORT_DISPUTE_CARD_RESET,
  }),
}
