import {ParamsModel} from 'src/app/modules/common/Model'
import {DeletePartnersModel, SortPartnersModel} from '../Model'
import {PartnersModel} from '../Model/PartnersModel'
import {actionTypes} from './constants'
export const actions = {
  // get Partners
  getAllPartners: () => ({
    type: actionTypes.GET_ALL_PARTNERS_START,
  }),
  getAllPartnersSuccess: (data: any) => ({
    type: actionTypes.GET_ALL_PARTNERS_SUCCESS,
    payload: data,
  }),
  getAllPartnersFinish: () => ({
    type: actionTypes.GET_ALL_PARTNERS_FINISH,
  }),

  // get Partners DATA
  getPartners: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_PARTNERS_START,
    payload: params,
  }),
  getPartnersSuccess: (data: any) => ({
    type: actionTypes.GET_PARTNERS_SUCCESS,
    payload: data,
  }),
  getPartnersFinish: () => ({
    type: actionTypes.GET_PARTNERS_FINISH,
  }),

  // create key
  addPartners: (data: PartnersModel) => ({
    type: actionTypes.ADD_PARTNERS_START,
    payload: data,
  }),
  addPartnersSuccess: (task: any) => ({
    type: actionTypes.ADD_PARTNERS_SUCCESS,
    payload: task,
  }),
  addPartnersFinish: () => ({
    type: actionTypes.ADD_PARTNERS_FINISH,
  }),
  resetPartners: () => ({
    type: actionTypes.RESET_PARTNERS,
  }),

  //Update Partners
  updatePartners: (data: PartnersModel, id: string) => ({
    type: actionTypes.UPDATE_PARTNERS_START,
    payload: {data, id},
  }),

  updatePartnersSuccess: (data: PartnersModel) => ({
    type: actionTypes.UPDATE_PARTNERS_SUCCESS,
    payload: data,
  }),

  updatePartnersFinish: () => ({
    type: actionTypes.UPDATE_PARTNERS_FINISH,
  }),

  // delete key
  deletePartners: (data: DeletePartnersModel[]) => ({
    type: actionTypes.DELETE_PARTNERS_START,
    payload: {partnersId: data},
  }),
  deletePartnersSuccess: (data: any) => ({
    type: actionTypes.DELETE_PARTNERS_SUCCESS,
    payload: data,
  }),
  deletePartnersFinish: () => ({
    type: actionTypes.DELETE_PARTNERS_FINISH,
  }),

  //Enable Partners
  enablePartners: (data: any) => ({
    type: actionTypes.ENABLE_PARTNERS_REQUEST,
    payload: {data},
  }),

  enablePartnersSuccess: (task: any) => ({
    type: actionTypes.ENABLE_PARTNERS_SUCCESS,
    payload: task,
  }),
  enablePartnersFinish: () => ({
    type: actionTypes.ENABLE_PARTNERS_FINISH,
  }),

  //Disable Partners
  disablePartners: (data: any) => ({
    type: actionTypes.DISABLE_PARTNERS_REQUEST,
    payload: {data},
  }),

  disablePartnersSuccess: (task: any) => ({
    type: actionTypes.DISABLE_PARTNERS_SUCCESS,
    payload: task,
  }),
  disablePartnersFinish: () => ({
    type: actionTypes.DISABLE_PARTNERS_FINISH,
  }),

  //Enable Partners
  singleEnablePartners: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_PARTNERS_REQUEST,
    payload: {data},
  }),

  singleEnablePartnersSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_PARTNERS_SUCCESS,
    payload: task,
  }),
  singleEnablePartnersFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_PARTNERS_FINISH,
  }),

  //Disable Partners
  singleDisablePartners: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_PARTNERS_REQUEST,
    payload: {data},
  }),

  singleDisablePartnersSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_PARTNERS_SUCCESS,
    payload: task,
  }),
  singleDisablePartnersFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_PARTNERS_FINISH,
  }),
  // sort
  sortPartners: (data: SortPartnersModel | any) => ({
    type: actionTypes.SORT_PARTNERS_START,
    payload: data,
  }),
  sortPartnersSuccess: (data: Array<PartnersModel>) => ({
    type: actionTypes.SORT_PARTNERS_SUCCESS,
    payload: data,
  }),
  sortPartnersFinish: () => ({
    type: actionTypes.SORT_PARTNERS_FINISH,
  }),
  sortPartnersReset: () => ({
    type: actionTypes.SORT_PARTNERS_RESET,
  }),
}
