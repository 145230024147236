import axios from 'axios'
import { GoogleParamsModel, ParamsModel } from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GOOGLE_ANALYTIC_USER = `${API_URL}/googleanalytics/total-users`
export const GOOGLE_ANALYTIC_COUNTRY = `${API_URL}/googleanalytics/users-from-all-around-world`
export const GOOGLE_ANALYTIC_UNIQUE = `${API_URL}/googleanalytics/total-uniqueusers`

export const service = {
  getUsersDetail: (params: GoogleParamsModel) => {
    return axios.get(GOOGLE_ANALYTIC_USER, {params})
  },
 
  getCountryDetail: (params: ParamsModel) => {
    return axios.get(GOOGLE_ANALYTIC_COUNTRY, {params})
  },
 
  getUniqueUsersDetail: (params: ParamsModel) => {
    return axios.get(GOOGLE_ANALYTIC_UNIQUE, {params})
  },
 

}
