import {Action} from 'redux'
import {ProgramModel, ProgramOptionModal} from '../Model/ProgramModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialProgramState: IProgramState = {
  data: {
    program: [],
    meta: [],
  },
  sortProgramData: [],
  programList: {program: []},
  bannerOption: [],
  albumOption: [],
  downloadOption: [],
  pageHeaderOption: [],
  leadOption: [],
  relatedOptions: [],
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IProgramState {
  data?: {
    program?: ProgramModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortProgramData?: IProgramState[]
  programList?: {program: ProgramModel[]}
  bannerOption?: ProgramOptionModal[]
  albumOption?: ProgramOptionModal[]
  downloadOption: ProgramOptionModal[]
  pageHeaderOption: ProgramOptionModal[]
  leadOption: ProgramOptionModal[]
  relatedOptions: ProgramOptionModal[]
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IProgramState = initialProgramState,
  action: ActionWithPayload<IProgramState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PROGRAM_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_PROGRAM_SUCCESS: {
      return {
        ...state,
        programList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_PROGRAM_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET Program DATA
    case actionTypes.GET_PROGRAM_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROGRAM_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_PROGRAM_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add Program
    case actionTypes.ADD_PROGRAM_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_PROGRAM_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_PROGRAM_TAG: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Program
    case actionTypes.UPDATE_PROGRAM_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_PROGRAM_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_PROGRAM_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_PROGRAM_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_PROGRAM_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_PROGRAM_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_PROGRAM_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_PROGRAM_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_PROGRAM_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_PROGRAM_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_PROGRAM_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_PROGRAM_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_PROGRAM_SUCCESS: {
      const changedData: any = action.payload?.data?.program
      let newData = state?.data?.program?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, program: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_PROGRAM_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_PROGRAM_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_PROGRAM_SUCCESS: {
      const changedData: any = action.payload?.data?.program
      let newData = state?.data?.program?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, program: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_PROGRAM_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_PROGRAM_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_PROGRAM_SUCCESS: {
      return {
        ...state,
        sortProgramData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_PROGRAM_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_PROGRAM_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortProgramData: [],
      }
    }

    //GET Banner OPTION REDUCER
    case actionTypes.GET_PROGRAM_BANNER_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROGRAM_BANNER_OPTION_SUCCESS: {
      return {
        ...state,
        bannerOption: action.payload?.bannerOption,
        loading: false,
      }
    }

    case actionTypes.GET_PROGRAM_BANNER_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    //GET Album OPTION REDUCER
    case actionTypes.GET_PROGRAM_ALBUM_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROGRAM_ALBUM_OPTION_SUCCESS: {
      return {
        ...state,
        albumOption: action.payload?.albumOption,
        loading: false,
      }
    }

    case actionTypes.GET_PROGRAM_ALBUM_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    //GET Downlaod OPTION REDUCER
    case actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_SUCCESS: {
      return {
        ...state,
        downloadOption: action.payload?.downloadOption,
        loading: false,
      }
    }

    case actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    //GET LEAD FORM REDUCER
    case actionTypes.GET_PROGRAM_LEAD_FORM_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROGRAM_LEAD_FORM_SUCCESS: {
      return {
        ...state,
        leadOption: action.payload?.leadOption,
        loading: false,
      }
    }

    case actionTypes.GET_PROGRAM_LEAD_FORM_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    //GET PAGE_HEADER REDUCER
    case actionTypes.GET_PROGRAM_PAGE_HEADER_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROGRAM_PAGE_HEADER_SUCCESS: {
      return {
        ...state,
        pageHeaderOption: action.payload?.pageHeaderOption,
        loading: false,
      }
    }

    case actionTypes.GET_PROGRAM_PAGE_HEADER_START: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    //GET PROGRAM_OPTION REDUCER
    case actionTypes.GET_PROGRAM_OPTION_START: {
      return {...state, loading: true}
    }
    case actionTypes.GET_PROGRAM_OPTION_SUCCESS: {
      return {
        ...state,
        relatedOptions: action.payload?.relatedOptions,
        loading: false,
      }
    }
    case actionTypes.GET_PROGRAM_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    default:
      return state
  }
}
