import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialDisputeOptionState: IDisputeOptionState = {
  data: {
    disputeOption: [],
    meta: {},
  },
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface IDisputeOptionState {
  data?: {
    disputeOption?: []
    meta?: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IDisputeOptionState = initialDisputeOptionState,
  action: ActionWithPayload<IDisputeOptionState>
) => {
  switch (action.type) {
    case actionTypes.GET_DISPUTE_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_DISPUTE_OPTION_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_DISPUTE_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_DISPUTE_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_DISPUTE_OPTION_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_DISPUTE_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_DISPUTE_OPTION_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_DISPUTE_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_DISPUTE_OPTION_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_DISPUTE_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_DISPUTE_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_DISPUTE_OPTION_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_DISPUTE_OPTION_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_DISPUTE_OPTION_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_DISPUTE_OPTION_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_DISPUTE_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_DISPUTE_OPTION_SUCCESS: {
      return {
        ...state,
        sortDisputeOptionData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_DISPUTE_OPTION_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_DISPUTE_OPTION_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortDisputeOptionData: [],
      }
    }

    default:
      return state
  }
}
