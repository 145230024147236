import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'
import {BankDetailModel, SortBankDetailActionModel, SortBankDetailModel} from '../Model'

function* getAboutBankDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAboutBankDetail, params)
    yield put(actions.getAboutBankDetailSuccess(response?.data?.data))
    yield put(actions.getAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAboutBankDetailFinish())
  }
}

function* addAboutBankDetailSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBankDetail, body)
    yield put(actions.addAboutBankDetailSuccess(response.data?.data))
    yield put(actions.addAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addAboutBankDetailFinish())
  }
}

function* enableAboutBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableBankDetail, selectedUsers)
    yield put(actions.enableAboutBankDetailSuccess(response?.data))
    yield put(actions.enableAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableAboutBankDetailFinish())
  }
}

function* disableAboutBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableBankDetail, selectedUsers)
    yield put(actions.disableAboutBankDetailSuccess(response?.data))
    yield put(actions.disableAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableAboutBankDetailFinish())
  }
}

function* singleEnableAboutBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableBankDetail, selectedUsers)
    yield put(actions.singleEnableAboutBankDetailSuccess(response?.data))
    yield put(actions.singleEnableAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableAboutBankDetailFinish())
  }
}

function* singleDisableAboutBankDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableBankDetail, selectedUsers)
    yield put(actions.singleDisableAboutBankDetailSuccess(response?.data))
    yield put(actions.singleDisableAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableAboutBankDetailFinish())
  }
}

function* updateAboutBankDetailSaga(action: ActionModel) {
  try {
    const body: BankDetailModel = action.payload
    const response: ResponseModel = yield call(service.updateBankDetail, body, action.payload?.id)
    yield put(actions.updateAboutBankDetailSuccess(response.data?.data))
    yield put(actions.updateAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateAboutBankDetailFinish())
  }
}

function* deleteAboutBankDetailSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBankDetail, body)

    yield put(actions.deleteAboutBankDetailSuccess(response.data?.data))
    yield put(actions.deleteAboutBankDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteAboutBankDetailFinish())
  }
}

//Get BankDetail saga
function* getAllAboutBankDetailSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getAllBankDetail)
    const data: any = response?.data
    yield put(actions.getAllAboutBankDetailSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getAllAboutBankDetailError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ABOUT_BANK_DETAIL_START, getAboutBankDetailSaga)

  /*   yield takeLatest(actionTypes.GET_ABOUT_BANK_DETAIL_START, getAllBankDetailSaga) */
  yield takeLatest(actionTypes.ADD_ABOUT_BANK_DETAIL_START, addAboutBankDetailSaga)
  yield takeLatest(actionTypes.UPDATE_ABOUT_BANK_DETAIL_START, updateAboutBankDetailSaga)
  yield takeLatest(actionTypes.DELETE_ABOUT_BANK_DETAIL_START, deleteAboutBankDetailSaga)
  yield takeLatest(actionTypes.ENABLE_ABOUT_BANK_DETAIL_REQUEST, enableAboutBankDetailSaga)
  yield takeLatest(actionTypes.DISABLE_ABOUT_BANK_DETAIL_REQUEST, disableAboutBankDetailSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_ABOUT_BANK_DETAIL_REQUEST,
    singleEnableAboutBankDetailSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_ABOUT_BANK_DETAIL_REQUEST,
    singleDisableAboutBankDetailSaga
  )
  yield takeLatest(actionTypes.GET_ALL_ABOUT_BANK_DETAIL_START, getAllAboutBankDetailSaga)
}
