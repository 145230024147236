import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteStockRateModel, StockRateModel} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_STOCK_RATE = `${API_URL}/share`
export const GET_DATA = `${API_URL}/share/get-sample`

export const service = {
  getStock: (params: ParamsModel) => {
    return axios.get(GET_STOCK_RATE, {params})
  },

  //Get file
  getStockFile: (fileType: string, data: any) => {
    return axios.get(`${GET_STOCK_RATE}/export`).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${'Stock'}.${fileType}`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  //Post Stock rate
  postStockRate: (body: StockRateModel) => {
    return axios.post(`${GET_STOCK_RATE}/storeData`, body)
  },

  //Activate Deactivate
  activateStockRate: (data: Array<string>) => {
    const formData = {
      shareId: data,
    }
    return axios.patch(`${GET_STOCK_RATE}/enable`, formData)
  },

  //Latest needed
  singleActivateStockRate: (id: string) => {
    return axios.patch(`${GET_STOCK_RATE}/${id}/enable`)
  },

  singleDeactivateStockRate: (id: string) => {
    return axios.patch(`${GET_STOCK_RATE}/${id}/disable`)
  },

  deactivateStockRate: (data: Array<string>) => {
    const formData = {
      shareId: data,
    }
    return axios.patch(`${GET_STOCK_RATE}/disable`, formData)
  },

  //Import File from Pc
  importStockRate: (data: any) => {
    return axios.post(`${GET_STOCK_RATE}/import`, data)
  },

  //Update Stock Rate
  updateStockRate: (body: StockRateModel, id: string) => {
    return axios.patch(`${GET_STOCK_RATE}/${id}`, body)
  },

  //Delete Stock Rate
  deleteStockRate: (data: DeleteStockRateModel) => {
    return axios.delete(`${GET_STOCK_RATE}/destroy`, {data})
  },

  //export template
  exportTemplateFile: () => {
    return axios.get(`${GET_DATA}`).then((response) => {
      // const blob = new Blob([response?.data])
      // const url = window.URL.createObjectURL(blob)
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', '') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
