import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ApplyMembershipModel, DeleteApplyMembershipModel} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_APPLY_MEMBERSHIP = `${API_URL}/apply-membership`
export const ADD_APPLY_MEMBERSHIP = `${API_URL}/apply-membership`
export const ACTIVATE_APPLY_MEMBERSHIPS = `${API_URL}/apply-membership/enable`
export const DEACTIVATE_APPLY_MEMBERSHIPS = `${API_URL}/apply-membership/disable`
export const DELETE_APPLY_MEMBERSHIP = `${API_URL}/apply-membership`

export const service = {
  getApplyMembership: (params: ParamsModel) => {
    return axios.get(GET_APPLY_MEMBERSHIP, {params})
  },
  getAllApplyMembership: () => {
    return axios.get(`${GET_APPLY_MEMBERSHIP}/list`)
  },

  addApplyMembership: (data: any) => {
    return axios.post(ADD_APPLY_MEMBERSHIP, data)
  },

  activateApplyMembership: (data: Array<string>) => {
    const formData = {
      applyMembershipId: data,
    }
    return axios.patch(ACTIVATE_APPLY_MEMBERSHIPS, formData)
  },

  singleActivateApplyMembership: (data: Array<string>) => {
    const formData = {
      applyMembershipId: [data],
    }
    return axios.patch(ACTIVATE_APPLY_MEMBERSHIPS, formData)
  },
  singleDeactivateApplyMembership: (data: Array<string>) => {
    const formData = {
      applyMembershipId: [data],
    }
    return axios.patch(DEACTIVATE_APPLY_MEMBERSHIPS, formData)
  },

  deactivateApplyMembership: (data: Array<string>) => {
    const formData = {
      applyMembershipId: data,
    }
    return axios.patch(DEACTIVATE_APPLY_MEMBERSHIPS, formData)
  },

  updateApplyMembership: (body: ApplyMembershipModel, id: string) => {
    return axios.patch(`${ADD_APPLY_MEMBERSHIP}/${id}`, body)
  },

  deleteApplyMembership: (data: DeleteApplyMembershipModel) => {
    return axios.delete(DELETE_APPLY_MEMBERSHIP, {data})
  },
}
