import {ParamsModel} from 'src/app/modules/common/Model'
import {
  ProductSubCategoryModel,
  SortProductSubCategoryModel,
} from '../Model/ProductSubCategoryModel'
import {actionTypes} from './constants'
export const actions = {
  // get ProductSubCategory
  getAllProductSubCategory: () => ({
    type: actionTypes.GET_ALL_PRODUCT_SUB_CATEGORY_START,
  }),
  getAllProductSubCategorySuccess: (data: ProductSubCategoryModel | any) => ({
    type: actionTypes.GET_ALL_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  getAllProductSubCategoryFinish: () => ({
    type: actionTypes.GET_ALL_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  // get ProductSubCategory DATA
  getProductSubCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_PRODUCT_SUB_CATEGORY_START,
    payload: params,
  }),
  getProductSubCategorySuccess: (data: ProductSubCategoryModel | any) => ({
    type: actionTypes.GET_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  getProductSubCategoryFinish: () => ({
    type: actionTypes.GET_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  // create key
  addProductSubCategory: (data: ProductSubCategoryModel | any) => ({
    type: actionTypes.ADD_PRODUCT_SUB_CATEGORY_START,
    payload: data,
  }),
  addProductSubCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  addProductSubCategoryFinish: () => ({
    type: actionTypes.ADD_PRODUCT_SUB_CATEGORY_FINISH,
  }),
  resetProductSubCategory: () => ({
    type: actionTypes.RESET_PRODUCT_SUB_CATEGORY,
  }),

  //Update ProductSubCategory
  updateProductSubCategory: (data: ProductSubCategoryModel | any, id: string) => ({
    type: actionTypes.UPDATE_PRODUCT_SUB_CATEGORY_START,
    payload: data,
    id,
  }),

  updateProductSubCategorySuccess: (data: ProductSubCategoryModel | any) => ({
    type: actionTypes.UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),

  updateProductSubCategoryFinish: () => ({
    type: actionTypes.UPDATE_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  // delete key
  deleteProductSubCategory: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_PRODUCT_SUB_CATEGORY_START,
    payload: {productSubCategoryId: data},
  }),
  deleteProductSubCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteProductSubCategoryFinish: () => ({
    type: actionTypes.DELETE_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  //Enable ProductSubCategory
  enableProductSubCategory: (data: any) => ({
    type: actionTypes.ENABLE_PRODUCT_SUB_CATEGORY_REQUEST,
    payload: {data},
  }),

  enableProductSubCategorySuccess: (task: any) => ({
    type: actionTypes.ENABLE_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  enableProductSubCategoryFinish: () => ({
    type: actionTypes.ENABLE_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  //Disable ProductSubCategory
  disableProductSubCategory: (data: any) => ({
    type: actionTypes.DISABLE_PRODUCT_SUB_CATEGORY_REQUEST,
    payload: {data},
  }),

  disableProductSubCategorySuccess: (task: any) => ({
    type: actionTypes.DISABLE_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  disableProductSubCategoryFinish: () => ({
    type: actionTypes.DISABLE_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  //Enable ProductSubCategory
  singleEnableProductSubCategory: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleEnableProductSubCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleEnableProductSubCategoryFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  //Disable ProductSubCategory
  singleDisableProductSubCategory: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleDisableProductSubCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleDisableProductSubCategoryFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_PRODUCT_SUB_CATEGORY_FINISH,
  }),

  //Sort ProductSubCategory
  sortProductSubCategory: (data: SortProductSubCategoryModel) => ({
    type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_START,
    payload: data,
  }),
  sortProductSubCategorySuccess: (data: Array<ProductSubCategoryModel>) => ({
    type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortProductSubCategoryFinish: () => ({
    type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_FINISH,
  }),
  sortProductSubCategoryReset: () => ({
    type: actionTypes.SORT_PRODUCT_SUB_CATEGORY_RESET,
  }),
}
