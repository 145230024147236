import {LoadingOutlined} from '@ant-design/icons'
import {Spin} from 'antd'
import axios from 'axios'
import {useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {Modal} from 'rsuite'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  data: {
    id: String
    name: String
    prefix: String
    uniqueCode: String
    dobAd: String
    dobBs: String
    age: String
    gender: String
    grandfatherName: String
    fatherName: String
    motherName: String
    placeOfBirth: String
    bloodGroup: String
    citizenshipNumber: String
    districtId: String
    citizenshipIssueDateAd: String
    citizenshipIssueDateBs: String
    maritalStatus: String
    email: String
    mobileNumber: String
    telephone: String
    sameAsPermanent: true
    workedHere: true
    internshipStatusId: String
    status: true
    internshipPermanentAddress: {
      id: String
      internshipId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
    }
    internshipTemporaryAddress: {
      id: String
      internshipId: String
      provinceId: String
      zoneId: String
      districtId: String
      municipalityId: String
      wardNumber: String
    }
    internshipEducation: {
      internshipEducation: [
        {
          id: String
          internshipId: String
          nameAddressInstituition: String
          university: String
          qualificationId: String
          areaSpecialization: String
          passingYear: String
          percentage: String
          cgpa: String
          marksheet: String
        }
      ]
    }
    internshipJobPreferanceDetail: {
      internshipStatus: [
        {
          branchId: any
          provinceId: any
          departmentId: String
          id: String
          internshipId: String
          status: String
        }
      ]
    }

    internshipDocument: {
      id: String
      internshipId: String
      latestPhoto: String
      resume: String
      citizenship: String
      application: String
      recommendationLetter: String
      frontCitizenship: String
      backCitizenship: String
    }
    internshipCurrentExperience: {
      internshipCurrentExperience: [
        {
          id: String
          internshipId: String
          organizationName: String
          designation: String
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          lastPromotionDate: String
          document: String
          expectedSalary: String
        }
      ]
    }
    internshipRecentExperience: {
      internshipRecentExperience: [
        {
          id: String
          internshipId: String
          organizationName: String
          designation: String
          functionalAreaId: String
          fromDate: String
          toDate: String
          salaryPerMonth: String
          jobResponsibilities: String
          totalMonth: String
          reasonForLeaving: String
        }
      ]
    }
    internshipWorkedHereDetail: {
      id: String
      internshipId: String
      branchId: String
      departmentId: String
      position: String
      fromDate: String
      toDate: String
      status: String
    }
  }
  handleClose: () => void
  provinceList: Array<any>
  districtList: Array<any>
  zoneList: Array<any>
  municipalityList: Array<any>
  functionalAreaList: Array<any>
  branchList: Array<any>
  departmentList: Array<any>
  educationList: Array<any>
}

const ViewCandidateDetails = ({
  data,
  handleClose,
  branchList,
  departmentList,
  provinceList,
  districtList,
  zoneList,
  municipalityList,
  functionalAreaList,
  educationList,
}: Props) => {
  const [loading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState('')
  const ftpStorage = checkBackendSetting('ftpStorage')
  const FTP_API = '/api/apply-vacancy/ftp-file-download?'

  const handleOpenFile = async (url: any, fileName: string) => {
    setIsLoading(true)
    setFileName(fileName)
    const fileExtension = url.split('.').pop()
    await axios
      .get(`${imageBaseUrl}${FTP_API}ftpFilePath=${url}`, {
        method: 'GET',
        responseType: 'blob',
      })
      .then((response: any) => {
        setIsLoading(false)
        setFileName('')
        const blob = new Blob([response.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.${fileExtension}`)
        document.body.appendChild(link)
        link.click()
        toast.success('Download successfully')
      })
      .catch((exception) => {
        setIsLoading(false)
        setFileName('')
        toast.error('Internal error occurred')
        console.log('exception', exception)
      })
  }

  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Candidate Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>Personal Details</h5>
            <Row>
              <Col md={3}>Prefix : {data?.prefix}</Col>
              <Col md={3}>Name : {data?.name}</Col>
              <Col md={3}>Unique Code : {data?.uniqueCode}</Col>
              <Col md={3}>Date Of Birth (A.D): {data?.dobAd}</Col>
            </Row>

            <Row className='mt-5'>
              <Col md={3}>Email : {data?.email}</Col>
              <Col md={3}>Mobile Number: {data?.mobileNumber}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.internshipPermanentAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>

              <Col md={3}>
                Permanent District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.internshipPermanentAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Permanent Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.internshipPermanentAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Permanent Ward Number: {data?.internshipPermanentAddress?.wardNumber}
              </Col>
              <Col md={3}>
                Present Province:{' '}
                {provinceList.filter(
                  (d: any) => d.id === data?.internshipTemporaryAddress?.provinceId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>
                Present District:{' '}
                {districtList.filter(
                  (d: any) => d.id === data?.internshipTemporaryAddress?.districtId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Present Municipality/VDC:{' '}
                {municipalityList.filter(
                  (d: any) => d.id === data?.internshipTemporaryAddress?.municipalityId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>

            <h5 className='mt-5'>Education Details</h5>
            {data?.internshipEducation?.internshipEducation?.map(
              (education: any, index: number) => {
                return (
                  <div key={index}>
                    <Row className='mt-5'>
                      <Col md={6}>
                        Higher Educational Qualification:{' '}
                        {educationList.filter((d: any) => d.id === education?.qualificationId)[0]
                          ?.name || 'N/A'}
                      </Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col md={3}>CGPA: {education?.cgpa}</Col>
                      <Col md={3}>Percentage : {education?.percentage}</Col>
                    </Row>
                  </div>
                )
              }
            )}

            <h5 className='mt-5'>Job Reference </h5>
            <Row className='mt-5'>
              <Col md={3}>
                Department:{' '}
                {departmentList.filter(
                  (d) =>
                    d.id === data?.internshipJobPreferanceDetail?.internshipStatus[0].departmentId
                )[0]?.name || 'N/A'}
              </Col>

              <Col md={3}>
                Province:{' '}
                {provinceList.filter(
                  (d) =>
                    d.id === data?.internshipJobPreferanceDetail?.internshipStatus[0].provinceId
                )[0]?.title || 'N/A'}
              </Col>
              <Col md={3}>
                Branch :{' '}
                {branchList.filter(
                  (d) => d.id === data?.internshipJobPreferanceDetail?.internshipStatus[0].branchId
                )[0]?.title || 'N/A'}
              </Col>
            </Row>

            <h5 className='mt-5'>Document Details</h5>
            <Row className='mt-5'>
              <Col md={3}>
                Citizenship:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() =>
                      handleOpenFile(`${data?.internshipDocument?.frontCitizenship}`, 'citizenship')
                    }
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'citizenship' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a
                    target='_blank'
                    href={imageBaseUrl + '/' + data?.internshipDocument?.frontCitizenship}
                  >
                    View
                  </a>
                )}
              </Col>
              <Col md={3}>
                Resume:{' '}
                {ftpStorage ? (
                  <span
                    onClick={() => handleOpenFile(`${data?.internshipDocument?.resume}`, 'resume')}
                    className='text-danger cursor-pointer'
                  >
                    {loading && fileName === 'resume' ? (
                      <Spin indicator={<LoadingOutlined style={{fontSize: 15}} spin />} />
                    ) : (
                      'View'
                    )}
                  </span>
                ) : (
                  <a target='_blank' href={imageBaseUrl + '/' + data?.internshipDocument?.resume}>
                    View
                  </a>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewCandidateDetails
