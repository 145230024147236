import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {VacancyModel, DeleteVacancyModel} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const VACANCY = `${API_URL}/vacancy`

export const service = {
  getVacancy : (params: ParamsModel) => {
    return axios.get(VACANCY, {params})
  },
  getAllVacancy: () => {
    return axios.get(`${VACANCY}`)
  },

  addVacancy: (data: any) => {
    return axios.post(VACANCY, data)
  },

  activateVacancy: (data: Array<string>) => {
    const formData = {
      vacancyId: data,
    }
    return axios.patch(`${VACANCY}/enable`, formData)
  },

  singleActivateVacancy: (data: Array<string>) => {
    const formData = {
      vacancyId: [data],
    }
    return axios.patch(`${VACANCY}/enable`, formData)
  },
  singleDeactivateVacancy: (data: Array<string>) => {
    const formData = {
      vacancyId: [data],
    }
    return axios.patch(`${VACANCY}/disable`, formData)
  },

  deactivateVacancy: (data: Array<string>) => {
    const formData = {
      vacancyId: data,
    }
    return axios.patch(`${VACANCY}/disable`, formData)
  },

  updateVacancy: (body: VacancyModel, id: string) => {
    return axios.put(`${VACANCY}/${id}`, body)
  },

  deleteVacancy: (data: VacancyModel) => {
    return axios.delete(VACANCY, {data})
  },
}
