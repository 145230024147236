import {FieldArray, FormikErrors} from 'formik'
import {isEmpty} from 'lodash'
import {ChangeEvent} from 'react'
import {FormOptionModal} from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  values: any
  sliderSectionOptions: FormOptionModal[]
  validationState?: {[key: string]: Yup.StringSchema}
  setValidationState?: any
  setFieldValue: any
}

export function SliderComponent({
  handleChange,
  errors,
  touched,
  values,
  sliderSectionOptions,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {
  const showSliderSectionId = sliderSectionOptions?.find(
    (item: FormOptionModal) => item.value === values.show_slider_id
  )

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Show Slider Section'
              name='show_slider_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={sliderSectionOptions}
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          {showSliderSectionId?.systemName === 'yes' ? (
            <>
              <div className='row'>
                <FieldArray
                  name='sliderData'
                  render={(arrayHelpers) => (
                    <div>
                      <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                        <button
                          className='p-2 ps-5 pe-5 btn btn-primary'
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              title_np: '',
                              imageData: [{name: '', name_np: '', image: '', link: ''}],
                            })
                          }
                          data-cy='modal-field-add'
                        >
                          + Add More Slider
                        </button>
                      </div>
                      {values.sliderData && values.sliderData.length > 0
                        ? values.sliderData.map((friend: any, index: any) => (
                            <div className='row' key={index}>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Enter Title'
                                  label='Title (EN)'
                                  name={`sliderData.${index}.title`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Enter Title (NP)'
                                  label='Title (NP)'
                                  name={`sliderData.${index}.title_np`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <FieldArray
                                name={`sliderData.${index}.imageData`}
                                render={(arrayHelpers2) => (
                                  <div>
                                    {values.sliderData[index]?.imageData &&
                                    values.sliderData[index]?.imageData.length > 0
                                      ? values?.sliderData[index]?.imageData?.map(
                                          (friend: any, iconIndex: any) => (
                                            <div className='row' key={iconIndex}>
                                              <div className='col-md-6 col-xs-12'>
                                                <FormTextBox
                                                  type='text'
                                                  labelClassName='col-md-12'
                                                  containerClassName='col-md-12'
                                                  placeholder='Enter Alt Name'
                                                  label='Alt Name (EN)'
                                                  name={`sliderData.${index}.imageData.${iconIndex}.name`}
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                />
                                              </div>
                                              <div className='col-md-6 col-xs-12'>
                                                <FormTextBox
                                                  type='text'
                                                  labelClassName='col-md-12'
                                                  containerClassName='col-md-12'
                                                  placeholder='Enter Alt Name (NP)'
                                                  label='Alt Name (NP)'
                                                  name={`sliderData.${index}.imageData.${iconIndex}.name_np`}
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                />
                                              </div>
                                              <div className='col-md-6 col-xs-12'>
                                                <FormInputMediaManager
                                                  labelClassName='col-md-12'
                                                  containerClassName='col-md-12'
                                                  label='Upload Image'
                                                  name={`sliderData.${index}.imageData.${iconIndex}.image`}
                                                  setFieldValue={setFieldValue}
                                                  value={
                                                    values.sliderData[index]?.imageData[iconIndex]
                                                      ?.image
                                                  }
                                                />

                                                {!isEmpty(
                                                  values?.sliderData[index]?.imageData[iconIndex]
                                                    ?.image
                                                ) ? (
                                                  <>
                                                    <li className='listing'>
                                                      <div className='thumbImageBlock'>
                                                        <button
                                                          type='button'
                                                          title='Remove'
                                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                          onClick={() => {
                                                            setFieldValue(
                                                              `sliderData.${index}.imageData.${iconIndex}.image`,
                                                              ''
                                                            )
                                                          }}
                                                          data-cy='modal-thumbnail-remove'
                                                        >
                                                          X
                                                        </button>

                                                        <img
                                                          className='thumbImage w-100 h-100'
                                                          src={`${imageBaseUrl}/${values?.sliderData[index]?.imageData[iconIndex]?.image}`}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </li>
                                                  </>
                                                ) : null}
                                              </div>
                                              <div className='col-md-6 col-xs-12'>
                                                <FormTextBox
                                                  type='text'
                                                  labelClassName='col-md-12'
                                                  containerClassName='col-md-12'
                                                  placeholder='Enter Link'
                                                  label='Link'
                                                  name={`sliderData.${index}.imageData.${iconIndex}.link`}
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                />
                                              </div>

                                              {iconIndex > 0 && (
                                                <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                                  <button
                                                    type='button'
                                                    className='p-2 ps-5 pe-5 btn btn-secondary'
                                                    onClick={() => arrayHelpers2.remove(iconIndex)}
                                                    data-cy='modal-field-remove'
                                                  >
                                                    Remove
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )
                                      : null}
                                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                      <button
                                        className='p-2 ps-5 pe-5 btn btn-primary'
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers2.push({
                                            name: '',
                                            nameNp: '',
                                            image: '',
                                            link: '',
                                          })
                                        }
                                        data-cy='modal-field-add'
                                      >
                                        + Add More Component
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                              {index > 0 && (
                                <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                  <button
                                    type='button'
                                    className='p-2 ps-5 pe-5 btn btn-secondary'
                                    onClick={() => arrayHelpers.remove(index)}
                                    data-cy='modal-field-remover'
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </div>
                          ))
                        : null}
                    </div>
                  )}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
