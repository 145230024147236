import {FormikErrors} from 'formik'
import {ChangeEvent} from 'react'
import {FormOptionModal} from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import * as Yup from 'yup'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{[key: string]: string}> | any
  touched: any
  downloadOptionOptions: FormOptionModal[]
  downloadDataOption?: FormOptionModal[]
  validationState: {[key: string]: Yup.StringSchema}
  setValidationState: any
  values: any
  setFieldValue: any
}

export function DownloadComponent({
  handleChange,
  errors,
  touched,
  values,
  downloadDataOption,
  downloadOptionOptions,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {
  const showDownloadId = downloadOptionOptions?.find(
    (item: FormOptionModal) => item.value === values.download_option_id
  )
  // useEffect(() => {
  //   showDownloadId?.systemName === 'yes'
  //     ? setValidationState({
  //         ...validationState,
  //         downloadSection: Yup.string().required('Download  Data is required'),
  //       })
  //     : showDownloadId?.systemName === 'no'
  //     ? setValidationState({
  //         ...validationState,
  //         downloadSection: Yup.string().nullable(),
  //       })
  //     : null
  // }, [values.downloadSection])

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Show download'
              name='download_option_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={downloadOptionOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          {showDownloadId?.systemName === 'yes' ? (
            <>
              <div className='col-md-6 col-xs-12'>
                <FormSelect
                  labelClassName='col-md-12'
                  containerClassName='col-md-12'
                  placeholder='Please select'
                  label='Downloads'
                  name='downloadSection'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={downloadDataOption}
                  values={values}
                  setFieldValue={setFieldValue}
                  multiple={true}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
