import {ParamsModel} from 'src/app/modules/common/Model'
import { GalleryFileTypeModel } from '../Model'
import {actionTypes} from './constants'
import { IGalleryFileTypeState } from './reducer'
export const actions = {


    // get Gallery File Type Data
    getGalleryFileType: (params: ParamsModel = {page: 1, limit: 10}) => ({
      type: actionTypes.GET_GALLERY_FILE_TYPE_START,
      payload: params,
    }),
    getGalleryFileTypeSuccess: (data: GalleryFileTypeModel) => ({
      type: actionTypes.GET_GALLERY_FILE_TYPE_SUCCESS,
      payload: data,
    }),
    getGalleryFileTypeFinish: () => ({
      type: actionTypes.GET_GALLERY_FILE_TYPE_FINISH,
    }), 
}