import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get TisaForex Rate
function* getTisaForexRateSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getTisaForexRate, params)
    const data: any = response?.data
    yield put(actions.getTisaForexRateSuccess(data))
  } catch (error: any) {
    yield put(actions.getTisaForexRateError(error))
  }
}

//Get gold rate file
function* getTisaForexRateFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getTisaForexRateFile, fileName)
    const data: any = response?.data
    yield put(actions.getTisaForexRateFileSuccess(data))
  } catch (error: any) {
    yield put(actions.getTisaForexRateFileError(error))
  }
}

//Activate Deactivate TisaForex Rate
function* activateTisaForexRatesSaga(action: ActionModel) {
  try {
    const selectedTisaForexRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateTisaForexRate, selectedTisaForexRate)
    yield put({type: actionTypes.ACTIVATE_TISA_FOREX_RATE_SUCCESS, payload: response?.data})
    yield put({type: actionTypes.ACTIVATE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_TISA_FOREX_RATE_FINISH})
  }
}

//Single Activate
function* singleActivateTisaForexRate(action: ActionModel) {
  try {
    const selectedTisaForexRate: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateTisaForexRate,
      selectedTisaForexRate
    )
    yield put({type: actionTypes.SINGLE_ACTIVATE_TISA_FOREX_RATE_SUCCESS, payload: response?.data})
    yield put({type: actionTypes.SINGLE_ACTIVATE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_TISA_FOREX_RATE_FINISH})
  }
}

function* singleDeactivateTisaForex(action: ActionModel) {
  try {
    const selectedGoldRate: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateTisaForexRate,
      selectedGoldRate
    )
    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_SUCCESS,
      payload: response?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_FINISH})
  }
}

function* deactivateTisaForexRate(action: ActionModel) {
  try {
    const selectedGoldRate: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateTisaForexRate, selectedGoldRate)
    yield put({type: actionTypes.DEACTIVATE_TISA_FOREX_RATE_SUCCESS, payload: response?.data})
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_FINISH})
  }
}

//Create TisaForex Rate
function* createTisaForexRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.postTisaForexRate, body)
    yield put({type: actionTypes.CREATE_TISA_FOREX_RATE_SUCCESS, payload: response.data})
    yield put({type: actionTypes.CREATE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.CREATE_TISA_FOREX_RATE_FINISH})
  }
}

//Update TisaForex Rate
function* updateTisaForexRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateTisaForexRate,
      body,
      action.payload?.id
    )
    yield put({type: actionTypes.UPDATE_TISA_FOREX_RATE_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_TISA_FOREX_RATE_FINISH})
  }
}

//Import TisaForex Rate File
function* importTisaForexRateSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importTisaForexRate, body)
    yield put(actions.importTisaForexRateSuccess(response.data?.data))
    yield put(actions.importTisaForexRateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importTisaForexRateFinish())
  }
}

//Delete TisaForex Rate
function* deleteTisaForexRateSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteTisaForexRate, body)
    yield put({type: actionTypes.DELETE_TISA_FOREX_RATE_SUCCESS, payload: response.data})
    yield put({type: actionTypes.DELETE_TISA_FOREX_RATE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_TISA_FOREX_RATE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_TISA_FOREX_RATE_START, getTisaForexRateSaga)
  yield takeLatest(actionTypes.CREATE_TISA_FOREX_RATE_START, createTisaForexRateSaga)
  yield takeLatest(actionTypes.UPDATE_TISA_FOREX_RATE_START, updateTisaForexRateSaga)
  yield takeLatest(actionTypes.DELETE_TISA_FOREX_RATE_START, deleteTisaForexRateSaga)
  yield takeLatest(actionTypes.GET_TISA_FOREX_RATE_FILE_START, getTisaForexRateFileSaga)
  yield takeLatest(actionTypes.ACTIVATE_TISA_FOREX_RATE_REQUEST, activateTisaForexRatesSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_TISA_FOREX_RATE_REQUEST, singleActivateTisaForexRate)
  yield takeLatest(actionTypes.DEACTIVATE_TISA_FOREX_RATE_REQUEST, deactivateTisaForexRate)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_TISA_FOREX_RATE_REQUEST, singleDeactivateTisaForex)
  yield takeLatest(actionTypes.IMPORT_TISA_FOREX_RATE_START, importTisaForexRateSaga)
}
