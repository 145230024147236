import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteServicePartnerModel, ServicePartnerModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_SERVICE_PARTNER = `${API_URL}/servicepartner/`
export const GET_SERVICE_PARTNER_STATUS = `${API_URL}/servicepartner`
export const POST_SERVICE_PARTNER = `${API_URL}/servicepartner`
export const GET_SAMPLE_SERVICE_PARTNER = `${API_URL}/servicepartner/sample-servicepartner-file`
export const GET_IMPORT_SERVICE_PARTNER = `${API_URL}/servicepartner/import`
export const GET_SERVICE_PARTNER_COUNTRY = `${API_URL}/servicepartner/country-list`
export const GET_SERVICE_PARTNER_CURRENCY = `${API_URL}/servicepartner/currency-list`

export const service = {
  getServicePartner: (params: ParamsModel) => {
    return axios.get(GET_SERVICE_PARTNER, {params})
  },
  addServicePartner: (data: any) => {
    return axios.post(POST_SERVICE_PARTNER, data)
  },

  updateServicePartner: (body: ServicePartnerModel, id: string) => {
    return axios.put(`${POST_SERVICE_PARTNER}/${id}`, body)
  },

  deleteServicePartner: (data: DeleteServicePartnerModel) => {
    return axios.delete(POST_SERVICE_PARTNER, {data})
  },

  enableServicePartner: (data: Array<string>) => {
    const selectedServicePartner = {
      servicePartnerId: data,
    }
    return axios.patch(`${GET_SERVICE_PARTNER_STATUS}/enable`, selectedServicePartner)
  },

  disableServicePartner: (data: Array<string>) => {
    const selectedServicePartner = {
      servicePartnerId: data,
    }
    return axios.patch(`${GET_SERVICE_PARTNER_STATUS}/disable`, selectedServicePartner)
  },

  singleEnableServicePartner: (data: Array<string>) => {
    const selectedServicePartner = {
      servicePartnerId: [data],
    }
    return axios.patch(`${GET_SERVICE_PARTNER_STATUS}/enable`, selectedServicePartner)
  },

  singleDisableServicePartner: (data: Array<string>) => {
    const selectedServicePartner = {
      servicePartnerId: [data],
    }
    return axios.patch(`${GET_SERVICE_PARTNER_STATUS}/disable`, selectedServicePartner)
  },

  downloadSample: (fileName: string) => {
    return axios.get(`${GET_SAMPLE_SERVICE_PARTNER}/${fileName}`).then((response) => {
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `service_partner.${fileName}`)
      document.body.appendChild(link)
      link.click()
    })
  },

  importServicePartner: (data: any) => {
    return axios.post(`${GET_IMPORT_SERVICE_PARTNER}`, data)
  },
  getServicePartnerCountryList: () => {
    return axios.get(`${GET_SERVICE_PARTNER_COUNTRY}`)
  },
  getServicePartnerCurrencyList: () => {
    return axios.get(`${GET_SERVICE_PARTNER_CURRENCY}`)
  },
}
