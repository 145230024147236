import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getEventSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getEvent, params)
    yield put(actions.getEventSuccess(response?.data?.data))
    yield put(actions.getEventFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getEventFinish())
  }
}

function* addEventSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addEvent, body)
    yield put(actions.addEventSuccess(response.data?.data))
    yield put(actions.addEventFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addEventFinish())
  }
}

function* updateEventSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateEvent, body, action.payload?.id)
    yield put(actions.updateEventSuccess(response.data?.data))
    yield put(actions.updateEventFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateEventFinish())
  }
}

function* deleteEventSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteEvent, body)
    yield put(actions.deleteEventSuccess(response.data?.data))
    yield put(actions.deleteEventFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteEventFinish())
  }
}

function* singleActivateEvent(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateEvent,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_EVENT_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_EVENT_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_EVENT_FINISH})
  }
}

function* singleDeactivateEvent(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateEvent,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_EVENT_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_EVENT_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_EVENT_FINISH})
  }
}

function* getBannerOptionSaga() {
  //GET Banner OPTION SAGA
  try {
    const response: ResponseModel = yield call(service.getBannerOption)
    yield put(actions.getBannerOptionSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getBannerOptionError(err))
  }
}

function* getAlbumOptionSaga() {
  //GET Album OPTION SAGA
  try {
    const response: ResponseModel = yield call(service.getAlbumOption)
    yield put(actions.getAlbumOptionSuccess(response?.data?.data))
  } catch (err: any) {
    yield put(actions.getAlbumOptionError(err))
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_EVENT_START, getEventSaga)
  yield takeLatest(actionTypes.ADD_EVENT_START, addEventSaga)
  yield takeLatest(actionTypes.UPDATE_EVENT_START, updateEventSaga)
  yield takeLatest(actionTypes.DELETE_EVENT_START, deleteEventSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_EVENT_REQUEST, singleActivateEvent)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_EVENT_REQUEST, singleDeactivateEvent)
  yield takeLatest(actionTypes.GET_EVENT_BANNER_OPTION_START, getBannerOptionSaga)
  yield takeLatest(actionTypes.GET_ALBUM_OPTION_START, getAlbumOptionSaga)
}
