export const actionTypes = {
  GET_ALL_BENEFIT_MASTER_START: 'GET_ALL_BENEFIT_MASTER_START',
  GET_ALL_BENEFIT_MASTER_SUCCESS: 'GET_ALL_BENEFIT_MASTER_SUCCESS',
  GET_ALL_BENEFIT_MASTER_FINISH: 'GET_ALL_BENEFIT_MASTER_FINISH',

  GET_SPECIFIC_BENEFIT_MASTER_START: 'GET_SPECIFIC_BENEFIT_MASTER_START',
  GET_SPECIFIC_BENEFIT_MASTER_SUCCESS: 'GET_SPECIFIC_BENEFIT_MASTER_SUCCESS',
  GET_SPECIFIC_BENEFIT_MASTER_FINISH: 'GET_SPECIFIC_BENEFIT_MASTER_FINISH',

  GET_BENEFIT_MASTER_START: 'GET_BENEFIT_MASTER_START',
  GET_BENEFIT_MASTER_SUCCESS: 'GET_BENEFIT_MASTER_SUCCESS',
  GET_BENEFIT_MASTER_FINISH: 'GET_BENEFIT_MASTER_FINISH',

  ADD_BENEFIT_MASTER_START: 'ADD_BENEFIT_MASTER_START',
  ADD_BENEFIT_MASTER_SUCCESS: 'ADD_BENEFIT_MASTER_SUCCESS',
  ADD_BENEFIT_MASTER_FINISH: 'ADD_BENEFIT_MASTER_FINISH',
  ADD_BENEFIT_MASTER_RESET: 'ADD_BENEFIT_MASTER_RESET',

  UPDATE_BENEFIT_MASTER_START: 'UPDATE_BENEFIT_MASTER_START',
  UPDATE_BENEFIT_MASTER_SUCCESS: 'UPDATE_BENEFIT_MASTER_SUCCESS',
  UPDATE_BENEFIT_MASTER_FINISH: 'UPDATE_BENEFIT_MASTER_FINISH',
  UPDATE_BENEFIT_MASTER_RESET: 'UPDATE_BENEFIT_MASTER_RESET',

  DELETE_BENEFIT_MASTER_START: 'DELETE_BENEFIT_MASTER_START',
  DELETE_BENEFIT_MASTER_SUCCESS: 'DELETE_BENEFIT_MASTER_SUCCESS',
  DELETE_BENEFIT_MASTER_FINISH: 'DELETE_BENEFIT_MASTER_FINISH',

  SINGLE_ACTIVATE_BENEFIT_MASTER_REQUEST: 'SINGLE_ACTIVATE_BENEFIT_MASTER_REQUEST',
  SINGLE_ACTIVATE_BENEFIT_MASTER_SUCCESS: 'SINGLE_ACTIVATE_BENEFIT_MASTER_SUCCESS',
  SINGLE_ACTIVATE_BENEFIT_MASTER_FINISH: 'SINGLE_ACTIVATE_BENEFIT_MASTER_FINISH',

  SINGLE_DEACTIVATE_BENEFIT_MASTER_REQUEST: 'SINGLE_DEACTIVATE_BENEFIT_MASTER_REQUEST',
  SINGLE_DEACTIVATE_BENEFIT_MASTER_SUCCESS: 'SINGLE_DEACTIVATE_BENEFIT_MASTER_SUCCESS',
  SINGLE_DEACTIVATE_BENEFIT_MASTER_FINISH: 'SINGLE_DEACTIVATE_BENEFIT_MASTER_FINISH',

  SORT_BENEFIT_MASTER_START: 'SORT_BENEFIT_MASTER_START',
  SORT_BENEFIT_MASTER_SUCCESS: 'SORT_BENEFIT_MASTER_SUCCESS',
  SORT_BENEFIT_MASTER_FINISH: 'SORT_BENEFIT_MASTER_FINISH',
  SORT_BENEFIT_MASTER_RESET: 'SORT_BENEFIT_MASTER_RESET',
}
