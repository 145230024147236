import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getLoanTenure: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_LOAN_TENURE_START,
    payload: params,
  }),

  getLoanTenureSuccess: (data: any) => ({
    type: actionTypes.GET_LOAN_TENURE_SUCCESS,
    payload: data,
  }),
  getLoanTenureFinish: () => ({
    type: actionTypes.GET_LOAN_TENURE_FINISH,
  }),
  getLoanTenureError: (error: unknown) => ({
    type: actionTypes.GET_LOAN_TENURE_FINISH,
    payload: {error},
  }),

  addLoanTenure: (data: any) => ({
    type: actionTypes.ADD_LOAN_TENURE_START,
    payload: data,
  }),
  addLoanTenureSuccess: (task: any) => ({
    type: actionTypes.ADD_LOAN_TENURE_SUCCESS,
    payload: task,
  }),
  addLoanTenureFinish: () => ({
    type: actionTypes.ADD_LOAN_TENURE_FINISH,
  }),
  addLoanTenureReset: () => ({
    type: actionTypes.ADD_LOAN_TENURE_RESET,
  }),

  updateLoanTenure: (data: any, id: string) => ({
    type: actionTypes.UPDATE_LOAN_TENURE_START,
    payload: {data, id},
  }),
  updateLoanTenureSuccess: (task: any) => ({
    type: actionTypes.UPDATE_LOAN_TENURE_SUCCESS,
    payload: task,
  }),
  updateLoanTenureFinish: () => ({
    type: actionTypes.UPDATE_LOAN_TENURE_FINISH,
  }),
  updateLoanTenureReset: () => ({
    type: actionTypes.UPDATE_LOAN_TENURE_RESET,
  }),

  deleteLoanTenure: (data: any) => ({
    type: actionTypes.DELETE_LOAN_TENURE_START,
    payload: {loanTenureId: data},
  }),
  deleteLoanTenureSuccess: (data: any) => ({
    type: actionTypes.DELETE_LOAN_TENURE_SUCCESS,
    payload: data,
  }),
  deleteLoanTenureFinish: () => ({
    type: actionTypes.DELETE_LOAN_TENURE_FINISH,
  }),
  activateLoanTenure: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_REQUEST,
    payload: {loanTenureId: id},
  }),
  deactivateLoanTenure: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_REQUEST,
    payload: {loanTenureId: id},
  }),
  singleActivateLoanTenure: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_REQUEST,
    payload: {loanTenureId: [id]},
  }),
  singleDeactivateLoanTenure: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_REQUEST,
    payload: {loanTenureId: [id]},
  }),

  sortLoanTenure: (data: any) => ({
    type: actionTypes.SORT_LOAN_TENURE_START,
    payload: data,
  }),
  sortLoanTenureSuccess: (data: any) => ({
    type: actionTypes.SORT_LOAN_TENURE_SUCCESS,
    payload: data,
  }),
  sortLoanTenureFinish: () => ({
    type: actionTypes.SORT_LOAN_TENURE_FINISH,
  }),
  sortLoanTenureReset: () => ({
    type: actionTypes.SORT_LOAN_TENURE_RESET,
  }),
}
