import {ParamsModel} from 'src/app/modules/common/Model'
import {IExportFileState} from 'src/app/modules/network/components/branchless'
import {DeleteGrievanceModal} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  // get ServiceType DATA
  getSubscribe: (params: ParamsModel | {status: number} = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_SUBSCRIBE_START,
    payload: params,
  }),
  getSubscribeSuccess: (data: any) => ({
    type: actionTypes.GET_SUBSCRIBE_SUCCESS,
    payload: data,
  }),
  getSubscribeFinish: () => ({
    type: actionTypes.GET_SUBSCRIBE_FINISH,
  }),

  // delete key
  deleteSubscribe: (data: DeleteGrievanceModal[]) => ({
    type: actionTypes.DELETE_SUBSCRIBE_START,
    payload: {subscribeId: data},
  }),
  deleteSubscribeSuccess: (data: any) => ({
    type: actionTypes.DELETE_SUBSCRIBE_SUCCESS,
    payload: data,
  }),
  deleteSubscribeFinish: () => ({
    type: actionTypes.DELETE_SUBSCRIBE_FINISH,
  }),

  //Export file csv
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_SUBSCRIBE_FILE_START,
      payload: {fileName, params},
    }
  },

  exportFileSuccess: (data: IExportFileState) => ({
    type: actionTypes.EXPORT_SUBSCRIBE_FILE_SUCCESS,
    payload: data,
  }),
  exportFileError: () => ({
    type: actionTypes.EXPORT_SUBSCRIBE_FILE_FINISH,
  }),
}
