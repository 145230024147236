import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as shareRedux from 'src/app/modules/stock/components/share/redux'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const AddShare = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, editSuccess} = useSelector((state: any) => state.share)

  const FORM_VALIDATION = {
    maxPrice: Yup.string().required('Maximum price is required'),
    minPrice: Yup.string().required('Minimum price is required'),
    closingPrice: Yup.string().required('Closing price is required'),
    lastDayValue: Yup.string().required('Previous Closing price is required'),
    status: Yup.string().required('Status is required'),
  }

  useEffect(() => {
    if (editSuccess) {
      dispatch(shareRedux?.actions.getStockRate(params))
      isEmpty(editSelectedData)
        ? toast.success('Stock added successfully')
        : toast.success('Stock edited successfully')
      dispatch(shareRedux?.actions.createStockRateReset())
      handleClose()
    }
  }, [editSuccess])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Stock </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                maxPrice: '',
                minPrice: '',
                closingPrice: '',
                turnover: '',
                lastDayValue: '',
                status: '',
              }}
              // validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(shareRedux.actions.updateStockRate(formData, editSelectedData?.id))
                } else {
                  dispatch(shareRedux.actions.CreateStockRate(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'maxPrice',
                      'minPrice',
                      'closingPrice',
                      'turnover',
                      'lastDayValue',
                      'status',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])
                return (
                  // <Form>
                  //   <div className='card-body border-top pt-5'>
                  //     <div className='row'>
                  //       <div className='col-6'>
                  //         <FormTextBox
                  //           type='text'
                  //           placeholder='Enter Maximum Price'
                  //           name='maxPrice'
                  //           label='Max Price'
                  //           onChange={handleChange}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={true}
                  //         />
                  //       </div>

                  //       <div className='col-6'>
                  //         <FormTextBox
                  //           type='text'
                  //           placeholder='Minimum Price'
                  //           name='minPrice'
                  //           label='Minimum Price'
                  //           onChange={handleChange}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={true}
                  //         />
                  //       </div>
                  //     </div>
                  //     <div className='row justify-content-between'>
                  //       <div className='col-6'>
                  //         <FormTextBox
                  //           type='text'
                  //           placeholder='Closing Price'
                  //           name='closingPrice'
                  //           label='Closing Price'
                  //           onChange={handleChange}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={true}
                  //         />
                  //       </div>
                  //       <div className='col-6'>
                  //         <FormTextBox
                  //           type='text'
                  //           placeholder='Enter Turn Over'
                  //           name='turnover'
                  //           label='Turn Over'
                  //           onChange={handleChange}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={true}
                  //         />
                  //       </div>
                  //     </div>

                  //     <div className='row justify-content-between'>
                  //       <div className='col-6'>
                  //         <FormTextBox
                  //           type='text'
                  //           placeholder='Enter Previous Closing'
                  //           name='lastDayValue'
                  //           label='Previous Closing'
                  //           onChange={handleChange}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={true}
                  //         />
                  //       </div>
                  //       {/* <div className='col-6'>
                  //         <FormDatepicker
                  //           containerClassName='col-lg-6 '
                  //           label='Date'
                  //           name='date'
                  //           placeholderText='Please select a date'
                  //           setFieldValue={setFieldValue}
                  //           value={values.date || false}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={false}
                  //           showIcon={true}
                  //         />
                  //       </div> */}
                  //     </div>

                  //     <div className='row justify-content-between'>
                  //      {/*  <div className='col-6'>
                  //         <FormDatepicker
                  //           containerClassName='col-lg-6 '
                  //           label='Time'
                  //           name='time'
                  //            dateFormat='hh:mm aa'
                  //           showTimeSelect={true}
                  //           showTimeSelectOnly={true}
                  //           placeholderText='Please select a time'
                  //           setFieldValue={setFieldValue}
                  //           value={values.time || false}
                  //           errors={errors}
                  //           touched={touched}
                  //           required={false}
                  //           showIcon={true}
                  //         />
                  //       </div> */}
                  //       <div className='col-lg-6'>
                  //         <label className='form-label fw-bolder text-dark fs-6 required'>
                  //           Status
                  //         </label>

                  //         <div className='d-flex ms-5'>
                  //           {statusOptions?.map((status: {label: string; value: string}) => (
                  //             <FormRadio
                  //               containerClassName=''
                  //               label='Select Status'
                  //               name='status'
                  //               type='radio'
                  //               onChange={handleChange}
                  //               errors={errors}
                  //               touched={touched}
                  //               checkBoxText={status?.label}
                  //               value={status?.value}
                  //               required={true}
                  //             />
                  //           ))}
                  //         </div>
                  //         <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                  //           <ErrorMessage
                  //             name='status'
                  //             component='div'
                  //             className='field-error-message'
                  //           />
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <div className='d-flex justify-content-end px-9 '>
                  //     <button
                  //       type='submit'
                  //       disabled={loading}
                  //       className='btn btn-primary btn-sm ms-3'
                  //     >
                  //       Save
                  //     </button>
                  //     <button
                  //       type='button'
                  //       onClick={handleClose}
                  //       className='btn btn-secondary btn-sm ms-3'
                  //     >
                  //       Cancel
                  //     </button>
                  //   </div>
                  // </Form>
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Maximum Price'
                            name='maxPrice'
                            label='Max Price'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Minimum Price'
                            name='minPrice'
                            label='Minimum Price'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Closing Price'
                            name='closingPrice'
                            label='Closing Price'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Turn Over'
                            name='turnover'
                            label='Turn Over'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className='row justify-content-between '>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Previous Closing'
                            name='lastDayValue'
                            label='Previous Closing'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                      </div>

                      <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>

                      <div className='d-flex ms-5'>
                        {statusOptions?.map((status: {label: string; value: string}) => (
                          <FormRadio
                            containerClassName=''
                            label='Select Status'
                            name='status'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            checkBoxText={status?.label}
                            value={status?.value}
                            required={true}
                          />
                        ))}
                      </div>
                      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                        <ErrorMessage
                          name='status'
                          component='div'
                          className='field-error-message'
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddShare
