import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getLoanPurpose: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_LOAN_PURPOSE_START,
    payload: params,
  }),

  getLoanPurposeSuccess: (data: any) => ({
    type: actionTypes.GET_LOAN_PURPOSE_SUCCESS,
    payload: data,
  }),
  getLoanPurposeFinish: () => ({
    type: actionTypes.GET_LOAN_PURPOSE_FINISH,
  }),
  getLoanPurposeError: (error: unknown) => ({
    type: actionTypes.GET_LOAN_PURPOSE_FINISH,
    payload: {error},
  }),

  addLoanPurpose: (data: any) => ({
    type: actionTypes.ADD_LOAN_PURPOSE_START,
    payload: data,
  }),
  addLoanPurposeSuccess: (task: any) => ({
    type: actionTypes.ADD_LOAN_PURPOSE_SUCCESS,
    payload: task,
  }),
  addLoanPurposeFinish: () => ({
    type: actionTypes.ADD_LOAN_PURPOSE_FINISH,
  }),
  addLoanPurposeReset: () => ({
    type: actionTypes.ADD_LOAN_PURPOSE_RESET,
  }),

  updateLoanPurpose: (data: any, id: string) => ({
    type: actionTypes.UPDATE_LOAN_PURPOSE_START,
    payload: {data, id},
  }),
  updateLoanPurposeSuccess: (task: any) => ({
    type: actionTypes.UPDATE_LOAN_PURPOSE_SUCCESS,
    payload: task,
  }),
  updateLoanPurposeFinish: () => ({
    type: actionTypes.UPDATE_LOAN_PURPOSE_FINISH,
  }),
  updateLoanPurposeReset: () => ({
    type: actionTypes.UPDATE_LOAN_PURPOSE_RESET,
  }),

  deleteLoanPurpose: (data: any) => ({
    type: actionTypes.DELETE_LOAN_PURPOSE_START,
    payload: {loanPurposeId: data},
  }),
  deleteLoanPurposeSuccess: (data: any) => ({
    type: actionTypes.DELETE_LOAN_PURPOSE_SUCCESS,
    payload: data,
  }),
  deleteLoanPurposeFinish: () => ({
    type: actionTypes.DELETE_LOAN_PURPOSE_FINISH,
  }),
  activateLoanPurpose: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_LOAN_PURPOSE_REQUEST,
    payload: {loanPurposeId: id},
  }),
  deactivateLoanPurpose: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_LOAN_PURPOSE_REQUEST,
    payload: {loanPurposeId: id},
  }),
  singleActivateLoanPurpose: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_LOAN_PURPOSE_REQUEST,
    payload: {loanPurposeId: [id]},
  }),
  singleDeactivateLoanPurpose: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_LOAN_PURPOSE_REQUEST,
    payload: {loanPurposeId: [id]},
  }),

  sortLoanPurpose: (data: any) => ({
    type: actionTypes.SORT_LOAN_PURPOSE_START,
    payload: data,
  }),
  sortLoanPurposeSuccess: (data: any) => ({
    type: actionTypes.SORT_LOAN_PURPOSE_SUCCESS,
    payload: data,
  }),
  sortLoanPurposeFinish: () => ({
    type: actionTypes.SORT_LOAN_PURPOSE_FINISH,
  }),
  sortLoanPurposeReset: () => ({
    type: actionTypes.SORT_LOAN_PURPOSE_RESET,
  }),
}
