import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BENEFIT_MASTER = `${API_URL}/benefit-master`

export const service = {
  getAllBenefitMaster: (id: string) => {
    return axios.get(`${BENEFIT_MASTER}/list`)
  },
  getSpecificBenefitMaster: (id: string) => {
    return axios.get(`${BENEFIT_MASTER}/list/${id}`)
  },
  getBenefitMaster: (params: ParamsModel) => {
    return axios.get(BENEFIT_MASTER, {params})
  },

  addBenefitMaster: (data: any) => {
    return axios.post(BENEFIT_MASTER, data)
  },

  updateBenefitMaster: (body: any, id: string) => {
    return axios.patch(`${BENEFIT_MASTER}/${id}`, body)
  },

  deleteBenefitMaster: (data: any) => {
    return axios.delete(BENEFIT_MASTER, {data})
  },
  singleActivateBenefitMaster: (id: any) => {
    return axios.patch(`${BENEFIT_MASTER}/enable`, id)
  },

  singleDeactivateBenefitMaster: (id: any) => {
    return axios.patch(`${BENEFIT_MASTER}/disable`, id)
  },

  sortTutorialBenefitMaster: (body: any) => {
    return axios.patch(`${BENEFIT_MASTER}/sort`, body)
  },
}
