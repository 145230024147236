import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { ErrorMessage, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import * as collegeDetailRedux from '../index'
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import { StateParamsModel } from 'src/app/modules/common/Model'

import Modal from 'rsuite/Modal'

import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'

import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import { OptionModel } from '../Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import { imageBaseUrl } from 'src/cms/helpers/constants'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  second_title: Yup.string().required('Second Title is required'),
})

const AddCollegeDetail = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const [imagePath, setImagePath] = useState<any>('')
  const [imageUrl, setImageUrl] = useState('')
  const [secondImageUrl, setSecondImageUrl] = useState('')

  const { loading, success, aboutTypeOption } = useSelector((state: any) => state.collegeDetail)
  const { videoOption } = useSelector((state: any) => state.banner)

  const aboutTypeOptions = aboutTypeOption?.data?.typeOption?.map((items: OptionModel) => ({
    label: items?.displayName,
    value: items?.id,
    key: items?.systemName,
  }))

  const videoOptions = videoOption?.data?.videoSourceOption?.map((items: OptionModel) => ({
    label: items?.displayName,
    value: items?.id,
    key: items?.systemName,
    isReset: true,
    resetValue: 'video',
  }))

  const secondVideoOptions = videoOption?.data?.videoSourceOption?.map((items: OptionModel) => ({
    label: items?.displayName,
    value: items?.id,
    key: items?.systemName,
    isReset: true,
    resetValue: 'second_video',
  }))

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  useEffect(() => {
    if (success) {
      dispatch(collegeDetailRedux?.actions.getCollegeDetail(params))
      isEmpty(editSelectedData)
        ? toast.success('College detail added successfully')
        : toast.success('College detail edited successfully')
      dispatch(collegeDetailRedux?.actions?.resetCollegeDetail())
      handleClose()
    }
  }, [success])

  useEffect(() => {
    dispatch(collegeDetailRedux?.actions?.getAboutTypeOption())
    dispatch(bannerRedux.actions.getVideoOption())
  }, [])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} College detail </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                slug: '',
                description: '',
                typeId: '',
                image: '',
                video: '',
                videoSourceId: '',
                first_button_text: '',
                first_button_url: '',

                second_title: '',
                second_subtitle: '',
                second_description: '',
                second_typeId: '',
                second_image: '',
                second_video: '',
                second_videoSourceId: '',
                second_button_text: '',
                second_button_url: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  title: !isEmpty(editSelectedData) ? editSelectedData?.name : values?.name,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.name.replace(/\s/g, '-').toLowerCase(),
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    collegeDetailRedux.actions.updateCollegeDetail(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(collegeDetailRedux.actions.addCollegeDetail(formData))
                }
              }}
            >
              {({ touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'slug',
                      'description',
                      'typeId',
                      'image',
                      'video',
                      'videoSourceId',
                      'first_button_text',
                      'first_button_url',

                      'second_title',
                      'second_subtitle',
                      'second_description',
                      'second_typeId',
                      'second_image',
                      'second_video',
                      'second_videoSourceId',
                      'second_button_text',
                      'second_button_url',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Title'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : values?.name.replace(/\s/g, '-').toLowerCase()
                            }
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description'
                            name='description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select media type'
                            label='Media Type'
                            name='typeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={aboutTypeOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        {!isEmpty(values?.typeId) ? (
                          values?.typeId === '09fd6e7e-9c9d-461e-b2ce-00b6312dd98e' ? (
                            <>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Image'
                                  name='image'
                                  setFieldValue={setFieldValue}
                                  setImageUrl={setImageUrl}
                                  value={values?.image}
                                />

                                {!isEmpty(values?.image) ? (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            setImageUrl('')
                                            setFieldValue('image', '')
                                          }}
                                        >
                                          Delete
                                        </button>

                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${values?.image}`}
                                          alt=''
                                        />
                                      </div>
                                    </li>
                                  </>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Please select video source type'
                                  label='Video Source'
                                  name='videoSourceId'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={videoOptions}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Video'
                                  as='textarea'
                                  name='video'
                                  label='Video'
                                  containerClassName=''
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </>
                          )
                        ) : null}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Button Text'
                            label='Button Text'
                            name='first_button_text'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Button URL'
                            label='Button URL'
                            name='first_button_url'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <hr />
                      <h3>Second Section</h3>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Title'
                            name='second_title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Sub Title'
                            label='Sub Title'
                            name='second_subtitle'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description'
                            name='second_description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.second_description : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select media type'
                            label='Media Type'
                            name='second_typeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={aboutTypeOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        {!isEmpty(values?.second_typeId) ? (
                          values?.second_typeId === '09fd6e7e-9c9d-461e-b2ce-00b6312dd98e' ? (
                            <>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Image'
                                  name='second_image'
                                  setFieldValue={setFieldValue}
                                  setImageUrl={setImageUrl}
                                  value={values?.second_image}
                                />

                                {!isEmpty(values?.second_image) ? (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            setSecondImageUrl('')
                                            setFieldValue('second_image', '')
                                          }}
                                        >
                                          Delete
                                        </button>

                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${values?.second_image}`}
                                          alt=''
                                        />
                                      </div>
                                    </li>
                                  </>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Please select video source type'
                                  label='Video Source'
                                  name='second_videoSourceId'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={secondVideoOptions}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Video'
                                  as='textarea'
                                  name='second_video'
                                  label='Video'
                                  containerClassName=''
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </>
                          )
                        ) : null}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Button Text'
                            label='Button Text'
                            name='second_button_text'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Button URL'
                            label='Button URL'
                            name='second_button_url'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddCollegeDetail
