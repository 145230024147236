import {ErrorMessage, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
// branch
import {isEmpty} from 'lodash'
import * as categoryType from 'src/app/modules/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as cmsCategories from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddCategory = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const {loading, success} = useSelector((state: any) => state.categories)
  const {data: categoryTypeData} = useSelector((state: any) => state.categoryType)
  const [imagePath, setImagePath] = useState<any>('')

  useEffect(() => {
    dispatch(categoryType.action.getCategoryType())
  }, [])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const categoryTypeOptions = categoryTypeData?.categoryType?.map((items: any) => ({
    label: items.display_name,
    value: items.id,
  }))

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  const settingType: any = settingTypeData?.data?.setting
    ?.filter((item: any) => item.name === 'categoryImg')
    .map((item: any) => item)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(cmsCategories?.actions.getCmsCategories(params))
      isEmpty(editSelectedData)
        ? toast.success('Category added successfully')
        : toast.success('Category edited successfully')
      dispatch(cmsCategories?.actions?.addCmsCategoriesReset())
      handleClose()
    }
  }, [success])

  function convertToSlug(text: any) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }
  const multiLanguage = checkMultiLanguage()

  const isHideNameNp = checkBackendSetting('hideNameNp')
  const isCategoryTypeRequired = checkBackendSetting('isCategoryTypeRequired')
  const isNameRequired = checkBackendSetting('isNameRequired')

  const FORM_VALIDATION = Yup.object().shape({
    categoryTypeId: Yup.string().when('isCategoryTypeRequired', {
      is: (value: any) => !value && isCategoryTypeRequired,
      then: Yup.string().required('Category type is required'),
      otherwise: Yup.string(),
    }),
    name: Yup.string().when('isNameRequired', {
      is: (value: any) => !value && isNameRequired,
      then: Yup.string().required('Name is required'),
      otherwise: Yup.string(),
    }),
    status: Yup.string().required('Status is required'),
  })

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Category </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                name_np: '',
                slug: '',
                order: '',
                categoryTypeId: '',
                status: '',
                image: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.name),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    cmsCategories.actions.updateCmsCategories(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(cmsCategories.actions.addCmsCategories(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'name_np',
                      'categoryTypeId',
                      'order',
                      'slug',
                      'type',
                      'typeId',
                      'image',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select category type'
                            label='Category Type'
                            name='categoryTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryTypeOptions}
                            required={isCategoryTypeRequired ? true : false}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder={multiLanguage ? 'Name' : 'Name (EN)'}
                            label={multiLanguage ? 'Name' : 'Name (EN)'}
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={isNameRequired ? true : false}
                          />
                        </div>
                        {!isHideNameNp && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTextBox
                              type='text'
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              placeholder='Name (NP)'
                              label='Name (NP)'
                              name='name_np'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : convertToSlug(values?.name)
                            }
                          />
                        </div>

                        {settingType[0]?.value === '1' && (
                          <div className='col-6'>
                            <FormInputMediaManager
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label=' Image '
                              name='image'
                              setFieldValue={setFieldValue}
                              setImageUrl={setImagePath}
                              value={values?.image}
                              required={true}
                            />

                            {!isEmpty(values?.image) ? (
                              <>
                                <li className='listing'>
                                  <div className='thumbImageBlock'>
                                    <button
                                      type='button'
                                      title='Remove'
                                      className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                      onClick={() => {
                                        setImagePath('')
                                        setFieldValue('image', '')
                                      }}
                                      data-cy='modal-thumbnail-remove'
                                    >
                                      X
                                    </button>

                                    <img
                                      className='thumbImage w-100 h-100'
                                      src={`${imageBaseUrl}/${values?.image}`}
                                      alt=''
                                    />
                                  </div>
                                </li>
                              </>
                            ) : null}
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddCategory
