import {call, delay, put, takeLatest} from 'redux-saga/effects'
import {ParamsModel} from 'src/app/modules/common/Model'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {SortAuctionTypeModel, SortSearchActionModel} from '../Model/Model'
import {actions} from './action'
import {actionTypes} from './constants'
import {service} from './service'

function* getAuctionTypeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getAuctionType, params)
    yield put(actions.getAuctionTypeSuccess(response?.data?.data))
    yield put(actions.getAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getAuctionTypeFinish())
  }
}

function* getAllAuctionTypeSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllAuctionType)
    yield put(actions.getAllAuctionTypeSuccess(response?.data?.data))
    yield put(actions.getAllAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getAllAuctionTypeFinish())
  }
}

function* addAuctionTypeSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addAuctionType, body)
    yield put(actions.addAuctionTypeSuccess(response.data?.data))
    yield put(actions.addAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.addAuctionTypeFinish())
  }
}

function* enableAuctionTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableAuctionType, selectedUsers)
    yield put(actions.enableAuctionTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.enableAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.enableAuctionTypeFinish())
  }
}

function* disableAuctionTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableAuctionType, selectedUsers)
    yield put(actions.disableAuctionTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.disableAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.enableAuctionTypeFinish())
  }
}

function* singleEnableAuctionTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableAuctionType, selectedUsers)
    yield put(actions.singleEnableAuctionTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleEnableAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.singleEnableAuctionTypeFinish())
  }
}

function* singleDisableAuctionTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableAuctionType, selectedUsers)
    yield put(actions.singleDisableAuctionTypeSuccess(response?.data))
    yield delay(100)
    yield put(actions.singleDisableAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.singleDisableAuctionTypeFinish())
  }
}

function* updateAuctionTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    // const {id, ...newPayload} = body
    const response: ResponseModel = yield call(service.updateAuctionType, body, action.payload?.id)
    yield put(actions.updateAuctionTypeSuccess(response.data?.data))
    yield put(actions.updateAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.updateAuctionTypeFinish())
  }
}

function* deleteAuctionTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAuctionType, body)
    yield put(actions.deleteAuctionTypeSuccess(response.data?.data))
    yield delay(0.1)
    yield put(actions.deleteAuctionTypeFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.deleteAuctionTypeFinish())
  }
}

function* sortAuctionType(action: SortSearchActionModel) {
  try {
    const body: SortAuctionTypeModel = action.payload
    const response: ResponseModel = yield call(service.sortAuctionType, body)

    yield put({
      type: actionTypes.SORT_AUCTION_TYPE_SUCCESS,
      payload: response?.data?.data,
    })
    yield delay(100)
    yield put({type: actionTypes.SORT_AUCTION_TYPE_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put({type: actionTypes.SORT_AUCTION_TYPE_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_AUCTION_TYPE_START, getAuctionTypeSaga)
  yield takeLatest(actionTypes.GET_ALL_AUCTION_TYPE_START, getAllAuctionTypeSaga)
  yield takeLatest(actionTypes.ADD_AUCTION_TYPE_START, addAuctionTypeSaga)
  yield takeLatest(actionTypes.UPDATE_AUCTION_TYPE_START, updateAuctionTypeSaga)
  yield takeLatest(actionTypes.DELETE_AUCTION_TYPE_START, deleteAuctionTypeSaga)
  yield takeLatest(actionTypes.ENABLE_AUCTION_TYPE_REQUEST, enableAuctionTypeSaga)
  yield takeLatest(actionTypes.DISABLE_AUCTION_TYPE_REQUEST, disableAuctionTypeSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_AUCTION_TYPE_REQUEST, singleEnableAuctionTypeSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_AUCTION_TYPE_REQUEST, singleDisableAuctionTypeSaga)
  yield takeLatest(actionTypes.SORT_AUCTION_TYPE_START, sortAuctionType)
}
