import {Action} from 'redux'
import {OptionModel} from '../Model'
import {EventsModel} from '../Model/EventsModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialEventState: IEventState = {
  data: {
    events: [],
    meta: {},
  },
  bannerOption: [],
  albumOption: [],
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface IEventState {
  data?: {
    events?: EventsModel[]
    meta?: {[key: string]: number}
  }
  bannerOption?: OptionModel[]
  albumOption?: OptionModel[]
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IEventState = initialEventState,
  action: ActionWithPayload<IEventState>
) => {
  switch (action.type) {
    //GET NEWS DATA
    case actionTypes.GET_EVENT_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_EVENT_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_EVENT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add SUB CATEGORY

    case actionTypes.ADD_EVENT_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_EVENT_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_EVENT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_EVENT_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update news
    case actionTypes.UPDATE_EVENT_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_EVENT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_EVENT_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_EVENT_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Singer activate branchless banking
    //Activate Users
    case actionTypes.SINGLE_ACTIVATE_EVENT_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_EVENT_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_EVENT_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_EVENT_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_EVENT_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_EVENT_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    //GET Banner OPTION REDUCER
    case actionTypes.GET_EVENT_BANNER_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_EVENT_BANNER_OPTION_SUCCESS: {
      return {
        ...state,
        bannerOption: action.payload?.bannerOption,
        loading: false,
      }
    }

    case actionTypes.GET_EVENT_BANNER_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    //GET Album OPTION REDUCER
    case actionTypes.GET_ALBUM_OPTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALBUM_OPTION_SUCCESS: {
      return {
        ...state,
        albumOption: action.payload?.albumOption,
        loading: false,
      }
    }

    case actionTypes.GET_ALBUM_OPTION_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    default:
      return state
  }
}
