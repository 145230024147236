import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const ExperienceManager = `${API_URL}/experience-manager`

export const service = {
  getExperienceManager: (params: ParamsModel) => {
    return axios.get(ExperienceManager, {params})
  },

  addExperienceManager: (data: any) => {
    return axios.post(ExperienceManager, data)
  },

  updateExperienceManager: (body: any, id: string) => {
    return axios.put(`${ExperienceManager}/${id}`, body)
  },

  deleteExperienceManager: (data: string) => {
    return axios.delete(`${ExperienceManager}/bulk-delete`, {data})
  },
  singleActivateExperienceManager: (id: any) => {
    return axios.patch(`${ExperienceManager}/enable`, id)
  },

  singleDeactivateExperienceManager: (id: any) => {
    return axios.patch(`${ExperienceManager}/disable`, id)
  },

  sortExperienceManager: (body: any) => {
    return axios.patch(`${ExperienceManager}/sort`, body)
  },
  importExperienceManager: (data: any) => {
    return axios.post(`${ExperienceManager}/import`, data)
  },
}
