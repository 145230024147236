import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteFormDownloadModel, SortFormDownloadModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CERTIFICATE_DATA = `${API_URL}/certificate`
export const GET_CERTIFICATE_LIST = `${API_URL}/certificate-category/list`

export const service = {
  getCertificate: (params: ParamsModel) => {
    return axios.get(CERTIFICATE_DATA, {params})
  },
  getAllCertificate: () => {
    return axios.get(`${CERTIFICATE_DATA}/list`)
  },

  addCertificate: (data: any) => {
    return axios.post(CERTIFICATE_DATA, data)
  },

  updateCertificate: (body: any, id: string) => {
    return axios.put(`${CERTIFICATE_DATA}/${id}`, body)
  },

  deleteCertificate: (data: DeleteFormDownloadModel) => {
    return axios.delete(`${CERTIFICATE_DATA}/bulk-delete`, {data})
  },

  enableCertificate: (data: Array<string>) => {
    const selectedFormDownload = {
      certificateId: data,
    }
    return axios.patch(`${CERTIFICATE_DATA}/enable`, selectedFormDownload)
  },

  disableCertificate: (data: Array<string>) => {
    const selectedFormDownload = {
      certificateId: data,
    }
    return axios.patch(`${CERTIFICATE_DATA}/disable`, selectedFormDownload)
  },
  getCertificateListSaga: () => {
    return axios.get(GET_CERTIFICATE_LIST)
  },

  singleEnableCertificate: (data: Array<string>) => {
    const selectedFormDownload = {
      certificateId: [data],
    }
    return axios.patch(`${CERTIFICATE_DATA}/enable`, selectedFormDownload)
  },

  singleDisableCertificate: (data: Array<string>) => {
    const selectedFormDownload = {
      certificateId: [data],
    }
    return axios.patch(`${CERTIFICATE_DATA}/disable`, selectedFormDownload)
  },

  sortCertificate: (body: SortFormDownloadModel) => {
    return axios.patch(`${CERTIFICATE_DATA}/sort`, body)
  },
}
