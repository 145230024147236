export const actionTypes = {
  // get
  GET_GROUP_SETTING_START: 'GET_GROUP_SETTING_START',
  GET_GROUP_SETTING_SUCCESS: 'GET_GROUP_SETTING_SUCCESS',
  GET_GROUP_SETTING_FINISH: 'GET_GROUP_SETTING_FINISH',
  // get
  GET_SETTING_GROUP_LIST_START: 'GET_SETTING_GROUP_LIST_START',
  GET_SETTING_GROUP_LIST_SUCCESS: 'GET_SETTING_GROUP_LIST_SUCCESS',
  GET_SETTING_GROUP_LIST_FINISH: 'GET_SETTING_GROUP_LIST_FINISH',
  // create
  CREATE_GROUP_START: 'CREATE_GROUP_START',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FINISH: 'CREATE_GROUP_FINISH',
  CREATE_GROUP_FAILURE: 'CREATE_GROUP_FAILURE',
  CREATE_GROUP_RESET: 'CREATE_GROUP_RESET',
  // update
  UPDATE_GROUP_START: 'UPDATE_GROUP_START',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FINISH: 'UPDATE_GROUP_FINISH',
  UPDATE_GROUP_RESET: 'UPDATE_GROUP_RESET',

  // delete
  DELETE_GROUP_START: 'DELETE_GROUP_START',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FINISH: 'DELETE_GROUP_FINISH',
}
