import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {IAppointmentStatusState} from './reducer'

export const actions = {
  // get AppointmentRequest
  getAppointmentStatus: (params: ParamsModel = {page: 1, limit: 10}, id: string = '') => {
    return {
      type: actionTypes.GET_APPOINTMENT_STATUS_START,
      payload: {params, id},
    }
  },
  getAppointmentStatusSuccess: (data: IAppointmentStatusState) => ({
    type: actionTypes.GET_APPOINTMENT_STATUS_SUCCESS,
    payload: data,
  }),
  getAppointmentStatusError: (data: IAppointmentStatusState) => ({
    type: actionTypes.GET_APPOINTMENT_STATUS_FINISH,
    payload: data,
  }),
}
