import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteTisaForexRateModel, TisaForexRateModel} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_TISA_FOREX_RATE = `${API_URL}/tisa-forex-rate`
export const POST_TISA_FOREX_RATE = `${API_URL}/tisa-forex-rate`
export const ACTIVATE_TISA_FOREX_RATES = `${API_URL}/tisa-forex-rate/enable`
export const DEACTIVATE_TISA_FOREX_RATE = `${API_URL}/tisa-forex-rate/disable`
export const ACTIVATE_TISA_FOREX_RATE = `${API_URL}/tisa-forex-rate`
export const DELETE_TISA_FOREX_RATE = `${API_URL}/tisa-forex-rate/destroy`
export const GET_TISA_FOREX_RATE_FILE = `${API_URL}/tisa-forex-rate/get-sample`
export const IMPORT_TISA_FOREX_RATE = `${API_URL}/tisa-forex-rate/store-from-file`

export const service = {
  getTisaForexRate: (params: ParamsModel) => {
    return axios.get(GET_TISA_FOREX_RATE, {params})
  },

  //Get file
  getTisaForexRateFile: (fileName: string) => {
    return axios.get(`${GET_TISA_FOREX_RATE_FILE}/${fileName}`).then((response) => {
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `TisaForex_rate.${fileName}`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  //Activate Deactivate
  activateTisaForexRate: (data: Array<string>) => {
    const formData = {
      forexRateId: data,
    }
    return axios.patch(ACTIVATE_TISA_FOREX_RATES, formData)
  },

  //Activatae Deactivate post
  singleActivateTisaForexRate: (id: string) => {
    return axios.patch(`${ACTIVATE_TISA_FOREX_RATE}/${id}/enable`)
  },

  singleDeactivateTisaForexRate: (id: string) => {
    return axios.patch(`${ACTIVATE_TISA_FOREX_RATE}/${id}/disable`)
  },

  deactivateTisaForexRate: (data: Array<string>) => {
    const formData = {
      forexRateId: data,
    }
    return axios.patch(DEACTIVATE_TISA_FOREX_RATE, formData)
  },

  //Import File from Pc
  importTisaForexRate: (data: any) => {
    return axios.post(IMPORT_TISA_FOREX_RATE, data)
  },

  //post forex rate
  postTisaForexRate: (body: TisaForexRateModel) => {
    return axios.post(POST_TISA_FOREX_RATE, body)
  },

  updateTisaForexRate: (body: TisaForexRateModel, id: string) => {
    return axios.patch(`${POST_TISA_FOREX_RATE}/${id}`, body)
  },

  deleteTisaForexRate: (data: DeleteTisaForexRateModel) => {
    return axios.delete(DELETE_TISA_FOREX_RATE, {data})
  },
}
