import Edit2 from '@rsuite/icons/Edit'
import RemindOutlineIcon from '@rsuite/icons/RemindOutline'
import TrashIcon from '@rsuite/icons/Trash'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

//Manual import
import {Modal} from 'rsuite'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as servicePartnerRedux from '../../servicePartnerManagement'
import {IServicePartnerState} from '../redux/reducer'
import AddServicePartner from './AddServicePartner'

const ServicePartnerManagement = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [alertOpenFile, setAlertOpenFile] = useState(false)
  const handleAlertOpenFile = () => setAlertOpenFile(true)
  const handleAlertCloseFile = () => setAlertOpenFile(false)
  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const servicePartnerData: IServicePartnerState = useSelector((state: any) => state.servicePartner)

  useEffect(() => {
    dispatch(servicePartnerRedux.actions.servicePartnerCountryList())
    dispatch(servicePartnerRedux.actions.servicePartnerCurrencyList())
  }, [])

  //retrive the service partner list here
  useEffect(() => {
    dispatch(servicePartnerRedux.actions.getServicePartner(params))
    dispatch(setting.actions.getSettingType())
  }, [params])

  const {
    loading,
    singleEnableSuccess,
    singleDisableSuccess,
    enableSuccess,
    disableSuccess,
    toggleLoading,
    success,
    deleteSuccess,
    importSuccess,
  } = useSelector((state: any) => state.servicePartner)

  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const enableServicePartnerData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(servicePartnerRedux.actions.enableServicePartner(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableServicePartnerData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(servicePartnerRedux.actions.disableServicePartner(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
              data-cy='edit-button'
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let servicePartnerId = [rowData.id]
                setCheckedValues(servicePartnerId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={toggleLoading}
            checked={rowData.status === '1' ? true : false}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const handleToggleAction = (data: {[key: string]: string}) => {
    data?.status === '1'
      ? dispatch(servicePartnerRedux.actions.singleDisableServicePartner({id: data.id}))
      : dispatch(servicePartnerRedux.actions.singleEnableServicePartner({id: data.id}))
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      dataKey: 'title',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },

    {
      label: 'Country Code',
      dataKey: 'countryCode',
      flexGrow: 1,
      cell: <Cell dataKey='countryCode' />,
      sortable: true,
    },

    {
      label: 'Address',
      dataKey: 'address',
      flexGrow: 1,
      cell: <Cell dataKey='address' />,
      sortable: true,
    },
    {
      label: 'Currency Name',
      dataKey: 'currencyName',
      flexGrow: 1,
      cell: <Cell dataKey='currencyName' />,
      sortable: true,
    },
    {
      label: 'Service Charge',
      dataKey: 'serviceCharge',
      flexGrow: 1,
      cell: <Cell dataKey='serviceCharge' />,
      sortable: true,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Service partner deleted successfully')
    }
    if (enableSuccess) {
      toast.success('Service partner enabled successfully')
    }
    if (disableSuccess) {
      toast.success('Service partner disabled successfully')
    }
    if (importSuccess) {
      toast.success('File upload successfully')
    }
    handleChecked([])
    dispatch(servicePartnerRedux?.actions.getServicePartner(params))
  }, [deleteSuccess, success, enableSuccess, disableSuccess, importSuccess])

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('Service partner enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('Service partner disabled successfully')
    }
    handleChecked([])
    dispatch(servicePartnerRedux.actions.getServicePartner(params))
  }, [singleDisableSuccess, singleEnableSuccess])

  const data = servicePartnerData?.data?.servicePartner
    ? servicePartnerData?.data?.servicePartner?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        slug: item?.slug,
        title: item?.title,
        titleNp: item?.title_np,
        countryCode: item?.countryCode,
        currencyCode: item?.currencyCode,
        currencyName: item?.currencyName,
        website: item?.website,
        phoneNo: item?.phoneNo,
        address: item?.address,
        serviceCharge: item?.serviceCharge,
        status: item?.status,
        order: item?.order,
        img: item?.img,
      }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let servicePartner = checkedValues?.map((value) => ({id: value}))
    dispatch(servicePartnerRedux?.actions.deleteServicePartner(servicePartner))
    handleAlertClose()
  }

  function fileImport(event: any) {
    if (!isEmpty(event.target.files)) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      dispatch(servicePartnerRedux.actions.importServicePartner(formData))
      event.target.value = null
    }
  }

  const handleRefresh = () => {
    dispatch(servicePartnerRedux.actions.getServicePartner(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Service Partner Management'
          params={params}
          setParams={setParams}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleRefresh={handleRefresh}
          importShow={true}
          handleImport={fileImport}
          handleExport={handleAlertOpenFile}
          exportButtonName='Download Sample File'
          enableMultiple={enableServicePartnerData}
          disableMultiple={disableServicePartnerData}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={servicePartnerData?.loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={servicePartnerData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
          {open && (
            <AddServicePartner
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>

        {/* Delete Modal */}
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              handleClose={() => handleAlertClose()}
              isOpen={alertOpen}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}

        {/* File Download */}
        <div className='modal-container'>
          <Modal
            backdrop='static'
            role='alertdialog'
            open={alertOpenFile}
            onClose={handleAlertCloseFile}
            enforceFocus={false}
            size='xs'
            data-cy='modal'
          >
            <Modal.Body>
              <div className='px-4'>
                <div className='d-flex'>
                  <RemindOutlineIcon
                    style={{
                      color: '#ffb300',
                      fontSize: 24,
                    }}
                  />
                  <h4 data-cy='modal-title'>
                    <strong className='ms-3'>Choose file format and download!</strong>
                  </h4>
                </div>

                {/* Template download */}

                <div className='row mt-5'>
                  <div className='col-lg-6'>
                    <button
                      className='dt-btn dt-btn-outline-primary dt-btn-sm w-100'
                      onClick={() => {
                        dispatch(servicePartnerRedux.actions.downloadServicePartnerSample('csv'))
                        handleAlertCloseFile()
                      }}
                      data-cy='import-file-link'
                    >
                      CSV file
                    </button>
                  </div>
                  <div className='col-lg-6'>
                    <button
                      className='dt-btn dt-btn-outline-primary dt-btn-sm w-100'
                      onClick={() => {
                        dispatch(servicePartnerRedux.actions.downloadServicePartnerSample('xlsx'))
                        handleAlertCloseFile()
                      }}
                      data-cy='import-show-button'
                    >
                      {' '}
                      XLSX file
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleAlertCloseFile}
                className='btn btn-secondary btn-sm ms-3'
                data-cy='import-cancel'
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* End of file Download */}
      </div>
    </>
  )
}

export default ServicePartnerManagement
