export const actionTypes = {
  // get_MEETING_TYPE
  GET_MEETING_TYPE_START: 'GET_MEETING_TYPE_START',
  GET_MEETING_TYPE_SUCCESS: 'GET_MEETING_TYPE_SUCCESS',
  GET_MEETING_TYPE_FINISH: 'GET_MEETING_TYPE_FINISH',
  // get appointment_type
  GET_APPOINTMENT_TYPE_START: 'GET_APPOINTMENT_TYPE_START',
  GET_APPOINTMENT_TYPE_SUCCESS: 'GET_APPOINTMENT_TYPE_SUCCESS',
  GET_APPOINTMENT_TYPE_FINISH: 'GET_APPOINTMENT_TYPE_FINISH',

  // get appointment_type
  GET_ALL_APPOINTMENT_TYPE_START: 'GET_ALL_APPOINTMENT_TYPE_START',
  GET_ALL_APPOINTMENT_TYPE_SUCCESS: 'GET_ALL_APPOINTMENT_TYPE_SUCCESS',
  GET_ALL_APPOINTMENT_TYPE_FINISH: 'GET_ALL_APPOINTMENT_TYPE_FINISH',

  //Add appointment_type
  ADD_APPOINTMENT_TYPE_REQUEST: 'ADD_APPOINTMENT_TYPE_REQUEST',
  ADD_APPOINTMENT_TYPE_SUCCESS: 'ADD_APPOINTMENT_TYPE_SUCCESS',
  ADD_APPOINTMENT_TYPE_FINISH: 'ADD_APPOINTMENT_TYPE_FINISH',
  ADD_APPOINTMENT_TYPE_RESET: 'ADD_APPOINTMENT_TYPE_RESET',

  // update appointment_type
  UPDATE_APPOINTMENT_TYPE_START: 'UPDATE_APPOINTMENT_TYPE_START',
  UPDATE_APPOINTMENT_TYPE_SUCCESS: 'UPDATE_APPOINTMENT_TYPE_SUCCESS',
  UPDATE_APPOINTMENT_TYPE_FINISH: 'UPDATE_APPOINTMENT_TYPE_FINISH',
  UPDATE_APPOINTMENT_TYPE_RESET: 'UPDATE_APPOINTMENT_TYPE_RESET',

  //Bulk Activate appointment_type
  ACTIVATE_APPOINTMENT_TYPE_REQUEST: 'ACTIVATE_APPOINTMENT_TYPE_REQUEST',
  ACTIVATE_APPOINTMENT_TYPE_SUCCESS: 'ACTIVATE_APPOINTMENT_TYPE_SUCCESS',
  ACTIVATE_APPOINTMENT_TYPE_FINISH: 'ACTIVATE_APPOINTMENT_TYPE_FINISH',

  //Bulk Deactivate appointment_type
  DEACTIVATE_APPOINTMENT_TYPE_REQUEST: 'DEACTIVATE_APPOINTMENT_TYPE_REQUEST',
  DEACTIVATE_APPOINTMENT_TYPE_SUCCESS: 'DEACTIVATE_APPOINTMENT_TYPE_SUCCESS',
  DEACTIVATE_APPOINTMENT_TYPE_FINISH: 'DEACTIVATE_APPOINTMENT_TYPE_FINISH',

  //Single Activate appointment_type
  SINGLE_ACTIVATE_APPOINTMENT_TYPE_REQUEST: 'SINGLE_ACTIVATE_APPOINTMENT_TYPE_REQUEST',
  SINGLE_ACTIVATE_APPOINTMENT_TYPE_SUCCESS: 'SINGLE_ACTIVATE_APPOINTMENT_TYPE_SUCCESS',
  SINGLE_ACTIVATE_APPOINTMENT_TYPE_FINISH: 'SINGLE_ACTIVATE_APPOINTMENT_TYPE_FINISH',

  //Single Deactivate appointment_type
  SINGLE_DEACTIVATE_APPOINTMENT_TYPE_REQUEST: 'SINGLE_DEACTIVATE_APPOINTMENT_TYPE_REQUEST',
  SINGLE_DEACTIVATE_APPOINTMENT_TYPE_SUCCESS: 'SINGLE_DEACTIVATE_APPOINTMENT_TYPE_SUCCESS',
  SINGLE_DEACTIVATE_APPOINTMENT_TYPE_FINISH: 'SINGLE_DEACTIVATE_APPOINTMENT_TYPE_FINISH',

  // delete appointment_type
  DELETE_APPOINTMENT_TYPE_START: 'DELETE_APPOINTMENT_TYPE_START',
  DELETE_APPOINTMENT_TYPE_SUCCESS: 'DELETE_APPOINTMENT_TYPE_SUCCESS',
  DELETE_APPOINTMENT_TYPE_FINISH: 'DELETE_APPOINTMENT_TYPE_FINISH',
}
