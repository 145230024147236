import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const LoanTenure = `${API_URL}/loan-tenure`

export const service = {
  getLoanTenure: (params: ParamsModel) => {
    return axios.get(LoanTenure, {params})
  },

  addLoanTenure: (data: any) => {
    return axios.post(LoanTenure, data)
  },

  updateLoanTenure: (body: any, id: string) => {
    return axios.patch(`${LoanTenure}/${id}`, body)
  },

  deleteLoanTenure: (data: DeleteModel) => {
    return axios.delete(LoanTenure, {data})
  },
  singleActivateLoanTenure: (id: any) => {
    return axios.patch(`${LoanTenure}/enable`, id)
  },

  singleDeactivateLoanTenure: (id: any) => {
    return axios.patch(`${LoanTenure}/disable`, id)
  },

  sortLoanTenure: (body: any) => {
    return axios.patch(`${LoanTenure}/sort`, body)
  },
}
