import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const LoanSubCategory = `${API_URL}/loan-sub-category`

export const service = {
  getLoanSubCategory: (params: ParamsModel) => {
    return axios.get(LoanSubCategory, {params})
  },

  addLoanSubCategory: (data: any) => {
    return axios.post(LoanSubCategory, data)
  },

  updateLoanSubCategory: (body: any, id: string) => {
    return axios.patch(`${LoanSubCategory}/${id}`, body)
  },

  deleteLoanSubCategory: (data: DeleteModel) => {
    return axios.delete(LoanSubCategory, {data})
  },
  singleActivateLoanSubCategory: (id: any) => {
    return axios.patch(`${LoanSubCategory}/enable`, id)
  },

  singleDeactivateLoanSubCategory: (id: any) => {
    return axios.patch(`${LoanSubCategory}/disable`, id)
  },

  sortLoanSubCategory: (body: any) => {
    return axios.patch(`${LoanSubCategory}/sort`, body)
  },
}
