import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import TrashIcon from '@rsuite/icons/Trash'
import {isEmpty} from 'lodash'
import {toast} from 'react-toastify'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

import axios from 'axios'
import {FiEye} from 'react-icons/fi'
import {SortType} from 'rsuite-table/lib/@types/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as dispute from '../redux'
import ViewDetailsModal from './ViewDetailsModal'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const DisputeClaimManager = () => {
  const dispatch = useDispatch()

  const disputesData = useSelector((state: any) => state.disputes)
  const {loading, success, deleteSuccess} = useSelector((state: any) => state.disputes)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const downloadDisputeClaimData = () => {
    const GET_DISPUTE_FILE = `${API_URL}/dispute-claim/export?search=${params.search}`
    axios(GET_DISPUTE_FILE, {
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `DisputeData.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              data-cy='edit-button'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let loan = [rowData.id]
                setCheckedValues(loan)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Dispute Type',
      dataKey: 'disputeType',
      flexGrow: 1,
      cell: <Cell dataKey='disputeType' />,
    },
    {
      label: 'Account Holder Name',
      dataKey: 'accountHolderName',
      flexGrow: 1,
      cell: <Cell dataKey='accountHolderName' />,
    },
    {
      label: 'Email',
      dataKey: 'email_address',
      flexGrow: 1,
      cell: <Cell dataKey='email_address' />,
      sortable: true,
    },

    {
      label: 'Mobile No',
      dataKey: 'contactNumber',
      flexGrow: 1,
      cell: <Cell dataKey='contactNumber' />,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Dispute deleted successfully')
    }

    handleChecked([])
    dispatch(dispute?.actions.getDisputes(params))
  }, [deleteSuccess, success, params])

  const data = disputesData?.data?.disputeClaim
    ? disputesData?.data?.disputeClaim?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        disputeType: item?.disputeType?.disputeTypeEn,
        accountHolderName: item.accountHolderName,
        transactionDate: item.transactionDate,
        accountNumber: item.accountNumber,
        email_address: item.emailAddress,
        emailAddress: item.emailAddress,
        claimDisputeAmount: item.claimDisputeAmount,
        transactionBankMerchant: item.transactionBankMerchant,
        transactionLocationCountry: item.transactionLocationCountry,
        contactNumber: item.contactNumber,
        cardType: item?.cardType?.cardTypeEn,
        cardNumber: item?.cardNo,
        otherInformation: item?.otherInformation,
        iTouchUserName: item?.iTouchUserName,
        traceNo: item?.traceNo,
        regMobileNo: item?.regMobileNo,
      }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleDelete = () => {
    let disputeIds = checkedValues?.map((value) => ({id: value}))
    dispatch(dispute?.actions.deleteDisputes(disputeIds))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(dispute?.actions.getDisputes(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Dispute Claim'
          addShow={false}
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={false}
          toggleMultipleShow={false}
          exportButtonName='Export CSV'
          handleExport={downloadDisputeClaimData}
          addNoteShow={false}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={disputesData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}

        {/* Delete Modal */}
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default DisputeClaimManager
