import {Action} from 'redux'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialServiceTypeState: IServiceTypeState = {
  data: {
    subscribe: [],
    meta: [],
  },
  deleteSuccess: false,

  loading: false,
  success: false,
}

export interface IServiceTypeState {
  data?: {
    subscribe?: ServiceTypeModel[]
    meta?: {[key: string]: string | number}[]
  }
  deleteSuccess?: boolean

  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IServiceTypeState = initialServiceTypeState,
  action: ActionWithPayload<IServiceTypeState>
) => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIBE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SUBSCRIBE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SUBSCRIBE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_SUBSCRIBE_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_SUBSCRIBE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_SUBSCRIBE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.EXPORT_SUBSCRIBE_FILE_START: {
      return {...state, loading: true}
    }

    case actionTypes.EXPORT_SUBSCRIBE_FILE_SUCCESS: {
      return {...state, success: true, loading: false}
    }

    case actionTypes.EXPORT_SUBSCRIBE_FILE_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    default:
      return state
  }
}
