import axios from 'axios'
import { ParamsModel } from 'src/app/modules/common/Model'
import { DeleteAgencyModel } from '../Model'
import { AgencyModel } from '../Model/AgencyModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_AGENCY_CATEGORY = `${API_URL}/district-category-option`
export const GET_EXTENDED_HOURS = `${API_URL}/agency-extended-hours-status`
export const GET_AGENCY_DATA = `${API_URL}/agency`
export const IMPORT_AGENCY = `${API_URL}/agency/store-from-file`

export const service = {
  getAgencyCategory: () => {
    return axios.get(GET_AGENCY_CATEGORY)
  },

  getExtendedHours: () => {
    return axios.get(GET_EXTENDED_HOURS)
  },

  getAgencyData: (params: ParamsModel) => {
    return axios.get(GET_AGENCY_DATA, { params })
  },
  getAgencyListData: (params: ParamsModel) => {
    return axios.get(`${GET_AGENCY_DATA}/list`, { params })
  },
  getAllAgencyData: () => {
    return axios.get(`${GET_AGENCY_DATA}/list`)
  },

  addAgency: (data: any) => {
    return axios.post(GET_AGENCY_DATA, data)
  },

  updateAgency: (body: AgencyModel, id: string) => {
    return axios.patch(`${GET_AGENCY_DATA}/${id}`, body)
  },

  deleteAgency: (data: DeleteAgencyModel) => {
    return axios.delete(GET_AGENCY_DATA, { data })
  },

  enableAgency: (data: Array<string>) => {
    const selectedAgency = {
      agencyId: data,
    }
    return axios.patch(`${GET_AGENCY_DATA}/enable`, selectedAgency)
  },

  disableAgency: (data: Array<string>) => {
    const selectedAgency = {
      agencyId: data,
    }
    return axios.patch(`${GET_AGENCY_DATA}/disable`, selectedAgency)
  },

  singleEnableAgency: (data: Array<string>) => {
    const selectedAgency = {
      agencyId: [data],
    }
    return axios.patch(`${GET_AGENCY_DATA}/enable`, selectedAgency)
  },

  singleDisableAgency: (data: Array<string>) => {
    const selectedAgency = {
      agencyId: [data],
    }
    return axios.patch(`${GET_AGENCY_DATA}/disable`, selectedAgency)
  },

  //export file
  exportFile: (fileName: string, data: any) => {
    return axios
      .get(`${GET_AGENCY_DATA}/export?search=${data?.search || ''}&fileFormat=csv`)
      .then((response) => {
        const blob = new Blob([response?.data])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
  },

  exportTemplateFile: () => {
    return axios.get(`${GET_AGENCY_DATA}/get-sample`).then((response) => {
      // const blob = new Blob([response?.data])
      // const url = window.URL.createObjectURL(blob)
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', '') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  importAgency: (data: any) => {
    return axios.post(IMPORT_AGENCY, data)
  },
}
