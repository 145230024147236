import {Action} from 'redux'
import {
  AuctionImageTypeListModel,
  AuctionModel,
  AuctionTypeModel,
  PropertyTypeListModel,
} from '../Model/Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAuctionState: IAuctionState = {
  data: {
    auction: [],
    meta: [],
  },
  sortAuctionData: [],
  auctionList: {auction: []},
  auctionTypeList: {auctionType: []},
  propertyTypeList: {propertyType: []},
  auctionImageTypeList: {auctionImageType: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IAuctionState {
  data?: {
    auction?: AuctionModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortAuctionData?: IAuctionState[]
  auctionList?: {auction: AuctionModel[]}
  auctionTypeList?: {auctionType: AuctionTypeModel[]}
  propertyTypeList?: {propertyType: PropertyTypeListModel[]}
  auctionImageTypeList?: {auctionImageType: AuctionImageTypeListModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IAuctionState = initialAuctionState,
  action: ActionWithPayload<IAuctionState>
) => {
  switch (action.type) {
    //GET Auction DATA
    case actionTypes.GET_AUCTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_AUCTION_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_AUCTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add Auction
    case actionTypes.ADD_AUCTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_AUCTION_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_AUCTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_AUCTION: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Auction
    case actionTypes.UPDATE_AUCTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_AUCTION_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_AUCTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_AUCTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_AUCTION_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_AUCTION_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_AUCTION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_AUCTION_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_AUCTION_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_AUCTION_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_AUCTION_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_AUCTION_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_AUCTION_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_AUCTION_SUCCESS: {
      const changedData: any = action.payload?.data?.auction
      let newData = state?.data?.auction?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, auction: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_AUCTION_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_AUCTION_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_AUCTION_SUCCESS: {
      const changedData: any = action.payload?.data?.auction
      let newData = state?.data?.auction?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, auction: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_AUCTION_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_AUCTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_AUCTION_SUCCESS: {
      return {
        ...state,
        sortAuctionData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_AUCTION_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_AUCTION_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortAuctionData: [],
      }
    }
    // AUCTION TYPE
    case actionTypes.GET_AUCTION_TYPE_LIST_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_AUCTION_TYPE_LIST_SUCCESS: {
      return {...state, auctionTypeList: action.payload, loading: false}
    }

    case actionTypes.GET_AUCTION_TYPE_LIST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // PROPERTY TYPE
    case actionTypes.GET_PROPERTY_TYPE_LIST_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PROPERTY_TYPE_LIST_SUCCESS: {
      return {...state, propertyTypeList: action.payload, loading: false}
    }

    case actionTypes.GET_PROPERTY_TYPE_LIST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    // AUCTION_IMAGE TYPE
    case actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_SUCCESS: {
      return {...state, auctionImageTypeList: action.payload, loading: false}
    }

    case actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
