import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

import {StateParamsModel} from 'src/app/modules/common/Model'
import * as certificateCategory from '../../certificateCategory/index'
import * as certificateManager from '../index'

import Modal from 'rsuite/Modal'

import {IServiceCategoryState} from 'src/app/modules/services/components/serviceCategory/redux'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  status: Yup.string().required('Status is required'),
  attachedFile: Yup.string().required('Attached File is required'),
})

const AddFormsAndDownload = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.certificateManager)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    dispatch(certificateCategory.actions.getAllCertificateCategory())
  }, [])

  const certificateData = useSelector((state: any) => state.certificateCategory)

  // useEffect(() => {
  //   const merchantCatType = categoryType?.filter(
  //     (item: {[key: string]: string}) => item.system_name === 'certificate'
  //   )
  //   setDownloadCategory(merchantCatType[0]?.id)
  // }, [categoryType])

  const certificateList: IServiceCategoryState = useSelector(
    (state: any) => state.certificateCategoryOne
  )

  // const serviceCategoryOptions = serviceCatData?.data?.serviceCategory?.map((items: any) => ({
  //   label: items.name,
  //   value: items.id,
  // }))

  // useEffect(() => {
  //   if (!isEmpty(downloadCategory)) {
  //     dispatch(categoriesRedux.categories.actions.getSpecificCmsCategories(downloadCategory))
  //   }
  // }, [downloadCategory])

  const categoryOptions = certificateData?.CertificateCategoryList?.certificateCategory?.map(
    (item: {[key: string]: string}) => ({
      label: item.name,
      value: item.id,
    })
  )

  useEffect(() => {
    if (success) {
      dispatch(certificateManager?.actions.getCertificate(params))
      isEmpty(editSelectedData)
        ? toast.success('Certificate added successfully')
        : toast.success('Certificate edited successfully')
      dispatch(certificateManager?.actions?.resetCertificate())
      handleClose()
    }
  }, [success])
  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                name_np: '',
                attachedFileTitle: '',
                attachedFile: '',
                publishedDate: '',
                certificateCategoryId: '',
                slug: '',
                status: '',
                image: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.name.replace(/\s/g, '-').toLowerCase(),
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    certificateManager.actions.updateCertificate(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(certificateManager.actions.addCertificate(formData))
                }
              }}
            >
              {({touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'name',
                      'name_np',
                      'attachedFileTitle',
                      'attachedFile',
                      'publishedDate',
                      'slug',
                      'status',
                      'certificateCategoryId',
                      'image',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Category Type'
                            name='certificateCategoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Name (NP)'
                            label='Name (NP)'
                            name='name_np'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Attached file title'
                            label='Attached file title (EN)'
                            name='attachedFileTitle'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Attached File'
                            name='attachedFile'
                            setFieldValue={setFieldValue}
                            value={values?.attachedFile}
                            required
                          />
                          {!isEmpty(values?.attachedFile) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setFieldValue('attachedFile', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    Delete
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${
                                      !imageBaseUrl.includes('framework')
                                        ? imageBaseUrl + '/framework'
                                        : imageBaseUrl
                                    }/${values?.attachedFile}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='date'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter publish date'
                            label='Publish Date'
                            name='publishedDate'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : values?.name.replace(/\s/g, '-').toLowerCase()
                            }
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddFormsAndDownload
