import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getApplication: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ONLINE_APPLICATION_START,
    payload: params,
  }),

  getApplicationSuccess: (data: any) => ({
    type: actionTypes.GET_ONLINE_APPLICATION_SUCCESS,
    payload: data,
  }),
  getApplicationFinish: () => ({
    type: actionTypes.GET_ONLINE_APPLICATION_FINISH,
  }),
  getApplicationError: (error: unknown) => ({
    type: actionTypes.GET_ONLINE_APPLICATION_FINISH,
    payload: {error},
  }),

  addApplication: (data: any) => ({
    type: actionTypes.ADD_ONLINE_APPLICATION_START,
    payload: data,
  }),
  addApplicationSuccess: (task: any) => ({
    type: actionTypes.ADD_ONLINE_APPLICATION_SUCCESS,
    payload: task,
  }),
  addApplicationFinish: () => ({
    type: actionTypes.ADD_ONLINE_APPLICATION_FINISH,
  }),
  addApplicationReset: () => ({
    type: actionTypes.ADD_ONLINE_APPLICATION_RESET,
  }),

  updateApplication: (data: any, id: string) => ({
    type: actionTypes.UPDATE_ONLINE_APPLICATION_START,
    payload: {data, id},
  }),
  updateApplicationSuccess: (task: any) => ({
    type: actionTypes.UPDATE_ONLINE_APPLICATION_SUCCESS,
    payload: task,
  }),
  updateApplicationFinish: () => ({
    type: actionTypes.UPDATE_ONLINE_APPLICATION_FINISH,
  }),
  updateApplicationReset: () => ({
    type: actionTypes.UPDATE_ONLINE_APPLICATION_RESET,
  }),

  deleteOnlineApplication: (data: any) => ({
    type: actionTypes.DELETE_ONLINE_ONLINE_APPLICATION_START,
    payload: {onlineApplicationId: data},
  }),
  deleteApplicationSuccess: (data: any) => ({
    type: actionTypes.DELETE_ONLINE_APPLICATION_SUCCESS,
    payload: data,
  }),
  deleteApplicationFinish: () => ({
    type: actionTypes.DELETE_ONLINE_APPLICATION_FINISH,
  }),
  activateApplication: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_REQUEST,
    payload: {onlineApplicationId: id},
  }),
  deactivateApplication: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_REQUEST,
    payload: {onlineApplicationId: id},
  }),
  singleActivateApplication: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_ONLINE_APPLICATION_REQUEST,
    payload: {onlineApplicationId: [id]},
  }),
  singleDeactivateApplication: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_ONLINE_APPLICATION_REQUEST,
    payload: {onlineApplicationId: [id]},
  }),

  sortApplication: (data: any) => ({
    type: actionTypes.SORT_ONLINE_APPLICATION_START,
    payload: data,
  }),
  sortApplicationSuccess: (data: any) => ({
    type: actionTypes.SORT_ONLINE_APPLICATION_SUCCESS,
    payload: data,
  }),
  sortApplicationFinish: () => ({
    type: actionTypes.SORT_ONLINE_APPLICATION_FINISH,
  }),
  sortApplicationReset: () => ({
    type: actionTypes.SORT_ONLINE_APPLICATION_RESET,
  }),
}
