import {ParamsModel} from 'src/app/modules/common/Model'
import {IExportFileState} from 'src/app/modules/network/components/branchless'
import {actionTypes} from './constants'

export const actions = {
  // get ServiceType DATA
  getFeedbackFormDetail: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_FEEDBACK_FORM_DETAIL_START,
    payload: params,
  }),
  getFeedbackFormDetailSuccess: (data: any) => ({
    type: actionTypes.GET_FEEDBACK_FORM_DETAIL_SUCCESS,
    payload: data,
  }),
  getFeedbackFormDetailFinish: () => ({
    type: actionTypes.GET_FEEDBACK_FORM_DETAIL_FINISH,
  }),

  //Export file csv
  exportFile: (fileName: string, params: ParamsModel) => {
    return {
      type: actionTypes.EXPORT_FEEDBACK_FORM_DETAIL_FILE_START,
      payload: {fileName, params},
    }
  },

  exportFileSuccess: (data: IExportFileState) => ({
    type: actionTypes.EXPORT_FEEDBACK_FORM_DETAIL_FILE_SUCCESS,
    payload: data,
  }),
  exportFileError: () => ({
    type: actionTypes.EXPORT_FEEDBACK_FORM_DETAIL_FILE_FINISH,
  }),

  //Delete Feedback
  deleteFeedback: (data: any) => ({
    type: actionTypes.DELETE_FEEDBACK_START,
    payload: {feedbackId: data},
  }),
  deleteFeedbackSuccess: (data: any) => ({
    type: actionTypes.DELETE_FEEDBACK_SUCCESS,
    payload: data,
  }),
  deleteFeedbackFinish: () => ({
    type: actionTypes.DELETE_FEEDBACK_FINISH,
  }),
}
