import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteBankDetailModel, SortBankDetailModel} from '../Model'
import {BankDetailModel} from '../Model/BankDetailModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BANK_DETAIL_DATA = `${API_URL}/about-bank`

export const service = {
  getBankDetail: (params: ParamsModel) => {
    return axios.get(BANK_DETAIL_DATA, {params})
  },
  getAllBankDetail: () => {
    return axios.get(`${BANK_DETAIL_DATA}/list`)
  },

  addBankDetail: (data: any) => {
    return axios.post(BANK_DETAIL_DATA, data)
  },

  updateBankDetail: (body: BankDetailModel, id: string) => {
    return axios.put(`${BANK_DETAIL_DATA}/${id}`, body)
  },

  deleteBankDetail: (data: DeleteBankDetailModel) => {
    return axios.delete(`${BANK_DETAIL_DATA}/bulk-delete`, {data})
  },

  enableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankId: data,
    }
    return axios.patch(`${BANK_DETAIL_DATA}/enable`, selectedBankDetail)
  },

  disableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankId: data,
    }
    return axios.patch(`${BANK_DETAIL_DATA}/disable`, selectedBankDetail)
  },

  singleEnableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankId: [data],
    }
    return axios.patch(`${BANK_DETAIL_DATA}/enable`, selectedBankDetail)
  },

  singleDisableBankDetail: (data: Array<string>) => {
    const selectedBankDetail = {
      aboutBankId: [data],
    }
    return axios.patch(`${BANK_DETAIL_DATA}/disable`, selectedBankDetail)
  },

  sortBankDetail: (body: SortBankDetailModel) => {
    return axios.patch(`${BANK_DETAIL_DATA}/sort`, body)
  },
}
