import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BENEFIT_LIST_API = `${API_URL}/benefit-calculator`

export const getBenfitListDetailsAPI = (params: ParamsModel) => {
  return axios.get(BENEFIT_LIST_API, {params})
}
