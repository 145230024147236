export const actionTypes = {
  // get Road Block Popup
  GET_ROAD_BLOCK_POPUP_START: 'GET_ROAD_BLOCK_POPUP_START',
  GET_ROAD_BLOCK_POPUP_SUCCESS: 'GET_ROAD_BLOCK_POPUP_SUCCESS',
  GET_ROAD_BLOCK_POPUP_FINISH: 'GET_ROAD_BLOCK_POPUP_FINISH',

  //Add Road Block Popup
  ADD_ROAD_BLOCK_POPUP_REQUEST: 'ADD_ROAD_BLOCK_POPUP_REQUEST',
  ADD_ROAD_BLOCK_POPUP_SUCCESS: 'ADD_ROAD_BLOCK_POPUP_SUCCESS',
  ADD_ROAD_BLOCK_POPUP_FINISH: 'ADD_ROAD_BLOCK_POPUP_FINISH',
  ADD_ROAD_BLOCK_POPUP_RESET: 'ADD_ROAD_BLOCK_POPUP_RESET',

  // update Road Block Popup
  UPDATE_ROAD_BLOCK_POPUP_START: 'UPDATE_ROAD_BLOCK_POPUP_START',
  UPDATE_ROAD_BLOCK_POPUP_SUCCESS: 'UPDATE_ROAD_BLOCK_POPUP_SUCCESS',
  UPDATE_ROAD_BLOCK_POPUP_FINISH: 'UPDATE_ROAD_BLOCK_POPUP_FINISH',
  UPDATE_ROAD_BLOCK_POPUP_RESET: 'UPDATE_ROAD_BLOCK_POPUP_RESET',

  //Bulk Activate Road Block Popup
  ACTIVATE_ROAD_BLOCK_POPUP_REQUEST: 'ACTIVATE_ROAD_BLOCK_POPUP_REQUEST',
  ACTIVATE_ROAD_BLOCK_POPUP_SUCCESS: 'ACTIVATE_ROAD_BLOCK_POPUP_SUCCESS',
  ACTIVATE_ROAD_BLOCK_POPUP_FINISH: 'ACTIVATE_ROAD_BLOCK_POPUP_FINISH',

  //Bulk Deactivate Road Block Popup
  DEACTIVATE_ROAD_BLOCK_POPUP_REQUEST: 'DEACTIVATE_ROAD_BLOCK_POPUP_REQUEST',
  DEACTIVATE_ROAD_BLOCK_POPUP_SUCCESS: 'DEACTIVATE_ROAD_BLOCK_POPUP_SUCCESS',
  DEACTIVATE_ROAD_BLOCK_POPUP_FINISH: 'DEACTIVATE_ROAD_BLOCK_POPUP_FINISH',

  //Single Activate Road Block Popup
  SINGLE_ACTIVATE_ROAD_BLOCK_POPUP_REQUEST: 'SINGLE_ACTIVATE_ROAD_BLOCK_POPUP_REQUEST',
  SINGLE_ACTIVATE_ROAD_BLOCK_POPUP_SUCCESS: 'SINGLE_ACTIVATE_ROAD_BLOCK_POPUP_SUCCESS',
  SINGLE_ACTIVATE_ROAD_BLOCK_POPUP_FINISH: 'SINGLE_ACTIVATE_ROAD_BLOCK_POPUP_FINISH',

  //Single Deactivate Road Block Popup
  SINGLE_DEACTIVATE_ROAD_BLOCK_POPUP_REQUEST: 'SINGLE_DEACTIVATE_ROAD_BLOCK_POPUP_REQUEST',
  SINGLE_DEACTIVATE_ROAD_BLOCK_POPUP_SUCCESS: 'SINGLE_DEACTIVATE_ROAD_BLOCK_POPUP_SUCCESS',
  SINGLE_DEACTIVATE_ROAD_BLOCK_POPUP_FINISH: 'SINGLE_DEACTIVATE_ROAD_BLOCK_POPUP_FINISH',

  // delete Road Block Popup
  DELETE_ROAD_BLOCK_POPUP_START: 'DELETE_ROAD_BLOCK_POPUP_START',
  DELETE_ROAD_BLOCK_POPUP_SUCCESS: 'DELETE_ROAD_BLOCK_POPUP_SUCCESS',
  DELETE_ROAD_BLOCK_POPUP_FINISH: 'DELETE_ROAD_BLOCK_POPUP_FINISH',

  // get list oF Road Block
  GET_ALL_ROAD_BLOCK_START: 'GET_ALL_ROAD_BLOCK_START',
  GET_ALL_ROAD_BLOCK_SUCCESS: 'GET_ALL_ROAD_BLOCK_SUCCESS',
  GET_ALL_ROAD_BLOCK_FINISH: 'GET_ALL_ROAD_BLOCK_FINISH',

  // sort
  SORT_ROAD_BLOCK_START: 'SORT_ROAD_BLOCK_START',
  SORT_ROAD_BLOCK_SUCCESS: 'SORT_ROAD_BLOCK_SUCCESS',
  SORT_ROAD_BLOCK_FINISH: 'SORT_ROAD_BLOCK_FINISH',
  SORT_ROAD_BLOCK_RESET: 'SORT_ROAD_BLOCK_RESET',
}
