import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getAllMidMenu: (id: string = '') => ({
    type: actionTypes.GET_ALL_MID_MENU_MANAGER_START,
    payload: id,
  }),

  getAllMidMenuSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ALL_MID_MENU_MANAGER_SUCCESS,
    payload: data,
  }),
  getAllMidMenuFinish: () => ({
    type: actionTypes.GET_ALL_MID_MENU_MANAGER_FINISH,
  }),
  getAllMidMenuError: (error: unknown) => ({
    type: actionTypes.GET_ALL_MID_MENU_MANAGER_FINISH,
    payload: {error},
  }),

  getSpecificMidMenu: (id: string = '') => ({
    type: actionTypes.GET_SPECIFIC_MID_MENU_MANAGER_START,
    payload: id,
  }),

  getSpecificMidMenuSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SPECIFIC_MID_MENU_MANAGER_SUCCESS,
    payload: data,
  }),
  getSpecificMidMenuFinish: () => ({
    type: actionTypes.GET_SPECIFIC_MID_MENU_MANAGER_FINISH,
  }),
  getSpecificMidMenuError: (error: unknown) => ({
    type: actionTypes.GET_SPECIFIC_MID_MENU_MANAGER_FINISH,
    payload: {error},
  }),

  getMidMenu: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_MID_MENU_MANAGER_START,
    payload: params,
  }),

  getMidMenuSuccess: (data: any) => ({
    type: actionTypes.GET_MID_MENU_MANAGER_SUCCESS,
    payload: data,
  }),
  getMidMenuFinish: () => ({
    type: actionTypes.GET_MID_MENU_MANAGER_FINISH,
  }),
  getMidMenuError: (error: unknown) => ({
    type: actionTypes.GET_MID_MENU_MANAGER_FINISH,
    payload: {error},
  }),

  addMidMenu: (data: any) => ({
    type: actionTypes.ADD_MID_MENU_MANAGER_START,
    payload: data,
  }),
  addMidMenuSuccess: (task: any) => ({
    type: actionTypes.ADD_MID_MENU_MANAGER_SUCCESS,
    payload: task,
  }),
  addMidMenuFinish: () => ({
    type: actionTypes.ADD_MID_MENU_MANAGER_FINISH,
  }),
  addMidMenuReset: () => ({
    type: actionTypes.ADD_MID_MENU_MANAGER_RESET,
  }),

  updateMidMenu: (data: any, id: string) => ({
    type: actionTypes.UPDATE_MID_MENU_MANAGER_START,
    payload: {data, id},
  }),
  updateMidMenuSuccess: (task: any) => ({
    type: actionTypes.UPDATE_MID_MENU_MANAGER_SUCCESS,
    payload: task,
  }),
  updateMidMenuFinish: () => ({
    type: actionTypes.UPDATE_MID_MENU_MANAGER_FINISH,
  }),
  updateMidMenuReset: () => ({
    type: actionTypes.UPDATE_MID_MENU_MANAGER_RESET,
  }),

  deleteMidMenu: (data: any) => ({
    type: actionTypes.DELETE_MID_MENU_MANAGER_START,
    payload: {midMenuId: data},
  }),
  deleteMidMenuSuccess: (data: any) => ({
    type: actionTypes.DELETE_MID_MENU_MANAGER_SUCCESS,
    payload: data,
  }),
  deleteMidMenuFinish: () => ({
    type: actionTypes.DELETE_MID_MENU_MANAGER_FINISH,
  }),
  activateMidMenu: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_MID_MENU_MANAGER_REQUEST,
    payload: {midMenuId: id},
  }),
  deactivateMidMenu: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_MID_MENU_MANAGER_REQUEST,
    payload: {midMenuId: id},
  }),
  singleActivateMidMenu: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_MID_MENU_MANAGER_REQUEST,
    payload: {midMenuId: [id]},
  }),
  singleDeactivateMidMenu: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_MID_MENU_MANAGER_REQUEST,
    payload: {midMenuId: [id]},
  }),

  sortMidMenu: (data: any) => ({
    type: actionTypes.SORT_MID_MENU_MANAGER_START,
    payload: data,
  }),
  sortMidMenuSuccess: (data: any) => ({
    type: actionTypes.SORT_MID_MENU_MANAGER_SUCCESS,
    payload: data,
  }),
  sortMidMenuFinish: () => ({
    type: actionTypes.SORT_MID_MENU_MANAGER_FINISH,
  }),
  sortMidMenuReset: () => ({
    type: actionTypes.SORT_MID_MENU_MANAGER_RESET,
  }),
}
