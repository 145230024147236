export const actionTypes = {
  // get fAQ CATEGORY
  GET_FAQ_CATEGORY_START: 'GET_FAQ_CATEGORY_START',
  GET_FAQ_CATEGORY_SUCCESS: 'GET_FAQ_CATEGORY_SUCCESS',
  GET_FAQ_CATEGORY_FINISH: 'GET_FAQ_CATEGORY_FINISH',

  // get all fAQ CATEGORY
  GET_ALL_FAQ_CATEGORY_START: 'GET_ALL_FAQ_CATEGORY_START',
  GET_ALL_FAQ_CATEGORY_SUCCESS: 'GET_ALL_FAQ_CATEGORY_SUCCESS',
  GET_ALL_FAQ_CATEGORY_FINISH: 'GET_ALL_FAQ_CATEGORY_FINISH',

  //Add FAQ Category
  ADD_FAQ_CATEGORY_REQUEST: 'ADD_FAQ_CATEGORY_REQUEST',
  ADD_FAQ_CATEGORY_SUCCESS: 'ADD_FAQ_CATEGORY_SUCCESS',
  ADD_FAQ_CATEGORY_FINISH: 'ADD_FAQ_CATEGORY_FINISH',
  ADD_FAQ_CATEGORY_RESET: 'ADD_FAQ_CATEGORY_RESET',

  // update Faq Category
  UPDATE_FAQ_CATEGORY_START: 'UPDATE_FAQ_CATEGORY_START',
  UPDATE_FAQ_CATEGORY_SUCCESS: 'UPDATE_FAQ_CATEGORY_SUCCESS',
  UPDATE_FAQ_CATEGORY_FINISH: 'UPDATE_FAQ_CATEGORY_FINISH',
  UPDATE_FAQ_CATEGORY_RESET: 'UPDATE_FAQ_CATEGORY_RESET',

  //Bulk Activate Faq Category
  ACTIVATE_FAQ_CATEGORY_REQUEST: 'ACTIVATE_FAQ_CATEGORY_REQUEST',
  ACTIVATE_FAQ_CATEGORY_SUCCESS: 'ACTIVATE_FAQ_CATEGORY_SUCCESS',
  ACTIVATE_FAQ_CATEGORY_FINISH: 'ACTIVATE_FAQ_CATEGORY_FINISH',

  //Bulk Deactivate Faq Category
  DEACTIVATE_FAQ_CATEGORY_REQUEST: 'DEACTIVATE_FAQ_CATEGORY_REQUEST',
  DEACTIVATE_FAQ_CATEGORY_SUCCESS: 'DEACTIVATE_FAQ_CATEGORY_SUCCESS',
  DEACTIVATE_FAQ_CATEGORY_FINISH: 'DEACTIVATE_FAQ_CATEGORY_FINISH',

  //Single Activate Faq Category
  SINGLE_ACTIVATE_FAQ_CATEGORY_REQUEST: 'SINGLE_ACTIVATE_FAQ_CATEGORY_REQUEST',
  SINGLE_ACTIVATE_FAQ_CATEGORY_SUCCESS: 'SINGLE_ACTIVATE_FAQ_CATEGORY_SUCCESS',
  SINGLE_ACTIVATE_FAQ_CATEGORY_FINISH: 'SINGLE_ACTIVATE_FAQ_CATEGORY_FINISH',

  //Single Deactivate Faq Category
  SINGLE_DEACTIVATE_FAQ_CATEGORY_REQUEST: 'SINGLE_DEACTIVATE_FAQ_CATEGORY_REQUEST',
  SINGLE_DEACTIVATE_FAQ_CATEGORY_SUCCESS: 'SINGLE_DEACTIVATE_FAQ_CATEGORY_SUCCESS',
  SINGLE_DEACTIVATE_FAQ_CATEGORY_FINISH: 'SINGLE_DEACTIVATE_FAQ_CATEGORY_FINISH',

  // delete Faq Category
  DELETE_FAQ_CATEGORY_START: 'DELETE_FAQ_CATEGORY_START',
  DELETE_FAQ_CATEGORY_SUCCESS: 'DELETE_FAQ_CATEGORY_SUCCESS',
  DELETE_FAQ_CATEGORY_FINISH: 'DELETE_FAQ_CATEGORY_FINISH',
  
  // sort
  SORT_FAQ_CATEGORY_START: 'SORT_FAQ_CATEGORY_START',
  SORT_FAQ_CATEGORY_SUCCESS: 'SORT_FAQ_CATEGORY_SUCCESS',
  SORT_FAQ_CATEGORY_FINISH: 'SORT_FAQ_CATEGORY_FINISH',
  SORT_FAQ_CATEGORY_RESET: 'SORT_FAQ_CATEGORY_RESET',
}
