export const actionTypes = {
  // get
  GET_ALL_SERVICE_CATEGORY_START: 'GET_ALL_SERVICE_CATEGORY_START',
  GET_ALL_SERVICE_CATEGORY_SUCCESS: 'GET_ALL_SERVICE_CATEGORY_SUCCESS',
  GET_ALL_SERVICE_CATEGORY_FINISH: 'GET_ALL_SERVICE_CATEGORY_FINISH',

  // extended hours
  GET_SERVICE_CATEGORY_START: 'GET_SERVICE_CATEGORY_START',
  GET_SERVICE_CATEGORY_SUCCESS: 'GET_SERVICE_CATEGORY_SUCCESS',
  GET_SERVICE_CATEGORY_FINISH: 'GET_SERVICE_CATEGORY_FINISH',

  // add branch
  ADD_SERVICE_CATEGORY_START: 'ADD_SERVICE_CATEGORY_START',
  ADD_SERVICE_CATEGORY_SUCCESS: 'ADD_SERVICE_CATEGORY_SUCCESS',
  ADD_SERVICE_CATEGORY_FINISH: 'ADD_SERVICE_CATEGORY_FINISH',
  RESET_SERVICE_CATEGORY: 'RESET_SERVICE_CATEGORY',

  // update SERVICE_CATEGORY
  UPDATE_SERVICE_CATEGORY_START: 'UPDATE_SERVICE_CATEGORY_START',
  UPDATE_SERVICE_CATEGORY_SUCCESS: 'UPDATE_SERVICE_CATEGORY_SUCCESS',
  UPDATE_SERVICE_CATEGORY_FINISH: 'UPDATE_SERVICE_CATEGORY_FINISH',

  // delete SERVICE_CATEGORY
  DELETE_SERVICE_CATEGORY_START: 'DELETE_SERVICE_CATEGORY_START',
  DELETE_SERVICE_CATEGORY_SUCCESS: 'DELETE_SERVICE_CATEGORY_SUCCESS',
  DELETE_SERVICE_CATEGORY_FINISH: 'DELETE_SERVICE_CATEGORY_FINISH',

  // Enable SERVICE_CATEGORY
  ENABLE_SERVICE_CATEGORY_REQUEST: 'ENABLE_SERVICE_CATEGORY_REQUEST',
  ENABLE_SERVICE_CATEGORY_SUCCESS: 'ENABLE_SERVICE_CATEGORY_SUCCESS',
  ENABLE_SERVICE_CATEGORY_FINISH: 'ENABLE_SERVICE_CATEGORY_FINISH',

  // Disable SERVICE_CATEGORY
  DISABLE_SERVICE_CATEGORY_REQUEST: 'DISABLE_SERVICE_CATEGORY_REQUEST',
  DISABLE_SERVICE_CATEGORY_SUCCESS: 'DISABLE_SERVICE_CATEGORY_SUCCESS',
  DISABLE_SERVICE_CATEGORY_FINISH: 'DISABLE_SERVICE_CATEGORY_FINISH',

  // Enable SERVICE_CATEGORY
  SINGLE_ENABLE_SERVICE_CATEGORY_REQUEST: 'SINGLE_ENABLE_SERVICE_CATEGORY_REQUEST',
  SINGLE_ENABLE_SERVICE_CATEGORY_SUCCESS: 'SINGLE_ENABLE_SERVICE_CATEGORY_SUCCESS',
  SINGLE_ENABLE_SERVICE_CATEGORY_FINISH: 'SINGLE_ENABLE_SERVICE_CATEGORY_FINISH',

  // Disable SERVICE_CATEGORY
  SINGLE_DISABLE_SERVICE_CATEGORY_REQUEST: 'SINGLE_DISABLE_SERVICE_CATEGORY_REQUEST',
  SINGLE_DISABLE_SERVICE_CATEGORY_SUCCESS: 'SINGLE_DISABLE_SERVICE_CATEGORY_SUCCESS',
  SINGLE_DISABLE_SERVICE_CATEGORY_FINISH: 'SINGLE_DISABLE_SERVICE_CATEGORY_FINISH',
}
