export const actionTypes = {
  GET_AWARD_START: 'GET_AWARD_START',
  GET_AWARD_SUCCESS: 'GET_AWARD_SUCCESS',
  GET_AWARD_FINISH: 'GET_AWARD_FINISH',

  ADD_AWARD_START: 'ADD_AWARD_START',
  ADD_AWARD_SUCCESS: 'ADD_AWARD_SUCCESS',
  ADD_AWARD_FINISH: 'ADD_AWARD_FINISH',
  ADD_AWARD_RESET: 'ADD_AWARD_RESET',

  UPDATE_AWARD_START: 'UPDATE_AWARD_START',
  UPDATE_AWARD_SUCCESS: 'UPDATE_AWARD_SUCCESS',
  UPDATE_AWARD_FINISH: 'UPDATE_AWARD_FINISH',
  UPDATE_AWARD_RESET: 'UPDATE_AWARD_RESET',

  DELETE_AWARD_START: 'DELETE_AWARD_START',
  DELETE_AWARD_SUCCESS: 'DELETE_AWARD_SUCCESS',
  DELETE_AWARD_FINISH: 'DELETE_AWARD_FINISH',

  SINGLE_ACTIVATE_AWARD_REQUEST: 'SINGLE_ACTIVATE_AWARD_REQUEST',
  SINGLE_ACTIVATE_AWARD_SUCCESS: 'SINGLE_ACTIVATE_AWARD_SUCCESS',
  SINGLE_ACTIVATE_AWARD_FINISH: 'SINGLE_ACTIVATE_AWARD_FINISH',

  SINGLE_DEACTIVATE_AWARD_REQUEST: 'SINGLE_DEACTIVATE_AWARD_REQUEST',
  SINGLE_DEACTIVATE_AWARD_SUCCESS: 'SINGLE_DEACTIVATE_AWARD_SUCCESS',
  SINGLE_DEACTIVATE_AWARD_FINISH: 'SINGLE_DEACTIVATE_AWARD_FINISH',

  SORT_AWARD_START: 'SORT_AWARD_START',
  SORT_AWARD_SUCCESS: 'SORT_AWARD_SUCCESS',
  SORT_AWARD_FINISH: 'SORT_AWARD_FINISH',
  SORT_AWARD_RESET: 'SORT_AWARD_RESET',
}
