import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

import axios from 'axios'
import {isEmpty} from 'lodash'
import {FiEye} from 'react-icons/fi'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import DeleteModal from '../../common/components/deleteModal'
import * as agentRedux from '../redux'
import ViewDetailsModal from './ViewDetailsModal'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const AgentManager = () => {
  const dispatch = useDispatch()

  const allList = useSelector((state: any) => state.agent)
  const {loading} = useSelector((state: any) => state.agent)

  useEffect(() => {
    dispatch(agentRedux.actions.getList())
  }, [])

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  //for view details modal
  const [selectedApplicationId, setSelectedApplicationId] = useState()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  const downloadAgentList = () => {
    const AGENT_DATA_EXPORT_API = `${API_URL}/agent/export?search=${params.search}&fileFormat=csv`
    axios(AGENT_DATA_EXPORT_API, {
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `allList.csv`)
      document.body.appendChild(link)
      link.click()
    })
  }

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>View</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setShowDetailsModal(true)
                setSelectedApplicationId(rowData?.id)
              }}
              data-cy='edit-button'
              icon={<FiEye />}
            />
          </Whisper>
        </CheckPermissions>
      </Cell>
    )
  }

  const Cell = Table.Cell

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Full Name',
      dataKey: 'full_name',
      flexGrow: 1,
      cell: <Cell dataKey='full_name' />,
      sortable: true,
    },
    {
      label: 'Email Address',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell dataKey='email' />,
      sortable: true,
    },
    {
      label: 'Mobile No',
      dataKey: 'mobile_number',
      flexGrow: 1,
      cell: <Cell dataKey='mobile_number' />,
      sortable: true,
    },
    {
      label: 'Agent Type',
      dataKey: 'agentType',
      flexGrow: 1,
      cell: <Cell dataKey='agentType' />,
      sortable: true,
    },
    {
      label: 'Message',
      dataKey: 'message',
      flexGrow: 1,
      cell: <Cell dataKey='message' />,
      sortable: true,
    },
    {
      label: 'Remittance',
      dataKey: 'remittance',
      flexGrow: 1,
      cell: <Cell dataKey='remittance' />,
      sortable: true,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  const data = allList?.data?.agent
    ? allList?.data?.agent?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item.id,
        full_name: item.full_name,
        email: item.email,
        mobile_number: item.mobile_number,
        agentType: item.agentType?.name || '-',
        message: item.message,
      }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleDelete = () => {
    let agentSelectedId = checkedValues?.map((value) => ({id: value}))
    dispatch(agentRedux?.actions.deleteAgent(agentSelectedId))
    dispatch(agentRedux?.actions.getList(params))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(agentRedux?.actions.getList(params))
  }

  const handleGetAllData = () => {
    setParams({...params, limit: data?.meta?.total})
    dispatch(agentRedux?.actions.getList(params))
  }
  
  useEffect(() => {
    dispatch(agentRedux?.actions.getList(params))
  }, [params])

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Agent List'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          addShow={false}
          handleAlertOpen={handleAlertOpen}
          exportShow={true}
          importShow={false}
          deleteShow={true}
          data={data}
          exportButtonName='Export Data'
          handleExport={downloadAgentList}
          addNoteShow={false}
          toggleMultipleShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={allList?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}

        {showDetailsModal && (
          <ViewDetailsModal
            handleClose={() => setShowDetailsModal(false)}
            data={data?.filter((d: any) => d.id === selectedApplicationId)[0]}
          />
        )}
      </div>
    </>
  )
}

export default AgentManager
