import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteServiceTypeModel} from '../Model'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const ENQUIRY_TYPE = `${API_URL}/query-type`

export const service = {
  getEnquiryType: (params: ParamsModel) => {
    return axios.get(ENQUIRY_TYPE, {params})
  },
  getAllEnquiryType: (params: any = {}) => {
    return axios.get(`${ENQUIRY_TYPE}/list`, {params})
  },

  addEnquiryType: (data: any) => {
    return axios.post(ENQUIRY_TYPE, data)
  },

  updateEnquiryType: (body: ServiceTypeModel, id: string) => {
    return axios.patch(`${ENQUIRY_TYPE}/${id}`, body)
  },

  deleteEnquiryType: (data: DeleteServiceTypeModel) => {
    return axios.delete(`${ENQUIRY_TYPE}`, {data})
  },

  enableEnquiryType: (data: Array<string>) => {
    const selectedServiceType = {
      queryTypeId: data,
    }
    return axios.patch(`${ENQUIRY_TYPE}/enable`, selectedServiceType)
  },

  disableEnquiryType: (data: Array<string>) => {
    const selectedServiceType = {
      queryTypeId: data,
    }
    return axios.patch(`${ENQUIRY_TYPE}/disable`, selectedServiceType)
  },

  singleEnableEnquiryType: (data: Array<string>) => {
    const selectedServiceType = {
      queryTypeId: [data],
    }
    return axios.patch(`${ENQUIRY_TYPE}/enable`, selectedServiceType)
  },

  singleDisableEnquiryType: (data: Array<string>) => {
    const selectedServiceType = {
      queryTypeId: [data],
    }
    return axios.patch(`${ENQUIRY_TYPE}/disable`, selectedServiceType)
  },
}
