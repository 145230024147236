import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteBaseRateModel, BaseRateModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_BASE_RATE = `${API_URL}/baserate-interestspread`
export const GET_BASE_RATE_LOAN_FILE = `${API_URL}/baserate-interestspread/baserateExport`
export const GET_INTEREST_SPREAD_FILE = `${API_URL}/baserate-interestspread/interestspreadExport`

export const service = {
  getBaseRate: (params: ParamsModel) => {
    return axios.get(GET_BASE_RATE, {params})
  },

  //Get file of loan interest
  getBaseRateFile: () => {
    return axios.get(`${GET_BASE_RATE_LOAN_FILE}`).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `base_rate.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
  //Get file of deposit interest
  getInterestSpreadFile: () => {
    return axios.get(`${GET_INTEREST_SPREAD_FILE}`).then((response) => {
      const blob = new Blob([response?.data])
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `interest_spread.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  addBaseRate: (data: any) => {
    return axios.post(GET_BASE_RATE, data)
  },

  updateBaseRate: (body: BaseRateModel, id: string) => {
    return axios.post(`${GET_BASE_RATE}/${id}`, body)
  },

  deleteBaseRate: (data: DeleteBaseRateModel) => {
    return axios.delete(GET_BASE_RATE, {data})
  },

  enableBaseRate: (data: Array<string>) => {
    const selectedBaseRate = {
      baseRateId: data,
    }
    return axios.patch(`${GET_BASE_RATE}/enable`, selectedBaseRate)
  },

  disableBaseRate: (data: Array<string>) => {
    const selectedBaseRate = {
      baseRateId: data,
    }
    return axios.patch(`${GET_BASE_RATE}/disable`, selectedBaseRate)
  },

  singleEnableBaseRate: (data: Array<string>) => {
    const selectedGallery = {
      baseRateId: [data],
    }
    return axios.patch(`${GET_BASE_RATE}/enable`, selectedGallery)
  },

  singleDisableBaseRate: (data: Array<string>) => {
    const selectedBaseRate = {
      baseRateId: [data],
    }
    return axios.patch(`${GET_BASE_RATE}/disable`, selectedBaseRate)
  },
}
