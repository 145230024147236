import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getSpecificBenefitManager: (id: string = '') => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_START,
    payload: id,
  }),

  getSpecificBenefitManagerSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_SUCCESS,
    payload: data,
  }),
  getSpecificBenefitManagerFinish: () => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_FINISH,
  }),
  getSpecificBenefitManagerError: (error: unknown) => ({
    type: actionTypes.GET_SPECIFIC_BENEFIT_MANAGER_FINISH,
    payload: {error},
  }),

  getBenefitManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BENEFIT_MANAGER_START,
    payload: params,
  }),

  getBenefitManagerSuccess: (data: any) => ({
    type: actionTypes.GET_BENEFIT_MANAGER_SUCCESS,
    payload: data,
  }),
  getBenefitManagerFinish: () => ({
    type: actionTypes.GET_BENEFIT_MANAGER_FINISH,
  }),
  getBenefitManagerError: (error: unknown) => ({
    type: actionTypes.GET_BENEFIT_MANAGER_FINISH,
    payload: {error},
  }),

  addBenefitManager: (data: any) => ({
    type: actionTypes.ADD_BENEFIT_MANAGER_START,
    payload: data,
  }),
  addBenefitManagerSuccess: (task: any) => ({
    type: actionTypes.ADD_BENEFIT_MANAGER_SUCCESS,
    payload: task,
  }),
  addBenefitManagerFinish: () => ({
    type: actionTypes.ADD_BENEFIT_MANAGER_FINISH,
  }),
  addBenefitManagerReset: () => ({
    type: actionTypes.ADD_BENEFIT_MANAGER_RESET,
  }),

  updateBenefitManager: (data: any, id: string) => ({
    type: actionTypes.UPDATE_BENEFIT_MANAGER_START,
    payload: {data, id},
  }),
  updateBenefitManagerSuccess: (task: any) => ({
    type: actionTypes.UPDATE_BENEFIT_MANAGER_SUCCESS,
    payload: task,
  }),
  updateBenefitManagerFinish: () => ({
    type: actionTypes.UPDATE_BENEFIT_MANAGER_FINISH,
  }),
  updateBenefitManagerReset: () => ({
    type: actionTypes.UPDATE_BENEFIT_MANAGER_RESET,
  }),

  deleteBenefitManager: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_MANAGER_START,
    payload: {benefitManagerId: data},
  }),
  deleteBenefitManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_BENEFIT_MANAGER_SUCCESS,
    payload: data,
  }),
  deleteBenefitManagerFinish: () => ({
    type: actionTypes.DELETE_BENEFIT_MANAGER_FINISH,
  }),
  activateBenefitManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_REQUEST,
    payload: {benefitManagerId: id},
  }),
  deactivateBenefitManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_REQUEST,
    payload: {benefitManagerId: id},
  }),
  singleActivateBenefitManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_BENEFIT_MANAGER_REQUEST,
    payload: {benefitManagerId: [id]},
  }),
  singleDeactivateBenefitManager: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_BENEFIT_MANAGER_REQUEST,
    payload: {benefitManagerId: [id]},
  }),

  sortBenefitManager: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_MANAGER_START,
    payload: data,
  }),
  sortBenefitManagerSuccess: (data: any) => ({
    type: actionTypes.SORT_BENEFIT_MANAGER_SUCCESS,
    payload: data,
  }),
  sortBenefitManagerFinish: () => ({
    type: actionTypes.SORT_BENEFIT_MANAGER_FINISH,
  }),
  sortBenefitManagerReset: () => ({
    type: actionTypes.SORT_BENEFIT_MANAGER_RESET,
  }),
}
