import axios from "axios";
import { ParamsModel } from "src/app/modules/common/Model";
import { PropertyTypeModel, SortPropertyTypeModel } from "../Model/Model";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;

export const PROPERTY_TYPE_API = `${API_URL}/property-type`;

export const service = {
  getPropertyType: (params: ParamsModel) => {
    return axios.get(PROPERTY_TYPE_API, { params });
  },
  addPropertyType: (data: any) => {
    return axios.post(PROPERTY_TYPE_API, data);
  },

  updatePropertyType: (body: PropertyTypeModel, id: string) => {
    return axios.put(`${PROPERTY_TYPE_API}/${id}`, body);
  },

  deletePropertyType: (data: { id: string }) => {
    return axios.delete(`${PROPERTY_TYPE_API}/bulk-delete`, { data });
  },

  enablePropertyType: (data: Array<string>) => {
    const selectedPropertyType = {
      propertyTypeId: data,
    };
    return axios.patch(`${PROPERTY_TYPE_API}/enable`, selectedPropertyType);
  },

  disablePropertyType: (data: Array<string>) => {
    const selectedPropertyType = {
      propertyTypeId: data,
    };
    return axios.patch(`${PROPERTY_TYPE_API}/disable`, selectedPropertyType);
  },

  singleEnablePropertyType: (data: Array<string>) => {
    const selectedPropertyType = {
      propertyTypeId: [data],
    };
    return axios.patch(`${PROPERTY_TYPE_API}/enable`, selectedPropertyType);
  },

  singleDisablePropertyType: (data: Array<string>) => {
    const selectedPropertyType = {
      propertyTypeId: [data],
    };
    return axios.patch(`${PROPERTY_TYPE_API}/disable`, selectedPropertyType);
  },
  sortPropertyType: (body: SortPropertyTypeModel) => {
    return axios.patch(`${PROPERTY_TYPE_API}/sort`, body);
  },
};
