import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteVacancyModel, VacancyModel} from '../Model'
import {actionTypes} from './constants'
// import {INewsState} from './reducer'
// import {NewsModel} from '../Model/NewsModel'
export const actions = {
  // GET NEWS
  getVacancy: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_VACANCY_START,
    payload: params,
  }),

  getVacancySuccess: (data: any) => ({
    type: actionTypes.GET_VACANCY_SUCCESS,
    payload: data,
  }),
  getVacancyFinish: () => ({
    type: actionTypes.GET_VACANCY_FINISH,
  }),
  getVacancyError: (error: unknown) => ({
    type: actionTypes.GET_VACANCY_FINISH,
    payload: {error},
  }),

  // create NEWS
  addVacancy: (data: any) => ({
    type: actionTypes.ADD_VACANCY_START,
    payload: data,
  }),
  addVacancySuccess: (task: any) => ({
    type: actionTypes.ADD_VACANCY_SUCCESS,
    payload: task,
  }),
  addVacancyFinish: () => ({
    type: actionTypes.ADD_VACANCY_FINISH,
  }),
  addVacancyReset: () => ({
    type: actionTypes.ADD_VACANCY_RESET,
  }),

  // update NEWS

  updateVacancy: (data: any, id: string) => ({
    type: actionTypes.UPDATE_VACANCY_START,
    payload: {data, id},
  }),
  updateVacancySuccess: (task: any) => ({
    type: actionTypes.UPDATE_VACANCY_SUCCESS,
    payload: task,
  }),
  updateVacancyFinish: () => ({
    type: actionTypes.UPDATE_VACANCY_FINISH,
  }),
  updateVacancyReset: () => ({
    type: actionTypes.UPDATE_VACANCY_RESET,
  }),

  // DELETE qualification
  deleteVacancy: (data: DeleteVacancyModel[]) => ({
    type: actionTypes.DELETE_VACANCY_START,
    payload: {vacancyId: data},
  }),
  deleteVacancySuccess: (data: any) => ({
    type: actionTypes.DELETE_VACANCY_SUCCESS,
    payload: data,
  }),
  deleteVacancyFinish: () => ({
    type: actionTypes.DELETE_VACANCY_FINISH,
  }),
  // Active Vacancy
  activateVacancy: (id: {[key: string]: any}) => ({
    type: actionTypes.SINGLE_ACTIVATE_VACANCY_REQUEST,
    payload: {vacancyId: id},
  }),
  enableActivateSuccess: (task: any) => ({
    type: actionTypes.ENABLE_VACANCY_SUCCESS,
    payload: task,
  }),
  enableVacancyFinish: () => ({
    type: actionTypes.ENABLE_VACANCY_FINISH,
  }),
  //  deactive Qulification
  deactivateVacancy: (id: {[key: string]: any}) => ({
    type: actionTypes.DISABLE_VACANCY_REQUEST,
    payload: {vacancyId: id},
  }),
  enableDeactivateSuccess: (task: any) => ({
    type: actionTypes.DISABLE_VACANCY_SUCCESS,
    payload: task,
  }),
  enableDeactivateFinish: () => ({
    type: actionTypes.DISABLE_VACANCY_FINISH,
  }),
  // single active NEWS
  singleActivateVacancy: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_VACANCY_REQUEST,
    payload: {vacancyId: [id]},
  }),
  singleActivateVacancySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_VACANCY_SUCCESS,
    payload: task,
  }),
  singleActivateVacancyFinish: () => ({
    type: actionTypes.SINGLE_ACTIVATE_VACANCY_SUCCESS,
  }),
  // single deactive NEWS
  singleDeactivateVacancy: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_VACANCY_REQUEST,
    payload: {vacancyId: [id]},
  }),
  singleDeactivateVacancySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_VACANCY_SUCCESS,
    payload: task,
  }),
  singleDeactivateVacancyFinish: () => ({
    type: actionTypes.SINGLE_DEACTIVATE_VACANCY_FINISH,
  }),
}
