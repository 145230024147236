import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import Tag from 'rsuite/Tag'

// includes
import * as collegeDetailRedux from '../index'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { ItemType, StateParamsModel } from 'src/app/modules/common/Model'
import { SortType } from 'rsuite-table/lib/@types/common'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import AddCollegeDetail from './AddCollegeDetail'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'

const CollegeDetailComponent = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const {
    data,
    loading,

    singleEnableSuccess,
    singleDisableSuccess,
    enableSuccess,
    disableSuccess,
    toggleLoading,
    success,
    deleteSuccess,
    CollegeDetailList,
    sortCollegeDetailData,
  } = useSelector((state: any) => state.collegeDetail)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
    orderBy: '',
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()

  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [openSortModal, setOpenSortModal] = useState(false)

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const enableCollegeDetailData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(collegeDetailRedux.actions.enableCollegeDetail(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableCollegeDetailData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(collegeDetailRedux.actions.disableCollegeDetail(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              data-cy='edit-button'
              icon={<Edit2 />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let branchId = [rowData.id]
                setCheckedValues(branchId)
              }}
              icon={<TrashIcon />}
              data-cy='delete-button'
            />
          </Whisper>
        </CheckPermissions>
        {/* <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={toggleLoading}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper> */}
      </Cell>
    )
  }

  const handleToggleAction = (data: { [key: string]: string }) => {
    data?.status
      ? dispatch(collegeDetailRedux.actions.singleDisableCollegeDetail({ id: data.id }))
      : dispatch(collegeDetailRedux.actions.singleEnableCollegeDetail({ id: data.id }))
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Title',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Slug',
      dataKey: 'slug',
      flexGrow: 1,
      cell: <Cell dataKey='slug' />,
      sortable: true,
    },

    // {
    //   label: 'Status',
    //   dataKey: 'status',
    //   width: 85,
    //   cell: (
    //     <Cell>
    //       {(rowData) =>
    //         rowData?.status ? (
    //           <Tag color='green' size='sm'>
    //             Active
    //           </Tag>
    //         ) : (
    //           <Tag color='red' size='sm'>
    //             Inactive
    //           </Tag>
    //         )
    //       }
    //     </Cell>
    //   ),
    //   sortable: false,
    // },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(collegeDetailRedux.actions.getCollegeDetail(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('College detail deleted successfully')
    }
    if (enableSuccess) {
      toast.success('College detail enabled successfully')
    }
    if (disableSuccess) {
      toast.success('College detail disabled successfully')
    }
    handleChecked([])
    dispatch(collegeDetailRedux?.actions.getCollegeDetail(params))
  }, [deleteSuccess, success, enableSuccess, disableSuccess])

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('College detail enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('College detail disabled successfully')
    }
    handleChecked([])
  }, [singleDisableSuccess, singleEnableSuccess])

  const CollegeDetailData = data?.aboutCollege
    ? data?.aboutCollege?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      id: item?.id,
      name: item?.title,
      slug: item?.slug,
      description: item?.description,
      typeId: item?.type?.id,
      image: item?.image,
      video: item?.video,
      videoSourceId: item?.videoSource?.id,
      first_button_text: item?.first_button_text,
      first_button_url: item?.first_button_url,
      status: item?.status,
      second_title: item?.second_title,
      second_subtitle: item?.second_subtitle,
      second_description: item?.second_description,
      second_typeId: item?.second_type?.id,
      second_image: item?.second_image,
      second_video: item?.second_video,
      second_videoSourceId: item?.second_videoSource?.id,
      second_button_text: item?.second_button_text,
      second_button_url: item?.second_button_url,
    }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let CollegeDetailId = checkedValues?.map((value) => ({ id: value }))
    dispatch(collegeDetailRedux?.actions?.deleteCollegeDetail(CollegeDetailId))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(collegeDetailRedux.actions.getCollegeDetail(params))
  }

  const handleGetAllData = () => {
    setParams({ ...params, limit: data?.meta?.total })
    dispatch(collegeDetailRedux.actions.getCollegeDetail(params))
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      aboutCollegeId: data?.map((item) => ({ id: item?.keyId })),
    }
    dispatch(collegeDetailRedux.actions.sortCollegeDetail(body))
  }

  useEffect(() => {
    if (!isEmpty(sortCollegeDetailData) && success) {
      toast.success('College Detail sorted successfully')
      dispatch(collegeDetailRedux.actions.sortCollegeDetailReset())
      setParams({ ...params, limit: 10 })
      dispatch(collegeDetailRedux.actions.getCollegeDetail(params))
      setOpenSortModal(false)
    }
  }, [sortCollegeDetailData, success])

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='College Detail'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          toggleMultipleShow={false}
          enableMultiple={enableCollegeDetailData}
          disableMultiple={disableCollegeDetailData}
          data={CollegeDetailData}
          sortShow={false}
          sortButtonName='Sort'
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleGetAllData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={CollegeDetailData}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
          />
          {open && (
            <AddCollegeDetail
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>

        {/* Delete Modal */}
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </>
  )
}

export default CollegeDetailComponent
