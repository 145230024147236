import { ErrorMessage, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import { StateParamsModel } from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import * as Yup from 'yup'
import * as interestRateRedux from '../../interestRate'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const AddInterestRate = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()

  const { loading, success } = useSelector((state: any) => state.interestRate)

  useEffect(() => {
    dispatch(interestRateRedux.actions.getInterestRate(params))
    dispatch(setting.actions.getSettingType())
  }, [params])

  useEffect(() => {
    if (success) {
      dispatch(interestRateRedux?.actions.getInterestRate(params))
      isEmpty(editSelectedData)
        ? toast.success('Interest Rate added successfully')
        : toast.success('Interest Rate edited successfully')
      dispatch(interestRateRedux?.actions.resetInterestRate())
      handleClose()
    }
  }, [success])

  // check backend Setting
  const loanInterestRateFileRequired = checkBackendSetting('loanInterestRateFileRequired')
  const depositInterestRateFileRequired = checkBackendSetting('depositInterestRateFileRequired')
  const hideMultipleInputBox = checkBackendSetting('hideMultipleInputBox')
  const showFixedLoanInterestRateFileUpload = checkBackendSetting(
    'showFixedLoanInterestRateFileUpload'
  )
  const showFixedDepositInterestRateFileUpload = checkBackendSetting(
    'showFixedDepositInterestRateFileUpload'
  )
  const fcyRateFileRequired = checkBackendSetting('fcyRateFileRequired')
  const lcyRateFileRequired = checkBackendSetting('lcyRateFileRequired')

  const FORM_VALIDATION = Yup.object().shape({
    date: Yup.date().required('Date is required'),
    fcyRateFile: Yup.string().when('fcyRateFileRequired', {
      is: (value: any) => !value && fcyRateFileRequired,
      then: Yup.string().required('FCY file is required'),
      otherwise: Yup.string(),
    }),
    lcyRateFile: Yup.string().when('lcyRateFileRequired', {
      is: (value: any) => !value && lcyRateFileRequired,
      then: Yup.string().required('LCY file is required'),
      otherwise: Yup.string(),
    }),
    loanInterestRateFile: Yup.string().when('loanInterestRateFileRequired', {
      is: (value: any) => !value && loanInterestRateFileRequired,
      then: Yup.string().required('Loan file is required'),
      otherwise: Yup.string(),
    }),
    depositInterestRateFile: Yup.string().when('depositInterestRateFileRequired', {
      is: (value: any) => !value && depositInterestRateFileRequired,
      then: Yup.string().required('Deposit file is required'),
      otherwise: Yup.string(),
    }),
    fixedLoanInterestRateFile: Yup.string().when('showFixedLoanInterestRateFileUpload', {
      is: (value: any) => !value && showFixedLoanInterestRateFileUpload,
      then: Yup.string().required('Fixed Loan file is required'),
      otherwise: Yup.string(),
    }),
    fixedDepositInterestRateFile: Yup.string().when('showFixedDepositInterestRateFileUpload', {
      is: (value: any) => !value && showFixedDepositInterestRateFileUpload,
      then: Yup.string().required('Fixed Deposit file is required'),
      otherwise: Yup.string(),
    }),
  })

  return (
    <div className='modal-container'>
      <Modal open={open} onClose={handleClose} className='w-75' enforceFocus={false} data-cy='modal'>
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Interest Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                date: '',
                loanInterestRateFile: '',
                depositInterestRateFile: '',
                fixedLoanInterestRateFile: '',
                fixedDepositInterestRateFile: '',
                fcyRateFile: '',
                lcyRateFile: '',
                firstSectionInfoText: '',
                loanTitle: '',
                loanTitleNp: '',
                depositTitle: '',
                depositTitleNp: '',
                fcyTitle: '',
                lcyTitle: '',
                fcyTitleNp: '',
                lcyTitleNp: '',
                fixedDepositTitle: '',
                fixedLoanTitle: '',
                secondSectionInfoText: '',
                thirdSectionHeaderOneText: '',
                thirdSectionHeaderTwoText: '',
                thirdSectionBodyOneText: '',
                thirdSectionBodyTwoText: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { setSubmitting }) => {
                const formData: any = new FormData()
                formData.append('date', moment(values?.date).format('YYYY-MM-DD'))
                loanInterestRateFileRequired &&
                  formData.append('loanInterestRateFile', values.loanInterestRateFile)
                depositInterestRateFileRequired &&
                  formData.append('depositInterestRateFile', values.depositInterestRateFile)
                showFixedLoanInterestRateFileUpload &&
                  formData.append('fixedLoanInterestRateFile', values.fixedLoanInterestRateFile)

                showFixedDepositInterestRateFileUpload &&
                  formData.append(
                    'fixedDepositInterestRateFile',
                    values.fixedDepositInterestRateFile
                  )
                fcyRateFileRequired && formData.append('fcyRateFile', values.fcyRateFile)
                lcyRateFileRequired && formData.append('lcyRateFile', values.lcyRateFile)

                formData.append('loanTitle', values.loanTitle ? values?.loanTitle : null)
                formData.append('loanTitleNp', values.loanTitleNp ? values?.loanTitleNp : null)
                formData.append('depositTitle', values.depositTitle ? values?.depositTitle : null)
                formData.append(
                  'depositTitleNp',
                  values.depositTitleNp ? values?.depositTitleNp : null
                )
                formData.append(
                  'fixedLoanTitle',
                  values.fixedLoanTitle ? values?.fixedLoanTitle : null
                )
                // formData.append(
                //   'fixedLoanTitle',
                //   values.fixedLoanTitle ? values.fixedLoanTitle : null
                // )

                formData.append('lcyTitle', values.lcyTitle ? values?.lcyTitle : null)
                formData.append('lcyTitleNp', values.lcyTitleNp ? values?.lcyTitleNp : null)
                formData.append('fcyTitle', values.fcyTitle ? values.fcyTitle : null)
                formData.append('fcyTitleNp', values.fcyTitleNp ? values.fcyTitleNp : null)
                formData.append(
                  'fixedDepositTitle',
                  values.fixedDepositTitle ? values.fixedDepositTitle : null
                )
                formData.append(
                  'firstSectionInfoText',
                  values.firstSectionInfoText ? values.firstSectionInfoText : null
                )
                formData.append(
                  'secondSectionInfoText',
                  values.secondSectionInfoText ? values.secondSectionInfoText : null
                )
                formData.append(
                  'thirdSectionHeaderOneText',
                  values.thirdSectionHeaderOneText ? values.thirdSectionHeaderOneText : null
                )
                formData.append(
                  'thirdSectionHeaderTwoText',
                  values.thirdSectionHeaderTwoText ? values.thirdSectionHeaderTwoText : null
                )
                formData.append(
                  'thirdSectionBodyOneText',
                  values.thirdSectionBodyOneText ? values.thirdSectionBodyOneText : null
                )
                formData.append(
                  'thirdSectionBodyTwoText',
                  values.thirdSectionBodyTwoText ? values.thirdSectionBodyTwoText : null
                )

                actionType === 'Edit' ? formData.append('_method', 'PUT') : null

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    interestRateRedux.actions.updateInterestRate(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(interestRateRedux.actions.addInterestRate(formData))
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                handleChange,
                handleBlur,
                errors,
                values,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'date',
                      'firstSectionInfoText',
                      'loanInterestRateFile',
                      'depositInterestRateFile',
                      'fixedLoanInterestRateFile',
                      'fixedDepositInterestRateFile',
                      'loanTitle',
                      'loanTitleNp',
                      'depositTitle',
                      'depositTitleNp',
                      'fixedDepositTitle',
                      'fixedLoanTitle',
                      'fcyTitle',
                      'lcyTitle',
                      'fcyTitleNp',
                      'lcyTitleNp',
                      'fcyRateFile',
                      'lcyRateFile',
                      'secondSectionInfoText',
                      'thirdSectionBodyOneText',
                      'thirdSectionBodyTwoText',
                      'thirdSectionHeaderOneText',
                      'thirdSectionHeaderTwoText',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'date',
                      editSelectedData?.date
                        ? moment(editSelectedData?.date, 'YYYY-MM-DD').toDate()
                        : null,
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <FormDatepicker
                          labelClassName='col-12'
                          containerClassName='col-6 '
                          label=' Date'
                          name='date'
                          placeholderText='Please select a date'
                          setFieldValue={setFieldValue}
                          value={values.date || false}
                          touched={touched}
                          required={true}
                          showIcon={true}
                          errors={errors}
                        />

                        <div style={{ display: 'flex' }}>
                          {loanInterestRateFileRequired && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2'
                              style={{ paddingBottom: '10px' }}
                            >
                              <label
                                className={`mb-1 fw-bolder fs-6 ${loanInterestRateFileRequired && 'required'
                                  } `}
                              >
                                Loan Interest Rate File(CSV, XLS, XLSX)
                              </label>
                              <input
                                type='file'
                                className='mx-2'
                                onBlur={handleBlur}
                                name='loanInterestRateFile'
                                onChange={(event) => {
                                  setFieldValue('loanInterestRateFile', event.target.files?.[0])
                                }}
                                required
                                data-cy='add-loaninterest-input'
                              />
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='loanInterestRateFile' component='div' />
                              </div>
                            </div>
                          )}
                          {depositInterestRateFileRequired && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2 '
                              style={{ paddingBottom: '10px' }}
                            >
                              <label
                                className={`mb-1 fw-bolder fs-6 ${depositInterestRateFileRequired && 'required'
                                  } `}
                              >
                                Deposit Interest Rate File(CSV, XLS, XLSX)
                              </label>
                              <input
                                type='file'
                                onBlur={handleBlur}
                                className='mx-2'
                                name='depositInterestRateFile'
                                onChange={(event) => {
                                  setFieldValue('depositInterestRateFile', event.target.files?.[0])
                                }}
                                required
                                data-cy='add-DepositInterestRate-input'
                              />
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='depositInterestRateFile' component='div' />
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ display: 'flex' }}>
                          {showFixedLoanInterestRateFileUpload && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2'
                              style={{ paddingBottom: '10px' }}
                            >
                              <label className='mb-1 fw-bolder fs-6 required w-100'>
                                Fixed Loan Interest Rate File(CSV, XLS, XLSX)
                              </label>
                              <input
                                type='file'
                                onBlur={handleBlur}
                                className='mx-2'
                                name='fixedLoanInterestRateFile'
                                onChange={(event) => {
                                  setFieldValue(
                                    'fixedLoanInterestRateFile',
                                    event.target.files?.[0]
                                  )
                                }}
                                required
                                data-cy='add-fixed-loan-input'
                              />
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='fixedLoanInterestRateFile' component='div' />
                              </div>
                            </div>
                          )}

                          {showFixedDepositInterestRateFileUpload && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2 '
                              style={{ paddingBottom: '10px' }}
                            >
                              <label className='mb-1 fw-bolder fs-6 required  w-100'>
                                Fixed Deposit Interest Rate File(CSV, XLS, XLSX)
                              </label>
                              <input
                                type='file'
                                onBlur={handleBlur}
                                className='mx-2'
                                name='fixedDepositInterestRateFile'
                                onChange={(event) => {
                                  setFieldValue(
                                    'fixedDepositInterestRateFile',
                                    event.target.files?.[0]
                                  )
                                }}
                                required
                                data-cy='add-fixed-deposit-input'

                              />
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='fixedDepositInterestRateFile' component='div' />
                              </div>
                            </div>
                          )}

                          {fcyRateFileRequired && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2 '
                              style={{ paddingBottom: '10px' }}
                            >
                              <label
                                className={`mb-1 fw-bolder fs-6 ${fcyRateFileRequired && 'required'
                                  } `}
                              >
                                FCY Rate File(CSV, XLS, XLSX)
                              </label>
                              <input
                                type='file'
                                onBlur={handleBlur}
                                className='mx-2'
                                name='fcyRateFile'
                                onChange={(event) => {
                                  setFieldValue('fcyRateFile', event.target.files![0])
                                }}
                                required
                                data-cy='add-FCY-input'
                              />
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='fcyRateFile' component='div' />
                              </div>
                            </div>
                          )}

                          {lcyRateFileRequired && (
                            <div
                              className='col-md-6 col-sm-6 col-xs-12 mb-2 '
                              style={{ paddingBottom: '10px' }}
                            >
                              <label
                                className={`mb-1 fw-bolder fs-6 ${lcyRateFileRequired && 'required'
                                  } `}
                              >
                                LCY Rate File(CSV, XLS, XLSX)
                              </label>
                              <input
                                type='file'
                                onBlur={handleBlur}
                                className='mx-2 lyc-fied-rate'
                                name='lcyRateFile'
                                onChange={(event) => {
                                  setFieldValue('lcyRateFile', event.target.files![0])
                                }}
                                required
                                data-cy='add-LCY-input'
                              />
                              <div className='text-danger fw-bolder small text-end'>
                                <ErrorMessage name='lcyRateFile' component='lyc-fied-rate' />
                              </div>
                            </div>
                          )}
                        </div>

                        {!hideMultipleInputBox && (
                          <>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Loan Title '
                                label='Loan Title'
                                name='loanTitle'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Deposit Title '
                                label='Deposit Title'
                                name='depositTitle'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Loan Title (NP)'
                                label='Loan Title (NP)'
                                name='loanTitleNp'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Deposit Title (NP)'
                                label='Deposit Title (NP)'
                                name='depositTitleNp'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            {lcyRateFileRequired && (
                              <>
                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    placeholder='LCY Title (EN)'
                                    label='LCY Title (EN)'
                                    name='lcyTitle'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    placeholder='LCY Title (NP)'
                                    label='LCY Title (NP)'
                                    name='lcyTitleNp'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </>
                            )}

                            {fcyRateFileRequired && (
                              <>
                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    placeholder='FCY Title (EN)'
                                    label='FCY Title (EN)'
                                    name='fcyTitle'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                                <div className='col-md-6 col-sm-6 col-xs-12'>
                                  <FormTextBox
                                    type='text'
                                    labelClassName='col-md-12'
                                    containerClassName='col-md-12'
                                    placeholder='FCY Title (NP)'
                                    label='FCY Title (NP)'
                                    name='fcyTitleNp'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </>
                            )}

                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTinyMce
                                name='firstSectionInfoText'
                                handleChange={handleChange}
                                labelClassName='col-12'
                                label='First Section Info Text (EN)'
                                initialValue={
                                  !isEmpty(editSelectedData)
                                    ? editSelectedData?.firstSectionInfoText
                                    : ''
                                }
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTinyMce
                                name='secondSectionInfoText'
                                handleChange={handleChange}
                                label='Second Section Info Text (NP)'
                                labelClassName='col-12'
                                initialValue={
                                  !isEmpty(editSelectedData)
                                    ? editSelectedData?.secondSectionInfoText
                                    : ''
                                }
                              />
                            </div>

                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Third Section Header One '
                                label='Third Section Header One Text'
                                name='thirdSectionHeaderOneText'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Third Section Header Two'
                                label='Third Section Header Two Text'
                                name='thirdSectionHeaderTwoText'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Third Section Body One '
                                label='Third Section Body One Text'
                                name='thirdSectionBodyOneText'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                placeholder='Third Section Body Two '
                                label='Third Section Body Two Text'
                                name='thirdSectionBodyTwoText'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-5 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddInterestRate
