import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getIntroductorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getIntroductory, params)
    yield put(actions.getIntroductorySuccess(response?.data?.data))
    yield put(actions.getIntroductoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getIntroductoryFinish())
  }
}

function* addIntroductorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addIntroductory, body)
    yield put(actions.addIntroductorySuccess(response.data?.data))
    yield put(actions.addIntroductoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addIntroductoryFinish())
  }
}

function* updateIntroductorySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateIntroductory, body, action.payload?.id)
    yield put(actions.updateIntroductorySuccess(response.data?.data))
    yield put(actions.updateIntroductoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateIntroductoryFinish())
  }
}

function* deleteIntroductorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteIntroductory, body)
    yield put(actions.deleteIntroductorySuccess(response.data?.data))
    yield put(actions.deleteIntroductoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteIntroductoryFinish())
  }
}

function* singleActivateIntroductory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateIntroductory,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_INTRODUCTORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_INTRODUCTORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_INTRODUCTORY_FINISH})
  }
}

function* singleDeactivateIntroductory(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateIntroductory,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_INTRODUCTORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_INTRODUCTORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_INTRODUCTORY_FINISH})
  }
}

function* sortIntroductory(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortIntroductory, body)

    yield put({
      type: actionTypes.SORT_INTRODUCTORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_INTRODUCTORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_INTRODUCTORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_INTRODUCTORY_START, getIntroductorySaga)
  yield takeLatest(actionTypes.ADD_INTRODUCTORY_START, addIntroductorySaga)
  yield takeLatest(actionTypes.UPDATE_INTRODUCTORY_START, updateIntroductorySaga)
  yield takeLatest(actionTypes.DELETE_INTRODUCTORY_START, deleteIntroductorySaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_INTRODUCTORY_REQUEST, singleActivateIntroductory)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_INTRODUCTORY_REQUEST, singleDeactivateIntroductory)
  yield takeLatest(actionTypes.SORT_INTRODUCTORY_START, sortIntroductory)
}
