import { ErrorMessage, FormikErrors } from 'formik'
import { isEmpty } from 'lodash'
import { ChangeEvent } from 'react'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import { FormOptionModal } from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{ [key: string]: string }> | any
  touched: any
  values: { [key: string]: string } | any
  bannerOptions: FormOptionModal[]
  editSelectedData: { [key: string]: string }
  topMenuOptions: any
  productCategoryOptions: FormOptionModal[]
  productSubCategoryOptions: FormOptionModal[]
  productTagOptions: FormOptionModal[]
  productPopularityOptions: FormOptionModal[]
  leadFormOptions: FormOptionModal[]
  interestRateOptions: FormOptionModal[]
  reviewOptions: FormOptionModal[]
  competitorOptions: FormOptionModal[]
  setFieldValue: any
  bannerData: FormOptionModal[]
  breadcrumbOptions: FormOptionModal[]
}

export function GeneralComponent({
  handleChange,
  errors,
  touched,
  values,
  editSelectedData,
  bannerOptions,
  productCategoryOptions,
  productSubCategoryOptions,
  productTagOptions,
  productPopularityOptions,
  leadFormOptions,
  topMenuOptions,
  interestRateOptions,
  reviewOptions,
  competitorOptions,
  setFieldValue,
  bannerData,
  breadcrumbOptions,
}: Props) {
  const featuredOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ]

  const showBannerId = bannerOptions?.find(
    (item: FormOptionModal) => item.value === values.show_banner_id
  )

  const hideTopMenu = checkBackendSetting('hideTopMenuDropdown')
  const showProductTagDropdown = checkBackendSetting('showProductTagDropdown')
  const isProductTagRequired = checkBackendSetting('productTagRequired')
  const showLeadFormDropdown = checkBackendSetting('showLeadFormDropdown')
  const showProductPopularityDropdown = checkBackendSetting('showProductPopularityDropdown')
  const showInterestRateDropdown = checkBackendSetting('showInterestRateDropdown')
  const showReviewSectionDropdown = checkBackendSetting('showReviewSectionDropdown')
  const showDefaultReviewSection = checkBackendSetting('showDefaultReviewSection')
  const showCompetitorProduct = checkBackendSetting('showCompetitorProduct')
  const showAccountBenefitOption = checkBackendSetting('showAccountBenefitOption')
  const showBookAppointmentOption = checkBackendSetting('showBookAppointmentOption')
  const showProductSubCategory = checkBackendSetting('showProductSubCategory')
  const hideProductBanner = checkBackendSetting('showProductBanner')
  const showBreadCrumb = checkBackendSetting('showBreadCrumb')

  return (
    <>
      <div>
        {!hideTopMenu ? (
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select top menu'
                label='Top Menu'
                name='topmenuIds'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={topMenuOptions}
                multiple={true}
                values={values}
                setFieldValue={setFieldValue}
                required
              />
            </div>
          </div>
        ) : null}
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              placeholder='Enter Title (EN)'
              name='title'
              label='Title (EN)'
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              required={true}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              placeholder='Enter Title (NP)'
              name='title_np'
              label='Title (NP)'
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              onChange={handleChange}
              errors={errors}
              touched={touched}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormTextBox
              type='text'
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Enter Slug'
              label='Slug'
              name='slug'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              disabled={true}
              value={
                !isEmpty(editSelectedData)
                  ? editSelectedData?.slug
                  : values?.title.replace(/\s/g, '-').toLowerCase()
              }
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please Select Category'
              label='Category'
              name='productCategoryId'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={productCategoryOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormTinyMce
              containerClassName='col-md-12'
              label='Short Description (EN)'
              name='shortDescription'
              initialValue={!isEmpty(editSelectedData) ? editSelectedData?.shortDescription : ''}
              handleChange={handleChange}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormTinyMce
              containerClassName='col-md-12'
              label='Short Description (NP)'
              name='shortDescriptionNp'
              initialValue={!isEmpty(editSelectedData) ? editSelectedData?.shortDescriptionNp : ''}
              handleChange={handleChange}
            />
          </div>

          {/* <div className='col-md-6 col-xs-12'>
            <FormTinyMce
              containerClassName='col-md-12'
              label=' Description (EN)'
              name='description'
              initialValue={!isEmpty(editSelectedData) ? editSelectedData?.description : ''}
              handleChange={handleChange}
            />
          </div>
          <div className='col-md-6 col-xs-12'>
            <FormTinyMce
              containerClassName='col-md-12'
              label=' Description (NP)'
              name='DescriptionNp'
              initialValue={!isEmpty(editSelectedData) ? editSelectedData?.DescriptionNp : ''}
              handleChange={handleChange}
            />
          </div> */}

          <div className='d-flex flex-row justify-content-between align-items-center'>
            <div className='col-md-6 col-xs-12'>
              <FormTinyMce
                containerClassName='col-md-12'
                label='Description (EN)'
                name='description'
                initialValue={!isEmpty(editSelectedData) ? editSelectedData?.description : ''}
                handleChange={handleChange}
              />
            </div>

            <div className='col-md-6 col-xs-12 ms-2'>
              <FormTinyMce
                containerClassName='col-md-12'
                label='Description (NP)'
                name='descriptionNp'
                initialValue={!isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''}
                handleChange={handleChange}
              />
            </div>
          </div>

          <div className='col-md-6 col-xs-12'>
            <label className='form-label fw-bolder text-dark fs-6 required'>
              Featured in Homepage
            </label>

            <div className='d-flex ms-5'>
              {featuredOptions?.map((featuredInHomepage: { label: string; value: string }) => (
                <FormRadio
                  containerClassName=''
                  label='Featured in Homepage'
                  name='featuredInHomepage'
                  type='radio'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  checkBoxText={featuredInHomepage?.label}
                  value={featuredInHomepage.value}
                />
              ))}
            </div>
            <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
              <ErrorMessage name='status' component='div' className='field-error-message' />
            </div>
          </div>

          {showProductTagDropdown && (
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select service tags'
                label='Product Tags'
                name='tags'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={productTagOptions}
                multiple={true}
                values={values}
                required={isProductTagRequired}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {showLeadFormDropdown ? (
            <>
              <div className='col-md-6 col-xs-12'>
                <FormSelect
                  labelClassName='col-md-12'
                  containerClassName='col-md-12'
                  placeholder='Please select'
                  label='Show Lead Form'
                  name='productLeadFormId'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={leadFormOptions}
                  required
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
            </>
          ) : (
            ''
          )}

          {showCompetitorProduct && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Is this a competitor product ?'
                name='competitorStatusId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={competitorOptions}
                values={values}
                setFieldValue={setFieldValue}
                required
              />
            </div>
          )}

          {showProductSubCategory && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please Select Sub Category'
                label='Sub Category'
                name='productSubCategoryId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={productSubCategoryOptions}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {showDefaultReviewSection && (
            <div className='col-md-6 col-xs-12'>
              <FormTextBox
                placeholder='Default Review'
                name='review'
                label='Default Review'
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                required={showDefaultReviewSection}
              />
            </div>
          )}

          {showReviewSectionDropdown && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Show Reviews Section'
                name='reviewId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={reviewOptions}
                required
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {showInterestRateDropdown && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Show Interest Rate'
                name='interestRateId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={interestRateOptions}
                required
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {showProductPopularityDropdown && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Product Popularity'
                name='productPopularityId'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={productPopularityOptions}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {showBookAppointmentOption && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Show Book an Appointment Button ?'
                name='bookAppointment'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {showAccountBenefitOption && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Account Benefit Option?'
                name='accountBenefitOption'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {hideProductBanner && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Show Banner'
                name='show_banner_id'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={bannerOptions}
                required
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}
          {showBannerId?.systemName === 'yes' ? (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select banner'
                label='Banner'
                name='bannerId'
                onChange={handleChange}
                multiple={true}
                errors={errors}
                touched={touched}
                options={bannerData}
                required
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          ) : null}

          {showBreadCrumb && (
            <div className='col-md-6 col-xs-12'>
              <FormSelect
                labelClassName='col-md-12'
                containerClassName='col-md-12'
                placeholder='Please select'
                label='Show BreadCrumb'
                name='show_breadcrumb_id'
                onChange={handleChange}
                errors={errors}
                touched={touched}
                options={breadcrumbOptions}
                required
                values={values}
                setFieldValue={setFieldValue}
              />
            </div>
          )}

          {/* {topNavStatus === 'true' ? (
            <div className='row'>
              <h4>Sub Banner Blocks</h4>
              <FieldArray
                name='blocks'
                render={(arrayHelpers) => (
                  <div>
                    {values.blocks && values.blocks.length > 0
                      ? values.blocks.map((friend: any, index: any) => (
                          <div className='row' key={index}>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Label'
                                name={`blocks[${index}].label`}
                                label='Label'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                            <div className='col-md-6 col-xs-12'>
                              <FormTextBox
                                type='text'
                                placeholder='Enter Value'
                                name={`blocks[${index}].value`}
                                label='Value'
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            </div>

                            {index > 0 && (
                              <div className='col-md-12 col-sm-12 col-xs-12 justify-content-md-end text-end mb-2'>
                                <button
                                  type='button'
                                  className='row p-2 ps-5 pe-5 btn btn-secondary'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        ))
                      : null}
                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light pt-5'>
                      <button
                        className='p-2 ps-5 pe-5 btn btn-primary'
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            label: '',
                            value: '',
                          })
                        }
                      >
                        + Add More
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          ) : null} */}
        </div>
      </div>
    </>
  )
}
