import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteBankDetailModel, OptionModel, SortBankDetailModel} from '../Model'
import {BankDetailModel} from '../Model/BankDetailModel'
import {actionTypes} from './constants'
export const actions = {
  // get BankDetail
  getAllBankDetail: () => ({
    type: actionTypes.GET_ALL_BANK_DETAIL_START,
  }),
  getAllBankDetailSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_ALL_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  getAllBankDetailFinish: () => ({
    type: actionTypes.GET_ALL_BANK_DETAIL_FINISH,
  }),

  // get BankDetail DATA
  getBankDetail: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_BANK_DETAIL_START,
    payload: params,
  }),
  getBankDetailSuccess: (data: OptionModel) => ({
    type: actionTypes.GET_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  getBankDetailFinish: () => ({
    type: actionTypes.GET_BANK_DETAIL_FINISH,
  }),

  // create key
  addBankDetail: (data: BankDetailModel) => ({
    type: actionTypes.ADD_BANK_DETAIL_START,
    payload: data,
  }),
  addBankDetailSuccess: (task: any) => ({
    type: actionTypes.ADD_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  addBankDetailFinish: () => ({
    type: actionTypes.ADD_BANK_DETAIL_FINISH,
  }),
  resetBankDetail: () => ({
    type: actionTypes.RESET_BANK_DETAIL,
  }),

  //Update BankDetail
  updateBankDetail: (data: BankDetailModel, id: string) => ({
    type: actionTypes.UPDATE_BANK_DETAIL_START,
    payload: data,
    id,
  }),

  updateBankDetailSuccess: (data: BankDetailModel) => ({
    type: actionTypes.UPDATE_BANK_DETAIL_SUCCESS,
    payload: data,
  }),

  updateBankDetailFinish: () => ({
    type: actionTypes.UPDATE_BANK_DETAIL_FINISH,
  }),

  // delete key
  deleteBankDetail: (data: DeleteBankDetailModel[]) => ({
    type: actionTypes.DELETE_BANK_DETAIL_START,
    payload: {aboutbank: data},
  }),
  deleteBankDetailSuccess: (data: any) => ({
    type: actionTypes.DELETE_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  deleteBankDetailFinish: () => ({
    type: actionTypes.DELETE_BANK_DETAIL_FINISH,
  }),

  //Enable BankDetail
  enableBankDetail: (data: any) => ({
    type: actionTypes.ENABLE_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  enableBankDetailSuccess: (task: any) => ({
    type: actionTypes.ENABLE_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  enableBankDetailFinish: () => ({
    type: actionTypes.ENABLE_BANK_DETAIL_FINISH,
  }),

  //Disable BankDetail
  disableBankDetail: (data: any) => ({
    type: actionTypes.DISABLE_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  disableBankDetailSuccess: (task: any) => ({
    type: actionTypes.DISABLE_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  disableBankDetailFinish: () => ({
    type: actionTypes.DISABLE_BANK_DETAIL_FINISH,
  }),

  //Enable BankDetail
  singleEnableBankDetail: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  singleEnableBankDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  singleEnableBankDetailFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_BANK_DETAIL_FINISH,
  }),

  //Disable BankDetail
  singleDisableBankDetail: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_BANK_DETAIL_REQUEST,
    payload: {data},
  }),

  singleDisableBankDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_BANK_DETAIL_SUCCESS,
    payload: task,
  }),
  singleDisableBankDetailFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_BANK_DETAIL_FINISH,
  }),
  // sort
  sortBankDetail: (data: SortBankDetailModel) => ({
    type: actionTypes.SORT_BANK_DETAIL_START,
    payload: data,
  }),
  sortBankDetailSuccess: (data: Array<BankDetailModel>) => ({
    type: actionTypes.SORT_BANK_DETAIL_SUCCESS,
    payload: data,
  }),
  sortBankDetailFinish: () => ({
    type: actionTypes.SORT_BANK_DETAIL_FINISH,
  }),
  sortBankDetailReset: () => ({
    type: actionTypes.SORT_BANK_DETAIL_RESET,
  }),
}
