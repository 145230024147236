import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const MENU_BUTTON_MANAGER = `${API_URL}/menu-button`

export const service = {
  getAllMenuButton: (id: string) => {
    return axios.get(`${MENU_BUTTON_MANAGER}/list`)
  },
  getSpecificMenuButton: (id: string) => {
    return axios.get(`${MENU_BUTTON_MANAGER}/list/${id}`)
  },
  getMenuButton: (params: ParamsModel) => {
    return axios.get(MENU_BUTTON_MANAGER, {params})
  },

  addMenuButton: (data: any) => {
    return axios.post(MENU_BUTTON_MANAGER, data)
  },

  updateMenuButton: (body: any, id: string) => {
    return axios.put(`${MENU_BUTTON_MANAGER}/${id}`, body)
  },

  deleteMenuButton: (data: any) => {
    return axios.delete(`${MENU_BUTTON_MANAGER}/bulk-delete`, {data})
  },
  singleActivateMenuButton: (id: any) => {
    return axios.patch(`${MENU_BUTTON_MANAGER}/enable`, id)
  },

  singleDeactivateMenuButton: (id: any) => {
    return axios.patch(`${MENU_BUTTON_MANAGER}/disable`, id)
  },

  sortMenuButton: (body: any) => {
    return axios.patch(`${MENU_BUTTON_MANAGER}/sort`, body)
  },
}
