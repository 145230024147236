import {Action} from 'redux'
import { ReviewStatusModel} from '../Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialReviewStatusState: IReviewStatusState = {
  data: {
    reviewStatus: [],
    meta: {},
  },
  
 // reviewStatus: {reviewStatus: []},
  loading: false,
  success: false,
 
}

export interface IReviewStatusState {
  data: {
    reviewStatus: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  //reviewStatus?: {reviewStatus: ReviewStatusModel[] | any}
  loading: false
  success: false
 
}

export const reducer = (
  state: IReviewStatusState = initialReviewStatusState,
  action: ActionWithPayload<IReviewStatusState>
) => {
  switch (action.type) {

     //Get Review Status
     case actionTypes.GET_REVIEW_STATUS_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_REVIEW_STATUS_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_REVIEW_STATUS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

 
   
    default:
      return state
  }
}
