import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const DisputeOption = `${API_URL}/dispute-option`

export const service = {
  getDisputeOption: (params: ParamsModel) => {
    return axios.get(DisputeOption, {params})
  },

  addDisputeOption: (data: any) => {
    return axios.post(DisputeOption, data)
  },

  updateDisputeOption: (body: any, id: string) => {
    return axios.put(`${DisputeOption}/${id}`, body)
  },

  deleteDisputeOption: (data: DeleteModel) => {
    return axios.delete(`${DisputeOption}/bulk-delete`, {data})
  },
  singleActivateDisputeOption: (id: any) => {
    return axios.patch(`${DisputeOption}/enable`, id)
  },

  singleDeactivateDisputeOption: (id: any) => {
    return axios.patch(`${DisputeOption}/disable`, id)
  },

  sortDisputeOption: (body: any) => {
    return axios.patch(`${DisputeOption}/sort`, body)
  },
}
