import Modal from 'rsuite/Modal'
import FileManager from 'src/app/modules/common/components/fileManager/components'

interface Props {
  isOpen: boolean
  handleClose: () => void
  editorCallBack: any
  showImageOnly?: boolean
}
const MediaManagerModal = ({isOpen, handleClose, editorCallBack, showImageOnly}: Props) => {
  return (
    <>
      <Modal
        backdrop='static'
        role='alertdialog'
        open={isOpen}
        onClose={() => handleClose()}
        size='lg'
        className='mediaManagerModal'
        enforceFocus={false}
        id='mediaManager'
        data-cy='media-manager-modal-close-button'
      >
        <Modal.Header>
          <Modal.Title data-cy='media-manager-modal-title'>Media Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileManager
            isModal={true}
            showImageOnly={showImageOnly}
            editorCallBack={editorCallBack}
            handleCloseFileManagerModal={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MediaManagerModal
