export const actionTypes = {
  GET_SOCIAL_INTEGRATION_START: 'GET_SOCIAL_INTEGRATION_START',
  GET_SOCIAL_INTEGRATION_SUCCESS: 'GET_SOCIAL_INTEGRATION_SUCCESS',
  GET_SOCIAL_INTEGRATION_FINISH: 'GET_SOCIAL_INTEGRATION_FINISH',

  GET_NAV_VISIBILITY_START: 'GET_NAV_VISIBILITY_START',
  GET_NAV_VISIBILITY_SUCCESS: 'GET_NAV_VISIBILITY_SUCCESS',
  GET_NAV_VISIBILITY_FINISH: 'GET_NAV_VISIBILITY_FINISH',

  ADD_MODULE_START: 'ADD_MODULE_START',
  ADD_MODULE_SUCCESS: 'ADD_MODULE_SUCCESS',
  ADD_MODULE_FINISH: 'ADD_MODULE_FINISH',
  ADD_MODULE_RESET: 'ADD_MODULE_RESET',

  UPDATE_MODULE_START: 'UPDATE_MODULE_START',
  UPDATE_MODULE_SUCCESS: 'UPDATE_MODULE_SUCCESS',
  UPDATE_MODULE_FINISH: ' UPDATE_MODULE_FINISH',
  UPDATE_MODULE_RESET: 'UPDATE_MODULE_RESET',

  GET_MODULE_START: 'GET_MODULE_START',
  GET_MODULE_SUCCESS: 'GET_MODULE_SUCCESS',
  GET_MODULE_FINISH: 'GET_MODULE_FINISH',

  GET_ALL_MODULE_START: 'GET_ALL_MODULE_START',
  GET_ALL_MODULE_SUCCESS: 'GET_ALL_MODULE_SUCCESS',
  GET_ALL_MODULE_FINISH: 'GET_ALL_MODULE_FINISH',

  TOOGLE_NAVIGATION_STATUS_START: 'TOOGLE_NAVIGATION_STATUS_START',
  TOOGLE_NAVIGATION_STATUS_SUCCESS: 'TOOGLE_NAVIGATION_STATUS_SUCCESS',
  TOOGLE_NAVIGATION_STATUS_FINISH: ' TOOGLE_NAVIGATION_STATUS_FINISH',
  TOOGLE_NAVIGATION_STATUS_RESET: 'TOOGLE_NAVIGATION_STATUS_RESET',

  TOOGLE_SOCIAL_INT_STATUS_START: 'TOOGLE_SOCIAL_INT_STATUS_START',
  TOOGLE_SOCIAL_INT_STATUS_SUCCESS: 'TOOGLE_SOCIAL_INT_STATUS_SUCCESS',
  TOOGLE_SOCIAL_INT_STATUS_FINISH: ' TOOGLE_SOCIAL_INT_STATUS_FINISH',
  TOOGLE_SOCIAL_INT_STATUS_RESET: 'TOOGLE_SOCIAL_INT_STATUS_RESET',

  ENABLE_MODULE_STATUS_START: 'ENABLE_MODULE_STATUS_START',
  ENABLE_MODULE_STATUS_SUCCESS: 'ENABLE_MODULE_STATUS_SUCCESS',
  ENABLE_MODULE_STATUS_FINISH: ' ENABLE_MODULE_STATUS_FINISH',
  ENABLE_MODULE_STATUS_RESET: 'ENABLE_MODULE_STATUS_RESET',

  DISABLE_MODULE_STATUS_START: 'DISABLE_MODULE_STATUS_START',
  DISABLE_MODULE_STATUS_SUCCESS: 'DISABLE_MODULE_STATUS_SUCCESS',
  DISABLE_MODULE_STATUS_FINISH: ' DISABLE_MODULE_STATUS_FINISH',
  DISABLE_MODULE_STATUS_RESET: 'DISABLE_MODULE_STATUS_RESET',

  // sort 
  SORT_MODULE_MANAGER_START: 'SORT_MODULE_MANAGER_START',
  SORT_MODULE_MANAGER_SUCCESS: 'SORT_MODULE_MANAGER_SUCCESS',
  SORT_MODULE_MANAGER_FINISH: 'SORT_MODULE_MANAGER_FINISH',
  SORT_MODULE_MANAGER_RESET: 'SORT_MODULE_MANAGER_RESET',
}
