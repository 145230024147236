import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getAward: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_AWARD_START,
    payload: params,
  }),

  getAwardSuccess: (data: any) => ({
    type: actionTypes.GET_AWARD_SUCCESS,
    payload: data,
  }),
  getAwardFinish: () => ({
    type: actionTypes.GET_AWARD_FINISH,
  }),
  getAwardError: (error: unknown) => ({
    type: actionTypes.GET_AWARD_FINISH,
    payload: {error},
  }),

  addAward: (data: any) => ({
    type: actionTypes.ADD_AWARD_START,
    payload: data,
  }),
  addAwardSuccess: (task: any) => ({
    type: actionTypes.ADD_AWARD_SUCCESS,
    payload: task,
  }),
  addAwardFinish: () => ({
    type: actionTypes.ADD_AWARD_FINISH,
  }),
  addAwardReset: () => ({
    type: actionTypes.ADD_AWARD_RESET,
  }),

  updateAward: (data: any, id: string) => ({
    type: actionTypes.UPDATE_AWARD_START,
    payload: {data, id},
  }),
  updateAwardSuccess: (task: any) => ({
    type: actionTypes.UPDATE_AWARD_SUCCESS,
    payload: task,
  }),
  updateAwardFinish: () => ({
    type: actionTypes.UPDATE_AWARD_FINISH,
  }),
  updateAwardReset: () => ({
    type: actionTypes.UPDATE_AWARD_RESET,
  }),

  deleteAward: (data: any) => ({
    type: actionTypes.DELETE_AWARD_START,
    payload: {awardId: data},
  }),
  deleteAwardSuccess: (data: any) => ({
    type: actionTypes.DELETE_AWARD_SUCCESS,
    payload: data,
  }),
  deleteAwardFinish: () => ({
    type: actionTypes.DELETE_AWARD_FINISH,
  }),
  activateAward: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_AWARD_REQUEST,
    payload: {awardId: id},
  }),
  deactivateAward: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_AWARD_REQUEST,
    payload: {awardId: id},
  }),
  singleActivateAward: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_AWARD_REQUEST,
    payload: {awardId: [id]},
  }),
  singleDeactivateAward: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_AWARD_REQUEST,
    payload: {awardId: [id]},
  }),

  sortAward: (data: any) => ({
    type: actionTypes.SORT_AWARD_START,
    payload: data,
  }),
  sortAwardSuccess: (data: any) => ({
    type: actionTypes.SORT_AWARD_SUCCESS,
    payload: data,
  }),
  sortAwardFinish: () => ({
    type: actionTypes.SORT_AWARD_FINISH,
  }),
  sortAwardReset: () => ({
    type: actionTypes.SORT_AWARD_RESET,
  }),
}
