import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {IApplicantStatusState, IVacancyApplicantState} from './reducer'

export const actions = {
  // get Vacancy Applicant
  getVacancyApplicant: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_VACANCY_APPLICANT_START,
      payload: {params},
    }
  },
  getVacancyApplicantSuccess: (data: IVacancyApplicantState) => ({
    type: actionTypes.GET_VACANCY_APPLICANT_SUCCESS,
    payload: data,
  }),
  getVacancyApplicantError: (data: IVacancyApplicantState) => ({
    type: actionTypes.GET_VACANCY_APPLICANT_FINISH,
    payload: data,
  }),

   // get Vacancy Applicant
   getApplicantStatus: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_APPLICANT_STATUS_START,
      payload: {params},
    }
  },
  getApplicantStatusSuccess: (data: IApplicantStatusState) => ({
    type: actionTypes.GET_APPLICANT_STATUS_SUCCESS,
    payload: data,
  }),
  getApplicantStatusError: (data: IApplicantStatusState) => ({
    type: actionTypes.GET_APPLICANT_STATUS_FINISH,
    payload: data,
  }),

}