import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialState: IStateModel = {
  data: {
    agent: [],
    meta: {},
  },
  loading: false,
  success: false,
}

export interface IStateModel {
  data?: {
    agent?: {[key: string]: number | string}[]
    meta?: {[key: string]: number}
  }
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IStateModel = initialState,
  action: ActionWithPayload<IStateModel>
) => {
  switch (action.type) {
    case actionTypes.GET_LIST_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_LIST_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_LIST_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_LIST: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.DELETE_LIST_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_LIST_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_LIST_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    default:
      return state
  }
}
