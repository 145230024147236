import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialGalleryFileTypeState: IGalleryFileTypeState = {
  data: {
    galleryFileOption: [],
  
  },

  loading: false,
  success: false,
}

export interface IGalleryFileTypeState {
  data: {
    galleryFileOption: {[key: string]: number | string}[]
  
  }
  
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IGalleryFileTypeState = initialGalleryFileTypeState,
  action: ActionWithPayload<IGalleryFileTypeState>
) => {
  switch (action.type) {
    

    //GET Gallery File type Data
    case actionTypes.GET_GALLERY_FILE_TYPE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_GALLERY_FILE_TYPE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_GALLERY_FILE_TYPE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    
    default:
      return state
  }
}
