import {ParamsModel} from 'src/app/modules/common/Model'
import {StockRateModel} from '../Model'
import {actionTypes} from './constants'
import {IStockRateFileState, IStockRateState} from './reducer'

export const actions = {
  // get STOCK RATE
  getStockRate: (params: ParamsModel = {page: 1, limit: 10}) => {
    return {
      type: actionTypes.GET_STOCK_RATE_START,
      payload: {params},
    }
  },

  getStockRateSuccess: (data: IStockRateState) => ({
    type: actionTypes.GET_STOCK_RATE_SUCCESS,
    payload: data,
  }),
  getStockRateError: () => ({
    type: actionTypes.GET_STOCK_RATE_FINISH,
  }),

  //Get stock rate file csv slsx
  getStockRateFile: (fileType: string, params: ParamsModel) => {
    return {
      type: actionTypes.GET_STOCK_RATE_FILE_START,
      payload: {fileType, params},
    }
  },

  getStockRateFileSuccess: (data: IStockRateFileState) => ({
    type: actionTypes.GET_STOCK_RATE_FILE_SUCCESS,
    payload: data,
  }),
  getStockRateFileError: () => ({
    type: actionTypes.GET_STOCK_RATE_FILE_FINISH,
  }),

  // create Stock Rate
  CreateStockRate: (data: any) => ({
    type: actionTypes.CREATE_STOCK_RATE_START,
    payload: data,
  }),

  createStockRateSuccess: (task: any) => ({
    type: actionTypes.CREATE_STOCK_RATE_SUCCESS,
    payload: task,
  }),
  createStockRateFinish: (errorMsg: any) => ({
    type: actionTypes.CREATE_STOCK_RATE_FINISH,
    payload: errorMsg,
  }),
  createStockRateReset: () => ({
    type: actionTypes.CREATE_STOCK_RATE_RESET,
  }),

  //Activate deactivate
  activateStockRates: (data: any) => ({
    type: actionTypes.ACTIVATE_STOCK_RATE_REQUEST,
    payload: {data},
  }),

  singleActivateStockRate: (data: string) => ({
    type: actionTypes.SINGLE_ACTIVATE_STOCK_RATE_REQUEST,
    payload: data,
  }),

  singleDeactivateStockRate: (id: string) => ({
    type: actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_REQUEST,
    payload: id,
  }),

  deactivateStockRate: (data: any) => ({
    type: actionTypes.DEACTIVATE_STOCK_RATE_REQUEST,
    payload: {data},
  }),

  // IMPORT Stock RATE
  importStockRate: (data: any) => ({
    type: actionTypes.IMPORT_STOCK_RATE_START,
    payload: data,
  }),
  importStockRateSuccess: (task: any) => ({
    type: actionTypes.IMPORT_STOCK_RATE_SUCCESS,
    payload: task,
  }),
  importStockRateFinish: () => ({
    type: actionTypes.IMPORT_STOCK_RATE_FINISH,
  }),

  // update Stock Rate
  updateStockRate: (data: any, id: string) => ({
    type: actionTypes.UPDATE_STOCK_RATE_START,
    payload: {data, id},
  }),
  updateStockRateSuccess: (task: any) => ({
    type: actionTypes.UPDATE_STOCK_RATE_SUCCESS,
    payload: task,
  }),
  updateStockRateFinish: (errorMsg: any) => ({
    type: actionTypes.UPDATE_STOCK_RATE_FINISH,
    payload: errorMsg,
  }),
  updateStockRateReset: () => ({
    type: actionTypes.UPDATE_STOCK_RATE_RESET,
  }),

  //delete and reset
  deleteStockRate: (data: StockRateModel[]) => ({
    type: actionTypes.DELETE_STOCK_RATE_START,
    payload: {shareId: data},
  }),

  //Export Template
  exportTemplateFile: () => {
    return {
      type: actionTypes.EXPORT_SHARES_TEMPLATE_FILE_START,
    }
  },

  exportTemplateFileSuccess: (data: any) => ({
    type: actionTypes.EXPORT_SHARES_TEMPLATE_FILE_SUCCESS,
    payload: data,
  }),
  exportTemplateFileFinish: () => ({
    type: actionTypes.EXPORT_SHARES_TEMPLATE_FILE_FINISH,
  }),
}
