import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {
  SearchCategoryModel,
  SortSearchActionModel,
  SortSearchCategoryModel,
} from '../Model/SearchCategoryModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getSearchCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getSearchCategory, params)
    yield put(actions.getSearchCategorySuccess(response?.data?.data))
    yield put(actions.getSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSearchCategoryFinish())
  }
}

function* getAllSearchCategorySaga() {
  try {
    const response: ResponseModel = yield call(service.getAllSearchCategory)
    yield put(actions.getAllSearchCategorySuccess(response?.data?.data))
    yield put(actions.getAllSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllSearchCategoryFinish())
  }
}

function* addSearchCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addSearchCategory, body)
    yield put(actions.addSearchCategorySuccess(response.data?.data))
    yield put(actions.addSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addSearchCategoryFinish())
  }
}

function* enableSearchCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableSearchCategory, selectedUsers)
    yield put(actions.enableSearchCategorySuccess(response?.data))
    yield put(actions.enableSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableSearchCategoryFinish())
  }
}

function* disableSearchCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableSearchCategory, selectedUsers)
    yield put(actions.disableSearchCategorySuccess(response?.data))
    yield put(actions.disableSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableSearchCategoryFinish())
  }
}

function* singleEnableSearchCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableSearchCategory, selectedUsers)
    yield put(actions.singleEnableSearchCategorySuccess(response?.data))
    yield put(actions.singleEnableSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableSearchCategoryFinish())
  }
}

function* singleDisableSearchCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableSearchCategory, selectedUsers)
    yield put(actions.singleDisableSearchCategorySuccess(response?.data))
    yield put(actions.singleDisableSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableSearchCategoryFinish())
  }
}

function* updateSearchCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateSearchCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateSearchCategorySuccess(response.data?.data))
    yield put(actions.updateSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateSearchCategoryFinish())
  }
}

function* deleteSearchCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteSearchCategory, body)
    yield put(actions.deleteSearchCategorySuccess(response.data?.data))
    yield put(actions.deleteSearchCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteSearchCategoryFinish())
  }
}

function* sortSearchCategory(action: SortSearchActionModel) {
  try {
    const body: SortSearchCategoryModel = action.payload
    const response: ResponseModel = yield call(service.sortSearchCategory, body)

    yield put({
      type: actionTypes.SORT_SEARCH_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_SEARCH_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_SEARCH_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_SEARCH_CATEGORY_START, getSearchCategorySaga)
  yield takeLatest(actionTypes.GET_ALL_SEARCH_CATEGORY_START, getAllSearchCategorySaga)
  yield takeLatest(actionTypes.ADD_SEARCH_CATEGORY_START, addSearchCategorySaga)
  yield takeLatest(actionTypes.UPDATE_SEARCH_CATEGORY_START, updateSearchCategorySaga)
  yield takeLatest(actionTypes.DELETE_SEARCH_CATEGORY_START, deleteSearchCategorySaga)
  yield takeLatest(actionTypes.ENABLE_SEARCH_CATEGORY_REQUEST, enableSearchCategorySaga)
  yield takeLatest(actionTypes.DISABLE_SEARCH_CATEGORY_REQUEST, disableSearchCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_REQUEST,
    singleEnableSearchCategorySaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_REQUEST,
    singleDisableSearchCategorySaga
  )
  yield takeLatest(actionTypes.SORT_SEARCH_CATEGORY_START, sortSearchCategory)
}
