import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {COMMON_TYPES, PORTFOLIO_TYPES} from './constant'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {addPortfolioAPI, deletePortfolioAPI, getAllPortfolioAPI} from './service'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

function* getAllPortfolio(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)

    const response: ResponseModel = yield call(getAllPortfolioAPI, params)
    yield put({
      type: PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.CALL_OVER})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.CALL_OVER})
  }
}

function* addPortfolio(action: ActionModel) {
  try {
    const body = action.payload

    const response: ResponseModel = yield call(addPortfolioAPI, body)
    yield put({
      type: PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.CALL_OVER})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.CALL_OVER})
  }
}
function* deletePortfolio(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(deletePortfolioAPI, body)
    yield put({
      type: PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.SUCCESS,
    })
    yield put({type: PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.CALL_OVER})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.CALL_OVER})
  }
}

export function* saga() {
  yield takeLatest(PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.REQUEST, getAllPortfolio)
  yield takeLatest(PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.REQUEST, addPortfolio)
  yield takeLatest(PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.REQUEST, deletePortfolio)
}
