export const actionTypes = {
  // get appointment Request
  GET_APPOINTMENT_REQUEST_START: 'GET_APPOINTMENT_REQUEST_START',
  GET_APPOINTMENT_REQUEST_SUCCESS: 'GET_APPOINTMENT_REQUEST_SUCCESS',
  GET_APPOINTMENT_REQUEST_FINISH: 'GET_APPOINTMENT_REQUEST_FINISH',
  GET_APPOINTMENT_REQUEST_RESET: 'GET_APPOINTMENT_REQUEST_RESET',

  //Add appointment Request
  ADD_APPOINTMENT_REQUEST_REQUEST: 'ADD_APPOINTMENT_REQUEST_REQUEST',
  ADD_APPOINTMENT_REQUEST_SUCCESS: 'ADD_APPOINTMENT_REQUEST_SUCCESS',
  ADD_APPOINTMENT_REQUEST_FINISH: 'ADD_APPOINTMENT_REQUEST_FINISH',
  ADD_APPOINTMENT_REQUEST_RESET: 'ADD_APPOINTMENT_REQUEST_RESET',

  // update appointment Request
  UPDATE_APPOINTMENT_REQUEST_START: 'UPDATE_APPOINTMENT_REQUEST_START',
  UPDATE_APPOINTMENT_REQUEST_SUCCESS: 'UPDATE_APPOINTMENT_REQUEST_SUCCESS',
  UPDATE_APPOINTMENT_REQUEST_FINISH: 'UPDATE_APPOINTMENT_REQUEST_FINISH',
  UPDATE_APPOINTMENT_REQUEST_RESET: 'UPDATE_APPOINTMENT_REQUEST_RESET',

  // get File appointment Request
  GET_REQUEST_FILE_START: 'GET_REQUEST_FILE_START',
  GET_REQUEST_FILE_SUCCESS: 'GET_REQUEST_FILE_SUCCESS',
  GET_REQUEST_FILE_FINISH: 'GET_REQUEST_FILE_FINISH',
}
