import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteDisputeModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getDisputes: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_DISPUTES_START,
    payload: params,
  }),
  getDisputesSuccess: (data: any) => ({
    type: actionTypes.GET_DISPUTES_SUCCESS,
    payload: data,
  }),
  getDisputesFinish: () => ({
    type: actionTypes.GET_DISPUTES_FINISH,
  }),

  deleteDisputes: (data: DeleteDisputeModel[]) => ({
    type: actionTypes.DELETE_DISPUTES_START,
    payload: {disputeClaim: data},
  }),
  deleteDisputesSuccess: (data: any) => ({
    type: actionTypes.DELETE_DISPUTES_SUCCESS,
    payload: data,
  }),
  deleteDisputesFinish: () => ({
    type: actionTypes.DELETE_DISPUTES_FINISH,
  }),
}
