import {Action} from 'redux'
import {SearchCategoryModel} from '../Model/SearchCategoryModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialSearchCategoryState: ISearchCategoryState = {
  data: {
    searchCategory: [],
    meta: [],
  },
  sortSearchCategoryData: [],
  searchCategoryList: {searchCategory: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface ISearchCategoryState {
  data?: {
    searchCategory?: SearchCategoryModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortSearchCategoryData?: ISearchCategoryState[]
  searchCategoryList?: {searchCategory: SearchCategoryModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ISearchCategoryState = initialSearchCategoryState,
  action: ActionWithPayload<ISearchCategoryState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_SEARCH_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        searchCategoryList: action.payload,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_SEARCH_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET SearchCategory DATA
    case actionTypes.GET_SEARCH_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_SEARCH_CATEGORY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_SEARCH_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add SearchCategory
    case actionTypes.ADD_SEARCH_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_SEARCH_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_SEARCH_TAG: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update SearchCategory
    case actionTypes.UPDATE_SEARCH_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_SEARCH_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_SEARCH_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_SEARCH_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_SEARCH_CATEGORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_SEARCH_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_SEARCH_CATEGORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_SEARCH_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_SUCCESS: {
      const changedData: any = action.payload?.data?.searchCategory
      let newData = state?.data?.searchCategory?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, searchCategory: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_SUCCESS: {
      const changedData: any = action.payload?.data?.searchCategory
      let newData = state?.data?.searchCategory?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, searchCategory: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_SEARCH_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_SEARCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        sortSearchCategoryData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_SEARCH_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_SEARCH_CATEGORY_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortSearchCategoryData: [],
      }
    }

    default:
      return state
  }
}
