import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const DisputeCard = `${API_URL}/card-type`
export const DisputeType = `${API_URL}/dispute-claim/disputetype/list`

export const service = {
  getDisputeType: (params: ParamsModel) => {
    return axios.get(DisputeType, {params})
  },

  getDisputeCard: (params: ParamsModel) => {
    return axios.get(DisputeCard, {params})
  },

  addDisputeCard: (data: any) => {
    return axios.post(DisputeCard, data)
  },

  updateDisputeCard: (body: any, id: string) => {
    return axios.put(`${DisputeCard}/${id}`, body)
  },

  deleteDisputeCard: (data: DeleteModel) => {
    return axios.delete(`${DisputeCard}/bulk-delete`, {data})
  },
  singleActivateDisputeCard: (id: any) => {
    return axios.patch(`${DisputeCard}/enable`, id)
  },

  singleDeactivateDisputeCard: (id: any) => {
    return axios.patch(`${DisputeCard}/disable`, id)
  },

  sortDisputeCard: (body: any) => {
    return axios.patch(`${DisputeCard}/sort`, body)
  },
}
