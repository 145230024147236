import {isEmpty} from 'lodash'
import React, {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import {useSelector} from 'react-redux'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {imageBaseUrl} from 'src/cms/helpers/constants'

const CmsSplashScreenContext = createContext<Dispatch<SetStateAction<number>> | undefined>(
  undefined
)

const CmsSplashScreenProvider: FC = ({children}) => {
  const [count, setCount] = useState(0)
  let visible = count > 0

  useEffect(() => {
    const splashScreen = document.getElementById('splash-screen')

    // Show SplashScreen
    if (splashScreen && visible) {
      splashScreen.classList.remove('hidden')

      return () => {
        splashScreen.classList.add('hidden')
      }
    }

    // Hide SplashScreen
    let timeout: number
    if (splashScreen && !visible) {
      timeout = window.setTimeout(() => {
        splashScreen.classList.add('hidden')
      }, 3000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [visible])

  return (
    <CmsSplashScreenContext.Provider value={setCount}>{children}</CmsSplashScreenContext.Provider>
  )
}

const LayoutSplashScreen: FC<{visible?: boolean}> = ({visible = true}) => {
  // Everything are ready - remove splashscreen
  const setCount = useContext(CmsSplashScreenContext)

  const [splashLogo, setSplashLogo] = useState('')
  const [splashLogoAlt, setSplashLogoAlt] = useState('')
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  const splashScreen = document.getElementById('splash-screen')

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Theme settings']?.map((item: any) => {
          if (item?.name === 'loadingImage') {
            setSplashLogo(item?.value)
            setSplashLogoAlt(item?.name)
          }
        })
    }
  }, [settingTypeData])

  useEffect(() => {
    if (!isEmpty(splashLogo)) {
      let img = document.createElement('img')
      img.setAttribute('src', `${imageBaseUrl}/${splashLogo}`)
      img.setAttribute('alt', `${splashLogoAlt}`)
      img.setAttribute('class', 'h-30px')

      splashScreen?.appendChild(img)
    }
  }, [splashLogo])

  useEffect(() => {
    if (!visible) {
      return
    }

    if (setCount) {
      setCount((prev) => {
        return prev + 1
      })
    }

    return () => {
      if (setCount) {
        setCount((prev) => {
          return prev - 1
        })
      }
    }
  }, [setCount, visible])

  return (
    <>
      <div className='splash-screen' style={{height: '100vh'}}>
        <img
          src={`${imageBaseUrl}/${splashLogo}`}
          alt={`${imageBaseUrl}/${splashLogoAlt}`}
          className='h-120px'
        />
      </div>
    </>
  )
}

export {CmsSplashScreenProvider, LayoutSplashScreen}
