export const actionTypes = {
  // extended hours
  GET_NOTIFICATION_START: 'GET_NOTIFICATION_START',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FINISH: 'GET_NOTIFICATION_FINISH',

  // add branch
  ADD_NOTIFICATION_START: 'ADD_NOTIFICATION_START',
  ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
  ADD_NOTIFICATION_FINISH: 'ADD_NOTIFICATION_FINISH',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',

  // update NOTIFICATION
  UPDATE_NOTIFICATION_START: 'UPDATE_NOTIFICATION_START',
  UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_FINISH: 'UPDATE_NOTIFICATION_FINISH',

  // delete NOTIFICATION
  DELETE_NOTIFICATION_START: 'DELETE_NOTIFICATION_START',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FINISH: 'DELETE_NOTIFICATION_FINISH',
}
