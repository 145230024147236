import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const MID_MENU_MASTER = `${API_URL}/mid-menu`

export const service = {
  getAllMidMenu: (id: string) => {
    return axios.get(`${MID_MENU_MASTER}/list`)
  },
  getSpecificMidMenu: (id: string) => {
    return axios.get(`${MID_MENU_MASTER}/list/${id}`)
  },
  getMidMenu: (params: ParamsModel) => {
    return axios.get(MID_MENU_MASTER, {params})
  },

  addMidMenu: (data: any) => {
    return axios.post(MID_MENU_MASTER, data)
  },

  updateMidMenu: (body: any, id: string) => {
    return axios.put(`${MID_MENU_MASTER}/${id}`, body)
  },

  deleteMidMenu: (data: any) => {
    return axios.delete(`${MID_MENU_MASTER}/bulk-delete`, {data})
  },
  singleActivateMidMenu: (id: any) => {
    return axios.patch(`${MID_MENU_MASTER}/enable`, id)
  },

  singleDeactivateMidMenu: (id: any) => {
    return axios.patch(`${MID_MENU_MASTER}/disable`, id)
  },

  sortTutorialMidMenu: (body: any) => {
    return axios.patch(`${MID_MENU_MASTER}/sort`, body)
  },
}
