import {ParamsModel} from 'src/app/modules/common/Model'
import {AuctionTypeModel, SortAuctionTypeModel} from '../Model/Model'
import {actionTypes} from './constants'
export const actions = {
  // get AuctionType
  getAllAuctionType: () => ({
    type: actionTypes.GET_ALL_AUCTION_TYPE_START,
  }),
  getAllAuctionTypeSuccess: (data: AuctionTypeModel | any) => ({
    type: actionTypes.GET_ALL_AUCTION_TYPE_SUCCESS,
    payload: data,
  }),
  getAllAuctionTypeFinish: () => ({
    type: actionTypes.GET_ALL_AUCTION_TYPE_FINISH,
  }),

  // get AuctionType DATA
  getAuctionType: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_AUCTION_TYPE_START,
    payload: params,
  }),
  getAuctionTypeSuccess: (data: AuctionTypeModel | any) => ({
    type: actionTypes.GET_AUCTION_TYPE_SUCCESS,
    payload: data,
  }),
  getAuctionTypeFinish: () => ({
    type: actionTypes.GET_AUCTION_TYPE_FINISH,
  }),

  // create key
  addAuctionType: (data: AuctionTypeModel | any) => ({
    type: actionTypes.ADD_AUCTION_TYPE_START,
    payload: data,
  }),
  addAuctionTypeSuccess: (task: any) => ({
    type: actionTypes.ADD_AUCTION_TYPE_SUCCESS,
    payload: task,
  }),
  addAuctionTypeFinish: () => ({
    type: actionTypes.ADD_AUCTION_TYPE_FINISH,
  }),
  resetAuctionType: () => ({
    type: actionTypes.RESET_AUCTION_TYPE,
  }),

  //Update AuctionType
  updateAuctionType: (data: AuctionTypeModel | any, id: string) => ({
    type: actionTypes.UPDATE_AUCTION_TYPE_START,
    payload: data,
    id,
  }),

  updateAuctionTypeSuccess: (data: AuctionTypeModel | any) => ({
    type: actionTypes.UPDATE_AUCTION_TYPE_SUCCESS,
    payload: data,
  }),

  updateAuctionTypeFinish: () => ({
    type: actionTypes.UPDATE_AUCTION_TYPE_FINISH,
  }),

  // delete key
  deleteAuctionType: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_AUCTION_TYPE_START,
    payload: {auctionTypeId: data},
  }),
  deleteAuctionTypeSuccess: (data: any) => ({
    type: actionTypes.DELETE_AUCTION_TYPE_SUCCESS,
    payload: data,
  }),
  deleteAuctionTypeFinish: () => ({
    type: actionTypes.DELETE_AUCTION_TYPE_FINISH,
  }),

  //Enable AuctionType
  enableAuctionType: (data: any) => ({
    type: actionTypes.ENABLE_AUCTION_TYPE_REQUEST,
    payload: {data},
  }),

  enableAuctionTypeSuccess: (task: any) => ({
    type: actionTypes.ENABLE_AUCTION_TYPE_SUCCESS,
    payload: task,
  }),
  enableAuctionTypeFinish: () => ({
    type: actionTypes.ENABLE_AUCTION_TYPE_FINISH,
  }),

  //Disable AuctionType
  disableAuctionType: (data: any) => ({
    type: actionTypes.DISABLE_AUCTION_TYPE_REQUEST,
    payload: {data},
  }),

  disableAuctionTypeSuccess: (task: any) => ({
    type: actionTypes.DISABLE_AUCTION_TYPE_SUCCESS,
    payload: task,
  }),
  disableAuctionTypeFinish: () => ({
    type: actionTypes.DISABLE_AUCTION_TYPE_FINISH,
  }),

  //Enable AuctionType
  singleEnableAuctionType: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_AUCTION_TYPE_REQUEST,
    payload: {data},
  }),

  singleEnableAuctionTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_AUCTION_TYPE_SUCCESS,
    payload: task,
  }),
  singleEnableAuctionTypeFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_AUCTION_TYPE_FINISH,
  }),

  //Disable AuctionType
  singleDisableAuctionType: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_AUCTION_TYPE_REQUEST,
    payload: {data},
  }),

  singleDisableAuctionTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_AUCTION_TYPE_SUCCESS,
    payload: task,
  }),
  singleDisableAuctionTypeFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_AUCTION_TYPE_FINISH,
  }),

  // sort
  sortAuctionType: (data: SortAuctionTypeModel) => ({
    type: actionTypes.SORT_AUCTION_TYPE_START,
    payload: data,
  }),
  sortAuctionTypeSuccess: (data: Array<AuctionTypeModel>) => ({
    type: actionTypes.SORT_AUCTION_TYPE_SUCCESS,
    payload: data,
  }),
  sortAuctionTypeFinish: () => ({
    type: actionTypes.SORT_AUCTION_TYPE_FINISH,
  }),
  sortAuctionTypeReset: () => ({
    type: actionTypes.SORT_AUCTION_TYPE_RESET,
  }),
}
