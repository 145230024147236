import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CARD_MANAGER = `${API_URL}/card-manager`

export const service = {
  getSpecificCardManager: (id: string) => {
    return axios.get(`${CARD_MANAGER}/list/${id}`)
  },
  getCardManager: (params: ParamsModel) => {
    return axios.get(CARD_MANAGER, {params})
  },

  addCardManager: (data: any) => {
    return axios.post(CARD_MANAGER, data)
  },

  updateCardManager: (body: any, id: string) => {
    return axios.put(`${CARD_MANAGER}/${id}`, body)
  },

  deleteCardManager: (data: any) => {
    return axios.delete(`${CARD_MANAGER}/bulk-delete`, {data})
  },
  singleActivateCardManager: (id: any) => {
    return axios.patch(`${CARD_MANAGER}/enable`, id)
  },

  singleDeactivateCardManager: (id: any) => {
    return axios.patch(`${CARD_MANAGER}/disable`, id)
  },

  sortCardManager: (body: any) => {
    return axios.patch(`${CARD_MANAGER}/sort`, body)
  },
}
