import { FormikErrors } from 'formik'
import * as Yup from 'yup'
import { ChangeEvent, useEffect } from 'react'
import { FormOptionModal } from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

interface Props {
  handleChange: (e: ChangeEvent<any>) => void
  errors: FormikErrors<{ [key: string]: string }> | any
  touched: any
  albumOptionOptions: FormOptionModal[]
  albumDataOption?: FormOptionModal[]
  validationState: { [key: string]: Yup.StringSchema }
  setValidationState: any
  values: any
  setFieldValue: any
}

export function AlbumComponent({
  handleChange,
  errors,
  touched,
  values,
  albumDataOption,
  albumOptionOptions,
  validationState,
  setValidationState,
  setFieldValue,
}: Props) {
  const showBannerId = albumOptionOptions?.find(
    (item: FormOptionModal) => item.value === values.album_option_id
  )
  // useEffect(() => {
  //   showBannerId?.systemName === 'yes'
  //     ? setValidationState({
  //         ...validationState,
  //         albumSection: Yup.string().required('Banner  Data is required'),
  //       })
  //     : showBannerId?.systemName === 'no'
  //     ? setValidationState({
  //         ...validationState,
  //         albumSection: Yup.string().nullable(),
  //       })
  //     : null
  // }, [values.albumSection])

  return (
    <>
      <div className=''>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <FormSelect
              labelClassName='col-md-12'
              containerClassName='col-md-12'
              placeholder='Please select'
              label='Show album'
              name='album_option_id'
              onChange={handleChange}
              errors={errors}
              touched={touched}
              options={albumOptionOptions}
              required
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
          {showBannerId?.systemName === 'yes' ? (
            <>
              <div className='col-md-6 col-xs-12'>
                <FormSelect
                  labelClassName='col-md-12'
                  containerClassName='col-md-12'
                  placeholder='Please select'
                  label='Album'
                  name='album_id'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={albumDataOption}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}
