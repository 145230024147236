import {ParamsModel} from 'src/app/modules/common/Model'
import {
  AuctionImageTypeListModel,
  AuctionModel,
  AuctionTypeModel,
  PropertyTypeListModel,
  SortAuctionModel,
} from '../Model/Model'
import {actionTypes} from './constants'
export const actions = {
  // get Auction DATA
  getAuction: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_AUCTION_START,
    payload: params,
  }),
  getAuctionSuccess: (data: AuctionModel | any) => ({
    type: actionTypes.GET_AUCTION_SUCCESS,
    payload: data,
  }),
  getAuctionFinish: () => ({
    type: actionTypes.GET_AUCTION_FINISH,
  }),

  // create key
  addAuction: (data: AuctionModel | any) => ({
    type: actionTypes.ADD_AUCTION_START,
    payload: data,
  }),
  addAuctionSuccess: (task: any) => ({
    type: actionTypes.ADD_AUCTION_SUCCESS,
    payload: task,
  }),
  addAuctionFinish: () => ({
    type: actionTypes.ADD_AUCTION_FINISH,
  }),
  resetAuction: () => ({
    type: actionTypes.RESET_AUCTION,
  }),

  //Update Auction
  updateAuction: (data: AuctionModel | any, id: string) => ({
    type: actionTypes.UPDATE_AUCTION_START,
    payload: data,
    id,
  }),

  updateAuctionSuccess: (data: AuctionModel | any) => ({
    type: actionTypes.UPDATE_AUCTION_SUCCESS,
    payload: data,
  }),

  updateAuctionFinish: () => ({
    type: actionTypes.UPDATE_AUCTION_FINISH,
  }),

  // delete key
  deleteAuction: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_AUCTION_START,
    payload: {auctionId: data},
  }),
  deleteAuctionSuccess: (data: any) => ({
    type: actionTypes.DELETE_AUCTION_SUCCESS,
    payload: data,
  }),
  deleteAuctionFinish: () => ({
    type: actionTypes.DELETE_AUCTION_FINISH,
  }),

  //Enable Auction
  enableAuction: (data: any) => ({
    type: actionTypes.ENABLE_AUCTION_REQUEST,
    payload: {data},
  }),

  enableAuctionSuccess: (task: any) => ({
    type: actionTypes.ENABLE_AUCTION_SUCCESS,
    payload: task,
  }),
  enableAuctionFinish: () => ({
    type: actionTypes.ENABLE_AUCTION_FINISH,
  }),

  //Disable Auction
  disableAuction: (data: any) => ({
    type: actionTypes.DISABLE_AUCTION_REQUEST,
    payload: {data},
  }),

  disableAuctionSuccess: (task: any) => ({
    type: actionTypes.DISABLE_AUCTION_SUCCESS,
    payload: task,
  }),
  disableAuctionFinish: () => ({
    type: actionTypes.DISABLE_AUCTION_FINISH,
  }),

  //Enable Auction
  singleEnableAuction: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_AUCTION_REQUEST,
    payload: {data},
  }),

  singleEnableAuctionSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_AUCTION_SUCCESS,
    payload: task,
  }),
  singleEnableAuctionFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_AUCTION_FINISH,
  }),

  //Disable Auction
  singleDisableAuction: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_AUCTION_REQUEST,
    payload: {data},
  }),

  singleDisableAuctionSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_AUCTION_SUCCESS,
    payload: task,
  }),
  singleDisableAuctionFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_AUCTION_FINISH,
  }),

  // sort
  sortAuction: (data: SortAuctionModel) => ({
    type: actionTypes.SORT_AUCTION_START,
    payload: data,
  }),
  sortAuctionSuccess: (data: Array<AuctionModel>) => ({
    type: actionTypes.SORT_AUCTION_SUCCESS,
    payload: data,
  }),
  sortAuctionFinish: () => ({
    type: actionTypes.SORT_AUCTION_FINISH,
  }),
  sortAuctionReset: () => ({
    type: actionTypes.SORT_AUCTION_RESET,
  }),

  // auction type
  getAuctionType: () => ({
    type: actionTypes.GET_AUCTION_TYPE_LIST_START,
  }),
  getAuctionTypeSuccess: (data: AuctionTypeModel | any) => ({
    type: actionTypes.GET_AUCTION_TYPE_LIST_SUCCESS,
    payload: data,
  }),
  getAuctionTypeFinish: () => ({
    type: actionTypes.GET_AUCTION_TYPE_LIST_FINISH,
  }),

  // Property type
  getPropertyTypeList: () => ({
    type: actionTypes.GET_PROPERTY_TYPE_LIST_START,
  }),
  getPropertyTypeListSuccess: (data: PropertyTypeListModel | any) => ({
    type: actionTypes.GET_PROPERTY_TYPE_LIST_SUCCESS,
    payload: data,
  }),
  getPropertyTypeListFinish: () => ({
    type: actionTypes.GET_PROPERTY_TYPE_LIST_FINISH,
  }),

  // Auction Image type
  getAuctionImageTypeList: () => ({
    type: actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_START,
  }),
  getAuctionImageTypeListSuccess: (data: AuctionImageTypeListModel | any) => ({
    type: actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_SUCCESS,
    payload: data,
  }),
  getAuctionImageTypeListFinish: () => ({
    type: actionTypes.GET_AUCTION_IMAGE_TYPE_LIST_FINISH,
  }),
}
