import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialHistoryState: IHistoryState = {
  data: {
    history: [],
    meta: {},
  },
  fiscal_year: [],
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface IHistoryState {
  data?: {
    history?: []
    meta?: {[key: string]: number}
  }
  fiscal_year: {[key: string]: string}[]
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IHistoryState = initialHistoryState,
  action: ActionWithPayload<IHistoryState>
) => {
  switch (action.type) {
    case actionTypes.GET_HISTORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_HISTORY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_HISTORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_HISTORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_HISTORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_HISTORY_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_HISTORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_HISTORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_HISTORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_HISTORY_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_HISTORY_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_HISTORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_HISTORY_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_HISTORY_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_HISTORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_HISTORY_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_HISTORY_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_HISTORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_HISTORY_SUCCESS: {
      return {
        ...state,
        sortHistoryData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_HISTORY_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_HISTORY_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortHistoryData: [],
      }
    }
    case actionTypes.GET_FISCAL_YEAR_AD_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_FISCAL_YEAR_AD_SUCCESS: {
      return {...state, fiscal_year: action.payload?.fiscal_year, loading: false}
    }

    case actionTypes.GET_FISCAL_YEAR_AD_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
