import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteServicePartnerModel, ServicePartnerModel} from '../Model'
import {actionTypes} from './constants'
import { IServicePartnerRateFileState } from './reducer'
export const actions = {
  // get Service partneer
  getServicePartner: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_SERVICE_PARTNER_START,
    payload: params,
  }),
  getServicePartnerSuccess: (data: ServicePartnerModel) => ({
    type: actionTypes.GET_SERVICE_PARTNER_SUCCESS,
    payload: data,
  }),
  getServicePartnerFinish: () => ({
    type: actionTypes.GET_SERVICE_PARTNER_FINISH,
  }),

  // create service partner
  addServicePartner: (data: ServicePartnerModel | any) => ({
    type: actionTypes.ADD_SERVICE_PARTNER_START,
    payload: data,
  }),
  addServicePartnerSuccess: (task: any) => ({
    type: actionTypes.ADD_SERVICE_PARTNER_SUCCESS,
    payload: task,
  }),
  addServicePartnerFinish: () => ({
    type: actionTypes.ADD_SERVICE_PARTNER_FINISH,
  }),
  resetServicePartner: () => ({
    type: actionTypes.RESET_SERVICE_PARTNER_TYPE,
  }),

  //Update service partner
  updateServicePartner: (data: ServicePartnerModel | any, id: string) => ({
    type: actionTypes.UPDATE_SERVICE_PARTNER_START,
    payload: data,
    id,
  }),

  updateServicePartnerSuccess: (data: ServicePartnerModel) => ({
    type: actionTypes.UPDATE_SERVICE_PARTNER_SUCCESS,
    payload: data,
  }),

  updateServicePartnerFinish: () => ({
    type: actionTypes.UPDATE_SERVICE_PARTNER_FINISH,
  }),

  // delete service partner
  deleteServicePartner: (data: DeleteServicePartnerModel[]) => ({
    type: actionTypes.DELETE_SERVICE_PARTNER_START,
    payload: {servicePartnerId: data},
  }),
  deleteServicePartnerSuccess: (data: any) => ({
    type: actionTypes.DELETE_SERVICE_PARTNER_SUCCESS,
    payload: data,
  }),
  deleteServicePartnerFinish: () => ({
    type: actionTypes.DELETE_SERVICE_PARTNER_FINISH,
  }),

  //Enable Service Partner
  enableServicePartner: (data: any) => ({
    type: actionTypes.ENABLE_SERVICE_PARTNER_REQUEST,
    payload: {data},
  }),

  enableServicePartnerSuccess: (task: any) => ({
    type: actionTypes.ENABLE_SERVICE_PARTNER_SUCCESS,
    payload: task,
  }),
  enableServicePartnerFinish: () => ({
    type: actionTypes.ENABLE_SERVICE_PARTNER_FINISH,
  }),

  //Disable ServicePartner
  disableServicePartner: (data: any) => ({
    type: actionTypes.DISABLE_SERVICE_PARTNER_REQUEST,
    payload: {data},
  }),

  disableServicePartnerSuccess: (task: any) => ({
    type: actionTypes.DISABLE_SERVICE_PARTNER_SUCCESS,
    payload: task,
  }),
  disableServicePartnerFinish: () => ({
    type: actionTypes.DISABLE_SERVICE_PARTNER_FINISH,
  }),

  //Single Enable ServicePartner
  singleEnableServicePartner: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_REQUEST,
    payload: {data},
  }),

  singleEnableServicePartnerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_SUCCESS,
    payload: task,
  }),
  singleEnableServicePartnerFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_SERVICE_PARTNER_FINISH,
  }),

  //Single Disable ServicePartner
  singleDisableServicePartner: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_REQUEST,
    payload: {data},
  }),

  singleDisableServicePartnerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_SUCCESS,
    payload: task,
  }),
  singleDisableServicePartnerFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_SERVICE_PARTNER_FINISH,
  }),

  //Donload ServicePartner sample
  downloadServicePartnerSample: (fileType: string = 'csv') => ({
    type: actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_REQUEST,
    payload:fileType
  }),

  downloadServicePartnerSampleSuccess: (data: IServicePartnerRateFileState) => ({
    type: actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_SUCCESS,
    payload:data
  }),
  downloadServicePartnerSampleError: (data: IServicePartnerRateFileState) => ({
    type: actionTypes.DOWNLOAD_SERVICE_PARTNER_SAMPLE_ERROR,
    payload:data

  }),

  //Import ServicePartner
  importServicePartner: (data:any) => ({
    type: actionTypes.IMPORT_SERVICE_PARTNER_REQUEST,
    payload:data
  }),

  importServicePartnerSuccess: (data: any) => ({
    type: actionTypes.IMPORT_SERVICE_PARTNER_SUCCESS,
  }),
  importServicePartnerFinish: () => ({
    type: actionTypes.IMPORT_SERVICE_PARTNER_FINISH,
  }),

  // ServicePartner Countrylist
  servicePartnerCountryList: () => ({
    type: actionTypes.GET_SERVICE_PARTNER_COUNTRY_REQUEST,
  }),

  servicePartnerCountryListSuccess: (data: any) => ({
    type: actionTypes.GET_SERVICE_PARTNER_COUNTRY_SUCCESS,
    payload: data,
  }),
  servicePartnerCountryListFinish: () => ({
    type: actionTypes.GET_SERVICE_PARTNER_COUNTRY_FINISH,
  }),

  // ServicePartner Currencylist
  servicePartnerCurrencyList: () => ({
    type: actionTypes.GET_SERVICE_PARTNER_CURRENCY_REQUEST,
  }),

  servicePartnerCurrencyListSuccess: (data: any) => ({
    type: actionTypes.GET_SERVICE_PARTNER_CURRENCY_SUCCESS,
    payload: data,
  }),
  servicePartnerCurrencyListFinish: () => ({
    type: actionTypes.GET_SERVICE_PARTNER_CURRENCY_FINISH,
  }),
}
