import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getAllMenuButton: (id: string = '') => ({
    type: actionTypes.GET_ALL_MENU_BUTTON_MANAGER_START,
    payload: id,
  }),

  getAllMenuButtonSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ALL_MENU_BUTTON_MANAGER_SUCCESS,
    payload: data,
  }),
  getAllMenuButtonFinish: () => ({
    type: actionTypes.GET_ALL_MENU_BUTTON_MANAGER_FINISH,
  }),
  getAllMenuButtonError: (error: unknown) => ({
    type: actionTypes.GET_ALL_MENU_BUTTON_MANAGER_FINISH,
    payload: {error},
  }),

  getSpecificMenuButton: (id: string = '') => ({
    type: actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_START,
    payload: id,
  }),

  getSpecificMenuButtonSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_SUCCESS,
    payload: data,
  }),
  getSpecificMenuButtonFinish: () => ({
    type: actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_FINISH,
  }),
  getSpecificMenuButtonError: (error: unknown) => ({
    type: actionTypes.GET_SPECIFIC_MENU_BUTTON_MANAGER_FINISH,
    payload: {error},
  }),

  getMenuButton: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_MENU_BUTTON_MANAGER_START,
    payload: params,
  }),

  getMenuButtonSuccess: (data: any) => ({
    type: actionTypes.GET_MENU_BUTTON_MANAGER_SUCCESS,
    payload: data,
  }),
  getMenuButtonFinish: () => ({
    type: actionTypes.GET_MENU_BUTTON_MANAGER_FINISH,
  }),
  getMenuButtonError: (error: unknown) => ({
    type: actionTypes.GET_MENU_BUTTON_MANAGER_FINISH,
    payload: {error},
  }),

  addMenuButton: (data: any) => ({
    type: actionTypes.ADD_MENU_BUTTON_MANAGER_START,
    payload: data,
  }),
  addMenuButtonSuccess: (task: any) => ({
    type: actionTypes.ADD_MENU_BUTTON_MANAGER_SUCCESS,
    payload: task,
  }),
  addMenuButtonFinish: () => ({
    type: actionTypes.ADD_MENU_BUTTON_MANAGER_FINISH,
  }),
  addMenuButtonReset: () => ({
    type: actionTypes.ADD_MENU_BUTTON_MANAGER_RESET,
  }),

  updateMenuButton: (data: any, id: string) => ({
    type: actionTypes.UPDATE_MENU_BUTTON_MANAGER_START,
    payload: {data, id},
  }),
  updateMenuButtonSuccess: (task: any) => ({
    type: actionTypes.UPDATE_MENU_BUTTON_MANAGER_SUCCESS,
    payload: task,
  }),
  updateMenuButtonFinish: () => ({
    type: actionTypes.UPDATE_MENU_BUTTON_MANAGER_FINISH,
  }),
  updateMenuButtonReset: () => ({
    type: actionTypes.UPDATE_MENU_BUTTON_MANAGER_RESET,
  }),

  deleteMenuButton: (data: any) => ({
    type: actionTypes.DELETE_MENU_BUTTON_MANAGER_START,
    payload: {menuButtonId: data},
  }),
  deleteMenuButtonSuccess: (data: any) => ({
    type: actionTypes.DELETE_MENU_BUTTON_MANAGER_SUCCESS,
    payload: data,
  }),
  deleteMenuButtonFinish: () => ({
    type: actionTypes.DELETE_MENU_BUTTON_MANAGER_FINISH,
  }),
  activateMenuButton: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_REQUEST,
    payload: {menuButtonId: id},
  }),
  deactivateMenuButton: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_REQUEST,
    payload: {menuButtonId: id},
  }),
  singleActivateMenuButton: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_MENU_BUTTON_MANAGER_REQUEST,
    payload: {menuButtonId: [id]},
  }),
  singleDeactivateMenuButton: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_MENU_BUTTON_MANAGER_REQUEST,
    payload: {menuButtonId: [id]},
  }),

  sortMenuButton: (data: any) => ({
    type: actionTypes.SORT_MENU_BUTTON_MANAGER_START,
    payload: data,
  }),
  sortMenuButtonSuccess: (data: any) => ({
    type: actionTypes.SORT_MENU_BUTTON_MANAGER_SUCCESS,
    payload: data,
  }),
  sortMenuButtonFinish: () => ({
    type: actionTypes.SORT_MENU_BUTTON_MANAGER_FINISH,
  }),
  sortMenuButtonReset: () => ({
    type: actionTypes.SORT_MENU_BUTTON_MANAGER_RESET,
  }),
}
