import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import Tag from 'rsuite/Tag'

// includes
import * as agency from '../index'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import Pagination from 'rsuite/Pagination'
import IconButton from 'rsuite/esm/IconButton'
import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { StateParamsModel } from 'src/app/modules/common/Model'
import { SortType } from 'rsuite-table/lib/@types/common'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import AddAgency from './AddAgency'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import moment from 'moment'
import { getTodayDate } from 'src/cms/helpers'

const AgencyComponent = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const {
    data,
    loading,
    importSuccess,
    singleEnableSuccess,
    singleDisableSuccess,
    enableSuccess,
    disableSuccess,
    exportSuccess,
    success,
    toggleLoading,
    deleteSuccess,
  } = useSelector((state: any) => state.agency)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<any>()
  // alert modal
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)

  console.log(data)
  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const enableAgencyData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(agency.actions.enableAgency(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableAgencyData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(agency.actions.disableAgency(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    function handleAction() {
      alert(`id:${rowData[dataKey]}`)
    }
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              icon={<Edit2 />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let agencyId = [rowData.id]
                setCheckedValues(agencyId)
              }}
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={toggleLoading}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
          />
        </Whisper>
      </Cell>
    )
  }

  const handleToggleAction = (data: { [key: string]: string }) => {
    data?.status
      ? dispatch(agency.actions.singleDisableAgency({ id: data.id }))
      : dispatch(agency.actions.singleEnableAgency({ id: data.id }))
  }

  const Cell = Table.Cell

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name',
      dataKey: 'title',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },
    // {
    //   label: 'Agency Manager',
    //   dataKey: 'agencyManager',
    //   flexGrow: 1,
    //   cell: <Cell dataKey='agencyManager' />,
    //   sortable: true,
    // },
    {
      label: 'Address',
      dataKey: 'address',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (rowData.address ? rowData.address : '-')}</Cell>,
      sortable: true,
    },
    {
      label: 'Telephone',
      dataKey: 'telephone',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (rowData.telephone ? rowData.telephone : '-')}</Cell>,
      sortable: true,
    },
    {
      label: 'Email',
      dataKey: 'email',
      flexGrow: 1,
      cell: <Cell>{(rowData) => (rowData.email ? rowData.email : '-')}</Cell>,
      sortable: true,
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  useEffect(() => {
    dispatch(agency.actions.getAgencyData(params))
  }, [params])


  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Agency deleted successfully')
    }
    if (enableSuccess) {
      toast.success('Agency enabled successfully')
    }
    if (disableSuccess) {
      toast.success('Agency disabled successfully')
    }
    if (importSuccess) {
      toast.success('Agency imported successfully')
    }
    if (deleteSuccess | enableSuccess | disableSuccess | importSuccess | exportSuccess) {
      handleChecked([])
      dispatch(agency.actions.getAgencyData(params))
    }
  }, [deleteSuccess, success, enableSuccess, disableSuccess, importSuccess, exportSuccess])

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('Agency enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('Agency disabled successfully')
    }
    handleChecked([])
  }, [singleDisableSuccess, singleEnableSuccess])

  const agencyData = data?.agency
    ? data?.agency?.map((item: any, i: number) => ({
      sn: (params?.page - 1) * params?.limit + (i + 1),
      address: item.address,
      address_np: item.address_np,
      cash_counter: item.cash_counter,
      category: item.category,
      closingTime: item?.closing_time
        ? moment(`${getTodayDate()} ${item?.closing_time}`).toDate()
        : null,
      code: item.code,
      description: item.description,
      description_np: item.description_np,
      district: item.district,
      email: item.email,
      extended_hour: item.extended_hour,
      fax: item.fax,
      id: item.id,
      latitude: item.latitude,
      longitude: item.longitude,
      openingTime: item?.opening_time
        ? moment(`${getTodayDate()} ${item?.opening_time}`).toDate()
        : null,
      province: item.province,
      status: item.status,
      telephone: item.telephone,
      title: item.title,
      title_np: item.title_np,
      upcoming_agency: item.upcoming_agency,
      qos: item?.qos,
      iframe: item?.iframe,
      hide_in_website: item?.hide_in_website,
    }))
    : []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({ ...params, orderBy: dataKey, order: sortType })
  }

  function fileImport(event: any) {
    if (!isEmpty(event.target.files)) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      dispatch(agency?.actions?.importAgency(formData))
      event.target.value = null
    }
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const handleDelete = () => {
    let agencyId = checkedValues?.map((value) => ({ id: value }))
    dispatch(agency?.actions?.deleteAgency(agencyId))
    handleAlertClose()
  }

  const handleExport = () => {
    dispatch(agency.actions.exportFile('Agency Data', params))
  }

  const handleTemplateExport = () => {
    dispatch(agency.actions.exportTemplateFile())
  }

  const handleRefresh = () => {
    dispatch(agency.actions.getAgencyData(params))
  }

  return (
    <div className='shadow p-3 bg-white rounded'>
      <DesignComponent
        moduleName='Agency'
        params={params}
        setParams={setParams}
        handleRefresh={handleRefresh}
        handleAddModal={handleAddModal}
        handleAlertOpen={handleAlertOpen}
        handleExport={handleExport}
        handleImport={fileImport}
        enableMultiple={enableAgencyData}
        disableMultiple={disableAgencyData}
        templateButtonName='Download Template for Agency'
        templateLinkShow={true}
        handleTemplateExport={handleTemplateExport}
      />

      <div className='datatable'>
        <RSuiteTable
          onChecked={handleChecked}
          showCheckbox={true}
          columns={columns}
          data={agencyData}
          checkedValues={checkedValues}
          showLoading={loading}
          handleSort={handleSort}
        />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          className='mt-5'
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={data?.meta?.total || 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({ ...params, page: value })}
          onChangeLimit={handleChangeLimit}
        />
        {open && (
          <AddAgency
            open={open}
            params={params}
            handleClose={handleClose}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}
        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </div>
  )
}

export default AgencyComponent
