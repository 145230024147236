import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {CollegeDetailModel} from '../Model/CollegeDetailModel'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SortCollegeDetailActionModel, SortCollegeDetailModel} from '../Model'

function* getCollegeDetailSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCollegeDetail, params)
    yield put(actions.getCollegeDetailSuccess(response?.data?.data))
    yield put(actions.getCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCollegeDetailFinish())
  }
}

function* addCollegeDetailSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCollegeDetail, body)
    yield put(actions.addCollegeDetailSuccess(response.data?.data))
    yield put(actions.addCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCollegeDetailFinish())
  }
}

function* enableCollegeDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableCollegeDetail, selectedUsers)
    yield put(actions.enableCollegeDetailSuccess(response?.data))
    yield put(actions.enableCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCollegeDetailFinish())
  }
}

function* disableCollegeDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableCollegeDetail, selectedUsers)
    yield put(actions.disableCollegeDetailSuccess(response?.data))
    yield put(actions.disableCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCollegeDetailFinish())
  }
}

function* singleEnableCollegeDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableCollegeDetail, selectedUsers)
    yield put(actions.singleEnableCollegeDetailSuccess(response?.data))
    yield put(actions.singleEnableCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableCollegeDetailFinish())
  }
}

function* singleDisableCollegeDetailSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableCollegeDetail, selectedUsers)
    yield put(actions.singleDisableCollegeDetailSuccess(response?.data))
    yield put(actions.singleDisableCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableCollegeDetailFinish())
  }
}

function* updateCollegeDetailSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateCollegeDetail,
      body,
      action.payload?.id
    )
    yield put(actions.updateCollegeDetailSuccess(response.data?.data))
    yield put(actions.updateCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCollegeDetailFinish())
  }
}

function* deleteCollegeDetailSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCollegeDetail, body)
    yield put(actions.deleteCollegeDetailSuccess(response.data?.data))
    yield put(actions.deleteCollegeDetailFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCollegeDetailFinish())
  }
}

function* sortCollegeDetail(action: SortCollegeDetailActionModel) {
  try {
    const body: SortCollegeDetailModel = action.payload
    const response: ResponseModel = yield call(service.sortCollegeDetail, body)

    yield put({
      type: actionTypes.SORT_COLLEGE_DETAIL_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_COLLEGE_DETAIL_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_COLLEGE_DETAIL_FINISH})
  }
}

//Get AboutType Type saga
function* getAboutTypeOptionSaga(action: ActionModel) {
  try {
    const response: ResponseModel = yield call(service.getAboutTypeOption)
    const data: any = response?.data
    yield put(actions.getAboutTypeOptionSuccess(data))
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.getAboutTypeOptionError())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_COLLEGE_DETAIL_START, getCollegeDetailSaga)
  yield takeLatest(actionTypes.ADD_COLLEGE_DETAIL_START, addCollegeDetailSaga)
  yield takeLatest(actionTypes.UPDATE_COLLEGE_DETAIL_START, updateCollegeDetailSaga)
  yield takeLatest(actionTypes.DELETE_COLLEGE_DETAIL_START, deleteCollegeDetailSaga)
  yield takeLatest(actionTypes.ENABLE_COLLEGE_DETAIL_REQUEST, enableCollegeDetailSaga)
  yield takeLatest(actionTypes.DISABLE_COLLEGE_DETAIL_REQUEST, disableCollegeDetailSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_COLLEGE_DETAIL_REQUEST, singleEnableCollegeDetailSaga)
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_COLLEGE_DETAIL_REQUEST,
    singleDisableCollegeDetailSaga
  )
  yield takeLatest(actionTypes.SORT_COLLEGE_DETAIL_START, sortCollegeDetail)
  yield takeLatest(actionTypes.GET_ABOUT_TYPE_OPTION_START, getAboutTypeOptionSaga)
}
