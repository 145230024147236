import {Action} from 'redux'
import {AuctionEnquiryModel} from '../Model/Model'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAuctionEnquiryState: IAuctionEnquiryState = {
  data: {
    auctionEnquiries: [],
    meta: [],
  },
  sortAuctionEnquiryData: [],
  auctionEnquiryList: {auctionEnquiry: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IAuctionEnquiryState {
  data?: {
    auctionEnquiries?: AuctionEnquiryModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortAuctionEnquiryData?: IAuctionEnquiryState[]
  auctionEnquiryList?: {auctionEnquiry: AuctionEnquiryModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IAuctionEnquiryState = initialAuctionEnquiryState,
  action: ActionWithPayload<IAuctionEnquiryState>
) => {
  switch (action.type) {
    //GET AuctionEnquiry DATA
    case actionTypes.GET_AUCTION_ENQUIRY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_AUCTION_ENQUIRY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_AUCTION_ENQUIRY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //View Auction Enquiry
    case actionTypes.PUT_VIEW_AUCTION_ENQUIRY_START: {
      return state
    }

    case actionTypes.PUT_VIEW_AUCTION_ENQUIRY_SUCCESS: {
      const response = action.payload as AuctionEnquiryModel
      const viewedEnquiryList = state.data?.auctionEnquiries?.map((enquiry) =>
        enquiry?.id === response?.id ? response : enquiry
      )
      return {
        ...state,
        data: {...state.data, auctionEnquiries: viewedEnquiryList},
      }
    }

    case actionTypes.PUT_VIEW_AUCTION_ENQUIRY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
