import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteDisputeModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const DISPUTES_URL = `${API_URL}/dispute-claim`

export const service = {
  getDisputes: (params: ParamsModel) => {
    return axios.get(DISPUTES_URL, {params})
  },

  deleteDisputes: (data: DeleteDisputeModel) => {
    return axios.delete(DISPUTES_URL + '/bulk-delete', {data})
  },
}
