import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialStockRateState: IStockRateState = {
  data: {
    share: [],
    meta: {},
  },
  editSuccess: false,
  loading: false,
  success: false,
  deleteSuccess: false,
  toggleLoading: false,
  activateSuccess: false,
  deactivateSuccess: false,
  singleActivateSuccess: false,
  singleDeactivateSuccess: false,
  importSuccess: false,
}

export interface IStockRateState {
  data: {
    share: {[key: string]: string | number}[]
    meta: {[key: string]: number}
  }
  loading?: boolean
  deleteSuccess?: boolean
  success?: boolean
  activateSuccess?: boolean
  toggleLoading?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  editSuccess?: boolean
  importSuccess?: boolean
}

export const reducer = (
  state: IStockRateState = initialStockRateState,
  action: ActionWithPayload<IStockRateState>
) => {
  switch (action.type) {
    case actionTypes.GET_STOCK_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_STOCK_RATE_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_STOCK_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Create stock Rate
    case actionTypes.CREATE_STOCK_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.CREATE_STOCK_RATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        editSuccess: true,
        loading: false,
      }
    }

    case actionTypes.CREATE_STOCK_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.CREATE_STOCK_RATE_RESET: {
      return {
        ...state,
        data: [],
        editSuccess: false,
      }
    }

    //Activate stock Rate
    case actionTypes.ACTIVATE_STOCK_RATE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ACTIVATE_STOCK_RATE_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.ACTIVATE_STOCK_RATE_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    //Single Activate stock
    case actionTypes.SINGLE_ACTIVATE_STOCK_RATE_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_STOCK_RATE_SUCCESS: {
      const changedData: any = action.payload?.data
      let newData = state?.data?.share?.map((data) => {
        if (data?.id === changedData?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state?.data?.meta, share: newData},
        singleActivateSuccess: true,
        toggleLoading: false,
      }
    }
    case actionTypes.SINGLE_ACTIVATE_STOCK_RATE_FINISH: {
      return {...state, singleActivateSuccess: false, toggleLoading: false}
    }

    //Deactivate stock Rate
    case actionTypes.DEACTIVATE_STOCK_RATE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DEACTIVATE_STOCK_RATE_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.DEACTIVATE_STOCK_RATE_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    //Single Deactivate stock
    case actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_SUCCESS: {
      const changedData: any = action.payload?.data
      let newData = state?.data?.share?.map((data) => {
        if (data?.id === changedData?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state?.data?.meta, share: newData},
        singleDeactivateSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DEACTIVATE_STOCK_RATE_FINISH: {
      return {...state, singleDeactivateSuccess: false, toggleLoading: false}
    }

    //Import stock rate file
    case actionTypes.IMPORT_STOCK_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.IMPORT_STOCK_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        importSuccess: true,
      }
    }

    case actionTypes.IMPORT_STOCK_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false, importSuccess: false}
    }

    //Update Stock Rate
    case actionTypes.UPDATE_STOCK_RATE_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_STOCK_RATE_SUCCESS: {
      return {
        ...state,
        editSuccess: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_STOCK_RATE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.UPDATE_STOCK_RATE_RESET: {
      return {
        ...state,
        data: [],
        editSuccess: false,
      }
    }

    //Delete Stock Rate
    case actionTypes.DELETE_STOCK_RATE_START: {
      return {...state, loading: true, deleteSuccess: false}
    }

    case actionTypes.DELETE_STOCK_RATE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_STOCK_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.EXPORT_SHARES_TEMPLATE_FILE_START: {
      return {...state, loading: true}
    }

    case actionTypes.EXPORT_SHARES_TEMPLATE_FILE_SUCCESS: {
      return {...state, templateExport: true, loading: false}
    }

    case actionTypes.EXPORT_SHARES_TEMPLATE_FILE_FINISH: {
      const error = action.payload
      return {...state, error, templateExport: false, loading: false}
    }

    default:
      return state
  }
}
const initialStockRateFileState: IStockRateFileState = {
  data: {
    file: {},
  },
  loading: false,
  success: false,
}

export interface IStockRateFileState {
  data: {
    file: {[key: string]: number}
  }
  loading?: boolean
  success?: boolean
}

export const reducer1 = (
  state: IStockRateFileState = initialStockRateFileState,
  action: ActionWithPayload<IStockRateFileState>
) => {
  switch (action.type) {
    case actionTypes.GET_STOCK_RATE_FILE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_STOCK_RATE_FILE_SUCCESS: {
      return {...state, exportSuccess: true, loading: false}
    }

    case actionTypes.GET_STOCK_RATE_FILE_FINISH: {
      const error = action.payload
      return {...state, error, exportSuccess: false, loading: false}
    }

    default:
      return state
  }
}
