import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { ParamsModel } from 'src/app/modules/common/Model'
import { globalActionTypes } from 'src/app/modules/errors/redux/constants'
import { ActionModel } from 'src/cms/helpers/Models/ActionModel'
import { ResponseModel } from 'src/cms/helpers/Models/ResponseModel'
import { actions } from './action'
import { actionTypes } from './constants'
import { service } from './service'

function* getActivityLogsSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getActivityLogs, params)
    yield put(actions.getActivityLogsSuccess(response?.data?.data))
    yield put(actions.getActivityLogsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      })
    }
    yield put(actions.getActivityLogsFinish())
  }
}


export function* saga() {
  yield takeLatest(actionTypes.GET_ACTIVITY_LOG_START, getActivityLogsSaga)
 
}
