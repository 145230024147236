import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

import { StateParamsModel } from 'src/app/modules/common/Model'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import { addPortfolio, getAllPortfolio } from '../redux'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
}

const FORM_VALIDATION = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  mobileNumber: Yup.string().required('Mobile Number is required'),
  emailAddress: Yup.string().email().required(),
})

const AddPortfolio = ({ open, params, handleClose, actionType }: Props) => {
  const dispatch = useDispatch()
  const { loading, success } = useSelector((state: any) => state.portfolio)

  useEffect(() => {
    if (success) {
      toast.success('Portfolio added successfully')
      dispatch(getAllPortfolio(params))
      //   dispatch(resetPortfolio())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Portfolio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                fullName: '',
                mobileNumber: '',
                emailAddress: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                if (actionType === 'Add') {
                  dispatch(addPortfolio(values))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Full Name'
                            label='Full Name'
                            name='fullName'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Mobile Number'
                            label='Mobile Number'
                            name='mobileNumber'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Email Address'
                            label='Email Address'
                            name='emailAddress'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddPortfolio
