import TrashIcon from '@rsuite/icons/Trash'
import {EmptyObject} from 'chart.js/types/basic'
import parse from 'html-react-parser'
import {useEffect, useState} from 'react'
import {AiOutlineEdit} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import IconButton from 'rsuite/IconButton'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'

//Manual import
import {isEmpty} from 'lodash'
import {QualificationModel} from 'src/app/modules/careerSettings/components/Qualification/Model'
import AddQualification from 'src/app/modules/careerSettings/components/Qualification/components/AddQualification'
import * as qualificationRedux from 'src/app/modules/careerSettings/components/Qualification/redux'
import {IQualificationState} from 'src/app/modules/careerSettings/components/Qualification/redux'
import {ItemType, StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import checkMultiLanguageColumns from 'src/app/modules/common/checkMultiLanguageColumns'

const Cell = Table.Cell
const Qualification = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })
  const [allDataParams, setAllDataParams] = useState<StateParamsModel>({
    page: 0,
    limit: 0,
  })

  const [actionType, setActionType] = useState('Add')
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [open1, setOpen1] = useState(false)
  const [editSelectedData, setEditCheckedData] = useState<QualificationModel | EmptyObject>({})
  const [selectedData, setSelectedData] = useState<Array<string>>([])

  const handleClose1 = () => setOpen1(false)
  const [openSortModal, setOpenSortModal] = useState(false)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  let qualificationData: IQualificationState = useSelector((state: any) => state.Qualification)
  useEffect(() => {
    dispatch(qualificationRedux.actions.getQualification(params))
  }, [params])
  const {
    success,
    activateSuccess,
    deactivateSuccess,
    singleActivateSuccess,
    singleDeactivateSuccess,
    deleteSuccess,
    loading,
    sortQualificationData,
  } = qualificationData

  const enableQualificationData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(qualificationRedux.actions.activateQualification(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableQualificationData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(qualificationRedux.actions.deactivateQualification(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleToggleAction = (data: {[key: string]: string}) => {
    data?.status
      ? dispatch(qualificationRedux.actions.singleDeactivateQualification({id: data.id}))
      : dispatch(qualificationRedux.actions.singleActivateQualification({id: data.id}))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group' data-cy='qualification-link-group'>
        <CheckPermissions type='Edit'>
          <Whisper
            placement='top'
            trigger='hover'
            data-cy='qualification-edit'
            speaker={<Tooltip> Edit</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen1(true)
              }}
              data-cy='edit-button'
              icon={<AiOutlineEdit />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper
            placement='top'
            trigger='hover'
            data-cy='qualification-delete'
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let qualificationId = [rowData.id]
                setCheckedValues(qualificationId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
        <Whisper
          placement='top'
          trigger='hover'
          data-cy='toggle-button'
          speaker={<Tooltip>Status</Tooltip>}
        >
          <Toggle size='sm' checked={rowData.status} onClick={() => handleToggleAction(rowData)} />
        </Whisper>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Name (EN)',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='name' />,
      sortable: true,
    },
    {
      label: 'Name (NP)',
      flexGrow: 1,
      cell: <Cell dataKey='name_np'>{(rowData) => rowData?.nameNp}</Cell>,
      sortable: true,
      isNepaliColumn: true
    },
    {
      label: 'Description (EN)',
      dataKey: 'description',
      flexGrow: 1,
      cell: <Cell dataKey='description'>{(rowData) => parse(rowData?.description || '')}</Cell>,
    },
    {
      label: 'Description (NP)',
      dataKey: 'descriptionNp',
      flexGrow: 1,
      cell: <Cell dataKey='description_np'>{(rowData) => parse(rowData?.descriptionNp || "")}</Cell>,
      isNepaliColumn: true
    },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleCheck = (data: any) => {
    setCheckedValues(data)
  }

  useEffect(() => {
    if (activateSuccess) {
      toast.success('Qualification activated successfully')
    }
    if (deactivateSuccess) {
      toast.success('Qualification deactivated successfully')
    }
    if (deleteSuccess) {
      toast.success('Qualification deleted successfully')
    }
    handleCheck([])
    dispatch(qualificationRedux?.actions.getQualification(params))
  }, [activateSuccess, deactivateSuccess, deleteSuccess])
  useEffect(() => {
    if (singleActivateSuccess) {
      toast.success('Qualification Activated successfully')
      dispatch(qualificationRedux.actions.getQualification(params))
    }
    if (singleDeactivateSuccess) {
      toast.success('Qualification deactivated successfully')
    }
    handleCheck([])
  }, [singleActivateSuccess, singleDeactivateSuccess])

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleRefresh = () => {
    dispatch(qualificationRedux.actions.getQualification(params))
  }

  //    Get data from api to map in datatable
  const data = qualificationData?.data?.qualification
    ? qualificationData?.data?.qualification?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        name: item?.name,
        nameNp: item?.nameNp,
        description: item?.description,
        descriptionNp: item?.descriptionNp,
        status: item?.status,
      }))
    : []
  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen1(true)
    setActionType('Add')
  }
  const handleDelete = () => {
    let bannerSelectedId = checkedValues?.map((value) => ({id: value}))
    dispatch(qualificationRedux?.actions.deleteQualification(bannerSelectedId))
    handleAlertClose()
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      qualificationId: data?.map((item) => ({id: item?.keyId})),
    }

    dispatch(qualificationRedux.actions.sortQualification(body))
  }

  useEffect(() => {
    if (!isEmpty(sortQualificationData) && success) {
      toast.success('Qualification sorted successfully')
      dispatch(qualificationRedux.actions.sortQualificationReset())
      setParams({...params, limit: 10})
      dispatch(qualificationRedux.actions.getQualification(params))
      setOpenSortModal(false)
    }
  }, [sortQualificationData, success])

  return (
    <div>
      <div className='shadow p-3 bg-white rounded' data-cy='qualification-link-group'>
        <DesignComponent
          moduleName='Qualification Manager'
          params={params}
          setParams={setParams}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          handleRefresh={handleRefresh}
          exportShow={false}
          importShow={false}
          enableMultiple={enableQualificationData}
          disableMultiple={disableQualificationData}
          data={data}
          sortShow={true}
          sortButtonName='Sort'
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          handleSubmitSort={handleSubmitSort}
          loading={loading}
          data-cy='qualification-design-comp'
        />

        <div className='datatable' data-cy='qualification-datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={checkMultiLanguageColumns(columns)}
            data={data}
            checkedValues={checkedValues}
            showLoading={qualificationData?.loading}
            onChecked={handleCheck}
            handleSort={handleSort}
            data-cy='qualification-r-suite'
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={qualificationData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='qualification-pagination'
          />
        </div>

        {open1 && (
          <AddQualification
            open={open1}
            handleClose={handleClose1}
            actionType={actionType}
            editSelectedData={editSelectedData}
          />
        )}

        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </div>
  )
}
export default Qualification
