export const actionTypes = {
  //Add role
  ADD_ROLE_REQUEST: 'ADD_ROLE_REQUEST',
  ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
  ADD_ROLE_FINISH: 'ADD_ROLE_FINISH',
  ADD_ROLE_RESET: 'ADD_ROLE_RESET',

   //Update role
   UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
   UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
   UPDATE_ROLE_FINISH: 'UPDATE_ROLE_FINISH',
   UPDATE_ROLE_RESET: 'UPDATE_ROLE_RESET',

  //Get role
  GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FINISH: 'GET_ROLE_FINISH',

  //Delete role
  DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FINISH: 'DELETE_ROLE_FINISH',
}
