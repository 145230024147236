import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {
  ProductSubCategoryModel,
  SortProductSubCategoryModel,
} from '../Model/ProductSubCategoryModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const PRODUCT_SUB_CATEGORY_DATA = `${API_URL}/productsubcategory`

export const service = {
  getProductSubCategory: (params: ParamsModel) => {
    return axios.get(PRODUCT_SUB_CATEGORY_DATA, {params})
  },
  getAllProductSubCategory: () => {
    return axios.get(`${PRODUCT_SUB_CATEGORY_DATA}/list`)
  },

  addProductSubCategory: (data: any) => {
    return axios.post(PRODUCT_SUB_CATEGORY_DATA, data)
  },

  updateProductSubCategory: (body: ProductSubCategoryModel, id: string) => {
    return axios.put(`${PRODUCT_SUB_CATEGORY_DATA}/${id}`, body)
  },

  deleteProductSubCategory: (data: {id: string}) => {
    return axios.delete(PRODUCT_SUB_CATEGORY_DATA, {data})
  },

  enableProductSubCategory: (data: Array<string>) => {
    const selectedProductSubCategory = {
      productSubCategoryId: data,
    }
    return axios.patch(`${PRODUCT_SUB_CATEGORY_DATA}/enable`, selectedProductSubCategory)
  },

  disableProductSubCategory: (data: Array<string>) => {
    const selectedProductSubCategory = {
      productSubCategoryId: data,
    }
    return axios.patch(`${PRODUCT_SUB_CATEGORY_DATA}/disable`, selectedProductSubCategory)
  },

  singleEnableProductSubCategory: (data: Array<string>) => {
    const selectedProductSubCategory = {
      productSubCategoryId: [data],
    }
    return axios.patch(`${PRODUCT_SUB_CATEGORY_DATA}/enable`, selectedProductSubCategory)
  },

  singleDisableProductSubCategory: (data: Array<string>) => {
    const selectedProductSubCategory = {
      productSubCategoryId: [data],
    }
    return axios.patch(`${PRODUCT_SUB_CATEGORY_DATA}/disable`, selectedProductSubCategory)
  },

  sortProductSubCategory: (body: SortProductSubCategoryModel) => {
    return axios.patch(`${PRODUCT_SUB_CATEGORY_DATA}/sort`, body)
  },
}
