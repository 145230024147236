import { call, delay, put, takeLatest } from "redux-saga/effects";
import { ParamsModel } from "src/app/modules/common/Model";
import { globalActionTypes } from "src/app/modules/errors/redux/constants";
import { ActionModel } from "src/cms/helpers/Models/ActionModel";
import { ResponseModel } from "src/cms/helpers/Models/ResponseModel";
import { SortPropertyTypeModel, SortSearchActionModel } from "../Model/Model";
import { actions } from "./action";
import { actionTypes } from "./constants";
import { service } from "./service";

function* getPropertyTypeSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload;

    yield delay(500);
    const response: ResponseModel = yield call(service.getPropertyType, params);
    yield put(actions.getPropertyTypeSuccess(response?.data?.data));
    yield put(actions.getPropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.getPropertyTypeFinish());
  }
}

function* addPropertyTypeSaga(action: ActionModel) {
  try {
    const body = action.payload;
    const response: ResponseModel = yield call(service.addPropertyType, body);
    yield put(actions.addPropertyTypeSuccess(response.data?.data));
    yield put(actions.addPropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.addPropertyTypeFinish());
  }
}

function* enablePropertyTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data;
    const response: ResponseModel = yield call(
      service.enablePropertyType,
      selectedUsers
    );
    yield put(actions.enablePropertyTypeSuccess(response?.data));
    yield delay(100);
    yield put(actions.enablePropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.enablePropertyTypeFinish());
  }
}

function* disablePropertyTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data;
    const response: ResponseModel = yield call(
      service.disablePropertyType,
      selectedUsers
    );
    yield put(actions.disablePropertyTypeSuccess(response?.data));
    yield delay(100);
    yield put(actions.disablePropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.enablePropertyTypeFinish());
  }
}

function* singleEnablePropertyTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data;
    const response: ResponseModel = yield call(
      service.singleEnablePropertyType,
      selectedUsers
    );
    yield put(actions.singleEnablePropertyTypeSuccess(response?.data));
    yield delay(100);
    yield put(actions.singleEnablePropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.singleEnablePropertyTypeFinish());
  }
}

function* singleDisablePropertyTypeSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data;
    const response: ResponseModel = yield call(
      service.singleDisablePropertyType,
      selectedUsers
    );
    yield put(actions.singleDisablePropertyTypeSuccess(response?.data));
    yield delay(100);
    yield put(actions.singleDisablePropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.singleDisablePropertyTypeFinish());
  }
}

function* updatePropertyTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload;
    // const {id, ...newPayload} = body
    const response: ResponseModel = yield call(
      service.updatePropertyType,
      body,
      action.payload?.id
    );
    yield put(actions.updatePropertyTypeSuccess(response.data?.data));
    yield put(actions.updatePropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.updatePropertyTypeFinish());
  }
}

function* deletePropertyTypeSaga(action: ActionModel) {
  try {
    const body: any = action.payload;
    const response: ResponseModel = yield call(
      service.deletePropertyType,
      body
    );
    yield put(actions.deletePropertyTypeSuccess(response.data?.data));
    yield delay(0.1);
    yield put(actions.deletePropertyTypeFinish());
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put(actions.deletePropertyTypeFinish());
  }
}

function* sortPropertyType(action: SortSearchActionModel) {
  try {
    const body: SortPropertyTypeModel = action.payload;
    const response: ResponseModel = yield call(service.sortPropertyType, body);

    yield put({
      type: actionTypes.SORT_PROPERTY_TYPE_SUCCESS,
      payload: response?.data?.data,
    });
    yield delay(100);
    yield put({ type: actionTypes.SORT_PROPERTY_TYPE_FINISH });
  } catch (err: any) {
    if (err.response) {
      yield put({
        type: globalActionTypes.GLOBAL_ERROR,
        payload: err.response.data.message,
      });
    }
    yield put({ type: actionTypes.SORT_PROPERTY_TYPE_FINISH });
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_PROPERTY_TYPE_START, getPropertyTypeSaga);
  yield takeLatest(actionTypes.ADD_PROPERTY_TYPE_START, addPropertyTypeSaga);
  yield takeLatest(
    actionTypes.UPDATE_PROPERTY_TYPE_START,
    updatePropertyTypeSaga
  );
  yield takeLatest(
    actionTypes.DELETE_PROPERTY_TYPE_START,
    deletePropertyTypeSaga
  );
  yield takeLatest(
    actionTypes.ENABLE_PROPERTY_TYPE_REQUEST,
    enablePropertyTypeSaga
  );
  yield takeLatest(
    actionTypes.DISABLE_PROPERTY_TYPE_REQUEST,
    disablePropertyTypeSaga
  );
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_PROPERTY_TYPE_REQUEST,
    singleEnablePropertyTypeSaga
  );
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_PROPERTY_TYPE_REQUEST,
    singleDisablePropertyTypeSaga
  );
  yield takeLatest(actionTypes.SORT_PROPERTY_TYPE_START, sortPropertyType);
}
