import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAppointmentStatusState: IAppointmentStatusState = {
  data: {
    appointmentStatus: [],
  },

  loading: false,
  success: false,
  editSuccess: false,
}

export interface IAppointmentStatusState {
  data: {
    appointmentStatus: {[key: string]: number | string}[]
  }
  loading: false
  success: false
  editSuccess: false
}

export const reducer = (
  state: IAppointmentStatusState = initialAppointmentStatusState,
  action: ActionWithPayload<IAppointmentStatusState>
) => {
  switch (action.type) {
    //Get appointment status Reducer
    case actionTypes.GET_APPOINTMENT_STATUS_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_APPOINTMENT_STATUS_SUCCESS: {
      return {...state, data: action.payload?.data, loading: false}
    }

    case actionTypes.GET_APPOINTMENT_STATUS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
