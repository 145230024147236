import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const history = `${API_URL}/history`

export const service = {
  getHistory: (params: ParamsModel) => {
    return axios.get(history, {params})
  },

  addHistory: (data: any) => {
    return axios.post(history, data)
  },

  updateHistory: (body: any, id: string) => {
    return axios.put(`${history}/${id}`, body)
  },

  deleteHistory: (data: string) => {
    return axios.delete(`${history}/bulk-delete`, {data})
  },
  singleActivateHistory: (id: any) => {
    return axios.patch(`${history}/enable`, id)
  },

  singleDeactivateHistory: (id: any) => {
    return axios.patch(`${history}/disable`, id)
  },

  sortHistory: (body: any) => {
    return axios.patch(`${history}/sort`, body)
  },

  getFiscalYearAD: () => {
    return axios.get(`${API_URL}/eng-fiscal-year`)
  },
}
