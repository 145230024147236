import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

//Get Extension Counter saga
function* getExtensionCounterSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getExtensionCounter, params)
    const data: any = response?.data
    yield put(actions.getExtensionCounterSuccess(data))
  } catch (error: any) {
    yield put(actions.getExtensionCounterError(error))
  }
}

//Create Extension Counter
function* createExtensionCounterSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.addExtensionCounter, body)
    yield put({type: actionTypes.ADD_EXTENSION_COUNTER_SUCCESS, payload: response.data})
    yield put({type: actionTypes.ADD_EXTENSION_COUNTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ADD_EXTENSION_COUNTER_FINISH})
  }
}

//Update Extension Counter
function* updateExtensionCounter(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateExtensionCounter,
      body,
      action.payload?.id
    )
    yield put({type: actionTypes.UPDATE_EXTENSION_COUNTER_SUCCESS, payload: response.data})
    yield put({type: actionTypes.UPDATE_EXTENSION_COUNTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.UPDATE_EXTENSION_COUNTER_FINISH})
  }
}

//Activate Deactivate Extension Counter Saga
function* activateExtensionCounterSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateExtensionCounter, selectedBanners)
    yield put(actions.activateExtensionCounterSuccess(response?.data))
    yield put(actions.activateExtensionCounterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.activateExtensionCounterFinish())
  }
}

function* singleActivateExtensionCounterSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleActivateExtensionCounter,
      selectedBanner
    )

    yield put(actions.singleActivateExtensionCounterSuccess(response?.data))
    yield put(actions.singleActivateExtensionCounterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleActivateExtensionCounterFinish())
  }
}

function* deactivateExtensionCounterSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(service.deactivateExtensionCounter, selectedBanner)

    yield put(actions.deactivateExtensionCounterSuccess(response?.data))
    yield put(actions.deactivateExtensionCounterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deactivateExtensionCounterFinish())
  }
}

function* singleDeactivateExtensionCounterSaga(action: ActionModel) {
  try {
    const selectedBanner: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDeactivateExtensionCounter,
      selectedBanner
    )
    yield put(actions.singleDeactivateExtensionCounterSuccess(response?.data))
    yield put(actions.singleDeactivateExtensionCounterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDeactivateExtensionCounterFinish())
  }
}

function* deleteExtensionCounterSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteExtensionCounter, body)
    yield put({type: actionTypes.DELETE_EXTENSION_COUNTER_SUCCESS})
    yield put({type: actionTypes.DELETE_EXTENSION_COUNTER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DELETE_EXTENSION_COUNTER_FINISH})
  }
}

//Get Extension Counter  file
function* getExtensionCounterFileSaga(action: ActionModel) {
  try {
    const fileName: any = action.payload.fileName
    yield delay(500)
    const data: any = action.payload.params
    const response: ResponseModel = yield call(service.getExtensionCounterFile, fileName, data)
    const responseData: any = response?.data
    yield put(actions.geExtensionCounterFileSuccess(responseData))
  } catch (error: any) {
    yield put(actions.getExtensionCounterFileError(error))
  }
}

//Import Extension Counter File
function* importExtensionCounterSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importExtensionCounterFile, body)
    yield put(actions.importExtensionCounterSuccess(response.data?.data))
    yield put(actions.importExtensionCounterFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importExtensionCounterFinish())
  }
}

function* exportTemplateFileSaga() {
  try {
    const response: ResponseModel = yield call(service.exportTemplateFile)
    const responseData: any = response?.data
    yield put(actions.exportTemplateFileSuccess(responseData))
    yield put(actions.exportTemplateFileFinish())
  } catch (error: any) {
    if (error.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: error.response.data.message})
    }
    yield put(actions.exportTemplateFileFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_EXTENSION_COUNTER_START, getExtensionCounterSaga)
  yield takeLatest(actionTypes.ADD_EXTENSION_COUNTER_REQUEST, createExtensionCounterSaga)
  yield takeLatest(actionTypes.UPDATE_EXTENSION_COUNTER_START, updateExtensionCounter)
  yield takeLatest(actionTypes.DELETE_EXTENSION_COUNTER_START, deleteExtensionCounterSaga)
  yield takeLatest(actionTypes.ACTIVATE_EXTENSION_COUNTER_REQUEST, activateExtensionCounterSaga)
  yield takeLatest(actionTypes.DEACTIVATE_EXTENSION_COUNTER_REQUEST, deactivateExtensionCounterSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_EXTENSION_COUNTER_REQUEST,
    singleActivateExtensionCounterSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_EXTENSION_COUNTER_REQUEST,
    singleDeactivateExtensionCounterSaga
  )
  yield takeLatest(actionTypes.GET_EXTENSION_COUNTER_FILE_START, getExtensionCounterFileSaga)
  yield takeLatest(actionTypes.IMPORT_EXTENSION_COUNTER_START, importExtensionCounterSaga)
  yield takeLatest(actionTypes.EXPORT_EXTENSION_COUNTER_TEMPLATE_FILE_START, exportTemplateFileSaga)
}
