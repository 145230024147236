import {Action} from 'redux'
import {PartnersModel} from '../Model/PartnersModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialPartnersState: IPartnersState = {
  data: {
    partner: [],
    partners: [],
    meta: [],
  },
  sortPartnersData: [],
  PartnersList: {partners: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IPartnersState {
  data?: {
    partner?: PartnersModel[]
    partners?: PartnersModel[]
    meta?: {[key: string]: string | number}[]
  }
  sortPartnersData?: IPartnersState[]
  PartnersList?: {partners: PartnersModel[]}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IPartnersState = initialPartnersState,
  action: ActionWithPayload<IPartnersState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PARTNERS_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_ALL_PARTNERS_SUCCESS: {
      return {...state, PartnersList: action.payload, loading: false}
    }

    case actionTypes.GET_ALL_PARTNERS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET Partners DATA
    case actionTypes.GET_PARTNERS_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_PARTNERS_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_PARTNERS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add Partners
    case actionTypes.ADD_PARTNERS_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_PARTNERS_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_PARTNERS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.RESET_PARTNERS: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Partners
    case actionTypes.UPDATE_PARTNERS_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_PARTNERS_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_PARTNERS_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_PARTNERS_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_PARTNERS_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_PARTNERS_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_PARTNERS_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_PARTNERS_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_PARTNERS_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_PARTNERS_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_PARTNERS_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_PARTNERS_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_PARTNERS_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_PARTNERS_SUCCESS: {
      const changedData: any = action.payload?.data?.partners
      let newData = state?.data?.partner?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, partner: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_PARTNERS_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_PARTNERS_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_PARTNERS_SUCCESS: {
      const changedData: any = action.payload?.data?.partners
      let newData = state?.data?.partner?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, partner: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_PARTNERS_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_PARTNERS_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_PARTNERS_SUCCESS: {
      return {
        ...state,
        sortPartnersData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_PARTNERS_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_PARTNERS_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortPartnersData: [],
      }
    }

    default:
      return state
  }
}
