export const actionTypes: any = {
  // GET
  GET_AUCTION_ENQUIRY_START: 'GET_AUCTION_ENQUIRY_START',
  GET_AUCTION_ENQUIRY_SUCCESS: 'GET_AUCTION_ENQUIRY_SUCCESS',
  GET_AUCTION_ENQUIRY_FINISH: 'GET_AUCTION_ENQUIRY_FINISH',

  // Enquiry set to seem
  PUT_VIEW_AUCTION_ENQUIRY_START: 'PUT_VIEW_AUCTION_ENQUIRY_START',
  PUT_VIEW_AUCTION_ENQUIRY_SUCCESS: 'PUT_VIEW_AUCTION_ENQUIRY_SUCCESS',
  PUT_VIEW_AUCTION_ENQUIRY_FINISH: 'PUT_VIEW_AUCTION_ENQUIRY_FINISH',
}
