import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteFeaturedModel, FeaturedModel, SortFeaturedModel} from '../Model'
import {actionTypes} from './constants'
import {IFeaturedState} from './reducer'
export const actions = {
  // get Featured DATA
  getFeatured: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_FEATURED_START,
    payload: params,
  }),
  getFeaturedSuccess: (data: FeaturedModel) => ({
    type: actionTypes.GET_FEATURED_SUCCESS,
    payload: data,
  }),
  getFeaturedFinish: () => ({
    type: actionTypes.GET_FEATURED_FINISH,
  }),

  // get Featured File Type Data
  getFeaturedFileType: () => ({
    type: actionTypes.GET_FEATURED_FILE_TYPE_START,
  }),
  getFeaturedFileTypeSuccess: (data: FeaturedModel) => ({
    type: actionTypes.GET_FEATURED_FILE_TYPE_SUCCESS,
    payload: data,
  }),
  getFeaturedFileTypeFinish: () => ({
    type: actionTypes.GET_FEATURED_FILE_TYPE_FINISH,
  }),

    // get Featured Image Type Data
    getFeaturedImageType: () => ({
      type: actionTypes.GET_FEATURED_IMAGE_TYPE_START,
    }),
    getFeaturedImageTypeSuccess: (data: FeaturedModel) => ({
      type: actionTypes.GET_FEATURED_IMAGE_TYPE_SUCCESS,
      payload: data,
    }),
    getFeaturedImageTypeFinish: () => ({
      type: actionTypes.GET_FEATURED_IMAGE_TYPE_FINISH,
    }),

  // create key
  addFeatured: (data: FeaturedModel) => ({
    type: actionTypes.ADD_FEATURED_START,
    payload: data,
  }),
  addFeaturedSuccess: (task: any) => ({
    type: actionTypes.ADD_FEATURED_SUCCESS,
    payload: task,
  }),
  addFeaturedFinish: () => ({
    type: actionTypes.ADD_FEATURED_FINISH,
  }),
  resetFeatured: () => ({
    type: actionTypes.RESET_FEATURED_TYPE,
  }),

  //Update Featured
  updateFeatured: (data: FeaturedModel, id: string) => ({
    type: actionTypes.UPDATE_FEATURED_START,
    payload: data,
    id,
  }),

  updateFeaturedSuccess: (data: FeaturedModel) => ({
    type: actionTypes.UPDATE_FEATURED_SUCCESS,
    payload: data,
  }),

  updateFeaturedFinish: () => ({
    type: actionTypes.UPDATE_FEATURED_FINISH,
  }),

  // delete key
  deleteFeatured: (data: DeleteFeaturedModel[]) => ({
    type: actionTypes.DELETE_FEATURED_START,
    payload: {featuredImageId: data},
  }),
  deleteFeaturedSuccess: (data: any) => ({
    type: actionTypes.DELETE_FEATURED_SUCCESS,
    payload: data,
  }),
  deleteFeaturedFinish: () => ({
    type: actionTypes.DELETE_FEATURED_FINISH,
  }),

  //Enable Featured
  enableFeatured: (data: any) => ({
    type: actionTypes.ENABLE_FEATURED_REQUEST,
    payload: {data},
  }),

  enableFeaturedSuccess: (task: any) => ({
    type: actionTypes.ENABLE_FEATURED_SUCCESS,
    payload: task,
  }),
  enableFeaturedFinish: () => ({
    type: actionTypes.ENABLE_FEATURED_FINISH,
  }),

  //Disable Featured
  disableFeatured: (data: any) => ({
    type: actionTypes.DISABLE_FEATURED_REQUEST,
    payload: {data},
  }),

  disableFeaturedSuccess: (task: any) => ({
    type: actionTypes.DISABLE_FEATURED_SUCCESS,
    payload: task,
  }),
  disableFeaturedFinish: () => ({
    type: actionTypes.DISABLE_FEATURED_FINISH,
  }),

  //Enable Featured
  singleEnableFeatured: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_FEATURED_REQUEST,
    payload: {data},
  }),

  singleEnableFeaturedSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_FEATURED_SUCCESS,
    payload: task,
  }),
  singleEnableFeaturedFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_FEATURED_FINISH,
  }),

  //Disable Featured
  singleDisableFeatured: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_FEATURED_REQUEST,
    payload: {data},
  }),

  singleDisableFeaturedSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_FEATURED_SUCCESS,
    payload: task,
  }),
  singleDisableFeaturedFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_FEATURED_FINISH,
  }),

  // get Featured
  getAllFeatured: () => {
    return {
      type: actionTypes.GET_ALL_FEATURED_START,
    }
  },
  getAllFeaturedSuccess: (data: IFeaturedState) => ({
    type: actionTypes.GET_ALL_FEATURED_SUCCESS,
    payload: data,
  }),
  getAllFeaturedError: () => ({
    type: actionTypes.GET_ALL_FEATURED_FINISH,
  }),

  // sort
  sortFeatured: (data: SortFeaturedModel) => ({
    type: actionTypes.SORT_FEATURED_START,
    payload: data,
  }),
  sortFeaturedSuccess: (data: Array<FeaturedModel>) => ({
    type: actionTypes.SORT_FEATURED_SUCCESS,
    payload: data,
  }),
  sortFeaturedFinish: () => ({
    type: actionTypes.SORT_FEATURED_FINISH,
  }),
  sortFeaturedReset: () => ({
    type: actionTypes.SORT_FEATURED_RESET,
  }),
}
