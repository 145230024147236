export const actionTypes = {
  GET_ALL_MID_MENU_MANAGER_START: 'GET_ALL_MID_MENU_MANAGER_START',
  GET_ALL_MID_MENU_MANAGER_SUCCESS: 'GET_ALL_MID_MENU_MANAGER_SUCCESS',
  GET_ALL_MID_MENU_MANAGER_FINISH: 'GET_ALL_MID_MENU_MANAGER_FINISH',

  GET_SPECIFIC_MID_MENU_MANAGER_START: 'GET_SPECIFIC_MID_MENU_MANAGER_START',
  GET_SPECIFIC_MID_MENU_MANAGER_SUCCESS: 'GET_SPECIFIC_MID_MENU_MANAGER_SUCCESS',
  GET_SPECIFIC_MID_MENU_MANAGER_FINISH: 'GET_SPECIFIC_MID_MENU_MANAGER_FINISH',

  GET_MID_MENU_MANAGER_START: 'GET_MID_MENU_MANAGER_START',
  GET_MID_MENU_MANAGER_SUCCESS: 'GET_MID_MENU_MANAGER_SUCCESS',
  GET_MID_MENU_MANAGER_FINISH: 'GET_MID_MENU_MANAGER_FINISH',

  ADD_MID_MENU_MANAGER_START: 'ADD_MID_MENU_MANAGER_START',
  ADD_MID_MENU_MANAGER_SUCCESS: 'ADD_MID_MENU_MANAGER_SUCCESS',
  ADD_MID_MENU_MANAGER_FINISH: 'ADD_MID_MENU_MANAGER_FINISH',
  ADD_MID_MENU_MANAGER_RESET: 'ADD_MID_MENU_MANAGER_RESET',

  UPDATE_MID_MENU_MANAGER_START: 'UPDATE_MID_MENU_MANAGER_START',
  UPDATE_MID_MENU_MANAGER_SUCCESS: 'UPDATE_MID_MENU_MANAGER_SUCCESS',
  UPDATE_MID_MENU_MANAGER_FINISH: 'UPDATE_MID_MENU_MANAGER_FINISH',
  UPDATE_MID_MENU_MANAGER_RESET: 'UPDATE_MID_MENU_MANAGER_RESET',

  DELETE_MID_MENU_MANAGER_START: 'DELETE_MID_MENU_MANAGER_START',
  DELETE_MID_MENU_MANAGER_SUCCESS: 'DELETE_MID_MENU_MANAGER_SUCCESS',
  DELETE_MID_MENU_MANAGER_FINISH: 'DELETE_MID_MENU_MANAGER_FINISH',

  SINGLE_ACTIVATE_MID_MENU_MANAGER_REQUEST: 'SINGLE_ACTIVATE_MID_MENU_MANAGER_REQUEST',
  SINGLE_ACTIVATE_MID_MENU_MANAGER_SUCCESS: 'SINGLE_ACTIVATE_MID_MENU_MANAGER_SUCCESS',
  SINGLE_ACTIVATE_MID_MENU_MANAGER_FINISH: 'SINGLE_ACTIVATE_MID_MENU_MANAGER_FINISH',

  SINGLE_DEACTIVATE_MID_MENU_MANAGER_REQUEST: 'SINGLE_DEACTIVATE_MID_MENU_MANAGER_REQUEST',
  SINGLE_DEACTIVATE_MID_MENU_MANAGER_SUCCESS: 'SINGLE_DEACTIVATE_MID_MENU_MANAGER_SUCCESS',
  SINGLE_DEACTIVATE_MID_MENU_MANAGER_FINISH: 'SINGLE_DEACTIVATE_MID_MENU_MANAGER_FINISH',

  SORT_MID_MENU_MANAGER_START: 'SORT_MID_MENU_MANAGER_START',
  SORT_MID_MENU_MANAGER_SUCCESS: 'SORT_MID_MENU_MANAGER_SUCCESS',
  SORT_MID_MENU_MANAGER_FINISH: 'SORT_MID_MENU_MANAGER_FINISH',
  SORT_MID_MENU_MANAGER_RESET: 'SORT_MID_MENU_MANAGER_RESET',
}
