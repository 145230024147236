import {ParamsModel} from 'src/app/modules/common/Model'
import {ProgramModel, ProgramOptionModal, SortProgramModel} from '../Model/ProgramModel'
import {actionTypes} from './constants'
export const actions = {
  // get Program
  getAllProgram: () => ({
    type: actionTypes.GET_ALL_PROGRAM_START,
  }),
  getAllProgramSuccess: (data: ProgramModel | any) => ({
    type: actionTypes.GET_ALL_PROGRAM_SUCCESS,
    payload: data,
  }),
  getAllProgramFinish: () => ({
    type: actionTypes.GET_ALL_PROGRAM_FINISH,
  }),

  // get Program DATA
  getProgram: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_PROGRAM_START,
    payload: params,
  }),
  getProgramSuccess: (data: ProgramModel | any) => ({
    type: actionTypes.GET_PROGRAM_SUCCESS,
    payload: data,
  }),
  getProgramFinish: () => ({
    type: actionTypes.GET_PROGRAM_FINISH,
  }),

  // create key
  addProgram: (data: ProgramModel | any) => ({
    type: actionTypes.ADD_PROGRAM_START,
    payload: data,
  }),
  addProgramSuccess: (task: any) => ({
    type: actionTypes.ADD_PROGRAM_SUCCESS,
    payload: task,
  }),
  addProgramFinish: () => ({
    type: actionTypes.ADD_PROGRAM_FINISH,
  }),
  resetProgram: () => ({
    type: actionTypes.RESET_PROGRAM_TAG,
  }),

  //Update Program
  updateProgram: (data: ProgramModel | any, id: string) => ({
    type: actionTypes.UPDATE_PROGRAM_START,
    payload: data,
    id,
  }),

  updateProgramSuccess: (data: ProgramModel | any) => ({
    type: actionTypes.UPDATE_PROGRAM_SUCCESS,
    payload: data,
  }),

  updateProgramFinish: () => ({
    type: actionTypes.UPDATE_PROGRAM_FINISH,
  }),

  // delete key
  deleteProgram: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_PROGRAM_START,
    payload: {programId: data},
  }),
  deleteProgramSuccess: (data: any) => ({
    type: actionTypes.DELETE_PROGRAM_SUCCESS,
    payload: data,
  }),
  deleteProgramFinish: () => ({
    type: actionTypes.DELETE_PROGRAM_FINISH,
  }),

  //Enable Program
  enableProgram: (data: any) => ({
    type: actionTypes.ENABLE_PROGRAM_REQUEST,
    payload: {data},
  }),

  enableProgramSuccess: (task: any) => ({
    type: actionTypes.ENABLE_PROGRAM_SUCCESS,
    payload: task,
  }),
  enableProgramFinish: () => ({
    type: actionTypes.ENABLE_PROGRAM_FINISH,
  }),

  //Disable Program
  disableProgram: (data: any) => ({
    type: actionTypes.DISABLE_PROGRAM_REQUEST,
    payload: {data},
  }),

  disableProgramSuccess: (task: any) => ({
    type: actionTypes.DISABLE_PROGRAM_SUCCESS,
    payload: task,
  }),
  disableProgramFinish: () => ({
    type: actionTypes.DISABLE_PROGRAM_FINISH,
  }),

  //Enable Program
  singleEnableProgram: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_PROGRAM_REQUEST,
    payload: {data},
  }),

  singleEnableProgramSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_PROGRAM_SUCCESS,
    payload: task,
  }),
  singleEnableProgramFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_PROGRAM_FINISH,
  }),

  //Disable Program
  singleDisableProgram: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_PROGRAM_REQUEST,
    payload: {data},
  }),

  singleDisableProgramSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_PROGRAM_SUCCESS,
    payload: task,
  }),
  singleDisableProgramFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_PROGRAM_FINISH,
  }),

  // sort
  sortProgram: (data: SortProgramModel) => ({
    type: actionTypes.SORT_PROGRAM_START,
    payload: data,
  }),
  sortProgramSuccess: (data: Array<ProgramModel>) => ({
    type: actionTypes.SORT_PROGRAM_SUCCESS,
    payload: data,
  }),
  sortProgramFinish: () => ({
    type: actionTypes.SORT_PROGRAM_FINISH,
  }),
  sortProgramReset: () => ({
    type: actionTypes.SORT_PROGRAM_RESET,
  }),

  //GET BANNER OPTION ACTIONS
  getBannerOption: () => ({type: actionTypes.GET_PROGRAM_BANNER_OPTION_START}),
  getBannerOptionSuccess: (data: ProgramOptionModal) => ({
    type: actionTypes.GET_PROGRAM_BANNER_OPTION_SUCCESS,
    payload: data,
  }),
  getBannerOptionError: (error: string) => ({
    type: actionTypes.GET_PROGRAM_BANNER_OPTION_FINISH,
    payload: {error},
  }),

  //GET ALBUM OPTION ACTIONS
  getAlbumOption: () => ({type: actionTypes.GET_PROGRAM_ALBUM_OPTION_START}),
  getAlbumOptionSuccess: (data: ProgramOptionModal) => ({
    type: actionTypes.GET_PROGRAM_ALBUM_OPTION_SUCCESS,
    payload: data,
  }),
  getAlbumOptionError: (error: string) => ({
    type: actionTypes.GET_PROGRAM_ALBUM_OPTION_FINISH,
    payload: {error},
  }),

  //GET DOWNLOAD OPTION ACTIONS
  getDownloadOption: () => ({type: actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_START}),

  getDownloadOptionSuccess: (data: ProgramOptionModal) => ({
    type: actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_SUCCESS,
    payload: data,
  }),
  getDownloadOptionError: (error: string) => ({
    type: actionTypes.GET_PROGRAM_DOWNLOAD_OPTION_FINISH,
    payload: {error},
  }),

  //GET LEAD FORM ACTIONS
  getLeadForm: () => ({type: actionTypes.GET_PROGRAM_LEAD_FORM_START}),
  getLeadFormSuccess: (data: ProgramOptionModal) => ({
    type: actionTypes.GET_PROGRAM_LEAD_FORM_SUCCESS,
    payload: data,
  }),
  getLeadFormError: (error: string) => ({
    type: actionTypes.GET_PROGRAM_LEAD_FORM_FINISH,
    payload: {error},
  }),

  //GET PAGE HEADER ACTIONS
  getPageHeader: () => ({type: actionTypes.GET_PROGRAM_PAGE_HEADER_START}),
  getPageHeaderSuccess: (data: ProgramOptionModal) => ({
    type: actionTypes.GET_PROGRAM_PAGE_HEADER_SUCCESS,
    payload: data,
  }),
  getPageHeaderError: (error: string) => ({
    type: actionTypes.GET_PROGRAM_PAGE_HEADER_FINISH,
    payload: {error},
  }),

  //GET program OPTION ACTIONS
  getProgramOption: () => ({type: actionTypes.GET_PROGRAM_OPTION_START}),
  getProgramOptionSuccess: (data: ProgramOptionModal) => ({
    type: actionTypes.GET_PROGRAM_OPTION_SUCCESS,
    payload: data,
  }),
  getProgramOptionError: (error: string) => ({
    type: actionTypes.GET_PROGRAM_OPTION_FINISH,
    payload: {error},
  }),
}
