import {Col, Row} from 'react-bootstrap-v5'
import {Modal} from 'rsuite'

type Props = {
  data: {
    id: String
    grievanceCode: String
    fullName: String
    email: String
    department: {
      id: String
      name: String
      nameNp: String
    }
    mobileNumber: String
    message: String
    accountNumber: String
    preferredBranch: [
      {
        id: String
        name: String
      }
    ]
    serviceCategory: [
      {
        id: String
        name: String
      }
    ]
    serviceType: [
      {
        id: String
        name: String
      }
    ]
    serviceSubType: [
      {
        id: String
        name: String
      }
    ]
    status: [
      {
        id: String
        name: String
      }
    ]
  }
  handleClose: () => void
}

const ViewGrievanceDetails = ({data, handleClose}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Grievance Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={3}>Full Name : {data?.fullName}</Col>
              <Col md={3}>Email : {data?.email}</Col>
              <Col md={3}>Contact Number: {data?.mobileNumber}</Col>
              <Col md={3}>Account Number: {data?.accountNumber}</Col>
            </Row>
            <Row className='mt-5'>
              <Col md={3}>Message : {data?.message}</Col>
              <Col md={3}>Assigned To: {data?.department?.name || 'N/A'}</Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewGrievanceDetails
