export const actionTypes = {
  GET_LOAN_PURPOSE_START: 'GET_LOAN_PURPOSE_START',
  GET_LOAN_PURPOSE_SUCCESS: 'GET_LOAN_PURPOSE_SUCCESS',
  GET_LOAN_PURPOSE_FINISH: 'GET_LOAN_PURPOSE_FINISH',

  ADD_LOAN_PURPOSE_START: 'ADD_LOAN_PURPOSE_START',
  ADD_LOAN_PURPOSE_SUCCESS: 'ADD_LOAN_PURPOSE_SUCCESS',
  ADD_LOAN_PURPOSE_FINISH: 'ADD_LOAN_PURPOSE_FINISH',
  ADD_LOAN_PURPOSE_RESET: 'ADD_LOAN_PURPOSE_RESET',

  UPDATE_LOAN_PURPOSE_START: 'UPDATE_LOAN_PURPOSE_START',
  UPDATE_LOAN_PURPOSE_SUCCESS: 'UPDATE_LOAN_PURPOSE_SUCCESS',
  UPDATE_LOAN_PURPOSE_FINISH: 'UPDATE_LOAN_PURPOSE_FINISH',
  UPDATE_LOAN_PURPOSE_RESET: 'UPDATE_LOAN_PURPOSE_RESET',

  DELETE_LOAN_PURPOSE_START: 'DELETE_LOAN_PURPOSE_START',
  DELETE_LOAN_PURPOSE_SUCCESS: 'DELETE_LOAN_PURPOSE_SUCCESS',
  DELETE_LOAN_PURPOSE_FINISH: 'DELETE_LOAN_PURPOSE_FINISH',

  SINGLE_ACTIVATE_LOAN_PURPOSE_REQUEST: 'SINGLE_ACTIVATE_LOAN_PURPOSE_REQUEST',
  SINGLE_ACTIVATE_LOAN_PURPOSE_SUCCESS: 'SINGLE_ACTIVATE_LOAN_PURPOSE_SUCCESS',
  SINGLE_ACTIVATE_LOAN_PURPOSE_FINISH: 'SINGLE_ACTIVATE_LOAN_PURPOSE_FINISH',

  SINGLE_DEACTIVATE_LOAN_PURPOSE_REQUEST: 'SINGLE_DEACTIVATE_LOAN_PURPOSE_REQUEST',
  SINGLE_DEACTIVATE_LOAN_PURPOSE_SUCCESS: 'SINGLE_DEACTIVATE_LOAN_PURPOSE_SUCCESS',
  SINGLE_DEACTIVATE_LOAN_PURPOSE_FINISH: 'SINGLE_DEACTIVATE_LOAN_PURPOSE_FINISH',

  SORT_LOAN_PURPOSE_START: 'SORT_LOAN_PURPOSE_START',
  SORT_LOAN_PURPOSE_SUCCESS: 'SORT_LOAN_PURPOSE_SUCCESS',
  SORT_LOAN_PURPOSE_FINISH: 'SORT_LOAN_PURPOSE_FINISH',
  SORT_LOAN_PURPOSE_RESET: 'SORT_LOAN_PURPOSE_RESET',
}
