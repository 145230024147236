import {BankDetailParamsModel} from 'src/app/modules/common/Model'
import {DeleteCardMenuDetailModel, CardMenuDetailModel, SortBankDetailModel} from '../Model'
import {actionTypes} from './constants'
import {ICardMenuState} from './reducer'
export const actions = {
  // get BankDetail DATA
  getCardMenuDetail: (params: BankDetailParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CARD_MENU_START,
    payload: params,
  }),
  getCardMenuDetailSuccess: (data: CardMenuDetailModel) => ({
    type: actionTypes.GET_CARD_MENU_SUCCESS,
    payload: data,
  }),
  getCardMenuDetailFinish: () => ({
    type: actionTypes.GET_CARD_MENU_FINISH,
  }),

  // get BankDetail File Type Data
  getCardMenuDetailFileType: () => ({
    type: actionTypes.GET_CARD_MENU_FILE_TYPE_START,
  }),
  getCardMenuDetailFileTypeSuccess: (data: CardMenuDetailModel) => ({
    type: actionTypes.GET_CARD_MENU_FILE_TYPE_SUCCESS,
    payload: data,
  }),
  getCardMenuDetailFileTypeFinish: () => ({
    type: actionTypes.GET_CARD_MENU_FILE_TYPE_FINISH,
  }),

  // create key
  addCardMenuDetail: (data: CardMenuDetailModel) => ({
    type: actionTypes.ADD_CARD_MENU_START,
    payload: data,
  }),
  addCardMenuDetailSuccess: (task: any) => ({
    type: actionTypes.ADD_CARD_MENU_SUCCESS,
    payload: task,
  }),
  addCardMenuDetailFinish: () => ({
    type: actionTypes.ADD_CARD_MENU_FINISH,
  }),
  resetCardMenuDetail: () => ({
    type: actionTypes.RESET_CARD_MENU_TYPE,
  }),

  //Update BankDetail
  updateCardMenuDetail: (data: CardMenuDetailModel, id: string) => ({
    type: actionTypes.UPDATE_CARD_MENU_START,
    payload: data,
    id,
  }),

  updateCardMenuDetailSuccess: (data: CardMenuDetailModel) => ({
    type: actionTypes.UPDATE_CARD_MENU_SUCCESS,
    payload: data,
  }),

  updateCardMenuDetailFinish: () => ({
    type: actionTypes.UPDATE_CARD_MENU_FINISH,
  }),

  // delete key
  deleteCardMenuDetail: (data: DeleteCardMenuDetailModel[]) => ({
    type: actionTypes.DELETE_CARD_MENU_START,
    payload: {cardMenuId: data},
  }),
  deleteCardMenuDetailSuccess: (data: any) => ({
    type: actionTypes.DELETE_CARD_MENU_SUCCESS,
    payload: data,
  }),
  deleteCardMenuDetailFinish: () => ({
    type: actionTypes.DELETE_CARD_MENU_FINISH,
  }),

  //Enable BankDetail
  enableCardMenuDetail: (data: any) => ({
    type: actionTypes.ENABLE_CARD_MENU_REQUEST,
    payload: {data},
  }),

  enableCardMenuDetailSuccess: (task: any) => ({
    type: actionTypes.ENABLE_CARD_MENU_SUCCESS,
    payload: task,
  }),
  enableCardMenuDetailFinish: () => ({
    type: actionTypes.ENABLE_CARD_MENU_FINISH,
  }),

  //Disable BankDetail
  disableCardMenuDetail: (data: any) => ({
    type: actionTypes.DISABLE_CARD_MENU_REQUEST,
    payload: {data},
  }),

  disableCardMenuDetailSuccess: (task: any) => ({
    type: actionTypes.DISABLE_CARD_MENU_SUCCESS,
    payload: task,
  }),
  disableCardMenuDetailFinish: () => ({
    type: actionTypes.DISABLE_CARD_MENU_FINISH,
  }),

  //Enable BankDetail
  singleEnableCardMenuDetail: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_CARD_MENU_REQUEST,
    payload: {data},
  }),

  singleEnableCardMenuDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_CARD_MENU_SUCCESS,
    payload: task,
  }),
  singleEnableCardMenuDetailFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_CARD_MENU_FINISH,
  }),

  //Disable BankDetail
  singleDisableCardMenuDetail: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_CARD_MENU_REQUEST,
    payload: {data},
  }),

  singleDisableCardMenuDetailSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_CARD_MENU_SUCCESS,
    payload: task,
  }),
  singleDisableCardMenuDetailFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_CARD_MENU_FINISH,
  }),

  // get BankDetail
  getAllCardMenuDetail: () => {
    return {
      type: actionTypes.GET_ALL_CARD_MENU_START,
    }
  },
  getAllCardMenuDetailSuccess: (data: ICardMenuState) => ({
    type: actionTypes.GET_ALL_CARD_MENU_SUCCESS,
    payload: data,
  }),
  getAllCardMenuDetailError: () => ({
    type: actionTypes.GET_ALL_CARD_MENU_FINISH,
  }),

  // sort
  sortCardMenuDetail: (data: SortBankDetailModel) => ({
    type: actionTypes.SORT_CARD_MENU_START,
    payload: data,
  }),
  sortCardMenuDetailSuccess: (data: Array<CardMenuDetailModel>) => ({
    type: actionTypes.SORT_CARD_MENU_SUCCESS,
    payload: data,
  }),
  sortCardMenuDetailFinish: () => ({
    type: actionTypes.SORT_CARD_MENU_FINISH,
  }),
  sortCardMenuDetailReset: () => ({
    type: actionTypes.SORT_CARD_MENU_RESET,
  }),
}
