import {Col, Row} from 'react-bootstrap-v5'
import {Modal} from 'rsuite'

type Props = {
  data: {
    disputeType: string
    accountHolderName: string
    transactionDate: string
    accountNumber: string
    emailAddress: string
    claimDisputeAmount: string
    transactionBankMerchant: string
    transactionLocationCountry: string
    contactNumber: string
    cardType: string
    cardNumber: string
    otherInformation: string
    iTouchUserName: string
    traceNo: string
    regMobileNo: string
  }
  handleClose: () => void
}
const ViewDetailsModal = ({handleClose, data}: Props) => {
  return (
    <div className='modal-container'>
      <Modal
        open={true}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>Dispute Claim Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={6}>
                <b>Dispute Type</b>
              </Col>
              <Col md={6}>
                <p>{data.disputeType}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Account Holder Name</b>
              </Col>
              <Col md={6}>
                <p>{data.accountHolderName}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Account Number</b>
              </Col>
              <Col md={6}>
                <p>{data.accountNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Email</b>
              </Col>
              <Col md={6}>
                <p>{data.emailAddress}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Contact Number</b>
              </Col>
              <Col md={6}>
                <p>{data.contactNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Claim/Dispute Amount</b>
              </Col>
              <Col md={6}>
                <p>{data.claimDisputeAmount}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Transaction Date</b>
              </Col>
              <Col md={6}>
                <p>{data.transactionDate}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Transaction Bank/Merchant</b>
              </Col>
              <Col md={6}>
                <p>{data.transactionBankMerchant}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Transaction Location/Country</b>
              </Col>
              <Col md={6}>
                <p>{data.transactionLocationCountry}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Card Type</b>
              </Col>
              <Col md={6}>
                <p>{data.cardType}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Card Number</b>
              </Col>
              <Col md={6}>
                <p>{data.cardNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Other Information</b>
              </Col>
              <Col md={6}>
                <p>{data?.otherInformation}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>iTouch Username</b>
              </Col>
              <Col md={6}>
                <p>{data.iTouchUserName}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Register Mobile Number</b>
              </Col>
              <Col md={6}>
                <p>{data.regMobileNo}</p>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <b>Trace Number</b>
              </Col>
              <Col md={6}>
                <p>{data.traceNo}</p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ViewDetailsModal
