export const actionTypes = {
  // get
  GET_ALL_DOCUMENT_START: 'GET_ALL_DOCUMENT_START',
  GET_ALL_DOCUMENT_SUCCESS: 'GET_ALL_DOCUMENT_SUCCESS',
  GET_ALL_DOCUMENT_FINISH: 'GET_ALL_DOCUMENT_FINISH',

  // get DOCUMENTs
  GET_DOCUMENT_START: 'GET_DOCUMENT_START',
  GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_FINISH: 'GET_DOCUMENT_FINISH',
  // get FISCAL_YEARs
  GET_FISCAL_YEAR_START: 'GET_FISCAL_YEAR_START',
  GET_FISCAL_YEAR_SUCCESS: 'GET_FISCAL_YEAR_SUCCESS',
  GET_FISCAL_YEAR_FINISH: 'GET_FISCAL_YEAR_FINISH',

  // get QUATERs
  GET_QUATER_START: 'GET_QUATER_START',
  GET_QUATER_SUCCESS: 'GET_QUATER_SUCCESS',
  GET_QUATER_FINISH: 'GET_QUATER_FINISH',

  // add DOCUMENT
  ADD_DOCUMENT_START: 'ADD_DOCUMENT_START',
  ADD_DOCUMENT_SUCCESS: 'ADD_DOCUMENT_SUCCESS',
  ADD_DOCUMENT_FINISH: 'ADD_DOCUMENT_FINISH',
  RESET_DOCUMENT: 'RESET_DOCUMENT',

  // update DOCUMENT
  UPDATE_DOCUMENT_START: 'UPDATE_DOCUMENT_START',
  UPDATE_DOCUMENT_SUCCESS: 'UPDATE_DOCUMENT_SUCCESS',
  UPDATE_DOCUMENT_FINISH: 'UPDATE_DOCUMENT_FINISH',

  // delete DOCUMENT
  DELETE_DOCUMENT_START: 'DELETE_DOCUMENT_START',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FINISH: 'DELETE_DOCUMENT_FINISH',

  // Enable DOCUMENT
  ENABLE_DOCUMENT_REQUEST: 'ENABLE_DOCUMENT_REQUEST',
  ENABLE_DOCUMENT_SUCCESS: 'ENABLE_DOCUMENT_SUCCESS',
  ENABLE_DOCUMENT_FINISH: 'ENABLE_DOCUMENT_FINISH',

  // Disable DOCUMENT
  DISABLE_DOCUMENT_REQUEST: 'DISABLE_DOCUMENT_REQUEST',
  DISABLE_DOCUMENT_SUCCESS: 'DISABLE_DOCUMENT_SUCCESS',
  DISABLE_DOCUMENT_FINISH: 'DISABLE_DOCUMENT_FINISH',

  // Enable DOCUMENT
  SINGLE_ENABLE_DOCUMENT_REQUEST: 'SINGLE_ENABLE_DOCUMENT_REQUEST',
  SINGLE_ENABLE_DOCUMENT_SUCCESS: 'SINGLE_ENABLE_DOCUMENT_SUCCESS',
  SINGLE_ENABLE_DOCUMENT_FINISH: 'SINGLE_ENABLE_DOCUMENT_FINISH',

  // Disable DOCUMENT
  SINGLE_DISABLE_DOCUMENT_REQUEST: 'SINGLE_DISABLE_DOCUMENT_REQUEST',
  SINGLE_DISABLE_DOCUMENT_SUCCESS: 'SINGLE_DISABLE_DOCUMENT_SUCCESS',
  SINGLE_DISABLE_DOCUMENT_FINISH: 'SINGLE_DISABLE_DOCUMENT_FINISH',

  // sort
  SORT_DOCUMENT_START: 'SORT_DOCUMENT_START',
  SORT_DOCUMENT_SUCCESS: 'SORT_DOCUMENT_SUCCESS',
  SORT_DOCUMENT_FINISH: 'SORT_DOCUMENT_FINISH',
  SORT_DOCUMENT_RESET: 'SORT_DOCUMENT_RESET',
}
