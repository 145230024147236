import axios from 'axios'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const AUCTION_ENQUIRY_API = `${API_URL}/auction-enquiries`
export const PUT_VIEW_AUCTION_ENQUIRY_API = `${API_URL}/auction-enquiries/view-auction-enquiry`

export const service = {
  getAuctionEnquiry: (params: any) => {
    return axios.get(AUCTION_ENQUIRY_API, {params})
  },

  putViewAuctionEnquiry: (auctionEnquiryId: string) => {
    console.log(auctionEnquiryId)
    return axios.put(PUT_VIEW_AUCTION_ENQUIRY_API, {auctionEnquiryId})
  },
}
