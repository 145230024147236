import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getVacancySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getVacancy, params)
    yield put(actions.getVacancySuccess(response?.data?.data))
    yield put(actions.getVacancyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getVacancyFinish())
  }
}

function* addVacancySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addVacancy, body)
    yield put(actions.addVacancySuccess(response.data?.data))
    yield put(actions.addVacancyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addVacancyFinish())
  }
}

function* updateVacancySaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateVacancy, body, action.payload?.id)
    yield put(actions.updateVacancySuccess(response.data?.data))
    yield put(actions.updateVacancyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateVacancyFinish())
  }
}

function* deleteVacancySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteVacancy, body)
    yield put(actions.deleteVacancySuccess(response.data?.data))
    yield put(actions.deleteVacancyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteVacancyFinish())
  }
}
function* enableVacancySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.activateVacancy, selectedUsers)
    yield put(actions.enableActivateSuccess(response?.data))
    yield put(actions.enableVacancyFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableVacancyFinish())
  }
}

function* disableVacancySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload
    const response: ResponseModel = yield call(service.deactivateVacancy, selectedUsers)
    yield put(actions.enableDeactivateSuccess(response?.data))
    yield put(actions.enableDeactivateFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableDeactivateFinish())
  }
}

function* singleActivateVacancy(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateVacancy,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_VACANCY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_VACANCY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_VACANCY_FINISH})
  }
}

function* singleDeactivateVacancy(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateVacancy,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_VACANCY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_VACANCY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_VACANCY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_VACANCY_START, getVacancySaga)
  yield takeLatest(actionTypes.ADD_VACANCY_START, addVacancySaga)
  yield takeLatest(actionTypes.UPDATE_VACANCY_START, updateVacancySaga)
  yield takeLatest(actionTypes.DELETE_VACANCY_START, deleteVacancySaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_VACANCY_REQUEST, singleActivateVacancy)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_VACANCY_REQUEST, singleDeactivateVacancy)
  yield takeLatest(actionTypes.ENABLE_VACANCY_REQUEST, enableVacancySaga)
  yield takeLatest(actionTypes.DISABLE_VACANCY_REQUEST, disableVacancySaga)
}
