export const actionTypes = {
  // get
  GET_ALL_MERCHANT_MANAGER_START: 'GET_ALL_MERCHANT_MANAGER_START',
  GET_ALL_MERCHANT_MANAGER_SUCCESS: 'GET_ALL_MERCHANT_MANAGER_SUCCESS',
  GET_ALL_MERCHANT_MANAGER_FINISH: 'GET_ALL_MERCHANT_MANAGER_FINISH',

  // extended hours
  GET_MERCHANT_MANAGER_START: 'GET_MERCHANT_MANAGER_START',
  GET_MERCHANT_MANAGER_SUCCESS: 'GET_MERCHANT_MANAGER_SUCCESS',
  GET_MERCHANT_MANAGER_FINISH: 'GET_MERCHANT_MANAGER_FINISH',

  // add branch
  ADD_MERCHANT_MANAGER_START: 'ADD_MERCHANT_MANAGER_START',
  ADD_MERCHANT_MANAGER_SUCCESS: 'ADD_MERCHANT_MANAGER_SUCCESS',
  ADD_MERCHANT_MANAGER_FINISH: 'ADD_MERCHANT_MANAGER_FINISH',
  RESET_MERCHANT_MANAGER: 'RESET_MERCHANT_MANAGER',

  // update MERCHANT_MANAGER
  UPDATE_MERCHANT_MANAGER_START: 'UPDATE_MERCHANT_MANAGER_START',
  UPDATE_MERCHANT_MANAGER_SUCCESS: 'UPDATE_MERCHANT_MANAGER_SUCCESS',
  UPDATE_MERCHANT_MANAGER_FINISH: 'UPDATE_MERCHANT_MANAGER_FINISH',

  // delete MERCHANT_MANAGER
  DELETE_MERCHANT_MANAGER_START: 'DELETE_MERCHANT_MANAGER_START',
  DELETE_MERCHANT_MANAGER_SUCCESS: 'DELETE_MERCHANT_MANAGER_SUCCESS',
  DELETE_MERCHANT_MANAGER_FINISH: 'DELETE_MERCHANT_MANAGER_FINISH',

  // Enable MERCHANT_MANAGER
  ENABLE_MERCHANT_MANAGER_REQUEST: 'ENABLE_MERCHANT_MANAGER_REQUEST',
  ENABLE_MERCHANT_MANAGER_SUCCESS: 'ENABLE_MERCHANT_MANAGER_SUCCESS',
  ENABLE_MERCHANT_MANAGER_FINISH: 'ENABLE_MERCHANT_MANAGER_FINISH',

  // Disable MERCHANT_MANAGER
  DISABLE_MERCHANT_MANAGER_REQUEST: 'DISABLE_MERCHANT_MANAGER_REQUEST',
  DISABLE_MERCHANT_MANAGER_SUCCESS: 'DISABLE_MERCHANT_MANAGER_SUCCESS',
  DISABLE_MERCHANT_MANAGER_FINISH: 'DISABLE_MERCHANT_MANAGER_FINISH',

  // Enable MERCHANT_MANAGER
  SINGLE_ENABLE_MERCHANT_MANAGER_REQUEST: 'SINGLE_ENABLE_MERCHANT_MANAGER_REQUEST',
  SINGLE_ENABLE_MERCHANT_MANAGER_SUCCESS: 'SINGLE_ENABLE_MERCHANT_MANAGER_SUCCESS',
  SINGLE_ENABLE_MERCHANT_MANAGER_FINISH: 'SINGLE_ENABLE_MERCHANT_MANAGER_FINISH',

  // Disable MERCHANT_MANAGER
  SINGLE_DISABLE_MERCHANT_MANAGER_REQUEST: 'SINGLE_DISABLE_MERCHANT_MANAGER_REQUEST',
  SINGLE_DISABLE_MERCHANT_MANAGER_SUCCESS: 'SINGLE_DISABLE_MERCHANT_MANAGER_SUCCESS',
  SINGLE_DISABLE_MERCHANT_MANAGER_FINISH: 'SINGLE_DISABLE_MERCHANT_MANAGER_FINISH',

  // sort
  SORT_MERCHANT_MANAGER_START: 'SORT_MERCHANT_MANAGER_START',
  SORT_MERCHANT_MANAGER_SUCCESS: 'SORT_MERCHANT_MANAGER_SUCCESS',
  SORT_MERCHANT_MANAGER_FINISH: 'SORT_MERCHANT_MANAGER_FINISH',
  SORT_MERCHANT_MANAGER_RESET: 'SORT_MERCHANT_MANAGER_RESET',

  // export
  EXPORT_MERCHANT_TEMPLATE_FILE_START: 'EXPORT_MERCHANT_TEMPLATE_FILE_START',
  EXPORT_MERCHANT_TEMPLATE_FILE_SUCCESS: 'EXPORT_MERCHANT_TEMPLATE_FILE_SUCCESS',
  EXPORT_MERCHANT_TEMPLATE_FILE_FINISH: 'EXPORT_MERCHANT_TEMPLATE_FILE_FINISH',

  // import

  IMPORT_MERCHANT_START: 'IMPORT_MERCHANT_START',
  IMPORT_MERCHANT_SUCCESS: 'IMPORT_MERCHANT_SUCCESS',
  IMPORT_MERCHANT_FINISH: 'IMPORT_MERCHANT_FINISH',
}
