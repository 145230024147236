import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'
import {DeleteAgentModel} from '../Model'

export const actions = {
  getList: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_LIST_START,
    payload: params,
  }),
  getListSuccess: (data: any) => ({
    type: actionTypes.GET_LIST_SUCCESS,
    payload: data,
  }),
  getListFinish: () => ({
    type: actionTypes.GET_LIST_FINISH,
  }),

  //delete and reset
  deleteAgent: (data: any) => ({
    type: actionTypes.DELETE_LIST_START,
    payload: {agentId: data},
  }),
}
