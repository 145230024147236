export const actionTypes = {
  // get Featured
  GET_FEATURED_START: 'GET_FEATURED_START',
  GET_FEATURED_SUCCESS: 'GET_FEATURED_SUCCESS',
  GET_FEATURED_FINISH: 'GET_FEATURED_FINISH',

  // get Media type
  GET_FEATURED_FILE_TYPE_START: 'GET_FEATURED_FILE_TYPE_START',
  GET_FEATURED_FILE_TYPE_SUCCESS: 'GET_FEATURED_FILE_TYPE_SUCCESS',
  GET_FEATURED_FILE_TYPE_FINISH: 'GET_FEATURED_FILE_TYPE_FINISH',

  // get Media type
  GET_FEATURED_IMAGE_TYPE_START: 'GET_FEATURED_IMAGE_TYPE_START',
  GET_FEATURED_IMAGE_TYPE_SUCCESS: 'GET_FEATURED_IMAGE_TYPE_SUCCESS',
  GET_FEATURED_IMAGE_TYPE_FINISH: 'GET_FEATURED_IMAGE_TYPE_FINISH',

  // add Featured
  ADD_FEATURED_START: 'ADD_FEATURED_START',
  ADD_FEATURED_SUCCESS: 'ADD_FEATURED_SUCCESS',
  ADD_FEATURED_FINISH: 'ADD_FEATURED_FINISH',
  RESET_FEATURED_TYPE: 'RESET_FEATURED_TYPE',

  // update Featured
  UPDATE_FEATURED_START: 'UPDATE_FEATURED_START',
  UPDATE_FEATURED_SUCCESS: 'UPDATE_FEATURED_SUCCESS',
  UPDATE_FEATURED_FINISH: 'UPDATE_FEATURED_FINISH',

  // delete Featured
  DELETE_FEATURED_START: 'DELETE_FEATURED_START',
  DELETE_FEATURED_SUCCESS: 'DELETE_FEATURED_SUCCESS',
  DELETE_FEATURED_FINISH: 'DELETE_FEATURED_FINISH',

  // Enable Featured
  ENABLE_FEATURED_REQUEST: 'ENABLE_FEATURED_REQUEST',
  ENABLE_FEATURED_SUCCESS: 'ENABLE_FEATURED_SUCCESS',
  ENABLE_FEATURED_FINISH: 'ENABLE_FEATURED_FINISH',

  // Disable Featured
  DISABLE_FEATURED_REQUEST: 'DISABLE_FEATURED_REQUEST',
  DISABLE_FEATURED_SUCCESS: 'DISABLE_FEATURED_SUCCESS',
  DISABLE_FEATURED_FINISH: 'DISABLE_FEATURED_FINISH',

  // Enable Featured
  SINGLE_ENABLE_FEATURED_REQUEST: 'SINGLE_ENABLE_FEATURED_REQUEST',
  SINGLE_ENABLE_FEATURED_SUCCESS: 'SINGLE_ENABLE_FEATURED_SUCCESS',
  SINGLE_ENABLE_FEATURED_FINISH: 'SINGLE_ENABLE_FEATURED_FINISH',

  // Disable Featured
  SINGLE_DISABLE_FEATURED_REQUEST: 'SINGLE_DISABLE_FEATURED_REQUEST',
  SINGLE_DISABLE_FEATURED_SUCCESS: 'SINGLE_DISABLE_FEATURED_SUCCESS',
  SINGLE_DISABLE_FEATURED_FINISH: 'SINGLE_DISABLE_FEATURED_FINISH',

  // get FEATUREDs
  GET_ALL_FEATURED_START: 'GET_ALL_FEATURED_START',
  GET_ALL_FEATURED_SUCCESS: 'GET_ALL_FEATURED_SUCCESS',
  GET_ALL_FEATURED_FINISH: 'GET_ALL_FEATURED_FINISH',

  // sort
  SORT_FEATURED_START: 'SORT_FEATURED_START',
  SORT_FEATURED_SUCCESS: 'SORT_FEATURED_SUCCESS',
  SORT_FEATURED_FINISH: 'SORT_FEATURED_FINISH',
  SORT_FEATURED_RESET: 'SORT_FEATURED_RESET',
}
