import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* importExperienceManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.importExperienceManager, body)
    yield put(actions.importExperienceManagerSuccess(response.data?.data))
    yield put(actions.importExperienceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.importExperienceManagerFinish())
  }
}

function* getExperienceManagerSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getExperienceManager, params)
    yield put(actions.getExperienceManagerSuccess(response?.data?.data))
    yield put(actions.getExperienceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getExperienceManagerFinish())
  }
}

function* addExperienceManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addExperienceManager, body)
    yield put(actions.addExperienceManagerSuccess(response.data?.data))
    yield put(actions.addExperienceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addExperienceManagerFinish())
  }
}

function* updateExperienceManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(
      service.updateExperienceManager,
      body,
      action.payload?.id
    )
    yield put(actions.updateExperienceManagerSuccess(response.data?.data))
    yield put(actions.updateExperienceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateExperienceManagerFinish())
  }
}

function* deleteExperienceManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteExperienceManager, body)
    yield put(actions.deleteExperienceManagerSuccess(response.data?.data))
    yield put(actions.deleteExperienceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteExperienceManagerFinish())
  }
}

function* singleActivateExperienceManager(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateExperienceManager,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_EXPERIENCE_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_EXPERIENCE_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_EXPERIENCE_MANAGER_FINISH})
  }
}

function* singleDeactivateExperienceManager(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateExperienceManager,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_FINISH})
  }
}

function* sortExperienceManager(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortExperienceManager, body)

    yield put({
      type: actionTypes.SORT_EXPERIENCE_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_EXPERIENCE_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_EXPERIENCE_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_EXPERIENCE_MANAGER_START, getExperienceManagerSaga)
  yield takeLatest(actionTypes.ADD_EXPERIENCE_MANAGER_START, addExperienceManagerSaga)
  yield takeLatest(actionTypes.UPDATE_EXPERIENCE_MANAGER_START, updateExperienceManagerSaga)
  yield takeLatest(actionTypes.DELETE_EXPERIENCE_MANAGER_START, deleteExperienceManagerSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_EXPERIENCE_MANAGER_REQUEST,
    singleActivateExperienceManager
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_EXPERIENCE_MANAGER_REQUEST,
    singleDeactivateExperienceManager
  )
  yield takeLatest(actionTypes.SORT_EXPERIENCE_MANAGER_START, sortExperienceManager)
  yield takeLatest(actionTypes.IMPORT_EXPERIENCE_MANAGER_START, importExperienceManagerSaga)
}
