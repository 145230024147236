import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {ISettingTypeState} from '../siteSettings/components/settings'

const checkProvince = () => {
  const [isProvince, setIsProvince] = useState<boolean>(false)
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Vacancy Setting']?.map((item: any) => {
          if (item?.name === 'ProvinceField') {
            let data = item?.value === '1' ? true : false
            setIsProvince(data)
          }
        })
    }
  }, [settingTypeData])
  return isProvince
}

export default checkProvince
