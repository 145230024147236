import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {
  AlgoliaSearchModel,
  SortSearchActionModel,
  SortAlgoliaSearchModel,
} from '../Model/AlgoliaSearchModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getAlgoliaSearchSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getAlgoliaSearch, params)
    yield put(actions.getAlgoliaSearchSuccess(response?.data?.data))
    yield put(actions.getAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAlgoliaSearchFinish())
  }
}

function* getAllAlgoliaSearchSaga() {
  try {
    const response: ResponseModel = yield call(service.getAllAlgoliaSearch)
    yield put(actions.getAllAlgoliaSearchSuccess(response?.data?.data))
    yield put(actions.getAllAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllAlgoliaSearchFinish())
  }
}

function* addAlgoliaSearchSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addAlgoliaSearch, body)
    yield put(actions.addAlgoliaSearchSuccess(response.data?.data))
    yield put(actions.addAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addAlgoliaSearchFinish())
  }
}

function* enableAlgoliaSearchSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableAlgoliaSearch, selectedUsers)
    yield put(actions.enableAlgoliaSearchSuccess(response?.data))
    yield put(actions.enableAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableAlgoliaSearchFinish())
  }
}

function* disableAlgoliaSearchSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableAlgoliaSearch, selectedUsers)
    yield put(actions.disableAlgoliaSearchSuccess(response?.data))
    yield put(actions.disableAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableAlgoliaSearchFinish())
  }
}

function* singleEnableAlgoliaSearchSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableAlgoliaSearch, selectedUsers)
    yield put(actions.singleEnableAlgoliaSearchSuccess(response?.data))
    yield put(actions.singleEnableAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableAlgoliaSearchFinish())
  }
}

function* singleDisableAlgoliaSearchSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableAlgoliaSearch, selectedUsers)
    yield put(actions.singleDisableAlgoliaSearchSuccess(response?.data))
    yield put(actions.singleDisableAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableAlgoliaSearchFinish())
  }
}

function* updateAlgoliaSearchSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateAlgoliaSearch,
      body,
      action.payload?.id
    )
    yield put(actions.updateAlgoliaSearchSuccess(response.data?.data))
    yield put(actions.updateAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateAlgoliaSearchFinish())
  }
}

function* deleteAlgoliaSearchSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAlgoliaSearch, body)
    yield put(actions.deleteAlgoliaSearchSuccess(response.data?.data))
    yield put(actions.deleteAlgoliaSearchFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteAlgoliaSearchFinish())
  }
}

function* sortAlgoliaSearch(action: SortSearchActionModel) {
  try {
    const body: SortAlgoliaSearchModel = action.payload
    const response: ResponseModel = yield call(service.sortAlgoliaSearch, body)

    yield put({
      type: actionTypes.SORT_ALGOLIA_SEARCH_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_ALGOLIA_SEARCH_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_ALGOLIA_SEARCH_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALGOLIA_SEARCH_START, getAlgoliaSearchSaga)
  yield takeLatest(actionTypes.GET_ALL_ALGOLIA_SEARCH_START, getAllAlgoliaSearchSaga)
  yield takeLatest(actionTypes.ADD_ALGOLIA_SEARCH_START, addAlgoliaSearchSaga)
  yield takeLatest(actionTypes.UPDATE_ALGOLIA_SEARCH_START, updateAlgoliaSearchSaga)
  yield takeLatest(actionTypes.DELETE_ALGOLIA_SEARCH_START, deleteAlgoliaSearchSaga)
  yield takeLatest(actionTypes.ENABLE_ALGOLIA_SEARCH_REQUEST, enableAlgoliaSearchSaga)
  yield takeLatest(actionTypes.DISABLE_ALGOLIA_SEARCH_REQUEST, disableAlgoliaSearchSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_REQUEST, singleEnableAlgoliaSearchSaga)
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_REQUEST,
    singleDisableAlgoliaSearchSaga
  )
  yield takeLatest(actionTypes.SORT_ALGOLIA_SEARCH_START, sortAlgoliaSearch)
}
