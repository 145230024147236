import TrashIcon from '@rsuite/icons/Trash'
import {EmptyObject} from 'chart.js/types/basic'
import {useEffect, useState} from 'react'
import {AiOutlineEdit} from 'react-icons/ai'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {IconButton, Pagination, Table, Tag, Toggle, Tooltip, Whisper} from 'rsuite'
import {SortType} from 'rsuite-table/lib/@types/common'
import {ItemType, StateParamsModel} from 'src/app/modules/common/Model'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import {SuccessStoryModel} from '../Model'
import AddSuccessStory from './AddSuccessStory'

import {isEmpty} from 'lodash'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as successStory from '../index'
import {ISuccessStoryState} from '../redux/reducer'

const Cell = Table.Cell

const SuccessStories = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [selectedData, setSelectedData] = useState<Array<string>>([])
  const [editSelectedData, setEditCheckedData] = useState<SuccessStoryModel | EmptyObject>({})
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const successStoryData: ISuccessStoryState = useSelector((state: any) => {
    return state.successStory
  })

  const {success, loading, sortSuccessStoryData} = successStoryData

  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [openSortModal, setOpenSortModal] = useState(false)

  useEffect(() => {
    if (successStoryData?.deleteSuccess) {
      toast.success('Success Story deleted successfully')
    }
    if (successStoryData?.activateSuccess) {
      toast.success('Success Story activated successfully')
    }
    if (successStoryData?.deactivateSuccess) {
      toast.success('Success Story deactivated successfully')
    }
    handleCheck([])
    dispatch(successStory?.actions.getSuccessStoryData(params))
  }, [
    successStoryData?.deleteSuccess,
    successStoryData?.activateSuccess,
    successStoryData?.deactivateSuccess,
  ])

  useEffect(() => {
    if (successStoryData?.singleActivateSuccess) {
      toast.success('Success Story activated successfully')
    }
    if (successStoryData?.singleDeactivateSuccess) {
      toast.success('Success Story deactivated successfully')
    }
    handleCheck([])
  }, [successStoryData?.singleActivateSuccess, successStoryData?.singleDeactivateSuccess])

  const data = successStoryData?.data?.successstory
    ? successStoryData?.data?.successstory?.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        name: item?.title,
        description: item?.description,
        descriptionNp: item?.descriptionNp,
        shortDescription: item?.shortDescription,
        shortDescriptionNp: item?.shortDescriptionNp,
        thumbnailImage: item?.thumbnailImage,
        title: item?.title,
        titleNp: item?.titleNp,
        slug: item?.slug,
        fileType: item?.fileType,
        file: item?.file,
        status: item?.status,
        albumId: item?.album?.id,
        published_date: item?.published_date,
      }))
    : []

  const handleToggleAction = (data: {[key: string]: string}) => {
    const formData = {id: data?.id}
    data?.status
      ? dispatch(successStory.actions.singleDeactivateSuccessStory(formData))
      : dispatch(successStory.actions.singleActivateSuccessStory(formData))
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <IconButton
          appearance='subtle'
          onClick={() => {
            setEditCheckedData(rowData)
            setActionType('Edit')
            setOpen(true)
          }}
          data-cy='edit-button'
          icon={<AiOutlineEdit />}
        />
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let successStoryId = [rowData.id]
                setSelectedData(successStoryId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            checked={rowData.status}
            disabled={successStoryData?.toggleLoading}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Title',
      dataKey: 'name',
      flexGrow: 1,
      cell: <Cell dataKey='title' />,
      sortable: true,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleClose = () => setOpen(false)

  const handleCheck = (data: any) => {
    setSelectedData(data)
  }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  useEffect(() => {
    dispatch(successStory?.actions.getSuccessStoryData(params))
  }, [params])

  const activateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData: any = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(successStory.actions.activateSuccessStory(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const deactivateSelectedData = () => {
    if (!isEmpty(selectedData)) {
      const formData: any = selectedData.map((d: string) => ({
        id: d,
      }))
      dispatch(successStory.actions.deactivateSuccessStory(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }
  const handleDelete = () => {
    let successStorySelectedId = selectedData?.map((value) => ({id: value}))
    dispatch(successStory?.actions.deleteSuccessStoryItem(successStorySelectedId))

    handleAlertClose()
  }
  const handleGetAllData = () => {
    setParams({...params, limit: successStoryData?.data?.meta?.total})
    dispatch(successStory?.actions.getSuccessStoryData(params))
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      successstoryLists: data?.map((item) => ({successstoryId: item?.keyId})),
    }

    dispatch(successStory.actions.sortSuccessStory(body))
  }

  useEffect(() => {
    if (!isEmpty(sortSuccessStoryData) && success) {
      toast.success('Success Story sorted successfully')
      dispatch(successStory.actions.sortSuccessStoryReset())
      setParams({...params, limit: 10})
      dispatch(successStory.actions.getSuccessStoryData(params))
      setOpenSortModal(false)
    }
  }, [sortSuccessStoryData, success])

  const handleRefresh = () => {
    dispatch(successStory?.actions.getSuccessStoryData(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Success Stories'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          exportShow={false}
          importShow={false}
          enableMultiple={activateSelectedData}
          disableMultiple={deactivateSelectedData}
          data={data}
          sortShow={true}
          sortButtonName='Sort'
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleGetAllData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            checkedValues={selectedData}
            showLoading={successStoryData?.loading}
            onChecked={handleCheck}
            handleSort={handleSort}
          />

          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={successStoryData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />

          {open && (
            <AddSuccessStory
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}
        </div>
        {alertOpen &&
          (!isEmpty(selectedData) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => handleAlertClose()}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>{' '}
    </>
  )
}

export default SuccessStories
