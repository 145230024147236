import {ParamsModel} from 'src/app/modules/common/Model'
import { GalleryCategoryTypeModel } from '../Model'
import {actionTypes} from './constants'
import { IGalleryCategoryTypeState } from './reducer'
export const actions = {
    // get Gallery Category Type Data
    getGalleryCategoryType: (params: ParamsModel = {page: 1, limit: 10}) => ({
      type: actionTypes.GET_GALLERY_CATEGORY_TYPE_START,
      payload: params,
    }),
    getGalleryCategoryTypeSuccess: (data: GalleryCategoryTypeModel) => ({
      type: actionTypes.GET_GALLERY_CATEGORY_TYPE_SUCCESS,
      payload: data,
    }),
    getGalleryCategoryTypeFinish: () => ({
      type: actionTypes.GET_GALLERY_CATEGORY_TYPE_FINISH,
    }), 
}