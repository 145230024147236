import {ParamsModel} from 'src/app/modules/common/Model'
import {SearchCategoryModel, SortSearchCategoryModel} from '../Model/SearchCategoryModel'
import {actionTypes} from './constants'
export const actions = {
  // get SearchCategory
  getAllSearchCategory: () => ({
    type: actionTypes.GET_ALL_SEARCH_CATEGORY_START,
  }),
  getAllSearchCategorySuccess: (data: SearchCategoryModel | any) => ({
    type: actionTypes.GET_ALL_SEARCH_CATEGORY_SUCCESS,
    payload: data,
  }),
  getAllSearchCategoryFinish: () => ({
    type: actionTypes.GET_ALL_SEARCH_CATEGORY_FINISH,
  }),

  // get SearchCategory DATA
  getSearchCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_SEARCH_CATEGORY_START,
    payload: params,
  }),
  getSearchCategorySuccess: (data: SearchCategoryModel | any) => ({
    type: actionTypes.GET_SEARCH_CATEGORY_SUCCESS,
    payload: data,
  }),
  getSearchCategoryFinish: () => ({
    type: actionTypes.GET_SEARCH_CATEGORY_FINISH,
  }),

  // create key
  addSearchCategory: (data: SearchCategoryModel | any) => ({
    type: actionTypes.ADD_SEARCH_CATEGORY_START,
    payload: data,
  }),
  addSearchCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_SEARCH_CATEGORY_SUCCESS,
    payload: task,
  }),
  addSearchCategoryFinish: () => ({
    type: actionTypes.ADD_SEARCH_CATEGORY_FINISH,
  }),
  resetSearchCategory: () => ({
    type: actionTypes.RESET_SEARCH_TAG,
  }),

  //Update SearchCategory
  updateSearchCategory: (data: SearchCategoryModel | any, id: string) => ({
    type: actionTypes.UPDATE_SEARCH_CATEGORY_START,
    payload: data,
    id,
  }),

  updateSearchCategorySuccess: (data: SearchCategoryModel | any) => ({
    type: actionTypes.UPDATE_SEARCH_CATEGORY_SUCCESS,
    payload: data,
  }),

  updateSearchCategoryFinish: () => ({
    type: actionTypes.UPDATE_SEARCH_CATEGORY_FINISH,
  }),

  // delete key
  deleteSearchCategory: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_SEARCH_CATEGORY_START,
    payload: {searchCategoryId: data},
  }),
  deleteSearchCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_SEARCH_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteSearchCategoryFinish: () => ({
    type: actionTypes.DELETE_SEARCH_CATEGORY_FINISH,
  }),

  //Enable SearchCategory
  enableSearchCategory: (data: any) => ({
    type: actionTypes.ENABLE_SEARCH_CATEGORY_REQUEST,
    payload: {data},
  }),

  enableSearchCategorySuccess: (task: any) => ({
    type: actionTypes.ENABLE_SEARCH_CATEGORY_SUCCESS,
    payload: task,
  }),
  enableSearchCategoryFinish: () => ({
    type: actionTypes.ENABLE_SEARCH_CATEGORY_FINISH,
  }),

  //Disable SearchCategory
  disableSearchCategory: (data: any) => ({
    type: actionTypes.DISABLE_SEARCH_CATEGORY_REQUEST,
    payload: {data},
  }),

  disableSearchCategorySuccess: (task: any) => ({
    type: actionTypes.DISABLE_SEARCH_CATEGORY_SUCCESS,
    payload: task,
  }),
  disableSearchCategoryFinish: () => ({
    type: actionTypes.DISABLE_SEARCH_CATEGORY_FINISH,
  }),

  //Enable SearchCategory
  singleEnableSearchCategory: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleEnableSearchCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleEnableSearchCategoryFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_SEARCH_CATEGORY_FINISH,
  }),

  //Disable SearchCategory
  singleDisableSearchCategory: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_REQUEST,
    payload: {data},
  }),

  singleDisableSearchCategorySuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_SUCCESS,
    payload: task,
  }),
  singleDisableSearchCategoryFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_SEARCH_CATEGORY_FINISH,
  }),

  // sort
  sortSearchCategory: (data: SortSearchCategoryModel) => ({
    type: actionTypes.SORT_SEARCH_CATEGORY_START,
    payload: data,
  }),
  sortSearchCategorySuccess: (data: Array<SearchCategoryModel>) => ({
    type: actionTypes.SORT_SEARCH_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortSearchCategoryFinish: () => ({
    type: actionTypes.SORT_SEARCH_CATEGORY_FINISH,
  }),
  sortSearchCategoryReset: () => ({
    type: actionTypes.SORT_SEARCH_CATEGORY_RESET,
  }),
}
