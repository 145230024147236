import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap-v5'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
// import * as ProgramRedux from 'src/app/modules/programs/programManager'
import { BannerModel } from 'src/app/modules/cms/components/banner/Model'
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import { GalleryModel } from 'src/app/modules/cms/components/gallery/Model'
import * as galleryRedux from 'src/app/modules/cms/components/gallery/redux'
import { StateParamsModel } from 'src/app/modules/common/Model'
import * as downloadRedux from 'src/app/modules/formsAndDownload/components/formsDownload/index'
import * as ProgramCategoryRedux from 'src/app/modules/programs/programCategory'
import * as ProgramsRedux from 'src/app/modules/programs/programManager'
import * as Yup from 'yup'
import { ProgramCategoryModel } from '../../programCategory/Model/ProgramCategoryModel'
import { ProgramOptionModal } from '../Model/ProgramModel'
import { AdditionalSection } from './TabComponents/AdditionalSection'
import { AlbumComponent } from './TabComponents/AlbumComponent'
import { BannerComponent } from './TabComponents/BannerComponent'
import { DownloadComponent } from './TabComponents/DownloadComponent'
import { GeneralComponent } from './TabComponents/GeneralComponent'
import { RelatedProgramComponent } from './TabComponents/RelatedProgramComponent'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = {
  title: Yup.string().required('Program title is required'),
  status: Yup.string().required('Status is required'),
}

const AddProgramModal = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const [validationState, setValidationState] = useState(FORM_VALIDATION)
  const {
    bannerOption,
    albumOption,
    downloadOption,
    leadOption,
    pageHeaderOption,
    relatedOptions,
    loading,
    programList,
    success,
  } = useSelector((state: any) => state.program)

  const { data } = useSelector((state: any) => state.formsAndDownload)
  const {
    data: { programCategory },
  } = useSelector((state: any) => state.programCategory)

  const {
    data: { banner },
  } = useSelector((state: any) => state.banner)

  const {
    data: { album },
  } = useSelector((state: any) => state.gallery)

  useEffect(() => {
    dispatch(ProgramsRedux.actions.getBannerOption())
    dispatch(ProgramsRedux.actions.getAlbumOption())
    dispatch(ProgramsRedux.actions.getDownloadOption())
    dispatch(ProgramsRedux.actions.getPageHeader())
    dispatch(ProgramsRedux.actions.getLeadForm())
    dispatch(ProgramsRedux.actions.getProgramOption())
    dispatch(ProgramsRedux.actions.getAllProgram())
    dispatch(ProgramCategoryRedux.actions.getAllProgramCategory())
    dispatch(bannerRedux.actions.getAllBanner())
    dispatch(galleryRedux.actions.getAllGallery())
    dispatch(downloadRedux.actions.getFormsDownload({ limit: 100 }))
  }, [])

  //Create options from API for FormSelect
  const categoryOptions = programCategory?.map((items: ProgramCategoryModel) => ({
    label: items?.name,
    value: items?.id,
  }))

  const pageHeaderOptions = pageHeaderOption?.map((items: ProgramOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  const leadOptions = leadOption?.map((items: ProgramOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  const bannerOptions = bannerOption?.map((items: ProgramOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const bannerData = banner?.map((items: BannerModel) => ({
    label: items.name,
    value: items.id,
  }))

  const albumOptions = albumOption?.map((items: ProgramOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))
  const albumData = album?.map((items: GalleryModel) => ({
    label: items.title,
    value: items.id,
  }))

  const relatedOptionOptions = relatedOptions?.map((items: ProgramOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  const relatedDataOptions = programList?.program?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  const downloadDataOptions = data?.download?.map((items: any) => ({
    label: items.title,
    value: items.id,
  }))

  const downloadOptionOptions = downloadOption?.map((items: ProgramOptionModal) => ({
    label: items.displayName,
    systemName: items.systemName,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Program added successfully')
        : toast.success('Program edited successfully')
      dispatch(ProgramsRedux.actions.resetProgram())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        style={{ minHeight: '90vh' }}
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Program</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '80vh' }}>
          <div>
            <Formik
              validationSchema={Yup.object().shape(validationState)}
              initialValues={{
                category_id: '',
                title: '',
                slug: '',
                status: '',
                short_description: '',
                long_description: '',
                image: '',
                page_header_id: '',
                lead_option_id: '',

                banner_option_id: '',
                banner_id: '',

                album_option_id: '',
                album_id: '',

                related_option_id: '',
                relatedProgramData: [],

                download_option_id: '',
                downloadSection: [],

                bannerDetails: [
                  {
                    title: '',
                    subtitle: '',
                    description: '',
                    image: '',
                    first_button_text: '',
                    first_button_url: '',
                    second_button_text: '',
                    second_button_url: '',
                  },
                ],
                additionalDetails: [{ text: '', description: '', order: '' }],
              }}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  additionalDetails: values?.additionalDetails?.map(
                    (item: { [key: string]: string }) => ({
                      ...item,
                      id: !isEmpty(editSelectedData) ? item.id : null,
                    })
                  ),

                  bannerDetails: values?.bannerDetails?.map((item: { [key: string]: string }) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),

                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.title.replace(/\s/g, '-').toLowerCase(),

                  relatedProgramData: !isEmpty(values?.relatedProgramData)
                    ? values?.relatedProgramData?.map((item: { id: string }) => ({
                      id: item,
                    }))
                    : null,

                  downloadSection: !isEmpty(values?.downloadSection)
                    ? values?.downloadSection?.map((item: { id: string }) => ({
                      id: item,
                    }))
                    : null,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(ProgramsRedux.actions.updateProgram(formData, editSelectedData?.id))
                } else {
                  dispatch(ProgramsRedux.actions.addProgram(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'category_id',
                      'title',
                      'slug',
                      'short_description',
                      'long_description',
                      'lead_option_id',
                      'page_header_id',
                      'image',

                      'banner_option_id',
                      'banner_id',

                      'album_option_id',
                      'album_id',

                      'related_option_id',
                      'relatedProgramData',
                      'download_option_id',
                      'downloadSection',
                      'additionalDetails',
                      'bannerDetails',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                useEffect(() => {
                  //Default Value No
                  if (actionType === 'Add') {
                    setFieldValue(
                      'page_header_id',
                      pageHeaderOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'lead_option_id',
                      leadOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),

                      false
                    )
                    setFieldValue(
                      'banner_option_id',
                      bannerOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'album_option_id',
                      albumOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                    setFieldValue(
                      'related_option_id',
                      relatedOptionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )

                    setFieldValue(
                      'download_option_id',
                      downloadOptionOptions
                        ?.filter((items: any) => items.systemName === 'no')
                        .map((item: any) => item.value)
                        .toString(),
                      false
                    )
                  }
                }, [
                  downloadOptionOptions?.length,
                  bannerOptions?.length,
                  pageHeaderOptions?.length,
                  leadOptions?.length,
                  relatedDataOptions?.length,
                  relatedOptionOptions?.length,
                ])

                return (
                  <Form>
                    <div className='card-body border-top'>
                      <div className=''>
                        <div className='row'>
                          <Tabs
                            defaultActiveKey='general'
                            id='uncontrolled-tab-example'
                            className='mb-5'
                          >
                            <Tab eventKey='general' title='General'>
                              <div className='card-body bg-white'>
                                <GeneralComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  leadFormOptions={leadOptions}
                                  pageHeaderOptions={pageHeaderOptions}
                                  categoryOptions={categoryOptions}
                                  editSelectedData={editSelectedData}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>

                            <Tab eventKey='bannerSection' title='Banner Section'>
                              <div className='card-body bg-white'>
                                <BannerComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  bannerOptionOptions={bannerOptions}
                                  // bannerDataOption={bannerData}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                  editSelectedData={editSelectedData}
                                />
                              </div>
                            </Tab>

                            <Tab eventKey='albumSection' title='Album Section'>
                              <div className='card-body bg-white'>
                                <AlbumComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  albumOptionOptions={albumOptions}
                                  albumDataOption={albumData}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>

                            <Tab eventKey='relatedSection' title='Program Related Section'>
                              <div className='card-body bg-white'>
                                <RelatedProgramComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  programOptionOptions={relatedOptionOptions}
                                  programDataOption={relatedDataOptions}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>

                            <Tab eventKey='downloadSection' title='Download Section'>
                              <div className='card-body bg-white'>
                                <DownloadComponent
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  downloadOptionOptions={downloadOptionOptions}
                                  downloadDataOption={downloadDataOptions}
                                  validationState={validationState}
                                  setValidationState={setValidationState}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </Tab>

                            <Tab eventKey='additionalSection' title='Additional Section'>
                              <div className='card-body bg-white'>
                                <AdditionalSection
                                  handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  editSelectedData={editSelectedData}
                                />
                              </div>
                            </Tab>
                          </Tabs>
                        </div>

                        <div className='d-flex justify-content-end px-9 '>
                          <button
                            type='submit'
                            disabled={loading}
                            className='btn btn-primary btn-sm ms-3'
                            data-cy='modal-submit'
                          >
                            Save
                          </button>
                          <button
                            type='button'
                            onClick={handleClose}
                            className='btn btn-secondary btn-sm ms-3'
                            data-cy='modal-cancel'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddProgramModal
