import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getAllCardMaster: (id: string = '') => ({
    type: actionTypes.GET_ALL_CARD_MASTER_START,
    payload: id,
  }),

  getAllCardMasterSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ALL_CARD_MASTER_SUCCESS,
    payload: data,
  }),
  getAllCardMasterFinish: () => ({
    type: actionTypes.GET_ALL_CARD_MASTER_FINISH,
  }),
  getAllCardMasterError: (error: unknown) => ({
    type: actionTypes.GET_ALL_CARD_MASTER_FINISH,
    payload: {error},
  }),

  getSpecificCardMaster: (id: string = '') => ({
    type: actionTypes.GET_SPECIFIC_CARD_MASTER_START,
    payload: id,
  }),

  getSpecificCardMasterSuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_SPECIFIC_CARD_MASTER_SUCCESS,
    payload: data,
  }),
  getSpecificCardMasterFinish: () => ({
    type: actionTypes.GET_SPECIFIC_CARD_MASTER_FINISH,
  }),
  getSpecificCardMasterError: (error: unknown) => ({
    type: actionTypes.GET_SPECIFIC_CARD_MASTER_FINISH,
    payload: {error},
  }),

  getCardMaster: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_CARD_MASTER_START,
    payload: params,
  }),

  getCardMasterSuccess: (data: any) => ({
    type: actionTypes.GET_CARD_MASTER_SUCCESS,
    payload: data,
  }),
  getCardMasterFinish: () => ({
    type: actionTypes.GET_CARD_MASTER_FINISH,
  }),
  getCardMasterError: (error: unknown) => ({
    type: actionTypes.GET_CARD_MASTER_FINISH,
    payload: {error},
  }),

  addCardMaster: (data: any) => ({
    type: actionTypes.ADD_CARD_MASTER_START,
    payload: data,
  }),
  addCardMasterSuccess: (task: any) => ({
    type: actionTypes.ADD_CARD_MASTER_SUCCESS,
    payload: task,
  }),
  addCardMasterFinish: () => ({
    type: actionTypes.ADD_CARD_MASTER_FINISH,
  }),
  addCardMasterReset: () => ({
    type: actionTypes.ADD_CARD_MASTER_RESET,
  }),

  updateCardMaster: (data: any, id: string) => ({
    type: actionTypes.UPDATE_CARD_MASTER_START,
    payload: {data, id},
  }),
  updateCardMasterSuccess: (task: any) => ({
    type: actionTypes.UPDATE_CARD_MASTER_SUCCESS,
    payload: task,
  }),
  updateCardMasterFinish: () => ({
    type: actionTypes.UPDATE_CARD_MASTER_FINISH,
  }),
  updateCardMasterReset: () => ({
    type: actionTypes.UPDATE_CARD_MASTER_RESET,
  }),

  deleteCardMaster: (data: any) => ({
    type: actionTypes.DELETE_CARD_MASTER_START,
    payload: {cardMasterId: data},
  }),
  deleteCardMasterSuccess: (data: any) => ({
    type: actionTypes.DELETE_CARD_MASTER_SUCCESS,
    payload: data,
  }),
  deleteCardMasterFinish: () => ({
    type: actionTypes.DELETE_CARD_MASTER_FINISH,
  }),
  activateCardMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CARD_MASTER_REQUEST,
    payload: {cardMasterId: id},
  }),
  deactivateCardMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CARD_MASTER_REQUEST,
    payload: {cardMasterId: id},
  }),
  singleActivateCardMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_CARD_MASTER_REQUEST,
    payload: {cardMasterId: [id]},
  }),
  singleDeactivateCardMaster: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_CARD_MASTER_REQUEST,
    payload: {cardMasterId: [id]},
  }),

  sortCardMaster: (data: any) => ({
    type: actionTypes.SORT_CARD_MASTER_START,
    payload: data,
  }),
  sortCardMasterSuccess: (data: any) => ({
    type: actionTypes.SORT_CARD_MASTER_SUCCESS,
    payload: data,
  }),
  sortCardMasterFinish: () => ({
    type: actionTypes.SORT_CARD_MASTER_FINISH,
  }),
  sortCardMasterReset: () => ({
    type: actionTypes.SORT_CARD_MASTER_RESET,
  }),
}
