import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from '../../../../../../cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {SortHomepageActionModel, SortHomepageSettingModel} from '../Model'
import {SortActionModel} from 'src/app/modules/cms/components/categories/Model'

function* getHomepageSectionSaga() {
  try {
    const response: ResponseModel = yield call(service.getHomepageSection)
    const data: any = response?.data?.data
    yield put(actions.getHomepageSectionSuccess(data))
    yield put(actions.getHomepageSectionFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getHomepageSectionFinish())
  }
}

function* getHomepageLayoutSaga() {
  try {
    const response: ResponseModel = yield call(service.getHomepageLayout)
    const data: any = response?.data?.data
    yield put(actions.getHomepageLayoutSuccess(data))
    yield put(actions.getHomepageLayoutFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getHomepageLayoutFinish())
  }
}

function* addHomepageSettingSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addHomepageSetting, body)
    const data: any = response?.data?.data
    yield put(actions.addHomepageSettingSuccess(data))
    yield put(actions.addHomepageSettingFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addHomepageSettingFinish())
  }
}

function* updateHomepageSettingSaga(action: ActionModel) {
  try {
    const body: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.updateHomepageSetting,
      body,
      action.payload?.id
    )
    yield put(actions.updateHomepageSettingSuccess(response.data?.data))
    yield put(actions.updateHomepageSettingFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateHomepageSettingFinish())
  }
}

function* getHomepageSettingSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getHomepageSetting, params)
    const data: any = response?.data?.data
    yield put(actions.getHomepageSettingSuccess(data))
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getHomepageSettingFinish())
  }
}

function* singleEnableHomepageSettingSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableHomepageSetting, selectedUsers)
    yield put(actions.singleEnableHomepageSettingSuccess(response?.data))
    yield put(actions.singleEnableHomepageSettingFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableHomepageSettingFinish())
  }
}

function* singleDisableHomepageSettingSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableHomepageSetting, selectedUsers)
    yield put(actions.singleDisableHomepageSettingSuccess(response?.data))
    yield put(actions.singleDisableHomepageSettingFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableHomepageSettingFinish())
  }
}

function* sortHomepageSetting(action: SortHomepageActionModel) {
  try {
    const body: SortHomepageSettingModel = action.payload
    const response: ResponseModel = yield call(service.sortHomepageSetting, body)

    yield put({
      type: actionTypes.SORT_HOMEPAGE_SETTING_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_HOMEPAGE_SETTING_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_HOMEPAGE_SETTING_FINISH})
  }
}

function* deleteHomepageSettingSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteHomepageSetting, body)
    yield put(actions.deleteHomepageSettingSuccess(response.data?.data))
    yield put(actions.deleteHomepageSettingFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteHomepageSettingFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_HOMEPAGE_SECTION_START, getHomepageSectionSaga)
  yield takeLatest(actionTypes.GET_HOMEPAGE_LAYOUT_START, getHomepageLayoutSaga)
  yield takeLatest(actionTypes.ADD_HOMEPAGE_SETTING_START, addHomepageSettingSaga)
  yield takeLatest(actionTypes.UPDATE_HOMEPAGE_SETTING_START, updateHomepageSettingSaga)
  yield takeLatest(actionTypes.GET_HOMEPAGE_SETTING_START, getHomepageSettingSaga)
  yield takeLatest(actionTypes.DELETE_HOMEPAGE_SETTING_START, deleteHomepageSettingSaga)
  yield takeLatest(
    actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_REQUEST,
    singleEnableHomepageSettingSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_REQUEST,
    singleDisableHomepageSettingSaga
  )
  yield takeLatest(actionTypes.SORT_HOMEPAGE_SETTING_START, sortHomepageSetting)
}
