export const actionTypes = {
  //Get user role permission
  GET_USER_ROLE_PERMISSION_REQUEST: 'GET_USER_ROLE_PERMISSION_REQUEST',
  GET_USER__ROLE_PERMISSION_SUCCESS: 'GET_USER__ROLE_PERMISSION_SUCCESS',
  GET_USER_ROLE_PERMISSION_FINISH: 'GET_USER_ROLE_PERMISSION_FINISH',

  //Get province permission
  GET_PROVINCE_REQUEST: 'GET_PROVINCE_REQUEST',
  GET_PROVINCE_SUCCESS: 'GET_PROVINCE_SUCCESS',
  GET_PROVINCE_FINISH: 'GET_PROVINCE_FINISH',

  //Get district permission
  GET_DISTRICT_REQUEST: 'GET_DISTRICT_REQUEST',
  GET_DISTRICT_SUCCESS: 'GET_DISTRICT_SUCCESS',
  GET_DISTRICT_FINISH: 'GET_DISTRICT_FINISH',

  //Get Municipality permission
  GET_MUNICIPALITY_REQUEST: 'GET_MUNICIPALITY_REQUEST',
  GET_MUNICIPALITY_SUCCESS: 'GET_MUNICIPALITY_SUCCESS',
  GET_MUNICIPALITY_FINISH: 'GET_MUNICIPALITY_FINISH',

  //Get category type
  GET_CATEGORY_TYPE_REQUEST: 'GET_CATEGORY_TYPE_REQUEST',
  GET_CATEGORY_TYPE_SUCCESS: 'GET_CATEGORY_TYPE_SUCCESS',
  GET_CATEGORY_TYPE_FINISH: 'GET_CATEGORY_TYPE_FINISH',

  //Get category type
  GET_MEDIA_TYPE_REQUEST: 'GET_MEDIA_TYPE_REQUEST',
  GET_MEDIA_TYPE_SUCCESS: 'GET_MEDIA_TYPE_SUCCESS',
  GET_MEDIA_TYPE_FINISH: 'GET_MEDIA_TYPE_FINISH',
}
