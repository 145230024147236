import { ErrorMessage, FieldArray, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'

import { isEmpty } from 'lodash'
import { StateParamsModel } from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import { imageBaseUrl } from 'src/cms/helpers/constants'
import * as productCategoryRedux from '../../productCategory'
import { ProductCategoryModel } from '../../productCategory/Model/ProductCategoryModel'
import * as midMenu from '../index'
type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  child: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Title is required'),
    })
  ),
  status: Yup.string().required('Status is required'),
})

const AddMidMenuManager = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()

  const [imagePath, setImagePath] = useState<any>('')

  const { loading, success } = useSelector((state: any) => state.productMidMenu)
  const {
    data: { productCategory },
  } = useSelector((state: any) => state.productCategory)

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  const featuredOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  useEffect(() => {
    dispatch(productCategoryRedux.actions.getAllProductCategory())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(midMenu?.actions.getMidMenu(params))
      isEmpty(editSelectedData)
        ? toast.success('Mid Menu added successfully')
        : toast.success('Mid Menu edited successfully')
      dispatch(midMenu?.actions?.addMidMenuReset())
      handleClose()
    }
  }, [success])

  const productCategoryOptions = productCategory?.map((items: ProductCategoryModel) => ({
    label: items.name,
    value: items.id,
  }))

  return (
    <div className='modal-container' data-cy='mid-menu-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy="modal-title">{actionType} Mid Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                productCategoryId: '',
                is_featured: 'Active',
                child: [
                  {
                    title: '',
                    title_np: '',
                    image: '',
                    url: '',
                    order: '',
                  },
                ],
                status: 'Active',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { setSubmitting }) => {
                const formData = {
                  ...values,
                  child: values?.child?.map((item: { [key: string]: string }) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                  is_featured: values?.is_featured === 'Active' ? true : false,
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(midMenu.actions.updateMidMenu(formData, editSelectedData?.id))
                } else {
                  dispatch(midMenu.actions.addMidMenu(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['productCategoryId', 'is_featured', 'child', 'status']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'is_featured',
                      editSelectedData?.is_featured === '1' ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select product'
                            label='Product Category'
                            name='productCategoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={productCategoryOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='mid-menu-category-select'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Featured
                          </label>

                          <div className='d-flex ms-5'>
                            {featuredOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Featured'
                                name='is_featured'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='mid-menu-featured-radio'
                              />
                            ))}
                          </div>
                        </div>
                        {/* Mid Menu Section */}
                        <div className='row pt-5 border-top'>
                          <h2>Mid Menu Section</h2>
                          <div className='row'>
                            <FieldArray
                              name='child'
                              render={(arrayHelpers) => (
                                <div>
                                  {values?.child && values?.child?.length > 0
                                    ? values?.child?.map((friend: any, index: any) => (
                                      <div className='row' key={index}>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Enter Title (EN)'
                                            name={`child.${index}.title`}
                                            label='Title (EN)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            required={true}
                                            touched={touched}
                                            data-cy='mid-menu-title-text-field'
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Enter Title (NP)'
                                            name={`child.${index}.title_np`}
                                            label='Title (NP)'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='mid-menu-title-np-text-field'
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Enter Link'
                                            name={`child.${index}.url`}
                                            label='Link'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='mid-menu-link-text-field'
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            placeholder='Enter Order'
                                            name={`child.${index}.order`}
                                            label='Order'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            data-cy='mid-menu-order-text-field'
                                          />
                                        </div>

                                        <div className='col-md-6 col-xs-12 mb-2'>
                                          <FormInputMediaManager
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            label='Upload Icon'
                                            name={`child.${index}.image`}
                                            setFieldValue={setFieldValue}
                                            setImageUrl={setImagePath}
                                            value={values?.child[index]?.image}
                                            data-cy='mid-menu-upload-image-area'
                                          />
                                          {!isEmpty(values?.child[index]?.image) ? (
                                            <>
                                              <li className='listing'>
                                                <div className='thumbImageBlock'>
                                                  <button
                                                    type='button'
                                                    title='Remove'
                                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                    onClick={() => {
                                                      setImagePath('')
                                                      setFieldValue(`child.${index}.image`, '')
                                                    }}
                                                    data-cy='modal-thumbnail-remove'
                                                  >
                                                    X
                                                  </button>

                                                  <img
                                                    className='thumbImage w-100 h-100'
                                                    src={`${imageBaseUrl}/${values?.child[index]?.image}`}
                                                    alt=''
                                                    data-cy='modal-thumbnail-remove'
                                                  />
                                                </div>
                                              </li>
                                            </>
                                          ) : null}
                                        </div>
                                        {index > 0 && (
                                          <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                            <button
                                              type='button'
                                              className='p-2 ps-5 pe-5 btn btn-secondary'
                                              onClick={() => arrayHelpers.remove(index)}
                                              data-cy='modal-field-remove'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))
                                    : null}
                                  {values?.child?.length == 4 ? null : (
                                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                      <button
                                        className='p-2 ps-5 pe-5 btn btn-primary'
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: null,
                                            title: '',
                                            title_np: '',
                                            url: '',
                                            image: '',
                                          })
                                        }
                                        data-cy='modal-field-add'
                                      >
                                        + Add More
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddMidMenuManager
