import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {AuctionTypeModel, SortAuctionTypeModel} from '../Model/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const AUCTION_TYPE_API = `${API_URL}/auction-type`

export const service = {
  getAuctionType: (params: ParamsModel) => {
    return axios.get(AUCTION_TYPE_API, {params})
  },
  getAllAuctionType: () => {
    return axios.get(`${AUCTION_TYPE_API}/list`)
  },

  addAuctionType: (data: any) => {
    return axios.post(AUCTION_TYPE_API, data)
  },

  updateAuctionType: (body: AuctionTypeModel, id: string) => {
    return axios.put(`${AUCTION_TYPE_API}/${id}`, body)
  },

  deleteAuctionType: (data: {id: string}) => {
    return axios.delete(`${AUCTION_TYPE_API}/bulk-delete`, {data})
  },

  enableAuctionType: (data: Array<string>) => {
    const selectedAuctionType = {
      auctionTypeId: data,
    }
    return axios.patch(`${AUCTION_TYPE_API}/enable`, selectedAuctionType)
  },

  disableAuctionType: (data: Array<string>) => {
    const selectedAuctionType = {
      auctionTypeId: data,
    }
    return axios.patch(`${AUCTION_TYPE_API}/disable`, selectedAuctionType)
  },

  singleEnableAuctionType: (data: Array<string>) => {
    const selectedAuctionType = {
      auctionTypeId: [data],
    }
    return axios.patch(`${AUCTION_TYPE_API}/enable`, selectedAuctionType)
  },

  singleDisableAuctionType: (data: Array<string>) => {
    const selectedAuctionType = {
      auctionTypeId: [data],
    }
    return axios.patch(`${AUCTION_TYPE_API}/disable`, selectedAuctionType)
  },
  sortAuctionType: (body: SortAuctionTypeModel) => {
    return axios.patch(`${AUCTION_TYPE_API}/sort`, body)
  },
}
