import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {AlgoliaSearchModel, SortAlgoliaSearchModel} from '../Model/AlgoliaSearchModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const ALGOLIA_SEARCH_API = `${API_URL}/algolia-searches`

export const service = {
  getAlgoliaSearch: (params: ParamsModel) => {
    return axios.get(ALGOLIA_SEARCH_API, {params})
  },
  getAllAlgoliaSearch: () => {
    return axios.get(`${ALGOLIA_SEARCH_API}/list`)
  },

  addAlgoliaSearch: (data: any) => {
    return axios.post(ALGOLIA_SEARCH_API, data)
  },

  updateAlgoliaSearch: (body: AlgoliaSearchModel, id: string) => {
    return axios.patch(`${ALGOLIA_SEARCH_API}/${id}`, body)
  },

  deleteAlgoliaSearch: (data: {id: string}) => {
    return axios.delete(ALGOLIA_SEARCH_API, {data})
  },

  enableAlgoliaSearch: (data: Array<string>) => {
    const selectedAlgoliaSearch = {
      algoliaId: data,
    }
    return axios.patch(`${ALGOLIA_SEARCH_API}/enable`, selectedAlgoliaSearch)
  },

  disableAlgoliaSearch: (data: Array<string>) => {
    const selectedAlgoliaSearch = {
      algoliaId: data,
    }
    return axios.patch(`${ALGOLIA_SEARCH_API}/disable`, selectedAlgoliaSearch)
  },

  singleEnableAlgoliaSearch: (data: Array<string>) => {
    const selectedAlgoliaSearch = {
      algoliaId: [data],
    }
    return axios.patch(`${ALGOLIA_SEARCH_API}/enable`, selectedAlgoliaSearch)
  },

  singleDisableAlgoliaSearch: (data: Array<string>) => {
    const selectedAlgoliaSearch = {
      algoliaId: [data],
    }
    return axios.patch(`${ALGOLIA_SEARCH_API}/disable`, selectedAlgoliaSearch)
  },
  sortAlgoliaSearch: (body: SortAlgoliaSearchModel) => {
    return axios.patch(`${ALGOLIA_SEARCH_API}/sort`, body)
  },
}
