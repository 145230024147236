import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteModel, SortCsrCategoryModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CSR_CATEGORY = `${API_URL}/csr-category`

export const service = {
  getCsrCategory: (params: ParamsModel) => {
    return axios.get(CSR_CATEGORY, {params})
  },
  getCsrCategoryDropdown: () => {
    return axios.get(`${CSR_CATEGORY}/list`)
  },

  addCsrCategory: (data: any) => {
    return axios.post(CSR_CATEGORY, data)
  },

  updateCsrCategory: (body: any, id: string) => {
    return axios.put(`${CSR_CATEGORY}/${id}`, body)
  },

  deleteCsrCategory: (data: DeleteModel) => {
    return axios.delete(`${CSR_CATEGORY}/bulk-delete`, {data})
  },
  singleActivateCsrCategory: (id: any) => {
    return axios.patch(`${CSR_CATEGORY}/enable`, id)
  },

  singleDeactivateCsrCategory: (id: any) => {
    return axios.patch(`${CSR_CATEGORY}/disable`, id)
  },

  sortCsrCategory: (body: SortCsrCategoryModel) => {
    return axios.patch(`${CSR_CATEGORY}/sort`, body)
  },
}
