export const actionTypes = {
  GET_APPLICATION_START: 'GET_APPLICATION_START',
  GET_APPLICATION_SUCCESS: 'GET_APPLICATION_SUCCESS',
  GET_APPLICATION_FINISH: 'GET_APPLICATION_FINISH',
  RESET_APPLY_LOAN: 'RESET_APPLY_LOAN',

  DELETE_APPLICATION_START: 'DELETE_APPLICATION_START',
  DELETE_APPLICATION_SUCCESS: 'DELETE_APPLICATION_SUCCESS',
  DELETE_APPLICATION_FINISH: 'DELETE_APPLICATION_FINISH',
}
