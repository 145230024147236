import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialTutoriaState: ILoanTenureState = {
  data: {
    loanTenure: [],
    meta: {},
  },
  deleteSuccess: false,
  loading: false,
  success: false,
}

export interface ILoanTenureState {
  data?: {
    loanTenure?: []
    meta?: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ILoanTenureState = initialTutoriaState,
  action: ActionWithPayload<ILoanTenureState>
) => {
  switch (action.type) {
    case actionTypes.GET_LOAN_TENURE_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_LOAN_TENURE_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_LOAN_TENURE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_LOAN_TENURE_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_LOAN_TENURE_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_LOAN_TENURE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_LOAN_TENURE_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_LOAN_TENURE_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_LOAN_TENURE_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_LOAN_TENURE_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.DELETE_LOAN_TENURE_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_LOAN_TENURE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_LOAN_TENURE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_LOAN_TENURE_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_LOAN_TENURE_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    case actionTypes.SORT_LOAN_TENURE_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_LOAN_TENURE_SUCCESS: {
      return {
        ...state,
        sortLoanTenureData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_LOAN_TENURE_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_LOAN_TENURE_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortLoanTenureData: [],
      }
    }

    default:
      return state
  }
}
