import { Action } from 'redux'
import { OptionModel } from '../Model'
import { AgencyModel } from '../Model/AgencyModel'
import { actionTypes } from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialAgencyState: IAgencyState = {
  category: [],
  agencyExtendedHourStatus: [],
  data: {
    agency: [],
    meta: [],
  },
  importSuccess: false,
  exportSuccess: false,
  templateExport: false,
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IAgencyState {
  data?: {
    agency?: AgencyModel[]
    meta?: { [key: string]: string | number }[]
  }
  category?: OptionModel[]
  agencyExtendedHourStatus?: OptionModel[]
  exportSuccess?: boolean
  templateExport?: boolean
  deleteSuccess?: boolean
  importSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IAgencyState = initialAgencyState,
  action: ActionWithPayload<IAgencyState>
) => {
  switch (action.type) {
    case actionTypes.GET_AGENCY_CATEGORY_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_AGENCY_CATEGORY_SUCCESS: {
      return { ...state, category: action.payload?.category, loading: false }
    }

    case actionTypes.GET_AGENCY_CATEGORY_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //EXTENDED HOURS
    case actionTypes.GET_EXTENDED_HOURS_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_EXTENDED_HOURS_SUCCESS: {
      return {
        ...state,
        agencyExtendedHourStatus: action.payload?.agencyExtendedHourStatus,
        loading: false,
      }
    }

    case actionTypes.GET_EXTENDED_HOURS_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //GET AGENCY DATA
    case actionTypes.GET_AGENCY_DATA_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_AGENCY_DATA_SUCCESS: {
      return { ...state, data: action.payload, loading: false }
    }

    case actionTypes.GET_AGENCY_DATA_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //GET AGENCY DATA
    case actionTypes.GET_AGENCY_LIST_DATA_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_AGENCY_LIST_DATA_SUCCESS: {
      return { ...state, data: action.payload, loading: false }
    }

    case actionTypes.GET_AGENCY_LIST_DATA_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //GET ALL AGENCY DATA
    case actionTypes.GET_ALL_AGENCY_DATA_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_ALL_AGENCY_DATA_SUCCESS: {
      return { ...state, data: action.payload, loading: false }
    }

    case actionTypes.GET_ALL_AGENCY_DATA_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //Add Agency
    case actionTypes.ADD_AGENCY_START: {
      return { ...state, loading: true }
    }

    case actionTypes.ADD_AGENCY_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_AGENCY_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    case actionTypes.RESET_AGENCY: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update Agency
    case actionTypes.UPDATE_AGENCY_START: {
      return { ...state, loading: true }
    }

    case actionTypes.UPDATE_AGENCY_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_AGENCY_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //Delete
    case actionTypes.DELETE_AGENCY_START: {
      return { ...state, loading: true }
    }

    case actionTypes.DELETE_AGENCY_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_AGENCY_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_AGENCY_REQUEST: {
      return { ...state, loading: true }
    }

    case actionTypes.ENABLE_AGENCY_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_AGENCY_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_AGENCY_REQUEST: {
      return { ...state, loading: true }
    }

    case actionTypes.DISABLE_AGENCY_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_AGENCY_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }

    //Enable
    case actionTypes.SINGLE_ENABLE_AGENCY_REQUEST: {
      return { ...state, toggleLoading: true }
    }

    case actionTypes.SINGLE_ENABLE_AGENCY_SUCCESS: {
      const changedData: any = action.payload?.data?.agency
      let newData = state?.data?.agency?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: { ...state, meta: state?.data?.meta, agency: newData },
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_AGENCY_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_AGENCY_REQUEST: {
      return { ...state, toggleLoading: true }
    }

    case actionTypes.SINGLE_DISABLE_AGENCY_SUCCESS: {
      const changedData: any = action.payload?.data?.agency
      let newData = state?.data?.agency?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: { ...state, meta: state?.data?.meta, agency: newData },
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_AGENCY_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Import
    case actionTypes.IMPORT_AGENCY_START: {
      return { ...state, loading: true }
    }

    case actionTypes.IMPORT_AGENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        importSuccess: true,
      }
    }

    case actionTypes.IMPORT_AGENCY_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false, importSuccess: false }
    }

    case actionTypes.EXPORT_AGENCY_FILE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.EXPORT_AGENCY_FILE_SUCCESS: {
      return { ...state, exportSuccess: true, loading: false }
    }

    case actionTypes.EXPORT_AGENCY_FILE_FINISH: {
      const error = action.payload
      return { ...state, error, exportSuccess: false, loading: false }
    }

    case actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_SUCCESS: {
      return { ...state, templateExport: true, loading: false }
    }

    case actionTypes.EXPORT_AGENCY_TEMPLATE_FILE_FINISH: {
      const error = action.payload
      return { ...state, error, templateExport: false, loading: false }
    }

    default:
      return state
  }
}
