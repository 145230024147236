import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'

// includes
import moment from 'moment'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as activityLogsRedux from '../index'
import {ActivityLogModel} from '../Model/Model'

const ActivityLogsPage = () => {
  const dispatch = useDispatch()
  const {data, loading} = useSelector((state: any) => state.activityLogs)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const Cell = Table.Cell

  const IPCell = ({rowData, dataKey, ...props}: any) => {
    const propertiesJson = JSON.parse(rowData?.properties)
    return (
      <Cell {...props}>{rowData?.ipAddress ? rowData?.ipAddress : propertiesJson?.request_ip}</Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'User',
      dataKey: 'user',
      width: 160,
      cell: <Cell dataKey='user' />,
      sortable: false,
    },
    {
      label: 'Subject',
      dataKey: 'subject',
      flexGrow: 1,
      cell: <Cell dataKey='subject' />,
      sortable: false,
    },
    {
      label: 'Action',
      dataKey: 'action',
      width: 160,
      cell: <Cell dataKey='action' />,
      sortable: false,
    },
    {
      label: 'IP Address',
      dataKey: 'ipAddress',
      width: 200,
      cell: <IPCell dataKey='id' />,
      sortable: false,
    },
    {
      label: 'Date',
      dataKey: 'createdAt',
      width: 240,
      cell: <Cell dataKey='createdAt' />,
      sortable: false,
    },
  ]

  useEffect(() => {
    dispatch(activityLogsRedux.actions.getActivityLogs(params))
  }, [params])

  const activityLogsData =
    data?.userActivityLog?.map((userActivity: ActivityLogModel, i: number) => ({
      ...userActivity,
      sn: (params?.page - 1) * params?.limit + (i + 1),
    })) ?? []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleRefresh = () => {
    dispatch(activityLogsRedux.actions.getActivityLogs(params))
  }

  const handleGetAllData = () => {
    setParams({...params, limit: data?.meta?.total})
    dispatch(activityLogsRedux.actions.getActivityLogs(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded' data-cy='search-category-shadow-comp'>
        <DesignComponent
          moduleName='Activity Logs'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          exportShow={false}
          importShow={false}
          data={activityLogsData}
          addShow={false}
          deleteShow={false}
          addNoteShow={false}
          toggleMultipleShow={false}
          sortShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading}
          data-cy='search-category-design-comp'
          filterData
        />

        <div className='datatable' data-cy='search-category-datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={activityLogsData}
            showLoading={loading}
            handleSort={handleSort}
            data-cy='search-category-r-suite'
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='search-category-pagination'
          />
        </div>
      </div>
    </>
  )
}

export default ActivityLogsPage
