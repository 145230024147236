import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BENEFIT_MANAGER = `${API_URL}/benefit-manager`

// export const BENEFIT_CALCULATOR = `${API_URL}/benefit-calculator`

export const service = {
  getSpecificBenefitManager: (id: string) => {
    return axios.get(`${BENEFIT_MANAGER}/list/${id}`)
  },
  getBenefitManager: (params: ParamsModel) => {
    return axios.get(BENEFIT_MANAGER, {params})
  },

  addBenefitManager: (data: any) => {
    return axios.post(BENEFIT_MANAGER, data)
  },

  updateBenefitManager: (body: any, id: string) => {
    return axios.patch(`${BENEFIT_MANAGER}/${id}`, body)
  },

  deleteBenefitManager: (data: any) => {
    return axios.delete(BENEFIT_MANAGER, {data})
  },
  singleActivateBenefitManager: (id: any) => {
    return axios.patch(`${BENEFIT_MANAGER}/enable`, id)
  },

  singleDeactivateBenefitManager: (id: any) => {
    return axios.patch(`${BENEFIT_MANAGER}/disable`, id)
  },

  sortTutorialBenefitManager: (body: any) => {
    return axios.patch(`${BENEFIT_MANAGER}/sort`, body)
  },
}
