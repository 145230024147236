import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getAllMidMenuSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllMidMenu, params)
    yield put(actions.getAllMidMenuSuccess(response?.data?.data))
    yield put(actions.getAllMidMenuFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllMidMenuFinish())
  }
}

function* getSpecificMidMenuSaga(action: ActionModel) {
  try {
    const params: any = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getSpecificMidMenu, params)
    yield put(actions.getSpecificMidMenuSuccess(response?.data?.data))
    yield put(actions.getSpecificMidMenuFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getSpecificMidMenuFinish())
  }
}

function* getMidMenuSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getMidMenu, params)
    yield put(actions.getMidMenuSuccess(response?.data?.data))
    yield put(actions.getMidMenuFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getMidMenuFinish())
  }
}

function* addMidMenuSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addMidMenu, body)
    yield put(actions.addMidMenuSuccess(response.data?.data))
    yield put(actions.addMidMenuFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addMidMenuFinish())
  }
}

function* updateMidMenuSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateMidMenu, body, action.payload?.id)
    yield put(actions.updateMidMenuSuccess(response.data?.data))
    yield put(actions.updateMidMenuFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateMidMenuFinish())
  }
}

function* deleteMidMenuSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteMidMenu, body)
    yield put(actions.deleteMidMenuSuccess(response.data?.data))
    yield put(actions.deleteMidMenuFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteMidMenuFinish())
  }
}

function* singleActivateMidMenu(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateMidMenu,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_MID_MENU_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_MID_MENU_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_MID_MENU_MANAGER_FINISH})
  }
}

function* singleDeactivateMidMenu(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateMidMenu,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_MID_MENU_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_MID_MENU_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_MID_MENU_MANAGER_FINISH})
  }
}

function* sortMidMenu(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortTutorialMidMenu, body)

    yield put({
      type: actionTypes.SORT_MID_MENU_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_MID_MENU_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_MID_MENU_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_MID_MENU_MANAGER_START, getAllMidMenuSaga)
  yield takeLatest(actionTypes.GET_SPECIFIC_MID_MENU_MANAGER_START, getSpecificMidMenuSaga)
  yield takeLatest(actionTypes.GET_MID_MENU_MANAGER_START, getMidMenuSaga)
  yield takeLatest(actionTypes.ADD_MID_MENU_MANAGER_START, addMidMenuSaga)
  yield takeLatest(actionTypes.UPDATE_MID_MENU_MANAGER_START, updateMidMenuSaga)
  yield takeLatest(actionTypes.DELETE_MID_MENU_MANAGER_START, deleteMidMenuSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_MID_MENU_MANAGER_REQUEST, singleActivateMidMenu)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_MID_MENU_MANAGER_REQUEST, singleDeactivateMidMenu)
  yield takeLatest(actionTypes.SORT_MID_MENU_MANAGER_START, sortMidMenu)
}
