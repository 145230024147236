import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getCookie: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_COOKIE_START,
    payload: params,
  }),

  getCookieSuccess: (data: any) => ({
    type: actionTypes.GET_COOKIE_SUCCESS,
    payload: data,
  }),
  getCookieFinish: () => ({
    type: actionTypes.GET_COOKIE_FINISH,
  }),
  getCookieError: (error: unknown) => ({
    type: actionTypes.GET_COOKIE_FINISH,
    payload: {error},
  }),

  addCookie: (data: any) => ({
    type: actionTypes.ADD_COOKIE_START,
    payload: data,
  }),
  addCookieSuccess: (task: any) => ({
    type: actionTypes.ADD_COOKIE_SUCCESS,
    payload: task,
  }),
  addCookieFinish: () => ({
    type: actionTypes.ADD_COOKIE_FINISH,
  }),
  addCookieReset: () => ({
    type: actionTypes.ADD_COOKIE_RESET,
  }),

  updateCookie: (data: any, id: string) => ({
    type: actionTypes.UPDATE_COOKIE_START,
    payload: {data, id},
  }),
  updateCookieSuccess: (task: any) => ({
    type: actionTypes.UPDATE_COOKIE_SUCCESS,
    payload: task,
  }),
  updateCookieFinish: () => ({
    type: actionTypes.UPDATE_COOKIE_FINISH,
  }),
  updateCookieReset: () => ({
    type: actionTypes.UPDATE_COOKIE_RESET,
  }),

  deleteCookie: (data: any) => ({
    type: actionTypes.DELETE_COOKIE_START,
    payload: {cookieId: data},
  }),
  deleteCookieSuccess: (data: any) => ({
    type: actionTypes.DELETE_COOKIE_SUCCESS,
    payload: data,
  }),
  deleteCookieFinish: () => ({
    type: actionTypes.DELETE_COOKIE_FINISH,
  }),
  activateCookie: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_COOKIE_REQUEST,
    payload: {cookieId: id},
  }),
  deactivateCookie: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_COOKIE_REQUEST,
    payload: {cookieId: id},
  }),
  singleActivateCookie: (id: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_COOKIE_REQUEST,
    payload: {cookieId: [id]},
  }),
  singleDeactivateCookie: (id: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_COOKIE_REQUEST,
    payload: {cookieId: [id]},
  }),

  sortCookie: (data: any) => ({
    type: actionTypes.SORT_COOKIE_START,
    payload: data,
  }),
  sortCookieSuccess: (data: any) => ({
    type: actionTypes.SORT_COOKIE_SUCCESS,
    payload: data,
  }),
  sortCookieFinish: () => ({
    type: actionTypes.SORT_COOKIE_FINISH,
  }),
  sortCookieReset: () => ({
    type: actionTypes.SORT_COOKIE_RESET,
  }),
}
