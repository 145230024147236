export const actionTypes = {
  // get
  GET_TISA_FOREX_RATE_START: 'GET_TISA_FOREX_RATE_START',
  GET_TISA_FOREX_RATE_SUCCESS: 'GET_TISA_FOREX_RATE_SUCCESS',
  GET_TISA_FOREX_RATE_FINISH: 'GET_TISA_FOREX_RATE_FINISH',

  // create
  CREATE_TISA_FOREX_RATE_START: 'CREATE_TISA_FOREX_RATE_START',
  CREATE_TISA_FOREX_RATE_SUCCESS: 'CREATE_TISA_FOREX_RATE_SUCCESS',
  CREATE_TISA_FOREX_RATE_FINISH: 'CREATE_TISA_FOREX_RATE_FINISH',
  CREATE_TISA_FOREX_RATE_RESET: 'CREATE_TISA_FOREX_RATE_RESET',

  // update
  UPDATE_TISA_FOREX_RATE_START: 'UPDATE_TISA_FOREX_RATE_START',
  UPDATE_TISA_FOREX_RATE_SUCCESS: 'UPDATE_TISA_FOREX_RATE_SUCCESS',
  UPDATE_TISA_FOREX_RATE_FINISH: 'UPDATE_TISA_FOREX_RATE_FINISH',
  UPDATE_TISA_FOREX_RATE_RESET: 'UPDATE_TISA_FOREX_RATE_RESET',

  //Activate deactivate
  ACTIVATE_TISA_FOREX_RATE_REQUEST: 'ACTIVATE_TISA_FOREX_RATE_REQUEST',
  ACTIVATE_TISA_FOREX_RATE_SUCCESS: 'ACTIVATE_TISA_FOREX_RATE_SUCCESS',
  ACTIVATE_TISA_FOREX_RATE_FINISH: 'ACTIVATE_TISA_FOREX_RATE_FINISH',

  SINGLE_ACTIVATE_TISA_FOREX_RATE_REQUEST: 'SINGLE_ACTIVATE_TISA_FOREX_RATE_REQUEST',
  SINGLE_ACTIVATE_TISA_FOREX_RATE_SUCCESS: 'SINGLE_ACTIVATE_TISA_FOREX_RATE_SUCCESS',
  SINGLE_ACTIVATE_TISA_FOREX_RATE_FINISH: 'SINGLE_ACTIVATE_TISA_FOREX_RATE_FINISH',

  SINGLE_DEACTIVATE_TISA_FOREX_RATE_REQUEST: 'SINGLE_DEACTIVATE_TISA_FOREX_RATE_REQUEST',
  SINGLE_DEACTIVATE_TISA_FOREX_RATE_SUCCESS: 'SINGLE_DEACTIVATE_TISA_FOREX_RATE_SUCCESS',
  SINGLE_DEACTIVATE_TISA_FOREX_RATE_FINISH: 'SINGLE_DEACTIVATE_TISA_FOREX_RATE_FINISH',

  DEACTIVATE_TISA_FOREX_RATE_REQUEST: 'DEACTIVATE_TISA_FOREX_RATE_REQUEST',
  DEACTIVATE_TISA_FOREX_RATE_SUCCESS: 'DEACTIVATE_TISA_FOREX_RATE_SUCCESS',
  DEACTIVATE_TISA_FOREX_RATE_FINISH: 'DEACTIVATE_TISA_FOREX_RATE_FINISH',

  // delete
  DELETE_TISA_FOREX_RATE_START: 'DELETE_TISA_FOREX_RATE_START',
  DELETE_TISA_FOREX_RATE_SUCCESS: 'DELETE_TISA_FOREX_RATE_SUCCESS',
  DELETE_TISA_FOREX_RATE_FINISH: 'DELETE_TISA_FOREX_RATE_FINISH',

  // get File csv xlsx
  GET_TISA_FOREX_RATE_FILE_START: 'GET_TISA_FOREX_RATE_FILE_START',
  GET_TISA_FOREX_RATE_FILE_SUCCESS: 'GET_TISA_FOREX_RATE_FILE_SUCCESS',
  GET_TISA_FOREX_RATE_FILE_FINISH: 'GET_TISA_FOREX_RATE_FILE_FINISH',

  // Import forex rate
  IMPORT_TISA_FOREX_RATE_START: 'IMPORT_TISA_FOREX_RATE_START',
  IMPORT_TISA_FOREX_RATE_SUCCESS: 'IMPORT_TISA_FOREX_RATE_SUCCESS',
  IMPORT_TISA_FOREX_RATE_FINISH: 'IMPORT_TISA_FOREX_RATE_FINISH',
}
