import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const TUTORIAL_CATEGORY = `${API_URL}/tutorial-category`

export const service = {
  getActiveTutorialCategory: (id: string) => {
    return axios.get(`${TUTORIAL_CATEGORY}/list`)
  },
  getTutorialCategory: (params: ParamsModel) => {
    return axios.get(TUTORIAL_CATEGORY, {params})
  },

  addTutorialCategory: (data: any) => {
    return axios.post(TUTORIAL_CATEGORY, data)
  },

  updateTutorialCategory: (body: any, id: string) => {
    return axios.patch(`${TUTORIAL_CATEGORY}/${id}`, body)
  },

  deleteTutorialCategory: (data: any) => {
    return axios.delete(TUTORIAL_CATEGORY, {data})
  },
  singleActivateTutorialCategory: (id: any) => {
    return axios.patch(`${TUTORIAL_CATEGORY}/enable`, id)
  },

  singleDeactivateTutorialCategory: (id: any) => {
    return axios.patch(`${TUTORIAL_CATEGORY}/disable`, id)
  },

  sortTutorialTutorialCategory: (body: any) => {
    return axios.patch(`${TUTORIAL_CATEGORY}/sort`, body)
  },
}
