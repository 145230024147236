import {ParamsModel} from 'src/app/modules/common/Model'
import {AlgoliaSearchModel, SortAlgoliaSearchModel} from '../Model/AlgoliaSearchModel'
import {actionTypes} from './constants'
export const actions = {
  // get AlgoliaSearch
  getAllAlgoliaSearch: () => ({
    type: actionTypes.GET_ALL_ALGOLIA_SEARCH_START,
  }),
  getAllAlgoliaSearchSuccess: (data: AlgoliaSearchModel | any) => ({
    type: actionTypes.GET_ALL_ALGOLIA_SEARCH_SUCCESS,
    payload: data,
  }),
  getAllAlgoliaSearchFinish: () => ({
    type: actionTypes.GET_ALL_ALGOLIA_SEARCH_FINISH,
  }),

  // get AlgoliaSearch DATA
  getAlgoliaSearch: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ALGOLIA_SEARCH_START,
    payload: params,
  }),
  getAlgoliaSearchSuccess: (data: AlgoliaSearchModel | any) => ({
    type: actionTypes.GET_ALGOLIA_SEARCH_SUCCESS,
    payload: data,
  }),
  getAlgoliaSearchFinish: () => ({
    type: actionTypes.GET_ALGOLIA_SEARCH_FINISH,
  }),

  // create key
  addAlgoliaSearch: (data: AlgoliaSearchModel | any) => ({
    type: actionTypes.ADD_ALGOLIA_SEARCH_START,
    payload: data,
  }),
  addAlgoliaSearchSuccess: (task: any) => ({
    type: actionTypes.ADD_ALGOLIA_SEARCH_SUCCESS,
    payload: task,
  }),
  addAlgoliaSearchFinish: () => ({
    type: actionTypes.ADD_ALGOLIA_SEARCH_FINISH,
  }),
  resetAlgoliaSearch: () => ({
    type: actionTypes.RESET_SEARCH_TAG,
  }),

  //Update AlgoliaSearch
  updateAlgoliaSearch: (data: AlgoliaSearchModel | any, id: string) => ({
    type: actionTypes.UPDATE_ALGOLIA_SEARCH_START,
    payload: data,
    id,
  }),

  updateAlgoliaSearchSuccess: (data: AlgoliaSearchModel | any) => ({
    type: actionTypes.UPDATE_ALGOLIA_SEARCH_SUCCESS,
    payload: data,
  }),

  updateAlgoliaSearchFinish: () => ({
    type: actionTypes.UPDATE_ALGOLIA_SEARCH_FINISH,
  }),

  // delete key
  deleteAlgoliaSearch: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_ALGOLIA_SEARCH_START,
    payload: {searchAlgoliaId: data},
  }),
  deleteAlgoliaSearchSuccess: (data: any) => ({
    type: actionTypes.DELETE_ALGOLIA_SEARCH_SUCCESS,
    payload: data,
  }),
  deleteAlgoliaSearchFinish: () => ({
    type: actionTypes.DELETE_ALGOLIA_SEARCH_FINISH,
  }),

  //Enable AlgoliaSearch
  enableAlgoliaSearch: (data: any) => ({
    type: actionTypes.ENABLE_ALGOLIA_SEARCH_REQUEST,
    payload: {data},
  }),

  enableAlgoliaSearchSuccess: (task: any) => ({
    type: actionTypes.ENABLE_ALGOLIA_SEARCH_SUCCESS,
    payload: task,
  }),
  enableAlgoliaSearchFinish: () => ({
    type: actionTypes.ENABLE_ALGOLIA_SEARCH_FINISH,
  }),

  //Disable AlgoliaSearch
  disableAlgoliaSearch: (data: any) => ({
    type: actionTypes.DISABLE_ALGOLIA_SEARCH_REQUEST,
    payload: {data},
  }),

  disableAlgoliaSearchSuccess: (task: any) => ({
    type: actionTypes.DISABLE_ALGOLIA_SEARCH_SUCCESS,
    payload: task,
  }),
  disableAlgoliaSearchFinish: () => ({
    type: actionTypes.DISABLE_ALGOLIA_SEARCH_FINISH,
  }),

  //Enable AlgoliaSearch
  singleEnableAlgoliaSearch: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_REQUEST,
    payload: {data},
  }),

  singleEnableAlgoliaSearchSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_SUCCESS,
    payload: task,
  }),
  singleEnableAlgoliaSearchFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_ALGOLIA_SEARCH_FINISH,
  }),

  //Disable AlgoliaSearch
  singleDisableAlgoliaSearch: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_REQUEST,
    payload: {data},
  }),

  singleDisableAlgoliaSearchSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_SUCCESS,
    payload: task,
  }),
  singleDisableAlgoliaSearchFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_ALGOLIA_SEARCH_FINISH,
  }),

  // sort
  sortAlgoliaSearch: (data: SortAlgoliaSearchModel) => ({
    type: actionTypes.SORT_ALGOLIA_SEARCH_START,
    payload: data,
  }),
  sortAlgoliaSearchSuccess: (data: Array<AlgoliaSearchModel>) => ({
    type: actionTypes.SORT_ALGOLIA_SEARCH_SUCCESS,
    payload: data,
  }),
  sortAlgoliaSearchFinish: () => ({
    type: actionTypes.SORT_ALGOLIA_SEARCH_FINISH,
  }),
  sortAlgoliaSearchReset: () => ({
    type: actionTypes.SORT_ALGOLIA_SEARCH_RESET,
  }),
}
