import {ParamsModel} from 'src/app/modules/common/Model'
import {CATEGORY_TYPES, COMMON_TYPES} from './constant'

export const getAllCategory = (params: ParamsModel = {page: 1, limit: 10}) => ({
  type: CATEGORY_TYPES.GET_ALL_CATEGORY + COMMON_TYPES.REQUEST,
  payload: params,
})

export const createCategory = (payload: any) => ({
  type: CATEGORY_TYPES.CREATE_CATEGORY + COMMON_TYPES.REQUEST,
  payload,
})

export const updateCategory = (data: any, id: string) => ({
  type: CATEGORY_TYPES.UPDATE_CATEGORY + COMMON_TYPES.REQUEST,
  payload: {data, id},
})

export const deleteCategory = (data: any) => ({
  type: CATEGORY_TYPES.DELETE_CATEGORY + COMMON_TYPES.REQUEST,
  payload: {onlineApplicationCategoryId: data},
})

export const singleActivateCategory = (id: {[key: string]: string}) => ({
  type: CATEGORY_TYPES.SINGLE_ACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.REQUEST,
  payload: {onlineApplicationCategoryId: [id]},
})

export const singleDeactivateCategory = (id: {[key: string]: string}) => ({
  type: CATEGORY_TYPES.SINGLE_DEACTIVATE_APPLICATION_CATEGORY + COMMON_TYPES.REQUEST,
  payload: {onlineApplicationCategoryId: [id]},
})

export const activateCategory = (id: {[key: string]: string}) => ({
  type: CATEGORY_TYPES.ACTIVATE_CATEGORY + COMMON_TYPES.REQUEST,
  payload: {onlineApplicationCategoryId: id},
})

export const deactivateCategory = (id: {[key: string]: string}) => ({
  type: CATEGORY_TYPES.DEACTIVATE_CATEGORY + COMMON_TYPES.REQUEST,
  payload: {onlineApplicationCategoryId: id},
})

export const getCategoryById = (payload: any) => ({
  type: CATEGORY_TYPES.GET_CATEGORY_BY_ID + COMMON_TYPES.REQUEST,
  payload,
})

export const sortCategory = (data: any) => {
  type: CATEGORY_TYPES.SORT_CATEGORY + COMMON_TYPES.REQUEST
  payload: data
}

export const sortCategoryReset = () => {
  type: CATEGORY_TYPES.SORT_CATEGORY_RESET + COMMON_TYPES.REQUEST
}
