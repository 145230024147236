import {Action} from 'redux'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {IModuleModel} from '../Model'
/* import { UserModel } from '../models/UserModel' */
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialHomepageSettingState: IHomepageSettingState = {
  homePageSection: [],
  homePageLayout: [],

  data: {
    homePage: [],
    meta: [],
  },
  sortHomepageSettingData: [],
  loading: false,
  success: false,
  sortSuccess: false,
  deleteSuccess: false,
  toggleLoading: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
}

export interface IHomepageSettingState {
  homePageSection?: {[key: string]: string | number}[]
  homePageLayout?: {[key: string]: string | number}[]
  data?: {
    homePage?: {[key: string]: string | number}[]
    meta?: {[key: string]: string | number}[]
  }
  sortHomepageSettingData: {[key: string]: string | number}[]
  loading?: boolean
  success?: boolean
  sortSuccess?: boolean
  toggleLoading: boolean
  deleteSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
}

export const reducer = (
  state: IHomepageSettingState = initialHomepageSettingState,
  action: ActionWithPayload<IHomepageSettingState>
) => {
  switch (action.type) {
    case actionTypes.GET_HOMEPAGE_SECTION_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_HOMEPAGE_SECTION_SUCCESS: {
      return {
        ...state,
        homePageSection: action.payload?.homePageSection,
        loading: false,
      }
    }

    case actionTypes.GET_HOMEPAGE_SECTION_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.GET_HOMEPAGE_LAYOUT_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_HOMEPAGE_LAYOUT_SUCCESS: {
      return {...state, homePageLayout: action.payload?.homePageLayout, loading: false}
    }

    case actionTypes.GET_HOMEPAGE_LAYOUT_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_HOMEPAGE_SETTING_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_HOMEPAGE_SETTING_SUCCESS: {
      return {...state, data: action.payload, success: true, loading: false}
    }

    case actionTypes.ADD_HOMEPAGE_SETTING_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    case actionTypes.ADD_HOMEPAGE_SETTING_RESET: {
      return {...state, success: false, loading: false}
    }

    //UPDATE REDUCER
    case actionTypes.UPDATE_HOMEPAGE_SETTING_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_HOMEPAGE_SETTING_SUCCESS: {
      return {...state, data: action.payload, success: true, loading: false}
    }

    case actionTypes.UPDATE_HOMEPAGE_SETTING_FINISH: {
      const error = action.payload
      return {...state, error, success: false, loading: false}
    }

    case actionTypes.UPDATE_HOMEPAGE_SETTING_RESET: {
      return {...state, success: false, loading: false}
    }

    case actionTypes.GET_HOMEPAGE_SETTING_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_HOMEPAGE_SETTING_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    //Enable
    case actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_SUCCESS: {
      const changedData: any = action.payload?.data?.homePage
      let newData = state?.data?.homePage?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, homePage: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_SUCCESS: {
      const changedData: any = action.payload?.data?.homePage
      let newData = state?.data?.homePage?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, homePage: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    // sort
    case actionTypes.SORT_HOMEPAGE_SETTING_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_HOMEPAGE_SETTING_SUCCESS: {
      return {
        ...state,
        sortHomepageSettingData: action?.payload,
        sortSuccess: true,
        loading: false,
      }
    }

    case actionTypes.SORT_HOMEPAGE_SETTING_FINISH: {
      return {
        ...state,
        loading: false,
        sortSuccess: false,
      }
    }

    case actionTypes.SORT_HOMEPAGE_SETTING_RESET: {
      return {
        ...state,
        loading: false,
        sortSuccess: false,
        sortHomepageSettingData: [],
      }
    }

    //Delete
    case actionTypes.DELETE_HOMEPAGE_SETTING_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_HOMEPAGE_SETTING_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_HOMEPAGE_SETTING_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }
    default:
      return state
  }
}
