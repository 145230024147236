export const actionTypes = {
  GET_LIST_START: 'GET_LIST_START',
  GET_LIST_SUCCESS: 'GET_LIST_SUCCESS',
  GET_LIST_FINISH: 'GET_LIST_FINISH',
  RESET_LIST : 'RESET_LIST',

  DELETE_LIST_START: 'DELETE_LIST_START',
  DELETE_LIST_SUCCESS: 'DELETE_LIST_SUCCESS',
  DELETE_LIST_FINISH: 'DELETE_LIST_FINISH',
}
