import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {
  ProgramCategoryModel,
  SortProgramActionModel,
  SortProgramCategoryModel,
} from '../Model/ProgramCategoryModel'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getProgramCategorySaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload

    yield delay(500)
    const response: ResponseModel = yield call(service.getProgramCategory, params)
    yield put(actions.getProgramCategorySuccess(response?.data?.data))
    yield put(actions.getProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getProgramCategoryFinish())
  }
}

function* getAllProgramCategorySaga() {
  try {
    const response: ResponseModel = yield call(service.getAllProgramCategory)
    yield put(actions.getAllProgramCategorySuccess(response?.data?.data))
    yield put(actions.getAllProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAllProgramCategoryFinish())
  }
}

function* addProgramCategorySaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addProgramCategory, body)
    yield put(actions.addProgramCategorySuccess(response.data?.data))
    yield put(actions.addProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addProgramCategoryFinish())
  }
}

function* enableProgramCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableProgramCategory, selectedUsers)
    yield put(actions.enableProgramCategorySuccess(response?.data))
    yield put(actions.enableProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableProgramCategoryFinish())
  }
}

function* disableProgramCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableProgramCategory, selectedUsers)
    yield put(actions.disableProgramCategorySuccess(response?.data))
    yield put(actions.disableProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableProgramCategoryFinish())
  }
}

function* singleEnableProgramCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableProgramCategory, selectedUsers)
    yield put(actions.singleEnableProgramCategorySuccess(response?.data))
    yield put(actions.singleEnableProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableProgramCategoryFinish())
  }
}

function* singleDisableProgramCategorySaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableProgramCategory, selectedUsers)
    yield put(actions.singleDisableProgramCategorySuccess(response?.data))
    yield put(actions.singleDisableProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableProgramCategoryFinish())
  }
}

function* updateProgramCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateProgramCategory,
      body,
      action.payload?.id
    )
    yield put(actions.updateProgramCategorySuccess(response.data?.data))
    yield put(actions.updateProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateProgramCategoryFinish())
  }
}

function* deleteProgramCategorySaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteProgramCategory, body)
    yield put(actions.deleteProgramCategorySuccess(response.data?.data))
    yield put(actions.deleteProgramCategoryFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteProgramCategoryFinish())
  }
}

function* sortProgramCategory(action: SortProgramActionModel) {
  try {
    const body: SortProgramCategoryModel = action.payload
    const response: ResponseModel = yield call(service.sortProgramCategory, body)

    yield put({
      type: actionTypes.SORT_PROGRAM_CATEGORY_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_PROGRAM_CATEGORY_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_PROGRAM_CATEGORY_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_PROGRAM_CATEGORY_START, getProgramCategorySaga)
  yield takeLatest(actionTypes.GET_ALL_PROGRAM_CATEGORY_START, getAllProgramCategorySaga)
  yield takeLatest(actionTypes.ADD_PROGRAM_CATEGORY_START, addProgramCategorySaga)
  yield takeLatest(actionTypes.UPDATE_PROGRAM_CATEGORY_START, updateProgramCategorySaga)
  yield takeLatest(actionTypes.DELETE_PROGRAM_CATEGORY_START, deleteProgramCategorySaga)
  yield takeLatest(actionTypes.ENABLE_PROGRAM_CATEGORY_REQUEST, enableProgramCategorySaga)
  yield takeLatest(actionTypes.DISABLE_PROGRAM_CATEGORY_REQUEST, disableProgramCategorySaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_PROGRAM_CATEGORY_REQUEST,
    singleEnableProgramCategorySaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_PROGRAM_CATEGORY_REQUEST,
    singleDisableProgramCategorySaga
  )
  yield takeLatest(actionTypes.SORT_PROGRAM_CATEGORY_START, sortProgramCategory)
}
