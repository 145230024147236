import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteAppointmentReasonModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_APPOINTMENT_REASON = `${API_URL}/appointment-reason`

export const service = {
  getAppointmentReasonData: (params: ParamsModel) => {
    return axios.get(GET_APPOINTMENT_REASON, {params})
  },

  getAppointmentReasonList: () => {
    return axios.get(`${GET_APPOINTMENT_REASON}/list`)
  },
  addAppointmentReason: (data: any) => {
    return axios.post(GET_APPOINTMENT_REASON, data)
  },

  updateAppointmentReason: (body: any, id: string) => {
    return axios.patch(`${GET_APPOINTMENT_REASON}/${id}`, body)
  },

  deleteBulkAppointmentReason: (data: DeleteAppointmentReasonModel) => {
    return axios.delete(`${GET_APPOINTMENT_REASON}`, {data})
  },

  activateAppointmentReason: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentReasonId: data,
    }
    return axios.patch(`${GET_APPOINTMENT_REASON}/enable`, selectedAppointmentType)
  },

  deactivateAppointmentReason: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentReasonId: data,
    }
    return axios.patch(`${GET_APPOINTMENT_REASON}/disable`, selectedAppointmentType)
  },

  singleActivateAppointmentReason: (data: Array<string>) => {
    const selectedAppointmentReason = {
      appointmentReasonId: [data],
    }
    return axios.patch(`${GET_APPOINTMENT_REASON}/enable`, selectedAppointmentReason)
  },

  singleDeactivateAppointmentReason: (data: Array<string>) => {
    const selectedAppointmentType = {
      appointmentReasonId: [data],
    }
    return axios.patch(`${GET_APPOINTMENT_REASON}/disable`, selectedAppointmentType)
  },
}
