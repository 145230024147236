export const actionTypes = {
  GET_COOKIE_START: 'GET_COOKIE_START',
  GET_COOKIE_SUCCESS: 'GET_COOKIE_SUCCESS',
  GET_COOKIE_FINISH: 'GET_COOKIE_FINISH',

  ADD_COOKIE_START: 'ADD_COOKIE_START',
  ADD_COOKIE_SUCCESS: 'ADD_COOKIE_SUCCESS',
  ADD_COOKIE_FINISH: 'ADD_COOKIE_FINISH',
  ADD_COOKIE_RESET: 'ADD_COOKIE_RESET',

  UPDATE_COOKIE_START: 'UPDATE_COOKIE_START',
  UPDATE_COOKIE_SUCCESS: 'UPDATE_COOKIE_SUCCESS',
  UPDATE_COOKIE_FINISH: 'UPDATE_COOKIE_FINISH',
  UPDATE_COOKIE_RESET: 'UPDATE_COOKIE_RESET',

  DELETE_COOKIE_START: 'DELETE_COOKIE_START',
  DELETE_COOKIE_SUCCESS: 'DELETE_COOKIE_SUCCESS',
  DELETE_COOKIE_FINISH: 'DELETE_COOKIE_FINISH',

  SINGLE_ACTIVATE_COOKIE_REQUEST: 'SINGLE_ACTIVATE_COOKIE_REQUEST',
  SINGLE_ACTIVATE_COOKIE_SUCCESS: 'SINGLE_ACTIVATE_COOKIE_SUCCESS',
  SINGLE_ACTIVATE_COOKIE_FINISH: 'SINGLE_ACTIVATE_COOKIE_FINISH',

  SINGLE_DEACTIVATE_COOKIE_REQUEST: 'SINGLE_DEACTIVATE_COOKIE_REQUEST',
  SINGLE_DEACTIVATE_COOKIE_SUCCESS: 'SINGLE_DEACTIVATE_COOKIE_SUCCESS',
  SINGLE_DEACTIVATE_COOKIE_FINISH: 'SINGLE_DEACTIVATE_COOKIE_FINISH',

  SORT_COOKIE_START: 'SORT_COOKIE_START',
  SORT_COOKIE_SUCCESS: 'SORT_COOKIE_SUCCESS',
  SORT_COOKIE_FINISH: 'SORT_COOKIE_FINISH',
  SORT_COOKIE_RESET: 'SORT_COOKIE_RESET',
}
