import {FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'

//Manual import
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as aboutbankdetailRedux from '../redux'

import FormRadio from 'src/cms/helpers/components/forms/FormRadio'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),

  // coverImage: Yup.string().required('Cover Image is required'),

  // photos: Yup.array().of(
  //   Yup.object().shape({
  //     photoTitle: Yup.string().required('Photo Title is required'),
  //   })
  // ),
})

const AddBankDetail = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {loading, success} = useSelector((state: any) => state.aboutbankdetail)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  useEffect(() => {
    if (success) {
      dispatch(aboutbankdetailRedux?.actions.getAboutBankDetail(params))
      isEmpty(editSelectedData)
        ? toast.success('Detail  added successfully')
        : toast.success('Detail edited successfully')
      dispatch(aboutbankdetailRedux?.actions?.resetAboutBankDetail())
      handleClose()
    }
  }, [success])

  const convertToSlug = (text: string) => {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Bank Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                titleNp: '',
                slug: '',
                blocks: [
                  {
                    title: '',
                    titleNp: ' ',
                    total: '',
                    order: '',
                  },
                ],
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : values?.title.replace(/\s/g, '-').toLowerCase(),
                  status: values?.status === 'Active' ? true : false,
                  blocks: values?.blocks?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    aboutbankdetailRedux.actions.updateAboutBankDetail(
                      formData,
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(aboutbankdetailRedux.actions.addAboutBankDetail(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['id', 'title', 'titleNp', 'slug', 'blocks']

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Album Title (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title (NP)'
                            label='Album Title (NP)'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : values?.title.replace(/\s/g, '-').toLowerCase()
                            }
                            disabled={true}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-body border-top pt-5 pb-5'>
                      <div className=''>
                        <div className=''>
                          <FieldArray
                            name='blocks'
                            render={(arrayHelpers) => (
                              <div>
                                {values?.blocks && values?.blocks.length > 0
                                  ? values?.blocks.map((friend: any, index: any) => (
                                      <div key={index} className='row'>
                                        <div className='col-12 pt-5 border-top'>
                                          {index > 0 && (
                                            <div className=' d-flex justify-content-md-end mb-2'>
                                              <button
                                                type='button'
                                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                                onClick={() => arrayHelpers.remove(index)}
                                                data-cy='modal-field-remove'
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                        </div>

                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Title in English'
                                            label='Title (EN)'
                                            name={`blocks.${index}.title`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Title in Nepali'
                                            label='Title (NP)'
                                            name={`blocks.${index}.titleNp`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='text'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Total'
                                            label='Total'
                                            name={`blocks.${index}.total`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                        <div className='col-md-6 col-xs-12'>
                                          <FormTextBox
                                            type='number'
                                            labelClassName='col-md-12'
                                            containerClassName='col-md-12'
                                            placeholder='Order'
                                            label='Order'
                                            name={`blocks.${index}.order`}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  : null}
                                <div className='d-flex justify-content-end'>
                                  <button
                                    className=' p-2 ps-3 pe-3 btn btn-primary w-100'
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        id: null,
                                        title: '',
                                        titleNp: '',
                                        total: '',
                                      })
                                    }
                                    data-cy='modal-field-add'
                                  >
                                    + Add More
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 mt-3'>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddBankDetail
