import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import FormPassword from 'src/cms/helpers/components/forms/FormPassword'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import {SmtpModel} from '../Model'
import * as smtp from '../index'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'

type Props = {
  open: boolean
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  portNumber: Yup.string().required('Port Number is required'),
  hostName: Yup.string().required('Hostname is required'),
  is_relay: Yup.boolean(),
  userName: Yup.string().when('is_relay', {
    is: (value: any) => value === false,
    then: Yup.string().required('Username is required'),
    otherwise: Yup.string().notRequired(),
  }),
  password: Yup.string().when('is_relay', {
    is: (value: any) => value === false,
    then: Yup.string().required('Password is required'),
    otherwise: Yup.string().notRequired(),
  }),
  encryption: Yup.string().required('Encryption is required'),
})

const AddSmtp = ({open, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {encryption, loading, success} = useSelector((state: any) => state.smtp)
  const data = useSelector((state: any) => state.smtp)

  useEffect(() => {
    dispatch(smtp?.actions.getSmtpEncryption())
  }, [])

  const encryptionOptions = encryption.map((item: {[key: string]: string}) => ({
    label: item.displayName,
    value: item.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(smtp?.actions.getSmtp())
      isEmpty(editSelectedData)
        ? toast.success('SMTP added successfully')
        : toast.success('SMTP edited successfully')
      dispatch(smtp?.actions?.createSmtpReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} SMTP Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                type: '',
                hostName: '',
                portNumber: '',
                is_relay: false,
                userName: '',
                password: '',
                encryption: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: SmtpModel) => {
                const formData = {
                  ...values,
                  portNumber: values?.portNumber?.toString(),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(smtp.actions.updateSmtp(formData, editSelectedData?.id))
                } else {
                  dispatch(smtp.actions.createSmtp(formData))
                }
              }}
            >
              {({touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (values?.is_relay) {
                    setFieldValue('userName', '', false)
                    setFieldValue('password', '', false)
                  } else {
                    if (!isEmpty(editSelectedData)) {
                      setFieldValue('userName', editSelectedData['userName'] ?? '', false)
                    }
                  }
                }, [values?.is_relay])

                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['type', 'hostName', 'portNumber', 'userName', 'encryption']
                    fields.forEach((field) =>
                      setFieldValue(field, editSelectedData[field] ?? '', false)
                    )
                    if (typeof editSelectedData?.is_relay === 'boolean') {
                      setFieldValue('is_relay', editSelectedData?.is_relay ?? false, false)
                    }
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-lg-12'
                            labelClassName='col-lg-12'
                            type='text'
                            placeholder='Type '
                            label='Type'
                            name='type'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-lg-12'
                            labelClassName='col-lg-12'
                            type='text'
                            placeholder='Host name'
                            label='Host Name'
                            name='hostName'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-lg-12'
                            labelClassName='col-lg-12'
                            type='number'
                            placeholder='Port Number'
                            label='Port number'
                            name='portNumber'
                            min='0'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Encryption'
                            name='encryption'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={encryptionOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            arrValue={
                              editSelectedData?.encryption && !values.encryption
                                ? editSelectedData?.encryption?.id
                                : values.encryption
                            }
                          />
                        </div>
                        <div className='col-12 mb-2'>
                          <FormCheckbox
                            containerClassName='col-lg-12'
                            labelClassName='col-lg-12'
                            type='checkbox'
                            checkBoxText='Relay Email'
                            errors={errors}
                            touched={touched}
                            label='Relay Email'
                            name='is_relay'
                            checked={values?.is_relay}
                            onChange={handleChange}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-lg-12'
                            labelClassName='col-lg-12'
                            type='email'
                            placeholder='abc@gmail.com'
                            label='Email'
                            name='userName'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={values?.is_relay}
                            required={!values?.is_relay}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormPassword
                            containerClassName='col-lg-12'
                            labelClassName='col-lg-12'
                            placeholder='Password'
                            label='Password'
                            name='password'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={values?.is_relay}
                            required={!values?.is_relay}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddSmtp
