import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import * as appointmentStatusRedux from 'src/app/modules/appointments/components/appointmentRequest/appointmentStatus/redux'
import { IAppointmentStatusState } from 'src/app/modules/appointments/components/appointmentRequest/appointmentStatus/redux'
import * as appointmentRequestRedux from 'src/app/modules/appointments/components/appointmentRequest/redux'
import { IAppointmentRequestState } from 'src/app/modules/appointments/components/appointmentRequest/redux'
import { StateParamsModel } from 'src/app/modules/common/Model'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

const FORM_VALIDATION = Yup.object().shape({
  statusId: Yup.string().required('Status Id is required'),
  remarks: Yup.string().required('Remarks is required').nullable(),
})

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
  appointmentTypeId?: string
}
const UpdateStatus = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const statusParams: { id: string } = useParams()
  const appointmentStatusData: IAppointmentStatusState = useSelector(
    (state: any) => state.appointmentStatus
  )

  const appointmentRequestData: IAppointmentRequestState = useSelector(
    (state: any) => state.appointmentRequest
  )

  const { loading, success } = useSelector((state: any) => state.appointmentRequest)

  useEffect(() => {
    dispatch(appointmentStatusRedux.actions.getAppointmentStatus())
    //dispatch(branch.branch.actions.getBranchData())
  }, [])

  const appointmentStatusOptions = appointmentStatusData?.data?.appointmentStatus.map(
    (item: any) => ({
      label: item.displayName,
      value: item.id,
    })
  )

  useEffect(() => {
    if (success) {
      dispatch(appointmentRequestRedux?.actions.getAppointmentRequest(params))
      isEmpty(editSelectedData)
        ? toast.success('Appointment Status added successfully')
        : toast.success('Appointment Status Updated successfully')
      dispatch(appointmentRequestRedux?.actions.getAppointmentRequestReset())
      handleClose()
    }
  }, [success])

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Status Appointment </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                statusId: '',
                remarks: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                }

                dispatch(
                  appointmentRequestRedux.actions.updateAppointmentRequest(
                    formData,
                    editSelectedData?.id
                  )
                )
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, setFieldValue, values }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['statusId', 'remarks']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormSelect
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Appointment Status'
                            label='Appointment Status'
                            name='statusId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={appointmentStatusOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className='col-md-6 '>
                          <FormTextBox
                            type='text'
                            as='textarea'
                            placeholder='Remarks'
                            name='remarks'
                            label='Remarks'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default UpdateStatus
