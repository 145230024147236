import {FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import * as Yup from 'yup'
import * as galleryCategoryRedux from '../../gallery/galleryCategory/redux'
import {IGalleryCategoryTypeState} from '../../gallery/galleryCategory/redux'
import * as galleryTypeRedux from '../../gallery/gallerytype/redux'
import {IGalleryFileTypeState} from '../../gallery/gallerytype/redux'
import * as galleryRedux from '../../gallery/redux'
import {GalleryCategoryTypeModel} from '../galleryCategory/Model'
import {GalleryFileTypeModel} from '../gallerytype/Model'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

let FORM_VALIDATION = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  // coverImage: Yup.string().required('Cover Image is required'),
  date: Yup.string().required('Date is required'),
  // photos: Yup.array().of(
  //   Yup.object().shape({
  //     photoTitle: Yup.string().required('Photo Title is required'),
  //   })
  // ),
})

const AddGallery = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [thumbImage, setThumbImage] = useState('')
  const [filterData, setFilterData] = useState([])
  const [linkData, setLinkData] = useState('')
  const {loading, success} = useSelector((state: any) => state.gallery)
  const galleryFileTypeData: IGalleryFileTypeState = useSelector((state: any) => state.gallerytype)

  const galleryCategoryTypeData: IGalleryCategoryTypeState = useSelector(
    (state: any) => state.galleryCategory
  )
  useEffect(() => {
    dispatch(galleryTypeRedux?.actions.getGalleryFileType())
    dispatch(galleryCategoryRedux?.actions.getGalleryCategoryType())
  }, [])

  const galleryFileOption = galleryFileTypeData?.data?.galleryFileOption?.map(
    (items: GalleryFileTypeModel | any) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )

  const galleryCategoryOption = galleryCategoryTypeData?.data?.galleryCategory?.map(
    (items: GalleryCategoryTypeModel | any) => ({
      label: items.displayName,
      value: items.id,
      systemName: items.systemName,
    })
  )

  useEffect(() => {
    if (success) {
      dispatch(galleryRedux?.actions.getGallery(params))
      isEmpty(editSelectedData)
        ? toast.success('Gallery added successfully')
        : toast.success('Gallery edited successfully')
      dispatch(galleryRedux?.actions?.resetGallery())
      handleClose()
    }
  }, [success])

  const convertToSlug = (text: string) => {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  const featuredEventsOptions = [
    {label: 'Yes', value: 'Active'},
    {label: 'No', value: 'Inactive'},
  ]

  const isAlbumThumbImageEnRequired = checkBackendSetting('isAlbumThumbImageEnRequired')
  const hidePhotoVideosOnAlbumTab = checkBackendSetting('hidePhotoVideosOnAlbumTab')
  const showIsGalleryFeatured = checkBackendSetting('showIsGalleryFeatured')

  if (isAlbumThumbImageEnRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      thumbImage: Yup.string().required('Thumb Image is required'),
    })
  }

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Photos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: '',
                titleNp: '',
                date: '',
                description: '',
                isFeatured: '',
                descriptionNp: '',
                thumbImage: '',
                // coverImage: '',
                slug: '',
                galleryoptionid: '',
                photos: [
                  {
                    photoTitle: '',
                    photoTitleNp: '',
                    filetype: galleryFileOption[0]?.value,
                    link: '',
                    iframe: '',
                  },
                ],
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  date: moment(values?.date).format('YYYY-MM-DD'),
                  isFeatured: values?.isFeatured === 'Active' ? true : false,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.title),
                  photos: values?.photos?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(galleryRedux.actions.updateGallery(formData, editSelectedData?.id))
                } else {
                  dispatch(galleryRedux.actions.addGallery(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'id',
                      'title',
                      'titleNp',
                      'date',
                      'thumbImage',
                      'isFeatured',
                      // 'coverImage',
                      'galleryoptionid',
                      'description',
                      'descriptionNp',
                      'photos',
                      'slug',
                      'photoTitleNp',
                    ]

                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'isFeatured',
                      editSelectedData?.isFeatured ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title'
                            label='Album Title (EN)'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Title (NP)'
                            label='Album Title (NP)'
                            name='titleNp'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label=' Date'
                            name='date'
                            placeholderText='Please select a date'
                            setFieldValue={setFieldValue}
                            value={values.date || false}
                            touched={touched}
                            showIcon={true}
                            errors={{}}
                            required={true}
                          />
                        </div>
                        <div className='col-lg-6'>
                          <div className='row'>
                            <div className='col-md-12 col-xs-12'>
                              <FormInputMediaManager
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Album Thumb Image (EN)'
                                name='thumbImage'
                                setFieldValue={setFieldValue}
                                setImageUrl={setThumbImage}
                                value={values?.thumbImage}
                                required={isAlbumThumbImageEnRequired}
                              />

                              {!isEmpty(values?.thumbImage) ? (
                                <>
                                  <li className='listing'>
                                    <div className='thumbImageBlock'>
                                      <button
                                        type='button'
                                        title='Remove'
                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                        onClick={() => {
                                          setThumbImage('')
                                          setFieldValue('thumbImage', '')
                                        }}
                                        data-cy='modal-thumbnail-remove'
                                      >
                                        X
                                      </button>

                                      <img
                                        className='thumbImage w-100 h-100'
                                        src={`${imageBaseUrl}/${values?.thumbImage}`}
                                        alt=''
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : null}
                            </div>

                            {/* <div className='col-md-6 col-xs-12'>
                              <FormInputMediaManager
                                labelClassName='col-md-12'
                                containerClassName='col-md-12'
                                label='Album Cover Image (EN)'
                                name='coverImage'
                                setFieldValue={setFieldValue}
                                setImageUrl={setCoverImage}
                                value={values?.coverImage}
                                required={true}
                              />

                              {!isEmpty(values?.coverImage) ? (
                                <>
                                  <li className='listing'>
                                    <div className='thumbImageBlock'>
                                      <button
                                        type='button'
                                        title='Remove'
                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                        onClick={() => {
                                          setCoverImage('')
                                          setFieldValue('coverImage', '')
                                        }}
                                      >
                                        X
                                      </button>

                                      <img
                                        className='thumbImage w-100 h-100'
                                        src={`${imageBaseUrl}/${values?.coverImage}`}
                                        alt=''
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : null}
                            </div> */}
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            name='description'
                            handleChange={handleChange}
                            label='Album Description (EN)'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            name='descriptionNp'
                            handleChange={handleChange}
                            label=' Album Description (NP)'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''
                            }
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : convertToSlug(values?.title)
                            }
                            disabled={true}
                            required={true}
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select a Category type'
                            label='Gallery Category'
                            name='galleryoptionid'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={galleryCategoryOption}
                            values={values}
                            setFieldValue={setFieldValue}
                            // required
                          />
                        </div>
                      </div>
                    </div>

                    {showIsGalleryFeatured && (
                      <div className='col-md-6 col-xs-12'>
                        <label className='form-label fw-bolder text-dark fs-6'>Featured ?</label>

                        <div className='d-flex my-3'>
                          {featuredEventsOptions?.map(
                            (featuredOption: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Is Featured'
                                name='isFeatured'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                checkBoxText={featuredOption?.label}
                                value={featuredOption?.value}
                              />
                            )
                          )}
                        </div>
                      </div>
                    )}

                    {!hidePhotoVideosOnAlbumTab && (
                      <div className='card-body border-top pt-5'>
                        <h5>Photo Videos on Album</h5>
                        <div className=''>
                          <div className=''>
                            <FieldArray
                              name='photos'
                              render={(arrayHelpers) => (
                                <div>
                                  {values.photos && values.photos.length > 0
                                    ? values.photos.map((friend: any, index: any) => (
                                        <div key={index} className='row'>
                                          <div className='col-12 pt-5 border-top'>
                                            {index > 0 && (
                                              <div className=' d-flex justify-content-md-end mb-2'>
                                                <button
                                                  type='button'
                                                  className='p-2 ps-5 pe-5 btn btn-secondary'
                                                  onClick={() => arrayHelpers.remove(index)}
                                                  data-cy='modal-field-remove'
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                          {galleryFileTypeData?.data?.galleryFileOption
                                            ?.filter(
                                              (item: any) =>
                                                item.systemName === values.photos[index].filetype
                                            )
                                            .map((filter: any) => setFilterData(filter))}
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              placeholder='Title in English'
                                              label='Title (EN)'
                                              name={`photos.${index}.photoTitle`}
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              placeholder='Title in Nepali'
                                              label='Title (NP)'
                                              name={`photos.${index}.photoTitleNp`}
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>

                                          <div className='col-md-6 col-sm-6 col-xs-12'>
                                            <FormSelect
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              placeholder='Please select a file type'
                                              label='File Type'
                                              name={`photos.${index}.filetype`}
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                              options={galleryFileOption}
                                              values={values.photos[index].filetype}
                                              arrValue={values.photos[index].filetype}
                                              setFieldValue={setFieldValue}
                                              // required
                                            />
                                          </div>

                                          {galleryFileOption.find(
                                            (option) =>
                                              option.value === values.photos[index].filetype
                                          )?.label == 'Link' ? (
                                            <div className='col-md-6 col-xs-12'>
                                              <FormInputMediaManager
                                                labelClassName='col-md-12'
                                                containerClassName='col-md-12'
                                                label='Upload Image'
                                                name={`photos.${index}.link`}
                                                setFieldValue={setFieldValue}
                                                setImageUrl={setLinkData}
                                                value={values.photos[index].link}
                                              />

                                              {!isEmpty(values.photos[index].link) ? (
                                                <>
                                                  <li className='listing'>
                                                    <div className='thumbImageBlock'>
                                                      <button
                                                        type='button'
                                                        title='Remove'
                                                        className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                                        onClick={() => {
                                                          setLinkData('')
                                                          setFieldValue(`photos.${index}.link`, '')
                                                        }}
                                                        data-cy='modal-thumbnail-remover'
                                                      >
                                                        X
                                                      </button>

                                                      <img
                                                        className='thumbImage w-100 h-100'
                                                        src={`${imageBaseUrl}/${values.photos[index].link}`}
                                                        alt=''
                                                      />
                                                    </div>
                                                  </li>
                                                </>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div className='col-md-6 col-xs-12'>
                                              <FormTextBox
                                                type='text'
                                                as='textarea'
                                                labelClassName='col-md-12'
                                                containerClassName='col-md-12'
                                                placeholder='Iframe '
                                                label='Iframe'
                                                name={`photos.${index}.iframe`}
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    : null}
                                  <div className='d-flex justify-content-end'>
                                    <button
                                      className=' p-2 ps-3 pe-3 btn btn-primary w-100'
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.push({
                                          id: null,
                                          photoTitle: '',
                                          photoTitleNp: '',
                                          filetype: galleryFileOption[0]?.value,
                                          link: '',
                                          iframe: '',
                                        })
                                      }
                                      data-cy='modal-field-add'
                                    >
                                      + Add More
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='d-flex justify-content-end px-9 mt-2'>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddGallery
