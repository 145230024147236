import {isEmpty} from 'lodash'
import {Suspense, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../cms/partials'
import OnboardedCandidatesList from '../modules/careers/components/onboardedCandidates/component/candidatesList'
import ExamCandidatesList from '../modules/careers/components/shortlistedCandidatesForExamReport/component/candidatesList'
import GroupDiscussionCandidatesList from '../modules/careers/components/shortlistedCandidatesForGroupDiscussion/component/candidatesList'
import InterviewCandidatesList from '../modules/careers/components/shortlistedCandidatesForInterviewReport/component/candidatesList'
import VacancyCandidatesList from '../modules/careers/components/vacancy/component/candidatesList'
import VacancyApplicant from '../modules/careers/components/vacancyApplicantReport/components/index'
import FileManager from '../modules/common/components/fileManager/components'
import {ProfileHeader} from '../modules/profileComponent/components/ProfileHeader'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RouteList} from './RoutesList'
import {IAuthState} from '../../app/modules/auth'
import CheckPermissions from '../modules/common/checkPermission'

export function PrivateRoutes() {
  const module: any = useSelector((state: any) => state.auth.module)
  const auth: IAuthState = useSelector((state: any) => state.auth)
  const [authorizedRoute, setAuthorizedRoute] = useState<Array<string>>([])

  // const hasPermission = auth?.rolePermissions?.permissions.find(
  //   (permission) => permission?.name === `${type} ${activeMenu?.currentActiveMenu}`
  // )

  useEffect(() => {
    if (isEmpty(authorizedRoute)) {
      let newAuthorizedRoute = [...authorizedRoute]
      module?.moduleData?.map((modules: any) =>
        modules.children?.map((route: any) => {
          newAuthorizedRoute?.push(route?.alias)
        })
      )
      setAuthorizedRoute(newAuthorizedRoute)
    }
  }, [module])

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/media-manager' component={FileManager} />
        <Route path='/profile' component={ProfileHeader} />

        <Route path='/career/vacancy/:vacancyId' component={VacancyCandidatesList} exact />

        <Route
          path='/career/onboarded-candidates/:vacancyId'
          component={OnboardedCandidatesList}
          exact
        />

        <Route
          path='/career/shortlisted-exam-candidates/:vacancyId'
          component={ExamCandidatesList}
          exact
        />

        <Route
          path='/career/shortlisted-interview-candidates/:vacancyId'
          component={InterviewCandidatesList}
          exact
        />

        <Route
          path='/career/vacancy-applicant-report/:vacancyId'
          component={VacancyApplicant}
          exact
        />

        <Route
          path='/career/shortlisted-group-discussion-candidates/:vacancyId'
          component={GroupDiscussionCandidatesList}
          exact
        />

        {RouteList?.map(
          (route: any, i: number) =>
            authorizedRoute?.includes(route?.path) && (
              <Route
                path={route?.path}
                key={i}
                exact={route?.exact}
                render={() => {
                  const Component = route.component
                  return (
                    <CheckPermissions type='View'>
                      <Component />
                    </CheckPermissions>
                  )
                }}
              />
            )
        )}

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
      </Switch>
    </Suspense>
  )
}
