import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import { InternshipApplicationModal } from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GENERAL_APPLICATION = `${API_URL}/apply-internship-clipping`
export const GENERAL_APPLICATION_FILE = `${API_URL}/apply-internship-clipping/export`


export const service = {
  getInternshipLists: (params: ParamsModel) => {
    return axios.get(GENERAL_APPLICATION, {params})
  },

  updateInternshipLists: (body: InternshipApplicationModal, id: string) => {
    return axios.put(`${GENERAL_APPLICATION}/${id}`, body)
  },

  deleteInternshipLists: (data: InternshipApplicationModal) => {
    return axios.delete(GENERAL_APPLICATION, {data})
  },

   //Get file
   getInternshipApplicationFile: (fileName: string) => {
    return axios.get(`${GENERAL_APPLICATION_FILE}/${fileName}`).then((response) => {
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Internship.${fileName}`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },
}
