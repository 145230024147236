import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as auth from '../redux/actions'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {isEmpty} from 'lodash'

const tokenSchema = Yup.object().shape({
  token: Yup.string().required('Token is required'),
})

export function OtpValidation() {
  const params: any = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const authResp = useSelector((state: any) => state.auth)
  const loading = authResp?.loading
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    if (!params.state?.email) {
      history.push({
        pathname: '/',
      })
    }
  }, [])

  useEffect(() => {
    if (authResp?.accessToken) {
      dispatch(auth.actions.requestUser(authResp?.accessToken))
    }
  }, [authResp?.accessToken])

  const [timer, setTimer] = useState(0)

  const [count, setCount] = useState(120)
  // let timeConst = timer
  // setCount(timeConst)

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['SMTP Settings']?.map((item: any) => {
          if (item?.name === 'otpResendTime') {
            let data = item?.value
            setTimer(data)
          }
        })
    }
  }, [settingTypeData])

  useEffect(() => {
    const interval = setInterval(() => {
      if (count >= 0) {
        setCount((prevCount) => prevCount - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleResendOTP = () => {
    dispatch(auth.actions.resendOtp(params?.state?.email))
  }

  return (
    <>
      <h4 className='login-box-msg text-center mt-10 mb-3' data-cy='otp-validation-heading'>
        OTP{' '}
      </h4>
      <Formik
        initialValues={{}}
        validationSchema={tokenSchema}
        onSubmit={(values: {[key: string]: string}, {setSubmitting}) => {
          dispatch(auth.actions.otpVerify(values.token, params?.state?.email))
        }}
        data-cy='otp-validation-form'
      >
        {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => (
          <Form>
            <div className='pt-8'>
              <div className=''>
                <FormTextBox
                  labelClassName='col-md-12'
                  containerClassName='col-md-12'
                  type='text'
                  placeholder='Please enter otp'
                  name='token'
                  label='Enter OTP'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  data-cy='otp-validation-token-field'
                />
              </div>
              <div className='text-center my-2'>
                {count > 0 ? (
                  <div className='login-link fs-6 fw-bolder' data-cy='otp-validation-login-link'>
                    OTP expires in {count}s
                  </div>
                ) : (
                  <>
                    <button onClick={handleResendOTP}>Resent OTP</button>
                  </>
                )}
              </div>
              <div className=''>
                <button
                  type='submit'
                  className='btn btn-lg w-100 mb-5'
                  disabled={loading}
                  data-cy='otp-validation-submit-button'
                >
                  {!loading && <span className='indicator-label'>Submit</span>}

                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <div className='text-center mb-5'>
                  <Link
                    to='/auth/login'
                    className='login-link fs-6 fw-bolder'
                    data-cy='otp-validation-login'
                  >
                    Go back
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
