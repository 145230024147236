import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getCustomerLoginDetailsSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getCustomerLoginDetails, params)
    yield put(actions.getCustomerLoginDetailsSuccess(response?.data?.data))
    yield put(actions.getCustomerLoginDetailsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getCustomerLoginDetailsFinish())
  }
}

function* deleteCustomerLoginDetailsSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCustomerLoginDetails, body)
    yield put(actions.deleteCustomerLoginDetailsSuccess(response.data?.data))
    yield put(actions.deleteCustomerLoginDetailsFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCustomerLoginDetailsFinish())
  }
}

function* activateCustomerLogin(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.activateCustomerLogin,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.ACTIVATE_CUSTOMER_LOGIN_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.ACTIVATE_CUSTOMER_LOGIN_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.ACTIVATE_CUSTOMER_LOGIN_FINISH})
  }
}

function* deactivateCustomerLogin(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.deactivateCustomerLogin,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.DEACTIVATE_CUSTOMER_LOGIN_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.DEACTIVATE_CUSTOMER_LOGIN_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.DEACTIVATE_CUSTOMER_LOGIN_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_CUSTOMER_LOGIN_START, getCustomerLoginDetailsSaga)
  yield takeLatest(actionTypes.DELETE_CUSTOMER_LOGIN_START, deleteCustomerLoginDetailsSaga)
  yield takeLatest(actionTypes.ACTIVATE_CUSTOMER_LOGIN_REQUEST, activateCustomerLogin)
  yield takeLatest(actionTypes.DEACTIVATE_CUSTOMER_LOGIN_REQUEST, deactivateCustomerLogin)
}
