import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteCustomerLoginModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CUSTOMER_LOGIN_API = `${API_URL}/customer`

export const service = {
  getCustomerLoginDetails: (params: ParamsModel) => {
    return axios.get(CUSTOMER_LOGIN_API, {params})
  },

  deleteCustomerLoginDetails: (data: DeleteCustomerLoginModel) => {
    return axios.delete(CUSTOMER_LOGIN_API + '/bulk-delete', {data})
  },

  activateCustomerLogin: (id: any) => {
    return axios.put(`${CUSTOMER_LOGIN_API}/bulk-active`, id)
  },

  deactivateCustomerLogin: (id: any) => {
    return axios.put(`${CUSTOMER_LOGIN_API}/bulk-inactive`, id)
  },
}
