import {Action} from 'redux'
import {COMMON_TYPES, PORTFOLIO_TYPES} from './constant'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IPortfolioState {
  data?: {
    portfolio?: {[key: string]: number | string}[]
    meta?: {[key: string]: number}
  }
  success: false
  deleteSuccess?: boolean
  loading?: boolean
}

const INIT_STATE: IPortfolioState = {
  data: {
    portfolio: [],
    meta: {},
  },
  success: false,
  deleteSuccess: false,
  loading: false,
}
export const reducer = (
  state: IPortfolioState = INIT_STATE,
  action: ActionWithPayload<IPortfolioState>
) => {
  switch (action.type) {
    case PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload,
      }
    case PORTFOLIO_TYPES.GET_PORTFOLIO_LIST + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.SUCCESS:
      return {...state, data: action?.payload?.data, loading: false, success: true}
    case PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.CALL_OVER:
      return {...state, success: false}
    case PORTFOLIO_TYPES.ADD_PORTFOLIO + COMMON_TYPES.FAILURE:
      return {...state, loading: false}
    case PORTFOLIO_TYPES.RESET_PORTFOLIO:
      return {...state, success: false}
    case PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.REQUEST:
      return {...state, loading: true}
    case PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.SUCCESS:
      return {...state, loading: false, deleteSuccess: true}
    case PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.CALL_OVER:
      return {...state, loading: false, deleteSuccess: false}
    case PORTFOLIO_TYPES.DELETE_PORTFOLIO + COMMON_TYPES.FAILURE:
      return {...state, loading: false, deleteSuccess: false}
    default:
      return state
  }
}
