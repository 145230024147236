export const actionTypes = {
  // get
  GET_ALL_CUSTOMER_TESTIMONIALS_START: 'GET_ALL_CUSTOMER_TESTIMONIALS_START',
  GET_ALL_CUSTOMER_TESTIMONIALS_SUCCESS: 'GET_ALL_CUSTOMER_TESTIMONIALS_SUCCESS',
  GET_ALL_CUSTOMER_TESTIMONIALS_FINISH: 'GET_ALL_CUSTOMER_TESTIMONIALS_FINISH',

  // get Customer Testimonials
  GET_CUSTOMER_TESTIMONIALS_START: 'GET_CUSTOMER_TESTIMONIALS_START',
  GET_CUSTOMER_TESTIMONIALS_SUCCESS: 'GET_CUSTOMER_TESTIMONIALS_SUCCESS',
  GET_CUSTOMER_TESTIMONIALS_FINISH: 'GET_CUSTOMER_TESTIMONIALS_FINISH',

  // get Customer Testimonials
  GET_CUSTOMER_TESTIMONIALS_MEDIA_START: 'GET_CUSTOMER_TESTIMONIALS_START',
  GET_CUSTOMER_TESTIMONIALS_MEDIA_SUCCESS: 'GET_CUSTOMER_TESTIMONIALS_SUCCESS',
  GET_CUSTOMER_TESTIMONIALS_MEDIA_FINISH: 'GET_CUSTOMER_TESTIMONIALS_FINISH',

  //Add Customer Testimonials
  ADD_CUSTOMER_TESTIMONIALS_REQUEST: 'ADD_CUSTOMER_TESTIMONIALS_REQUEST',
  ADD_CUSTOMER_TESTIMONIALS_SUCCESS: 'ADD_CUSTOMER_TESTIMONIALS_SUCCESS',
  ADD_CUSTOMER_TESTIMONIALS_FINISH: 'ADD_CUSTOMER_TESTIMONIALS_FINISH',
  ADD_CUSTOMER_TESTIMONIALS_RESET: 'ADD_CUSTOMER_TESTIMONIALS_RESET',

  // update Customer Testimonials
  UPDATE_CUSTOMER_TESTIMONIALS_START: 'UPDATE_CUSTOMER_TESTIMONIALS_START',
  UPDATE_CUSTOMER_TESTIMONIALS_SUCCESS: 'UPDATE_CUSTOMER_TESTIMONIALS_SUCCESS',
  UPDATE_CUSTOMER_TESTIMONIALS_FINISH: 'UPDATE_CUSTOMER_TESTIMONIALS_FINISH',
  UPDATE_CUSTOMER_TESTIMONIALS_RESET: 'UPDATE_CUSTOMER_TESTIMONIALS_RESET',

  //Bulk Activate Customer Testimonials
  ACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST: 'ACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST',
  ACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS: 'ACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS',
  ACTIVATE_CUSTOMER_TESTIMONIALS_FINISH: 'ACTIVATE_CUSTOMER_TESTIMONIALS_FINISH',

  //Bulk Deactivate Customer Testimonials
  DEACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST: 'DEACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST',
  DEACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS: 'DEACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS',
  DEACTIVATE_CUSTOMER_TESTIMONIALS_FINISH: 'DEACTIVATE_CUSTOMER_TESTIMONIALS_FINISH',

  //Single Activate Customer Testimonials
  SINGLE_ACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST: 'SINGLE_ACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST',
  SINGLE_ACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS: 'SINGLE_ACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS',
  SINGLE_ACTIVATE_CUSTOMER_TESTIMONIALS_FINISH: 'SINGLE_ACTIVATE_CUSTOMER_TESTIMONIALS_FINISH',

  //Single Deactivate Customer Testimonials
  SINGLE_DEACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST:
    'SINGLE_DEACTIVATE_CUSTOMER_TESTIMONIALS_REQUEST',
  SINGLE_DEACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS:
    'SINGLE_DEACTIVATE_CUSTOMER_TESTIMONIALS_SUCCESS',
  SINGLE_DEACTIVATE_CUSTOMER_TESTIMONIALS_FINISH: 'SINGLE_DEACTIVATE_CUSTOMER_TESTIMONIALS_FINISH',

  // delete Customer Testimonials
  DELETE_CUSTOMER_TESTIMONIALS_START: 'DELETE_CUSTOMER_TESTIMONIALS_START',
  DELETE_CUSTOMER_TESTIMONIALS_SUCCESS: 'DELETE_CUSTOMER_TESTIMONIALS_SUCCESS',
  DELETE_CUSTOMER_TESTIMONIALS_FINISH: 'DELETE_CUSTOMER_TESTIMONIALS_FINISH',

  // sort
  SORT_CUSTOMER_TESTIMONIALS_START: 'SORT_CUSTOMER_TESTIMONIALS_START',
  SORT_CUSTOMER_TESTIMONIALS_SUCCESS: 'SORT_CUSTOMER_TESTIMONIALS_SUCCESS',
  SORT_CUSTOMER_TESTIMONIALS_FINISH: 'SORT_CUSTOMER_TESTIMONIALS_FINISH',
  SORT_CUSTOMER_TESTIMONIALS_RESET: 'SORT_CUSTOMER_TESTIMONIALS_RESET',
}
