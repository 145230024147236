import { ParamsModel } from 'src/app/modules/common/Model'
import {
  ActivityLogModel
} from '../Model/Model'
import { actionTypes } from './constants'

export const actions = {
  // get Activity Logs DATA
  getActivityLogs: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ACTIVITY_LOG_START,
    payload: params,
  }),
  getActivityLogsSuccess: (data: ActivityLogModel | any) => ({
    type: actionTypes.GET_ACTIVITY_LOG_SUCCESS,
    payload: data,
  }),
  getActivityLogsFinish: () => ({
    type: actionTypes.GET_ACTIVITY_LOG_FINISH,
  }),

  
}
