import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteNotificationModel, NotificationModel} from '../Model'
import {actionTypes} from './constants'
export const actions = {
  // get Notification DATA
  getNotification: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_NOTIFICATION_START,
    payload: params,
  }),
  getNotificationSuccess: (data: NotificationModel) => ({
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    payload: data,
  }),
  getNotificationFinish: () => ({
    type: actionTypes.GET_NOTIFICATION_FINISH,
  }),

  // create key
  addNotification: (data: NotificationModel) => ({
    type: actionTypes.ADD_NOTIFICATION_START,
    payload: data,
  }),
  addNotificationSuccess: (task: any) => ({
    type: actionTypes.ADD_NOTIFICATION_SUCCESS,
    payload: task,
  }),
  addNotificationFinish: () => ({
    type: actionTypes.ADD_NOTIFICATION_FINISH,
  }),
  resetNotification: () => ({
    type: actionTypes.RESET_NOTIFICATION,
  }),

  //Update Notification
  updateNotification: (data: NotificationModel, id: string) => ({
    type: actionTypes.UPDATE_NOTIFICATION_START,
    payload: data,
    id,
  }),

  updateNotificationSuccess: (data: NotificationModel) => ({
    type: actionTypes.UPDATE_NOTIFICATION_SUCCESS,
    payload: data,
  }),

  updateNotificationFinish: () => ({
    type: actionTypes.UPDATE_NOTIFICATION_FINISH,
  }),

  // delete key
  deleteNotification: (data: DeleteNotificationModel[]) => ({
    type: actionTypes.DELETE_NOTIFICATION_START,
    payload: {notificationsId: data},
  }),
  deleteNotificationSuccess: (data: any) => ({
    type: actionTypes.DELETE_NOTIFICATION_SUCCESS,
    payload: data,
  }),
  deleteNotificationFinish: () => ({
    type: actionTypes.DELETE_NOTIFICATION_FINISH,
  }),
}
