import { ErrorMessage, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
// branch
import moment from 'moment'
import * as categories from 'src/app/modules/cms/components/categories'
import { GalleryModel } from 'src/app/modules/cms/components/gallery/Model'
import * as galleryRedux from 'src/app/modules/cms/components/gallery/redux'
import * as categoryTypeRedux from 'src/app/modules/common'
import { StateParamsModel } from 'src/app/modules/common/Model'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import { ISettingTypeState } from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import { bankName, imageBaseUrl } from 'src/cms/helpers/constants'
import * as subCategory from '../../subCategory'
import * as news from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  categoryId: Yup.string().required('Category is required'),
  name: Yup.string().required('Name is required'),
  published_date: Yup.string().required('Published date is required').nullable(),
  status: Yup.string().required('Status is required'),
  is_featured: Yup.string().required('Featured News is required'),
})

const AddNewsModal = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()

  const [categoryValue, setCategoryValue] = useState('')
  const [attachedFile, setAttachedFile] = useState('')
  const [imageEng, setImageEng] = useState('')
  const [imageNp, setImageNp] = useState('')
  const [newsCategory, setNewsCategory] = useState('')
  const [attachfile, setAttachfile] = useState('')
  const [applicationName, setApplicationName] = useState('')

  const { loading, success } = useSelector((state: any) => state.news)
  const {
    data: { categoryType },
  } = useSelector((state: any) => state.categoryType)
  const {
    data: { category },
  } = useSelector((state: any) => state.categories)

  const { data: subCategoryData } = useSelector((state: any) => state.subCategory)
  const {
    data: { album },
  } = useSelector((state: any) => state.gallery)

  useEffect(() => {
    dispatch(categoryTypeRedux.action.getCategoryType())
  }, [])

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
    dispatch(galleryRedux.actions.getAllGallery())
  }, [])
  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setApplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])

  useEffect(() => {
    const newsCatType = categoryType?.filter((item: { [key: string]: string }) =>
      bankName === applicationName
        ? item.system_name === 'news' || item.system_name === 'notice'
        : item.display_name === 'News'
    )

    setNewsCategory(newsCatType[0]?.id)
  }, [categoryType])

  useEffect(() => {
    if (!isEmpty(newsCategory)) {
      dispatch(categories.actions.getSpecificCmsCategories(newsCategory))
    }
  }, [newsCategory])

  useEffect(() => {
    if (categoryValue) {
      dispatch(subCategory.actions.getSpecificSubCategory(categoryValue))
    }
  }, [categoryValue])

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]

  const categoryOptions = category?.map((items: any) => ({
    label: items.name,
    value: items.id,
  }))

  const albumOptions = album?.map((items: GalleryModel) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('News added successfully')
        : toast.success('News edited successfully')
      dispatch(news?.actions.getNews(params))
      dispatch(news?.actions?.addNewsReset())
      handleClose()
    }
  }, [success])

  function convertToSlug(text: any) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  const featuredNewsOptions = [
    { label: 'Yes', value: 'Active' },
    { label: 'No', value: 'Inactive' },
  ]

  return (
    <div className='modal-container' data-cy="add-news-modal-container">
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                description: '',
                archive_date: '',
                categoryId: '',
                published_date: null,
                show_pop_up: false,
                status: '',
                image: '',
                albumId: '',
                attached_file: '',
                visibility_time: '',
                is_featured: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, { setSubmitting }) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  slug: !isEmpty(editSelectedData)
                    ? editSelectedData?.slug
                    : convertToSlug(values?.name),
                  published_date: moment(values?.published_date).format('YYYY-MM-DD'),
                  archive_date: values?.archive_date
                    ? moment(values?.archive_date).format('YYYY-MM-DD')
                    : null,
                  visibility_time: values?.visibility_time
                    ? values?.visibility_time.toString()
                    : null,
                  is_featured: values?.is_featured === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(news.actions.updateNews(formData, editSelectedData?.id))
                } else {
                  dispatch(news.actions.addNews(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'description',
                      'categoryId',
                      'published_date',
                      'slug',
                      'attached_file_title',
                      'attached_file',
                      'visibility_time',
                      'albumId',
                      'show_pop_up',
                      'image',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'archive_date',
                      editSelectedData?.archive_date
                        ? moment(editSelectedData?.archive_date, 'YYYY-MM-DD').toDate()
                        : null,
                      false
                    )
                    setFieldValue(
                      'published_date',
                      moment(editSelectedData?.published_date, 'YYYY-MM-DD').toDate(),
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'is_featured',
                      editSelectedData?.is_featured === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select category'
                            label='Category'
                            name='categoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={categoryOptions}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='add-new-modal-category-drop-down'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='News title'
                            label='News title '
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-news-title-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Attached file title'
                            label='Attached file title '
                            name='attached_file_title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='add-news-modal-file-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Attached File'
                            name='attached_file'
                            setFieldValue={setFieldValue}
                            setImageUrl={setAttachedFile}
                            value={values?.attached_file}
                            data-cy='add-news-modal-file-np-text-field'
                            buttonName='Add Attached File'
                          />
                          {!isEmpty(values?.attached_file) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setAttachfile('')
                                      setFieldValue('attached_file', '')
                                    }}
                                    data-cy="news-modal-remove-image-button"
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.attached_file.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.attached_file}`
                                    }
                                    alt=''
                                    data-cy='news-modal-file-preview'
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter slug'
                            label='Slug'
                            name='slug'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={true}
                            value={
                              !isEmpty(editSelectedData)
                                ? editSelectedData?.slug
                                : convertToSlug(values?.name)
                            }
                            data-cy='add-news-modal-slug-text-field'
                          />
                        </div>
                        {/* <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='date'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter publish date'
                            label='Published date'
                            name='published_date'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div> */}
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            label='Published date'
                            name='published_date'
                            placeholderText='Please select a Published date'
                            setFieldValue={setFieldValue}
                            value={values.published_date || false}
                            errors={errors}
                            showYearDropdown
                            peekNextMonth
                            showMonthDropdown
                            touched={touched}
                            required={true}
                            showIcon={true}
                            data-cy='news-modal-publish-date-time-drop-down'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormDatepicker
                            labelClassName='col-12'
                            containerClassName='col-12 '
                            minDate={values.published_date}
                            label='Archive date'
                            name='archive_date'
                            placeholderText='Please select a Archive date'
                            setFieldValue={setFieldValue}
                            value={values.archive_date || false}
                            errors={errors}
                            showYearDropdown
                            peekNextMonth
                            showMonthDropdown
                            touched={touched}
                            showIcon={true}
                            disabled={values.published_date ? false : true}
                            data-cy='news-modal-archive-date-time-drop-down'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description'
                            name='description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                            data-cy='add-news-modal-description-text-area'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select album'
                            label='Album'
                            name='albumId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={albumOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            data-cy='add-news-modal-album-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image '
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageEng}
                            value={values?.image}
                            showImageOnly
                          />

                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageEng('')
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='thumbnail-remove-button'
                                  >
                                    Delete
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.image}`}
                                    alt=''
                                    data-cy='news-image-preview'
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Featured News
                          </label>

                          <div className='d-flex ms-5 mt-3'>
                            {featuredNewsOptions?.map(
                              (featuredOption: { label: string; value: string }) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Featured News'
                                  name='is_featured'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  checkBoxText={featuredOption?.label}
                                  value={featuredOption?.value}
                                  required
                                  data-cy='news-modal-featured-radio-button'
                                />
                              )
                            )}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='is_featured'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                key={status?.value}
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='news-modal-status-radio-button'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddNewsModal
