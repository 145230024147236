export const actionTypes = {
  // Get BRANCH_MANAGER Data
  GET_BRANCH_MANAGER_DATA_START: 'GET_BRANCH_MANAGER_DATA_START',
  GET_BRANCH_MANAGER_DATA_SUCCESS: 'GET_BRANCH_MANAGER_DATA_SUCCESS',
  GET_BRANCH_MANAGER_DATA_FINISH: 'GET_BRANCH_MANAGER_DATA_FINISH',

  // add BRANCH_MANAGER
  ADD_BRANCH_MANAGER_START: 'ADD_BRANCH_MANAGER_START',
  ADD_BRANCH_MANAGER_SUCCESS: 'ADD_BRANCH_MANAGER_SUCCESS',
  ADD_BRANCH_MANAGER_FINISH: 'ADD_BRANCH_MANAGER_FINISH',
  RESET_BRANCH_MANAGER: 'RESET_BRANCH_MANAGER',

  // update branch Manager
  UPDATE_BRANCH_MANAGER_START: 'UPDATE_BRANCH_MANAGER_START',
  UPDATE_BRANCH_MANAGER_SUCCESS: 'UPDATE_BRANCH_MANAGER_SUCCESS',
  UPDATE_BRANCH_MANAGER_FINISH: 'UPDATE_BRANCH_MANAGER_FINISH',

  // delete branch Manager
  DELETE_BRANCH_MANAGER_START: 'DELETE_BRANCH_MANAGER_START',
  DELETE_BRANCH_MANAGER_SUCCESS: 'DELETE_BRANCH_MANAGER_SUCCESS',
  DELETE_BRANCH_MANAGER_FINISH: 'DELETE_BRANCH_MANAGER_FINISH',

  // Import branch Manager
  IMPORT_BRANCH_MANAGER_START: 'IMPORT_BRANCH_MANAGER_START',
  IMPORT_BRANCH_MANAGER_SUCCESS: 'IMPORT_BRANCH_MANAGER_SUCCESS',
  IMPORT_BRANCH_MANAGER_FINISH: 'IMPORT_BRANCH_MANAGER_FINISH',

  // Enable Branch Manager
  ENABLE_BRANCH_MANAGER_REQUEST: 'ENABLE_BRANCH_MANAGER_REQUEST',
  ENABLE_BRANCH_MANAGER_SUCCESS: 'ENABLE_BRANCH_MANAGER_SUCCESS',
  ENABLE_BRANCH_MANAGER_FINISH: 'ENABLE_BRANCH_MANAGER_FINISH',

  // Disable Branch Manager
  DISABLE_BRANCH_MANAGER_REQUEST: 'DISABLE_BRANCH_MANAGER_REQUEST',
  DISABLE_BRANCH_MANAGER_SUCCESS: 'DISABLE_BRANCH_MANAGER_SUCCESS',
  DISABLE_BRANCH_MANAGER_FINISH: 'DISABLE_BRANCH_MANAGER_FINISH',

  // Enable Branch Manager
  SINGLE_ENABLE_BRANCH_MANAGER_REQUEST: 'SINGLE_ENABLE_BRANCH_MANAGER_REQUEST',
  SINGLE_ENABLE_BRANCH_MANAGER_SUCCESS: 'SINGLE_ENABLE_BRANCH_MANAGER_SUCCESS',
  SINGLE_ENABLE_BRANCH_MANAGER_FINISH: 'SINGLE_ENABLE_BRANCH_MANAGER_FINISH',

  // Disable Branch Manager
  SINGLE_DISABLE_BRANCH_MANAGER_REQUEST: 'SINGLE_DISABLE_BRANCH_MANAGER_REQUEST',
  SINGLE_DISABLE_BRANCH_MANAGER_SUCCESS: 'SINGLE_DISABLE_BRANCH_MANAGER_SUCCESS',
  SINGLE_DISABLE_BRANCH_MANAGER_FINISH: 'SINGLE_DISABLE_BRANCH_MANAGER_FINISH',

  // get File csv xlsx
  EXPORT_BRANCH_MANAGER_FILE_START: 'EXPORT_BRANCH_MANAGER_FILE_START',
  EXPORT_BRANCH_MANAGER_FILE_SUCCESS: 'EXPORT_BRANCH_MANAGER_FILE_SUCCESS',
  EXPORT_BRANCH_MANAGER_FILE_FINISH: 'EXPORT_BRANCH_MANAGER_FILE_FINISH',

  EXPORT_BRANCH_MANAGER_TEMPLATE_FILE_START: 'EXPORT_BRANCH_MANAGER_TEMPLATE_FILE_START',
  EXPORT_BRANCH_MANAGER_TEMPLATE_FILE_SUCCESS: 'EXPORT_BRANCH_MANAGER_TEMPLATE_FILE_SUCCESS',
  EXPORT_BRANCH_MANAGER_TEMPLATE_FILE_FINISH: 'EXPORT_BRANCH_MANAGER_TEMPLATE_FILE_FINISH',
}
