import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {IModuleModel, SortHomepageSettingModel} from '../Model'
const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_SECTION_API = `${API_URL}/homepage/sections`
export const GET_LAYOUT_API = `${API_URL}/homepage/layouts`
export const HOMEPAGE_SETTING_API = `${API_URL}/homepage`

export const service = {
  getHomepageSection: () => {
    return axios.get(GET_SECTION_API)
  },

  getHomepageLayout: () => {
    return axios.get(GET_LAYOUT_API)
  },

  addHomepageSetting: (body: {[key: string]: string}) => {
    return axios.post(HOMEPAGE_SETTING_API, body)
  },

  updateHomepageSetting: (body: IModuleModel, id: string) => {
    return axios.patch(`${HOMEPAGE_SETTING_API}/${id}`, body)
  },

  getHomepageSetting: (params: ParamsModel) => {
    return axios.get(HOMEPAGE_SETTING_API, {params})
  },

  deleteHomepageSetting: (data: any) => {
    return axios.delete(`${HOMEPAGE_SETTING_API}/homePage`, {data})
  },

  singleEnableHomepageSetting: (data: Array<string>) => {
    const selectedHomepageSetting = {
      homePageId: [data],
    }
    return axios.patch(`${HOMEPAGE_SETTING_API}/enable`, selectedHomepageSetting)
  },

  singleDisableHomepageSetting: (data: Array<string>) => {
    const selectedHomepageSetting = {
      homePageId: [data],
    }
    return axios.patch(`${HOMEPAGE_SETTING_API}/disable`, selectedHomepageSetting)
  },

  sortHomepageSetting: (body: SortHomepageSettingModel) => {
    return axios.patch(`${HOMEPAGE_SETTING_API}/sort`, body)
  },
}
