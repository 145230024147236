export const actionTypes = {
  // get Interest Rate
  GET_EXCHANGE_RATE_START: 'GET_EXCHANGE_RATE_START',
  GET_EXCHANGE_RATE_SUCCESS: 'GET_EXCHANGE_RATE_SUCCESS',
  GET_EXCHANGE_RATE_FINISH: 'GET_EXCHANGE_RATE_FINISH',

  // add Interest Rate
  ADD_EXCHANGE_RATE_START: 'ADD_EXCHANGE_RATE_START',
  ADD_EXCHANGE_RATE_SUCCESS: 'ADD_EXCHANGE_RATE_SUCCESS',
  ADD_EXCHANGE_RATE_FINISH: 'ADD_EXCHANGE_RATE_FINISH',
  RESET_EXCHANGE_RATE_TYPE: 'RESET_EXCHANGE_RATE_TYPE',

  // update Interest Rate
  UPDATE_EXCHANGE_RATE_START: 'UPDATE_EXCHANGE_RATE_START',
  UPDATE_EXCHANGE_RATE_SUCCESS: 'UPDATE_EXCHANGE_RATE_SUCCESS',
  UPDATE_EXCHANGE_RATE_FINISH: 'UPDATE_EXCHANGE_RATE_FINISH',

  // delete Interest Rate
  DELETE_EXCHANGE_RATE_START: 'DELETE_EXCHANGE_RATE_START',
  DELETE_EXCHANGE_RATE_SUCCESS: 'DELETE_EXCHANGE_RATE_SUCCESS',
  DELETE_EXCHANGE_RATE_FINISH: 'DELETE_EXCHANGE_RATE_FINISH',

  // Enable Interest Rate
  ENABLE_EXCHANGE_RATE_REQUEST: 'ENABLE_EXCHANGE_RATE_REQUEST',
  ENABLE_EXCHANGE_RATE_SUCCESS: 'ENABLE_EXCHANGE_RATE_SUCCESS',
  ENABLE_EXCHANGE_RATE_FINISH: 'ENABLE_EXCHANGE_RATE_FINISH',

  // Disable Interest Rate
  DISABLE_EXCHANGE_RATE_REQUEST: 'DISABLE_EXCHANGE_RATE_REQUEST',
  DISABLE_EXCHANGE_RATE_SUCCESS: 'DISABLE_EXCHANGE_RATE_SUCCESS',
  DISABLE_EXCHANGE_RATE_FINISH: 'DISABLE_EXCHANGE_RATE_FINISH',

  // Enable Interest Rate
  SINGLE_ENABLE_EXCHANGE_RATE_REQUEST: 'SINGLE_ENABLE_EXCHANGE_RATE_REQUEST',
  SINGLE_ENABLE_EXCHANGE_RATE_SUCCESS: 'SINGLE_ENABLE_EXCHANGE_RATE_SUCCESS',
  SINGLE_ENABLE_EXCHANGE_RATE_FINISH: 'SINGLE_ENABLE_EXCHANGE_RATE_FINISH',

  // Disable Interest Rate
  SINGLE_DISABLE_EXCHANGE_RATE_REQUEST: 'SINGLE_DISABLE_EXCHANGE_RATE_REQUEST',
  SINGLE_DISABLE_EXCHANGE_RATE_SUCCESS: 'SINGLE_DISABLE_EXCHANGE_RATE_SUCCESS',
  SINGLE_DISABLE_EXCHANGE_RATE_FINISH: 'SINGLE_DISABLE_EXCHANGE_RATE_FINISH',

  // Download ServicePartner Sample
  DOWNLOAD_EXCHANGE_RATE_SAMPLE_REQUEST: 'DOWNLOAD_EXCHANGE_RATE_SAMPLE_REQUEST',
  DOWNLOAD_EXCHANGE_RATE_SAMPLE_SUCCESS: 'DOWNLOAD_EXCHANGE_RATE_SAMPLE_SUCCESS',
  DOWNLOAD_EXCHANGE_RATE_SAMPLE_FINISH: 'DOWNLOAD_EXCHANGE_RATE_SAMPLE_FINISH',

  // Import ServicePartner
  IMPORT_EXCHANGE_RATE_REQUEST: 'IMPORT_EXCHANGE_RATE_REQUEST',
  IMPORT_EXCHANGE_RATE_SUCCESS: 'IMPORT_EXCHANGE_RATE_SUCCESS',
  IMPORT_EXCHANGE_RATE_FINISH: 'IMPORT_EXCHANGE_RATE_FINISH',

  // Country List
  GET_EXCHANGE_RATE_COUNTRY_REQUEST: 'GET_EXCHANGE_RATE_COUNTRY_REQUEST',
  GET_EXCHANGE_RATE_COUNTRY_SUCCESS: 'GET_EXCHANGE_RATE_COUNTRY_SUCCESS',
  GET_EXCHANGE_RATE_COUNTRY_FINISH: 'GET_EXCHANGE_RATE_FINISH',

  // Currency List
  GET_EXCHANGE_RATE_CURRENCY_REQUEST: 'GET_EXCHANGE_RATE_CURRENCY_REQUEST',
  GET_EXCHANGE_RATE_CURRENCY_SUCCESS: 'GET_EXCHANGE_RATE_CURRENCY_SUCCESS',
  GET_EXCHANGE_RATE_CURRENCY_FINISH: 'GET_EXCHANGE_RATE_CURRENCY_FINISH',
}
