import {Action} from 'redux'
import {CsrCategoryModel} from '../Model/CsrCategoryModel'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialCsrCategoryState: ICsrCategoryState = {
  data: {
    csrCategory: [],
    meta: {},
  },
  deleteSuccess: false,
  sortCsrCategoryData: [],

  loading: false,
  success: false,
}

export interface ICsrCategoryState {
  data: {
    csrCategory: CsrCategoryModel[]
    meta: {[key: string]: number}
  }
  activateSuccess?: boolean
  deactivateSuccess?: boolean
  singleActivateSuccess?: boolean
  singleDeactivateSuccess?: boolean
  deleteSuccess?: boolean
  sortCsrCategoryData: ICsrCategoryState[]
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: ICsrCategoryState = initialCsrCategoryState,
  action: ActionWithPayload<ICsrCategoryState>
) => {
  switch (action.type) {
    //GET CSR_CATEGORY_DROPDOWN DATA
    case actionTypes.GET_CSR_CATEGORY_DROPDOWN_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_CSR_CATEGORY_DROPDOWN_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_CSR_CATEGORY_DROPDOWN_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET SPECIFI_CSR_CATEGORY DATA
    case actionTypes.GET_CSR_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_CSR_CATEGORY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_CSR_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //GET CSR_CATEGORY DATA
    case actionTypes.GET_CSR_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.GET_CSR_CATEGORY_SUCCESS: {
      return {...state, data: action.payload, loading: false}
    }

    case actionTypes.GET_CSR_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Add SUB CATEGORY

    case actionTypes.ADD_CSR_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.ADD_CSR_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_CSR_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.ADD_CSR_CATEGORY_RESET: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update csrCategory
    case actionTypes.UPDATE_CSR_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_CSR_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_CSR_CATEGORY_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    //Delete
    case actionTypes.DELETE_CSR_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_CSR_CATEGORY_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_CSR_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Singer activate branchless banking
    //Activate Users
    case actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_SUCCESS: {
      return {...state, activateSuccess: true, loading: false}
    }
    case actionTypes.SINGLE_ACTIVATE_CSR_CATEGORY_FINISH: {
      return {...state, activateSuccess: false, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_SUCCESS: {
      return {...state, deactivateSuccess: true, loading: false}
    }

    case actionTypes.SINGLE_DEACTIVATE_CSR_CATEGORY_FINISH: {
      return {...state, deactivateSuccess: false, loading: false}
    }

    // sort
    case actionTypes.SORT_CSR_CATEGORY_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_CSR_CATEGORY_SUCCESS: {
      return {
        ...state,
        sortCsrCategoryData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_CSR_CATEGORY_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_CSR_CATEGORY_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortCsrCategoryData: [],
      }
    }

    default:
      return state
  }
}
