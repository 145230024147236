import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteExchangeRateModel, ExchangeRateModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_EXCHANGE_RATE = `${API_URL}/servicepartner-rate/list`
export const POST_EXCHANGE_RATE = `${API_URL}/servicepartner-rate`
export const DELETE_EXCHANGE_RATE = `${API_URL}/servicepartner-rate/destroy`
export const IMPORT_EXCHANGE_RATE = `${API_URL}/servicepartner-rate/store-from-file`
export const DOWNLOAD_EXCHANGE_RATE_SAMPLE = `${API_URL}/servicepartner-rate/get-sample`

export const service = {
  getExchangeRate: (params: ParamsModel) => {
    return axios.get(GET_EXCHANGE_RATE, {params})
  },
  addExchangeRate: (data: any) => {
    return axios.post(POST_EXCHANGE_RATE, data)
  },

  updateExchangeRate: (body: ExchangeRateModel, id: string) => {
    return axios.patch(`${POST_EXCHANGE_RATE}/${id}`, body)
  },

  deleteExchangeRate: (data: DeleteExchangeRateModel) => {
    return axios.delete(DELETE_EXCHANGE_RATE, {data})
  },

  enableExchangeRate: (data: Array<string>) => {
    const selectedExchangeRate = {
      servicePartnerRateId: data,
    }
    return axios.patch(`${POST_EXCHANGE_RATE}/enable`, selectedExchangeRate)
  },

  disableExchangeRate: (data: Array<string>) => {
    const selectedExchangeRate = {
      servicePartnerRateId: data,
    }
    return axios.patch(`${POST_EXCHANGE_RATE}/disable`, selectedExchangeRate)
  },

  singleEnableExchangeRate: (data: Array<string>) => {
    const selectedExchangeRate = {
      servicePartnerRateId: [data],
    }
    return axios.patch(`${POST_EXCHANGE_RATE}/enable`, selectedExchangeRate)
  },

  singleDisableExchangeRate: (data: Array<string>) => {
    const selectedExchangeRate = {
      servicePartnerRateId: [data],
    }
    return axios.patch(`${POST_EXCHANGE_RATE}/disable`, selectedExchangeRate)
  },

  downloadSample: (fileName: string) => {
    return axios.get(`${DOWNLOAD_EXCHANGE_RATE_SAMPLE}/${fileName}`).then((response) => {
      const url = response?.data?.data?.file
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `service_partner_rate.${fileName}`)
      document.body.appendChild(link)
      link.click()
    })
  },

  importExchangeRate: (data: any) => {
    return axios.post(IMPORT_EXCHANGE_RATE, data)
  },
}
