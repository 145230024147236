import {ParamsModel} from 'src/app/modules/common/Model'
import {actionTypes} from './constants'

export const actions = {
  getActiveTutorialCategory: (id: string = '') => ({
    type: actionTypes.GET_ACTIVE_TUTORIAL_CATEGORY_START,
    payload: id,
  }),

  getActiveTutorialCategorySuccess: (data: {[key: string]: string}) => ({
    type: actionTypes.GET_ACTIVE_TUTORIAL_CATEGORY_SUCCESS,
    payload: data,
  }),
  getActiveTutorialCategoryFinish: () => ({
    type: actionTypes.GET_ACTIVE_TUTORIAL_CATEGORY_FINISH,
  }),
  getActiveTutorialCategoryError: (error: unknown) => ({
    type: actionTypes.GET_ACTIVE_TUTORIAL_CATEGORY_FINISH,
    payload: {error},
  }),

  getTutorialCategory: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_TUTORIAL_CATEGORY_START,
    payload: params,
  }),

  getTutorialCategorySuccess: (data: any) => ({
    type: actionTypes.GET_TUTORIAL_CATEGORY_SUCCESS,
    payload: data,
  }),
  getTutorialCategoryFinish: () => ({
    type: actionTypes.GET_TUTORIAL_CATEGORY_FINISH,
  }),
  getTutorialCategoryError: (error: unknown) => ({
    type: actionTypes.GET_TUTORIAL_CATEGORY_FINISH,
    payload: {error},
  }),

  addTutorialCategory: (data: any) => ({
    type: actionTypes.ADD_TUTORIAL_CATEGORY_START,
    payload: data,
  }),
  addTutorialCategorySuccess: (task: any) => ({
    type: actionTypes.ADD_TUTORIAL_CATEGORY_SUCCESS,
    payload: task,
  }),
  addTutorialCategoryFinish: () => ({
    type: actionTypes.ADD_TUTORIAL_CATEGORY_FINISH,
  }),
  addTutorialCategoryReset: () => ({
    type: actionTypes.ADD_TUTORIAL_CATEGORY_RESET,
  }),

  updateTutorialCategory: (data: any, id: string) => ({
    type: actionTypes.UPDATE_TUTORIAL_CATEGORY_START,
    payload: {data, id},
  }),
  updateTutorialCategorySuccess: (task: any) => ({
    type: actionTypes.UPDATE_TUTORIAL_CATEGORY_SUCCESS,
    payload: task,
  }),
  updateTutorialCategoryFinish: () => ({
    type: actionTypes.UPDATE_TUTORIAL_CATEGORY_FINISH,
  }),
  updateTutorialCategoryReset: () => ({
    type: actionTypes.UPDATE_TUTORIAL_CATEGORY_RESET,
  }),

  deleteTutorialCategory: (data: any) => ({
    type: actionTypes.DELETE_TUTORIAL_CATEGORY_START,
    payload: {tutorialCategoryId: data},
  }),
  deleteTutorialCategorySuccess: (data: any) => ({
    type: actionTypes.DELETE_TUTORIAL_CATEGORY_SUCCESS,
    payload: data,
  }),
  deleteTutorialCategoryFinish: () => ({
    type: actionTypes.DELETE_TUTORIAL_CATEGORY_FINISH,
  }),
  activateTutorialCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_CATEGORY_REQUEST,
    payload: {tutorialCategoryId: id},
  }),
  deactivateTutorialCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_REQUEST,
    payload: {tutorialCategoryId: id},
  }),
  singleActivateTutorialCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_ACTIVATE_TUTORIAL_CATEGORY_REQUEST,
    payload: {tutorialCategoryId: [id]},
  }),
  singleDeactivateTutorialCategory: (id: {[key: string]: string}) => ({
    type: actionTypes.SINGLE_DEACTIVATE_TUTORIAL_CATEGORY_REQUEST,
    payload: {tutorialCategoryId: [id]},
  }),

  sortTutorialCategory: (data: any) => ({
    type: actionTypes.SORT_TUTORIAL_CATEGORY_START,
    payload: data,
  }),
  sortTutorialCategorySuccess: (data: any) => ({
    type: actionTypes.SORT_TUTORIAL_CATEGORY_SUCCESS,
    payload: data,
  }),
  sortTutorialCategoryFinish: () => ({
    type: actionTypes.SORT_TUTORIAL_CATEGORY_FINISH,
  }),
  sortTutorialCategoryReset: () => ({
    type: actionTypes.SORT_TUTORIAL_CATEGORY_RESET,
  }),
}
