import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {actions} from './action'
import {actionTypes} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getAwardSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)
    const response: ResponseModel = yield call(service.getAward, params)
    yield put(actions.getAwardSuccess(response?.data?.data))
    yield put(actions.getAwardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.getAwardFinish())
  }
}

function* addAwardSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addAward, body)
    yield put(actions.addAwardSuccess(response.data?.data))
    yield put(actions.addAwardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addAwardFinish())
  }
}

function* updateAwardSaga(action: ActionModel) {
  try {
    const body: any = action.payload.data
    const response: ResponseModel = yield call(service.updateAward, body, action.payload?.id)
    yield put(actions.updateAwardSuccess(response.data?.data))
    yield put(actions.updateAwardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateAwardFinish())
  }
}

function* deleteAwardSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteAward, body)
    yield put(actions.deleteAwardSuccess(response.data?.data))
    yield put(actions.deleteAwardFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteAwardFinish())
  }
}

function* singleActivateAward(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleActivateAward,
      selectedOperationIncharge
    )
    yield put({
      type: actionTypes.SINGLE_ACTIVATE_AWARD_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_ACTIVATE_AWARD_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_ACTIVATE_AWARD_FINISH})
  }
}

function* singleDeactivateAward(action: ActionModel) {
  try {
    const selectedOperationIncharge: any = action.payload
    const response: ResponseModel = yield call(
      service.singleDeactivateAward,
      selectedOperationIncharge
    )

    yield put({
      type: actionTypes.SINGLE_DEACTIVATE_AWARD_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SINGLE_DEACTIVATE_AWARD_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SINGLE_DEACTIVATE_AWARD_FINISH})
  }
}

function* sortAward(action: any) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.sortAward, body)

    yield put({
      type: actionTypes.SORT_AWARD_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_AWARD_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_AWARD_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_AWARD_START, getAwardSaga)
  yield takeLatest(actionTypes.ADD_AWARD_START, addAwardSaga)
  yield takeLatest(actionTypes.UPDATE_AWARD_START, updateAwardSaga)
  yield takeLatest(actionTypes.DELETE_AWARD_START, deleteAwardSaga)
  yield takeLatest(actionTypes.SINGLE_ACTIVATE_AWARD_REQUEST, singleActivateAward)
  yield takeLatest(actionTypes.SINGLE_DEACTIVATE_AWARD_REQUEST, singleDeactivateAward)
  yield takeLatest(actionTypes.SORT_AWARD_START, sortAward)
}
