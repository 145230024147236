export const actionTypes: any = {
  // get
  GET_ALL_AUCTION_TYPE_START: 'GET_ALL_AUCTION_TYPE_START',
  GET_ALL_AUCTION_TYPE_SUCCESS: 'GET_ALL_AUCTION_TYPE_SUCCESS',
  GET_ALL_AUCTION_TYPE_FINISH: 'GET_ALL_AUCTION_TYPE_FINISH',

  // extended hours
  GET_AUCTION_TYPE_START: 'GET_AUCTION_TYPE_START',
  GET_AUCTION_TYPE_SUCCESS: 'GET_AUCTION_TYPE_SUCCESS',
  GET_AUCTION_TYPE_FINISH: 'GET_AUCTION_TYPE_FINISH',

  // add branch
  ADD_AUCTION_TYPE_START: 'ADD_AUCTION_TYPE_START',
  ADD_AUCTION_TYPE_SUCCESS: 'ADD_AUCTION_TYPE_SUCCESS',
  ADD_AUCTION_TYPE_FINISH: 'ADD_AUCTION_TYPE_FINISH',
  RESET_AUCTION_TYPE: 'RESET_AUCTION_TYPE',

  // update SEARCH_TAG
  UPDATE_AUCTION_TYPE_START: 'UPDATE_AUCTION_TYPE_START',
  UPDATE_AUCTION_TYPE_SUCCESS: 'UPDATE_AUCTION_TYPE_SUCCESS',
  UPDATE_AUCTION_TYPE_FINISH: 'UPDATE_AUCTION_TYPE_FINISH',

  // delete SEARCH_TAG
  DELETE_AUCTION_TYPE_START: 'DELETE_AUCTION_TYPE_START',
  DELETE_AUCTION_TYPE_SUCCESS: 'DELETE_AUCTION_TYPE_SUCCESS',
  DELETE_AUCTION_TYPE_FINISH: 'DELETE_AUCTION_TYPE_FINISH',

  // Enable SEARCH_TAG
  ENABLE_AUCTION_TYPE_REQUEST: 'ENABLE_AUCTION_TYPE_REQUEST',
  ENABLE_AUCTION_TYPE_SUCCESS: 'ENABLE_AUCTION_TYPE_SUCCESS',
  ENABLE_AUCTION_TYPE_FINISH: 'ENABLE_AUCTION_TYPE_FINISH',

  // Disable SEARCH_TAG
  DISABLE_AUCTION_TYPE_REQUEST: 'DISABLE_AUCTION_TYPE_REQUEST',
  DISABLE_AUCTION_TYPE_SUCCESS: 'DISABLE_AUCTION_TYPE_SUCCESS',
  DISABLE_AUCTION_TYPE_FINISH: 'DISABLE_AUCTION_TYPE_FINISH',

  // Enable SEARCH_TAG
  SINGLE_ENABLE_AUCTION_TYPE_REQUEST: 'SINGLE_ENABLE_AUCTION_TYPE_REQUEST',
  SINGLE_ENABLE_AUCTION_TYPE_SUCCESS: 'SINGLE_ENABLE_AUCTION_TYPE_SUCCESS',
  SINGLE_ENABLE_AUCTION_TYPE_FINISH: 'SINGLE_ENABLE_AUCTION_TYPE_FINISH',

  // Disable SEARCH_TAG
  SINGLE_DISABLE_AUCTION_TYPE_REQUEST: 'SINGLE_DISABLE_AUCTION_TYPE_REQUEST',
  SINGLE_DISABLE_AUCTION_TYPE_SUCCESS: 'SINGLE_DISABLE_AUCTION_TYPE_SUCCESS',
  SINGLE_DISABLE_AUCTION_TYPE_FINISH: 'SINGLE_DISABLE_AUCTION_TYPE_FINISH',

  // sort
  SORT_AUCTION_TYPE_START: 'SORT_AUCTION_TYPE_START',
  SORT_AUCTION_TYPE_SUCCESS: 'SORT_AUCTION_TYPE_SUCCESS',
  SORT_AUCTION_TYPE_FINISH: 'SORT_AUCTION_TYPE_FINISH',
  SORT_AUCTION_TYPE_RESET: 'SORT_AUCTION_TYPE_RESET',
}
