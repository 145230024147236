export const actionTypes = {
  // get
  GET_LEAD_FORM_DETAIL_START: 'GET_LEAD_FORM_DETAIL_START',
  GET_LEAD_FORM_DETAIL_SUCCESS: 'GET_LEAD_FORM_DETAIL_SUCCESS',
  GET_LEAD_FORM_DETAIL_FINISH: 'GET_LEAD_FORM_DETAIL_FINISH',

  // get File csv xlsx
  EXPORT_LEAD_FORM_DETAIL_FILE_START: 'EXPORT_LEAD_FORM_DETAIL_FILE_START',
  EXPORT_LEAD_FORM_DETAIL_FILE_SUCCESS: 'EXPORT_LEAD_FORM_DETAIL_FILE_SUCCESS',
  EXPORT_LEAD_FORM_DETAIL_FILE_FINISH: 'EXPORT_LEAD_FORM_DETAIL_FILE_FINISH',
}
