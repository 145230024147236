import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import * as Yup from 'yup'
import * as searchCategoryRedux from '../../searchCategory'
import * as algoliaSearchRedux from '../index'

import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  categoryId: Yup.string().required('Search category field is required'),
  url: Yup.string().required('URL is required'),
  // description: Yup.string().max(400, 'Description exceeds 400 words').nullable(),
  status: Yup.string().required('Status is required'),
})

const AddAlgoliaSearch = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState('')
  const {loading, success} = useSelector((state: any) => state.algoliaSearch)

  const {
    data: {searchCategory},
  } = useSelector((state: any) => state.searchCategory)

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  useEffect(() => {
    dispatch(searchCategoryRedux.actions.getAllSearchCategory())
  }, [])

  useEffect(() => {
    if (success) {
      dispatch(algoliaSearchRedux?.actions.getAlgoliaSearch(params))
      isEmpty(editSelectedData)
        ? toast.success('Algolia Search added successfully')
        : toast.success('Algolia Search edited successfully')
      dispatch(algoliaSearchRedux?.actions?.resetAlgoliaSearch())
      handleClose()
    }
  }, [success])

  const searchCategoryOptions = searchCategory?.map((items: any) => ({
    label: items.name,
    value: items.id,
  }))

  return (
    <div className='modal-container' data-cy='algolia-search-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Customized Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                name_np: '',
                categoryId: '',
                image: '',
                description: [
                  {
                    description: '',
                    description_np: '',
                  },
                ],
                url: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  description: values?.description?.map((item: {[key: string]: string}) => ({
                    ...item,
                    id: !isEmpty(editSelectedData) ? item.id : null,
                  })),
                  status: values?.status === 'Active' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    algoliaSearchRedux.actions.updateAlgoliaSearch(
                      {...formData, id: editSelectedData?.id},
                      editSelectedData?.id
                    )
                  )
                } else {
                  dispatch(algoliaSearchRedux.actions.addAlgoliaSearch(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['name', 'name_np', 'description', 'image', 'categoryId', 'url']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue('description', editSelectedData?.description, false)
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='algolia-search-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter Name'
                            label='Name (EN)'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            data-cy='algolia-search-name-text-fields'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Name (NP)'
                            label='Name (NP)'
                            name='name_np'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='algolia-search-name-np-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please Select Search Category'
                            label='Search Category'
                            name='categoryId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={searchCategoryOptions}
                            required
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Enter URL'
                            label='URL'
                            name='url'
                            required
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            data-cy='algolia-search-name-np-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image'
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setImageUrl}
                            value={values?.image}
                          />

                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setImageUrl('')
                                      setFieldValue('image', '')
                                    }}
                                    data-cy='modal-thumbnail-remove'
                                  >
                                    Delete
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={`${imageBaseUrl}/${values?.image}`}
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='row pt-5 border-top '>
                          <h2>Search Keywords</h2>
                          <div className='row'>
                            <FieldArray
                              name='description'
                              render={(arrayHelpers) => (
                                <div>
                                  {values?.description && values?.description?.length > 0
                                    ? values?.description?.map((friend: any, index: any) => (
                                        <div className='row' key={index}>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Search Keyword (EN)'
                                              name={`description.${index}.description`}
                                              label='Search Keyword (EN)'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                          <div className='col-md-6 col-xs-12'>
                                            <FormTextBox
                                              type='text'
                                              placeholder='Search Keyword (NP)'
                                              name={`description.${index}.description_np`}
                                              label='Search Keyword (NP)'
                                              labelClassName='col-md-12'
                                              containerClassName='col-md-12'
                                              onChange={handleChange}
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>

                                          {index > 0 && (
                                            <div className='col-md-12  justify-content-md-end text-end mb-2'>
                                              <button
                                                type='button'
                                                className='p-2 ps-5 pe-5 btn btn-secondary'
                                                onClick={() => arrayHelpers.remove(index)}
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      ))
                                    : null}
                                  {values?.description?.length == 100 ? null : (
                                    <div className=' d-flex justify-content-md-end mb-2 border-top border-light'>
                                      <button
                                        className='p-2 ps-5 pe-5 btn btn-primary'
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: null,
                                            description: '',
                                            description_np: '',
                                          })
                                        }
                                        data-cy='modal-field-adds'
                                      >
                                        + Add More
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='algolia-search-status-radio'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddAlgoliaSearch
