import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteServiceTypeModel} from '../Model'
import {ServiceTypeModel} from '../Model/ServiceTypeModel'
import {actionTypes} from './constants'
export const actions = {
  // get ServiceType
  getAllEnquiryType: (params: {} = {}) => ({
    type: actionTypes.GET_ALL_ENQUIRY_TYPE_START,
    payload: params,
  }),
  getAllEnquiryTypeSuccess: (data: ServiceTypeModel) => ({
    type: actionTypes.GET_ALL_ENQUIRY_TYPE_SUCCESS,
    payload: data,
  }),
  getAllEnquiryTypeFinish: () => ({
    type: actionTypes.GET_ALL_ENQUIRY_TYPE_FINISH,
  }),

  // get ServiceType DATA
  getEnquiryType: (params: ParamsModel | {status: number} = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ENQUIRY_TYPE_START,
    payload: params,
  }),
  getEnquiryTypeSuccess: (data: ServiceTypeModel) => ({
    type: actionTypes.GET_ENQUIRY_TYPE_SUCCESS,
    payload: data,
  }),
  getEnquiryTypeFinish: () => ({
    type: actionTypes.GET_ENQUIRY_TYPE_FINISH,
  }),

  // create key
  addEnquiryType: (data: ServiceTypeModel) => ({
    type: actionTypes.ADD_ENQUIRY_TYPE_START,
    payload: data,
  }),
  addEnquiryTypeSuccess: (task: any) => ({
    type: actionTypes.ADD_ENQUIRY_TYPE_SUCCESS,
    payload: task,
  }),
  addEnquiryTypeFinish: () => ({
    type: actionTypes.ADD_ENQUIRY_TYPE_FINISH,
  }),
  resetEnquiryType: () => ({
    type: actionTypes.RESET_ENQUIRY_TYPE,
  }),

  //Update ServiceType
  updateEnquiryType: (data: ServiceTypeModel, id: string) => ({
    type: actionTypes.UPDATE_ENQUIRY_TYPE_START,
    payload: data,
    id,
  }),

  updateEnquiryTypeSuccess: (data: ServiceTypeModel) => ({
    type: actionTypes.UPDATE_ENQUIRY_TYPE_SUCCESS,
    payload: data,
  }),

  updateEnquiryTypeFinish: () => ({
    type: actionTypes.UPDATE_ENQUIRY_TYPE_FINISH,
  }),

  // delete key
  deleteEnquiryType: (data: DeleteServiceTypeModel[]) => ({
    type: actionTypes.DELETE_ENQUIRY_TYPE_START,
    payload: {queryTypeId: data},
  }),
  deleteEnquiryTypeSuccess: (data: any) => ({
    type: actionTypes.DELETE_ENQUIRY_TYPE_SUCCESS,
    payload: data,
  }),
  deleteEnquiryTypeFinish: () => ({
    type: actionTypes.DELETE_ENQUIRY_TYPE_FINISH,
  }),

  //Enable ServiceType
  enableEnquiryType: (data: any) => ({
    type: actionTypes.ENABLE_ENQUIRY_TYPE_REQUEST,
    payload: {data},
  }),

  enableEnquiryTypeSuccess: (task: any) => ({
    type: actionTypes.ENABLE_ENQUIRY_TYPE_SUCCESS,
    payload: task,
  }),
  enableEnquiryTypeFinish: () => ({
    type: actionTypes.ENABLE_ENQUIRY_TYPE_FINISH,
  }),

  //Disable ServiceType
  disableEnquiryType: (data: any) => ({
    type: actionTypes.DISABLE_ENQUIRY_TYPE_REQUEST,
    payload: {data},
  }),

  disableEnquiryTypeSuccess: (task: any) => ({
    type: actionTypes.DISABLE_ENQUIRY_TYPE_SUCCESS,
    payload: task,
  }),
  disableEnquiryTypeFinish: () => ({
    type: actionTypes.DISABLE_ENQUIRY_TYPE_FINISH,
  }),

  //Enable ServiceType
  singleEnableEnquiryType: (data: any) => ({
    type: actionTypes.SINGLE_ENABLE_ENQUIRY_TYPE_REQUEST,
    payload: {data},
  }),

  singleEnableEnquiryTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_ENQUIRY_TYPE_SUCCESS,
    payload: task,
  }),
  singleEnableEnquiryTypeFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_ENQUIRY_TYPE_FINISH,
  }),

  //Disable ServiceType
  singleDisableEnquiryType: (data: any) => ({
    type: actionTypes.SINGLE_DISABLE_ENQUIRY_TYPE_REQUEST,
    payload: {data},
  }),

  singleDisableEnquiryTypeSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_ENQUIRY_TYPE_SUCCESS,
    payload: task,
  }),
  singleDisableEnquiryTypeFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_ENQUIRY_TYPE_FINISH,
  }),
}
