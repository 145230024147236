export const actionTypes = {
  // get
  GET_ALL_BANK_DETAIL_START: 'GET_ALL_BANK_DETAIL_START',
  GET_ALL_BANK_DETAIL_SUCCESS: 'GET_ALL_BANK_DETAIL_SUCCESS',
  GET_ALL_BANK_DETAIL_FINISH: 'GET_ALL_BANK_DETAIL_FINISH',

  // extended hours
  GET_BANK_DETAIL_START: 'GET_BANK_DETAIL_START',
  GET_BANK_DETAIL_SUCCESS: 'GET_BANK_DETAIL_SUCCESS',
  GET_BANK_DETAIL_FINISH: 'GET_BANK_DETAIL_FINISH',

  // add branch
  ADD_BANK_DETAIL_START: 'ADD_BANK_DETAIL_START',
  ADD_BANK_DETAIL_SUCCESS: 'ADD_BANK_DETAIL_SUCCESS',
  ADD_BANK_DETAIL_FINISH: 'ADD_BANK_DETAIL_FINISH',
  RESET_BANK_DETAIL: 'RESET_BANK_DETAIL',

  // update BANK_DETAIL
  UPDATE_BANK_DETAIL_START: 'UPDATE_BANK_DETAIL_START',
  UPDATE_BANK_DETAIL_SUCCESS: 'UPDATE_BANK_DETAIL_SUCCESS',
  UPDATE_BANK_DETAIL_FINISH: 'UPDATE_BANK_DETAIL_FINISH',

  // delete BANK_DETAIL
  DELETE_BANK_DETAIL_START: 'DELETE_BANK_DETAIL_START',
  DELETE_BANK_DETAIL_SUCCESS: 'DELETE_BANK_DETAIL_SUCCESS',
  DELETE_BANK_DETAIL_FINISH: 'DELETE_BANK_DETAIL_FINISH',

  // Enable BANK_DETAIL
  ENABLE_BANK_DETAIL_REQUEST: 'ENABLE_BANK_DETAIL_REQUEST',
  ENABLE_BANK_DETAIL_SUCCESS: 'ENABLE_BANK_DETAIL_SUCCESS',
  ENABLE_BANK_DETAIL_FINISH: 'ENABLE_BANK_DETAIL_FINISH',

  // Disable BANK_DETAIL
  DISABLE_BANK_DETAIL_REQUEST: 'DISABLE_BANK_DETAIL_REQUEST',
  DISABLE_BANK_DETAIL_SUCCESS: 'DISABLE_BANK_DETAIL_SUCCESS',
  DISABLE_BANK_DETAIL_FINISH: 'DISABLE_BANK_DETAIL_FINISH',

  // Enable BANK_DETAIL
  SINGLE_ENABLE_BANK_DETAIL_REQUEST: 'SINGLE_ENABLE_BANK_DETAIL_REQUEST',
  SINGLE_ENABLE_BANK_DETAIL_SUCCESS: 'SINGLE_ENABLE_BANK_DETAIL_SUCCESS',
  SINGLE_ENABLE_BANK_DETAIL_FINISH: 'SINGLE_ENABLE_BANK_DETAIL_FINISH',

  // Disable BANK_DETAIL
  SINGLE_DISABLE_BANK_DETAIL_REQUEST: 'SINGLE_DISABLE_BANK_DETAIL_REQUEST',
  SINGLE_DISABLE_BANK_DETAIL_SUCCESS: 'SINGLE_DISABLE_BANK_DETAIL_SUCCESS',
  SINGLE_DISABLE_BANK_DETAIL_FINISH: 'SINGLE_DISABLE_BANK_DETAIL_FINISH',

  // sort
  SORT_BANK_DETAIL_START: 'SORT_BANK_DETAIL_START',
  SORT_BANK_DETAIL_SUCCESS: 'SORT_BANK_DETAIL_SUCCESS',
  SORT_BANK_DETAIL_FINISH: 'SORT_BANK_DETAIL_FINISH',
  SORT_BANK_DETAIL_RESET: 'SORT_BANK_DETAIL_RESET',
}
