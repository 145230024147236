import {ParamsModel} from 'src/app/modules/common/Model'
import {AppointmentRequestModel} from '../Model'
import {actionTypes} from './constants'
import {IAppointmentRequestState} from './reducer'

export const actions = {
  // get AppointmentRequest
  getAppointmentRequest: (params: ParamsModel = {page: 1, limit: 10}, id: string = '') => {
    return {
      type: actionTypes.GET_APPOINTMENT_REQUEST_START,
      payload: {params, id},
    }
  },
  getAppointmentRequestSuccess: (data: IAppointmentRequestState) => ({
    type: actionTypes.GET_APPOINTMENT_REQUEST_SUCCESS,
    payload: data,
  }),
  getAppointmentRequestError: (data: IAppointmentRequestState) => ({
    type: actionTypes.GET_APPOINTMENT_REQUEST_FINISH,
    payload: data,
  }),

  //Add AppointmentRequest Action
  /*   CreateAppointmentRequest: (data: any) => ({
    type: actionTypes.ADD_APPOINTMENT_REQUEST_REQUEST,
    payload: data,
  }),

  createAppointmentRequestSuccess: (task: any) => ({
    type: actionTypes.ADD_APPOINTMENT_REQUEST_SUCCESS,
    payload: task,
  }),
  createAppointmentRequestFinish: (errorMsg: any) => ({
    type: actionTypes.ADD_APPOINTMENT_REQUEST_FINISH,
    payload: errorMsg,
  }),
  createAppointmentRequestReset: () => ({
    type: actionTypes.ADD_APPOINTMENT_REQUEST_RESET,
  }), */

  getAppointmentRequestReset: () => ({
    type: actionTypes.GET_APPOINTMENT_REQUEST_RESET,
  }),

  // update AppointmentRequest
  updateAppointmentRequest: (data: AppointmentRequestModel, id: string) => ({
    type: actionTypes.UPDATE_APPOINTMENT_REQUEST_START,
    payload: {data, id},
  }),

  //Get Appointment Request file csv
  getAppointmentRequestFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_REQUEST_FILE_START,
      payload: fileType,
    }
  },

  getAppointmentRequestFileSuccess: (data: IAppointmentRequestState) => ({
    type: actionTypes.GET_REQUEST_FILE_SUCCESS,
    payload: data,
  }),
  getAppointmentRequestFileError: (data: IAppointmentRequestState) => ({
    type: actionTypes.GET_REQUEST_FILE_FINISH,
    payload: data,
  }),
}
