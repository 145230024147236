import { Action } from 'redux'
import { actionTypes } from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const initialBaseRateState: IBaseRateState = {
  data: {
    baseInterestRate: [],
    meta: {},
  },
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export interface IBaseRateState {
  data?: {
    baseInterestRate?: { [key: string]: number | string }[]
    meta: { [key: string]: number }
  }
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

export const reducer = (
  state: IBaseRateState = initialBaseRateState,
  action: ActionWithPayload<IBaseRateState>
) => {
  switch (action.type) {
    //GET INTEREST RATE Data
    case actionTypes.GET_BASE_RATE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_BASE_RATE_SUCCESS: {
      return { ...state, data: action.payload, loading: false }
    }

    case actionTypes.GET_BASE_RATE_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //Add INTEREST RATE
    case actionTypes.ADD_BASE_RATE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.ADD_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_BASE_RATE_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    case actionTypes.RESET_BASE_RATE_TYPE: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    //Update INTEREST RATE
    case actionTypes.UPDATE_BASE_RATE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.UPDATE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_BASE_RATE_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //Delete
    case actionTypes.DELETE_BASE_RATE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.DELETE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_BASE_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_BASE_RATE_REQUEST: {
      return { ...state, loading: true }
    }

    case actionTypes.ENABLE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_BASE_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_BASE_RATE_REQUEST: {
      return { ...state, loading: true }
    }

    case actionTypes.DISABLE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_BASE_RATE_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }
    //Enable
    case actionTypes.SINGLE_ENABLE_BASE_RATE_REQUEST: {
      return { ...state, toggleLoading: true }
    }

    case actionTypes.SINGLE_ENABLE_BASE_RATE_SUCCESS: {
      const changedData: any = action.payload?.data?.baseInterestRate
      let newData = state?.data?.baseInterestRate?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: { ...state?.data?.meta, baseInterestRate: newData },
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_BASE_RATE_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_BASE_RATE_REQUEST: {
      return { ...state, toggleLoading: true }
    }

    case actionTypes.SINGLE_DISABLE_BASE_RATE_SUCCESS: {
      const changedData: any = action.payload?.data?.baseInterestRate
      let newData = state?.data?.baseInterestRate?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: { ...state?.data?.meta, baseInterestRate: newData },
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_BASE_RATE_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    default:
      return state
  }
}

const initialInterestRateLoanFileState: IInterestRateLoanFileState = {
  data: {
    file: {},
  },
  loading: false,
  success: false,
}

export interface IInterestRateLoanFileState {
  data: {
    file: { [key: string]: number }
  }
  loading?: boolean
  success?: boolean
}

export const reducer1 = (
  state: IInterestRateLoanFileState = initialInterestRateLoanFileState,
  action: ActionWithPayload<IInterestRateLoanFileState>
) => {
  switch (action.type) {
    case actionTypes.GET_BASE_RATE_FILE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_BASE_RATE_FILE_SUCCESS: {
      return { ...state, data: action.payload?.data, loading: false }
    }

    case actionTypes.GET_BASE_RATE_FILE_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    //Get Deposit interest rate
    case actionTypes.GET_INTEREST_SPREAD_FILE_START: {
      return { ...state, loading: true }
    }

    case actionTypes.GET_INTEREST_SPREAD_FILE_SUCCESS: {
      return { ...state, data: action.payload?.data, loading: false }
    }

    case actionTypes.GET_INTEREST_SPREAD_FILE_FINISH: {
      const error = action.payload
      return { ...state, error, loading: false }
    }

    default:
      return state
  }
}
