import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const GET_APPOINTMENT_STATUS = `${API_URL}/appointment-status`

export const service = {
  getAppointmentStatus: (params: ParamsModel) => {
    return axios.get(GET_APPOINTMENT_STATUS, {params})
  },
}
