export const actionTypes = {
  // get sub category
  GET_NOTICES_START: 'GET_NOTICES_START',
  GET_NOTICES_SUCCESS: 'GET_NOTICES_SUCCESS',
  GET_NOTICES_FINISH: 'GET_NOTICES_FINISH',

  // ADD sub category
  ADD_NOTICES_START: 'ADD_NOTICES_START',
  ADD_NOTICES_SUCCESS: 'ADD_NOTICES_SUCCESS',
  ADD_NOTICES_FINISH: 'ADD_NOTICES_FINISH',
  ADD_NOTICES_RESET: 'ADD_NOTICES_RESET',

  // Update sub category
  UPDATE_NOTICES_START: 'UPDATE_NOTICES_START',
  UPDATE_NOTICES_SUCCESS: 'UPDATE_NOTICES_SUCCESS',
  UPDATE_NOTICES_FINISH: 'UPDATE_NOTICES_FINISH',
  UPDATE_NOTICES_RESET: 'UPDATE_NOTICES_RESET',

  // delete sub category
  DELETE_NOTICES_START: 'DELETE_NOTICES_START',
  DELETE_NOTICES_SUCCESS: 'DELETE_NOTICES_SUCCESS',
  DELETE_NOTICES_FINISH: 'DELETE_NOTICES_FINISH',

  // single active sub category
  SINGLE_ACTIVATE_NOTICES_REQUEST: 'SINGLE_ACTIVATE_NOTICES_REQUEST',
  SINGLE_ACTIVATE_NOTICES_SUCCESS: 'SINGLE_ACTIVATE_NOTICES_SUCCESS',
  SINGLE_ACTIVATE_NOTICES_FINISH: 'SINGLE_ACTIVATE_NOTICES_FINISH',

  // single deactive sub category
  SINGLE_DEACTIVATE_NOTICES_REQUEST: 'SINGLE_DEACTIVATE_NOTICES_REQUEST',
  SINGLE_DEACTIVATE_NOTICES_SUCCESS: 'SINGLE_DEACTIVATE_NOTICES_SUCCESS',
  SINGLE_DEACTIVATE_NOTICES_FINISH: 'SINGLE_DEACTIVATE_NOTICES_FINISH',
}
