export const actionTypes = {
  GET_DISPUTES_START: 'GET_DISPUTES_START',
  GET_DISPUTES_SUCCESS: 'GET_DISPUTES_SUCCESS',
  GET_DISPUTES_FINISH: 'GET_DISPUTES_FINISH',
  RESET_APPLY_LOAN: 'RESET_APPLY_LOAN',

  DELETE_DISPUTES_START: 'DELETE_DISPUTES_START',
  DELETE_DISPUTES_SUCCESS: 'DELETE_DISPUTES_SUCCESS',
  DELETE_DISPUTES_FINISH: 'DELETE_DISPUTES_FINISH',
}
