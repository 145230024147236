import {ErrorMessage} from 'formik'
import React, {useState} from 'react'
import MediaManagerModal from '../MediaManagerModal'
import {isEmpty} from 'lodash'
import IconButton from 'rsuite/IconButton'
import {AiOutlineEdit} from 'react-icons/ai'
import {FaPlus} from 'react-icons/fa'

type Props = {
  containerClassName?: string
  label?: string
  name: string
  initialValue?: any
  rules?: any
  labelClassName?: string
  required?: boolean
  className?: string
  setFieldValue: any
  value: any
  setImageUrl?: any
  note?: string
  readOnly?: boolean
  showImageOnly?: boolean
  buttonName?: string
}

const FormInputMediaManager: React.FC<Props> = ({
  containerClassName,
  labelClassName,
  label,
  name,
  initialValue,
  rules,
  required,
  className,
  setFieldValue,
  value,
  setImageUrl,
  note,
  readOnly,
  showImageOnly,
  buttonName,
  ...rest
}) => {
  const [mediaManagerModalOpen, setMediaManagerModalOpen] = useState(false)

  const handleCloseMediaManagerModal = () => {
    setMediaManagerModalOpen(false)
  }

  const callBackFile = {
    callback: (downloadLink: string, title: string, storageLink: string) => {
      setFieldValue(name, storageLink)
      setImageUrl && setImageUrl(downloadLink)
    },
  }

  return (
    <div className={label ? 'mb-6' : 'col-lg-6 '}>
      {label && (
        <label className={`mb-1 fw-bolder fs-6 ${labelClassName || 'col-lg-4'}`}>
          <span className={required ? 'required' : ''}>{label}</span>
        </label>
      )}
      <div className={containerClassName ?? 'col-lg-6'}>
        <div className=' d-flex flex-column'>
          {isEmpty(value) ? (
            <>
              <button
                type='button'
                className='dt-btn dt-btn-primary'
                onClick={() => setMediaManagerModalOpen(true)}
                data-cy={`${name}-media-manager-field`}
              >
                <FaPlus className='me-2' />
                {buttonName ? buttonName : 'Add New Image'}
              </button>
            </>
          ) : (
            <>
              <input
                name={name}
                type={'text'}
                className={className || 'form-control border mt-2'}
                autoComplete='off'
                value={value}
                {...rest}
                readOnly={readOnly}
                data-cy={`${name}-media-manager-field`}
                onClick={() => setMediaManagerModalOpen(true)}
              />
            </>
          )}

          {!isEmpty(note) && (
            <em>
              <small>
                <span className='text-danger'>Note:</span> {note}
              </small>
            </em>
          )}
        </div>
        <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
          <ErrorMessage name={name} component='div' className='field-error-message' />
        </div>
      </div>
      <MediaManagerModal
        isOpen={mediaManagerModalOpen}
        handleClose={handleCloseMediaManagerModal}
        editorCallBack={callBackFile}
        showImageOnly={showImageOnly}
      />
    </div>
  )
}

export default FormInputMediaManager
