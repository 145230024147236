import {ParamsModel} from 'src/app/modules/common/Model'
import {IModuleState} from '../../moduleManager'
import {SortHomepageSettingModel} from '../Model'
import {actionTypes} from './constants'
import {IHomepageSettingState} from './reducer'

export const actions = {
  getHomepageSection: () => ({type: actionTypes.GET_HOMEPAGE_SECTION_START}),

  getHomepageSectionSuccess: (data: IModuleState) => ({
    type: actionTypes.GET_HOMEPAGE_SECTION_SUCCESS,
    payload: data,
  }),

  getHomepageSectionFinish: () => ({
    type: actionTypes.GET_HOMEPAGE_SECTION_FINISH,
  }),

  getHomepageLayout: () => ({type: actionTypes.GET_HOMEPAGE_LAYOUT_START}),

  getHomepageLayoutSuccess: (data: IModuleState) => ({
    type: actionTypes.GET_HOMEPAGE_LAYOUT_SUCCESS,
    payload: data,
  }),

  getHomepageLayoutFinish: () => ({
    type: actionTypes.GET_HOMEPAGE_LAYOUT_FINISH,
  }),

  addHomepageSetting: (data: any) => ({
    type: actionTypes.ADD_HOMEPAGE_SETTING_START,
    payload: data,
  }),

  addHomepageSettingSuccess: (data: any) => ({
    type: actionTypes.ADD_HOMEPAGE_SETTING_SUCCESS,
    payload: data,
  }),

  addHomepageSettingFinish: () => ({
    type: actionTypes.ADD_HOMEPAGE_SETTING_FINISH,
  }),

  addHomepageSettingReset: () => ({
    type: actionTypes.ADD_HOMEPAGE_SETTING_RESET,
  }),

  //Update
  updateHomepageSetting: (data: any, id: string) => ({
    type: actionTypes.UPDATE_HOMEPAGE_SETTING_START,
    payload: {data, id},
  }),

  updateHomepageSettingSuccess: (data: IHomepageSettingState) => ({
    type: actionTypes.UPDATE_HOMEPAGE_SETTING_SUCCESS,
    payload: data,
  }),

  updateHomepageSettingFinish: () => ({
    type: actionTypes.UPDATE_HOMEPAGE_SETTING_FINISH,
  }),

  updateHomepageSettingReset: () => ({
    type: actionTypes.UPDATE_HOMEPAGE_SETTING_RESET,
  }),

  getHomepageSetting: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_HOMEPAGE_SETTING_START,
    payload: {params},
  }),

  getHomepageSettingSuccess: (data: IHomepageSettingState) => ({
    type: actionTypes.GET_HOMEPAGE_SETTING_SUCCESS,
    payload: data,
  }),

  getHomepageSettingFinish: () => ({
    type: actionTypes.GET_HOMEPAGE_SETTING_FINISH,
  }),

  //Delete HomepageSetting
  deleteHomepageSetting: (data: any) => ({
    type: actionTypes.DELETE_HOMEPAGE_SETTING_START,
    payload: {homePageId: data},
  }),
  deleteHomepageSettingSuccess: (data: any) => ({
    type: actionTypes.DELETE_HOMEPAGE_SETTING_SUCCESS,
    payload: data,
  }),
  deleteHomepageSettingFinish: () => ({
    type: actionTypes.DELETE_HOMEPAGE_SETTING_FINISH,
  }),

  //Enable HomepageSetting
  singleEnableHomepageSetting: (data: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_REQUEST,
    payload: {data},
  }),

  singleEnableHomepageSettingSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_SUCCESS,
    payload: task,
  }),
  singleEnableHomepageSettingFinish: () => ({
    type: actionTypes.SINGLE_ACTIVATE_HOMEPAGE_SETTING_FINISH,
  }),

  //Disable HomepageSetting
  singleDisableHomepageSetting: (data: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_REQUEST,
    payload: {data},
  }),

  singleDisableHomepageSettingSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_SUCCESS,
    payload: task,
  }),
  singleDisableHomepageSettingFinish: () => ({
    type: actionTypes.SINGLE_DEACTIVATE_HOMEPAGE_SETTING_FINISH,
  }),
  // sort
  sortHomepageSetting: (data: SortHomepageSettingModel) => ({
    type: actionTypes.SORT_HOMEPAGE_SETTING_START,
    payload: data,
  }),
  sortHomepageSettingSuccess: (data: Array<any>) => ({
    type: actionTypes.SORT_HOMEPAGE_SETTING_SUCCESS,
    payload: data,
  }),
  sortHomepageSettingFinish: () => ({
    type: actionTypes.SORT_HOMEPAGE_SETTING_FINISH,
  }),
  sortHomepageSettingReset: () => ({
    type: actionTypes.SORT_HOMEPAGE_SETTING_RESET,
  }),
}
