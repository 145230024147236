export const actionTypes = {
    // get
    GET_MEDIA_LIST_START: 'GET_MEDIA_LIST_START',
    GET_MEDIA_LIST_SUCCESS: 'GET_MEDIA_LIST_SUCCESS',
    GET_MEDIA_LIST_FINISH: 'GET_MEDIA_LIST_FINISH',

    GET_MEDIA_HIERARCHY_START: 'GET_MEDIA_HIERARCHY_START',
    GET_MEDIA_HIERARCHY_SUCCESS: 'GET_MEDIA_HIERARCHY_SUCCESS',
    GET_MEDIA_HIERARCHY_FINISH: 'GET_MEDIA_HIERARCHY_FINISH',

    // create
    ADD_NEW_FOLDER_START: 'ADD_NEW_FOLDER_START',
    ADD_NEW_FOLDER_SUCCESS: 'ADD_NEW_FOLDER_SUCCESS',
    ADD_NEW_FOLDER_FINISH: 'ADD_NEW_FOLDER_FINISH',
    ADD_NEW_FOLDER_RESET: 'ADD_NEW_FOLDER_RESET',

    // update
    ADD_FILES_START: 'ADD_FILES_START',
    ADD_FILES_SUCCESS: 'ADD_FILES_SUCCESS',
    ADD_FILES_FINISH: 'ADD_FILES_FINISH',
    ADD_FILES_RESET: 'ADD_FILES_RESET',

    RENAME_FILE_FOLDER_START: 'RENAME_FILE_FOLDER_START',
    RENAME_FILE_FOLDER_SUCCESS: 'RENAME_FILE_FOLDER_SUCCESS',
    RENAME_FILE_FOLDER_FINISH: 'RENAME_FILE_FOLDER_FINISH',
    RENAME_FILE_FOLDER_RESET: 'RENAME_FILE_FOLDER_RESET',

    // delete
    DELETE_FOLDER_FILES_START: 'DELETE_FOLDER_FILES_START',
    DELETE_FOLDER_FILES_SUCCESS: 'DELETE_FOLDER_FILES_SUCCESS',
    DELETE_FOLDER_FILES_FINISH: 'DELETE_FOLDER_FILES_FINISH',
    DELETE_FOLDER_FILES_RESET: 'DELETE_FOLDER_FILES_RESET'
}