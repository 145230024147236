export const actionTypes = {
  // get
  GET_ALL_PRODUCT_TAG_START: 'GET_ALL_PRODUCT_TAG_START',
  GET_ALL_PRODUCT_TAG_SUCCESS: 'GET_ALL_PRODUCT_TAG_SUCCESS',
  GET_ALL_PRODUCT_TAG_FINISH: 'GET_ALL_PRODUCT_TAG_FINISH',

  // extended hours
  GET_PRODUCT_TAG_START: 'GET_PRODUCT_TAG_START',
  GET_PRODUCT_TAG_SUCCESS: 'GET_PRODUCT_TAG_SUCCESS',
  GET_PRODUCT_TAG_FINISH: 'GET_PRODUCT_TAG_FINISH',

  // add branch
  ADD_PRODUCT_TAG_START: 'ADD_PRODUCT_TAG_START',
  ADD_PRODUCT_TAG_SUCCESS: 'ADD_PRODUCT_TAG_SUCCESS',
  ADD_PRODUCT_TAG_FINISH: 'ADD_PRODUCT_TAG_FINISH',
  RESET_PRODUCT_TAG: 'RESET_PRODUCT_TAG',

  // update PRODUCT_TAG
  UPDATE_PRODUCT_TAG_START: 'UPDATE_PRODUCT_TAG_START',
  UPDATE_PRODUCT_TAG_SUCCESS: 'UPDATE_PRODUCT_TAG_SUCCESS',
  UPDATE_PRODUCT_TAG_FINISH: 'UPDATE_PRODUCT_TAG_FINISH',

  // delete PRODUCT_TAG
  DELETE_PRODUCT_TAG_START: 'DELETE_PRODUCT_TAG_START',
  DELETE_PRODUCT_TAG_SUCCESS: 'DELETE_PRODUCT_TAG_SUCCESS',
  DELETE_PRODUCT_TAG_FINISH: 'DELETE_PRODUCT_TAG_FINISH',

  // Enable PRODUCT_TAG
  ENABLE_PRODUCT_TAG_REQUEST: 'ENABLE_PRODUCT_TAG_REQUEST',
  ENABLE_PRODUCT_TAG_SUCCESS: 'ENABLE_PRODUCT_TAG_SUCCESS',
  ENABLE_PRODUCT_TAG_FINISH: 'ENABLE_PRODUCT_TAG_FINISH',

  // Disable PRODUCT_TAG
  DISABLE_PRODUCT_TAG_REQUEST: 'DISABLE_PRODUCT_TAG_REQUEST',
  DISABLE_PRODUCT_TAG_SUCCESS: 'DISABLE_PRODUCT_TAG_SUCCESS',
  DISABLE_PRODUCT_TAG_FINISH: 'DISABLE_PRODUCT_TAG_FINISH',

  // Enable PRODUCT_TAG
  SINGLE_ENABLE_PRODUCT_TAG_REQUEST: 'SINGLE_ENABLE_PRODUCT_TAG_REQUEST',
  SINGLE_ENABLE_PRODUCT_TAG_SUCCESS: 'SINGLE_ENABLE_PRODUCT_TAG_SUCCESS',
  SINGLE_ENABLE_PRODUCT_TAG_FINISH: 'SINGLE_ENABLE_PRODUCT_TAG_FINISH',

  // Disable PRODUCT_TAG
  SINGLE_DISABLE_PRODUCT_TAG_REQUEST: 'SINGLE_DISABLE_PRODUCT_TAG_REQUEST',
  SINGLE_DISABLE_PRODUCT_TAG_SUCCESS: 'SINGLE_DISABLE_PRODUCT_TAG_SUCCESS',
  SINGLE_DISABLE_PRODUCT_TAG_FINISH: 'SINGLE_DISABLE_PRODUCT_TAG_FINISH',
}
