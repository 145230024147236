import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import * as qualificationRedux from 'src/app/modules/careerSettings/components/Qualification/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  status: Yup.string().required('Status is required'),
})

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const AddQualification = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const { loading, success } = useSelector((state: any) => state.Qualification)

  useEffect(() => {
    if (success) {
      dispatch(qualificationRedux?.actions.getQualification(params))
      isEmpty(editSelectedData)
        ? toast.success('Qualification added successfully')
        : toast.success('Qualification edited successfully')
      dispatch(qualificationRedux?.actions.addQualificationReset())
      handleClose()
    }
  }, [success])

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ]
  const isSingleLanguage = checkMultiLanguage()
  return (
    <div className='modal-container' data-cy='qualification-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Qualification </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                nameNp: '',
                description: '',
                descriptionNp: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    qualificationRedux.actions.updateQualification(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(qualificationRedux.actions.addQualification(formData))
                }
              }}
            >
              {({ isSubmitting, touched, handleChange, errors, setFieldValue, values }) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['name', 'nameNp', 'description', 'descriptionNp']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row justify-content-between'>
                        <div className='col-6'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Name'
                            name='name'
                            label='Name (EN)'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='qualification-name-text-field'
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-6'>
                            <FormTextBox
                              type='text'
                              placeholder='Enter Name in Nepali'
                              name='nameNp'
                              label='Name (NP)'
                              containerClassName=''
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        )}
                        <div className='col-md-6 col-xs-12'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description (EN)'
                            name='description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                            required
                            data-cy='qualification-description-text-area'
                          />
                        </div>
                        {!isSingleLanguage && (
                          <div className='col-md-6 col-xs-12'>
                            <FormTinyMce
                              containerClassName='col-md-12'
                              label='Description (NP)'
                              name='descriptionNp'
                              initialValue={
                                !isEmpty(editSelectedData) ? editSelectedData?.descriptionNp : ''
                              }
                              handleChange={handleChange}
                            />
                          </div>
                        )}
                      </div>
                      <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>

                      <div className='d-flex ms-5'>
                        {statusOptions?.map((status: { label: string; value: string }) => (
                          <FormRadio
                            containerClassName=''
                            label='Select Status'
                            name='status'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            checkBoxText={status?.label}
                            value={status?.value}
                            required={true}
                            data-cy='qualification-status-radio'
                          />
                        ))}
                      </div>
                      <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                        <ErrorMessage
                          name='status'
                          component='div'
                          className='field-error-message'
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddQualification
