import {ParamsModel} from 'src/app/modules/common/Model'
import {DeleteGeneralApplicationModel, GeneralApplicationModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getGeneralApplicationsList: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_START,
    payload: params,
  }),

  getGeneralApplicationsListSuccess: (data: any) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_SUCCESS,
    payload: data,
  }),
  getGeneralApplicationsListFinish: () => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_FINISH,
  }),
  getGeneralApplicationsListError: (error: unknown) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_FINISH,
    payload: {error},
  }),

  updateGeneralApplicationsList: (data: any, id: string) => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_LIST_START,
    payload: {data, id},
  }),
  updateGeneralApplicationsListSuccess: (task: any) => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_LIST_SUCCESS,
    payload: task,
  }),
  updateGeneralApplicationsListFinish: () => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_LIST_FINISH,
  }),
  updateGeneralApplicationsListReset: () => ({
    type: actionTypes.UPDATE_GENERAL_APPLICATIONS_LIST_RESET,
  }),

  deleteGeneralApplicationsList: (data: DeleteGeneralApplicationModel[]) => ({
    type: actionTypes.DELETE_GENERAL_APPLICATIONS_LIST_START,
    payload: {generalApplicantId: data},
  }),
  deleteGeneralApplicationsListSuccess: (data: any) => ({
    type: actionTypes.DELETE_GENERAL_APPLICATIONS_LIST_SUCCESS,
    payload: data,
  }),
  deleteGeneralApplicationsListFinish: () => ({
    type: actionTypes.DELETE_GENERAL_APPLICATIONS_LIST_FINISH,
  }),

  //Get csv file
  getGeneralApplicationFile: (fileType: string = 'csv') => {
    return {
      type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_FILE_START,
      payload: fileType,
    }
  },

  getGeneralApplicationFileSuccess: (data:any) => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_FILE_SUCCESS,
    payload: data,
  }),
  getGeneralApplicationFileError: () => ({
    type: actionTypes.GET_GENERAL_APPLICATIONS_LIST_FILE_FINISH,
  }),
}
