import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
// branch
import moment from 'moment'
import * as categories from 'src/app/modules/cms/components/categories'
import {GalleryModel} from 'src/app/modules/cms/components/gallery/Model'
import * as categoryTypeRedux from 'src/app/modules/common'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkBackendSetting from 'src/app/modules/common/checkBackendSetting'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import * as setting from 'src/app/modules/siteSettings/components/settings'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import {bankName, imageBaseUrl} from 'src/cms/helpers/constants'
// import * as subCategory from '../../subCategory'
import * as apiManager from '../index'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

let FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required('API title is required'),
  api: Yup.string().required('API is required'),
  encryption: Yup.boolean().required('Encryption is required'),
})

const AddApiList = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()

  const [categoryValue, setCategoryValue] = useState('')
  const [attachedFile, setAttachedFile] = useState('')
  const [imageEng, setImageEng] = useState('')
  const [imageNp, setImageNp] = useState('')
  const [newsCategory, setNewsCategory] = useState('')
  const [attachfile, setAttachfile] = useState('')
  const [applicationName, setApplicationName] = useState('')

  const {loading, success} = useSelector((state: any) => state.apiManager)
  const {
    data: {categoryType},
  } = useSelector((state: any) => state.categoryType)
  const {
    data: {category},
  } = useSelector((state: any) => state.categories)

  const {data: subCategoryData} = useSelector((state: any) => state.subCategory)
  const {
    data: {album},
  } = useSelector((state: any) => state.gallery)

  useEffect(() => {
    dispatch(categoryTypeRedux.action.getCategoryType())
  }, [])

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    dispatch(setting.actions.getSettingType())
  }, [])
  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setApplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])

  useEffect(() => {
    const newsCatType = categoryType?.filter((item: {[key: string]: string}) =>
      bankName === applicationName
        ? item.system_name === 'news' || item.system_name === 'notice'
        : item.system_name === 'news'
    )

    setNewsCategory(newsCatType[0]?.id)
  }, [categoryType])

  useEffect(() => {
    if (!isEmpty(newsCategory)) {
      dispatch(categories.actions.getSpecificCmsCategories(newsCategory))
    }
  }, [newsCategory])

  // useEffect(() => {
  //   if (categoryValue) {
  //     dispatch(subCategory.actions.getSpecificSubCategory(categoryValue))
  //   }
  // }, [categoryValue])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]

  const encryptedOptions = [
    {label: 'True', value: 'True'},
    {label: 'False', value: 'False'},
  ]

  const categoryOptions = category?.map((items: any) => ({
    label: items.name,
    value: items.id,
  }))

  const albumOptions = album?.map((items: GalleryModel) => ({
    label: items.title,
    value: items.id,
  }))

  const subCategoryOptions = subCategoryData?.subCategory?.map((items: any) => ({
    label: items.name,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('API added successfully')
        : toast.success('API edited successfully')
      dispatch(apiManager?.actions.getApiManagerDetail(params))
      // dispatch(apiManager?.actions?.addNewsReset())
      handleClose()
    }
  }, [success])

  function convertToSlug(text: any) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  const hideAttachedFileTitle = checkBackendSetting('hideAttachedFileTitle')
  const hideImageField = checkBackendSetting('hideImageField')
  const isStatusRequired = checkBackendSetting('isStatusRequired')
  const newsSubCategoryRequired = checkBackendSetting('newsSubCategoryRequired')

  if (isStatusRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      status: Yup.string().required('Status is required'),
    })
  }

  if (newsSubCategoryRequired) {
    FORM_VALIDATION = FORM_VALIDATION.shape({
      subCategoryId: Yup.string().required('Sub category is required'),
    })
  }
  const isSingleLanguage = checkMultiLanguage()
  return (
    <div className='modal-container' data-cy='add-news-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} API List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                api: '',
                encryption: '',
                status: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values, {setSubmitting}) => {
                const formData = {
                  ...values,
                  status: values?.status === 'Active' ? true : false,
                  encryption: values?.encryption === 'True' ? true : false,
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    apiManager.actions.updateApiManagerDetail(formData, editSelectedData?.id)
                  )
                } else {
                  dispatch(apiManager.actions.addApiManagerDetail(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, values, setFieldValue}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = ['name', 'api', 'encryption', 'status']
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))

                    setFieldValue(
                      'archive_date',
                      editSelectedData?.archive_date
                        ? moment(editSelectedData?.archive_date, 'YYYY-MM-DD').toDate()
                        : null,
                      false
                    )
                    setFieldValue(
                      'published_date',
                      moment(editSelectedData?.published_date, 'YYYY-MM-DD').toDate(),
                      false
                    )
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                  }
                }, [])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='add-apiList-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='API title'
                            label='API title'
                            name='name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-apiList-title-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='API'
                            label='API'
                            name='api'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-apiList-api-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <label className={`form-label fw-bolder text-dark fs-6 required`}>
                            Encrypted
                          </label>
                          <div className='d-flex ms-5'>
                            {encryptedOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                key={status?.value}
                                containerClassName=''
                                label='Is Encrypted?'
                                name='encryption'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={isStatusRequired}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='news-status-radio-button'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='encryption'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label
                            className={`form-label fw-bolder text-dark fs-6 ${
                              isStatusRequired && 'required'
                            }`}
                          >
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                key={status?.value}
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={isStatusRequired}
                                checkBoxText={status?.label}
                                value={status?.value}
                                data-cy='news-status-radio-button'
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddApiList
