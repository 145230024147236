import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {COMMON_TYPES, BENEFIT_LIST_TYPES} from './constants'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {getBenfitListDetailsAPI} from './service'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {ParamsModel} from 'src/app/modules/common/Model'

function* getBenfitListDetailsSaga(action: ActionModel) {
  try {
    const params: ParamsModel = action.payload
    yield delay(500)

    const response: ResponseModel = yield call(getBenfitListDetailsAPI, params)

    yield put({
      type: BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.CALL_OVER})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }

    yield put({type: BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.CALL_OVER})
  }
}

export function* saga() {
  yield takeLatest(
    BENEFIT_LIST_TYPES.GET_BENEFIT_LIST + COMMON_TYPES.REQUEST,
    getBenfitListDetailsSaga
  )
}
